import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import InputMasked from '../../../../BaseComponents/InputMasked';

/**
 * @category Settings
 * @param {string} title
 * @param {function} validators
 * @param {string} name
 * @param {*} rest
 * @returns {React.FC}
 */
const NotificationSettingItem = ({ title, validators, name, ...rest }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        my: 3,
      }}
    >
      <Box as="h4" width="min-content">
        {title}
      </Box>
      <Field
        aria-label="Input"
        component={InputMasked}
        mask={createNumberMask({
          allowDecimal: false,
          integerLimit: 3,
          prefix: '',
          suffix: ' Days',
        })}
        name={name}
        placeholder="Days"
        validate={validators}
        wrapperSx={{ maxWidth: '50%' }}
        {...rest}
      />
    </Box>
  );
};

NotificationSettingItem.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  validators: PropTypes.func,
};

NotificationSettingItem.defaultProps = {
  validators: () => {},
};

export default NotificationSettingItem;
