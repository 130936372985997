import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import React, { useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import GetData from '@src/utilities/get-data';
import Route from '@petcomponents/Route';
import { useTranslation } from 'react-i18next';
import { email } from '@src/utilities/validators';
import remoteActionQuery from '../../../graphql/queries/remote-action.gql';
import useSnackbar from '../../../utilities/use-snackbar';
import InputText from '../../BaseComponents/Input';
import Modal from '../../BaseComponents/Modal';
import Spinner from '../../BaseComponents/Spinner';
import ToolbarButton from '../../BaseComponents/ToolbarButton';
import config from '../../../config.json';

/**
 * @category Components
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const AddSecondaryEmail = ({ ...rest }) => {
  const { t } = useTranslation()
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const queryParams = useParams();
  const employeeId = get(queryParams, 'employeeId', '');
  const [showModal, setShowModal] = useState(false);

  const { apiData = {}, loading } = GetData(
    'get-group-employee-by-id',
    JSON.stringify({ id: employeeId })
  );

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <Route
      header={{
        title: t('groupEmployees.detailsPage.alternateCommunication'),
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
      {t('groupEmployees.detailsPage.alternateCommunication')}
      </Box>
      <ApolloConsumer>
        {(client) => {
          const updateSecondaryEmail = async () => {
            setShowModal(false);
            client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'update-preferred-email',
                  params: JSON.stringify({
                    id: get(apiData, 'employeeId', ''),
                    secondaryEmail: '',
                    userId: get(apiData, 'userId', ''),
                  }),
                },
              })
              .then(() => {
                navigate(`/group-employees/${employeeId}/details`);
              })
              .catch((e) =>
                setErrorSnack(
                  t('snack.error.errorWithMessage', { message: e.message }),
                  config.notificationDuration
                )
              );
          };
          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                initialValues={{
                  secondaryEmail: get(apiData, 'user.secondaryEmail', ''),
                }}
                onSubmit={async (values) => {
                  client
                    .query({
                      fetchPolicy: 'no-cache',
                      query: remoteActionQuery,
                      variables: {
                        name: 'update-preferred-email',
                        params: JSON.stringify({
                          id: get(apiData, 'employeeId', ''),
                          secondaryEmail: values.secondaryEmail,
                          userId: get(apiData, 'userId', ''),
                        }),
                      },
                    })
                    .then(() => {
                      navigate(`/group-employees/${employeeId}/details`);
                    })
                    .catch((e) =>
                      setErrorSnack(
                        `There was an error: ${e.message}`,
                        config.notificationDuration
                      )
                    );
                }}
                render={({ submitting, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        padding: 3,
                      }}
                    >
                      <Field
                        aria-label={t('groupEmployees.detailsPage.alternateCommunication')}
                        component={InputText}
                        isDisabled
                        label={t('groupEmployees.detailsPage.alternateCommunication')}
                        name="secondaryEmail"
                        validate={email}
                        wrapperSx={{ ...commonFieldSxHalf }}
                        {...rest}
                      />
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          maxWidth: '25rem',
                          mt: 6,
                          width: '100%',
                        }}
                      >
                        <ToolbarButton
                          isDisabled={
                            !get(apiData, 'user.secondaryEmail', '') ||
                            submitting
                          }
                          label={t('common.remove')}
                          mr={0}
                          onClick={() => {
                            setShowModal(true);
                          }}
                          simple
                          submitting={loading}
                          sx={{
                            bg: 'transparent',
                            color: `${!get(apiData, 'user.secondaryEmail', '') ||
                              submitting
                              ? '#E9675D'
                              : 'error'
                              }`,
                            fontSize: 2,
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          }}
                          width="150px"
                        />
                        {showModal === true && (
                          <Modal
                            closeText={t('common.no')}
                            isOpen
                            onClose={() => setShowModal(false)}
                            onSubmit={updateSecondaryEmail}
                            submitText={t('common.yes')}
                            title={t('groupEmployees.secondaryEmail.confirmsubmission')}
                          >
                            <Box>
                              {t('groupEmployees.secondaryEmail.byClickingYes')}
                            </Box>
                          </Modal>
                        )}

                        <ToolbarButton
                          bg="accentDark"
                          isDisabled={submitting}
                          label={t('common.submit')}
                          mr={0}
                          submitting={submitting}
                          type="submit"
                          width="150px"
                        />
                      </Box>
                    </Box>
                  </form>
                )}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

export default AddSecondaryEmail;
