import { AuthContext } from '@basecomponents/Auth';
import { navigate, useLocation } from '@reach/router';
import Box from '@basecomponents/Box';
import React, { useContext, useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import ReactPlayer from 'react-player';
import config from '@src/config.json';
import BlockDemographicClaimModal from '@petcomponents/BlockDemographicClaim';
import localStorage from '@utils/local-storage';
import { USER_ROLE } from '@utils/constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from '@basecomponents/Button';
import Route from '@petcomponents/Route';
import getEmployeeStatus from '@utils/get-default-employee-status';
import GetData from '@utils/get-data';
import dateUtils from '@utils/date-utils';
import ButtonGroups from '@basecomponents/ButtonGroups';
import ToolbarButton from '@basecomponents/ToolbarButton';
import Spinner from '@basecomponents/Spinner';
import Modal from '@basecomponents/Modal';
import remoteActionQuery from '@queries/remote-action.gql';
import { useApolloClient } from '@apollo/client';
import { Field, Form } from 'react-final-form';
import Dropdown from '@basecomponents/Dropdown';
import { OnChange } from 'react-final-form-listeners';
import useSnackbar from '@src/utilities/use-snackbar';
import Input from '@basecomponents/Input';
import PropTypes from 'prop-types';
import useSsm from '@src/utilities/hooks/useSsm';

/**
 * @category TBD
 * @param {*} rest
 * @returns {React.FC}
 */

function NormalEnrollInInitialTerm({
                                       isInGroupTransitionStatus, yearDate, employeeId,
                                   }) {
    const {t} = useTranslation();
    return (<ToolbarButton
            bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
            buttonSx={{
                borderRadius: config.canadaEnv ? 50 : 1, color: config.canadaEnv ? 'primary' : 'white',
            }}
            isDisabled={isInGroupTransitionStatus}
            label={t('homePage.enrollInBenefitPeriod', {yearDate})}
            onClick={() => navigate(`/group-employees/${employeeId}/enroll`)}
            permission="home.button.addPets"
            // visible={isIntialTermStatusInactive && isEmployeeInOE}
        />);
}

NormalEnrollInInitialTerm.propTypes = {
    employeeId: PropTypes.string.isRequired,
    isInGroupTransitionStatus: PropTypes.bool.isRequired,
    yearDate: PropTypes.string.isRequired,
};

function FileClaimPDBA({
                           missingDemographicsLength, userLoginRootEmployeeId, showClaimsModal, status,
                       }) {
    return (<ToolbarButton
            bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
            buttonSx={{
                borderRadius: config.canadaEnv ? 50 : 1, color: config.canadaEnv ? 'primary' : 'white',
            }}
            label="File a Claim"
            onClick={() => {
                if (missingDemographicsLength > 0) {
                    showClaimsModal();
                } else {
                    navigate(`/claims/create?employeeId=${userLoginRootEmployeeId}`);
                }
            }}
            permission="home.button.fileClaim"
            visible={['enrolled', 'termination', 'terminated', 'resignation', 'non payment',].includes(status)}
        />);
}

FileClaimPDBA.propTypes = {
    status: PropTypes.string.isRequired,
    missingDemographicsLength: PropTypes.number.isRequired,
    showClaimsModal: PropTypes.func.isRequired,
    userLoginRootEmployeeId: PropTypes.string.isRequired,
};

function NormalEnrollRenewalTerm({
                                     renewalYear,
                                     renewalEmployeeId,
                                     isRenewalTermInactive,
                                     isIntialTermStatusInactive,
                                     isRenewalEmployeeInOE,
                                     isRenewalEmployeePresent,
                                 }) {
    const {t} = useTranslation();
    return (<ToolbarButton
            bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
            buttonSx={{
                borderRadius: config.canadaEnv ? 50 : 1, color: config.canadaEnv ? 'primary' : 'white',
            }}
            label={t('homePage.enrollInBenefitPeriodRenewal', {
                renewalYear,
            })}
            onClick={() => navigate(`/group-employees/${renewalEmployeeId}/enroll`)}
            permission="home.button.addPets"
            visible={isRenewalTermInactive && isIntialTermStatusInactive && isRenewalEmployeeInOE && isRenewalEmployeePresent}
        />);
}

NormalEnrollRenewalTerm.propTypes = {
    isIntialTermStatusInactive: PropTypes.bool.isRequired,
    isRenewalEmployeeInOE: PropTypes.bool.isRequired,
    isRenewalEmployeePresent: PropTypes.bool.isRequired,
    isRenewalTermInactive: PropTypes.bool.isRequired,
    renewalEmployeeId: PropTypes.string.isRequired,
    renewalYear: PropTypes.string.isRequired,
};

function AddMorePets({
                         employeeId, groupStatus, isNotPDBA, groupTransitionStatus, isPassiveRenewal, status,
                     }) {
    const {t} = useTranslation();
    const isNotExpiredOrTermedGroup = !['POLICY_EXPIRED', 'TERMINATED'].includes(groupStatus);
    const isEmployeeEnrolledStatus = status === 'enrolled';
    return (<ToolbarButton
            bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
            buttonSx={{
                borderRadius: config.canadaEnv ? 50 : 1, color: config.canadaEnv ? 'primary' : 'white',
            }}
            isDisabled={groupTransitionStatus === 'IN_PROGRESS'}
            label={isPassiveRenewal ? 'ADD MORE PETS TO YOUR CURRENT COVERAGE!' : t('homePage.addMorePets')}
            onClick={() => navigate(`/group-employees/${employeeId}/life-event`)}
            permission="home.button.addMorePets"
            visible={isEmployeeEnrolledStatus && isNotExpiredOrTermedGroup && isNotPDBA}
        />);
}

AddMorePets.propTypes = {
    groupTransitionStatus: PropTypes.string.isRequired,
    employeeId: PropTypes.string.isRequired,
    groupStatus: PropTypes.oneOf(['POLICY_EXPIRED', 'TERMINATED']).isRequired,
    isNotPDBA: PropTypes.bool.isRequired,
    isPassiveRenewal: PropTypes.bool.isRequired,
    status: PropTypes.oneOf(['enrolled']).isRequired,
};

function RenewCoverageActive({
                                 apiData, empId, grpNumber, isPDBA, renewalEmployeeId,
                             }) {
    const coverageEndDate = dateUtils.getUTCDateOnly(get(apiData, 'employeeDates.coverageEndDate'));
    return (<Box
            color={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
            fontSize={4}
            mt={5}
            sx={{
                fontWeight: 400,
                width: ['100% !important', '100% !important', '100% !important', '500px !important', '800px !important',],
            }}
        >
            Your current coverage will end on {coverageEndDate}
            . If you wish to change or continue your coverage in the new policy year,
            please use the link below.
            <ToolbarButton
                bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
                buttonSx={{
                    borderRadius: config.canadaEnv ? 50 : 1,
                    color: config.canadaEnv ? 'primary' : 'white',
                    height: ['250px !important', '250px !important', '250px !important', '100px !important', '100px !important',],
                    width: '100%',
                    padding: 50,
                    fontSize: '20px',
                    margin: 0,
                    marginTop: '1rem',
                }}
                label={<Box
                    sx={{
                        textWrap: 'wrap !important', textTransform: 'capitalize',
                    }}
                >
                    Renew Coverage
                </Box>}
                onClick={() => {
                    if (isPDBA) {
                        return navigate(`/group-employees/petInfo/${grpNumber}/${empId}`);
                    }
                    return navigate(`/group-employees/${renewalEmployeeId}/renewalenroll`);
                }}
                permission="home.button.renewalenroll"
            />
        </Box>);
}

RenewCoverageActive.propTypes = {
    apiData: PropTypes.shape({
        employeeDates: PropTypes.shape({
            coverageEndDate: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    empId: PropTypes.string.isRequired,
    grpNumber: PropTypes.string.isRequired,
    isPDBA: PropTypes.bool.isRequired,
    renewalEmployeeId: PropTypes.string.isRequired,
};

function FileClaimNormal({userLoginRootEmployeeId, status}) {
    const {t} = useTranslation();
    return (<ToolbarButton
            bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
            buttonSx={{
                borderRadius: config.canadaEnv ? 50 : 1, color: config.canadaEnv ? 'primary' : 'white',
            }}
            label={t('homePage.fileAClaim')}
            onClick={() => {
                navigate(`/claims/create?employeeId=${userLoginRootEmployeeId}`);
            }}
            permission="home.button.fileClaim"
            visible={['enrolled', 'termination', 'terminated', 'resignation', 'non payment',].includes(status)}
        />);
}

FileClaimNormal.propTypes = {
    status: PropTypes.oneOf(['enrolled', 'termination', 'terminated', 'resignation', 'non payment',]).isRequired,
    userLoginRootEmployeeId: PropTypes.string.isRequired,
};

function PassiveRenewal({
                            groupData, hasNotDeclinedFutureCoverage, renewalEmployeeId, isPassiveRenewal,
                        }) {
    const coverageToDate = get(groupData, 'plan.planChoices[0].coverageToDate', null);
    const formattedToDate = moment(dateUtils.setOffset2(coverageToDate))?.format('DD/MM/YYYY');
    const {t} = useTranslation();
    const [setSucessSnack] = useSnackbar({color: 'accent'});
    const [setErrorSnack] = useSnackbar({color: 'error'});
    const [submitting, setSubmitting] = useState(false);
    const {user} = useContext(AuthContext);
    const userId = get(user, 'customSystemUserId', '');
    const location = useLocation();
    const [reason, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [coverageModal, setCoverageModal] = useState(false);
    const date = get(groupData, 'renewalGroup.plan.planChoices.[0].enrollmentToDate') ? dateUtils.getLocaleDateOnly(dateUtils.setOffset2(get(groupData, 'renewalGroup.plan.planChoices.[0].enrollmentToDate'))) : 'OE End Date';
    const client = useApolloClient();
    return (<>
            <Box
                color={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
                fontSize={4}
                mt={3}
                sx={{
                    fontWeight: 400,
                    width: ['100% !important', '100% !important', '100% !important', '500px !important', '800px !important',],
                }}
            >
                Your coverage is set to be renewed in the upcoming term for your
                currently enrolled pets and coverage. If you would like to change your
                plan or covered pets please do so before {date} at 11:30pm ET
            </Box>
            <Box
                as="div"
                color={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
                fontSize={4}
                mb={30}
                mr={30}
                mt={30}
                sx={{
                    fontWeight: 400,
                    width: ['100% !important', '100% !important', '100% !important', '500px !important', '800px !important',],
                }}
            >
                {hasNotDeclinedFutureCoverage ? 'If you would like to update this coverage, click here:' : 'If you would like to renew your policy for the upcoming year, click here:'}
                <ToolbarButton
                    bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
                    buttonSx={{
                        borderRadius: config.canadaEnv ? 50 : 1,
                        color: config.canadaEnv ? 'primary' : 'white',
                        height: ['250px !important', '250px !important', '250px !important', '100px !important', '100px !important',],
                        width: '100%',
                        padding: 50,
                        fontSize: '20px',
                        margin: 0,
                    }}
                    label={<Box
                        sx={{
                            textWrap: 'wrap !important', textTransform: 'capitalize',
                        }}
                    >
                        Update Coverage
                    </Box>}
                    onClick={() => navigate(`/group-employees/${renewalEmployeeId}/renewalenroll`)}
                    permission="home.button.renewalenroll"
                />
            </Box>
            {isPassiveRenewal && hasNotDeclinedFutureCoverage && (<Box
                    as="div"
                    color={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
                    fontSize={4}
                    mb={30}
                    mr={30}
                    mt={30}
                    sx={{
                        fontWeight: 400,
                        width: ['100% !important', '100% !important', '100% !important', '500px !important', '800px !important',],
                    }}
                >
                    If you would like to opt out of renewal, click here:
                    <Box
                        sx={{
                            display: 'flex', justifyContent: 'center',
                        }}
                    >
                        <ToolbarButton
                            bg="error"
                            buttonSx={{
                                width: ['50% !important', '50% !important', '50% !important', '250px !important', '400px !important',],
                                fontSize: '20px',
                                margin: 0,
                            }}
                            label={<Box
                                sx={{
                                    textWrap: 'wrap !important', textTransform: 'capitalize',
                                }}
                            >
                                Opt out of Renewal
                            </Box>}
                            onClick={() => setCoverageModal(true)}
                        />
                    </Box>
                    <Modal
                        isOpen={coverageModal}
                        onClose={() => {
                            setCoverageModal(false);
                        }}
                        onSubmit={async () => {
                            setSubmitting(true);
                            return client
                                .query({
                                    fetchPolicy: 'no-cache', query: remoteActionQuery, variables: {
                                        name: 'update-employee', params: JSON.stringify({
                                            declineFutureCoverageOtherReason: otherReason,
                                            declineFutureCoverageReason: reason,
                                            declineFutureCoverage: true,
                                            employeeId: renewalEmployeeId,
                                            userId,
                                        }),
                                    },
                                })
                                .then(() => setSucessSnack('Declined Coverage Successfully'))
                                .catch(() => setErrorSnack('Error: Something went wrong'))
                                .finally(() => {
                                    setSubmitting(false);
                                    setCoverageModal(false);
                                    return navigate(location.pathname);
                                });
                        }}
                        shouldCloseOnOverlayClick
                        submitText={t('action.decline')}
                        submitting={submitting}
                        title="Decline to Renew Coverage"
                    >
                        <Box as="h4" mb={5} py={3}>
                            You are about to decline coverage for the upcoming plan year. By
                            proceeding, your current coverage will end on {formattedToDate}.
                        </Box>
                        <Form
                            onSubmit={() => {
                            }}
                            render={({values}) => {
                                return (<>
                                        <Field
                                            component={Dropdown}
                                            isSearchable={false}
                                            label="Please Select your reason for discontinuing coverage:"
                                            name="reasonToDecline"
                                            options={[{
                                                label: t('reason.petPassedAway'), value: 'My pet passed away',
                                            }, {
                                                label: t('reason.noLongerOwn'), value: 'No longer own this pet',
                                            }, {
                                                label: t('reason.unsatisfactory'),
                                                value: 'Unsatisfactory customer / claims experience',
                                            }, {
                                                label: t('reason.switched'), value: 'Switched to another provider',
                                            }, {
                                                label: t('reason.cost'), value: 'Cost',
                                            }, {
                                                label: t('reason.notFindValue'),
                                                value: 'Did not use or find value in the insurance',
                                            }, {
                                                label: t('reason.other'), value: 'Other',
                                            },]}
                                        />
                                        {values.reasonToDecline === 'Other' && (<Field
                                                component={Input}
                                                label="Please share your other reason for declining coverage:"
                                                name="otherReason"
                                            />)}
                                        <OnChange name="reasonToDecline">
                                            {(reason) => {
                                                setReason(reason);
                                                if (reason !== 'Other') {
                                                    setOtherReason('');
                                                }
                                            }}
                                        </OnChange>
                                        <OnChange name="otherReason">
                                            {(otherReason) => {
                                                setOtherReason(otherReason);
                                            }}
                                        </OnChange>
                                    </>);
                            }}
                        />
                    </Modal>
                </Box>)}
        </>);
}

PassiveRenewal.propTypes = {
    groupData: PropTypes.object.isRequired,
    hasNotDeclinedFutureCoverage: PropTypes.bool.isRequired,
    isPassiveRenewal: PropTypes.bool.isRequired,
    renewalEmployeeId: PropTypes.string.isRequired,
};

function CompletePetRegistration({
                                     grpNumber, empId, apiData, employeeId, missingDemographics,
                                 }) {
    const {t} = useTranslation();
    return (<ToolbarButton
            bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
            buttonSx={{
                borderRadius: config.canadaEnv ? 50 : 1,
                color: config.canadaEnv ? 'primary' : 'white',
                fontSize: '20px',
                marginLeft: 0,
                marginTop: '20px',
                padding: '3rem',
                whiteSpace: 'pre',
            }}
            label={Array.isArray(missingDemographics) && missingDemographics.filter((o) => o.hasDemographicChanges).length > 1 ? t('groupEmployees.detailsPage.renewPolicy') : t('homePage.completePetRegistration')}
            onClick={() => {
                if (Array.isArray(missingDemographics) && missingDemographics.filter((o) => o.hasDemographicChanges).length > 1 && !get(apiData, 'isPetSwapPerformed', false)) {
                    navigate(`/group-employees/petInfo/${grpNumber}/${empId}`);
                } else {
                    localStorage.setItem(`employeeDependentData_${employeeId}`, JSON.stringify([]));
                    navigate(`/group-employees/${employeeId}/petInfo`);
                }
            }}
        />);
}

CompletePetRegistration.propTypes = {
    apiData: PropTypes.object.isRequired,
    empId: PropTypes.string.isRequired,
    employeeId: PropTypes.string.isRequired,
    grpNumber: PropTypes.string.isRequired,
    missingDemographics: PropTypes.object.isRequired,
};

function EmployeeScreenHandler({
                                   isPetSwapPerformed,
                                   groupData,
                                   hasNotDeclinedFutureCoverage,
                                   apiData,
                                   eitherTermNotInactive,
                                   isInGroupTransitionStatus,
                                   isIntialTermInactive,
                                   isNotPDBA,
                                   isPassiveRenewal,
                                   isPDBA,
                                   isPdbaTransition,
                                   isRenewalEmployeeInOE,
                                   isRenewalEmployeePresent,
                                   isRenewalTermInactive,
                                   missingDemographics,
                                   showClaimsModal,
                                   renewalYear,
                                   yearDate,
                               }) {
    const missingDemographicsLength = Array.isArray(missingDemographics) ? missingDemographics.filter((o) => o.hasDemographicChanges).length : 0;
    const {user} = useContext(AuthContext);
    const role = get(user, 'customRole', '');
    const isEmployeeRole = [USER_ROLE.EMPLOYEE, USER_ROLE.TERMINATED_EMPLOYEE, USER_ROLE.RENEWAL_POLICY_DELETED,].includes(role);
    const {
        empId, employeeId, // employeePlanConfirmation,
        groupNumber: grpNumber, groupStatus, isCurrentUserActive, renewalEmployeeId, status, userLoginRootEmployeeId,
    } = getEmployeeStatus(get(user, 'customSystemUserId', ''), role);
    const {t} = useTranslation();
    if (isEmployeeRole) {
        if (isPdbaTransition) {
            if (isIntialTermInactive) {
                if (missingDemographicsLength > 0) {
                    return (<>
                            <Box as="h3" sx={{mt: 5}}>
                                {t('homePage.usefulLinks')}
                            </Box>
                            <ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                                <NormalEnrollInInitialTerm
                                    employeeId={employeeId}
                                    isInGroupTransitionStatus={isInGroupTransitionStatus}
                                    yearDate={yearDate}
                                />
                            </ButtonGroups>
                            <CompletePetRegistration
                                apiData={apiData}
                                empId={empId}
                                employeeId={employeeId}
                                grpNumber={grpNumber}
                                missingDemographics={missingDemographics}
                            />
                        </>);
                }
                return (<>
                        <Box as="h3" sx={{mt: 5}}>
                            {t('homePage.usefulLinks')}
                        </Box>
                        <ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                            <NormalEnrollInInitialTerm
                                employeeId={employeeId}
                                isInGroupTransitionStatus={isInGroupTransitionStatus}
                                yearDate={yearDate}
                            />
                        </ButtonGroups>
                    </>);
            }

            if (missingDemographicsLength > 0) {
                return (<>
                        {isCurrentUserActive
                            // && employeePlanConfirmation !== null &&
                            // employeePlanConfirmation?.totalPrice > 0
                            && (<>
                                    <Box as="h3" sx={{mt: 5}}>
                                        {t('homePage.usefulLinks')}
                                    </Box>
                                    <ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                                        <AddMorePets
                                            employeeId={employeeId}
                                            groupStatus={groupStatus}
                                            isNotPDBA={isNotPDBA}
                                            isPassiveRenewal={isPassiveRenewal}
                                            status={status}
                                        />
                                        <FileClaimPDBA
                                            missingDemographicsLength={missingDemographicsLength}
                                            showClaimsModal={showClaimsModal}
                                            status={status}
                                            userLoginRootEmployeeId={userLoginRootEmployeeId}
                                        />
                                    </ButtonGroups>
                                </>)}
                        <CompletePetRegistration
                            apiData={apiData}
                            empId={empId}
                            employeeId={employeeId}
                            grpNumber={grpNumber}
                            missingDemographics={missingDemographics}
                        />
                    </>);
            }
            if (isCurrentUserActive
                // && employeePlanConfirmation !== null &&
                // employeePlanConfirmation?.totalPrice > 0
            ) {
                return (<>
                        <Box as="h3" sx={{mt: 5}}>
                            {t('homePage.usefulLinks')}
                        </Box>
                        <ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                            <AddMorePets
                                employeeId={employeeId}
                                groupStatus={groupStatus}
                                isNotPDBA={isNotPDBA}
                                isPassiveRenewal={isPassiveRenewal}
                                status={status}
                            />
                            <FileClaimPDBA
                                missingDemographicsLength={missingDemographicsLength}
                                showClaimsModal={showClaimsModal}
                                status={status}
                                userLoginRootEmployeeId={userLoginRootEmployeeId}
                            />
                        </ButtonGroups>
                    </>);
            }
        }
        if (isPDBA) {
            if (eitherTermNotInactive) {
                if (missingDemographicsLength > 0 || !isPetSwapPerformed) {
                    return (<>
                            {isCurrentUserActive
                                // && employeePlanConfirmation !== null &&
                                // employeePlanConfirmation?.totalPrice > 0
                                && (<>
                                        <Box as="h3" sx={{mt: 5}}>
                                            {t('homePage.usefulLinks')}
                                        </Box>
                                        <ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                                            <FileClaimPDBA
                                                missingDemographicsLength={missingDemographicsLength}
                                                showClaimsModal={showClaimsModal}
                                                status={status}
                                                userLoginRootEmployeeId={userLoginRootEmployeeId}
                                            />
                                        </ButtonGroups>
                                    </>)}
                            {!isPetSwapPerformed && apiData?.hasDemographicChanges && (apiData?.parentEmployee !== null || apiData?.renewalEmployee !== null) && (
                                <CompletePetRegistration
                                    apiData={apiData}
                                    empId={empId}
                                    employeeId={employeeId}
                                    grpNumber={grpNumber}
                                    missingDemographics={missingDemographics}
                                />)}
                        </>);
                }
                if (isCurrentUserActive
                    // && employeePlanConfirmation !== null &&
                    // employeePlanConfirmation?.totalPrice > 0
                ) {
                    return (<>
                            <Box as="h3" sx={{mt: 5}}>
                                {t('homePage.usefulLinks')}
                            </Box>
                            <ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                                <FileClaimPDBA
                                    missingDemographicsLength={missingDemographicsLength}
                                    showClaimsModal={showClaimsModal}
                                    status={status}
                                    userLoginRootEmployeeId={userLoginRootEmployeeId}
                                />
                            </ButtonGroups>
                        </>);
                }
            }
        }
        if (isNotPDBA) {
            if (isIntialTermInactive) {
                if (isRenewalTermInactive) {
                    return (<>
                            <Box as="h3" sx={{mt: 5}}>
                                {t('homePage.usefulLinks')}
                            </Box>
                            <ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                                <NormalEnrollInInitialTerm
                                    employeeId={employeeId}
                                    isInGroupTransitionStatus={isInGroupTransitionStatus}
                                    yearDate={yearDate}
                                />
                                <NormalEnrollRenewalTerm
                                    isIntialTermStatusInactive={isIntialTermInactive}
                                    isRenewalEmployeeInOE={isRenewalEmployeeInOE}
                                    isRenewalEmployeePresent={isRenewalEmployeePresent}
                                    renewalEmployeeId={renewalEmployeeId}
                                    renewalYear={renewalYear}
                                />
                            </ButtonGroups>
                        </>);
                }
                return (<>
                        <Box as="h3" sx={{mt: 5}}>
                            {t('homePage.usefulLinks')}
                        </Box>
                        <ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                            <NormalEnrollInInitialTerm
                                employeeId={employeeId}
                                isInGroupTransitionStatus={isInGroupTransitionStatus}
                                yearDate={yearDate}
                            />
                        </ButtonGroups>
                    </>);
            }
            if (isRenewalTermInactive) {
                if (isPassiveRenewal) {
                    return (<>
                            <PassiveRenewal
                                groupData={groupData}
                                hasNotDeclinedFutureCoverage={hasNotDeclinedFutureCoverage}
                                isPassiveRenewal={isPassiveRenewal}
                                renewalEmployeeId={renewalEmployeeId}
                            />
                            {isCurrentUserActive
                                // && employeePlanConfirmation !== null &&
                                // employeePlanConfirmation?.totalPrice > 0
                                && (<>
                                        <Box as="h3" sx={{mt: 5}}>
                                            {t('homePage.usefulLinks')}
                                        </Box>
                                        <ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                                            <AddMorePets
                                                employeeId={employeeId}
                                                groupStatus={groupStatus}
                                                isNotPDBA={isNotPDBA}
                                                isPassiveRenewal={isPassiveRenewal}
                                                status={status}
                                            />
                                            <FileClaimNormal
                                                status={status}
                                                userLoginRootEmployeeId={userLoginRootEmployeeId}
                                            />
                                        </ButtonGroups>
                                    </>)}
                        </>);
                }
                return (<>
                        <RenewCoverageActive
                            apiData={apiData}
                            empId={empId}
                            grpNumber={grpNumber}
                            renewalEmployeeId={renewalEmployeeId}
                        />
                        {isCurrentUserActive
                            // && employeePlanConfirmation !== null &&
                            // employeePlanConfirmation?.totalPrice > 0
                            && (<>
                                    <Box as="h3" sx={{mt: 5}}>
                                        {t('homePage.usefulLinks')}
                                    </Box>
                                    <ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                                        <AddMorePets
                                            employeeId={employeeId}
                                            groupStatus={groupStatus}
                                            isNotPDBA={isNotPDBA}
                                            isPassiveRenewal={isPassiveRenewal}
                                            status={status}
                                        />
                                        <FileClaimNormal
                                            status={status}
                                            userLoginRootEmployeeId={userLoginRootEmployeeId}
                                        />
                                    </ButtonGroups>
                                </>)}
                    </>);
            }
            if (isCurrentUserActive
                // && employeePlanConfirmation !== null &&
                // employeePlanConfirmation?.totalPrice > 0
            ) {
                return (<>
                        <Box as="h3" sx={{mt: 5}}>
                            {t('homePage.usefulLinks')}
                        </Box>
                        <ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                            <AddMorePets
                                employeeId={employeeId}
                                groupStatus={groupStatus}
                                isNotPDBA={isNotPDBA}
                                isPassiveRenewal={isPassiveRenewal}
                                status={status}
                            />
                            <FileClaimNormal
                                status={status}
                                userLoginRootEmployeeId={userLoginRootEmployeeId}
                            />
                        </ButtonGroups>
                    </>);
            }
        }
    }
}

EmployeeScreenHandler.propTypes = {
    isPetSwapPerformed: PropTypes.bool.isRequired,
    apiData: PropTypes.object.isRequired,
    eitherTermNotInactive: PropTypes.bool.isRequired,
    groupData: PropTypes.object.isRequired,
    hasNotDeclinedFutureCoverage: PropTypes.bool.isRequired,
    isInGroupTransitionStatus: PropTypes.bool.isRequired,
    isIntialTermInactive: PropTypes.bool.isRequired,
    isNotPDBA: PropTypes.bool.isRequired,
    isPassiveRenewal: PropTypes.bool.isRequired,
    isPDBA: PropTypes.bool.isRequired,
    isPdbaTransition: PropTypes.bool.isRequired,
    isRenewalEmployeeInOE: PropTypes.bool.isRequired,
    isRenewalEmployeePresent: PropTypes.bool.isRequired,
    isRenewalTermInactive: PropTypes.bool.isRequired,
    missingDemographics: PropTypes.array.isRequired,
    renewalYear: PropTypes.string.isRequired,
    showClaimsModal: PropTypes.func.isRequired,
    yearDate: PropTypes.string.isRequired,
};

function HomePage({...rest}) {
    const {t} = useTranslation();
    const {isResponsive} = rest;
    const {user} = useContext(AuthContext);

    // disable all buttons if renewal status is any of the following
    const enrollmentButtonsDisableStatus = ['PENDING', 'ACTIVE', 'ENROLLED', 'FAILED_TO_ENROLL',];

    const role = get(user, 'customRole', '');
    const {
        employeeId, status, groupStatus, renewalEmployeeId, enrollmentStartDate, empId, groupNumber: grpNumber,
    } = getEmployeeStatus(get(user, 'customSystemUserId', ''), role);
    const groupMgaName = get(user, 'customMga', '').toLowerCase();
    const [claimsModalBlocked, setClaimsModal] = useState(false);

    function showClaimsModal() {
        setClaimsModal(true);
    }

    function closeClaimsModal() {
        setClaimsModal(false);
    }

    const isEmployeeRole = [USER_ROLE.EMPLOYEE, USER_ROLE.TERMINATED_EMPLOYEE, USER_ROLE.RENEWAL_POLICY_DELETED,].includes(role);
    const {
        apiData = {},
        loading
    } = GetData('get-group-employee-by-id', JSON.stringify({id: employeeId}), !isEmployeeRole || employeeId === null);
    const {
        apiData: renewalEmployeeData,
        loading: renewalEmployeeLoading
    } = GetData('get-group-employee-by-id', JSON.stringify({id: renewalEmployeeId}), !isEmployeeRole || renewalEmployeeId === null);
    const {
        apiData: groupData,
        loading: groupLoading
    } = GetData('get-group-by-id', JSON.stringify({id: get(apiData, 'groupId')}), !get(apiData, 'groupId'));
    const renewalGroupId = apiData?.renewalGroup?.groupId;
    const {
        apiData: renewalGroupData,
        loading: renewalGroupLoading
    } = GetData('get-group-by-id', JSON.stringify({id: renewalGroupId}), renewalGroupId == null);
    const isCurrentGroupRenewed = get(apiData, 'currentGroup.renewalStatus') === 'RENEWED';

    const isRenewalGroupNotRenewed = get(apiData, 'renewalGroup.renewalStatus') === null;

    const isRenewalEmployeeNotEnrolled = renewalEmployeeData?.status !== 'ENROLLED';
    const isNotPDBA = groupData?.postEnrollmentGroup === false;
    const isPDBA = groupData?.postEnrollmentGroup === true;
    const isPdbaTransition = groupData?.postEnrollmentGroup === false && renewalGroupData?.postEnrollmentGroup === true;
    const isIaicToIaicRenewal = groupData?.postEnrollmentGroup === false && renewalGroupData?.postEnrollmentGroup === false;
    const isPdbaToPdbaRenewal = groupData?.postEnrollmentGroup === true && renewalGroupData?.postEnrollmentGroup === true;
    const isEmployeeActive = apiData?.currentGroup?.isActiveGroup;
    const isGroupActive = apiData?.currentGroup?.groupStatus === 'ACTIVE';

    const isPassiveRenewal = Boolean(groupData?.renewalGroup?.passiveRenewal) === true;
    const currentDate = get(apiData, 'relativeDatetime') === null ? new Date() : dateUtils.setOffset2(get(apiData, 'relativeDatetime'));
    const OEStartDate = groupData?.renewalGroup?.plan?.planChoices?.[0]?.enrollmentFromDate ? dateUtils.setOffset2(groupData?.renewalGroup?.plan?.planChoices?.[0]?.enrollmentFromDate) : null;
    const OEEndDate = groupData?.renewalGroup?.plan?.planChoices?.[0]?.enrollmentToDate ? dateUtils.setOffset2(groupData?.renewalGroup?.plan?.planChoices?.[0]?.enrollmentToDate) : null;
    let isBetweenOEPeriod = false;
    if (OEStartDate && OEEndDate) {
        isBetweenOEPeriod = currentDate >= OEStartDate && currentDate <= OEEndDate;
    }
    const isEmployeeEnrolled = apiData?.status === 'ENROLLED';
    const hasNotDeclinedFutureCoverage = renewalEmployeeData?.declineFutureCoverage === false;
    const enableDeclineCoverageButton = isNotPDBA && isPassiveRenewal && isBetweenOEPeriod && isEmployeeEnrolled && hasNotDeclinedFutureCoverage && enrollmentButtonsDisableStatus.includes(renewalEmployeeData?.status) === false;

    const [isPetDemographicButtonEnabled, setDemographicsButtonEnabled] = useState(false);
  const postEnrollmentConfigEnabled = useSsm('iaic-post-enrollment')
    useEffect(() => {
        const hasPetBeenNotAdded = get(apiData, 'hasDemographicChanges', false);
        const employeeEnrolled = ['ENROLLED'].includes(get(apiData, 'status', ''));
        setDemographicsButtonEnabled(isEmployeeRole && postEnrollmentConfigEnabled && hasPetBeenNotAdded && employeeEnrolled);
    }, [loading, apiData, isEmployeeRole]);

    const {apiData: mediaData = {}, loading: isMediaLoading} = GetData('get-files', JSON.stringify({
        groupId: get(apiData, 'groupId'), isPromoFlag: true, page: 0, size: 10, sort: 'created,desc',
    }), ![USER_ROLE.EMPLOYER, USER_ROLE.EMPLOYEE].includes(role) || !get(apiData, 'groupId'));
    const {
        apiData: missingDemographics = [], loading: missingDemographicsLoading,
    } = GetData('get-all-missing-demographics', JSON.stringify({
        empId, groupNumber: grpNumber,
    }), isEmpty(grpNumber) || isEmpty(empId));

    const missingDemographicsLength = Array.isArray(missingDemographics) ? missingDemographics.filter((o) => o.hasDemographicChanges).length : 0;
    const missingOneTerm = missingDemographicsLength === 1;
    const missingMultipleTerm = missingDemographicsLength > 1;
    const isPetSwapPerformed = apiData?.isPetSwapPerformed === true;
    const coverageFromDate = get(groupData, 'plan.planChoices[0].coverageFromDate', null);
    const coverageToDate = get(groupData, 'plan.planChoices[0].coverageToDate', null);

    let yearDate = null;

    if (coverageFromDate && coverageToDate) {
        const formattedFromDate = moment(dateUtils.setOffset2(coverageFromDate));
        const formattedToDate = moment(dateUtils.setOffset2(coverageToDate));
        if (config.canadaEnv) {
            yearDate = `${formattedFromDate.format('DD/MM/YYYY')} - ${formattedToDate.format('DD/MM/YYYY')}`;
        } else {
            yearDate = `${formattedFromDate.format('L')} - ${formattedToDate.format('L')}`;
        }
    }

    const coverageFromDateRenewal = get(groupData, 'renewalGroup.plan.planChoices[0].coverageFromDate', null);
    const coverageToDateRenewal = get(groupData, 'renewalGroup.plan.planChoices[0].coverageToDate', null);

    let renewalYear = null;

    if (coverageFromDateRenewal && coverageToDateRenewal) {
        const formattedFromDateRenewal = moment(dateUtils.setOffset2(coverageFromDateRenewal));
        const formattedToDateRenewal = moment(dateUtils.setOffset2(coverageToDateRenewal));
        if (config.canadaEnv) {
            renewalYear = `${formattedFromDateRenewal.format('DD/MM/YYYY')} - ${formattedToDateRenewal.format('DD/MM/YYYY')}`;
        } else {
            renewalYear = `${formattedFromDateRenewal.format('L')} - ${formattedToDateRenewal.format('L')}`;
        }
    }

    const renewalEnrollmentDate = get(apiData, 'renewalEmployee.employeeDates.enrollmentStartDate') === null ? new Date() : dateUtils.setOffset2(get(apiData, 'renewalEmployee.employeeDates.enrollmentStartDate'));
    const enrollmentDate = dateUtils.setOffset2(enrollmentStartDate);

    const ifTheEmployeeIsTerminatedInCurrentGroup = (data) => {
        return ['TERMINATED'].includes(get(data, 'employmentStatus', ''));
    };

    const ifTheEmployeeIsNotEligibleForRenewal = () => {
        const newRenewalEmployee = isEmpty(get(apiData, 'parentEmployee')) && get(apiData, 'currentGroup.renewalStatus') !== 'RENEWED';
        return (ifTheEmployeeIsTerminatedInCurrentGroup(apiData) || ifTheEmployeeIsTerminatedInCurrentGroup(get(apiData, 'renewalEmployee')) || ['ENROLLED'].includes(get(apiData, 'renewalEmployee.status')) || !['ENROLLED'].includes(get(apiData, 'status')) || newRenewalEmployee || ['POLICY_EXPIRED', 'TERMINATED'].includes(get(apiData, 'currentGroup.groupStatus')));
    };

    const mediaDataLength = get(mediaData, 'content', []).length;
    const isEmbedLink = get(mediaData, `content[0].isEmbedLink`);
    const source = isEmbedLink ? get(mediaData, `content[0].s3Key`) : get(mediaData, `content[0].signedUrl`);

    const initialTermStatus = apiData?.status;
    const renewalTermStatus = apiData?.renewalEmployee?.status;
    const isIntialTermInactive = initialTermStatus === 'INACTIVE';
    const isEmployeeInOE = enrollmentDate <= currentDate;
    const isRenewalEmployeeInOE = renewalEnrollmentDate <= currentDate;
    // const isInitialEmpInactive = isIntialTermStatusInactive && isEmployeeInOE
    const isRenewalTermInactive = renewalTermStatus === 'INACTIVE';
    const isRenewalEmployeePresent = isEmpty(apiData?.renewalEmployee);
    const eitherIsNotInactive = !isIntialTermInactive || !isRenewalTermInactive;
    // const isRenewalEmpInactive = isRenewalEmployeeInactive && isIntialTermStatusInactive && isRenewalEmployeeInOE && isRenewalEmployeePresent
    const isGroupInTransitionStatus = apiData?.groupTransitionStatus === 'IN_PROGRESS';
    if (loading || isMediaLoading || missingDemographicsLoading || groupLoading || renewalEmployeeLoading || renewalGroupLoading) {
        return <Spinner/>;
    }
    return (<Route
            header={{
                icon: 'user-filled', title: 'Home', type: 'Home', typeLabel: 'Dashboard',
            }}
            isPrivate
            {...rest}
        >
            <Box
                display={{_: 'block', md: 'flex'}}
                sx={{
                    alignItems: mediaDataLength > 0 && !config.canadaEnv ? 'center' : 'flex-start',
                    display: mediaDataLength > 0 && !config.canadaEnv ? 'block' : 'flex',
                    height: '83vh',
                    justifyContent: mediaDataLength > 0 && !config.canadaEnv ? 'space-around' : 'flex-start',
                }}
            >
                <Box>
                    <Box as="h2">{t('homePage.welcome')}</Box>
                    <Box as="h1">{user.name}</Box>
                    <Box as="h4">
                        {isResponsive ? t('homePage.menuIconLegend') : t('homePage.sideBarLegend')}
                    </Box>
                    {['IN_PROGRESS'].includes(get(apiData, 'groupTransitionStatus')) && (<Box
                            as="h4"
                            sx={{
                                color: 'error', margin: '10px 0px 0px 5px;', wordWrap: 'break-word',
                            }}
                        >
                            {t('homePage.groupUnderMaintenance')}
                        </Box>)}
                    <>
                        {config?.canadaEnv && config?.cookieLawKey && (<>
                                <Button
                                    bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
                                    className="ot-sdk-show-settings"
                                    id="ot-sdk-btn"
                                    simple
                                    sx={{
                                        '&:hover': {
                                            bg: 'accentLight', boxShadow: 5, transition: '0.5s ease-in-out',
                                        },
                                        bg: 'accent',
                                        borderColor: 'transparent',
                                        borderRadius: 50,
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                        boxShadow: 1,
                                        color: 'primary',
                                        fontSize: 2,
                                        fontWeight: 'bold',
                                        height: 'heights.input',
                                        letterSpacing: 0,
                                        px: 7,
                                        py: 4,
                                        textTransform: 'uppercase',
                                        transition: 'background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.3s ease-in-out',
                                    }}
                                >
                                    CookieSettings
                                </Button>
                                <Box id="ot-sdk-cookie-policy"/>
                            </>)}

                        <EmployeeScreenHandler
                            apiData={apiData}
                            eitherTermNotInactive={eitherIsNotInactive}
                            groupData={groupData}
                            hasNotDeclinedFutureCoverage={hasNotDeclinedFutureCoverage}
                            isInGroupTransitionStatus={isGroupInTransitionStatus}
                            isIntialTermInactive={isIntialTermInactive}
                            isNotPDBA={isNotPDBA}
                            isPassiveRenewal={isPassiveRenewal}
                            isPDBA={isPDBA}
                            isPdbaTransition={isPdbaTransition}
                            isPetSwapPerformed={isPetSwapPerformed}
                            isRenewalEmployeeInOE={isRenewalEmployeeInOE}
                            isRenewalEmployeePresent={isRenewalEmployeePresent}
                            isRenewalTermInactive={isRenewalTermInactive}
                            missingDemographics={missingDemographics}
                            renewalYear={renewalYear}
                            showClaimsModal={() => showClaimsModal()}
                            yearDate={yearDate}
                        />
                        {groupMgaName && (<>
                                {/* {(((() => renewalEnrollmentDate <= currentDate)() &&
                                    !ifTheEmployeeIsNotEligibleForRenewal()) ||
                                    (['INACTIVE'].includes(get(apiData, 'status')) &&
                                        (() => enrollmentDate <= currentDate)()) ||
                                    (['INACTIVE'].includes(
                                        get(apiData, 'renewalEmployee.status')
                                    ) &&
                                        ['INACTIVE'].includes(get(apiData, 'status')) &&
                                        (() => renewalEnrollmentDate <= currentDate)() &&
                                        !isEmpty(get(apiData, 'renewalEmployee'))) ||
                                    (['enrolled', 'terminated'].includes(status) &&
                                        !['POLICY_EXPIRED', 'TERMINATED'].includes(groupStatus)) ||
                                    ['active', 'failed to enroll', 'pending'].includes(status) ||
                                    role === USER_ROLE.EMPLOYER) && (
                                        <Box as="h3" sx={{ mt: 5 }}>
                                            {t('homePage.usefulLinks')}
                                        </Box>
                                    )} */}

                                {isIaicToIaicRenewal && (<ButtonGroups leftAlign mb={30} mt={8} wrap="wrap">
                                        {employeeId && !isPetDemographicButtonEnabled && (<>
                                                <ToolbarButton
                                                    bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
                                                    buttonSx={{
                                                        borderRadius: config.canadaEnv ? 50 : 1,
                                                        color: config.canadaEnv ? 'primary' : 'white',
                                                    }}
                                                    label={t('homePage.checkEnrollmentStatus')}
                                                    onClick={() => role === 'employee' ? navigate(`/group-employees/defaultEnrollee`) : navigate(`/group-employees/${employeeId}/details`)}
                                                    permission="home.button.enrollStatus"
                                                    visible={['active', 'failed to enroll', 'pending',].includes(status)}
                                                />
                                            </>)}
                                        <ToolbarButton
                                            bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
                                            buttonSx={{
                                                borderRadius: config.canadaEnv ? 50 : 1,
                                                color: config.canadaEnv ? 'primary' : 'white',
                                            }}
                                            label={t('homePage.manageEmployees')}
                                            onClick={() => navigate(`/groups/defaultGroup`)}
                                            permission="home.button.manageEmployees"
                                        />
                                        {groupMgaName === 'ppi' && (<ToolbarButton
                                                bg={config.canadaEnv ? 'accentSecondary' : 'accentDark'}
                                                buttonSx={{
                                                    borderRadius: config.canadaEnv ? 50 : 1,
                                                    color: config.canadaEnv ? 'primary' : 'white',
                                                }}
                                                label={t('homePage.fileAClaimForEmployee')}
                                                onClick={() => navigate('/claims/dashboard')}
                                                permission="home.button.claimForEmployees"
                                            />)}
                                    </ButtonGroups>)}
                            </>)}
                    </>
                </Box>
                {mediaDataLength > 0 && !config.canadaEnv && (
                    <Box height={{md: '360px'}} width={{_: '100%', md: '640px'}}>
                        <ReactPlayer
                            config={{
                                youtube: {
                                    autoplay: 1,
                                },
                            }}
                            controls
                            height="100%"
                            muted
                            pip
                            playing
                            playsinline
                            url={source}
                            volume={1}
                            width="100%"
                        />
                    </Box>)}
            </Box>
            <BlockDemographicClaimModal
                empId={empId}
                employeeId={employeeId}
                groupNumber={grpNumber}
                isOpen={claimsModalBlocked}
                onClose={() => closeClaimsModal()}
            />
        </Route>);
}

export default HomePage;
