import { get } from 'lodash';
import React from 'react';
import Box from '@basecomponents/Box';
import classNamesTable from './Labels/class-names-table.json';
import commissionsDashboard from './Labels/commissions-dashboard-table.json';
import employeeCensus from './Labels/employee-census-table.json';
import enrolleeDashboardPage from './Labels/enrollee-dashboard-table.json';
import groupContactsBrokerTable from './Labels/group-contacts-broker-table.json';
import groupContactsTable from './Labels/group-contacts-table.json';
import groupBillingInformationTable from './Labels/group-billing-information-table.json';

export const labelUtils = ({ key, type }) => {
  let labels = null;
  switch (type) {
    case 'commissionsDashboard':
      labels = commissionsDashboard;
      break;
    case 'configureClassesSection':
      labels = classNamesTable;
      break;
    case 'employeeCensus':
      labels = employeeCensus;
      break;
    case 'enrolleeDashboardPage':
      labels = enrolleeDashboardPage;
      break;
    case 'groupContactsSection':
      labels = groupContactsTable;
      break;
    case 'groupContactsBrokerSection':
      labels = groupContactsBrokerTable;
      break;
    case 'groupBillingInformationSection':
      labels = groupBillingInformationTable;
      break;

    default:
      labels = {};
  }
  const label = get(labels, key, '');
  if (label !== '') {
    return label;
  }
  return `${key}`;
};

export const labelHandler = (label, isRequired) => {
  if (isRequired) {
    return (
      <Box display="flex" flexDirection="row">
        {label}
        <Box color="#B30000">*</Box>
      </Box>
    );
  }
  return label;
};
