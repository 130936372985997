import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { get } from 'lodash';
import { ApolloConsumer, useApolloClient } from '@apollo/client';
import GetData from "@utils/get-data";
import Spinner from "@basecomponents/Spinner";
import Dropdown from '@basecomponents/Dropdown';
import { required } from '@src/utilities/validators';
import { useTranslation } from 'react-i18next';
import ToolbarButton from '../../../BaseComponents/ToolbarButton';
import UploadFile from '../../UploadFile';
import useSnackbar from '../../../../utilities/use-snackbar';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql';
import generateRedirectPath from '../../../../utilities/generate-redirect-path';
import config from '../../../../config.json';

/**
 * @category ProductAdmin
 * @param {Object} location
 * @param {string} name
 * @param {*} rest
 * @returns {React.FC}
 */
const RatingTable = ({ location, name, isRenewal, ...rest }) => {
  const { t } = useTranslation();
  // const [datasetId, setDatasetId] = useState(null);
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const parameters = useParams();
  const groupId = get(parameters, 'groupId', '');
  const [secondPass, setSecondPass] = useState(null);
  const client = useApolloClient();
  const { apiData = {}, loading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
  );
  const {
    apiData: { BillingCycle = [] },
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: 'BillingCycle' }]),
    !groupId
  );

  useEffect(() => {
    const fetchPlanRateInterval = setInterval(() => {
      if (secondPass) {
        client
          .query({
            fetchPolicy: 'no-cache',
            query: remoteActionQuery,
            variables: {
              name: 'get-plan-rates',
              params: JSON.stringify({
                groupId,
              }),
            },
          })
          .then((response) => {
            const { content } = JSON.parse(response.data.remoteAction.data);
            if (content.length > 0) {
              return navigate(
                generateRedirectPath({
                  ...location,
                  queryParams: { [name]: isRenewal ? 11 : 5 },
                })
              );
            }
            setSecondPass(false);
            return setErrorSnack(
              `There was an error please check the file`,
              config.notificationDuration
            );
          });
      }
    }, 5000);
    return () => clearInterval(fetchPlanRateInterval);
  }, [secondPass]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <ApolloConsumer>
      {(client) => {
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={{}}
              onSubmit={() => {
                if (get(apiData, 'noOfEmployees', 0) > 0) {
                  return navigate(
                    generateRedirectPath({
                      ...location,
                      queryParams: { [name]: isRenewal ? 11 : 5 },
                    })
                  );
                }
                client
                  .query({
                    fetchPolicy: 'no-cache',
                    query: remoteActionQuery,
                    variables: {
                      // name: config.barrelS3Bucket
                      //   ? 'barrel-file-detail'
                      //   : 'get-tasks',
                      name: 'get-tasks',
                      params: JSON.stringify({
                        flow: 'rating',
                        groupId,
                        page: 0,
                        size: 1,
                        sort: 'created,desc',
                      }),
                      // params: config.barrelS3Bucket
                      //   ? JSON.stringify({
                      //       datasetId,
                      //       page: 0,
                      //       size: 1,
                      //       sort: 'created,desc',
                      //     })
                      //   : JSON.stringify({
                      //       flow: 'rating',
                      //       groupId,
                      //       page: 0,
                      //       size: 1,
                      //       sort: 'created,desc',
                      //     }),
                    },
                  })
                  .then((response) => {
                    // const task = config.barrelS3Bucket
                    //   ? JSON.parse(response.data.remoteAction.data)
                    //   : JSON.parse(response.data.remoteAction.data).content[0];
                    const task = JSON.parse(response.data.remoteAction.data)
                      .content[0];
                    if (
                      task.complete ||
                      (task.status === 'pipeline-completed' &&
                        task?.errorCount === 0)
                    ) {
                      if (task.status === 'file-error') {
                        return setErrorSnack(
                          `There was an error please check the file`,
                          config.notificationDuration
                        );
                      }
                      // trigger useEffect
                      return setSecondPass(true);
                    }
                    if (task?.errorCount > 0) {
                      return setErrorSnack(
                        `There was an error please check the file`,
                        config.notificationDuration
                      );
                    }
                    setErrorSnack(
                      'Please wait for rating table to finish processing before continuing.',
                      config.notificationDuration
                    );
                  })
                  .catch((e) =>
                    setErrorSnack(
                      `There was an error: ${e.message}`,
                      config.notificationDuration
                    )
                  );

              }}
              render={(formContext) => {
                return (
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        padding: 3,
                      }}
                    >
                      <Field
                        component={Dropdown}
                        isSearchable={false}
                        label={t('groups.selectBillingFrequency')}
                        name="frequency"
                        options={
                          BillingCycle
                        }
                        validate={required}
                      />
                      <Box
                        as="h3"
                        color="black"
                        display="flex"
                        justifyContent="flex-start"
                        sx={{
                          color: 'primary',
                          margin: '20px 0px 20px 0px;',
                          padding: 3,
                          width: '25rem',
                        }}
                      >
                        {get(formContext.values, "frequency") &&
                          "Upload Rating Table"
                        }
                      </Box>
                      {get(apiData, 'noOfEmployees', 0) > 0 && get(formContext.values, "frequency") && (
                        <Box>
                          Rating file upload is blocked since employees are uploaded in the group.
                        </Box>
                      )}
                      {get(apiData, 'noOfEmployees', 0) === 0 && get(formContext.values, "frequency", false) && (
                        <UploadFile
                          context={{
                            billingCycle: get(formContext.values, "frequency")
                          }}
                          fileTypes={[
                            'text/plain',
                            'application/vnd.ms-excel',
                            'text/x-csv',
                            'application/csv',
                            'application/x-csv',
                            'text/csv',
                            'text/comma-separated-values',
                            'text/x-comma-separated-values',
                            'text/tab-separated-values',
                          ]}
                          flow={config.canadaEnv? 'ratingCanada' : 'rating'}
                          form={formContext.form}
                          groupId={groupId}
                          // onUploadComplete={(response) =>
                          //   config.barrelS3Bucket ? setDatasetId(response) : null
                          // }
                          {...rest}
                        />
                      )}
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          my: 6,
                          p: 2,
                        }}
                      >
                        <ToolbarButton
                          bg="primaryLight"
                          label={t('common.previous')}
                          ml={0}
                          onClick={() =>
                            navigate(
                              generateRedirectPath({
                                ...location,
                                queryParams: { [name]: isRenewal ? 9 : 3 },
                              })
                            )
                          }
                          width="10rem"
                        />
                        <ToolbarButton
                          bg="primaryDark"
                          isLoading={secondPass || formContext.submitting}
                          label={t('common.next')}
                          mr={0}
                          type="submit"
                          width="10rem"
                        />
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

RatingTable.propTypes = {
  isRenewal: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
};

RatingTable.defaultProps = {
  isRenewal: false,
  name: 'step',
};

export default RatingTable;
