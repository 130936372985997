import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Dropdown from '../Dropdown';
import Icon from '../../Icon';

/**
 * @category BaseComponents
 * @param {*} rest
 * @returns {React.FC}
 */
const MultiSelectDropdown = ({ ...rest }) => {
  const { defaultStyle = {} } = useContext(ThemeContext);
  const { iconStyle = {} } = defaultStyle.defaultMultiSelectDropDown;

  const uncheck = <Icon svg="uncheck" sx={{ ...iconStyle }} />;
  const check = <Icon svg="checkbox" sx={{ ...iconStyle }} />;

  return <Dropdown checkIcon={check} isMulti uncheckIcon={uncheck} {...rest} />;
};

export default MultiSelectDropdown;
