import React from 'react';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ModuleContainer from '../Container';
import dateUtils from '../../../../../utilities/date-utils';

/**
 * @category Components
 * @param {Object} data
 * @param {string} qleNumber
 * @returns {React.FC}
 */
const QleStatusCode = ({ data, qleNumber }) => {
  const reopenedDate = get(
    get(data, 'qleEventHistories', [])
      .reverse()
      .find((obj) => get(obj, 'eventData.reopenReasonType', null) !== null),
    'created',
    null
  );

  const getDateDifference = reopenedDate
    ? dateUtils.getDateDifferenceInHours(reopenedDate)
    : dateUtils.getDateDifferenceInHours(
        get(data, `requestedDate`, new Date())
      );
  const requestStatus = get(data, `requestStatus`, null);
  let color = 'lightgray';
  if (['UNDER_REVIEW', 'UNASSIGNED', 'REOPEN'].includes(requestStatus)) {
    if (getDateDifference > 36) {
      color = 'red';
    } else {
      color = getDateDifference < 24 ? 'green' : 'orange';
    }
  }
  const displayValue = (
    <>
      <Box
        sx={{
          background: color,
          borderRadius: '5px',
          boxShadow: `0px 0px 5px ${color}`,
          display: 'inline-block',
          height: '10px',
          mx: '5px',
          width: '10px',
        }}
      />

      <Box
        sx={{
          display: 'inline-block',
          ml: 2,
        }}
      >
        {qleNumber}
      </Box>
    </>
  );
  return <ModuleContainer displayValue={displayValue} />;
};
QleStatusCode.propTypes = {
  data: PropTypes.shape({}).isRequired,
  qleNumber: PropTypes.string.isRequired,
};

export default QleStatusCode;
