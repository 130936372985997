import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import arrayMutators from 'final-form-arrays';
import { get, isArray } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Form as FinalForm } from 'react-final-form';
// import { ThemeContext } from 'styled-components';
import { FieldArray } from 'react-final-form-arrays';
import { AuthContext } from '@basecomponents/Auth';
import { useTranslation } from 'react-i18next';
import dateUtils from '@utils/date-utils';
import remoteActionQuery from '../../../../../graphql/queries/remote-action.gql';
import GetData from '../../../../../utilities/get-data';
import useSnackbar from '../../../../../utilities/use-snackbar';
import Spinner from '../../../../BaseComponents/Spinner';
import ToolbarButton from '../../../../BaseComponents/ToolbarButton';
import AddPet from './AddPet';
import gaEvent from '../../../../../utilities/gaEvent';
import config, { variation } from '../../../../../config.json';
import generateRedirectPath from '../../../../../utilities/generate-redirect-path';

/**
 * @category Employee
 * @param {Object} location
 * @param {string} name
 * @param {*} rest
 * @returns {React.FC}
 */
const PetInfo = ({ location, name, ...rest }) => {
  const start = Date.now();
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const userId = get(user, 'customSystemUserId', '');
  const queryParams = useParams();
  // const theme = useContext(ThemeContext);
  const employeeId = get(queryParams, 'employeeId', '');
  const isEditing = get(location, 'pathname', '').includes(
    '/group-employees/edit/'
  );
  const isPetInfo = get(location, 'pathname', '').includes('/petInfo');
  const isRenewals = get(location, 'pathname', '').includes('/renewalenroll');
  let oldVsNew = {};

  const { t, i18n } = useTranslation();
  if (isRenewals) {
    oldVsNew = {
      oldVsNew: 'NEW',
    };
  } else {
    oldVsNew = {};
  }

  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const { apiData: dependents = {}, loading } = GetData(
    'get-employee-dependents',
    JSON.stringify([{ employeeId }])
  );

  const { apiData: breeds, loading: breedsLoading } = GetData(
    'get-all-breeds',
    JSON.stringify([])
  );

  const { apiData, loading: employeeLoading } = GetData(
    'get-employee-by-id',
    JSON.stringify({ id: employeeId })
  );
  const { apiData: parentCoverageInfo = {}, loading: coverageLoading } =
    GetData(
      'get-coverage-info',
      JSON.stringify({
        employeeId: get(apiData, 'parentEmployeeId'),
      }),
      get(apiData, 'parentEmployeeId') === null
    );
  const {
    coverages: parentCoverages = [],
    planChoiceEmployees: parentPlanChoiceEmployees = [],
  } = parentCoverageInfo;
  const { planChoiceId: parentSelectedPlan } =
    parentPlanChoiceEmployees.find(
      ({ acceptGroupPlanChoice }) => acceptGroupPlanChoice
    ) || {};
  const parentProductPlanId = parentSelectedPlan
    ? parentCoverages.find(
        ({ planChoiceId }) => planChoiceId === parentSelectedPlan
      ).productPlanId
    : null;
  const groupMga = get(apiData, 'groupMgaName', '');
  const url = variation === 'demo' ? 'DEMO' : groupMga;
  const { content = [] } = dependents;
  const petData =
    content &&
    content.length &&
    content.map((data) => {
      const { dependentData, ...rest } = data;
      if (dependentData?.species === 'Dog' && dependentData?.age > 16) {
        dependentData.age = 16;
      } else if (dependentData?.species === 'Cat' && dependentData?.age > 24) {
        dependentData.age = 24;
      }
      return { ...rest, dependentData };
    });
  const petsInformation =
    (petData &&
      petData.length &&
      petData
        .map((item) => {
          return {
            ...item,
            ...item.dependentData,
          };
        })
        .filter(({ status }) => ['ACTIVE', 'PENDING'].includes(status))) ||
    [];
  const currentDate =
    get(apiData, 'relativeDatetime') === null
      ? dateUtils.getLocaleDateOnly(new Date())
      : dateUtils.getLocaleDateOnly(get(apiData, 'relativeDatetime'));
  if (loading || breedsLoading || employeeLoading || coverageLoading) {
    return <Spinner />;
  }

  return (
    <ApolloConsumer>
      {(client) => {
        const apiCall = (pathName, params) =>
          client.query({
            fetchPolicy: 'no-cache',
            query: remoteActionQuery,
            variables: {
              name: pathName,
              params: JSON.stringify(params),
            },
          });
        const onAddPet = (values) => {
          const { pets } = values;
          const newlyAddedPets = pets
            .filter((v) => !v.employeeDependentId)
            .map((item) => {
              return {
                deleted: false,
                dependentData: { ...item, ...oldVsNew },
                employeeId,
                relation: 'pet',
              };
            });
          const deletedPets = petsInformation
            .filter(
              (v) =>
                !pets.some(
                  (a) => a.employeeDependentId === v.employeeDependentId
                )
            )
            .map((item) => {
              return {
                ...item,
                deleted: true,
              };
            });

          const updatedPets = pets
            .filter((v) =>
              petsInformation.some(
                (a) => a.employeeDependentId === v.employeeDependentId
              )
            )
            .map((item) => {
              const petValues = [
                'deleted',
                'employeeDependentId',
                'employeeId',
                'relation',
                'dependentData',
                'created',
                'lastModified',
              ];
              const dependentData = {};
              Object.keys(item).forEach((key) => {
                if (!petValues.includes(key)) {
                  dependentData[key] = item[key];
                }
              });
              return {
                deleted: item.deleted,
                dependentData: { ...dependentData },
                employeeDependentId: item.employeeDependentId,
                employeeId: item.employeeId,
                relation: item.relation,
              };
            });

          const params = {
            addedDependents: newlyAddedPets,
            employeeId,
            updatedDependents: [...updatedPets, ...deletedPets],
            userId,
            isWithinGroupOpenEnrollment: false,
            relativeDate: dateUtils.setAPIDateOnly(currentDate),
          };

          return client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'update-employee-dependents',
                params: JSON.stringify(params),
              },
            })
            .then(() => {
              if (isEditing && ['ENROLLED'].includes(get(apiData, 'status'))) {
                const paramsData = {
                  employeeId,
                  helper: false,
                  sendEnrollmentEmail: false,
                  sendNotification: true,
                };
                return apiCall('generate-enrollment-by-employee', paramsData)
                  .then(() =>
                    navigate(`/group-employees/${employeeId}/details`)
                  )
                  .catch((e) =>
                    setErrorSnack(
                      t('snack.error.errorWithMessage', { message: e.message }),
                      config.notificationDuration
                    )
                  );
              }
              return navigate(
                generateRedirectPath({
                  ...location,
                  queryParams: { [name]: 1 },
                })
              );
            })
            .catch((e) =>
              setErrorSnack(
                t('snack.error.errorWithMessage', { message: e.message }),
                config.notificationDuration
              )
            );
        };
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={{
                pets:
                  // eslint-disable-next-line no-nested-ternary
                  petsInformation && petsInformation.length
                    ? petsInformation
                    : isRenewals
                    ? [{ agreeAll: false, oldVsNew: 'NEW' }]
                    : [{}],
              }}
              mutators={arrayMutators}
              onSubmit={async (values) => {
                gaEvent('Enrollment', 'Pet Information Set', role, start);
                await onAddPet(values);
                return isEditing
                  ? navigate(`/group-employees/${employeeId}/details`)
                  : navigate(
                      generateRedirectPath({
                        ...location,
                        queryParams: { [name]: 1 },
                      })
                    );
              }}
              render={({
                handleSubmit,
                submitting,
                form,
                values: formValues,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 3,
                      }}
                    >
                      <Box
                        as="h3"
                        sx={{
                          color: 'primary',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          ml:
                            config.canadaEnv && i18n.language === 'fr' ? 1 : 3,
                          my: 4,
                          width: '100%',
                        }}
                      >
                        {isRenewals
                          ? t('groupEmployees.enrollmentPage.petInformation')
                          : t('groupEmployees.enrollmentPage.addPetInfo')}
                      </Box>
                      {isRenewals && (
                        <Box
                          as="h4"
                          sx={{
                            color: 'primary',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            mb: 4,
                            ml: 3,
                            width: '100%',
                          }}
                        >
                          {t('groupEmployees.enrollmentPage.reviewPetInfo')}
                        </Box>
                      )}
                      <FieldArray name="pets" {...rest}>
                        {({ fields, meta }) => (
                          <>
                            <Box
                              sx={{
                                alignItems: 'flex-start',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'space-around',
                                maxWidth: '98vw',
                              }}
                            >
                              {fields.map((name, index) => {
                                const values = fields.value[index];
                                return (
                                  <Box
                                    key={index}
                                    sx={{
                                      borderRadius: 3,
                                      boxShadow: 7,
                                      m: 2,
                                    }}
                                  >
                                    {isRenewals ? (
                                      <AddPet
                                        breeds={breeds}
                                        employeeId={get(
                                          apiData,
                                          'parentEmployeeId'
                                        )}
                                        form={form}
                                        formValues={formValues}
                                        groupId={get(
                                          apiData,
                                          'parentGroup.groupId'
                                        )}
                                        isEditing={isEditing}
                                        isLateEnrollment={
                                          (get(
                                            values,
                                            'dependentData.oldVsNew'
                                          ) === 'NEW' ||
                                            get(values, 'oldVsNew') ===
                                              'NEW') &&
                                          get(
                                            apiData,
                                            'parentGroup.groupStatus'
                                          )
                                        }
                                        name={name}
                                        parentEmployeeId={get(
                                          apiData,
                                          'parentEmployeeId'
                                        )}
                                        parentGroupId={get(
                                          apiData,
                                          'parentGroup.groupId'
                                        )}
                                        parentPolicyYear={
                                          get(
                                            parentCoverageInfo,
                                            'groupUniqueIdentifier',
                                            ''
                                          ) &&
                                          get(
                                            parentCoverageInfo,
                                            'groupUniqueIdentifier',
                                            ''
                                          ).slice(-4)
                                        }
                                        parentProductPlanId={
                                          parentProductPlanId
                                        }
                                        url={url}
                                        values={values}
                                        {...rest}
                                      />
                                    ) : (
                                      <AddPet
                                        breeds={breeds}
                                        form={form}
                                        isEditing={isEditing}
                                        name={name}
                                        values={values}
                                        {...rest}
                                      />
                                    )}

                                    <Box
                                      p={4}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                      }}
                                    >
                                      {(isRenewals
                                        ? fields.length > 1
                                        : index > 0) &&
                                        (!isEditing || isRenewals) && (
                                          <ToolbarButton
                                            aria-label="close"
                                            bg="error"
                                            buttonSx={{
                                              borderRadius: '50%',
                                              height: '30px',
                                              width: '30px',
                                            }}
                                            icon="close"
                                            iconSx={{
                                              height: '20px',
                                              width: '20px',
                                            }}
                                            onClick={() => fields.remove(index)}
                                          />
                                        )}
                                    </Box>
                                  </Box>
                                );
                              })}
                              {meta.error &&
                                meta.touched &&
                                !isArray(meta.error) && (
                                  <Box
                                    sx={{
                                      color: 'error',
                                      fontSize: 2,
                                      mt: 1,
                                    }}
                                  >
                                    {meta.error}
                                  </Box>
                                )}
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                              <ToolbarButton
                                bg="accentLight"
                                icon="add"
                                iconSx={{
                                  mr: [0, null, null, 1],
                                }}
                                label={t(
                                  'groupEmployees.enrollmentPage.addPet'
                                )}
                                m="20px auto 0"
                                onClick={() =>
                                  isRenewals
                                    ? fields.push({
                                        agreeAll: false,
                                        oldVsNew: 'NEW',
                                      })
                                    : fields.push({})
                                }
                                sx={{ width: 'auto' }}
                                visible={!isEditing && !isPetInfo}
                              />
                            </Box>
                          </>
                        )}
                      </FieldArray>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: isRenewals ? 'flex-end' : 'center',
                          maxWidth: '25rem',
                          my: 6,
                          p: 3,
                          width: '100%',
                        }}
                      >
                        <ToolbarButton
                          bg="accentSecondary"
                          isDisabled={submitting}
                          label={
                            isEditing || isPetInfo
                              ? t('common.submit')
                              : t('common.next')
                          }
                          mr={0}
                          submitting={submitting}
                          type="submit"
                          width="10rem"
                        />
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

PetInfo.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string,
};

PetInfo.defaultProps = {
  name: 'step',
};

export default PetInfo;
