import { EMPLOYEE_STATUS } from './constants';

const getFormattedValue = (key, value) => {
  if (key.includes('Date')) {
    return value.map(
      (x, i) =>
        `${new Date(x).toLocaleDateString('fr-CA', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}${i === 0 ? ' - ' : ''}`
    );
  }
  if (key.includes('Agree')) {
    return value.map((x) =>
      x ? EMPLOYEE_STATUS.ACTIVE : EMPLOYEE_STATUS.INACTIVE
    );
  }
  if (key.includes('Salary')) {
    return value.map(
      (x, i) => `${x.toString().replace(/^/, '$')}${i === 0 ? ' - ' : ''}`
    );
  }
  if (key.includes('Hrs')) {
    return value.map((x, i) => `${x}${i === 0 ? ' - ' : ' hrs'}`);
  }
  return value;
};

export default getFormattedValue;
