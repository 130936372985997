import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import get from "lodash/get";
import GetData from '../../../../../utilities/get-data';
import ModuleContainer from '../Container';

/**
 * Employee Dependent Reason variant
 *
 * @category Components
 * @param {string} status
 * @returns {React.FC}
 */
const Reason = ({ value }) => {
    const { i18n } = useTranslation();
    const {
        apiData: { ReasonPetRemoval = [] },
            loading,
      } = GetData(
        'get-enum-options',
        JSON.stringify([{ params: 'ReasonPetRemoval' }]),
        false,
        'cache-first'
      );

    const reasonData = ReasonPetRemoval.find(
        (v) => v.value === value
      );


    return (
        <ModuleContainer displaySx={{whiteSpace: 'normal'}}
      displayValue={(i18n.language === 'fr' && get(reasonData, 'frenchValue', '') !== '') ? get(reasonData, 'frenchValue')  : reasonData?.label}
      loading={loading}
    />
    );
};
Reason.propTypes = {
    value: PropTypes.string.isRequired,
};

export default Reason;
