import Spinner from '@basecomponents/Spinner';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import Route from '../../components/Pet/Route';

const PostAuth = () => {
  useEffect(() => {
    const additionalChecks = async () => {
      await navigate('/login/?redirect=/secondary-auth/');
    };
    additionalChecks();
  }, []);

  return (
    <Route isPublic layout="plain">
      <Spinner />
    </Route>
  );
};

export default PostAuth;
