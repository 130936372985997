import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import GetData from '../../../../../utilities/get-data';
import getMetaData from '../../../../../utilities/get-meta-data';
import Spinner from '../../../../BaseComponents/Spinner';
import ListItem from '../../../../BaseComponents/ListGrid/ListItem';

/**
 * @category Claims
 * @param {object} dependentClaims
 * @param {string} employeeDependentIds Indicating unique Employee Dependent ID
 * @returns {React.FC}
 */

const ClaimDependentsSection = ({ dependentClaims, employeeDependentIds }) => {
  const { apiData = [], loading } = GetData(
    'get-employee-dependent-by-ids',
    JSON.stringify({ ids: employeeDependentIds })
  );
  const meta = getMetaData({ moduleName: 'claims' });
  const employeeDependentsMetadata = getMetaData({ moduleName: 'employee-dependents' });
  if (loading) {
    return <Spinner />;
  }
  return (
    <ListItem
      data={apiData}
      getExpandedSection={(row, rowIndex) => {
        return (
          <ListItem
            key={`table-${rowIndex}-expanded`}
            data={get(dependentClaims[rowIndex], 'dependentVisits', [])}
            grid={{
              fields: [
                {
                  name: 'diagnosis',
                },
                {
                  name: 'onsetDate',
                },
                {
                  name: 'treatmentStartDate',
                },
              ],
            }}
            isExpandedSectionMore
            listItemSx={{
              '&:hover': {
                bg: 'transparent',
              },
              bg: 'trnasparent',
              width: '100% !important',
            }}
            meta={get(meta, 'dependentsVisits', [])}
          />
        );
      }}
      grid={{
        fields: [
          {
            name: 'dependentData.petName',
          },
          {
            name: 'dependentData.species',
          },
          {
            name: 'dependentData.breed',
          },
          {
            name: 'dependentData.age',
          },
          {
            name: 'dependentData.weight',
          },
        ],
      }}
      isExpandedSection
      meta={employeeDependentsMetadata}
    />
  );
};
ClaimDependentsSection.defaultProps = {
  dependentClaims: [],
  employeeDependentIds: [],
};
ClaimDependentsSection.propTypes = {
  dependentClaims: PropTypes.arrayOf(PropTypes.shape({})),
  employeeDependentIds: PropTypes.arrayOf(PropTypes.string),
};

export default ClaimDependentsSection;
