import React from 'react';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';

/**
 * Represent null variant
 *
 * @category Components
 * @param {Object} value
 * @returns {React.FC}
 */
const EmptyVariant = ({ value }) => (
  <Box
    sx={
      value === 'NA'
        ? {
            color: 'accentDark',
          }
        : {}
    }
  >
    {value}
  </Box>
);

EmptyVariant.defaultProps = {
  value: 'NA',
};
EmptyVariant.propTypes = {
  value: PropTypes.string,
};

export default EmptyVariant;
