import React from 'react';
import Modal from '@basecomponents/Modal';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import localStorage from '@utils/local-storage';
import { useTranslation } from 'react-i18next';
import GetData from "@utils/get-data";
import {isEmpty} from "lodash";
import Spinner from "@basecomponents/Spinner";
import useSsm from '@src/utilities/hooks/useSsm';

const BlockDemographicClaimModal = ({ employeeId,empId,groupNumber, isOpen, onClose }) => {
  const pdbaRenewalConfigEnabled = useSsm('iaic-pdba-renewal')
  const { t } = useTranslation();
    const { apiData: missingDemographics = [], loading: missingDemographicsLoading } = GetData(
        'get-all-missing-demographics',
        JSON.stringify({
            empId,
            groupNumber
        }),
        isEmpty(groupNumber) || isEmpty(employeeId)
    );
    if (missingDemographicsLoading) {
        return <Spinner />;
    }
  return (
    <Modal
      closeText={t('common.close')}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={() => {
          if(Array.isArray(missingDemographics) &&
              missingDemographics.filter(o=> o.hasDemographicChanges).length > 1 &&
              pdbaRenewalConfigEnabled === true){
              navigate(`/group-employees/petInfo/${groupNumber}/${empId}`);
          }else {
              localStorage.setItem(`employeeDependentData_${employeeId}`, JSON.stringify([]));
              navigate(`/group-employees/${employeeId}/petInfo`);
          }
      }}
      shouldCloseOnOverlayClick={false}
      submitText={t('common.update')}
      title={t('modal.informationNeeded')}
    >
      {t('modal.blockDemographicClaim')}
    </Modal >
  );
};

BlockDemographicClaimModal.propTypes = {
  employeeId: PropTypes.string.isRequired,
  empId: PropTypes.string.isRequired,
  groupNumber: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

BlockDemographicClaimModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

export default BlockDemographicClaimModal;
