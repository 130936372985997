import React from 'react';
import PropTypes from 'prop-types';
import getMetaData from '../../../../../utilities/get-meta-data';
import Spinner from '../../../../BaseComponents/Spinner';
import ListItem from '../../../../BaseComponents/ListGrid/ListItem';

/**
 * @category QLE
 * @param {Object} dependentData
 * @param {boolean} loading
 * @returns {React.FC}
 */
const PetDetailPage = ({ dependentData, loading }) => {
  const dependentsMetaData = getMetaData({ moduleName: 'employee-dependents' });
  return loading ? (
    <Spinner />
  ) : (
    <ListItem
      data={dependentData}
      grid={{
        fields: [
          {
            name: 'dependentData.age',
          },
          {
            name: 'dependentData.breed',
          },
          {
            name: 'dependentData.petName',
          },
          {
            name: 'dependentData.species',
          },
          {
            name: 'dependentData.weight',
          },
        ],
      }}
      meta={dependentsMetaData}
    />
  );
};

PetDetailPage.propTypes = {
  dependentData: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PetDetailPage;
