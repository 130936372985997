import React from 'react';
import Box from '@basecomponents/Box';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ParentSize } from '@vx/responsive';
import { useCellDensities, LS_CELL_DENSITY } from '../utils/constants';
import EmptyVariant from '../../../Pet/Variant/EmptyVariant';
import localStorage from '../../../../utilities/local-storage';

const ShimmerBox = styled(Box)`
  background-color: transparent;
  width: 100%;
  overflow: hidden;
  transform: translateX(-100%);
  background-image: ${(p) => p.theme.gradients.listItemGradient};
  animation: shimmer 1s infinite;
  content: '';

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;

/**
 * @category BaseComponents
 * @param {number} pageSize
 * @param {Object} gridState
 * @returns {React.FC}
 */
const ListItemLoading = ({ pageSize, gridState: { cellDensity } }) => {
  const lsCellDensity = localStorage.getItem(LS_CELL_DENSITY);

  const cellDensities = useCellDensities();
  const listItem = (
    <Box
      sx={{
        bg: 'white',
        borderRadius: 2,
        my: get(
          cellDensities.find(({ id }) =>
            cellDensity ? id === cellDensity : id === lsCellDensity
          ),
          'py',
          5
        ),
        overflow: 'hidden',
      }}
    >
      <ShimmerBox
        sx={{
          borderRadius: 2,
          px: 2,
          py: get(
            cellDensities.find(({ id }) =>
              cellDensity ? id === cellDensity : id === lsCellDensity
            ),
            'pys',
            5
          ),
        }}
      >
        <Box
          sx={{
            py: get(
              cellDensities.find(({ id }) =>
                cellDensity ? id === cellDensity : id === lsCellDensity
              ),
              'py',
              5
            ),
            width: get(
              cellDensities.find(({ id }) =>
                cellDensity ? id === cellDensity : id === lsCellDensity
              ),
              'width',
              '120px'
            ),
          }}
        >
          <Box
            color="transparent"
            fontSize={2}
            my={1}
            sx={{ textTransform: 'uppercase' }}
          >
            Default
          </Box>
          <Box color="transparent" fontSize={3} fontWeight="bold">
            <EmptyVariant value="Invalid Date" />
          </Box>
        </Box>
      </ShimmerBox>
    </Box>
  );
  const rows = [];
  for (let i = 0; i < pageSize; i += 1) {
    rows.push(listItem);
  }
  return (
    <ParentSize>
      {({ width }) => (
        <Box data-testid="shimmer" height="100%" width={width}>
          {rows}
        </Box>
      )}
    </ParentSize>
  );
};

ListItemLoading.propTypes = {
  gridState: PropTypes.shape({
    cellDensity: PropTypes.string,
  }).isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default ListItemLoading;
