import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import MaskedInput from 'react-text-mask';
import { ThemeContext } from 'styled-components';
import InputWrapper from '../InputWrapper';

/**
 * @category BaseComponents
 * @param {Object} input
 * @param {boolean} disabled
 * @param {*} rest
 * @returns {React.FC}
 */
const InputMasked = ({ input, disabled, ...rest }) => {
  const { defaultStyle = {}, sizes = {} } = useContext(ThemeContext);

  return (
    <InputWrapper disabled={disabled} input={input} {...rest}>
      {({ error, ...inputRest }) => (
        <MaskedInput
          error={error}
          guide={false}
          id={input.name}
          keepCharPositions
          {...input}
          {...inputRest}
          render={(ref, props) => (
            <Box
              ref={(input) => ref(input)}
              as="input"
              sx={defaultStyle.defaultInputMasked(error, disabled)}
              variant="inputs.primary"
              width={sizes.widths.InputMasked}
              {...props}
            />
          )}
        />
      )}
    </InputWrapper>
  );
};

InputMasked.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({ error: PropTypes.bool, name: PropTypes.string })
    .isRequired,
};

InputMasked.defaultProps = {
  disabled: false,
};

export default InputMasked;
