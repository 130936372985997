import React from 'react';
import { useParams } from '@reach/router';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import ExportDetails from '@petcomponents/GroupDetails/ExportDetails';
import { useTranslation } from 'react-i18next';
import Route from '../../Route';
import KeyValuePairs from '../../../BaseComponents/KeyValuePairs';
import GetData from '../../../../utilities/get-data';
import getMetaData from '../../../../utilities/get-meta-data';
import Card from '../../../BaseComponents/Card';
import Tabs from '../../../BaseComponents/Tabs';
import UsersDashboardWithApolloConsumer from '../../Users/DashboardPage';
import GroupsDashboardPage from '../../GroupsDashboardPage';
import config from '../../../../config.json';

/**
 * @category BenAdmin
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const BenAdminDetailPage = ({ ...rest }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const benAdminId = get(queryParams, 'benAdminId', '');
  const { apiData = {}, loading } = GetData(
    'get-ben-admin-by-id',
    JSON.stringify({ id: benAdminId })
  );

  const fields = [
    { name: 'name' },
    { name: 'address.addressLine1' },
    { name: 'address.addressLine2' },
    { name: 'address.city' },
    {
      label: config.canadaEnv ? 'Province' : 'State',
      name: 'address.state',
    },
    // { name: 'address.zipCode' },
    { name: 'contact.phoneNumber' },
    { name: 'active' },
    { name: 'deleted' },
  ];
  const meta = getMetaData({ moduleName: 'ben-admin' });

  if (config.canadaEnv) {
    fields.splice(5, 0, { dynamicLabel: 'POSTAL CODE', name: 'address.zipCode' });
  } else {
    fields.splice(5, 0, { dynamicLabel: 'Zip Code', name: 'address.zipCode' });
  }
  return (
    <Route
      header={{
        title: get(apiData, 'name'),
        type: 'ben-admin',
      }}
      isPrivate
      permission="benAdmin.page.detail"
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Ben Admin Details
      </Box>
      <Card loading={loading}>
        <KeyValuePairs data={apiData} fields={fields} meta={meta} />
      </Card>
      <Tabs
        defaultTab={0}
        loading={loading}
        tabs={[
          {
            label: 'Group Info',
            render: () => <GroupsDashboardPage extBenAdminId={benAdminId} />,
          },
          {
            label: 'User Details',
            render: () => (
              <UsersDashboardWithApolloConsumer extBenAdminId={benAdminId} />
            ),
          },
          {
            label: t('common.export'),
            render: () => <ExportDetails {...rest} benAdminId={benAdminId} />,
          },
        ]}
      />
    </Route>
  );
};

BenAdminDetailPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default BenAdminDetailPage;
