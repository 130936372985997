import React, { useContext } from 'react';
import { AuthContext } from '@basecomponents/Auth';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { get } from 'lodash';
import DataFormPage from '../../DataFormPage';
import basicInformation from './configs/basic-information';
import eligibiltyRules from './configs/eligibility-rule';
import administrators from './configs/administrators';
import billingInfo from './configs/billing-info';
import locations from './configs/locations';

const UpsertGroupPage = ({ groupId }) => {
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const sections = [
    basicInformation,
    locations,
    eligibiltyRules,
    administrators,
    billingInfo,
  ];
  return (
    <DataFormPage
      data={{
        groupId,
        userId,
      }}
      mutationName="upsert-group-form"
      onComplete={({ groupId }) => navigate(`/groups/${groupId}/details`)}
      queryName="get-group-form"
      sections={sections}
      title={groupId ? 'Edit Group' : 'Create Group'}
      type="groups"
    />
  );
};

UpsertGroupPage.defaultProps = {
  groupId: null,
};

UpsertGroupPage.propTypes = {
  groupId: PropTypes.string,
};

export default UpsertGroupPage;
