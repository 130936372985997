import React from 'react';
import DataForm from './DataForm';
import ListForm from './ListForm';

/**
 * @category Components
 * @param {boolean} active
 * @param {Function} dispatch
 * @param {Array<Object>} fields Indicates the form fields
 * @param {object} stateValues
 * @param {string} mutationName Indicates the name of the mutation query
 * @param {string} name Indicates the name of the section
 * @param {string} queryName Indicates the name of the query
 * @param {object} sectionOrder
 * @param {object} sectionOperations
 * @param {string} sectionTitle Indicates the title of the section
 * @param {string} type Indicates the type of the form to render i.e DataForm or ListForm
 * @param {string} userId Indicates the unique user ID
 * @returns {React.FC}
 */
const Section = ({
  active,
  dispatch,
  fields,
  stateValues,
  mutationName,
  name,
  queryName,
  sectionOrder,
  sectionOperations,
  sectionTitle,
  type,
  userId,
}) => {
  let result = null;
  switch (type) {
    case 'Form': {
      result = (
        <DataForm
          active={active}
          dispatch={dispatch}
          fields={fields}
          mutationName={mutationName}
          queryName={queryName}
          sectionName={name}
          sectionOperations={sectionOperations}
          sectionOrder={sectionOrder}
          sectionTitle={sectionTitle}
          stateValues={stateValues}
          userId={userId}
        />
      );
      break;
    }

    case 'List': {
      result = (
        <ListForm
          active={active}
          dispatch={dispatch}
          mutationName={mutationName}
          queryName={queryName}
          sectionFields={fields}
          sectionName={name}
          sectionOperations={sectionOperations}
          sectionOrder={sectionOrder}
          sectionTitle={sectionTitle}
          stateValues={stateValues}
          userId={userId}
        />
      );
      break;
    }

    default: {
      result = `Form type "${type}" missing`;
    }
  }
  return result;
};

export default Section;
