import { useTranslation } from "react-i18next";

export const useCellDensities = () => {
  const { t } = useTranslation()
  return [
    {
      icon: 'row-height-sm',
      id: 'sm',
      label: t('component.compactView'),
      py: 2,
      pys: '0.75rem',
      widthFactor: 8,
    },
    {
      icon: 'row-height-md',
      id: 'md',
      label: t('component.defaultView'),
      py: 4,
      pys: '1.5rem',
      widthFactor: 10,
    },
    {
      icon: 'row-height-lg',
      id: 'lg',
      label: t('component.comfortableView'),
      py: 5,
      pys: '2.5rem',
      widthFactor: 12,
    },
  ]
};

export const TOOLTIP = 'dg-tooltip';
export const LS_CELL_DENSITY = `default-cell-density`;
