import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';
import Icon from '../../Icon';

/**
 * @category BaseComponents
 * @param {React.ReactNode} children
 * @param {string} dataCy
 * @param {boolean} disabled
 * @param {Object} errorSx
 * @param {function} validationIcon
 * @param {string} htmlFor
 * @param {Object} input
 * @param {Object} inputWrapperSx
 * @param {string} label
 * @param {Object} labelSx
 * @param {Object} meta
 * @param {React.ReactNode} subtext
 * @param {Object} wrapperSx
 * @param {*} rest
 * @returns {React.FC}
 */
const InputWrapper = ({
  children,
  dataCy,
  disabled,
  errorSx,
  validationIcon,
  htmlFor,
  input,
  inputWrapperSx,
  label,
  labelSx,
  meta,
  subtext,
  wrapperSx,
  ...rest
}) => {
  const error = meta.error && meta.touched;
  let below = null;
  const { defaultStyle = {} } = useContext(ThemeContext);
  /* eslint-disable*/
  const {
    errorContainerBelow = {},
    containerBelow = {},
    wrapperContainerBox = {},
    labelBox = {},
    inputContainerBox = {},
    validationContainerBox = {},
  } = defaultStyle.defaultInputWrapper(
    errorSx,
    wrapperSx,
    disabled,
    labelSx,
    inputWrapperSx,
    error
  );
  /* eslint-enable */

  if (error) {
    below = (
      <Box sx={errorContainerBelow}>
        <Icon height="20px" svg="error" width="20px" />
        <Box ml={1}>{meta.error}</Box>
      </Box>
    );
  } else if (subtext) {
    below = <Box sx={containerBelow}>{subtext}</Box>;
  }

  return (
    <Box data-cy={dataCy} sx={wrapperContainerBox}>
      {label && (
        <Box as="label" htmlFor={htmlFor || input.name} sx={labelBox}>
          {label}
        </Box>
      )}
      <Box
        // sx={inputContainerBox}
        sx={{ padding: '0px' }}
      >
        {children({ disabled, error, ...rest })}
        {validationIcon && meta.touched && (
          <Box sx={validationContainerBox}>{validationIcon(error)}</Box>
        )}
      </Box>
      {below}
    </Box>
  );
};

InputWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  errorSx: PropTypes.shape({}),
  htmlFor: PropTypes.string,
  input: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  inputWrapperSx: PropTypes.shape({}),
  label: PropTypes.node,
  labelSx: PropTypes.shape({}),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool.isRequired,
  }).isRequired,
  subtext: PropTypes.node,
  validationIcon: PropTypes.func,
  wrapperSx: PropTypes.shape({}),
};

InputWrapper.defaultProps = {
  dataCy: null,
  disabled: false,
  errorSx: {},
  htmlFor: null,
  inputWrapperSx: {},
  label: null,
  labelSx: {},
  subtext: null,
  validationIcon: () => null,
  wrapperSx: {},
};

export default InputWrapper;
