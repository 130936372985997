import get from 'lodash/get';
import { BARREL_FLOWS } from '../../../../utilities/constants';

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const BarrelFlows = ({ value }) => {
  const barrelFlows = BARREL_FLOWS.find((s) => s.value === value);
  return barrelFlows ? get(barrelFlows, 'label') : `#${value}`;
};

export default BarrelFlows;
