import { Router } from '@reach/router';
import React from 'react';
import HomePage from '../../components/Pet/HomePage';

const Home = (props) => (
  <Router>
    <HomePage {...props} path="/home/" />
  </Router>
);

export default Home;
