import {Location} from '@reach/router';
import {AuthContext} from '@basecomponents/Auth';
import Box from '@basecomponents/Box';
import {get} from 'lodash';
import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import PropTypes from 'prop-types';
import {lighten} from 'polished';
import {USER_ROLE} from '@src/utilities/constants';
import GetData from '@src/utilities/get-data';
import getEmployeeStatus from '@src/utilities/get-default-employee-status';
import {initReactI18next, useTranslation} from 'react-i18next';
import useSsm from '@src/utilities/hooks/useSsm';
import config from '../../../../config.json';
import Icon from '../../../Icon';
import Nav from './Nav';
import localStorage from '../../../../utilities/local-storage';
import EditAccessRightsContext from '../../EditAccessRights/Context';


const StyleSidebar = styled(Box)`
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: ${(p) => p.theme.sizes.heights.header};
  height: 100%;
  z-index: ${(p) => p.theme.zIndices[1]};
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.white};
  transition: transform 0.2s, width 0.25s, box-shadow 0.25s;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(p) => lighten(0.1, `${p.theme.colors.accent}`)};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.accent};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  &:hover {
    width: ${(p) => p.theme.sizes.widths.sidebar};
    box-shadow: ${(p) => p.theme.shadows[2]};
  }
`;

const SidebarHeader = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  width: ${(p) => p.theme.sizes.widths.sidebar};
  flex-direction: column;
  justify-content: space-between;
  margin: ${(p) => p.theme.space[5]} 0;
  opacity: 0.75;
`;

/**
 * @category Components
 * @param {boolean} isOpen
 * @param {boolean} isResponsive
 * @returns {React.FC}
 */
const Sidebar = ({isOpen, isResponsive}) => {
    const {signOut, user} = useContext(AuthContext);
    const {sizes} = useContext(ThemeContext);
    const ear = useContext(EditAccessRightsContext);
    const role = get(user, 'customRole', '');
    const {employeeId} = getEmployeeStatus(
        get(user, 'customSystemUserId', ''),
        role
    );
    const {t, i18n} = useTranslation()

    const userId = get(user, 'customSystemUserId', '');
    const {apiData: {content = []} = {}} = GetData(
        'get-all-administrator-by-group-id',
        JSON.stringify({userId}),
        role !== USER_ROLE.EMPLOYER
    );
    const {apiData = {}} = GetData(
        'get-group-employee-by-id',
        JSON.stringify({id: employeeId}),
        role !== USER_ROLE.EMPLOYEE ||
        employeeId === null
    );

    const groupId = get(content, '[0].groupId', null);
    const {apiData: groupData = {}} = GetData(
        'get-group-by-id',
        JSON.stringify({id: groupId}),
        groupId === null || role !== USER_ROLE.EMPLOYER
    );

    const sidebarWidth = () => {
        if (isOpen) {
            return sizes.widths.sidebar;
        }
        if (isResponsive) {
            return 0;
        }
        return sizes.widths.sidebarMin;
    };
    const postEnrollmentConfigEnabled = useSsm('iaic-post-enrollment')
    const barrelEnabledConfigEnabled = useSsm('iaic-barrel-enabled')

    return (
        <StyleSidebar
            sx={{
                boxShadow: `${isOpen ? 2 : null}`,
                width: `${sidebarWidth()}`,
            }}
        >
            <>
                <SidebarHeader>
                    <Box
                        sx={{
                            alignItems: 'center',
                            color: 'primary',
                            display: 'flex',
                            flexDirection: 'row',
                            m: 5,
                        }}
                    >
                        <Icon
                            svg="user-filled"
                            sx={{
                                color: 'primary',
                                mx: 4,
                                width: '1.5rem',
                            }}
                        />
                        <Box
                            sx={{
                                alignItems: 'center',
                                fontSize: 3,
                                justifyContent: 'center',
                                mx: 5,
                            }}
                        >
                            <Box
                                fontSize={4}
                                sx={{
                                    fontWeight: 'bold',
                                    mb: 2,
                                }}
                            >
                                {user.givenName} {user.familyName}
                            </Box>
                            <Box
                                onClick={() => {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    i18n
                                        .use(initReactI18next)
                                        .init({
                                            // other options
                                            debug: false, // Disable debug logging
                                        });
                                    signOut();
                                }}
                                sx={{
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <Icon
                                    mr={1}
                                    svg="logout"
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    width="1rem"
                                />
                                <Box
                                    sx={{
                                        fontSize: 2,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t('common.signOut')}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </SidebarHeader>
                <Location>
                    {({location}) => (
                        <Nav
                            items={config.navLinks
                                .filter(({permissionResource}) =>
                                    ear.isVisible(permissionResource)
                                )
                                // disable qle for post enroll employees
                                .filter(({id}) => {
                                    const isPostEnrollmentEmployee =
                                        postEnrollmentConfigEnabled &&
                                        [
                                            USER_ROLE.EMPLOYEE,
                                            USER_ROLE.TERMINATED_EMPLOYEE,
                                            USER_ROLE.RENEWAL_POLICY_DELETED,
                                        ].includes(get(user, 'customRole', '')) &&
                                        get(apiData, 'postEnrollmentGroup', false);
                                    // disable life events for post enrollments
                                    if (isPostEnrollmentEmployee && id === 'life-events') {
                                        return false;
                                    }
                                    if (config.canadaEnv && id === 'helpline') {
                                        return false;
                                    }
                                    return true;
                                })
                                // disable qle for post enroll employers
                                .filter(({id}) => {
                                    const isPostEnrollmentEmployer =
                                        postEnrollmentConfigEnabled &&
                                        USER_ROLE.EMPLOYER === get(user, 'customRole', '') &&
                                        get(groupData, 'postEnrollmentGroup', false);
                                    // disable life events for post enrollments
                                    if (isPostEnrollmentEmployer && id === 'life-events') {
                                        return false;
                                    }
                                    return true;
                                })
                                // Temporarily hide SFTP files section on non integrated environments for Phase 1
                                .filter(({id}) => id !== 'files' || barrelEnabledConfigEnabled)
                                .map((item) => {
                                    const linkPathname =
                                        item.link ||
                                        get(config, `moduleMapping.${item.id}.linkPath`, '');
                                    const active = location.pathname.includes(linkPathname);

                                    return {...item, active};
                                })}
                        />
                    )}
                </Location>
            </>
        </StyleSidebar>
    );
};

Sidebar.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isResponsive: PropTypes.bool.isRequired,
};
export default Sidebar;
