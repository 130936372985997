import React, { useContext } from 'react';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { AuthContext } from '@basecomponents/Auth';
import { USER_ROLE } from '@utils/constants';
import Route from '@petcomponents/Route';
import ListGrid from '../../../BaseComponents/ListGrid';
import downloadData from '../../../../utilities/download-file';
import config from '../../../../config.json';

/**
 * Export status dashboard page
 *
 * @category Employee
 * @param {boolean} actionDisabled
 * @param {any} groupId
 * @param {*} rest
 * @returns {React.FC}
 */

const ExportDetails = ({
  actionDisabled,
  benAdminId,
  location,
  groupId,
  ...rest
}) => {
  const { user } = useContext(AuthContext);
  let loginAdminId = null;
  const isExportFileDashboard = get(location, 'pathname', '').includes(
    '/groups/exportFile'
  );
  const role = get(user, 'customRole', '');
  if (role === USER_ROLE.BEN_ADMIN) {
    loginAdminId = get(user, 'customSystemBenadminId');
  } else if (
    [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN].includes(role) &&
    isExportFileDashboard
  ) {
    loginAdminId = '00000000-0000-0000-0000-000000000000';
  }
  // const filterOption = [
  //   {
  //     label: 'First Name',
  //     value: 'firstName',
  //   },
  //   {
  //     label: 'Last Name',
  //     value: 'lastName',
  //   },
  //   {
  //     label: 'Employee ID',
  //     value: 'empId',
  //   },
  //   {
  //     label: 'Email',
  //     value: 'email',
  //   },
  //   {
  //     label: 'Enrollment Status',
  //     value: 'enrollmentStatus',
  //   },
  //   {
  //     label: 'Export Status',
  //     value: 'exportStatus',
  //   },
  // ];

  const filters = [
    // {
    //   label: 'Applied Filters',
    //   name: 'appliedFilters',
    //   options: filterOption,
    //   type: 'Dropdown',
    // },
  ];

  const grid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'download',
            onClick: async () => {
              await downloadData({
                s3BucketName: config.amplify.Storage.AWSS3.bucket,
                s3KeyName: get(data, 'filePath', ''),
              });
            },
          };
        },
        label: 'Download',
        name: 'download',
      },
    ],
    fields: [
      { name: 'versionNumber' },
      { name: 'identifier' },
      // { name: 'appliedFilters' },
      { name: 'created' },
    ],
  };
  if (!benAdminId && !loginAdminId && !isExportFileDashboard) {
    grid.fields.splice(0, 0, { name: 'groupNumber' });
  }
  const defaultFilterValues =
    groupId || benAdminId || loginAdminId
      ? [
          {
            externalIdentifierId: benAdminId || groupId || loginAdminId,
            sort: 'created,desc',
          },
        ]
      : [{ sort: 'created,desc' }];

  return loginAdminId ? (
    <Route
      header={{
        title: 'Dashboard',
        type: 'group',
      }}
      isPrivate
      permission="groupEmployee.page.exportFileDashboard"
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Export File Dashboard
      </Box>
      <ListGrid
        defaultFilterValues={defaultFilterValues}
        filters={filters}
        grid={grid}
        gridQueryName="export-employee-report"
        moduleName="report-export"
        {...rest}
      />
    </Route>
  ) : (
    <Box>
      <ListGrid
        defaultFilterValues={defaultFilterValues}
        filters={filters}
        grid={grid}
        gridQueryName="export-employee-report"
        moduleName="report-export"
        {...rest}
      />
    </Box>
  );
};

ExportDetails.defaultProps = {
  actionDisabled: false,
  benAdminId: null,
  groupId: null,
  groupNumber: '',
  location: {},
};

ExportDetails.propTypes = {
  actionDisabled: PropTypes.bool,
  benAdminId: PropTypes.string,
  groupId: PropTypes.string,
  groupNumber: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
};

export default ExportDetails;
