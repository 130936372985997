import React from 'react';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';

/**
 * @category Employee
 * @param {string} label
 * @param {string} value
 * @returns {React.FC}
 */
const CoverageField = ({ label, value }) => (
  <Box
    marginBottom="10px"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
    }}
  >
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      }}
    >
      <Box
        sx={{
          bg: 'accent',
          borderRadius: 2,
          color: 'white',
          display: 'flex',
          fontSize: 2,
          fontWeight: 'bold',
          justifyContent: 'center',
          letterSpacing: 0,
          m: 3,
          mr: 4,
          p: 2,
          textAlign: 'center',
          textTransform: 'uppercase',
          width: '280px',
        }}
      >
        {`${label}`}
      </Box>
      <Box
        sx={{
          fontSize: 3,
        }}
      >
        {value}
      </Box>
    </Box>
  </Box>
);

CoverageField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CoverageField;
