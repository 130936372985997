import React from 'react';
import get from 'lodash/get';
import config from '@src/config.json';
import EmptyVariant from '../EmptyVariant';

/**
 * Return Variant based on condition
 * @category Components
 * @param {Object} data Data object
 * @param {string} name Condition
 * @returns {React.FC}
 */
const Condition = ({ data, name }) => {
  let result = null;
  if (name) {
    let displayValue = null;
    switch (name) {
      case 'totalRemittedAmount': {
        displayValue = config.newBillingApiVersionEnable
          ? get(data, 'remittedAmount', 0) + get(data, 'groupCreditShared', 0)
          : get(data, 'remittedAmount', 0);
        break;
      }
      case 'creditValue': {
        // displayValue = ['PAID'].includes(get(data, 'status', ''))
        //   ? get(data, 'remainingCredit', '0')
        //   : get(data, 'currentCredit', '0');
        displayValue = get(data, 'unappliedFunds', '0');
        break;
      }
      case 'receivableBalance': {
        const additionVal =
          get(data, 'remittedAmount', 0) >
          get(data, 'confirmedAmount', 0) +
            get(data, 'generalCreditPaid', 0) -
            get(data, 'groupCreditShared', 0)
            ? get(data, 'remittedAmount', 0) -
              (get(data, 'confirmedAmount', 0) +
                get(data, 'generalCreditPaid', 0) -
                get(data, 'groupCreditShared', 0))
            : 0;
        displayValue =
          get(data, 'billedAmount', 0) -
          (get(data, 'remittedAmount', 0) + get(data, 'groupCreditShared', 0)) +
          additionVal;
        break;
      }
      case 'outstandingBalance': {
        const additionVal =
          get(data, 'remittedAmount', 0) >
          get(data, 'confirmedAmount', 0) +
            get(data, 'generalCreditPaid', 0) -
            get(data, 'groupCreditShared', 0)
            ? get(data, 'remittedAmount', 0) -
              (get(data, 'confirmedAmount', 0) +
                get(data, 'generalCreditPaid', 0) -
                get(data, 'groupCreditShared', 0))
            : 0;
        const receivableBalance =
          get(data, 'billedAmount', 0) -
          (get(data, 'remittedAmount', 0) + get(data, 'groupCreditShared', 0)) +
          additionVal;

        displayValue = receivableBalance - get(data, 'unappliedFunds', 0);
        break;
      }
      default: {
        displayValue = <EmptyVariant />;
      }
    }
    result =
      displayValue === '0' ? <EmptyVariant /> : Number(displayValue).toFixed(2);
  } else {
    result = <EmptyVariant />;
  }

  return result;
};

export default Condition;
