import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import get from 'lodash/get';
import { PRODUCER_SUB_PRODUCER_STATUS } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import Route from '../../Route';
import config from '../../../../config.json';
import ListGrid from '../../../BaseComponents/ListGrid';

/**
 * @category Subproducer
 * @param {string} producerId
 * @param {*} rest
 * @returns {React.FC}
 */
const SubProducerDashboardPage = ({ producerId, ...rest }) => {
  const { t } = useTranslation()
  const filters = [
    {
      label: 'Sub-Producer Name',
      name: 'name',
      type: 'Input',
      visible: !producerId,
    },
    {
      label: t('metadata.status'),
      name: 'status',
      options: PRODUCER_SUB_PRODUCER_STATUS,
      type: 'Dropdown',
    },
  ];
  const grid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'details',
            link: `/sub-producers/${get(data, 'subProducerId')}/details`,
          };
        },
        label: 'View',
        name: 'view-sub-producer',
      },
      {
        getProps: (data) => {
          return {
            icon: 'edit',
            link: `/sub-producers/upsert?subProducerId=${get(
              data,
              'subProducerId'
            )}&isEditing=true`,
          };
        },
        label: 'Edit',
        name: 'upsert-sub-producer',
      },
    ],
    fields: [
      {
        name: 'producerDetail.name',
        visible: !producerId,
      },
      {
        name: 'name',
      },
      // {
      //   name: 'subProducerCode',
      // },
      { name: 'paidToProducer', visible: producerId },
      {
        name: 'status',
      },
    ],
  };
  if (config.canadaEnv) {
    grid.fields.splice(2, 0, { label: t('component.canada.fsraAgentNumber'), name: 'subProducerCode' });
  } else {
    grid.fields.splice(2, 0, { label: 'Sub-Producer Code/NPN', name: 'subProducerCode' });
  }
  const defaultFilterValues = producerId ? [{ producerId }] : [];
  const uiContent = (
    <ListGrid
      defaultFilterValues={defaultFilterValues}
      filters={filters}
      grid={grid}
      gridQueryName="get-all-subproducer"
      moduleName="sub-producers"
      {...rest}
    />
  );
  return producerId ? (
    uiContent
  ) : (
    <Route
      header={{
        title: 'Dashboard',
        type: 'sub-producers',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Sub-Producer Dashboard
      </Box>
      {uiContent}
    </Route>
  );
};

SubProducerDashboardPage.defaultProps = {
  producerId: null,
};

SubProducerDashboardPage.propTypes = {
  producerId: PropTypes.string,
};

export default SubProducerDashboardPage;
