import {get, isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {Form as FinalForm} from 'react-final-form';
import Box from '@basecomponents/Box';
import useSsm from '@src/utilities/hooks/useSsm';
import GetData from '../../../../utilities/get-data';
import Spinner from '../../../BaseComponents/Spinner';
import dateUtils from '../../../../utilities/date-utils';
import PayrollSchedule from '../../UpsertGroupPage/BillingInformation/PayrollSchedule';
import config from '../../../../config.json';

/**
 * Shows all the payroll generated dates
 * - Period start
 * - Payroll end
 * - Generated paid date
 * - Actual payroll date
 * - Generated bill creation date
 * - Actual bill creation date
 *
 * @category Group
 * @param {boolean} actionDisabled Disables all actions
 * @param {string} groupId
 * @param {*} rest
 * @returns {React.FC}
 */
const BillingCalender = ({ actionDisabled, groupId, ...rest }) => {
  const chaseEnabled = useSsm('iaic-chase-enabled' )
  const {
    apiData: { ChaseStatus = [] },
  } = GetData('get-enum-options', JSON.stringify([{ params: ' ChaseStatus' }]));
  const { apiData: groups = {}, loading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );

  const {
    apiData: { content = [] },
  } = GetData(
    'get-all-group-locations',
    JSON.stringify({
      groupId,
      queryParams: [{ page: 0 }],
    })
  );
  const {
    apiData: { content: groupBillingData = [] },
    loading: billLoading,
    refetch,
  } = GetData(
    'get-all-bills',
    JSON.stringify({
      groupId,
      page: 0,
      size: 60,
    })
  );

  const primaryLocation = content.find((item) => item.isSitus);

  const billingInformationId = get(groups, 'billingInfo.billingInfoId', '');

  const isBillingAddressSameAsCompanyAddress =
    get(groups, 'billingInfo.addressId', '') ===
    get(primaryLocation, 'address.addressId', '');

  const {
    apiData: { content: payrollData = [] },
    loading: payrollScheduleLoading,
  } = GetData(
    'get-payroll-schedule',
    JSON.stringify({
      billingInfoId: billingInformationId,
      groupId,
      size: get(groups, 'billingInfo.billingCycle', ''),
      sort: 'cycleNo,asc',
    }),
    !groupId || !billingInformationId
  );

  const billingInfoResponse = {
    accountName: get(groups, 'billingInfo.accountName', ''),
    billDaysPriorToPayroll: get(
      groups,
      'billingInfo.billDaysPriorToPayroll',
      ''
    )
      ? groups.billingInfo.billDaysPriorToPayroll.toString()
      : '',
    billingAddress: isBillingAddressSameAsCompanyAddress,
    billingCycle: get(groups, 'billingInfo.billingCycle', ''),
    billingInfo: {
      addressLine1: get(groups, 'billingInfo.address.addressLine1', ''),
      addressLine2: get(groups, 'billingInfo.address.addressLine2', ''),
      city: get(groups, 'billingInfo.address.city', ''),
      country: get(groups, 'billingInfo.address.country', ''),
      state: get(groups, 'billingInfo.address.state', ''),
      zipcode: get(groups, 'billingInfo.address.zipcode', ''),
    },
    firstPayrollDate: get(groups, 'billingInfo.firstPayrollDate', '')
      ? dateUtils.setOffset2(groups.billingInfo.firstPayrollDate)
      : '',
    paymentMethod: 'CHECK',
    phoneNumber: get(groups, 'billingInfo.contact.phoneNumber', ''),
  };

  if (loading || billLoading) {
    return <Spinner />;
  }

  return (
    <FinalForm
      initialValues={
        billingInformationId
          ? {
              ...billingInfoResponse,
            }
          : {}
      }
      keepDirtyOnReinitialize
      onSubmit={() => {}}
      render={({ handleSubmit, form, values }) => {
        return (
          <>
            {billingInformationId ? (
              <form onSubmit={handleSubmit}>
                <PayrollSchedule
                  actionDisabled={actionDisabled}
                  billingInfoId={billingInformationId}
                  change={form.change}
                  chasePayment={
                    get(groups, 'billingInfo.accountType') ===
                      'ACH_AUTOMATIC' &&
                    chaseEnabled
                  }
                  chaseStatus={ChaseStatus}
                  groupBillingData={groupBillingData}
                  groupId={groupId}
                  loading={payrollScheduleLoading}
                  payrollData={payrollData}
                  readOnly
                  refetch={refetch}
                  showGeneratedPayrollSchedule
                  values={values}
                  {...rest}
                />
              </form>
            ) : (
              <Box
                sx={{
                  color: 'accent',
                  display: 'table',
                  fontSize: 6,
                  fontWeight: 'bold',
                  height: '200px',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'table-cell',
                    verticalAlign: 'middle',
                  }}
                >
                  THE CALENDAR IS NOT YET BEEN GENERATED
                </Box>
              </Box>
            )}
          </>
        );
      }}
    />
  );
};

BillingCalender.propTypes = {
  actionDisabled: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
};
BillingCalender.defaultProps = {
  actionDisabled: false,
};
export default BillingCalender;
