// TODO: merge with DatePicker component in @spraoi/base

import DatePicker from 'react-date-picker';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import config from "@src/config.json";
import InputWrapper from '../InputWrapper';
import themeVariantToValue from '../../../utilities/theme-variant-to-value';

/**
 * @category BaseComponents
 * @param {any} input
 * @param {any} meta
 * @param {any} rest
 * @returns {React.FC}
 */

const DatePickerWrapper = styled(InputWrapper)`
    border: ${(p) => p.theme.variants.inputs.primary.borderStyle}
      ${(p) =>
    themeVariantToValue(p.theme, 'sizes', 'inputs.primary.borderWidth')}
      ${(p) =>
    themeVariantToValue(p.theme, 'colors', 'inputs.primary.borderColor')};
    &:focus-within {
      border-color: ${(p) =>
    themeVariantToValue(
      p.theme,
      'colors',
      'inputs.primary.&:focus.borderColor'
    )};
    }
    .react-calendar {
      font-family: inherit;
      border-color: ${(p) =>
    themeVariantToValue(p.theme, 'colors', 'inputs.primary.borderColor')};
      border-radius: ${(p) =>
    themeVariantToValue(p.theme, 'radii', 'inputs.primary.borderRadius')};
      &__tile {
        &:disabled {
          background-color: ${(p) => p.theme.colors.accent};
        }
        &:enabled {
          &:focus,
          &:hover {
            background-color: ${(p) => p.theme.colors.accent};
          }
        }
        &--active {
          background: ${(p) => p.theme.colors.accent};
          &:enabled {
            &:focus,
            &:hover {
              background: ${(p) => p.theme.colors.primary};
            }
          }
        }
      }
      &--selectRange &__tile--hover {
        background-color: ${(p) => p.theme.colors.accent};
      }
      &__navigation {
        button:enabled {
          &:hover,
          &:focus {
            background-color: ${(p) => p.theme.colors.accent};
          }
        }
        button[disabled] {
          background-color: ${(p) => p.theme.colors.accent};
        }
      }
      &__month-view__days__day {
        &--neighboringMonth {
          color: ${(p) => p.theme.colors.accent};
        }
      }
    }
    &.react-date-picker {
      width: 100%;
      height: ${(p) =>
    `calc(${themeVariantToValue(
      p.theme,
      'space',
      'inputs.primary.py'
    )} * 2 + ${p.theme.lineHeights[1]})`};
      padding: 0
        ${(p) => themeVariantToValue(p.theme, 'space', 'inputs.primary.px')};
      border-radius: ${(p) =>
    themeVariantToValue(p.theme, 'radii', 'inputs.primary.borderRadius')};
      background-color: ${(p) =>
    themeVariantToValue(p.theme, 'colors', 'inputs.primary.bg')};
    }
    .react-date-picker {
      &__wrapper {
        border: 0;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
      &__inputGroup {
        flex-grow: unset;
        min-width: 0;
      }
      &__clear-button {
        margin-left: auto;
      }
      &__calendar {
        box-shadow: ${(p) => p.theme.shadows[0]};
      }
      &__button {
        color: ${(p) => p.theme.colors.accent};
        &:enabled {
          &:focus,
          &:hover {
            color: hsl(0, 0%, 60%);
          }
          svg {
            stroke: currentColor !important;
          }
        }
      }
    }
  `;

const DatePickerWrapperError = styled(DatePickerWrapper)`
    border: ${(p) => p.theme.variants.inputs.primary.borderStyle}
      ${(p) =>
    themeVariantToValue(p.theme, 'sizes', 'inputs.primary.borderWidth')}
      ${(p) => p.theme.colors.error};
  `;

const SingleDatePicker = ({ input, meta, filterValues, ...rest }) => {
  const onChange = (date, meta) => {
    input.onChange(date, meta);
  };
  const { i18n } = useTranslation();

  return (
    <>
      {meta.touched && meta.error ? (
        <DatePickerWrapperError input={input} {...rest} meta={meta}>
          {(props) => (
            <DatePicker
              calendarAriaLabel="Toggle calendar"
              clearAriaLabel="Clear value"
              dayAriaLabel="Day"
              format={config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? "dd/MM/y" : "MM/dd/y"}
              locale={i18n.language === 'fr' ? "fr" : 'en'}
              monthAriaLabel="Month"
              nativeInputAriaLabel="Date"
              onChange={onChange}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              value={input.value}
              yearAriaLabel="Year"
              {...props}
            />
          )}
        </DatePickerWrapperError>
      ) : (
        <DatePickerWrapper input={input} {...rest} meta={meta}>
          {(props) => (
            <DatePicker
              calendarAriaLabel="Toggle calendar"
              clearAriaLabel="Clear value"
              dayAriaLabel="Day"
              format={config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? "dd/MM/y" : "MM/dd/y"}
              locale={i18n.language === 'fr' ? "fr" : 'en'}
              monthAriaLabel="Month"
              nativeInputAriaLabel="Date"
              onChange={onChange}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              value={input.value}
              yearAriaLabel="Year"
              {...props}
            />
          )}
        </DatePickerWrapper>
      )}
    </>
  );
};

SingleDatePicker.defaultProps = {
  filterValues: {
    disableClearButtton: false,
  },
};

SingleDatePicker.propTypes = {
  filterValues: PropTypes.shape({
    disableClearButtton: PropTypes.bool,
  }),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SingleDatePicker;
