import moment from 'moment';
import { parse } from 'query-string';
import { get, has } from 'lodash';

const DASHBOARD_FILTERS = {
  commissionsDashboard: {
    effectiveDate: {
      label: 'Effective Date',
      type: 'SingleDatePicker',
    },
    expirationDate: {
      label: 'Expiration Date',
      type: 'SingleDatePicker',
    },
    filterOrder: ['producer', 'effectiveDate', 'expirationDate', 'subProducer'],
    page: {
      offset: 0,
      pageSize: 10,
    },
    sort: 'created,desc',
  },
  configureClassesSection: {
    className: {
      label: 'Class Name',
      type: 'Input',
    },
    filterOrder: ['className'],
    page: {
      offset: 0,
      pageSize: 10,
    },
    sort: 'created,desc',
  },
  groupContactsSection: {
    filterOrder: ['firstName'],
    firstName: {
      label: 'First Name',
      type: 'Input',
    },
    page: {
      offset: 0,
      pageSize: 10,
    },
    sort: 'created,desc',
  },
};

const getFiltersValues = ({ search, type }) => {
  const meta = DASHBOARD_FILTERS[type];
  const metaKeys = Object.keys(DASHBOARD_FILTERS[type]);
  const searchFilter = parse(search);
  const searchApplied = has(searchFilter, 'filters');

  let searchKeys = [];
  let searchParams = {};
  if (searchApplied) {
    searchParams = parse(searchFilter.filters);
    searchKeys = Object.keys(searchParams);
  }

  const values = {};

  metaKeys.forEach((key) => {
    if (
      !['filterApplied', 'filterOrder', 'page', 'sort', 'values'].includes(key)
    ) {
      let value;
      const filter = meta[key];
      const { defaultValue, type } = filter;
      if (searchApplied && searchKeys.includes(key)) {
        const searchValue = searchParams[key];
        if (type === 'DatePicker') {
          value = searchValue.reduce((res, val) => {
            res.push(moment(parseInt(val, 10)).toDate());
            return res;
          }, []);
        } else if (type === 'SingleDatePicker') {
          value = moment(searchValue).toDate();
        } else {
          value = searchValue;
        }
      } else if (!searchApplied) {
        value = defaultValue;
      }

      if (value) {
        values[key] = value;
      }
    }
  });

  meta.values = values;
  meta.filterApplied = Object.keys(values).length > 0;
  return meta;
};

const getPageValues = ({ search, type }) => {
  const meta = DASHBOARD_FILTERS[type];
  const searchFilter = parse(search);
  const paginatorApplied = has(searchFilter, 'page');
  let pageCriteria;
  if (paginatorApplied) {
    const { offset, pageSize } = parse(get(searchFilter, 'page', {}));
    pageCriteria = {
      offset: Number.parseInt(offset, 10),
      pageSize: Number.parseInt(pageSize, 10),
    };
  } else {
    pageCriteria = meta.page;
  }

  return pageCriteria;
};

const getSortValues = ({ search, type }) => {
  const meta = DASHBOARD_FILTERS[type];
  const searchFilter = parse(search);
  return get(searchFilter, 'sort', meta.sort);
};

const dashboardConstants = {
  getCriterias: ({ search, type }) => {
    return {
      filters: getFiltersValues({ search, type }),
      pageCriteria: getPageValues({ search, type }),
      sortOptions: getSortValues({ search, type }),
    };
  },

  getQuery: ({ filters, page, sort }) => {
    const { values } = filters;
    const keys = Object.keys(values);
    const result = [];

    keys.forEach((key) => {
      const { type } = filters[key];
      const value = values[key];
      if (type === 'DatePicker') {
        result.push({
          value: value.map((val) => val.getTime()),
        });
      } else if (key === 'groupSize') {
        const groupSizes = value.split('-');
        result.push({
          maxGroupSize: groupSizes[1],
          minGroupSize: groupSizes[0],
        });
      } else {
        result.push({ [key]: value });
      }
    });
    if (sort) {
      result.push({ sort });
    }
    if (page) {
      result.push({
        page: Number(parseInt(page.offset / 10, 10)),
        size: page.pageSize,
      });
    }
    return result;
  },
};

export default dashboardConstants;
