import { print } from 'graphql';
import mutationRemoteAction from '@mutations/mutation-remote-action.gql';
import config from '@src/config.json';

async function mutationCall(name, params) {
  const mutationResponse = await (
    await fetch(config.apollo.url, {
      body: JSON.stringify({
        query: print(mutationRemoteAction),
        variables: {
          name,
          params,
        },
      }),
      headers: {
        'content-type': 'application/json',
        'x-api-key': config.apollo.apiKey,
      },
      method: 'POST',
    })
  ).json();
  return mutationResponse;
}

export default mutationCall;
