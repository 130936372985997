import React, { useEffect, useState } from 'react';
import TableSection from '@basecomponents/DataGrid/TableSection';
import Spinner from '@basecomponents/Spinner';
import PropTypes from 'prop-types';
import GetData from '../../../../utilities/get-data';
import getMetaData from '../../../../utilities/get-meta-data';

/**
 * @category Bill Info
 * @param {*} rest
 * @returns {React.FC}
 */
const BillingInfo = ({ groupPaymentRecordId }) => {
  const [groupPaymentID, setGroupPaymentId] = useState(null);
  useEffect(() => {
    if (groupPaymentRecordId) {
      setGroupPaymentId(groupPaymentRecordId);
    }
  }, [groupPaymentRecordId]);
  const { apiData: gridData = [], loading } = GetData(
    'get-group-payment-change-log',
    JSON.stringify({ id: groupPaymentID })
  );
  const fields = [
    { label: 'Activity', name: 'activityTableLabel' },
    { name: 'created' },
    { name: 'empId' },
    { name: 'groupData.unappliedFunds' },
    { name: 'groupData.outstandingBalance' },
    { name: 'groupData.pendingRetroCredit' },
    { name: 'groupData.adjustmentApplied' },
    { name: 'groupData.systemBilledAmount' },
    { name: 'groupData.totalAmountDue' },
    {
      label: "Aged Amount",
      name: 'groupData.agedAmount',
      type: "currency"
    },
  ];
  const meta = getMetaData({ moduleName: 'billing-history' });
  if (loading) {
    return <Spinner />;
  }
  return <TableSection data={gridData} grid={{ fields }} meta={meta} />;
};

BillingInfo.propTypes = {
  groupPaymentRecordId: PropTypes.string,
};
BillingInfo.defaultProps = {
  groupPaymentRecordId: null,
};
export default BillingInfo;
