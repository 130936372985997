import React, { useRef } from 'react';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import Route from '../Route';
import ListGrid from '../../BaseComponents/ListGrid';
import HeaderRightContainer from '../../BaseComponents/HeaderRightContainer';

const Label = ({ text }) => {
  return (
    <p
      style={{
        color: '#232323',
        fontSize: '0.8rem',
        fontWeight: 600,
        letterSpacing: '0.05em',
        marginBottom: '0.5em',
        maxWidth: 'fit-content',
        textTransform: 'uppercase',
      }}
    >
      {text}
    </p>
  );
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
};

/**
 * @category Billing
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const MasterCalendar = ({ ...rest }) => {
  const dataTableRef = useRef();

  const defaultFilterValues = [];

  const filters = [
    {
      name: 'groupNumber',
      type: 'Input',
    },
    {
      name: 'groupName',
      type: 'Input',
    },
    {
      fieldEnd: 'billCreationEndDate',
      fieldStart: 'billCreationStartDate',
      name: 'dateRange',
      type: 'DatePicker',
    },
    {
      fieldStart: 'relativeDate',
      label: 'BILL CREATION DATE',
      name: 'relativeDate',
      type: 'Date',
    },
  ];

  const grid = {
    actions: [],
    fields: [
      {
        name: 'groupNameWithSlaMarker',
        sortable: false,
      },
      {
        name: 'groupNumber',
        sortable: true,
        sortName: 'groupNumberSort',
      },
      {
        name: 'payrollCycle',
        sortable: true,
      },
      {
        name: 'billedCycle',
        sortable: true,
      },
      {
        name: 'billCreationDate',
        sortable: true,
      },
      {
        name: 'dueDate',
        sortable: true,
      },
      {
        name: 'enrollEmployees',
        sortable: true,
      },
      {
        name: 'amountOwed',
        sortable: true,
      },
    ],
  };

  return (
    <Route
      header={{
        rightContainer: (
          <HeaderRightContainer
            headerActions={[
              {
                action: '/billing/dashboard',
                icon: 'file',
                label: 'Billing Dashboard',
              },
              {
                action: `/billing/agingReport`,
                icon: 'file-description',
                label: 'Aging Report',
              },
              {
                action: `/billing/cashPremium`,
                icon: 'file-description',
                label: 'Cash Premium Report',
              },
              {
                action: `/billing/commissionReport`,
                icon: 'file-description',
                label: 'Commission Report',
              },
              {
                action: `/billing/masterCalendarReport`,
                icon: 'file-description',
                label: 'Master Billing Calendar Report',
              },
            ]}
            {...rest}
          />
        ),
        title: 'Master Calendar',
        type: 'billing',
      }}
      isPrivate
      permission="billing.page.masterCalendar"
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Master Calendar
      </Box>

      <ListGrid
        ref={dataTableRef}
        defaultFilterValues={defaultFilterValues}
        filters={filters}
        grid={grid}
        gridQueryName="get-master-calendar"
        moduleName="masterCalendar"
      />
    </Route>
  );
};

export default MasterCalendar;
