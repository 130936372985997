import React, { useContext } from 'react';
import { useParams } from '@reach/router';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import Route from '@petcomponents/Route';
import Card from '@basecomponents/Card';
import KeyValuePairs from '@basecomponents/KeyValuePairs';
import GetData from '@src/utilities/get-data';
import getMetaData from '@src/utilities/get-meta-data';
import UploadedDocumentsSection from '@petcomponents/UploadedDocumentsSection';
/**
 * @category User
 * @param {*} rest
 * @returns {React.FC}
 */
const BillingNotesDetails = ({ ...rest }) => {
  const theme = useContext(ThemeContext);
  const queryParams = useParams();
  const billingNotesId = get(queryParams, 'billingNotesId', '');
  const { apiData = {}, loading } = GetData(
    'get-billing-notes-detail',
    JSON.stringify({ billingNotesId })
  );
  const fields = [
    { name: 'created' },
    { name: 'lastModified' },
    { name: 'userId' },
    { name: 'categories' },
    { name: 'billingComment' },
    { name: 'payrollCycleNo' },
  ];
  const meta = getMetaData({ moduleName: 'billing-notes' });

  const isBilling = !!get(apiData, 'payrollScheduleId', false);

  const documentsDataList = apiData?.fileUrls?.split(',').map((item) => ({
    attachmentName: item.substring(item.lastIndexOf('/') + 1),
    s3Key: item,
  }));

  return (
    <Route
      header={{
        title: 'Billing Notes details',
        type: 'billing-notes',
      }}
      isPrivate
      permission="group.page.notes"
      {...rest}
    >
      <Box as="h2" sx={{ ml: 5, py: 3 }}>
        {loading && (
          <PulseLoader
            color={get(theme, 'colors.accent')}
            data-testid="loader"
            size={5}
          />
        )}
        {!loading && isBilling
          ? 'Billing Notes Details'
          : 'Group Notes Details'}
      </Box>
      <Card loading={loading}>
        <KeyValuePairs
          data={apiData}
          fields={fields}
          innerSxProps={{ flexGrow: 1 }}
          meta={meta}
        />
      </Card>
      {documentsDataList?.length && (
        <>
          <Box as="h2" ml={5}>
            Documents
          </Box>
          <Card loading={loading}>
            <UploadedDocumentsSection
              documentsList={
                Array.isArray(documentsDataList) && documentsDataList
              }
              setSignUrl
              {...rest}
            />
          </Card>
        </>
      )}
    </Route>
  );
};

BillingNotesDetails.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default BillingNotesDetails;
