import get from 'lodash/get';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import {useTranslation} from "react-i18next";
import GetData from '../../../../utilities/get-data';

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const QleStatus = ({ value }) => {
  const { colors } = useContext(ThemeContext);
  const { i18n } = useTranslation();
  const data = [
    GetData('get-enum-options', JSON.stringify([{ params: 'RequestStatus' }])),
  ];
  if (get(data, '0.loading', false)) {
    return <PulseLoader color={`${colors.accentSecondary}`} size={7} />;
  }
  const status = get(data, '0.apiData.RequestStatus', []);
  const qleStatus = status.find((item) => item.value === value);
  // eslint-disable-next-line no-nested-ternary
  return qleStatus ?  (i18n.language === 'fr' && get(qleStatus, 'frenchValue', '') !== '') ? get(qleStatus, 'frenchValue') : get(qleStatus, 'label') : value;
};

QleStatus.propTypes = {
  value: PropTypes.string.isRequired,
};

export default QleStatus;
