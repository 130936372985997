import { AuthContext } from '@basecomponents/Auth';
import Box from '@basecomponents/Box';
import Dropdown from '@basecomponents/Dropdown';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { required } from '@utils/validators';
import { useTranslation } from 'react-i18next';
import Modal from '../../../BaseComponents/Modal';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql';
import GetData from '../../../../utilities/get-data';
import getAdminGroup from '../../../../utilities/get-default-group-admin';
import useSnackbar from '../../../../utilities/use-snackbar';
import ListGrid from '../../../BaseComponents/ListGrid';
import InputText from '../../../BaseComponents/Input';
import Route from '../../Route';
import ToolbarButton from '../../../BaseComponents/ToolbarButton';
import config from '../../../../config.json';
import {
  SORT_VALUE
} from '../../../../utilities/constants';

/**
 * @category QLE
 * @param {Object} client
 * @param {*} rest
 * @returns {React.FC}
 */
const LifeEventsDashboardPage = ({ client, ...rest }) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation()
  const userId = get(user, 'customSystemUserId', '');
  const [qleData, setQleData] = useState({});
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [unassign, setUnassign] = useState({});
  const [reopen, setReopen] = useState({});
  const [terminateQle, setTerminateQle] = useState({});

  const role = get(user, 'customRole', '');
  const { groupNumber } = getAdminGroup(userId, role);
  const {
    apiData: {
      ReopenReasonType = [],
      UserRole = [],
      RequestStatus = [],
      QleType = [],
    },
  } = GetData(
    'get-enum-options',
    JSON.stringify([
      { params: ['ReopenReasonType', 'UserRole', 'RequestStatus', 'QleType'] },
    ])
  );
  let defaultFilterValues = [];
  if (role.toUpperCase() === get(UserRole, '[1].value', '')) {
    defaultFilterValues = [{ groupNumber, sort: 'requestedDate,desc' }];
  } else if (role.toUpperCase() === get(UserRole, '[0].value', '')) {
    defaultFilterValues = [{ createdFor: userId, sort: 'requestedDate,desc' }];
  } else if (role === get(UserRole, '[6].label', '')) {
    const benAdminId = get(user, 'customSystemBenadminId');
    defaultFilterValues = [{ benAdminId }];
  } else if (role === get(UserRole, '[7].label', '')) {
    const benBrokerId = get(user, 'customSystemBrokerId');
    defaultFilterValues = [{ benBrokerId }];
  }

  const options = async (data) => {
    const options = await client
      .query({
        fetchPolicy: 'no-cache',
        query: remoteActionQuery,
        variables: {
          name: 'get-all-users',
          params: JSON.stringify({
            active: true,
            deleted: false,
            fullName: data,
            role: ['SUPER_ADMIN', 'ADMIN'],
            size: 5,
          }),
        },
      })
      .then((response) => {
        const { content } = JSON.parse(
          get(response, 'data.remoteAction.data', {})
        );
        const temp = content.map((item) => {
          return {
            label: `${item.fullName} (${item.email})`,
            value: item.userId,
          };
        });
        return temp;
      });
    return options;
  };

  const filters = [
    { loadOptions: options, name: 'assignedTo', type: 'UserDropdown' },
    {
      name: t('metadata.qleNumber'),
      type: 'Input',
    },
    {
      name: 'groupName',
      type: 'Input',
    },
    {
      label: t('filters.employeeName'),
      name: 'createdForFullName',
      type: 'Input',
    },
    {
      label: t('filters.employeeEmail'),
      name: 'createdForEmail',
      type: 'Input',
    },
    {
      fieldEnd: 'requestEndDate',
      fieldStart: 'requestStartDate',
      name: 'requestDate',
      type: 'DatePicker',
    },
    {
      label: t('filters.requestStatus'),
      name: 'includedRequestStatus',
      options: RequestStatus,
      type: 'MultiselectDropdown',
    },
    {
      label: t('filters.sortBy'),
      name: 'sort',
      options: SORT_VALUE.LIFE_EVENTS_DASHBOARD,
      type: 'Dropdown',
    },
    {
      label: t('metadata.qleType'),
      name: 'qleType',
      options: QleType,
      type: 'Dropdown',
    },
  ];
  const grid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'details',
            link: `/life-events/${get(data, 'qleEventId')}/details`,
          };
        },
        label: t('lifeEvents.dashboard.view'),
        name: 'view',
      },
      {
        getProps: (data, refetch) => {
          return {
            icon: 'edit',
            isDisabled:
              get(data, 'qleType') === 'LATE_ENROLLMENT' &&
              get(data, 'requestStatus') === 'DECLINED',
            onClick: () => setQleData({ ...data, refetch }),
          };
        },
        label: t('lifeEvents.dashboard.assignTo'),
        name: 'edit',
        permission: 'lifeEvent.button.assign',
      },
      {
        getProps: (data, refetch) => {
          return {
            icon: 'remove',
            isDisabled:
              !get(data, 'assignedTo', false) ||
              (get(data, 'qleType') === 'LATE_ENROLLMENT' &&
                get(data, 'requestStatus') === 'DECLINED'),
            onClick: () => setUnassign({ ...data, refetch }),
          };
        },
        label: t('lifeEvents.dashboard.unassign'),
        name: 'remove',
        permission: 'lifeEvent.button.unassign',
      },
      {
        getProps: (data, refetch) => {
          return {
            icon: 'refresh',
            isDisabled:
              get(data, 'requestStatus') !== 'DECLINED' ||
              (get(data, 'qleType') === 'LATE_ENROLLMENT' &&
                get(data, 'requestStatus') === 'DECLINED'),
            onClick: () => setReopen({ ...data, refetch }),
          };
        },
        label: t('lifeEvents.dashboard.reopen'),
        name: 'refresh',
        permission: 'lifeEvent.button.reopen',
      },
      {
        getProps: (data, refetch) => {
          return {
            icon: 'bin',
            isDisabled:
              get(data, 'qleType') !== 'NEW_PET' ||
              ['REVIEWED', 'DECLINED'].includes(get(data, 'requestStatus')),
            onClick: () => setTerminateQle({ ...data, refetch }),
          };
        },
        label: t('lifeEvents.dashboard.remove'),
        name: 'remove',
        permission: 'lifeEvent.button.removeQle',
      },
    ],
    fields: [
      { name: 'qleNumber', sortable: true },
      { name: 'groupName', sortable: true },
      { label: t('lifeEvents.details.employee'), name: 'createdFor' },
      {
        name: 'requestedDate',
        sortable: true,
        sortDefault: 'desc',
        type: 'date',
      },
      { name: 'qleType' },
      { name: 'assignedTo' },
      {
        name: 'requestStatus',
      },
      {
        name: 'lastModified',
        sortable: true,
        sortDefault: 'desc',
        type: 'date',
      },
    ],
  };
  return (
    <Route
      header={{
        title: t('lifeEvents.dashboard.title'),
        type: 'life-events',
      }}
      isPrivate
      permission="lifeEvents.page.dashboard"
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {t('lifeEvents.dashboard.heading')}
      </Box>
      <ApolloConsumer>
        {(client) => {
          return (
            <>
              <ListGrid
                defaultFilterValues={defaultFilterValues}
                filters={filters}
                grid={grid}
                gridQueryName="get-all-qle-events"
                moduleName="life-events"
                {...rest}
              />
              {!isEmpty(reopen) && (
                <Modal
                  isOpen={!isEmpty(reopen)}
                  onClose={() => setReopen({})}
                  title={t('modal.reopenQleRequest')}
                >
                  <FinalForm
                    initialValues={{
                      assignedTo: get(reopen, 'assignedTo', ''),
                      requestedDate: get(reopen, 'requestedDate', ''),
                    }}
                    onSubmit={(value) =>
                      client
                        .query({
                          fetchPolicy: 'no-cache',
                          query: remoteActionQuery,
                          variables: {
                            name: 'update-qle',
                            params: JSON.stringify({
                              lastModified: value.lastModified,
                              qleEventId: get(reopen, 'qleEventId', ''),
                              reopenReasonType: value.reopenReasonType,
                              requestStatus: 'REOPEN',
                              userId,
                            }),
                          },
                        })
                        .then(async () => {
                          await reopen.refetch();
                          setReopen({});
                        })
                        .catch((e) =>
                          setErrorSnack(
                            t('snack.error.errorWithMessage', { message: e.message }),
                            config.notificationDuration
                          )
                        )
                    }
                    render={({ values, submitting, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <Box>
                          <Field
                            aria-label="Reopen Request Type"
                            component={Dropdown}
                            label={t('metadata.reopenRequestType')}
                            name="reopenReasonType"
                            options={ReopenReasonType}
                            validate={required}
                            {...rest}
                          />
                          {get(values, 'reopenReasonType', '') === 'OTHER' ? (
                            <>
                              <Field
                                aria-label="Reason"
                                component={InputText}
                                label={t('lifeEvents.dashboard.reason')}
                                name="reason"
                                validate={required}
                                {...rest}
                              />
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  mt: '30px',
                                  width: '100%',
                                }}
                              >
                                <ToolbarButton
                                  g="error"
                                  label={t('common.cancel')}
                                  onClick={() => setReopen({})}
                                  sx={{
                                    bg: 'error',
                                  }}
                                  width="150px"
                                />
                                <ToolbarButton
                                  g="error"
                                  label={t('common.submit')}
                                  submitting={submitting}
                                  type="submit"
                                  width="150px"
                                />
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  mt: '30px',
                                  width: '100%',
                                }}
                              >
                                <ToolbarButton
                                  g="error"
                                  label={t('common.cancel')}
                                  onClick={() => setReopen({})}
                                  sx={{
                                    bg: 'error',
                                  }}
                                  width="150px"
                                />
                                <ToolbarButton
                                  g="error"
                                  label={t('common.submit')}
                                  submitting={submitting}
                                  type="submit"
                                  width="150px"
                                />
                              </Box>
                            </>
                          )}
                        </Box>
                      </form>
                    )}
                  />
                </Modal>
              )}
              {!isEmpty(unassign) && (
                <Modal
                  isOpen={!isEmpty(unassign)}
                  onClose={() => setUnassign({})}
                  title={t('lifeEvents.dashboard.unassignUser')}
                >
                  <FinalForm
                    initialValues={{
                      assignedTo: get(unassign, 'assignedTo', ''),
                    }}
                    onSubmit={() =>
                      client
                        .query({
                          fetchPolicy: 'no-cache',
                          query: remoteActionQuery,
                          variables: {
                            name: 'update-qle',
                            params: JSON.stringify({
                              assignedBy: userId,
                              assignedTo: '',
                              qleEventId: get(unassign, 'qleEventId', ''),
                              requestStatus: ['REVIEWED', 'DECLINED'].includes(
                                get(unassign, 'requestStatus')
                              )
                                ? get(unassign, 'requestStatus')
                                : 'UNASSIGNED',
                              userId,
                            }),
                          },
                        })
                        .then(async () => {
                          await unassign.refetch();
                          setUnassign({});
                        })
                        .catch((e) =>
                          setErrorSnack(
                            t('snack.error.errorWithMessage', { message: e.message }),
                            config.notificationDuration
                          )
                        )
                    }
                    render={(formContext) => (
                      <form onSubmit={formContext.handleSubmit}>
                        <Box>
                          {t('lifeEvents.dashboard.unassignMessage')}
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-around',
                              mt: '30px',
                            }}
                          >
                            <ToolbarButton
                              g="error"
                              label={t('common.cancel')}
                              onClick={() => setUnassign({})}
                              sx={{
                                bg: 'error',
                              }}
                              width="150px"
                            />
                            <ToolbarButton
                              g="error"
                              label={t('common.submit')}
                              submitting={formContext.submitting}
                              type="submit"
                              width="150px"
                            />
                          </Box>
                        </Box>
                      </form>
                    )}
                  />
                </Modal>
              )}
              <Modal
                closeText={t('common.cancel')}
                isOpen={!isEmpty(terminateQle)}
                onClose={() => {
                  setTerminateQle({});
                }}
                onSubmit={() => {
                  const params = {
                    deleted: true,
                    dependentId: get(
                      terminateQle,
                      'dependentData.0.employeeDependentId',
                      ''
                    ),
                    status: 'INACTIVE',
                    userId,
                  };
                  client
                    .query({
                      fetchPolicy: 'no-cache',
                      query: remoteActionQuery,
                      variables: {
                        name: 'update-employee-dependent',
                        params: JSON.stringify(params),
                      },
                    })
                    .then(async () => {
                      setTerminateQle({});
                      terminateQle.refetch();
                    })
                    .catch((e) => {
                      setErrorSnack(
                        t('snack.error.errorWithMessage', { message: e.message }),
                        config.notificationDuration
                      );
                      setTerminateQle({});
                    });
                }}
                submitText={t('common.delete')}
                title={t('lifeEvents.dashboard.deleteRequest')}
              >
                <Box>
                  {t('lifeEvents.dashboard.deleteRequestLegend')}
                  <Box
                    sx={{
                      display: 'flex',
                      fontWeight: 'bold',
                      justifyContent: 'center',
                      my: 5,
                    }}
                  >
                    {t('common.cannotBeUndone')}
                  </Box>
                </Box>
              </Modal>
              {!isEmpty(qleData) && (
                <Modal
                  isOpen={!isEmpty(qleData)}
                  onClose={() => setQleData({})}
                  title={t('lifeEvents.dashboard.assignToUser')}
                >
                  <FinalForm
                    initialValues={{
                      assignedTo: get(qleData, 'assignedTo', ''),
                    }}
                    onSubmit={(values) =>
                      client
                        .query({
                          fetchPolicy: 'no-cache',
                          query: remoteActionQuery,
                          variables: {
                            name: 'update-qle',
                            params: JSON.stringify({
                              assignedBy: userId,
                              assignedTo: values.assignedTo,
                              qleEventId: get(qleData, 'qleEventId', ''),
                              requestStatus: 'UNDER_REVIEW',
                              userId,
                            }),
                          },
                        })
                        .then(async () => {
                          await qleData.refetch();
                          setQleData({});
                        })
                        .catch((e) =>
                          setErrorSnack(
                            t('snack.error.errorWithMessage', { message: e.message }),
                            config.notificationDuration
                          )
                        )
                    }
                    render={(formContext) => (
                      <form onSubmit={formContext.handleSubmit}>
                        <Box>
                          <Field
                            aria-label="Assign to User"
                            component={Dropdown}
                            isClearable
                            label={t('lifeEvents.dashboard.assignToUser')}
                            loadOptions={async (data) => {
                              return options(data);
                            }}
                            name="assignedTo"
                            placeholder={t('common.typeToSearch')}
                            validate={required}
                            {...rest}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-around',
                              mt: '30px',
                            }}
                          >
                            <ToolbarButton
                              g="error"
                              label={t('common.cancel')}
                              onClick={() => setQleData({})}
                              sx={{
                                bg: 'error',
                              }}
                              width="150px"
                            />
                            <ToolbarButton
                              g="error"
                              label={t('common.submit')}
                              submitting={formContext.submitting}
                              type="submit"
                              width="150px"
                            />
                          </Box>
                        </Box>
                      </form>
                    )}
                  />
                </Modal>
              )}
            </>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

LifeEventsDashboardPage.defaultProps = {};

LifeEventsDashboardPage.propTypes = {
  client: PropTypes.shape({
    query: PropTypes.func.isRequired,
  }).isRequired,
};

const QLEDashboard = ({ ...rest }) => (
  <ApolloConsumer>
    {(client) => <LifeEventsDashboardPage client={client} {...rest} />}
  </ApolloConsumer>
);

export default QLEDashboard;
