/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import { AuthContext } from '@basecomponents/Auth';
import PropTypes from 'prop-types';
import { useLocation, useParams } from '@reach/router';
import { v4 as uuidv4 } from 'uuid';
import arrayMutators from 'final-form-arrays';
import {
  E_CONSENT_URLS,
  PET_AGE,
  PET_SPECIES,
  USER_ROLE,
} from '@utils/constants';
import InputNumber from '@basecomponents/InputNumber';
import SingleDatePicker from '@basecomponents/InputDate';
import InputText from '@basecomponents/Input';
import dateUtils from '@src/utilities/date-utils';
import { labelHandler } from '@src/utilities/label-utils';
import { composeValidations, required } from '@src/utilities/validators';
import { maxLength } from '@src/utilities/validations';
import { FieldArray } from 'react-final-form-arrays';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
import GetData from '@src/utilities/get-data';
import remoteActionQuery from '@queries/remote-action.gql';
import Spinner from '@basecomponents/Spinner';
import Route from '@petcomponents/Route';
import ProgressBar from '@basecomponents/ProgressBar';
import config, { variation } from '@src/config.json';
import { useTranslation } from 'react-i18next';
import InputDropdown from '@basecomponents/Dropdown';
import ToolbarButton from '@basecomponents/ToolbarButton';
import { get, isArray, isEmpty } from 'lodash';
import Modal from '@basecomponents/Modal';
import { navigate } from 'gatsby';
import generateRedirectPath from '@src/utilities/generate-redirect-path';
import { parse } from 'query-string';
import localStorage from '@src/utilities/local-storage';
import { filter, flow, get as getFp, getOr, map, size } from 'lodash/fp';
import { produce } from 'immer';
import { useApolloClient } from '@apollo/client';
import Checkbox from '@basecomponents/InputCheckbox';
import useSnackbar from '@src/utilities/use-snackbar';
import i18n from '@src/utilities/i18n';
import { PhoneButton } from '@basecomponents/PhoneButton';
import { EmailButton } from '@basecomponents/EmailButton';
import useSsm from '@src/utilities/hooks/useSsm';

const lessThan65forCats = (name) => (value, values) => {
  if (get(values, `[${name}].species`, '') === 'Cat') {
    return (
      Number(value) > 65 &&
      'Weight must not be greater that 65 pounds for Cats.'
    );
  }
};

const lessThan0 = (name) => (value, values) => {
  if (get(values, `[${name}].species`, '') === ('Cat' || 'Dog')) {
    return Number(value) === 0 && 'Weight must be greater that 0.';
  }
};

const commonFieldSxHalf = {
  mx: 'auto',
  padding: 3,
  width: '25rem',
};
const fieldSxHalf = {
  mx: 'auto',
  padding: 3,
};
const stepName = 'step';
const PDBA_RENEWAL_LOCAL_PREFIX = 'EmployeeDependentData';

export default function PostEnrollmentPetsRenewal({ ...rest }) {
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const userId = get(user, 'customSystemUserId', '');
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const empId = get(params, 'employeeId', '');
  const groupNumber = get(params, 'groupNumber', '');
  const queryParams = parse(location.search);
  const providedStep = parseInt(get(queryParams, stepName, '0'), 10);
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [agreeEC, setAgreeEC] = useState(null);
  const [agreeCASL, setAgreeCASL] = useState(null);
  const [misrepresentation, setMisrepresentation] = useState(false);
  const [fraudWarning, setFraudWarning] = useState(false);
  const [acknowledgement, setAcknowledgement] = useState(false);
  const [premiumChanges, setPremiumChanges] = useState(false);
  const { apiData: summary = {}, loading: summaryLoading } = GetData(
    'get-enrollment-summary',
    JSON.stringify({ empId })
  );
  const { groupResponse = {} } = summary;
  const groupMga = get(groupResponse, 'mga', '');
  let url = '';
  if (i18n.language === 'fr' && groupMga === 'PTZ_SODACO') {
    url = E_CONSENT_URLS.PTZ_SODACO_FR;
  } else {
    url = variation === 'demo' ? 'DEMO' : groupMga;
  }

  const [dependentsState, dependentsDispatch] = useReducer(
    produce((draft, { type, payload }) => {
      switch (type) {
        case 'INIT':
          for (let i = 0; i < payload.length; i += 1) {
            // const { numberOfCats, numberOfDogs } = payload[i]
            for (let j = 0; j < payload[i].employeeDependents.length; j += 1) {
              payload[i].employeeDependents[j].UIRemoved = false;
              payload[i].employeeDependents[j].UISwapped = false;
              payload[i].employeeDependents[j].UiId = uuidv4();
              payload[i].employeeDependents[j].UINew = false;
            }
            const dependents = payload[i].employeeDependents;
            payload[i].activeEmployee = dependents.find(
              (x) =>
                x.postEnrollmentPetRemoved === false && x.status === 'ACTIVE'
            );
            // const dependentDogs = flow(
            // 	filter((y) => y?.dependentData?.species === 'Dog'),
            // 	filter(x => x.status === 'ACTIVE'),
            // 	x => x
            // )(dependents)
            // const dependentCats = flow(
            // 	filter((y) => y?.dependentData?.species === 'Cat'),
            // 	filter(x => x.status === 'ACTIVE'),
            // 	x => x
            // )(dependents)
            // const dogUiCount = size(dependentDogs)
            // const catUiCount = size(dependentCats)
            // if (dogUiCount < numberOfDogs) {
            // 	const newDog = {
            // 		policyDate: payload?.[i]?.activeEmployee?.coverageStartDate,
            // 		coverageStartDate: payload?.[i]?.coverageStartDate,
            // 		coverageEndDate: payload?.[i]?.coverageEndDate,
            // 		dependentData: {
            // 			age: null,
            // 			breed: null,
            // 			petName: '',
            // 			species: 'Dog',
            // 			weight: null
            // 		},
            // 		petName: '',
            // 		species: 'Dog',
            // 		status: 'ACTIVE',
            // 		UiId: uuidv4(),
            // 		UINew: true,
            // 		UIRemoved: false,
            // 		weight: null
            // 	}
            // 	payload[i].employeeDependents.push(newDog)
            // }
            // if (catUiCount < numberOfCats) {
            // 	const newCat = {
            // 		policyDate: payload?.[i]?.activeEmployee?.coverageStartDate,
            // 		coverageStartDate: payload?.[i]?.coverageStartDate,
            // 		coverageEndDate: payload?.[i]?.coverageEndDate,
            // 		dependentData: {
            // 			age: null,
            // 			breed: null,
            // 			petName: '',
            // 			species: 'Cat',
            // 			weight: null
            // 		},
            // 		petName: '',
            // 		species: 'Cat',
            // 		status: 'ACTIVE',
            // 		UiId: uuidv4(),
            // 		UINew: true,
            // 		UIRemoved: false,
            // 		weight: null
            // 	}
            // 	payload[i].employeeDependents.push(newCat)
            // }
          }
          return payload;
        case 'REMOVE':
          {
            const { dependentId, employeeId } = payload;
            const empIndex = draft.findIndex(
              (x) => x.employeeId === employeeId
            );
            if (empIndex >= 0) {
              const dependents = draft[empIndex].employeeDependents;
              const dependentIndex = dependents.findIndex(
                (x) => x.employeeDependentId === dependentId
              );
              draft[empIndex].employeeDependents[
                dependentIndex
              ].UIRemoved = true;
            }
          }
          break;
        default:
          break;
      }
    }),
    []
  );
  const {
    apiData: missingDemographics = [],
    loading: missingDemographicsLoading,
  } = GetData(
    'get-all-missing-demographics',
    JSON.stringify({
      empId,
      groupNumber,
    })
  );
  const currentEmpID = missingDemographics?.[providedStep]?.employeeId;
  const { apiData: employeeData = {}, loading: employeeLoading } = GetData(
    'get-group-employee-by-id',
    JSON.stringify({ id: currentEmpID })
  );
  const { apiData: breeds, loading: breedsLoading } = GetData(
    'get-all-breeds',
    JSON.stringify([])
  );
  const [modal, setModal] = useState(false);
  const [swapModal, setSwapModal] = useState(null);
  const [closeModal, setCloseModal] = useState([false, () => {}]);
  const relativeDatetime = get(employeeData, 'relativeDatetime')
    ? dateUtils.setOffset2(get(employeeData, 'relativeDatetime'))
    : dateUtils.setOffset2(new Date());

  const client = useApolloClient();

  useEffect(() => {
    if (Array.isArray(missingDemographics)) {
      // TODO filter by groupActive === true
      dependentsDispatch({
        payload: missingDemographics.filter(
          (o) => o.hasDemographicChanges || o.nextTermEmployeeId === null
        ),
        type: 'INIT',
      });
    }
  }, [JSON.stringify(missingDemographics)]);

  const { apiData = {}, loading } = GetData(
    'get-group-employee-by-id',
    JSON.stringify({ id: currentEmpID })
  );

  const { apiData: paramsData = {}, PALoading } = GetData(
    'get-ssm-params',
    JSON.stringify({ paramName: `${config.environment}_modelLaw_PA` })
  );

  const { apiData: paramsOHData = {}, loading: OHLoading } = GetData(
    'get-ssm-params',
    JSON.stringify({ paramName: `${config.environment}_modelLaw_OH` })
  );
  const pdbaRenewalConfigEnabled = useSsm('iaic-pdba-renewal')

  const renderDisclosureContent = () => {
    let disclosureContent;

    switch (true) {
      case paramsOHData.value === 'true' &&
        paramsOHData.name === `${config.environment}_modelLaw_OH`:
        disclosureContent = t('common.insurerDisclosureOH', {
          url: config.insurerDisclosuresUrlV3 || '#',
        });
        break;

      case paramsData.value === 'true':
        disclosureContent = t('common.insurerDisclosurePA', {
          url: config.insurerDisclosuresUrlV2 || '#',
        });
        break;

      default:
        disclosureContent = t('common.insurerDisclosure', {
          url: config.insurerDisclosuresUrl || '#',
        });
        break;
    }
    return disclosureContent;
  };

  const planId = get(apiData, 'employeePlanConfirmation.planId', '');
  const { apiData: planPricing = [], loading: planPricingLoading } = GetData(
    'get-plan-pricing',
    JSON.stringify({ planId }),
    !planId
  );

  const employeePlanChoices = get(apiData, 'planChoiceEmployees', []);
  const selectedPlan = employeePlanChoices
    .filter((item) => item.acceptGroupPlanChoice)
    .map((item) => {
      if (Array.isArray(planPricing)) {
        const plan =
          planPricing.find((v) => v.planChoiceId === item.planChoiceId) || {};
        return { ...item, ...plan };
      }
      return item;
    });

  function getMaxAge() {
    const maxAge = get(selectedPlan, '[0].maxAge', Infinity);
    if (maxAge === 0) return Infinity;
    return maxAge;
  }

  const maxAge = getMaxAge();

  const onSubmitData = async () => {
    const apiCall = (pathName, params) =>
      client.query({
        fetchPolicy: 'no-cache',
        query: remoteActionQuery,
        variables: {
          name: pathName,
          params: JSON.stringify(params),
        },
      });
    const payload = [];
    let lastEmpId = localStorage.getItem(`${PDBA_RENEWAL_LOCAL_PREFIX}-LAST`);
    while (
      lastEmpId !== null &&
      localStorage.getItem(`${PDBA_RENEWAL_LOCAL_PREFIX}-${lastEmpId}`)
    ) {
      const previousTermEmployeeData = localStorage.getItem(
        `${PDBA_RENEWAL_LOCAL_PREFIX}-${lastEmpId}`
      );
      let parsedPreviousEmpData = {};
      if (JSON.stringify(previousTermEmployeeData).length > 2) {
        parsedPreviousEmpData = JSON.parse(previousTermEmployeeData);
        const currentPayload = {
          employeeDependentList: parsedPreviousEmpData?.pets,
          employeeId: parsedPreviousEmpData?.employeeId,
        };
        payload.unshift(currentPayload);
        lastEmpId = parsedPreviousEmpData?.previousEmployeeId;
      }
    }
    const employeeIds = payload.map(({ employeeId }) => employeeId);

    const employeeId = localStorage.getItem(
      `${PDBA_RENEWAL_LOCAL_PREFIX}-LAST`
    );
    if (!employeeId) {
      setErrorSnack(
        t('snack.error.errorWithMessage', {
          message: 'Please complete the previous steps before EConsent',
        }),
        config.notificationDuration
      );
    }
    try {
      await apiCall('update-pet-demographic-for-renewal', {
        employeeId,
        payload,
        userId,
      });
      await apiCall('bulk-employee-demographic-confirmation-update', {
        currentEmployeeId: employeeId,
        employeeIds,
        hasDemographicChanges: false,
      });
      let updateEmp = {};
      if (!get(apiData, 'isEconsentSubmitted')) {
        updateEmp = {
          agree: agreeEC,
          caslAgree: agreeCASL,
          employeeId,
          isEconsentSubmitted: true,
          isPetSwapPerformed: true,
          userId,
        };
      } else {
        updateEmp = {
          employeeId,
          isPetSwapPerformed: true,
          userId,
        };
      }
      await apiCall('update-employee', { ...updateEmp });
      let lastEmpId = localStorage.getItem(`${PDBA_RENEWAL_LOCAL_PREFIX}-LAST`);
      while (lastEmpId) {
        const storageKey = `${PDBA_RENEWAL_LOCAL_PREFIX}-${lastEmpId}`;
        const previousTermEmployeeData = localStorage.getItem(storageKey);
        if (JSON.stringify(previousTermEmployeeData).length > 2) {
          const parsedPreviousEmpData = JSON.parse(previousTermEmployeeData);
          localStorage.removeItem(storageKey);
          lastEmpId = parsedPreviousEmpData?.previousEmployeeId;
        }
      }
      localStorage.removeItem(`${PDBA_RENEWAL_LOCAL_PREFIX}-LAST`);
      if (
        ![
          USER_ROLE.EMPLOYEE,
          USER_ROLE.TERMINATED_EMPLOYEE,
          USER_ROLE.RENEWAL_POLICY_DELETED,
        ].includes(role)
      ) {
        return navigate(`/group-employees/${employeeId}/details`, {
          replace: true,
        });
      }
      navigate(`/home`, { replace: true });
    } catch (err) {
      setErrorSnack(
        t('snack.error.errorWithMessage', {
          message: err.message,
        }),
        config.notificationDuration
      );
    }
  };

  if (
    breedsLoading ||
    loading ||
    planPricingLoading ||
    missingDemographicsLoading ||
    employeeLoading ||
    PALoading || OHLoading ||
    summaryLoading
  ) {
    return <Spinner />;
  }
  const tabsData = dependentsState.map((demographic, index) => {
    const {
      employeeId,
      numberOfCats,
      numberOfDogs,
      employeeDependents,
      groupActive,
      coverageFromDate,
      coverageToDate,
      policyTerm,
    } = demographic;
    const removedPets = employeeDependents.filter(
      ({ postEnrollmentPetRemoved }) => postEnrollmentPetRemoved === true
    );
    const catRemovedDates = flow(
      filter((x) => x?.dependentData?.species === 'Cat'),
      filter((x) => x?.status === 'ACTIVE'),
      map((x) => ({
        date: getFp('coverageEndDate', x),
        removalDate: getFp('removalDate', x),
        removalEventDate: getFp('removalEventDate', x),
      }))
    )(removedPets);
    const dogRemovedDates = flow(
      filter((x) => x?.dependentData?.species === 'Dog'),
      filter((x) => x?.status === 'ACTIVE'),
      map((x) => ({
        date: getFp('coverageEndDate', x),
        removalDate: getFp('removalDate', x),
        removalEventDate: getFp('removalEventDate', x)
          ? getFp('removalEventDate', x)
          : null,
      }))
    )(removedPets);

    const petStatus = [
      'ACTIVE',
      'PENDING',
      'TERMINATED',
      'INACTIVE',
      'INELIGIBLE',
    ];
    const previousTermEmployeeId = demographic.priorTermEmployeeId;
    const previousTermEmployeeData = localStorage.getItem(
      `${PDBA_RENEWAL_LOCAL_PREFIX}-${previousTermEmployeeId}`
    );
    let parsedPreviousEmpData = [];
    if (previousTermEmployeeData) {
      parsedPreviousEmpData = JSON.parse(previousTermEmployeeData);
    }
    const pastEmployeeDate = employeeDependents.find(
      (x) => x.coverageStartDate === x.coverageEndDate
    );
    const activeEmployeeDate =
      employeeDependents.find(
        (x) => x.postEnrollmentPetRemoved === false && x.status === 'ACTIVE'
      ) ||
      employeeDependents.find(
        (x) => x.postEnrollmentPetRemoved === true && x.status === 'ACTIVE'
      );
    const allPets = employeeDependents
      .map((data) => {
        const { dependentData, ...rest } = data;
        // Clamp age for pets
        if (dependentData?.species === 'Dog' && dependentData?.age > 16) {
          dependentData.age = 16;
        } else if (
          dependentData?.species === 'Cat' &&
          dependentData?.age > 24
        ) {
          dependentData.age = 24;
        }
        let UIPrefilled = false;
        if (
          dependentData.age &&
          dependentData.breed &&
          dependentData.petName &&
          dependentData.species
        ) {
          UIPrefilled = true;
        }
        return { ...rest, UIPrefilled, dependentData };
      })
      .map((item) => {
        const { age, ...rest } = item?.dependentData;
        return {
          age: age === 0 ? null : age,
          ...item,
          ...rest,
          maxAgeData: false,
          policyDate: dateUtils.setOffset2(
            item?.enrollmentEventDate || item.coverageStartDate
          ),
          terminatedPetMaxAgeData: false,
        };
      })
      // Get previous pet data if pet was present in previous term
      .map((current) => {
        const previousTermData = parsedPreviousEmpData?.pets?.find(
          (previous) => {
            return (
              previous.employeeDependentId ===
                current.parentEmployeeDependentId ||
              previous.dependentData.uiNewMaxAgeCard
            );
          }
        );
        if (!previousTermData) return current;
        let newAge = previousTermData?.age;
        if (['ACTIVE'].includes(previousTermData?.status)) {
          if (previousTermData?.age <= maxAge) {
            if (
              previousTermData?.species === 'Dog' &&
              previousTermData?.age < 16
            ) {
              newAge = previousTermData?.age + 1;
            } else if (
              previousTermData?.species === 'Cat' &&
              previousTermData?.age < 24
            ) {
              newAge = previousTermData?.age + 1;
            }
          }
        }
        current.dependentData = {
          age: newAge,
          breed: previousTermData?.breed,
          petName: previousTermData?.petName,
          species: previousTermData?.species,
          uiPetTermination: previousTermData?.dependentData?.uiPetTermination,
          weight: previousTermData?.weight,
        };
        current.age = newAge;
        current.breed = previousTermData?.breed;
        current.petName = previousTermData?.petName;
        current.species = previousTermData?.species;
        current.weight = previousTermData?.weight;
        current.postEnrollmentPetRemoved =
          previousTermData?.postEnrollmentPetRemoved;
        current.UIPrefilled = true;
        current.UIRemoved = previousTermData?.UIRemoved;
        current.status = previousTermData?.status;
        if (previousTermData?.dependentData?.uiPetTermination) {
          current.coverageEndDate = pastEmployeeDate?.coverageEndDate;
        } else {
          current.coverageEndDate = activeEmployeeDate?.coverageEndDate;
        }

        return current;
      })
      .filter(({ status }) => [...petStatus].includes(status));

    const addSwapData = (replacedDependentId, dataValues, fields) => {
      const swapData = {
        employeeDependentId: null,
        userId,
        employeeId,
        relation: 'Pet',
        deleted: false,
        petName: '',
        species: dataValues?.species,
        weight: null,
        breed: '',
        age: null,
        policyDate: dateUtils.setOffset2(
          dataValues?.enrollmentEventDate || dataValues?.coverageStartDate
        ),
        dependentData: {
          petName: '',
          species: dataValues?.species,
          weight: null,
          breed: '',
          age: null,
          replacedDependentId,
        },
        status: 'ACTIVE',
        planPrice: dataValues.planPrice,
        employerContribution: dataValues.employerContribution,
        employeeContribution: dataValues.employeeContribution,
        rstAmount: dataValues.rstAmount,
        employeeRstAmount: dataValues.employeeRstAmount,
        employerRstAmount: dataValues.employerRstAmount,
        coverageStartDate: dataValues.coverageStartDate,
        coverageEndDate: dataValues.coverageEndDate,
        certificateNumber: dataValues.certificateNumber,
        sentToSelerix: dataValues.sentToSelerix,
        parentEmployeeDependentId: dataValues.parentEmployeeDependentId,
        payPeriodPremiumPayPrice: dataValues.payPeriodPremiumPayPrice,
        payPeriodPremiumEmployee: dataValues.payPeriodPremiumEmployee,
        payPeriodPremiumEmployer: dataValues.payPeriodPremiumEmployer,
        postEnrollmentPetRemoved: false,
        reason: null,
        replacedDependentId,
        enrollmentEventDate: dataValues.enrollmentEventDate,
        removalEventDate: dataValues.removalEventDate,
        demographicChangesRequired: dataValues.demographicChangesRequired,
      };
      fields.push({ ...swapData });
    };
    return {
      render: () => (
        <FinalForm
          initialValues={{
            catRemovedDates,
            dogRemovedDates,
            pets: allPets,
            // .filter(x => {
            // 	if (get(x, 'uiPetTermination', false)) {
            // 		return false
            // 	}
            // 	return true
            // })
          }}
          keepDirtyOnReinitialize
          mutators={arrayMutators}
          onSubmit={() => {
            setModal(true);
          }}
          render={({ handleSubmit, submitting, form, values }) => {
            const activeCatData = flow(
              getOr([], 'pets'),
              filter((x) => x.dependentData.species === 'Cat'),
              filter((x) => ['ACTIVE'].includes(x.status)),
              filter((x) => !x.UIRemoved),
              size
            )(values);
            const activeDogData = flow(
              getOr([], 'pets'),
              filter((x) => x.dependentData.species === 'Dog'),
              filter((x) => ['ACTIVE'].includes(x.status)),
              filter((x) => !x.UIRemoved),
              size
            )(values);
            const maxAgeCat = flow(
              getOr([], 'pets'),
              filter((x) => x.dependentData.species === 'Cat'),
              filter((x) => ['ACTIVE', 'INELIGIBLE'].includes(x.status)),
              filter((x) => !x.UIRemoved),
              size,
              (x) => x > numberOfCats
            )(values);
            const maxAgeDog = flow(
              getOr([], 'pets'),
              filter((x) => x.dependentData.species === 'Dog'),
              filter((x) => ['ACTIVE', 'INELIGIBLE'].includes(x.status)),
              filter((x) => !x.UIRemoved),
              size,
              (x) => x > numberOfDogs
            )(values);
            return (
              <>
                <Modal
                  closeText="No"
                  isOpen={modal}
                  onClose={() => setModal(false)}
                  onSubmit={() => {
                    setModal(false);
                    const activePetsFromBackendDates = allPets
                      .filter(
                        ({ postEnrollmentPetRemoved }) =>
                          postEnrollmentPetRemoved !== true
                      )
                      .map(({ coverageEndDate }) => coverageEndDate);

                    const newValues = values?.pets?.map((x) => {
                      x.dependentData = {
                        age: x.age,
                        breed: x.breed,
                        petName: x.petName,
                        reason: x.reason,
                        species: x.species,
                        uiPetTermination:
                          x.age > maxAge &&
                          groupActive &&
                          (maxAgeDog || maxAgeCat)
                            ? true
                            : x.UIRemoved,
                        weight: x.weight,
                        replacedDependentId: x.replacedDependentId,
                      };
                      if (x.UIRemoved) {
                        x.postEnrollmentPetRemoved = true;
                        if (
                          (x.age > maxAge &&
                            get(x, 'parentEmployeeDependentId', null) ===
                              null) ||
                          x.status === 'INELIGIBLE'
                        ) {
                          x.status = 'INELIGIBLE';
                          x.reason = 'Pet Over Plan Max Age';
                          x.terminatedPetMaxAgeData = true;
                          x.uiRenewalMaxAged = true;
                          x.removalDate =
                            dateUtils.setAPIDateOnly(relativeDatetime);
                          x.coverageEndDate = dateUtils.setAPIDateOnly(
                            dateUtils.setOffset2(x.coverageStartDate)
                          );
                          x.removalEventDate = dateUtils.setAPIDateOnly(
                            dateUtils.setOffset2(
                              x?.enrollmentEventDate || x?.coverageStartDate
                            )
                          );
                        } else {
                          x.status = 'INACTIVE';
                          x.reason = null;
                          x.removalDate = dateUtils.setAPIDateOnly(
                            dateUtils.setOffset2(x.removalDate)
                          );
                          x.coverageEndDate = dateUtils.setAPIDateOnly(
                            dateUtils.setOffset2(x.coverageEndDate)
                          );
                          x.removalEventDate = x.removalEventDate
                            ? dateUtils.setAPIDateOnly(
                                dateUtils.setOffset2(x.removalEventDate)
                              )
                            : null;
                        }
                        // eslint-disable-next-line no-nested-ternary
                      } else {
                        x.removalEventDate = null;
                        if (x.age > maxAge) {
                          if (
                            get(x, 'parentEmployeeDependentId', null) ===
                              null ||
                            x.status === 'INELIGIBLE'
                          ) {
                            x.status = 'INELIGIBLE';
                            x.reason = 'Pet Over Plan Max Age';
                            x.postEnrollmentPetRemoved = true;
                            x.maxAgeData = true;
                            x.uiRenewalMaxAged = true;
                            x.removalDate =
                              dateUtils.setAPIDateOnly(relativeDatetime);
                            x.removalEventDate = dateUtils.setAPIDateOnly(
                              dateUtils.setOffset2(
                                x?.enrollmentEventDate || x?.coverageStartDate
                              )
                            );
                            x.coverageEndDate = dateUtils.setAPIDateOnly(
                              dateUtils.setOffset2(x.coverageStartDate)
                            );
                          } else {
                            x.removalDate = null;
                            x.postEnrollmentPetRemoved = false;
                            const endDate = activePetsFromBackendDates[0];
                            x.coverageEndDate = dateUtils.setAPIDateOnly(
                              dateUtils.setOffset2(endDate)
                            );
                          }
                        } else {
                          if (!['INACTIVE', 'INELIGIBLE'].includes(x.status)) {
                            x.status = 'ACTIVE';
                          }
                          x.reason = null;
                          x.removalDate = null;
                          x.postEnrollmentPetRemoved = false;
                          if (x.UISwapped) {
                            x.coverageEndDate = dateUtils.setAPIDateOnly(
                              dateUtils.setOffset2(x.coverageEndDate)
                            );
                          } else {
                            const endDate = activePetsFromBackendDates[0];

                            x.coverageEndDate = dateUtils.setAPIDateOnly(
                              dateUtils.setOffset2(endDate)
                            );
                          }
                        }
                      }
                      const startDate = x.coverageStartDate;
                      x.coverageStartDate = dateUtils.setAPIDateOnly(
                        dateUtils.setOffset2(startDate)
                      );
                      return x;
                    });

                    localStorage.setItem(
                      `${PDBA_RENEWAL_LOCAL_PREFIX}-${employeeId}`,
                      JSON.stringify({
                        employeeId,
                        pets: newValues,
                        previousEmployeeId: previousTermEmployeeId,
                      })
                    );
                    if (providedStep === dependentsState.length - 1) {
                      localStorage.setItem(
                        `${PDBA_RENEWAL_LOCAL_PREFIX}-LAST`,
                        employeeId
                      );
                      if (get(apiData, 'isEconsentSubmitted')) {
                        return onSubmitData();
                      }
                    }
                    return navigate(
                      generateRedirectPath({
                        ...location,
                        queryParams: {
                          [stepName]: providedStep + 1,
                        },
                      })
                    );
                  }}
                  submitText="Yes"
                  title="Attention"
                >
                  Thank you for entering your pet information for (
                  {dateUtils.getUTCDateOnly(coverageFromDate)} -{' '}
                  {dateUtils.getUTCDateOnly(coverageToDate)}). Once confirmed,
                  you won&lsquo;t be able to edit data for this policy term. Are
                  you ready to proceed?
                </Modal>
                <form onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      bg: 'white',
                      borderRadius: 4,
                      boxShadow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 6,
                    }}
                  >
                    <Box
                      as="h1"
                      sx={{
                        color: 'primary',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        ml: 3,
                        my: 4,
                        width: '100%',
                      }}
                    >
                      {t('groupEmployees.enrollmentPage.addPetInfo')}
                      <br />
                      {t('common.policyTerm')} {policyTerm} :{' '}
                      {dateUtils.getUTCDateOnly(coverageFromDate)}{' '}
                      {t('common.to')}{' '}
                      {dateUtils.getUTCDateOnly(coverageToDate)}
                    </Box>
                    <Box
                      as="h3"
                      sx={{
                        color: 'primary',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        ml: 3,
                        my: 4,
                        width: '100%',
                      }}
                    >
                      {t('groupEmployees.detailsPage.fillSpecies')}
                    </Box>
                    <FieldArray name="pets" {...rest}>
                      {({ fields, meta }) => {
                        return (
                          <Box
                            sx={{
                              alignItems: 'flex-start',
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              justifyContent: 'space-around',
                            }}
                          >
                            {fields.map((name, index) => {
                              const fieldValues = fields.value[index];
                              if (fieldValues?.weight === 0) {
                                form.change(`${name}.weight`, null);
                              }
                              const isFieldValues =
                                fieldValues?.petName &&
                                fieldValues?.age != null &&
                                fieldValues?.weight &&
                                fieldValues?.breed;
                              const { species } = fieldValues;

                              const filterBreedEnv = config.canadaEnv
                                ? 'CA'
                                : 'US';

                              const petBreeds = breeds
                                ?.filter?.(
                                  (item) =>
                                    item.species === fieldValues.species &&
                                    item?.envFlag === filterBreedEnv
                                )
                                .map((item) => {
                                  return {
                                    frenchValue: item.frenchValue,
                                    label: item.breedName,
                                    value: item.breedName,
                                  };
                                });
                              const disableData = [
                                'INACTIVE',
                                'INELIGIBLE',
                              ].includes(fieldValues.status);
                              const completeFormLegend = `${fieldValues?.petName} is not eligible for coverage under the selected plan due to their age. Complete the pet’s information here and click ‘SUBMIT’ to have the ineligible pet removed from the benefit.`;
                              return (
                                <>
                                  <Modal
                                    closeText="No"
                                    isOpen={closeModal[0]}
                                    onClose={() =>
                                      setCloseModal([false, () => {}])
                                    }
                                    onSubmit={() => {
                                      closeModal[1]();
                                      setCloseModal([false, () => {}]);
                                    }}
                                    submitText={t('common.yes')}
                                    title={t('common.warning')}
                                  >
                                    Are you sure you want to remove this pet?
                                  </Modal>
                                  <Modal
                                    closeText={t('common.cancel')}
                                    isOpen={!isEmpty(swapModal)}
                                    onClose={() => setSwapModal(null)}
                                    onSubmit={async () => {
                                      const dataValues = fields.value.filter(
                                        (o) =>
                                          o.employeeDependentId ===
                                          fields.value[swapModal.index]
                                            .employeeDependentId
                                      );
                                      await form.change(
                                        `${swapModal.name}.UISwapped`,
                                        true
                                      );
                                      await form.change(
                                        `${swapModal.name}.UIReplacedDependentId`,
                                        dataValues[0].employeeDependentId
                                      );
                                      await form.change(
                                        `${swapModal.name}.status`,
                                        'INACTIVE'
                                      );
                                      await form.change(
                                        `${swapModal.name}.coverageEndDate`,
                                        dataValues[0].coverageStartDate
                                      );
                                      addSwapData(
                                        fieldValues.employeeDependentId,
                                        dataValues[0],
                                        fields
                                      );
                                      setSwapModal(null);
                                    }}
                                    submitText={t('common.confirm')}
                                    title={t('common.warning')}
                                  >
                                    Do you want replace this&nbsp;
                                    {fields?.value[swapModal?.index]?.species}
                                    &nbsp;with another&nbsp;
                                    {
                                      fields?.value[swapModal?.index]?.species
                                    }{' '}
                                    for the same coverage period (
                                    {dateUtils.getUTCDateOnly(
                                      fields?.value[swapModal?.index]
                                        ?.coverageStartDate
                                    )}{' '}
                                    -
                                    {dateUtils.getUTCDateOnly(
                                      fields?.value[swapModal?.index]
                                        ?.coverageEndDate
                                    )}
                                    ). Doing so will remove coverage continuity
                                    for the existing pet.
                                  </Modal>

                                  <Box
                                    key={index}
                                    sx={{
                                      border: fieldValues.UIRemoved
                                        ? '5px solid #e60000'
                                        : null,
                                      borderRadius: 3,
                                      boxShadow: 7,
                                      display: disableData ? 'none' : 'block',
                                      m: 2,
                                      p: 3,
                                    }}
                                  >
                                    <Field
                                      aria-label={t(
                                        'groupEmployees.enrollmentPage.addPetSection.petSpecies'
                                      )}
                                      component={InputDropdown}
                                      disabled={!fieldValues.UISwapped}
                                      label={labelHandler(
                                        t(
                                          'groupEmployees.enrollmentPage.addPetSection.petSpecies'
                                        ),
                                        true
                                      )}
                                      name={`${name}.species`}
                                      options={PET_SPECIES}
                                      searchable={false}
                                      validate={required}
                                      wrapperSx={{
                                        ...commonFieldSxHalf,
                                      }}
                                      {...rest}
                                    />
                                    <Field
                                      aria-label={t(
                                        'groupEmployees.enrollmentPage.addPetSection.petName'
                                      )}
                                      component={InputText}
                                      disabled={
                                        fieldValues.UISwapped
                                          ? false
                                          : fieldValues.UIRemoved ||
                                            fieldValues.UIPrefilled
                                      }
                                      label={labelHandler(
                                        t(
                                          'groupEmployees.enrollmentPage.addPetSection.petName'
                                        ),
                                        true
                                      )}
                                      maxLength={50}
                                      name={`${name}.petName`}
                                      validate={
                                        fieldValues.UIRemoved
                                          ? null
                                          : composeValidations(
                                              required,
                                              maxLength(
                                                50,
                                                'Maximum 50 charecters allowed.'
                                              )
                                            )
                                      }
                                      wrapperSx={{
                                        ...commonFieldSxHalf,
                                      }}
                                      {...rest}
                                    />
                                    <Field
                                      aria-label={t(
                                        'metadata.policyEffectiveDate'
                                      )}
                                      component={SingleDatePicker}
                                      disabled
                                      label={labelHandler(
                                        t('metadata.policyEffectiveDate'),
                                        true
                                      )}
                                      name={`${name}.policyDate`}
                                      wrapperSx={{
                                        ...commonFieldSxHalf,
                                      }}
                                      {...rest}
                                    />
                                    <Field
                                      aria-label={t(
                                        'groupEmployees.enrollmentPage.addPetSection.petAgeEffective'
                                      )}
                                      component={InputDropdown}
                                      disabled={
                                        fieldValues.UISwapped
                                          ? false
                                          : fieldValues.UIRemoved ||
                                            fieldValues.UIPrefilled
                                      }
                                      label={labelHandler(
                                        t(
                                          'groupEmployees.enrollmentPage.addPetSection.petAgeEffective'
                                        ),
                                        true
                                      )}
                                      name={`${name}.age`}
                                      options={
                                        fieldValues.species
                                          ? PET_AGE[fieldValues.species]
                                          : []
                                      }
                                      searchable={false}
                                      validate={
                                        fieldValues.UIRemoved ? null : required
                                      }
                                      wrapperSx={{
                                        ...commonFieldSxHalf,
                                      }}
                                      {...rest}
                                    />
                                    {fieldValues.age > maxAge && (
                                      <Box
                                        as="h4"
                                        sx={{
                                          color: 'error',
                                          margin: '10px 0px 0px 5px;',
                                          width: '25rem',
                                          wordWrap: 'break-word',
                                        }}
                                      >
                                        {completeFormLegend}
                                      </Box>
                                    )}

                                    <Field
                                      aria-label={t(
                                        'groupEmployees.enrollmentPage.addPetSection.petWeightAdulthood'
                                      )}
                                      component={InputNumber}
                                      disabled={
                                        fieldValues.UISwapped
                                          ? false
                                          : fieldValues.UIRemoved ||
                                            fieldValues.UIPrefilled
                                      }
                                      label={labelHandler(
                                        t(
                                          'groupEmployees.enrollmentPage.addPetSection.petWeightAdulthood'
                                        ),
                                        true
                                      )}
                                      name={`${name}.weight`}
                                      type="number"
                                      validate={
                                        fieldValues.UIRemoved
                                          ? null
                                          : composeValidations(
                                              required,
                                              lessThan65forCats(name),
                                              lessThan0(name)
                                            )
                                      }
                                      wrapperSx={{
                                        ...commonFieldSxHalf,
                                      }}
                                      {...rest}
                                    />
                                    <Field
                                      aria-label={t(
                                        'groupEmployees.enrollmentPage.addPetSection.petBreed'
                                      )}
                                      component={InputDropdown}
                                      disabled={
                                        fieldValues.UISwapped
                                          ? false
                                          : fieldValues.UIRemoved ||
                                            fieldValues.UIPrefilled
                                      }
                                      label={labelHandler(
                                        t(
                                          'groupEmployees.enrollmentPage.addPetSection.petBreed'
                                        ),
                                        true
                                      )}
                                      name={`${name}.breed`}
                                      options={petBreeds}
                                      searchable={false}
                                      validate={
                                        fieldValues.UIRemoved ? null : required
                                      }
                                      wrapperSx={{
                                        ...commonFieldSxHalf,
                                      }}
                                      {...rest}
                                    />
                                    <Box
                                      alignItems="center"
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                    >
                                      {flow(
                                        getOr([], 'pets'),
                                        filter(
                                          (x) =>
                                            x.dependentData.species === 'Dog'
                                        ),
                                        filter(
                                          (x) =>
                                            Date.parse(x.coverageStartDate) <=
                                            Date.parse(x.coverageEndDate)
                                        ),
                                        filter((x) =>
                                          ['ACTIVE', 'INELIGIBLE'].includes(
                                            x.status
                                          )
                                        ),
                                        filter((x) => !x.UIRemoved),
                                        size,
                                        (x) => x > numberOfDogs
                                      )(values) &&
                                      activeDogData > numberOfDogs &&
                                      !get(fieldValues, 'UIRemoved', false) &&
                                      species === 'Dog'
                                        ? dogRemovedDates.map(
                                            ({
                                              date,
                                              removalDate,
                                              removalEventDate,
                                            }) => (
                                              <ToolbarButton
                                                bg="accentSecondary"
                                                display="flex"
                                                isDisabled={!isFieldValues}
                                                label={`Was this pet removed on ${dateUtils.getUTCDateOnly(
                                                  date
                                                )}?`}
                                                my={2}
                                                onClick={() => {
                                                  setCloseModal([
                                                    true,
                                                    () => {
                                                      // mark it removed
                                                      form.change(
                                                        `${name}.UIRemoved`,
                                                        true
                                                      );
                                                      // set the removal date
                                                      form.change(
                                                        `${name}.UIRemovedOn`,
                                                        dateUtils.setAPIDateOnly(
                                                          dateUtils.setOffset2(
                                                            date
                                                          )
                                                        )
                                                      );
                                                      form.change(
                                                        `${name}.coverageEndDate`,
                                                        date
                                                      );
                                                      form.change(
                                                        `${name}.removalDate`,
                                                        removalDate
                                                      );
                                                      form.change(
                                                        `${name}.removalEventDate`,
                                                        removalEventDate
                                                      );
                                                      dependentsDispatch({
                                                        payload: {
                                                          dependentId:
                                                            fieldValues.employeeDependentId,
                                                          employeeId:
                                                            fieldValues.employeeId,
                                                        },
                                                        type: 'REMOVE',
                                                      });
                                                    },
                                                  ]);
                                                }}
                                              />
                                            )
                                          )
                                        : // groupActive &&
                                          providedStep ===
                                            dependentsState.length - 1 &&
                                          fieldValues.UIPrefilled &&
                                          !fieldValues.UISwapped &&
                                          species === 'Dog' &&
                                          !get(
                                            fieldValues,
                                            'UIRemoved',
                                            false
                                          ) &&
                                          pdbaRenewalConfigEnabled && (
                                            <ToolbarButton
                                              bg="accentSecondary"
                                              display="flex"
                                              label="Replace"
                                              my={2}
                                              onClick={() => {
                                                setSwapModal({
                                                  index,
                                                  name,
                                                });
                                                // form.change(
                                                //   `${name}.UISwapped`,
                                                //   true
                                                // );
                                                // form.change(
                                                //   `${name}.UIreplacedDependentId`,
                                                //   fieldValues.employeeDependentId
                                                // );
                                                // form.change(
                                                //   `${name}.status`,
                                                //   'INACTIVE'
                                                // );
                                                // addSwapData(
                                                //   fieldValues.employeeDependentId,
                                                //   fieldValues,
                                                //   fields
                                                // );
                                              }}
                                            />
                                          )}
                                      {flow(
                                        getOr([], 'pets'),
                                        filter(
                                          (x) =>
                                            x.dependentData.species === 'Cat'
                                        ),
                                        filter(
                                          (x) =>
                                            Date.parse(x.coverageStartDate) <=
                                            Date.parse(x.coverageEndDate)
                                        ),
                                        filter((x) =>
                                          ['ACTIVE', 'INELIGIBLE'].includes(
                                            x.status
                                          )
                                        ),
                                        filter((x) => !x.UIRemoved),
                                        size,
                                        (x) => x > numberOfCats
                                      )(values) &&
                                      activeCatData > numberOfCats &&
                                      !get(fieldValues, 'UIRemoved', false) &&
                                      species === 'Cat'
                                        ? catRemovedDates.map(
                                            ({
                                              date,
                                              removalDate,
                                              removalEventDate,
                                            }) => (
                                              <ToolbarButton
                                                bg="accentSecondary"
                                                display="flex"
                                                isDisabled={!isFieldValues}
                                                label={`Was this pet removed on ${dateUtils.getUTCDateOnly(
                                                  date
                                                )}?`}
                                                my={2}
                                                onClick={() => {
                                                  setCloseModal([
                                                    true,
                                                    () => {
                                                      // mark it removed
                                                      form.change(
                                                        `${name}.UIRemoved`,
                                                        true
                                                      );
                                                      // set the removal date
                                                      form.change(
                                                        `${name}.UIRemovedOn`,
                                                        dateUtils.setAPIDateOnly(
                                                          dateUtils.setOffset2(
                                                            date
                                                          )
                                                        )
                                                      );
                                                      form.change(
                                                        `${name}.coverageEndDate`,
                                                        date
                                                      );
                                                      form.change(
                                                        `${name}.removalDate`,
                                                        removalDate
                                                      );
                                                      form.change(
                                                        `${name}.removalEventDate`,
                                                        removalEventDate
                                                      );
                                                      dependentsDispatch({
                                                        payload: {
                                                          dependentId:
                                                            fieldValues.employeeDependentId,
                                                          employeeId:
                                                            fieldValues.employeeId,
                                                        },
                                                        type: 'REMOVE',
                                                      });
                                                    },
                                                  ]);
                                                }}
                                              />
                                            )
                                          )
                                        : // groupActive &&
                                          !fieldValues.UISwapped &&
                                          providedStep ===
                                            dependentsState.length - 1 &&
                                          fieldValues.UIPrefilled &&
                                          species === 'Cat' &&
                                          !get(
                                            fieldValues,
                                            'UIRemoved',
                                            false
                                          ) &&
                                          pdbaRenewalConfigEnabled && (
                                            <ToolbarButton
                                              bg="accentSecondary"
                                              display="flex"
                                              label="Replace"
                                              my={2}
                                              onClick={() => {
                                                setSwapModal({
                                                  index,
                                                  name,
                                                });
                                                // mark it removed
                                                // form.change(
                                                //   `${name}.UISwapped`,
                                                //   true
                                                // );
                                                // form.change(
                                                //   `${name}.UIreplacedDependentId`,
                                                //   fieldValues.employeeDependentId
                                                // );
                                                // form.change(
                                                //   `${name}.status`,
                                                //   'INACTIVE'
                                                // );
                                                // addSwapData(
                                                //   fieldValues.employeeDependentId,
                                                //   fieldValues,
                                                //   fields
                                                // );
                                              }}
                                            />
                                          )}
                                      {/* {

																							flow(
																								getOr([], 'pets'),
																								filter(x => x.dependentData.species === 'Cat'),
																								size,
																								x => x <= numberOfCats
																							)(values) &&

																							!get(fieldValues, 'UIRemoved', false) &&
																							(species === "Cat") &&
																							groupActive &&
																							!fieldValues.UISwapped &&
																							<ToolbarButton
																								bg="accentSecondary"
																								display="flex"
																								label="Swap"
																								my={2}
																								onClick={() => {
																									// mark it removed
																									form.change(`${name}.UISwapped`, true);
																								}} />
																						}
																						{
																							flow(
																								getOr([], 'pets'),
																								filter(x => x.dependentData.species === 'Dog'),
																								size,
																								x => x === numberOfDogs
																							)(values) &&
																							!get(fieldValues, 'UIRemoved', false) &&
																							(species === "Dog") &&
																							groupActive &&
																							!fieldValues.UISwapped &&
																							<ToolbarButton
																								bg="accentSecondary"
																								display="flex"
																								label="Swap"
																								my={2}
																								onClick={() => {
																									// mark it removed
																									form.change(`${name}.UISwapped`, true);
																								}} />
																						} */}
                                    </Box>
                                  </Box>
                                </>
                              );
                            })}
                            {meta.error &&
                              meta.touched &&
                              !isArray(meta.error) && (
                                <Box
                                  sx={{
                                    color: 'error',
                                    fontSize: 2,
                                    mt: 1,
                                  }}
                                >
                                  {meta.error}
                                </Box>
                              )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        maxWidth: '25rem',
                        my: 6,
                        p: 3,
                        width: '100%',
                      }}
                    >
                      <ToolbarButton
                        bg="accentSecondary"
                        isDisabled={
                          submitting ||
                          flow(
                            getOr([], 'pets'),
                            filter((x) => x.dependentData.species === 'Dog'),
                            filter((x) => x.status === 'ACTIVE'),
                            filter((x) => !x.UIRemoved),
                            size,
                            (x) => x > numberOfDogs
                          )(values) ||
                          flow(
                            getOr([], 'pets'),
                            filter((x) => x.dependentData.species === 'Cat'),
                            filter((x) => x.status === 'ACTIVE'),
                            filter((x) => !x.UIRemoved),
                            size,
                            (x) => x > numberOfCats
                          )(values)
                        }
                        label={
                          get(apiData, 'isEconsentSubmitted')
                            ? t('common.submit')
                            : t('common.next')
                        }
                        mr={0}
                        submitting={submitting}
                        type="submit"
                        width="10rem"
                      />
                    </Box>
                    <Box
                      alignItems="center"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      my={5}
                    >
                      <Box fontSize={4} mb={4}>
                        If you have technical questions, such as adding a pet or
                        removing a pet, please contact customer care:
                      </Box>
                      <Box
                        sx={{
                          width: '300',
                        }}
                      >
                        <PhoneButton
                          number="800-956-2495"
                          sx={{ width: 350 }}
                        />
                        <EmailButton
                          email="mypolicy@petpartners.com"
                          sx={{ width: 350 }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </form>
              </>
            );
          }}
        />
      ),
      value: String(index),
    };
  });
  return (
    <Route
      header={{
        title: 'Post enrollment form',
        type: 'group-employees',
      }}
      isPrivate
      {...rest}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <ProgressBar
          defaultTab={String(providedStep)}
          name={stepName}
          slides={
            get(apiData, 'isEconsentSubmitted')
              ? dependentsState.map((x, index) => index)
              : dependentsState
                  .map((x, index) => index)
                  .concat(dependentsState.length)
          }
          tabs={
            get(apiData, 'isEconsentSubmitted')
              ? tabsData
              : tabsData.concat({
                  render: () => {
                    return (
                      <Box
                        alignItems="center"
                        display={{
                          lg: 'flex',
                          sm: 'block',
                        }}
                        flexDirection="column"
                        fontSize={4}
                        justifyContent="center"
                      >
                        <Box
                          width={{
                            lg: '40%',
                            sm: '100%',
                          }}
                        >
                          <Box
                            as="h2"
                            color="black"
                            fontSize={7}
                            my={6}
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              margin: 0,
                              width: '100%',
                            }}
                          >
                            {t('econsent.documentation')}
                          </Box>
                          <FinalForm
                            onSubmit={async () => {
                              const agreedToAll =
                                misrepresentation &&
                                fraudWarning &&
                                acknowledgement &&
                                premiumChanges &&
                                agreeEC !== null &&
                                (config.canadaEnv
                                  ? agreeCASL !== null
                                  : agreeCASL === null);
                              if (agreedToAll) {
                                await onSubmitData();
                              } else {
                                setErrorSnack(
                                  <Box fontSize={3}>
                                    {t('validation.completeAll')}
                                  </Box>,
                                  4000
                                );
                              }
                            }}
                            render={({ submitting, handleSubmit }) => (
                              <form onSubmit={handleSubmit}>
                                <Box
                                  as="h3"
                                  color="black"
                                  sx={{ textWidth: 300 }}
                                >
                                  {t(
                                    'groupEmployees.enrollmentPage.addPetSection.misstatementPetInfo'
                                  )}
                                </Box>
                                <Box>
                                  {t(
                                    'groupEmployees.enrollmentPage.addPetSection.agreeLegend'
                                  )}
                                </Box>
                                <Checkbox
                                  aria-label="Agree"
                                  checked={misrepresentation}
                                  data-testid="agree"
                                  input={{
                                    name: 'misrepresentation',
                                    value: 'misrepresentation',
                                  }}
                                  label={labelHandler(t('common.agree'), true)}
                                  meta={{ error: '', touched: true }}
                                  onChange={() =>
                                    setMisrepresentation(!misrepresentation)
                                  }
                                  type="checkbox"
                                  wrapperSx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    ...fieldSxHalf,
                                  }}
                                />
                                <Box
                                  as="h3"
                                  color="black"
                                  sx={{ textWidth: 300 }}
                                >
                                  {t(
                                    'groupEmployees.enrollmentPage.addPetSection.fraudWarning'
                                  )}
                                </Box>
                                {config.canadaEnv && (
                                  <Box as="p">
                                    {t(
                                      'groupEmployees.enrollmentPage.addPetSection.fraudWarningLegend'
                                    )}
                                  </Box>
                                )}
                                {!config.canadaEnv && (
                                  <Box as="p">
                                    I acknowledge and understand that any person
                                    who knowingly provides false, incomplete, or
                                    misleading information to an insurance
                                    company for the purpose of defrauding the
                                    company is guilty of a crime and may be
                                    subject to fines, imprisonment, and denial
                                    of insurance benefits. See state specific
                                    fraud notice:{' '}
                                    <a
                                      href="https://www.petpartners.com/insurance-fraud-warnings"
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      Insurance Fraud Warnings
                                    </a>
                                  </Box>
                                )}
                                <Checkbox
                                  checked={fraudWarning}
                                  data-testid="fraud"
                                  input={{
                                    name: 'fraudWarning',
                                    value: 'fraudWarning',
                                  }}
                                  label={labelHandler(t('common.agree'), true)}
                                  meta={{ error: '', touched: true }}
                                  name="fraud"
                                  onChange={() =>
                                    setFraudWarning(!fraudWarning)
                                  }
                                  type="checkbox"
                                  wrapperSx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    ...fieldSxHalf,
                                  }}
                                />
                                <Box
                                  as="h3"
                                  color="black"
                                  sx={{ textWidth: 300 }}
                                >
                                  {t(
                                    'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreement'
                                  )}
                                </Box>
                                <Box as="p">
                                  {t(
                                    'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreementLegend'
                                  )}
                                </Box>
                                <Checkbox
                                  checked={acknowledgement}
                                  data-testid="acknowledgement"
                                  input={{
                                    name: 'acknowledgement',
                                    value: 'acknowledgement',
                                  }}
                                  label={labelHandler(t('common.agree'), true)}
                                  meta={{ error: '', touched: true }}
                                  onChange={() =>
                                    setAcknowledgement(!acknowledgement)
                                  }
                                  type="checkbox"
                                  wrapperSx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    ...fieldSxHalf,
                                  }}
                                />
                                <Box
                                  as="h3"
                                  color="black"
                                  sx={{ textWidth: 300 }}
                                >
                                  {t(
                                    'groupEmployees.enrollmentPage.addPetSection.premiumChanges'
                                  )}
                                </Box>
                                <Box as="p">
                                  {t(
                                    'groupEmployees.enrollmentPage.addPetSection.premiumChangesLegend'
                                  )}
                                </Box>
                                <Checkbox
                                  checked={premiumChanges}
                                  data-testid="premiumChanges"
                                  input={{
                                    name: 'premiumChanges',
                                    value: 'premiumChanges',
                                  }}
                                  label={labelHandler(t('common.agree'), true)}
                                  meta={{ error: '', touched: true }}
                                  onChange={() =>
                                    setPremiumChanges(!premiumChanges)
                                  }
                                  type="checkbox"
                                  wrapperSx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    ...fieldSxHalf,
                                  }}
                                />
                                <Box>
                                  <Box
                                    as="h3"
                                    color="black"
                                    sx={{ textWidth: 300 }}
                                  >
                                    {t(
                                      'groupEmployees.enrollmentPage.addPetSection.eConsent'
                                    )}
                                  </Box>
                                  <Box
                                    dangerouslySetInnerHTML={{
                                      __html: t(
                                        'groupEmployees.enrollmentPage.addPetSection.eConsentLegend',
                                        { url: E_CONSENT_URLS[url] || url }
                                      ),
                                    }}
                                  />
                                </Box>
                                <Checkbox
                                  checked={agreeEC === true}
                                  input={{
                                    name: 'agreeEC',
                                    value: 'agreeEC',
                                  }}
                                  label={labelHandler(t('common.agree'))}
                                  meta={{ error: '', touched: true }}
                                  onChange={() => setAgreeEC(true)}
                                  type="radio"
                                  value="agree"
                                  wrapperSx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    m: 0,
                                    ...fieldSxHalf,
                                  }}
                                />
                                <Checkbox
                                  checked={agreeEC === false}
                                  input={{
                                    name: 'disagreeEC',
                                    value: 'disagreeEC',
                                  }}
                                  label={labelHandler(t('common.disagree'))}
                                  meta={{ error: '', touched: true }}
                                  onChange={() => setAgreeEC(false)}
                                  type="radio"
                                  value="disagree"
                                  wrapperSx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    m: 0,
                                    ...fieldSxHalf,
                                  }}
                                />
                                {config.canadaEnv && (
                                  <Box>
                                    <Box>
                                      <Box fontWeight={600} mb="10px">
                                        {t('component.caslDisclosure')}
                                      </Box>
                                      <Box
                                        dangerouslySetInnerHTML={{
                                          __html: t(
                                            'groupEmployees.enrollmentPage.addPetSection.eConsentLegendCanada',
                                            {
                                              url:
                                                i18n.language === 'fr'
                                                  ? 'https://www.onepackplan.ca/fr/politique-protection-renseignements'
                                                  : 'https://www.onepackplan.ca/privacy-policy',
                                            }
                                          ),
                                        }}
                                      />
                                    </Box>
                                    <Checkbox
                                      checked={agreeCASL === true}
                                      input={{
                                        name: 'agreeCASL',
                                        value: 'agreeCASL',
                                      }}
                                      label={labelHandler(t('common.agree'))}
                                      meta={{ error: '', touched: true }}
                                      onChange={() => setAgreeCASL(true)}
                                      type="radio"
                                      value="agree"
                                      wrapperSx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        m: 0,
                                        ...fieldSxHalf,
                                      }}
                                    />
                                    <Checkbox
                                      checked={agreeCASL === false}
                                      input={{
                                        name: 'disagreeCASL',
                                        value: 'disagreeCASL',
                                      }}
                                      label={labelHandler(t('common.disagree'))}
                                      meta={{ error: '', touched: true }}
                                      onChange={() => setAgreeCASL(false)}
                                      type="radio"
                                      value="disagree"
                                      wrapperSx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        m: 0,
                                        ...fieldSxHalf,
                                      }}
                                    />
                                  </Box>
                                )}
                                {!config.canadaEnv && (
                                  <Box>
                                    <Box
                                      as="h3"
                                      color="black"
                                      sx={{ textWidth: 300 }}
                                    >
                                      {t('common.insurerDisclosureTitle')}
                                    </Box>
                                    <Box
                                      dangerouslySetInnerHTML={{
                                        __html:  renderDisclosureContent() ,
                                      }}
                                    />
                                    {/* <Box
                                  dangerouslySetInnerHTML={{
                                    __html: t('common.insurerDisclosure', {
                                      url: config.insurerDisclosuresUrl || '#',
                                    }),
                                  }}
                                /> */}
                                  </Box>
                                )}
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="center"
                                >
                                  <ToolbarButton
                                    isDisabled={loading}
                                    label={t('common.submit')}
                                    submitting={submitting}
                                    sx={{
                                      ml: 0,
                                      my: 6,
                                    }}
                                    type="submit"
                                    width="200px"
                                  />
                                </Box>
                                {/* eslint-enable */}
                              </form>
                            )}
                          />
                        </Box>
                      </Box>
                    );
                  },
                  value: String(dependentsState.length),
                })
          }
        />
      </Box>
    </Route>
  );
}

PostEnrollmentPetsRenewal.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      empId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
