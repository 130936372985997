import { useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { navigate } from 'gatsby';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle } from 'react';
import dashboardConstants from '../../../../../utilities/dashboard-constants';
import GetData from '../../../../../utilities/get-data';
import setDashboardURL from '../../../../../utilities/set-dashboard-url';
import DashboardPaginator from '../../../DashboardPaginator';
import Spinner from '../../../../BaseComponents/Spinner';
import Table from '../../../../BaseComponents/Table';
import TableWrapper from '../../../../BaseComponents/TableWrapper';
import dateUtils from '../../../../../utilities/date-utils';
import ToolbarButton from '../../../../BaseComponents/ToolbarButton';
import { TOOLTIP } from '../../../../BaseComponents/DataGrid/utils/constants';
import config from '../../../../../config.json';

const CommissionsHistoryTable = forwardRef(
  /**
   * @category ProductAdmin
   * @param {Date} currentDate
   * @param {Object} location
   * @param {string} tableType
   * @param {boolean} editable
   * @param {function} setCommissionData
   * @param {function} setDeletedCommissionData
   * @param {any} ref
   * @returns {React.FC}
   */
  (
    {
      commissionData,
      currentDate,
      location,
      tableType,
      editable,
      setCommissionData,
      setDeletedCommissionData,
      isRenewal,
    },
    ref
  ) => {
    const queryParams = useParams();
    const groupId = get(queryParams, 'groupId', '');
    const { filters, sortOptions, pageCriteria } =
      dashboardConstants.getCriterias({
        search: get(location, 'search', ''),
        type: tableType,
      });

    if (isRenewal) {
      const { apiData: group = {} } = GetData(
        'get-group-by-id',
        JSON.stringify({ id: groupId }),
        !groupId
      );
      // eslint-disable-next-line no-param-reassign
      currentDate =
        config.developerMode && get(group, 'relativeDatetime') === null
          ? new Date()
          : dateUtils.setOffset2(get(group, 'relativeDatetime'));
    }

    const query = dashboardConstants.getQuery({
      filters,
      page: pageCriteria,
      sort: sortOptions,
    });

    query.push({ groupId });
    const actions = [
      {
        getProps: (data) => {
          return {
            icon: 'edit',
            onClick: () => setCommissionData({ ...data }),
          };
        },
        label: 'edit',
        name: 'edit',
      },
      {
        getProps: (data) => {
          const effectiveDate = dateUtils.setOffset2(
            `${get(data, 'effectiveDate')}`,
            null
          );
          return {
            icon: 'remove',
            isDisabled: isRenewal
              ? new Date(effectiveDate) < currentDate
              : effectiveDate < currentDate || !isEmpty(commissionData),
            onClick: () =>
              setDeletedCommissionData(get(data, 'groupCommissionHistoryId')),
          };
        },
        label: 'Delete',
        name: 'Delete',
      },
    ];
    const headerData = [
      'Effective Date',
      'Expiration Date',
      'Total Commission',
      <Box textAlign="right">Producer</Box>,
      <Box textAlign="right">Subproducer</Box>,
      <Box textAlign="right">Commission</Box>,
    ];
    if (editable) {
      headerData.push('Actions');
    }
    const {
      apiData = {},
      loading,
      refetch,
    } = GetData('get-all-commission-history', JSON.stringify(query));
    useImperativeHandle(ref, () => ({
      refetchCommissionsTableData() {
        refetch();
      },
    }));

    const renderTable = () => {
      if (loading) {
        return <Spinner />;
      }
      const { content = [], totalElements, numberOfElements } = apiData;

      return (
        <Box
          sx={{
            bg: 'white',
            display: 'grid',
            mt: '8px',
          }}
        >
          <TableWrapper>
            <Table
              colSpan="2"
              header={[...headerData]}
              onSortUpdate={(sortBy, orderBy) => {
                return navigate(
                  setDashboardURL({
                    filters,
                    filterValues: filters.values,
                    page: pageCriteria,
                    pathname: location.pathname,
                    sort: {
                      sortBy,
                      sortDirection: orderBy,
                    },
                  }),
                  { replace: true }
                );
              }}
              renderEmpty={
                <Box sx={{ lineHeight: 1, p: 6 }}>No Commissions Found</Box>
              }
              rows={content.map((e, rowIx) => {
                const rowUIData = [];
                rowUIData.push(
                  dateUtils.getUTCDateOnly(e.effectiveDate),
                  dateUtils.getUTCDateOnly(e.expirationDate),
                  e.totalCommissionPercentage,
                  <Box>
                    {e.commissionDTOList.map((pd) => (
                      <Box
                        sx={{
                          bg: 'accent',
                          borderRadius: 4,
                          m: 3,
                          p: 2,
                          textAlign: 'center',
                          width: '100%',
                        }}
                      >
                        {pd.producerDetail.name}
                      </Box>
                    ))}
                  </Box>,
                  <Box>
                    {e.commissionDTOList.map((sd) => (
                      <Box
                        sx={{
                          bg: 'accent',
                          borderRadius: 4,
                          m: 3,
                          p: 2,
                          textAlign: 'center',
                          width: '100%',
                        }}
                      >
                        {sd.subProducer.name}
                      </Box>
                    ))}
                  </Box>,
                  <Box>
                    {e.commissionDTOList.map((cp) => (
                      <Box
                        sx={{
                          bg: 'accent',
                          borderRadius: 4,
                          m: 3,
                          p: 2,
                          textAlign: 'center',
                          width: '100%',
                        }}
                      >
                        {cp.commissionPercentage}%
                      </Box>
                    ))}
                  </Box>
                );
                if (actions.length > 0 && editable) {
                  const actionUI = [];
                  actions.forEach((action, ix) => {
                    const { getProps, label, permission } = action;
                    const buttonProps = getProps(e, refetch);
                    actionUI.push(
                      <Box
                        key={`row-${rowIx}-action-${ix}`}
                        data-for={TOOLTIP}
                        data-tip={label}
                        mx={
                          (ix === 0 || ix === actions.length - 1) &&
                            actions.length !== 2
                            ? 0
                            : 2
                        }
                      >
                        <ToolbarButton
                          bg={ix % 2 === 0 ? 'primary' : 'accentSecondary'}
                          {...buttonProps}
                          permission={permission}
                        />
                      </Box>
                    );
                  });
                  rowUIData.push(
                    <Box alignItems="center" display="flex">
                      {actionUI}
                    </Box>
                  );
                }
                return rowUIData;
              })}
              tableSx={{ fontSize: 2 }}
            />

            <DashboardPaginator
              filters={{
                page: pageCriteria.offset,
              }}
              goToNextPage={() =>
                navigate(
                  setDashboardURL({
                    filters,
                    filterValues: filters.values,
                    page: {
                      offset: pageCriteria.offset + pageCriteria.pageSize,
                      pageSize: pageCriteria.pageSize,
                    },
                    pathname: location.pathname,
                    sort: sortOptions,
                  }),
                  { replace: true }
                )
              }
              goToPreviousPage={() =>
                navigate(
                  setDashboardURL({
                    filters,
                    filterValues: filters.values,
                    page: {
                      offset: pageCriteria.offset - pageCriteria.pageSize,
                      pageSize: pageCriteria.pageSize,
                    },
                    pathname: location.pathname,
                    sort: sortOptions,
                  }),
                  { replace: true }
                )
              }
              isClientSidePagination
              pageTotal={numberOfElements}
              sx={{
                bg: 'white',
                fontSize: 2,
                pt: 5,
              }}
              totalRecordCount={totalElements}
            />
          </TableWrapper>
        </Box>
      );
    };
    return <>{renderTable()}</>;
  }
);

CommissionsHistoryTable.propTypes = {
  commissionData: PropTypes.shape({}).isRequired,
  currentDate: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  isRenewal: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  setCommissionData: PropTypes.func,
  setDeletedCommissionData: PropTypes.func,
  tableType: PropTypes.string,
};

CommissionsHistoryTable.defaultProps = {
  editable: false,
  isRenewal: false,
  location: {},
  setCommissionData: () => { },
  setDeletedCommissionData: () => { },
  tableType: 'commissionsDashboardPage',
};
export default CommissionsHistoryTable;
