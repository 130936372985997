import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';

const imgContainerSx = {
  p: 1,
  svg: { height: 'auto', width: '10rem' },
};
const wrapperSx = {
  bg: 'white',
  borderRadius: 2,
  overflow: 'auto',
  p: 5,
};

/**
 * @category Components
 * @param {boolean} {setFigoMsg}
 * @returns {React.FC}
 */
const NoRecordFound = ({ setFigoMsg }) => {
  const { t } = useTranslation()
  let result = null;
  const theme = useContext(ThemeContext);
  if (setFigoMsg) {
    result = (
      <Box sx={{ mb: 6 }}>
        <Box
          sx={{
            ...wrapperSx,
            lineHeight: 2,
            m: '1.25rem auto',
            width: ['90%', null, '70%'],
          }}
        >
          <Box
            sx={{
              color: `${get(theme, 'colors.accent')}`,
              fontSize: '1rem',
              fontWeight: 600,
              letterSpacing: '0.05em',
              maxWidth: 'fit-content',
              textTransform: 'uppercase',
            }}
          >
            HOW DO I VIEW MY POLICY DOCUMENTS?
          </Box>
          <Box
            sx={{
              color: 'primary',
              fontSize: 3,
            }}
          >
            All policy documents will be conveniently located within your &nbsp;
            <Box
              as="a"
              href="https://www.mypetcloud.com/"
              sx={{
                color: `${get(theme, 'colors.accent')}`,
                textDecoration: 'underline',
              }}
              target="_blank"
            >
              Figo Pet Cloud,
            </Box>
            &nbsp; under “Inbox & Docs.” There you will see a folder labeled
            “Policy Documents”.
          </Box>
          <Box
            sx={{
              color: 'primary',
              fontSize: 3,
              mt: 2,
            }}
          >
            If you would prefer paper documentation mailed to you or if you have
            any questions about accessing this information, please contact us at
            &nbsp;
            <Box as="a" href="tel:8447383446">
              (844) 738-3446.
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              mt: 2,
            }}
          >
            <Box
              aria-label="Apple App Store Link"
              as="a"
              href="https://apps.apple.com/us/app/figo-pet-cloud/id1278520013"
              target="_blank"
            >
              <Icon color="primary" svg="appstore" sx={imgContainerSx} />
            </Box>
            <Box
              aria-label="Google Play Store Link"
              as="a"
              href="https://play.google.com/store/apps/details?id=com.figoinsurance.petcloud"
              target="_blank"
            >
              <Icon color="primary" svg="playstore" sx={imgContainerSx} />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  } else {
    result = (
      <Box
        sx={{
          color: 'accent',
          display: 'table',
          fontSize: 6,
          fontWeight: 'bold',
          height: '200px',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'table-cell',
            verticalAlign: 'middle',
          }}
        >
          {t('common.noResults')}
        </Box>
      </Box>
    );
  }

  return <Box>{result}</Box>;
};
NoRecordFound.defaultProps = {
  setFigoMsg: false,
};

NoRecordFound.propTypes = {
  setFigoMsg: PropTypes.bool,
};

export default NoRecordFound;
