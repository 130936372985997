import React, { useContext } from 'react';
import { AuthContext } from '@basecomponents/Auth';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Route from "@petcomponents/Route";
import Box from "@basecomponents/Box";
import UpsertDataPage from "@petcomponents/GroupNotes/Upsert/UpsertDataPage";
import ProgressBar from "@basecomponents/ProgressBar";
import GroupTemplate from "@petcomponents/GroupTemplate";
import { useParams } from '@reach/router';

const name = 'step';

const UpsertGroupNotesPage = ({ location, groupId }) => {
    const { user } = useContext(AuthContext);
    const title = 'Group Notes Configuration';
    const userId = get(user, 'customSystemUserId', '');
    const params = useParams();
    const noteId = params?.noteId

    return (
        <Route
            header={{
                title: 'Create Notes',
                type: 'groups',
            }}
            isPrivate
        >
            <Box as="h2" sx={{ py: 3 }}>
                {title}
            </Box>
            <ProgressBar
                defaultTab="0"
                name={name}
                slides={[
                    'Group Creation Form',
                    'Group Template',
                ]}
                tabs={[
                    {
                        render: () => <UpsertDataPage index={0} location={location} noteId={noteId} readOnly={false} userId={userId} />,
                        value: '0',
                    },
                    {
                        render: () => <GroupTemplate flow='groupNote' index={1} location={location} userId={userId} />,
                        value: '1',
                    }
                ]}
            />
        </Route>
    );
};

UpsertGroupNotesPage.defaultProps = {
    groupId: null,
};

UpsertGroupNotesPage.propTypes = {
    groupId: PropTypes.string,
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }).isRequired,
};

export default UpsertGroupNotesPage;
