import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';
import InputWrapper from '../InputWrapper';

/**
 * @category BaseComponents
 * @param {object} input
 * @param {string} label
 * @param {string} labelSx
 * @param {string} type
 * @param {*} rest
 * @returns {React.FC}
 */
const Checkbox = ({ input, label, labelSx, type, ...rest }) => {
  const { defaultStyle = {} } = useContext(ThemeContext);
  return (
    <InputWrapper
      htmlFor={input.name}
      input={input}
      label={label}
      labelSx={defaultStyle.defaultCheckbox(labelSx)}
      {...rest}
    >
      {(props) => (
        <Box
          as="input"
          id={input.name}
          type={type}
          {...props}
          {...input}
          {...rest}
        />
      )}
    </InputWrapper>
  );
};

Checkbox.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  labelSx: PropTypes.shape({}),
  type: PropTypes.oneOf(['checkbox', 'radio']),
};

Checkbox.defaultProps = {
  labelSx: {},
  type: 'checkbox',
};

export default Checkbox;
