/* eslint-disable sort-keys */
/* eslint-disable no-param-reassign */
import {ApolloConsumer} from '@apollo/client';
import {v4 as uuidv4} from 'uuid';
import get from 'lodash/get';
import {PET_AGE, PET_SPECIES, USER_ROLE} from '@utils/constants';
import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from '@basecomponents/Auth';
import Box from '@basecomponents/Box';
import arrayMutators from 'final-form-arrays';
import {isArray} from 'lodash';
import {Field, Form as FinalForm} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import ToolbarButton from '@basecomponents/ToolbarButton';
import gaEvent from '@utils/gaEvent';
import InputText from '@basecomponents/Input';
import {labelHandler} from '@src/utilities/label-utils';
import {composeValidations, required} from '@src/utilities/validators';
import {maxLength, reasonRequired} from '@src/utilities/validations';
import InputDropdown from '@basecomponents/Dropdown';
import InputNumber from '@basecomponents/InputNumber';
import GetData from '@src/utilities/get-data';
import SingleDatePicker from '@basecomponents/InputDate';
import {useParams} from '@reach/router';
import Spinner from '@basecomponents/Spinner';
import Route from '@petcomponents/Route';
import Modal from '@basecomponents/Modal';
import useSnackbar from '@src/utilities/use-snackbar';
import remoteActionQuery from '@queries/remote-action.gql';
import config from '@src/config.json';
import {navigate} from 'gatsby';
import dateUtils from '@src/utilities/date-utils';
import localStorage from '@src/utilities/local-storage';
import {useTranslation} from 'react-i18next';

const lessThan65forCats = (name) => (value, values) => {
    if (get(values, `[${name}].species`, '') === 'Cat') {
        return (
            Number(value) > 65 &&
            'Weight must not be greater that 65 pounds for Cats.'
        );
    }
};

const lessThan0 = (name) => (value, values) => {
    // eslint-disable-next-line no-constant-condition
    if (get(values, `[${name}].species`, '') === 'Cat' || 'Dog') {
        return Number(value) === 0 && 'Weight must be greater that 0.';
    }
};

const commonFieldSxHalf = {
    mx: 'auto',
    padding: 3,
    width: '25rem',
};

/**
 * @category Post Enrollment
 * @param {*} rest
 * @returns {React.FC}
 */

export default function PostEnrollmentPets({...rest}) {
    /**
     * State variable for modal status.
     * @type {Array} modalOpen
     * @property {boolean} modalOpen[0] - If the modal is open or not.
     * @property {string|null} modalOpen[1] - The name of the form section.
     * @property {string|null} modalOpen[2] - The UUID of the pet.
     * @property {string|null} modalOpen[3] - The coverageEndDate of the pet.
     */
    const {user} = useContext(AuthContext);
    const {t, i18n} = useTranslation();
    const role = get(user, 'customRole', '');
    const [modalOpen, setModalOpen] = useState([false, null]);
    // const [showEConsent, setShowEConsent] = useState(false);
    const [numberOfPets, setNumberOfPets] = useState(0);
    const [getUiActivePetLength, setUiActivePetLength] = useState(false);
    const [setErrorSnack] = useSnackbar({color: 'error'});
    const queryParams = useParams();
    const empId = get(queryParams, 'employeeId', '');
    const employeeDependentData = JSON.parse(
        localStorage.getItem(`employeeDependentData_${empId}`)
    );

    const [formProcessing, setFormProcessing] = useState(false);

    const {apiData: breeds, loading: breedsLoading} = GetData(
        'get-all-breeds',
        JSON.stringify([])
    );
    const {apiData: dependentData = [], loading: dependentsLoading} = GetData(
        'get-employee-dependents',
        JSON.stringify({employeeId: empId})
    );
    const {apiData: employeeData = {}, loading: employeeLoading} = GetData(
        'get-group-employee-by-id',
        JSON.stringify({id: empId})
    );
    const {
        apiData: {ReasonPetRemoval = []},
    } = GetData(
        'get-enum-options',
        JSON.stringify([{params: 'ReasonPetRemoval'}])
    );
    const isConsentAgreed = get(employeeData, 'isEconsentSubmitted') || false;
    const relativeDatetime = get(employeeData, 'relativeDatetime')
        ? dateUtils.setOffset2(get(employeeData, 'relativeDatetime'))
        : dateUtils.setOffset2(new Date());

    useEffect(() => {
        const pathname = localStorage.getItem('pageUrl');
        if (pathname) {
            localStorage.removeItem('pageUrl');
        }
    }, []);

    /**
     * hasDemographicChanges tells me if there is anything that needs to be processed
     * totalNumberOfPets - total sum
     * noOfDogs & noOfCats
     *
     * init 1 d 1 c, uploaded, hasDemChanges = true, expected c = 1 d = 1,
     * after processing, hasdemchanges = false
     *
     * now 2 dogs 2 cats, hasDemChanges = true, expected c = 2, d = 2
     *
     * now 2 pets removed, 1 c & 1 d. expectd d = 1 c = 1,
     * when i remove pets, i make status = INACTIVE, postEnrollmentPetRemoved = true
     */
    useEffect(() => {
        if (get(employeeData, 'isEconsentSubmitted')) {
            setNumberOfPets(get(employeeData, 'numberOfPets', 0));
        } else if (dependentData?.content && dependentData?.content?.length > 0) {
            const activePetCount = dependentData?.content.filter(
                ({status, removalDate}) =>
                    ['ACTIVE', 'PENDING', 'TERMINATED'].includes(status) &&
                    removalDate === null
            ).length;
            setNumberOfPets(activePetCount);
        }
    }, [dependentsLoading]);
    const petData =
        dependentData?.content &&
        dependentData?.content.length &&
        dependentData?.content.map((data) => {
            const {dependentData, ...rest} = data;
            if (dependentData?.species === 'Dog' && dependentData?.age > 16) {
                dependentData.age = 16;
            } else if (dependentData?.species === 'Cat' && dependentData?.age > 24) {
                dependentData.age = 24;
            }
            return {...rest, dependentData};
        });
    const petStatus =
        isConsentAgreed === false
            ? ['ACTIVE', 'INACTIVE', 'PENDING', 'TERMINATED', 'INELIGIBLE']
            : ['ACTIVE', 'PENDING', 'TERMINATED'];
    let activePets =
        (petData &&
            petData.length > 0 &&
            petData
                .map((item) => {
                    const {age, uiPetTermination, ...rest} = item?.dependentData;
                    return {
                        age: item?.demographicChangesRequired && age === 0 ? null : age,
                        ...item,
                        ...rest,
                        policyDate: dateUtils.setOffset2(item?.enrollmentEventDate || item.coverageStartDate),
                        uiId: uuidv4(),
                        uiPetTermination: uiPetTermination || false,
                        maxAgeData: false,
                        terminatedPetMaxAgeData: false,
                    };
                })
                .filter(({status}) => [...petStatus].includes(status))) ||
        [];
    const localData =
        (employeeDependentData &&
            employeeDependentData.length > 0 &&
            employeeDependentData
                .map((item) => {
                    const {age, uiPetTermination, ...rest} = item?.dependentData;
                    return {
                        age: item?.demographicChangesRequired && age === 0 ? null : age,
                        ...item,
                        ...rest,
                        policyDate: dateUtils.setOffset2(item?.enrollmentEventDate || item.coverageStartDate),
                        uiId: uuidv4(),
                        uiPetTermination: uiPetTermination || false,
                        maxAgeData: item?.maxAgeData || false,
                        terminatedPetMaxAgeData: item?.terminatedPetMaxAgeData || false,
                    };
                })
                .filter(({status}) => [...petStatus].includes(status))) ||
        [];

    function catCoverageEndDates(activePets, fields) {
        const removedFromBackendCats = activePets.filter(
            ({postEnrollmentPetRemoved, species, maxAgeData}) =>
                postEnrollmentPetRemoved === true &&
                species === 'Cat' &&
                maxAgeData === false
        );
        const removedFromFrontendCats = fields.value.filter(
            (x) => x.uiPetTermination && x.species === 'Cat'
        );
        const backendRemovedCount = {};

        removedFromBackendCats.forEach(({coverageEndDate}) => {
            if (backendRemovedCount[coverageEndDate]) {
                backendRemovedCount[coverageEndDate] += 1;
                return;
            }
            backendRemovedCount[coverageEndDate] = 1;
        });

        const frontendRemovedCount = {};

        removedFromFrontendCats.forEach(({coverageEndDate}) => {
            if (frontendRemovedCount[coverageEndDate]) {
                frontendRemovedCount[coverageEndDate] += 1;
                return;
            }
            frontendRemovedCount[coverageEndDate] = 1;
        });

        Object.entries(frontendRemovedCount).forEach(([date, count]) => {
            backendRemovedCount[date] -= count;
        });
        const dates = [];

        Object.entries(backendRemovedCount).forEach(([date, count]) => {
            if (count > 0) {
                const removedFromBackendCatsRemoval = removedFromBackendCats.find(
                    ({coverageEndDate}) => coverageEndDate === date
                );
                dates.push({
                    coverageEndDate: get(removedFromBackendCatsRemoval, 'coverageEndDate', null),
                    removalEventDate: get(removedFromBackendCatsRemoval, 'removalEventDate', null)
                });
            }
        });
        return dates;
    }

    function dogCoverageEndDates(activePets, fields) {
        const removedFromBackendDogs = activePets.filter(
            ({postEnrollmentPetRemoved, species, maxAgeData}) =>
                postEnrollmentPetRemoved === true &&
                species === 'Dog' &&
                maxAgeData === false
        );
        const removedFromFrontendDogs = fields.value.filter(
            (x) => x.uiPetTermination && x.species === 'Dog'
        );
        const backendRemovedCount = {};

        removedFromBackendDogs.forEach(({coverageEndDate}) => {
            if (backendRemovedCount[coverageEndDate]) {
                backendRemovedCount[coverageEndDate] += 1;
                return;
            }
            backendRemovedCount[coverageEndDate] = 1;
        });

        const frontendRemovedCount = {};

        removedFromFrontendDogs.forEach(({coverageEndDate}) => {
            if (frontendRemovedCount[coverageEndDate]) {
                frontendRemovedCount[coverageEndDate] += 1;
                return;
            }
            frontendRemovedCount[coverageEndDate] = 1;
        });

        Object.entries(frontendRemovedCount).forEach(([date, count]) => {
            backendRemovedCount[date] -= count;
        });
        const dates = [];

        Object.entries(backendRemovedCount).forEach(([date, count]) => {
            if (count > 0) {
                if (count > 0) {
                    const removedFromBackendDogsRemoval = removedFromBackendDogs.find(
                        ({coverageEndDate}) => coverageEndDate === date
                    );
                    dates.push({
                        coverageEndDate: get(removedFromBackendDogsRemoval, 'coverageEndDate', null),
                        removalEventDate: get(removedFromBackendDogsRemoval, 'removalEventDate', null)
                    });
                }
            }
        });
        return dates;
    }

    useEffect(() => {
        let activeData = [];
        if (localData && localData.length > 0) {
            activeData =
                localData && localData.length > 0
                    ? localData.filter(
                        ({status, terminatedPetMaxAgeData}) =>
                            ['ACTIVE', 'PENDING', 'TERMINATED', 'INELIGIBLE'].includes(
                                status
                            ) && terminatedPetMaxAgeData === false
                    )
                    : [];
        } else {
            activeData =
                petData && petData.length > 0
                    ? petData.filter(({status}) =>
                        ['ACTIVE', 'PENDING', 'TERMINATED'].includes(status)
                    )
                    : [];
        }
        setUiActivePetLength(activeData.length);
    }, [employeeLoading, JSON.stringify(petData), JSON.stringify(localData)]);
    const {apiData = {}, loading} = GetData(
        'get-group-employee-by-id',
        JSON.stringify({id: empId})
    );
    const planId = get(apiData, 'employeePlanConfirmation.planId', '');
    const {apiData: planPricing = [], loading: planPricingLoading} = GetData(
        'get-plan-pricing',
        JSON.stringify({planId}),
        !planId
    );
    const employeePlanChoices = get(apiData, 'planChoiceEmployees', []);
    const selectedPlan = employeePlanChoices
        .filter((item) => item.acceptGroupPlanChoice)
        .map((item) => {
            if (Array.isArray(planPricing)) {
                const plan =
                    planPricing.find((v) => v.planChoiceId === item.planChoiceId) || {};
                return {...item, ...plan};
            }
            return item;
        });

    function getMaxAge() {
        const maxAge = get(selectedPlan, '[0].maxAge', Infinity);
        if (maxAge === 0) return Infinity;
        return maxAge;
    }

    const maxAge = getMaxAge();
    if (
        breedsLoading ||
        dependentsLoading ||
        employeeLoading ||
        loading ||
        planPricingLoading
    ) {
        return <Spinner/>;
    }
    return (
        <Route
            header={{
                title: 'Post enrollment form',
                type: 'group-employees',
            }}
            isPrivate
            {...rest}
        >
            <ApolloConsumer>
                {
                    // eslint-disable-next-line no-unused-vars
                    (client) => {
                        return (
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                <FinalForm
                                    initialValues={{
                                        pets:
                                            employeeDependentData?.length > 0
                                                ? localData
                                                : activePets,
                                    }}
                                    keepDirtyOnReinitialize
                                    mutators={arrayMutators}
                                    // eslint-disable-next-line no-unused-vars
                                    onSubmit={async (values) => {
                                        setFormProcessing(true);
                                        gaEvent('Pet Demographics update');
                                        // disable pets with uiPetTermination flag
                                        const newValues = values?.pets?.map((x) => {
                                            x.dependentData = {
                                                age: x.age,
                                                breed: x.breed,
                                                petName: x.petName,
                                                species: x.species,
                                                uiPetTermination: x.uiPetTermination,
                                                weight: x.weight,
                                                reason: x.reason,
                                            };
                                            const activePetsFromBackendDates = activePets
                                                .filter(
                                                    ({postEnrollmentPetRemoved}) =>
                                                        postEnrollmentPetRemoved !== true
                                                )
                                                .map(({coverageEndDate}) => coverageEndDate);
                                            if (x.uiPetTermination) {
                                                let status = '';
                                                if (x.age > maxAge && x.demographicChangesRequired && get(x, 'parentEmployeeDependentId', null) === null) {
                                                    status = 'INELIGIBLE';
                                                    x.reason = 'Pet Over Plan Max Age';
                                                    x.terminatedPetMaxAgeData = true;
                                                    x.removalDate =
                                                        dateUtils.setAPIDateOnly(relativeDatetime);
                                                } else if (x.status === 'TERMINATED') {
                                                    status = 'TERMINATED';
                                                    x.reason = config.canadaEnv ? ReasonPetRemoval.find(o => o.value === x.reason)?.value : null;
                                                    x.removalDate = dateUtils.setAPIDateOnly(
                                                        dateUtils.setOffset2(x.removalDate)
                                                    );
                                                } else {
                                                    status = 'INACTIVE';
                                                    x.reason = config.canadaEnv ? ReasonPetRemoval.find(o => o.value === x.reason)?.value : null;
                                                    x.removalDate = dateUtils.setAPIDateOnly(
                                                        dateUtils.setOffset2(x.removalDate)
                                                    );
                                                }
                                                x.status = status;
                                                x.postEnrollmentPetRemoved = true;

                                                // eslint-disable-next-line no-nested-ternary
                                                x.removalEventDate = x.age > maxAge && get(x, 'parentEmployeeDependentId', null) === null ? dateUtils.setAPIDateOnly(dateUtils.setOffset2(x?.enrollmentEventDate || x?.coverageStartDate))
                                                    : x.removalEventDate ? dateUtils.setAPIDateOnly(dateUtils.setOffset2(x.removalEventDate)) : null;
                                                x.coverageEndDate =
                                                    x.age > maxAge && get(x, 'parentEmployeeDependentId', null) === null && x.demographicChangesRequired
                                                        ? dateUtils.setAPIDateOnly(
                                                            dateUtils.setOffset2(x.coverageStartDate)
                                                        )
                                                        : dateUtils.setAPIDateOnly(
                                                            dateUtils.setOffset2(x.coverageEndDate)
                                                        );
                                            } else {
                                                x.postEnrollmentPetRemoved = false;
                                                x.removalEventDate = null;
                                                if (x.age > maxAge && x.demographicChangesRequired && get(x, 'parentEmployeeDependentId', null) === null) {
                                                    x.status = 'INELIGIBLE';
                                                    x.reason = 'Pet Over Plan Max Age';
                                                    x.postEnrollmentPetRemoved = true;
                                                    x.maxAgeData = true;
                                                    x.removalDate =
                                                        dateUtils.setAPIDateOnly(relativeDatetime);
                                                    x.removalEventDate = dateUtils.setAPIDateOnly(dateUtils.setOffset2(x?.enrollmentEventDate || x?.coverageStartDate));
                                                    x.coverageEndDate = dateUtils.setAPIDateOnly(
                                                        dateUtils.setOffset2(x.coverageStartDate)
                                                    );
                                                } else {
                                                    if(x.status === 'ACTIVE') {
                                                        x.status = 'ACTIVE';
                                                    } else if (x.status === 'TERMINATED') {
                                                        x.status = 'TERMINATED';
                                                    }
                                                    x.reason = null;
                                                    x.removalDate = null;
                                                    x.postEnrollmentPetRemoved = false;
                                                    const endDate = activePetsFromBackendDates.pop();
                                                    x.coverageEndDate = dateUtils.setAPIDateOnly(
                                                        dateUtils.setOffset2(endDate)
                                                    );
                                                }
                                            }
                                            const startDate = x.coverageStartDate;
                                            x.coverageStartDate = dateUtils.setAPIDateOnly(
                                                dateUtils.setOffset2(startDate)
                                            );
                                            return x;
                                        });
                                        if (isConsentAgreed === false) {
                                            localStorage.setItem(
                                                `employeeDependentData_${empId}`,
                                                JSON.stringify(newValues)
                                            );
                                            return navigate(`/pdba-e-consent`, {
                                                state: {
                                                    employeeId: empId,
                                                },
                                            });
                                        }
                                        const payload = {
                                            employeeDependentList: newValues,
                                            employeeId: empId,
                                        };

                                        if (isConsentAgreed) {
                                            const apiCall = (pathName, params) =>
                                                client.query({
                                                    fetchPolicy: 'no-cache',
                                                    query: remoteActionQuery,
                                                    variables: {
                                                        name: pathName,
                                                        params: JSON.stringify(params),
                                                    },
                                                });
                                            return (
                                                apiCall('update-pet-demographic', payload)
                                                    // eslint-disable-next-line no-unused-vars
                                                    .then(async (response) => {
                                                        if (isConsentAgreed === false) {
                                                            return navigate(`/pdba-e-consent`, {
                                                                state: {
                                                                    employeeId: empId,
                                                                },
                                                            });
                                                        }
                                                        await apiCall(
                                                            'update-employee-demographic-confirmation',
                                                            {
                                                                employeeId: empId,
                                                                hasDemographicChanges: false,
                                                            }
                                                        )
                                                            .then(async () => {
                                                                if (
                                                                    role === USER_ROLE.BEN_ADMIN ||
                                                                    role === USER_ROLE.ADMIN ||
                                                                    role === USER_ROLE.BEN_BROKER ||
                                                                    role === USER_ROLE.SUPER_ADMIN
                                                                ) {
                                                                    navigate(`/group-employees/${empId}/details`);
                                                                } else {
                                                                    navigate(`/home`);
                                                                }
                                                            })
                                                            .catch((e) =>
                                                                setErrorSnack(
                                                                    `There was an error: ${e.message}`,
                                                                    config.notificationDuration
                                                                )
                                                            );
                                                    })
                                                    .catch((e) =>
                                                        setErrorSnack(
                                                            `There was an error: ${e.message}`,
                                                            config.notificationDuration
                                                        )
                                                    )
                                                    .finally(() =>
                                                        setTimeout(() => {
                                                            setFormProcessing(false);
                                                        }, 2000)
                                                    )
                                            );
                                        }
                                    }}
                                    render={({
                                                 handleSubmit,
                                                 submitting,
                                                 form,
                                                 values: formValues,
                                             }) => {
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <Box
                                                    sx={{
                                                        alignItems: 'center',
                                                        bg: 'white',
                                                        borderRadius: 4,
                                                        boxShadow: 1,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        padding: 6,
                                                    }}
                                                >
                                                    <Box
                                                        as="h1"
                                                        sx={{
                                                            color: 'primary',
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            ml: 3,
                                                            my: 4,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {t('groupEmployees.enrollmentPage.addPetInfo')}
                                                    </Box>
                                                    <Box
                                                        as="h3"
                                                        sx={{
                                                            color: 'primary',
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            ml: 3,
                                                            my: 4,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {t('groupEmployees.detailsPage.fillSpecies')}
                                                    </Box>
                                                    <FieldArray name="pets" {...rest}>
                                                        {({fields, meta}) => (
                                                            <>
                                                                <Box
                                                                    sx={{
                                                                        alignItems: 'flex-start',
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        flexWrap: 'wrap',
                                                                        justifyContent: 'space-around',
                                                                    }}
                                                                >
                                                                    {fields.map((name, index) => {
                                                                        const values = fields.value[index];
                                                                        if (values?.weight === 0) {
                                                                            form.change(`${name}.weight`, null);
                                                                        }
                                                                        const isPetSubmittedBefore =
                                                                            !values.demographicChangesRequired &&
                                                                            isConsentAgreed;
                                                                        const {uiId} = values;
                                                                        const removePet = async (
                                                                            name,
                                                                            form,
                                                                            uuid,
                                                                            endDate,
                                                                            removeReason,
                                                                        ) => {
                                                                            const currentPetInfo = activePets.filter(
                                                                                ({coverageEndDate}) =>
                                                                                    coverageEndDate === endDate,
                                                                            );
                                                                            activePets = activePets.map((x) => {
                                                                                if (x.uiId === uuid) {
                                                                                    x.uiPetTermination = true;
                                                                                    x.coverageEndDate = endDate;
                                                                                }
                                                                                return x;
                                                                            });
                                                                            await form.change(
                                                                                `${name}.coverageEndDate`,
                                                                                endDate
                                                                            );
                                                                            await form.change(
                                                                                `${name}.reason`,
                                                                                removeReason
                                                                            );

                                                                            await form.change(
                                                                                `${name}.uiPetTermination`,
                                                                                true
                                                                            );
                                                                            await form.change(
                                                                                `${name}.removalDate`,
                                                                                get(
                                                                                    currentPetInfo,
                                                                                    '0.removalDate',
                                                                                    null
                                                                                )
                                                                            );
                                                                            await form.change(
                                                                                `${name}.removalEventDate`,
                                                                                get(
                                                                                    currentPetInfo,
                                                                                    '0.removalEventDate',
                                                                                    null
                                                                                )
                                                                            );
                                                                            const getUiActivePet =
                                                                                get(formValues, 'pets', []).filter(
                                                                                    (item) => !item.uiPetTermination
                                                                                ).length - 1;
                                                                            setUiActivePetLength(getUiActivePet);
                                                                        };
                                                                        const {species} = values;

                                                                        const filterBreedEnv = config.canadaEnv ? 'CA' : 'US';

                                                                        const petBreeds = breeds
                                                                            ?.filter?.(
                                                                                (item) =>
                                                                                    item.species === values.species && item?.envFlag === filterBreedEnv
                                                                            )
                                                                            .map((item) => {
                                                                                return {
                                                                                    canEnglishValue: item.canEnglishValue,
                                                                                    frenchValue: item.frenchValue,
                                                                                    label: item.breedName,
                                                                                    value: item.breedName,
                                                                                };
                                                                            });

                                                                        const isFieldValues =
                                                                            values?.petName &&
                                                                            values?.age != null &&
                                                                            values?.weight &&
                                                                            values?.breed;

                                                                        return (
                                                                            <Box
                                                                                key={index}
                                                                                data-testid={`petformsection-${species}${index}-${values.uiPetTermination
                                                                                    ? 'disabled'
                                                                                    : 'enabled'
                                                                                }`}
                                                                                sx={{
                                                                                    border: values.uiPetTermination
                                                                                        ? '5px solid #e60000'
                                                                                        : null,
                                                                                    borderRadius: 3,
                                                                                    boxShadow: 7,
                                                                                    m: 2,
                                                                                    p: 3,
                                                                                }}
                                                                            >
                                                                                {config.canadaEnv && values.uiPetTermination && values.coverageEndDate &&
                                                                                    species === 'Dog' &&
                                                                                    <Box
                                                                                        sx={{color: 'error'}}>
                                                                                        {t('groupEmployees.detailsPage.removed')} {dateUtils.getUTCDateOnly(values.coverageEndDate)}<br/>
                                                                                        {t('groupEmployees.detailsPage.cause')}: {i18n.language === 'fr' ? ReasonPetRemoval.find(o => o.value === values.reason)?.frenchValue : ReasonPetRemoval.find(o => o.value === values.reason)?.label}
                                                                                    </Box>
                                                                                }
                                                                                {config.canadaEnv && values.uiPetTermination && values.coverageEndDate &&
                                                                                    species === 'Cat' &&
                                                                                    <Box
                                                                                        sx={{color: 'error'}}>
                                                                                        {t('groupEmployees.detailsPage.removed')} {dateUtils.getUTCDateOnly(values.coverageEndDate)}<br/>
                                                                                        {t('groupEmployees.detailsPage.cause')}: {i18n.language === 'fr' ? ReasonPetRemoval.find(o => o.value === values.reason)?.frenchValue : ReasonPetRemoval.find(o => o.value === values.reason)?.label}
                                                                                    </Box>
                                                                                }
                                                                                <Field
                                                                                    aria-label={t('groupEmployees.enrollmentPage.addPetSection.petSpecies')}
                                                                                    component={InputDropdown}
                                                                                    disabled
                                                                                    label={labelHandler(
                                                                                        t('groupEmployees.enrollmentPage.addPetSection.petSpecies'),
                                                                                        true
                                                                                    )}
                                                                                    name={`${name}.species`}
                                                                                    options={PET_SPECIES}
                                                                                    searchable={false}
                                                                                    validate={required}
                                                                                    wrapperSx={{...commonFieldSxHalf}}
                                                                                    {...rest}
                                                                                />
                                                                                <Field
                                                                                    aria-label={t('groupEmployees.enrollmentPage.addPetSection.petName')}
                                                                                    component={InputText}
                                                                                    disabled={
                                                                                        values.uiPetTermination ||
                                                                                        isPetSubmittedBefore
                                                                                    }
                                                                                    label={labelHandler(t('groupEmployees.enrollmentPage.addPetSection.petName'), true)}
                                                                                    maxLength={50}
                                                                                    name={`${name}.petName`}
                                                                                    validate={
                                                                                        values.uiPetTermination
                                                                                            ? null
                                                                                            : composeValidations(
                                                                                                required,
                                                                                                maxLength(
                                                                                                    50,
                                                                                                    'Maximum 50 charecters allowed.'
                                                                                                )
                                                                                            )
                                                                                    }
                                                                                    wrapperSx={{...commonFieldSxHalf}}
                                                                                    {...rest}
                                                                                />
                                                                                <Field
                                                                                    aria-label={t('metadata.policyEffectiveDate')}
                                                                                    component={SingleDatePicker}
                                                                                    disabled
                                                                                    label={labelHandler(
                                                                                        t('metadata.policyEffectiveDate'),
                                                                                        true
                                                                                    )}
                                                                                    name={`${name}.policyDate`}
                                                                                    wrapperSx={{...commonFieldSxHalf}}
                                                                                    {...rest}
                                                                                />
                                                                                <Field
                                                                                    aria-label={t('groupEmployees.enrollmentPage.addPetSection.petAgeEffective')}
                                                                                    component={InputDropdown}
                                                                                    disabled={
                                                                                        values.uiPetTermination ||
                                                                                        isPetSubmittedBefore
                                                                                    }
                                                                                    label={labelHandler(
                                                                                        t('groupEmployees.enrollmentPage.addPetSection.petAgeEffective'),
                                                                                        true
                                                                                    )}
                                                                                    name={`${name}.age`}
                                                                                    options={
                                                                                        values.species
                                                                                            ? PET_AGE[values.species]
                                                                                            : []
                                                                                    }
                                                                                    searchable={false}
                                                                                    validate={
                                                                                        values.uiPetTermination
                                                                                            ? null
                                                                                            : required
                                                                                    }
                                                                                    wrapperSx={{...commonFieldSxHalf}}
                                                                                    {...rest}
                                                                                />
                                                                                {values.demographicChangesRequired &&
                                                                                    values.age > maxAge && (
                                                                                        <Box
                                                                                            as="h4"
                                                                                            sx={{
                                                                                                color: 'error',
                                                                                                margin: '10px 0px 0px 5px;',
                                                                                                width: '25rem',
                                                                                                wordWrap: 'break-word',
                                                                                            }}
                                                                                        >
                                                                                            {`${values?.petName} is not eligible for coverage under the selected plan due to their age.  Complete the pet’s information here and click ‘SUBMIT’ to have the ineligible pet removed from the benefit.`}
                                                                                        </Box>
                                                                                    )}

                                                                                <Field
                                                                                    aria-label={t('groupEmployees.enrollmentPage.addPetSection.petWeightAdulthood')}
                                                                                    component={InputNumber}
                                                                                    disabled={
                                                                                        values.uiPetTermination ||
                                                                                        isPetSubmittedBefore
                                                                                    }
                                                                                    label={labelHandler(
                                                                                        t('groupEmployees.enrollmentPage.addPetSection.petWeightAdulthood'),
                                                                                        true
                                                                                    )}
                                                                                    name={`${name}.weight`}
                                                                                    type="number"
                                                                                    validate={
                                                                                        values.uiPetTermination
                                                                                            ? null
                                                                                            : composeValidations(
                                                                                                required,
                                                                                                lessThan65forCats(name),
                                                                                                lessThan0(name)
                                                                                            )
                                                                                    }
                                                                                    wrapperSx={{...commonFieldSxHalf}}
                                                                                    {...rest}
                                                                                />
                                                                                <Field
                                                                                    aria-label={t('groupEmployees.enrollmentPage.addPetSection.petBreed')}
                                                                                    component={InputDropdown}
                                                                                    disabled={
                                                                                        values.uiPetTermination ||
                                                                                        isPetSubmittedBefore
                                                                                    }
                                                                                    label={labelHandler(
                                                                                        t('groupEmployees.enrollmentPage.addPetSection.petBreed'),
                                                                                        true
                                                                                    )}
                                                                                    name={`${name}.breed`}
                                                                                    options={petBreeds}
                                                                                    searchable={false}
                                                                                    validate={
                                                                                        values.uiPetTermination
                                                                                            ? null
                                                                                            : required
                                                                                    }
                                                                                    wrapperSx={{...commonFieldSxHalf}}
                                                                                    {...rest}
                                                                                />
                                                                                {!values?.uiPetTermination &&
                                                                                    !formProcessing &&
                                                                                    !submitting && (
                                                                                        <Box
                                                                                            display="flex"
                                                                                            flexDirection="column"
                                                                                            justifyContent="center"
                                                                                            mb="2"
                                                                                            textAlign="center"
                                                                                        >
                                                                                            {species === 'Cat' &&
                                                                                                catCoverageEndDates(
                                                                                                    employeeDependentData?.length >
                                                                                                    0
                                                                                                        ? localData
                                                                                                        : activePets,
                                                                                                    fields
                                                                                                ).map(({
                                                                                                           coverageEndDate,
                                                                                                           removalEventDate
                                                                                                       }) => {
                                                                                                    const startDate = dateUtils.setOffset2(values?.coverageStartDate);
                                                                                                    const removalDate = dateUtils.setOffset2(coverageEndDate);
                                                                                                    // Prod #441 - Compare date values as dates and not strings
                                                                                                    const showRemoveButton = Date.parse(startDate) <= Date.parse(removalDate);
                                                                                                    return showRemoveButton ? (
                                                                                                        <ToolbarButton
                                                                                                            bg="accentSecondary"
                                                                                                            data-testid={`remove-${species}-${index}`}
                                                                                                            isDisabled={
                                                                                                                !isFieldValues
                                                                                                            }
                                                                                                            label={t('groupEmployees.detailsPage.removeDateStatement', {
                                                                                                                date: (dateUtils.getUTCDateOnly(
                                                                                                                    removalEventDate || coverageEndDate
                                                                                                                ))
                                                                                                            })}
                                                                                                            mt={10}
                                                                                                            onClick={() =>
                                                                                                                isFieldValues
                                                                                                                    ? setModalOpen([
                                                                                                                        true,
                                                                                                                        name,
                                                                                                                        uiId,
                                                                                                                        coverageEndDate,
                                                                                                                    ])
                                                                                                                    : () => {
                                                                                                                    }
                                                                                                            }
                                                                                                            type="button"
                                                                                                        />
                                                                                                    ) : null;
                                                                                                })}
                                                                                            {species === 'Dog' &&
                                                                                                // removed dog dates
                                                                                                dogCoverageEndDates(
                                                                                                    employeeDependentData?.length >
                                                                                                    0
                                                                                                        ? localData
                                                                                                        : activePets,
                                                                                                    fields
                                                                                                ).map(({
                                                                                                           coverageEndDate,
                                                                                                           removalEventDate
                                                                                                       }) => {
                                                                                                    const startDate = dateUtils.setOffset2(values?.coverageStartDate);
                                                                                                    const removalDate = dateUtils.setOffset2(coverageEndDate);
                                                                                                    // Prod #441 - Compare date values as dates and not strings
                                                                                                    const showRemoveButton = Date.parse(startDate) <= Date.parse(removalDate);
                                                                                                    return showRemoveButton ? (
                                                                                                        <ToolbarButton
                                                                                                            bg="accentSecondary"
                                                                                                            data-testid={`remove-${species}-${index}`}
                                                                                                            isDisabled={
                                                                                                                !isFieldValues
                                                                                                            }
                                                                                                            label={t('groupEmployees.detailsPage.removeDateStatement', {
                                                                                                                date: (dateUtils.getUTCDateOnly(
                                                                                                                    removalEventDate || coverageEndDate
                                                                                                                ))
                                                                                                            })}
                                                                                                            mt={10}
                                                                                                            onClick={() =>
                                                                                                                isFieldValues
                                                                                                                    ? setModalOpen([
                                                                                                                        true,
                                                                                                                        name,
                                                                                                                        uiId,
                                                                                                                        coverageEndDate,
                                                                                                                    ])
                                                                                                                    : () => {
                                                                                                                    }
                                                                                                            }
                                                                                                            type="button"
                                                                                                        />
                                                                                                    ) : null;
                                                                                                })}
                                                                                        </Box>
                                                                                    )}
                                                                                {config.canadaEnv &&
                                                                                    <Modal
                                                                                        isOpen={modalOpen[0]}
                                                                                        submitText={t('groupEmployees.enrollmentPage.addPetSection.removePet')}
                                                                                        title={t('groupEmployees.enrollmentPage.addPetSection.removePet')}
                                                                                    >
                                                                                        <FinalForm
                                                                                            initialValues={{}}
                                                                                            onSubmit={async (values) => {
                                                                                                const name = modalOpen[1];
                                                                                                const uiId = modalOpen[2];
                                                                                                const coverageEndDate =
                                                                                                    modalOpen[3];
                                                                                                const {reason} = values;
                                                                                                removePet(
                                                                                                    name,
                                                                                                    form,
                                                                                                    uiId,
                                                                                                    coverageEndDate,
                                                                                                    reason
                                                                                                );
                                                                                                setModalOpen([
                                                                                                    false,
                                                                                                    null,
                                                                                                    null,
                                                                                                    null,
                                                                                                    null,
                                                                                                ]);
                                                                                            }}
                                                                                            render={(formContext) => (
                                                                                                <form
                                                                                                    onSubmit={formContext.handleSubmit}>
                                                                                                    <Field
                                                                                                        aria-label={t('groupEmployees.detailsPage.removalReason')}
                                                                                                        component={InputDropdown}
                                                                                                        label={labelHandler(t('groupEmployees.detailsPage.removalReason'), true)}
                                                                                                        name="reason"
                                                                                                        options={ReasonPetRemoval}
                                                                                                        validate={composeValidations(reasonRequired)}
                                                                                                        wrapperSx={{padding: 4}}
                                                                                                    />
                                                                                                    {t('groupEmployees.detailsPage.removePetStatement')}

                                                                                                    <Box
                                                                                                        sx={{
                                                                                                            display: 'flex',
                                                                                                            justifyContent: 'space-around',
                                                                                                            mt: '30px',
                                                                                                        }}
                                                                                                    >
                                                                                                        <ToolbarButton
                                                                                                            g="error"
                                                                                                            label={t('common.cancel')}
                                                                                                            onClick={() =>
                                                                                                                setModalOpen([
                                                                                                                    false,
                                                                                                                    null,
                                                                                                                    null,
                                                                                                                    null,
                                                                                                                    null,
                                                                                                                ])
                                                                                                            }
                                                                                                            sx={{
                                                                                                                bg: 'red',
                                                                                                            }}
                                                                                                            width="150px"
                                                                                                        />
                                                                                                        <ToolbarButton
                                                                                                            g="error"
                                                                                                            label={t('groupEmployees.detailsPage.removePetButton')}
                                                                                                            submitting={formContext.submitting}
                                                                                                            type="submit"
                                                                                                            width="150px"
                                                                                                        />
                                                                                                    </Box>
                                                                                                </form>
                                                                                            )}
                                                                                        />
                                                                                    </Modal>
                                                                                }
                                                                                {!config.canadaEnv &&
                                                                                    <Modal
                                                                                        closeText={t('common.cancel')}
                                                                                        isOpen={modalOpen[0]}
                                                                                        onClose={() =>
                                                                                            setModalOpen([
                                                                                                false,
                                                                                                null,
                                                                                                null,
                                                                                                null,
                                                                                            ])
                                                                                        }
                                                                                        onSubmit={() => {
                                                                                            const name = modalOpen[1];
                                                                                            const uiId = modalOpen[2];
                                                                                            const coverageEndDate =
                                                                                                modalOpen[3];
                                                                                            removePet(
                                                                                                name,
                                                                                                form,
                                                                                                uiId,
                                                                                                coverageEndDate
                                                                                            );
                                                                                            setModalOpen([
                                                                                                false,
                                                                                                null,
                                                                                                null,
                                                                                                null,
                                                                                            ]);
                                                                                        }}
                                                                                        submitText={t('groupEmployees.enrollmentPage.addPetSection.removePet')}
                                                                                        title={t('groupEmployees.enrollmentPage.addPetSection.removePet')}
                                                                                    >
                                                                                        This pet will be removed from
                                                                                        your
                                                                                        policy. Are you sure you want to
                                                                                        remove this pet?
                                                                                    </Modal>
                                                                                }
                                                                            </Box>
                                                                        );
                                                                    })}
                                                                    {meta.error &&
                                                                        meta.touched &&
                                                                        !isArray(meta.error) && (
                                                                            <Box
                                                                                sx={{
                                                                                    color: 'error',
                                                                                    fontSize: 2,
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                {meta.error}
                                                                            </Box>
                                                                        )}
                                                                </Box>
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                    <Box
                                                        sx={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center',
                                                            maxWidth: '25rem',
                                                            my: 6,
                                                            p: 3,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <ToolbarButton
                                                            bg="accentSecondary"
                                                            isDisabled={
                                                                submitting
                                                                // || activePets.length === 0 ||
                                                                // getUiActivePetLength !== numberOfPets
                                                            }
                                                            label={t('common.submit')}
                                                            mr={0}
                                                            submitting={submitting}
                                                            type="submit"
                                                            width="10rem"
                                                        />
                                                    </Box>
                                                </Box>
                                            </form>
                                        );
                                    }}
                                />
                            </Box>
                        );
                    }
                }
            </ApolloConsumer>
        </Route>
    );
}
