import React from 'react';
import Box from '@basecomponents/Box';
import { IS_BROWSER } from '@utils/constants';

const NotFoundPage = () =>
  IS_BROWSER && (
    <Box
      sx={{
        alignItems: 'center',
        color: 'accent',
        display: 'flex',
        fontSize: 6,
        fontWeight: 'bold',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      404:
      <Box sx={{ fontWeight: 'normal', ml: 2 }}>Page not found</Box>
    </Box>
  );
export default NotFoundPage;
