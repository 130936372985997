import React from 'react';
import Box from '@basecomponents/Box';
import { useTranslation } from 'react-i18next';
import TermsDocument from '../../assets/documents/Terms_of_Use_5.2.21.pdf';
import PDFViewer from '../../components/Pet/PDFViewer';
import Route from '../../components/Pet/Route';

const TermsOfUse = ({ ...rest }) => {
  const { t } = useTranslation()
  return (
    <Route
      header={{
        icon: 'dashboard',
        title: t('footer.footerLinks.termsOfUseName'),
        type: 'Legal',
        typeLabel: 'Legal',
      }}
      isPrivate
      {...rest}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <PDFViewer file={TermsDocument} />
      </Box>
    </Route>
  );
};

export default TermsOfUse;
