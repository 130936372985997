import Box from '@basecomponents/Box';
import { get, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import { ApolloConsumer } from '@apollo/client';
import Modal from '@basecomponents/Modal';
import Dropdown from '@basecomponents/Dropdown';
import { required } from '@utils/validators';
import { Field, Form as FinalForm } from 'react-final-form';
import { USER_ROLE } from '@utils/constants';
import { AuthContext } from '@basecomponents/Auth';
import { useTranslation } from 'react-i18next';
import GetData from '@utils/get-data';
import getMetaData from '@utils/get-meta-data';
import localStorage from '@utils/local-storage';
import dateUtils from '@utils/date-utils';
import useSnackbar from '@utils/use-snackbar';
import DashboardPaginator from '@petcomponents/DashboardPaginator';
import EditAccessRightsContext from '@petcomponents/EditAccessRights/Context';
import DropdownMenu from '@basecomponents/DropdownMenu';
import ToolbarButton from '@basecomponents/ToolbarButton';
import TooltipBox from '@basecomponents/TooltipBox';
import Icon from '@src/components/Icon';
import remoteActionQuery from '@queries/remote-action.gql';
import config from '@src/config.json';
import ListFilterSection from './ListFilterSection';
import ListItem from './ListItem';
import ListItemLoading from './ListItemLoading';
import useViews from './utils/all-views';
import { useCellDensities, LS_CELL_DENSITY, TOOLTIP } from './utils/constants';
import getGridParams from './utils/get-grid-params';
import reducer from './utils/reducer';

const ListGrid = forwardRef(
  /**
   * @category BaseComponents
   * @param {Array<Object>} defaultFilterValues
   * @param {Array<Object>} filters
   * @param {Object} grid
   * @param {string} gridQueryName
   * @param {string} moduleName
   * @param {boolean} scrollTo
   * @param {*} rest
   * @param {any} ref
   * @returns {React.FC}
   */
  (
    {
      defaultFilterValues,
      exportReport,
      extBenAdminId,
      exportReportType,
      filters,
      getExpandedSection,
      grid,
      gridQueryName,
      groupPostEnrollmentGroup,
      moduleName,
      scrollTo,
      isExpandedSection,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const inputRef = useRef();
    const meta = getMetaData({ moduleName });
    const ear = useContext(EditAccessRightsContext);
    const { user } = useContext(AuthContext);
    const LS_DEFAULT_VIEW = `default-${moduleName}-view`;
    const LS_DISPLAY_FILTERS = `default-${moduleName}-show-filters`;
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [reportModal, setReportModal] = useState(false);
    const userRole = get(user, 'customRole', '');
    const { isResponsive, isHighContrastOn, isEnrollmentsDashboard } = rest;

    const [setErrorSnack] = useSnackbar({ color: 'error' });
    const [setSuccessSnack] = useSnackbar({ color: 'accent' });
    let reportType = [];
    if (
      exportReportType === 'GROUP' &&
      [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(userRole)
    ) {
      if (groupPostEnrollmentGroup) {
        reportType = [
          {
            frenchValue: "Rapport d'inscription",
            label: t('component.enrollmentReport'),
            value: 'ENROLLMENT_REPORT',
          },
          {
            frenchValue: "Rapport démographique sur les animaux de compagnie manquants",
            label: t('component.missingPetDemographicsReport'),
            value: 'MISSING_PET_DEMOGRAPHICS_REPORT',
          },
          {
            frenchValue: "Rapport sur les animaux inéligibles",
            label: t('component.ineligiblePetReport'),
            value: 'INELIGIBLE_PET_REPORT',
          },
        ];
      } else {
        reportType = [
          {
            frenchValue: "Rapport d'inscription",
            label: t('component.enrollmentReport'),
            value: 'ENROLLMENT_REPORT',
          },
        ];
      }
    } else {
      reportType = [
        {
          canEnglishValue: null,
          frenchValue: "Rapport démographique sur les animaux de compagnie manquants",
          label: t('component.missingPetDemographicsReport'),
          value: 'MISSING_PET_DEMOGRAPHICS_REPORT',
        },
        {
          canEnglishValue: null,
          frenchValue: "Rapport sur les animaux inéligibles",
          label: t('component.ineligiblePetReport'),
          value: 'INELIGIBLE_PET_REPORT',
        },
      ];
    }

    const allViews = useViews();
    const initalViewId =
      localStorage.getItem(LS_DEFAULT_VIEW) || `all-${moduleName}`;
    const isInitialView = get(allViews
      , moduleName, []).find(
        ({ isInitialView }) => isInitialView
      );
    const altInitialView = get(allViews
      , moduleName, []).find(
        ({ isAltInitialView, permission }) =>
          isAltInitialView && ear.isVisible(permission)
      );
    const initialView =
      isInitialView ||
      get(allViews
        , moduleName, []).find(({ id }) => id === initalViewId);

    const routeName = get(window?.location, 'pathname');

    const defaultSortField = get(grid, 'fields', []).find(
      ({ sortDefault }) => sortDefault
    );
    const [state, dispatch] = useReducer(reducer, {
      cellDensity: localStorage.getItem(LS_CELL_DENSITY) || `md`,
      currentViewId:
        get(altInitialView, 'id', null) ||
        get(initialView, 'id', null) ||
        initalViewId,
      filterValues:
        get(altInitialView, 'filterCriteria', null) ||
        get(initialView, 'filterCriteria', {}),
      isExportStatus: false,
      isListGrid: true,
      isMasterBillingCalendarRefreshing: false,
      isRefreshing: false,
      moduleName,
      offset: 0,
      pageSize: 10,
      showFilters: localStorage.getItem(LS_DISPLAY_FILTERS) === 'true',
      sortDirection: get(defaultSortField, 'sortDefault', 'asc'),
      sortField: get(defaultSortField, 'name'),
    });

    const {
      cellDensity,
      currentViewId,
      filterValues,
      isExportStatus,
      isRefreshing,
      isMasterBillingCalendarRefreshing,
      offset,
      pageSize,
      showFilters,
    } = state;

    const visibleFilters = filters.filter((filter) =>
      get(filter, 'visible', true)
    );

    const filterApplied = Object.keys(filterValues).reduce((res, key) => {
      const filterValue = get(filterValues, key);
      if (filterValue) {
        if (Array.isArray(filterValue)) {
          if (filterValue.length > 0) {
            return res || true;
          }
        }
        if (filterValue !== null) {
          return res || true;
        }
      }
      return res || false;
    }, false);

    const viewsData = get(allViews
      , moduleName).filter(({ permission }) =>
        ear.isVisible(permission)
      );
    const currentView = viewsData.find(({ id }) => id === currentViewId);
    const isViewModified = !isEqual(
      filterValues,
      get(currentView, 'filterCriteria', {})
    );
    const currentViewName = `${get(currentView, 'description')}${isViewModified ? '*' : ''
      }`;
    const {
      apiData = {},
      loading,
      refetch,
    } = GetData(
      gridQueryName,
      getGridParams({ defaultFilterValues, filters, state })
    );
    useImperativeHandle(ref, () => ({
      refetchTableData() {
        // done by old folks the refetch was not working;
        // refetch({gridQueryName, JSON.stringify(query)});
        refetch();
      },
    }));
    const { content: gridData = [], numberOfElements, totalElements } = apiData;

    useEffect(() => {
      if (scrollTo) {
        inputRef.current.scrollIntoView(false);
      }
      if (
        !loading &&
        gridData.length === 0 &&
        altInitialView &&
        isInitialLoad &&
        !isViewModified
      ) {
        setIsInitialLoad(false);
        dispatch({
          params: altInitialView || initialView,
          type: 'selectView',
        });
      }
    }, [gridData]);

    const cellDensities = useCellDensities();
    return (
      <Box ref={inputRef} height="auto" pb={[7, null, 5]} width="100%">
        <Box bg={config.canadaEnv ? "primary" : "accent"} sx={{ borderRadius: 2 }}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection={{ _: 'column', md: 'row' }}
            justifyContent="space-between"
            p={5}
            width="100%"
          >
            <Box>
              <DropdownMenu
                button={
                  <Box alignItems="center" color={config.canadaEnv ? "lightYellow" : "white"} display="flex" mx={2}>
                    <Box fontSize={4} fontWeight="bold">
                      {currentViewName}
                    </Box>
                    <Box height="1.5rem" mx={4} width="1.5rem">
                      <Icon height="1.5rem" svg="expand-more" width="1.5rem" />
                    </Box>
                  </Box>
                }
                itemActions={viewsData.map(
                  ({ id }) =>
                    () =>
                      dispatch({
                        params: viewsData.find((v) => v.id === id),
                        type: 'selectView',
                      })
                )}
                items={viewsData
                  .filter(({ permission }) => ear.isVisible(permission))
                  .map(({ description }) => description)}
              />
            </Box>
            <Box alignItems="center" display="flex" justifyContent="flex-end">
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <Box mx={isResponsive ? 0 : 6}>
                  <DashboardPaginator
                    enableJumpToPage={!isResponsive}
                    filters={{
                      page: offset * pageSize,
                    }}
                    goToNextPage={() => dispatch({ type: 'onPageNext' })}
                    goToPreviousPage={() => dispatch({ type: 'onPagePrev' })}
                    isClientSidePagination
                    onPageClick={(value) =>
                      dispatch({ params: value, type: 'onPageClick' })
                    }
                    pageTotal={numberOfElements}
                    sx={{
                      fontSize: 2,
                      pt: 5,
                    }}
                    totalRecordCount={totalElements}
                  />
                </Box>
                {!isResponsive &&
                  cellDensities.map(({ icon, id, label }) => (
                    <Box
                      key={`cd-${id}`}
                      data-for={`${id}-${TOOLTIP}`}
                      data-tip={label}
                      mx={2}
                    >
                      <ToolbarButton
                        aria-label={label}
                        bg={config.canadaEnv && label === t('component.defaultView') ? "accentSecondaryLighter" : "accentSecondary"}
                        icon={icon}
                        iconSx={{color: config.canadaEnv ? 'primary' : 'white'}}
                        isDisabled={id === cellDensity}
                        onClick={() =>
                          dispatch({ params: id, type: 'setDensity' })
                        }
                      />
                      <TooltipBox
                        id={`${id}-${TOOLTIP}`}
                        tooltipProps={{ effect: 'solid' }}
                      />
                    </Box>
                  ))}
                <Box
                  data-for={`refreshData-${TOOLTIP}`}
                  data-tip={t('component.refreshData')}
                  mx={2}
                >
                  <ToolbarButton
                    aria-label={t('component.refreshData')}
                    bg={config.canadaEnv ? "lightYellow" : "primary"}
                    icon="refresh"
                    iconSx={{color: config.canadaEnv ? 'primary' : 'white'}}
                    isLoading={isRefreshing}
                    onClick={async () => {
                      dispatch({ params: true, type: 'setRefreshing' });
                      await refetch();
                      dispatch({ params: false, type: 'setRefreshing' });
                    }}
                  />
                  <TooltipBox
                    id={`refreshData-${TOOLTIP}`}
                    tooltipProps={{ effect: 'solid' }}
                  />
                </Box>
                {routeName?.includes('masterCalendar') && (
                  <ApolloConsumer>
                    {(client) => {
                      const handleExportMasterBillingCalendar = async () => {
                        dispatch({
                          params: true,
                          type: 'setMasterBillingCalendarRefreshing',
                        });

                        const masterBillingCalendarView =
                          state?.filterValues?.masterBillingCalendarView;
                        const relativeDate = dateUtils.setAPIDateOnly(
                          state?.filterValues?.relativeDate,
                          true
                        );

                        return client
                          .query({
                            fetchPolicy: 'no-cache',
                            query: remoteActionQuery,
                            variables: {
                              name: 'export-master-billing-calendar',
                              params: JSON.stringify({
                                masterBillingCalendarView,
                                relativeDate,
                              }),
                            },
                          })
                          .then(() => {
                            dispatch({
                              params: false,
                              type: 'setMasterBillingCalendarRefreshing',
                            });
                            setSuccessSnack(
                              `Master Billing Calendar Report Exported Successfully`
                            );
                          })
                          .catch((e) => {
                            dispatch({
                              params: false,
                              type: 'setMasterBillingCalendarRefreshing',
                            });
                            setErrorSnack(`There was an error: ${e.message}`);
                          });
                      };

                      return (
                        <Box
                          data-for={TOOLTIP}
                          data-tip={t('component.exportMasterCalendar')}
                          mx={2}
                        >
                          <ToolbarButton
                            aria-label={t('component.exportMasterCalendar')}
                            icon="file-description"
                            isDisabled={visibleFilters.length === 0}
                            isLoading={isMasterBillingCalendarRefreshing}
                            onClick={handleExportMasterBillingCalendar}
                          />
                        </Box>
                      );
                    }}
                  </ApolloConsumer>
                )}
                {apiData?.content?.length > 0 &&
                  exportReport &&
                  !isEnrollmentsDashboard && (
                    <ApolloConsumer>
                      {(client) => {
                        const apiCall = (pathName, params) => {
                          return client.query({
                            fetchPolicy: 'no-cache',
                            query: remoteActionQuery,
                            variables: {
                              name: pathName,
                              params: JSON.stringify(params),
                            },
                          });
                        };
                        const handleExportStatus = async () => {
                          dispatch({
                            params: true,
                            type: 'setExportStatus',
                          });

                          const {
                            email,
                            empId,
                            enrollmentStatus,
                            exportStatus,
                            firstName,
                            groupName,
                            lastName,
                          } = get(state, 'filterValues');

                          const groupData = defaultFilterValues.find(
                            (o) => o.groupId
                          );

                          const appliedFiltersKey = Object.keys(filterValues);
                          const appliedFilters = appliedFiltersKey.toString();

                          const paramsData = {
                            appliedFilters,
                            email,
                            empId,
                            firstName,
                            groupName,
                            lastName,
                            ...groupData,
                          };
                          if (exportStatus) {
                            paramsData.exportStatus = exportStatus;
                          } else {
                            paramsData.enrollmentStatus = enrollmentStatus;
                          }
                          return apiCall('export-group-employee', paramsData)
                            .then(() => {
                              dispatch({
                                params: false,
                                type: 'setExportStatus',
                              });
                              setReportModal(false);
                              setSuccessSnack(
                                `Employee Report Exported Successfully`
                              );
                            })
                            .catch((e) => {
                              dispatch({
                                params: false,
                                type: 'setExportStatus',
                              });
                              setReportModal(false);
                              setErrorSnack(`There was an error: ${e.message}`);
                            });
                        };
                        const handleReportExportStatus = async (apiUrl) => {
                          dispatch({
                            params: true,
                            type: 'setExportStatus',
                          });
                          const groupData = defaultFilterValues.find(
                            (o) => o.groupId
                          );
                          const paramsData = {
                            reportType: exportReportType,
                            ...groupData,
                          };
                          if (exportReportType === 'BEN_ADMIN') {
                            paramsData.benAdminId = extBenAdminId;
                          }
                          return client
                            .query({
                              fetchPolicy: 'no-cache',
                              query: remoteActionQuery,
                              variables: {
                                name: apiUrl,
                                params: JSON.stringify({
                                  ...paramsData,
                                }),
                              },
                            })
                            .then(async (response) => {
                              dispatch({
                                params: false,
                                type: 'setExportStatus',
                              });
                              const data = JSON.parse(
                                get(response, 'data.remoteAction.data', [])
                              );
                              if (data?.reportGenerated) {
                                setSuccessSnack(`Report Exported Successfully`);
                              } else {
                                setErrorSnack('No Records Found');
                              }
                              setReportModal(false);
                            })
                            .catch((e) => {
                              dispatch({
                                params: false,
                                type: 'setExportStatus',
                              });
                              setReportModal(false);
                              setErrorSnack(`There was an error: ${e.message}`);
                            });
                        };

                        return (
                          <Box data-for={TOOLTIP} data-tip={t('common.export')} mx={2}>
                            <ToolbarButton
                              aria-label={t('common.exportStatus')}
                              icon="file-description"
                              isDisabled={visibleFilters.length === 0}
                              isLoading={isExportStatus}
                              onClick={() => setReportModal(true)}
                            />
                            {reportModal === true && (
                              <Modal
                                isOpen
                                title={
                                  exportReportType === 'GLOBAL'
                                    ? t('component.downloadReport')
                                    : t('component.exportReportType')
                                }
                              >
                                <FinalForm
                                  initialValues={{}}
                                  onSubmit={async (values) => {
                                    if (
                                      values?.reportType === 'ENROLLMENT_REPORT'
                                    ) {
                                      handleExportStatus();
                                    } else if (
                                      values?.reportType ===
                                      'INELIGIBLE_PET_REPORT'
                                    ) {
                                      handleReportExportStatus(
                                        'export-ineligible-pets'
                                      );
                                    } else {
                                      handleReportExportStatus(
                                        'missing-pets-report'
                                      );
                                    }
                                  }}
                                  render={({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                      <Box>
                                        <Field
                                          aria-label={t('component.reportType')}
                                          component={Dropdown}
                                          label={t('component.reportType')}
                                          name="reportType"
                                          options={reportType}
                                          validate={required}
                                          {...rest}
                                        />
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            mt: '30px',
                                            width: '100%',
                                          }}
                                        >
                                          <ToolbarButton
                                            g="error"
                                            label={t('common.cancel')}
                                            onClick={() =>
                                              setReportModal(false)
                                            }
                                            sx={{
                                              bg: 'error',
                                            }}
                                            width="150px"
                                          />
                                          <ToolbarButton
                                            g="error"
                                            label={t('common.submit')}
                                            submitting={isExportStatus}
                                            type="submit"
                                            width="150px"
                                          />
                                        </Box>
                                      </Box>
                                    </form>
                                  )}
                                />
                              </Modal>
                            )}
                          </Box>
                        );
                      }}
                    </ApolloConsumer>
                  )}
                <Box data-for={TOOLTIP} data-tip={t('component.viewHideFilters')} mx={2}>
                  <ToolbarButton
                    aria-label={t('component.viewHideFilters')}
                    bg={config.canadaEnv ? "lightYellow" : "primary"}
                    icon={filterApplied ? 'filter-filled' : 'filter-empty'}
                    iconSx={{color: config.canadaEnv ? 'primary' : 'white'}}
                    isDisabled={visibleFilters.length === 0}
                    onClick={() => dispatch({ type: 'toggleFilter' })}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            height={showFilters && visibleFilters.length > 0 ? 'auto' : 0}
            overflow={
              showFilters && visibleFilters.length ? 'visible' : 'hidden'
            }
          >
            <ListFilterSection
              filters={visibleFilters}
              filterValues={{
                ...filterValues,
              }}
              onChange={({ params }) =>
                dispatch({ params, type: 'onFilterChange' })
              }
            />
          </Box>
        </Box>
        {loading ? (
          <ListItemLoading gridState={state} pageSize={pageSize} />
        ) : (
          <ListItem
            data={gridData}
            filterValues={filterValues}
            getExpandedSection={getExpandedSection}
            grid={grid}
            gridState={state}
            isExpandedSection={isExpandedSection}
            isHighContrastOn={isHighContrastOn}
            isResponsive={isResponsive}
            meta={meta}
            refetch={refetch}
          />
        )}
        <TooltipBox
          id={TOOLTIP}
          tooltipProps={{ effect: 'solid', maxWidth: '100%' }}
        />
      </Box>
    );
  }
);

ListGrid.defaultProps = {
  defaultFilterValues: [],
  exportReport: false,
  exportReportType: null,
  extBenAdminId: null,
  filters: [],
  getExpandedSection: null,
  grid: {
    actions: [],
    fields: [],
  },
  groupPostEnrollmentGroup: false,
  isExpandedSection: false,
  scrollTo: false,
};

ListGrid.propTypes = {
  defaultFilterValues: PropTypes.arrayOf(PropTypes.shape({})),
  exportReport: PropTypes.bool,
  exportReportType: PropTypes.string,
  extBenAdminId: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  getExpandedSection: PropTypes.func,
  grid: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  gridQueryName: PropTypes.string.isRequired,
  groupPostEnrollmentGroup: PropTypes.bool,
  isExpandedSection: PropTypes.bool,
  moduleName: PropTypes.string.isRequired,
  scrollTo: PropTypes.bool,
};

export default ListGrid;
