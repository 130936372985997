import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';
import Spinner from '../Spinner';

/**
 * @category BaseComponents
 * @param {React.children} children
 * @param {boolean} loading
 * @param {string} title
 * @param {Object} cardSx
 * @returns {React.FC}
 */
const Card = ({ children, loading, title, cardSx }) => {
  const { defaultStyle = {} } = useContext(ThemeContext);
  const { outerBox = {}, titleBox = {} } = defaultStyle.defaultCard(cardSx);
  return (
    <Box sx={{ ...outerBox }}>
      {title && (
        <Box as="h2" sx={{ ...titleBox }}>
          {title}
        </Box>
      )}
      {loading ? <Spinner /> : <Box>{children}</Box>}
    </Box>
  );
};

Card.defaultProps = {
  cardSx: {},
  children: null,
  loading: false,
  title: '',
};

Card.propTypes = {
  cardSx: PropTypes.shape({}),
  children: PropTypes.node,
  loading: PropTypes.bool,
  title: PropTypes.string,
};

export default Card;
