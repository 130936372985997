import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ApolloConsumer } from '@apollo/client';
import { isEmpty } from 'lodash';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
import { AuthContext } from '@basecomponents/Auth';
import Modal from '@basecomponents/Modal';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import Input from '@basecomponents/Input';
import { OnChange } from 'react-final-form-listeners';
import { useLocation } from '@reach/router';
import moment from 'moment';
import useSsm from '@src/utilities/hooks/useSsm';
import { required } from '../../../../utilities/validators';
import Route from '../../Route';
import ListGrid from '../../../BaseComponents/ListGrid';
import GetData from '../../../../utilities/get-data';
import ToolbarButton from '../../../BaseComponents/ToolbarButton';
import useSnackbar from '../../../../utilities/use-snackbar';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql';
import InputDate from '../../../BaseComponents/InputDate';
import { labelHandler } from '../../../../utilities/label-utils';
import InputDropdown from '../../../BaseComponents/Dropdown';
import { USER_ROLE } from '../../../../utilities/constants';
import config from '../../../../config.json';
import dateUtils from '../../../../utilities/date-utils';
import PromoteModal from './PromoteModal';

function isPetDemographicEmployee(employee) {
  const isPostEnrollmentEmployee = get(employee, 'postEnrollmentGroup', false);
  return isPostEnrollmentEmployee;
}

function getVisibility(action, role) {
  if (action === 'promote' && role === 'EMPLOYEE') {
    return true;
  }
  if (action === 'demote' && role === 'EMPLOYER') {
    return true;
  }
  return false;
}

/**
 * Group employees dashboard page
 *
 * @category Employee
 * @param {boolean} actionDisabled
 * @param {any} groupId
 * @param {*} rest
 * @returns {React.FC}
 */
const GroupEmployeesDashboard = ({
  // actionDisabledTerminate,
  actionDisabled,
  groupId,
  groupPostEnrollmentGroup,
  location,
  ...rest
}) => {
  const {
    apiData: { EnrollmentStatus = [], TerminationType = [], ExportStatus = [] },
  } = GetData(
    'get-enum-options',
    JSON.stringify([
      { params: ['EnrollmentStatus', 'TerminationType', 'ExportStatus'] },
    ])
  );

  const postEnrollmentConfigEnabled = useSsm('iaic-post-enrollment');
  const pathName = useLocation().pathname;
  const [coverageModal, setCoverageModal] = useState(false);
  const [currentEmployee, setCurrentEmployeeId] = useState('');
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const userId = get(user, 'customSystemUserId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });
  const [empEndDate, setEmpEndDate] = useState(null);
  const [terminatedData, setTermination] = useState(null);
  const [showModal, setShowModal] = useState({});
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [showModalTermination, setShowModalTermination] = useState(false);
  const [showDeleteEmpModal, setShowDeleteEmpModal] = useState(null);
  const [showModalEnrollment, setShowModalEnrollment] = useState(false);
  const [demoteModal, setDemoteAdmin] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();

  const isEnrollmentsDashboard = get(location, 'pathname', '').includes(
    '/group-employees/dashboard/'
  );

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const filters = [
    {
      label: t('filters.firstName'),
      name: 'firstName',
      type: 'Input',
    },
    {
      label: t('filters.lastName'),
      name: 'lastName',
      type: 'Input',
    },
    {
      name: 'email',
      type: 'Input',
    },
    {
      label: t('filters.employeeId'),
      name: 'empId',
      type: 'Input',
    },
    {
      name: 'groupName',
      type: 'Input',
      visible: !groupId,
    },
    {
      label: t('groupEmployees.dashboardPage.enrollmentStatus'),
      name: 'enrollmentStatus',
      options: EnrollmentStatus,
      type: 'Dropdown',
    },
  ];
  const exportEmployeeConfigEnabled = useSsm('iaic-export-employee');
  if (
    !isEnrollmentsDashboard &&
    exportEmployeeConfigEnabled &&
    [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(role)
  ) {
    filters.push({
      label: t('groupEmployees.dashboardPage.exportStatus'),
      name: 'exportStatus',
      options: ExportStatus,
      type: 'Dropdown',
    });
  }

  if (config.canadaEnv) {
    filters.splice(6, 0, {
      label: t('groupEmployees.dashboardPage.policyNumber'),
      name: 'certificateNumber',
      type: 'Input',
    });
  } else {
    filters.splice(6, 0, {
      name: 'certificateNumber',
      type: 'Input',
    });
  }
  const removeEmployeeConfigEnabled = useSsm('iaic-remove-employee-enabled');

  const grid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'details',
            link: `/group-employees/${get(data, 'employeeId')}/details`,
          };
        },
        label: t('groupEmployees.dashboardPage.view'),
        name: 'view',
      },
      {
        getProps: (data) => {
          const currentDate =
            get(data, 'relativeDatetime') === null
              ? new Date()
              : dateUtils.setOffset2(get(data, 'relativeDatetime'));
          const enrollmentStartDate =
            get(data, 'employeeDates.enrollmentStartDate') === null
              ? new Date()
              : dateUtils.setOffset2(
                  get(data, 'employeeDates.enrollmentStartDate')
                );
          return !get(data, 'disableImportEmployee', '')
            ? {
                icon: 'users-add',
                isDisabled:
                  actionDisabled ||
                  !get(data, 'user.cognitoCreated') ||
                  ['ENROLLED'].includes(get(data, 'parentEmployee.status')) ||
                  [
                    'ENROLLED',
                    'TERMINATED',
                    'POLICY_EXPIRED',
                    'ACTIVE',
                    'PENDING',
                    'FAILED_TO_ENROLL',
                  ].includes(get(data, 'status', '')) ||
                  ['TERMINATED'].includes(get(data, 'employmentStatus', '')) ||
                  enrollmentStartDate > currentDate ||
                  ['IN_PROGRESS'].includes(
                    get(data, 'groupTransitionStatus')
                  ) ||
                  get(data, 'employeePlanConfirmation') !== null,
                link: `/group-employees/${get(data, 'employeeId')}/enroll`,
                permission: 'groupEmployee.button.enroll',
              }
            : {
                icon: 'users-add',
                isDisabled:
                  actionDisabled ||
                  !get(data, 'user.cognitoCreated') ||
                  ['ENROLLED'].includes(get(data, 'parentEmployee.status')) ||
                  [
                    'ENROLLED',
                    'TERMINATED',
                    'POLICY_EXPIRED',
                    'ACTIVE',
                    'PENDING',
                    'FAILED_TO_ENROLL',
                  ].includes(get(data, 'status', '')) ||
                  ['TERMINATED'].includes(get(data, 'employmentStatus', '')) ||
                  enrollmentStartDate > currentDate ||
                  ['IN_PROGRESS'].includes(
                    get(data, 'groupTransitionStatus')
                  ) ||
                  get(data, 'employeePlanConfirmation') !== null,
                onClick: () => {
                  setShowModalEnrollment(true);
                },
                permission: 'groupEmployee.button.enroll',
              };
        },
        label: t('groupEmployees.dashboardPage.enroll'),
        name: 'enroll',
      },
      {
        getProps: (data) => {
          const currentDate =
            get(data, 'relativeDatetime') === null
              ? new Date()
              : dateUtils.setOffset2(get(data, 'relativeDatetime'));
          const enrollmentStartDate =
            get(data, 'employeeDates.enrollmentStartDate') === null
              ? new Date()
              : dateUtils.setOffset2(
                  get(data, 'employeeDates.enrollmentStartDate')
                );
          const enrollmentEndDate =
            get(data, 'employeeDates.enrollmentEndDate') === null
              ? new Date()
              : dateUtils.setOffset2(
                  get(data, 'employeeDates.enrollmentEndDate')
                );
          if (data?.passiveRenewal === false) {
            return {
              visible: false,
            };
          }
          if (
            [
              USER_ROLE.EMPLOYER,
              USER_ROLE.ADMIN,
              USER_ROLE.SUPER_ADMIN,
            ].includes(role) === false
          ) {
            return {
              visible: false,
            };
          }

          const datesPresent =
            enrollmentStartDate && enrollmentEndDate && currentDate;
          const isWithinOE =
            currentDate >= enrollmentStartDate &&
            currentDate <= enrollmentEndDate;
          const coverageEndDate =
            data?.parentEmployee?.employeeDates?.coverageEndDate;
          if (datesPresent && isWithinOE && coverageEndDate != null) {
            return {
              icon: 'close',
              onClick: () => {
                const formattedToDate = moment(
                  dateUtils.setOffset2(coverageEndDate)
                )?.format('DD/MM/YYYY');
                setEmpEndDate(formattedToDate);
                setCurrentEmployeeId(data.employeeId);
                setCoverageModal(true);
              },
            };
          }
          return {
            visible: false,
          };
        },
        label: 'Opt-Out',
        name: 'cancel',
      },
      {
        getProps: (data, refetch) => {
          const role = get(data, 'user.role');
          return {
            icon: 'promote',
            isDisabled:
              (get(data, 'user.email') ?? '') === '' ||
              ['IN_PROGRESS'].includes(get(data, 'groupTransitionStatus')),
            onClick: () => {
              setShowModal({ ...data, refetch });
            },
            permission: 'groupEmployee.button.setAdmin',
            visible: getVisibility('promote', role),
          };
        },
        label: t('groupEmployees.dashboardPage.promote'),
        name: 'promote',
      },
      {
        getProps: (data, refetch) => {
          const role = get(data, 'user.role');
          return {
            icon: 'remove',
            isDisabled:
              (get(data, 'user.email') ?? '') === '' ||
              ['IN_PROGRESS'].includes(get(data, 'groupTransitionStatus')),
            onClick: () => {
              setDemoteAdmin({ ...data, refetch });
            },
            permission: 'groupEmployee.button.setAdmin',
            visible: getVisibility('demote', role),
          };
        },
        label: t('groupEmployees.dashboardPage.removeAsGroupAdmin'),
        name: 'remove-as-group-admin',
      },
      {
        getProps: (data, refetch) => {
          // const role = get(data, 'user.role');
          return {
            icon: 'close',
            isDisabled:
              !['INACTIVE', 'ENROLLED', 'TERMINATED'].includes(
                get(data, 'status', '')
              ) ||
              actionDisabled ||
              ['IN_PROGRESS'].includes(get(data, 'groupTransitionStatus')),
            onClick: () => {
              setShowDeleteEmpModal({
                employeeId: get(data, 'employeeId'),
                empName: `${get(data, 'user.firstName')}  ${get(
                  data,
                  'user.lastName'
                )}`,
                refetch,
              });
            },
            permission: 'groupEmployee.button.removeEmployee',
            visible: removeEmployeeConfigEnabled,
          };
        },
        label: t('groupEmployees.dashboardPage.deleteEmployee'),
        name: 'delete-employee',
      },
    ],
    fields: [
      { name: 'empId' },
      { name: 'user' },
      { name: 'user.email' },
      {
        label: t('metadata.enrollmentStatus'),
        name: 'enrollmentStatus',
      },
      { name: 'dateOfHire' },
      { name: 'groupId', visible: !groupId },
      {
        label: t('metadata.phoneNumber'),
        name: 'user.contacts[0].phoneNumber',
      },
    ],
  };
  if (config.canadaEnv) {
    grid.fields.splice(4, 0, {
      label: t('groupEmployees.dashboardPage.policyNumber'),
      name: 'certificateNumber',
    });
  } else {
    grid.fields.splice(4, 0, {
      name: 'certificateNumber',
    });
  }
  if (
    [
      USER_ROLE.ADMIN,
      USER_ROLE.SUPER_ADMIN,
      USER_ROLE.EMPLOYER,
      USER_ROLE.BEN_ADMIN,
      USER_ROLE.BEN_BROKER,
    ].includes(role)
  ) {
    grid.actions.push({
      getProps: (data, refetch) => {
        const disableEmployeeTerminate =
          !['INACTIVE', 'ENROLLED', 'TERMINATED'].includes(
            get(data, 'status', '')
          ) || // actionDisabledTerminate ||
          actionDisabled ||
          (postEnrollmentConfigEnabled && isPetDemographicEmployee(data)) ||
          ['IN_PROGRESS'].includes(get(data, 'groupTransitionStatus'));
        return {
          icon: 'remove',
          isDisabled: disableEmployeeTerminate,
          onClick: () =>
            !get(data, 'disableImportEmployee', '')
              ? setShowModalTermination({
                  ...data,
                  refetch,
                })
              : setShowModalEnrollment(true),
          permission: 'groupEmployee.button.terminate',
        };
      },
      label: t('groupEmployees.dashboardPage.terminate'),
      name: 'terminate',
    });
  }
  const defaultFilterValues = groupId
    ? [{ groupId }, { sort: 'empId,asc' }]
    : [{ sort: 'created,desc' }];
  const uiContent = (
    <ApolloConsumer>
      {(client) => {
        const userName = `${get(terminatedData, 'user.firstName')}  ${get(
          terminatedData,
          'user.lastName'
        )}`;

        const handleDeleteEmployee = async () => {
          return client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'delete-specific-employee',
                params: JSON.stringify({
                  employeeId: showDeleteEmpModal?.employeeId,
                }),
              },
            })
            .then(() => {
              setSuccessSnack(
                t('snack.success.deleteEmployee'),
                config.notificationDuration
              );
              showDeleteEmpModal?.refetch();
              setShowDeleteEmpModal(null);
            })
            .catch((e) => {
              setShowDeleteEmpModal(null);
              return setErrorSnack(
                t('snack.error.errorWithMessage', { message: e.message }),
                config.notificationDuration
              );
            });
        };

        return (
          <>
            <Modal
              closeText={t('common.ok')}
              isOpen={showModalEnrollment}
              onClose={() => {
                setShowModalEnrollment(false);
              }}
              shouldCloseOnOverlayClick={false}
            >
              <Box>{t('groupEmployees.dashboardPage.wait')}</Box>
            </Modal>
            <ListGrid
              ref={rest?.listGridRef}
              defaultFilterValues={defaultFilterValues}
              exportReport={
                exportEmployeeConfigEnabled &&
                [
                  USER_ROLE.ADMIN,
                  USER_ROLE.SUPER_ADMIN,
                  USER_ROLE.BEN_ADMIN,
                  USER_ROLE.EMPLOYER,
                  USER_ROLE.BEN_BROKER,
                ].includes(role)
              }
              exportReportType="GROUP"
              filters={filters}
              grid={grid}
              gridQueryName="get-all-group-employees"
              groupPostEnrollmentGroup={groupPostEnrollmentGroup}
              isEnrollmentsDashboard={isEnrollmentsDashboard}
              moduleName={
                isEnrollmentsDashboard
                  ? 'enrollment-group-employees'
                  : 'group-employees'
              }
              {...rest}
            />
            {!isEmpty(showModal) && (
              <PromoteModal
                client={client}
                setErrorSnack={setErrorSnack}
                setShowModal={setShowModal}
                setSubmitting={setSubmitting}
                setSuccessSnack={setSuccessSnack}
                showModal={showModal}
                submitting={submitting}
              />
            )}
            {!isEmpty(demoteModal) && (
              <Modal
                closeText={t('common.cancel')}
                isOpen={!isEmpty(demoteModal)}
                onClose={() => {
                  setDemoteAdmin({});
                }}
                onSubmit={async () => {
                  setSubmitting(true);
                  const params = {
                    employeeId: get(demoteModal, 'employeeId'),
                  };
                  await client
                    .query({
                      fetchPolicy: 'no-cache',
                      query: remoteActionQuery,
                      variables: {
                        name: 'remove-group-admin',
                        params: JSON.stringify(params),
                      },
                    })
                    .then(async () => {
                      demoteModal.refetch();
                      setDemoteAdmin({});
                      setSubmitting(false);
                    })
                    .catch((e) => {
                      setErrorSnack(
                        t('snack.error.errorWithMessage', {
                          message: e.message,
                        }),
                        config.notificationDuration
                      );
                      setDemoteAdmin({});
                      setSubmitting(false);
                    });
                }}
                submitText={t(
                  'groupEmployees.dashboardPage.removeAsGroupAdmin'
                )}
                submitting={submitting}
                title={t('groupEmployees.dashboardPage.removeAsGroupAdmin')}
              >
                <Box>
                  {t('groupEmployees.dashboardPage.wishToDemote', {
                    firstName: get(demoteModal, 'user.firstName'),
                    lastName: get(demoteModal, 'user.lastName'),
                  })}
                  <Box
                    sx={{
                      display: 'flex',
                      fontWeight: 'bold',
                      justifyContent: 'center',
                      my: 5,
                    }}
                  >
                    {t('groupEmployees.dashboardPage.cannotBeUndone')}
                  </Box>
                </Box>
              </Modal>
            )}
            {!isEmpty(showModalTermination) && (
              <Modal
                closeText={t('common.no')}
                isOpen={!isEmpty(showModalTermination)}
                onClose={() => {
                  setShowModalTermination(null);
                }}
                onSubmit={() => {
                  setTermination(showModalTermination);
                  setShowModalTermination(null);
                }}
                submitText={t('common.yes')}
              >
                <Box
                  sx={{
                    color: 'error',
                    mb: '10px',
                  }}
                >
                  {t('groupEmployees.dashboardPage.cancelCoverage')}
                </Box>
              </Modal>
            )}
            <Modal
              isOpen={coverageModal}
              onClose={() => {
                setCoverageModal(false);
              }}
              onSubmit={async () => {
                setSubmitting(true);
                return client
                  .query({
                    fetchPolicy: 'no-cache',
                    query: remoteActionQuery,
                    variables: {
                      name: 'update-employee',
                      params: JSON.stringify({
                        declineFutureCoverageOtherReason: otherReason,
                        declineFutureCoverageReason: reason,
                        declineFutureCoverage: true,
                        employeeId: currentEmployee,
                        userId,
                      }),
                    },
                  })
                  .then(() => setSuccessSnack('Declined Coverage Successfully'))
                  .catch(() => setErrorSnack('Error: Something went wrong'))
                  .finally(() => {
                    setSubmitting(false);
                    setCoverageModal(false);
                    return navigate(pathName);
                  });
              }}
              shouldCloseOnOverlayClick
              submitText={t('action.decline')}
              submitting={submitting}
              title="Decline to Renew Coverage"
            >
              <Box as="h4" mb={5} py={3}>
                You are about to decline coverage for the upcoming plan year. By
                proceeding, your current coverage will end on {empEndDate}.
              </Box>
              <FinalForm
                onSubmit={() => {}}
                render={({ values }) => {
                  return (
                    <>
                      <Field
                        component={InputDropdown}
                        isSearchable={false}
                        label="Please Select your reason for discontinuing coverage:"
                        name="reasonToDecline"
                        options={[
                          {
                            label: t('reason.petPassedAway'),
                            value: 'My pet passed away',
                          },
                          {
                            label: t('reason.noLongerOwn'),
                            value: 'No longer own this pet',
                          },
                          {
                            label: t('reason.unsatisfactory'),
                            value:
                              'Unsatisfactory customer / claims experience',
                          },
                          {
                            label: t('reason.switched'),
                            value: 'Switched to another provider',
                          },
                          {
                            label: t('reason.cost'),
                            value: 'Cost',
                          },
                          {
                            label: t('reason.notFindValue'),
                            value: 'Did not use or find value in the insurance',
                          },
                          {
                            label: t('reason.other'),
                            value: 'Other',
                          },
                        ]}
                      />
                      {values.reasonToDecline === 'Other' && (
                        <Field
                          component={Input}
                          label="Please share your other reason for declining coverage:"
                          name="otherReason"
                        />
                      )}
                      <OnChange name="reasonToDecline">
                        {(reason) => {
                          setReason(reason);
                          if (reason !== 'Other') {
                            setOtherReason('');
                          }
                        }}
                      </OnChange>
                      <OnChange name="otherReason">
                        {(otherReason) => {
                          setOtherReason(otherReason);
                        }}
                      </OnChange>
                    </>
                  );
                }}
              />
            </Modal>
            {!isEmpty(terminatedData) && (
              <Modal
                isOpen={!isEmpty(terminatedData)}
                onClose={() => setTermination({})}
                title={
                  get(terminatedData, 'employmentStatus') === 'TERMINATED'
                    ? 'Update Termination Date'
                    : 'Terminate User'
                }
              >
                <FinalForm
                  initialValues={{}}
                  onSubmit={(values) =>
                    client
                      .query({
                        fetchPolicy: 'no-cache',
                        query: remoteActionQuery,
                        variables: {
                          name:
                            get(terminatedData, 'employmentStatus') ===
                            'TERMINATED'
                              ? 'update-termination-date'
                              : 'update-employee',
                          params: JSON.stringify({
                            employeeId: get(terminatedData, 'employeeId', ''),
                            employmentStatus: 'TERMINATED',
                            ...values,
                            terminationDate: dateUtils.setAPIDateOnly(
                              get(values, 'terminationDate')
                            ),
                            userId,
                          }),
                        },
                      })
                      .then(async (response) => {
                        const contactResponse = await JSON.parse(
                          get(response, 'data.remoteAction.data', '{}')
                        );
                        const retroactiveTermination = await get(
                          contactResponse,
                          'retroactiveTermination',
                          false
                        );
                        if (retroactiveTermination) {
                          setSuccessSnack(
                            t('snack.success.eventCreateLegend'),
                            config.notificationDuration
                          );
                        }
                        await terminatedData.refetch();
                        setTermination({});
                      })
                      .catch((e) => {
                        setTermination({});
                        setErrorSnack(
                          t('snack.error.errorWithMessage', {
                            message: e.message,
                          }),
                          config.notificationDuration
                        );
                      })
                  }
                  render={(formContext) => (
                    <form onSubmit={formContext.handleSubmit}>
                      <Box>
                        {get(terminatedData, 'employmentStatus') ===
                        'TERMINATED' ? (
                          <Box
                            sx={{
                              color: 'error',
                              fontSize: '2',
                            }}
                          >
                            {t(
                              'groupEmployees.dashboardPage.updateTerminationDate'
                            )}
                          </Box>
                        ) : (
                          <Box />
                        )}
                        {t('groupEmployees.dashboardPage.byConfirming', {
                          userName,
                        })}
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          {get(terminatedData, 'employmentStatus') ===
                          'TERMINATED' ? (
                            <Field
                              aria-label={t(
                                'groupEmployees.dashboardPage.terminationDate'
                              )}
                              component={InputDate}
                              disabled
                              initialValue={`${dateUtils.setOffset2(
                                get(terminatedData, 'terminationDate')
                              )}`}
                              label={labelHandler(
                                t(
                                  'groupEmployees.dashboardPage.terminationDate'
                                ),
                                true
                              )}
                              name="terminationDate"
                              validate={required}
                              wrapperSx={{ ...commonFieldSxHalf }}
                              {...rest}
                            />
                          ) : (
                            <Field
                              aria-label={t(
                                'groupEmployees.dashboardPage.terminationDate'
                              )}
                              component={InputDate}
                              label={labelHandler(
                                t(
                                  'groupEmployees.dashboardPage.terminationDate'
                                ),
                                true
                              )}
                              name="terminationDate"
                              validate={required}
                              wrapperSx={{ ...commonFieldSxHalf }}
                              {...rest}
                            />
                          )}
                          <Field
                            aria-label={t(
                              'groupEmployees.dashboardPage.terminationType'
                            )}
                            component={InputDropdown}
                            label={labelHandler(
                              t('groupEmployees.dashboardPage.terminationType'),
                              true
                            )}
                            name="terminationType"
                            options={TerminationType}
                            searchable={false}
                            validate={required}
                            wrapperSx={commonFieldSxHalf}
                            {...rest}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            mt: '30px',
                          }}
                        >
                          <ToolbarButton
                            g="error"
                            label={t('common.cancel')}
                            onClick={() => setTermination(null)}
                            sx={{
                              bg: 'error',
                            }}
                            width="10rem"
                          />
                          <ToolbarButton
                            g="error"
                            label={t('common.submit')}
                            submitting={formContext.submitting}
                            type="submit"
                            width="10rem"
                          />
                        </Box>
                      </Box>
                    </form>
                  )}
                />
              </Modal>
            )}
            {showDeleteEmpModal && (
              <Modal
                closeText={t('common.no')}
                isOpen
                onClose={() => setShowDeleteEmpModal(false)}
                onSubmit={() => {
                  handleDeleteEmployee();
                }}
                submitText={t('common.yes')}
                title={t('groupEmployees.dashboardPage.confirmSubmission')}
              >
                <Box>
                  {t('groupEmployees.dashboardPage.byClickingYes', {
                    employerName: showDeleteEmpModal?.empName,
                  })}
                  <Box
                    sx={{
                      display: 'flex',
                      fontWeight: 'bold',
                      justifyContent: 'center',
                      my: 5,
                    }}
                  >
                    {t('groupEmployees.dashboardPage.cannotBeUndone')}
                  </Box>
                  {t('groupEmployees.dashboardPage.wishToContinue')}
                </Box>
              </Modal>
            )}
          </>
        );
      }}
    </ApolloConsumer>
  );
  return groupId ? (
    uiContent
  ) : (
    <Route
      header={{
        title: t('groupEmployees.dashboardPage.title'),
        type: 'group-employees',
      }}
      isPrivate
      permission="groupEmployee.page.dashboard"
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {t('groupEmployees.dashboardPage.enrollmentsDashboard')}
      </Box>
      {uiContent}
    </Route>
  );
};

GroupEmployeesDashboard.defaultProps = {
    actionDisabled: false, // actionDisabledTerminate: false,
    groupId: null, groupPostEnrollmentGroup: false, location: {},
};

GroupEmployeesDashboard.propTypes = {
    actionDisabled: PropTypes.bool, // actionDisabledTerminate: PropTypes.bool,
    groupId: PropTypes.string, groupPostEnrollmentGroup: PropTypes.bool, location: PropTypes.shape({}),
};

export default GroupEmployeesDashboard;
