import Box from '@basecomponents/Box';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../../../../Icon';

const imgContainerSx = {
  p: 1,
  svg: { height: 'auto', width: '10rem' },
};

/**
 * @category Claims
 * @param {object} wrapperSx Styling for the section
 * @returns {React.FC}
 */

const FigoInfo = ({ wrapperSx }) => {
  const { colors } = useContext(ThemeContext);
  return (
    <Box sx={{ mb: 6, minHeight: '100vh' }}>
      <Box
        sx={{
          ...wrapperSx,
          color: 'primary',
          fontSize: 3,
          lineHeight: 2,
          m: '1.25rem auto',
          width: ['90%', null, '70%'],
        }}
      >
        <Box
          sx={{
            color: `${colors.accent}`,
            fontSize: '1rem',
            fontWeight: 600,
            letterSpacing: '0.05em',
            maxWidth: 'fit-content',
            textTransform: 'uppercase',
          }}
        >
          How do I file a claim?
        </Box>
        <Box>Electronic/Paperless Claims Submission</Box>
        <Box>
          All Figo policyholders can file a claim via the Figo Pet Cloud in
          minutes. Download the Figo Pet Cloud app for iOS or Android or &nbsp;
          <Box
            as="a"
            href="https://www.mypetcloud.com/"
            sx={{
              color: `${colors.accent}`,
              textDecoration: 'underline',
            }}
            target="_blank"
          >
            log in from your desktop.
          </Box>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            aria-label="Apple App Store Link"
            as="a"
            href="https://apps.apple.com/us/app/figo-pet-cloud/id1278520013"
            target="_blank"
          >
            <Icon color="primary" svg="appstore" sx={imgContainerSx} />
          </Box>
          <Box
            aria-label="Google Play Store Link"
            as="a"
            href="https://play.google.com/store/apps/details?id=com.figoinsurance.petcloud"
            target="_blank"
          >
            <Icon color="primary" svg="playstore" sx={imgContainerSx} />
          </Box>
        </Box>
        <Box>Once you’re logged in:</Box>
        <Box>1. Scroll down to “My Insurance” and select “File A Claim”</Box>
        <Box>
          2. Answer a few questions and upload a copy or picture of your
          paid-in-full invoice.
        </Box>
        <Box>3. Click submit and you’re done!</Box>
      </Box>
    </Box>
  );
};
export default FigoInfo;
FigoInfo.propTypes = {
  wrapperSx: PropTypes.shape({}),
};

FigoInfo.defaultProps = {
  wrapperSx: {},
};
