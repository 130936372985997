import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Select from 'react-select';
import uniqBy from 'lodash/uniqBy';
import { ThemeContext } from 'styled-components';
import Box from '@basecomponents/Box';
import { useTranslation } from "react-i18next";
import { get, isEmpty, negate } from "lodash";
import InputWrapper from '../InputWrapper';
import Option from './Option';
import ValueContainer from './ValueContainer';

/**
 * @category BaseComponents
 * @param {Function} input
 * @param {*} rest
 * @returns {React.FC}
 */
const Dropdown = ({ input, ...rest }) => {
  const theme = useContext(ThemeContext);
  const { i18n } = useTranslation();
  const [asyncOptions, setAsyncOptions] = useState([]);
  const { defaultStyle = {} } = useContext(ThemeContext);
  const {
    formatGroupLabelBox = {},
    optionLabelBox = {},
    optionPillBox = {},
    pillBox = {},
    subTextBox = {},
  } = defaultStyle.defaultDropDown;

  const isNotEmpty = negate(isEmpty);

  const getLabel = (data, language) => {
    if (language === 'caEn' && get(data, 'canEnglishValue', '') !== '') {
      return data.canEnglishValue;
    } if (language === 'fr' && get(data, 'frenchValue', '') !== '') {
      return data.frenchValue;
    } 
      return data.label;
    
  };

  const formatGroupLabel = (data) => (
    <Box sx={{ ...formatGroupLabelBox }}>
      {getLabel(data, i18n.language)}
    </Box>
  );

  return (
    <InputWrapper dataCy={rest['data-cy']} input={input} name={input.name} {...rest}>
      {({
        backspaceRemoves = false,
        checkIcon,
        error,
        externalAsyncOptions = null,
        isMulti,
        loadOptions,
        name,
        placeholder = '',
        setExternalAsyncOptions,
        uncheckIcon,
        ...inputRest
      }) => {
        const overrideStyles = defaultStyle.getDropDownOverrideStyles({
          error,
          name,
          theme,
        });

        const getLabel = (option, language) => {
          if (language === 'caEn' && get(option, 'canEnglishValue', '') !== '') {
            return option.canEnglishValue;
          } if (language === 'fr' && get(option, 'frenchValue', '') !== '') {
            return option.frenchValue;
          } 
            return option.label;
          
        };

        const getOptionLabel = (option) => (
          <span title={option.subtext}>
            <Box sx={{ ...optionLabelBox }}>
              <Box sx={{ ...optionPillBox }}>
                {getLabel(option, i18n.language)}
              </Box>
              {option.pill && <Box sx={{ ...pillBox }}>{option.pill}</Box>}
            </Box>
            <Box sx={{ ...subTextBox }}>{option.subtext}</Box>
          </span>
        );

    //     const getOptionLabel = (option) => (
    //       <span title={option.subtext}>
    //         <Box sx={{ ...optionLabelBox }}>
    //           <Box sx={{ ...optionPillBox }}>
    //             {i18n.language === 'caEn' && get(option, 'canEnglishValue', '') !== ''
    // ? option.canEnglishValue
    // : i18n.language === 'fr' && get(option, 'frenchValue', '') !== ''
    // ? option.frenchValue
    // : option.label}</Box>
    //           {option.pill && <Box sx={{ ...pillBox }}>{option.pill}</Box>}
    //         </Box>
    //         <Box sx={{ ...subTextBox }}>{option.subtext}</Box>
    //       </span>
    //     );

        const onChange = (value, meta) => {
          let parsedValue = '';
          let subValues = null;

          if (value) {
            if (Array.isArray(value)) {
              parsedValue = value.length ? value.map((o) => o.value) : '';
            } else {
              parsedValue = value.value;
            }
            subValues = value?.subValues;
          }
          if (inputRest.onChange) inputRest.onChange(parsedValue, meta, subValues);
          input.onChange(parsedValue, meta, subValues);
        };

        const optionByValue = (value) => {
          // after search add new options in state
          const newAsyncOptions = externalAsyncOptions || asyncOptions;

          const getOptionVal =  [...(inputRest.options || []), ...newAsyncOptions]
            .reduce((acc, o) => [...acc, ...(o.options || [o])], [])
            .find((o) => o.value === value);

          if(i18n.language === 'fr' && get(getOptionVal, 'frenchValue', '') !== '' ){
            return {
              label: getOptionVal.frenchValue || getOptionVal.label,
              value: getOptionVal.value
            }
          } if(i18n.language === 'caEn' && get(getOptionVal, 'canEnglishValue', '') !== '' ){
            return {
              label: getOptionVal.canEnglishValue || getOptionVal.label,
              value: getOptionVal.value
            }
          }
          return getOptionVal;
        };

        const value = Array.isArray(input.value)
          ? input.value.map(optionByValue)
          : optionByValue(input.value) || '';

        const components = isMulti ? { Option, ValueContainer } : {};

        return loadOptions ? (
          <AsyncSelect
            {...inputRest}
            {...input}
            backspaceRemoves={backspaceRemoves}
            checkIcon={checkIcon}
            className='react-select-classname'
            closeMenuOnSelect={!isMulti}
            components={components}
            defaultOptions
            formatGroupLabel={formatGroupLabel}
            hideSelectedOptions={false}
            isMulti={isMulti}
            loadOptions={async (query) => {
              let options = await loadOptions(query);

              if (externalAsyncOptions) {
                // after search add new options in state & remove duplicate
                // options from state.
                setExternalAsyncOptions((prevOptions) =>
                  uniqBy([...prevOptions, ...options], 'value')
                );
              } else {
                // changes made to support pagination api calls.
                setAsyncOptions((prevOptions) =>
                  uniqBy([...prevOptions, ...options], 'value')
                );
                // setAsyncOptions(options);
              }

              // check for subtext and display if exists
              options = options.map((option) => {
                let label;
              
                if (typeof option.subtext !== 'undefined') {
                  label = getOptionLabel(option);
                } else if (i18n.language === 'caEn' && get(option, 'canEnglishValue', '') !== '') {
                  label = option.canEnglishValue;
                } else if (i18n.language === 'fr' && get(option, 'frenchValue', '') !== '') {
                  label = option.frenchValue;
                } else {
                  label = option.label;
                }
              
                return {
                  label,
                  subtext: option.subtext,
                  subValues: option?.subValues,
                  value: option.value,
                };
              });

              return options;
            }}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            name
            onChange={onChange}
            placeholder={placeholder}
            styles={overrideStyles}
            uncheckIcon={uncheckIcon}
            value={value}
          />
        ) : (
          <Select
            {...inputRest}
            {...input}
            backspaceRemoves={backspaceRemoves}
            checkIcon={checkIcon}
            className='react-select-classname'
            closeMenuOnSelect={!isMulti}
            components={components}
            defaultValue={value}
            formatGroupLabel={formatGroupLabel}
            hideSelectedOptions={false}
            isMulti={isMulti}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            name
            onChange={onChange}
            options = {inputRest?.options?.map((item) => {
              let label;
              if (i18n.language === 'caEn' && isNotEmpty(get(item, 'canEnglishValue'))) {
                label = item.canEnglishValue;
              } else if (i18n.language === 'fr' && isNotEmpty(get(item, 'frenchValue'))) {
                label = item.frenchValue;
              } else {
                label = item.label;
              }
            
              return {
                label,
                value: item.value,
              };
            })}
            placeholder={placeholder}
            styles={overrideStyles}
            uncheckIcon={uncheckIcon}
            value={value}
          />
        );
      }}
    </InputWrapper>
  );
};

Dropdown.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,
};

export default Dropdown;
