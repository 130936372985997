import { useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import arrayMutators from 'final-form-arrays';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { composeValidations, required } from '@utils/validators';
import { labelHandler } from '@utils/label-utils';
import { commissionMatch, totalCommission } from '@utils/validations';
import { useTranslation } from 'react-i18next';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql';
import useSnackbar from '../../../../utilities/use-snackbar';
import InputMasked from '../../../BaseComponents/InputMasked';
import InputMonth from '../../../BaseComponents/InputMonth';
import ToolbarButton from '../../../BaseComponents/ToolbarButton';
import CommissionFieldsArray from '../../ProductAdminPage/Commissions/CommissionFieldsArray';
import CommissionsHistoryTable from '../../ProductAdminPage/Commissions/CommissionsHistoryTable';
import Route from '../../Route';
import Modal from '../../../BaseComponents/Modal';
import Spinner from '../../../BaseComponents/Spinner';
import GetData from '../../../../utilities/get-data';
import Card from '../../../BaseComponents/Card';
import config from '../../../../config.json';
import dateUtils from '../../../../utilities/date-utils';

/**
 * @category Group
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const UpsertCommissions = ({ location, ...rest }) => {
  const { t, i18n } = useTranslation()
  const parameters = useParams();
  const groupId = get(parameters, 'groupId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const [commissionData, setCommissionData] = useState({});
  const [deletedCommissionId, setDeletedCommissionData] = useState(null);
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const commissionsHistoryTableRef = useRef();
  const { apiData: group = {}, loading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }));
  const today =
    dateUtils.setOffset2(get(group, 'relativeDatetime')) || new Date();
  const resetDay = new Date(today.setDate(1));
  const initialMonth = new Date(resetDay.setMonth(resetDay.getMonth()));
  const state = get(group, 'locationDetails.address.state');
  if (loading) {
    return <Spinner />;
  }

  return (
    <Route
      header={{
        title: 'Edit Commissions',
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Edit Commissions
      </Box>
      <ApolloConsumer>
        {(client) => {
          const onClickCreateCommission = async (values) => {
            const effectiveDate = new Date(get(values, 'effectiveDate', ''));
            const expirationDate = new Date(get(values, 'expirationDate', ''));
            const totalCommissionPercentage = parseFloat(
              get(values, 'totalCommissionPercentage', '0')
            );
            const commissionConfigs = get(values, 'commissions', []).map(
              (item) => {
                const temp = {};
                temp.commissionPercentage = parseFloat(
                  get(item, 'commissionPercentage', '0')
                );
                temp.subProducerId = get(item, 'subProducer.subProducerId', '');
                return temp;
              }
            );

            const isUpdate = !isEmpty(commissionData);
            const params = {
              commissionConfigs,
              effectiveDate: dateUtils.setAPIDateOnly(effectiveDate),
              groupId,
              id: get(commissionData, 'groupCommissionHistoryId'),
              totalCommissionPercentage,
            };
            if (isUpdate) {
              params.expirationDate = dateUtils.setAPIDateOnly(expirationDate)
            }
            return client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: isUpdate
                    ? 'update-commission-history'
                    : 'create-commission',
                  params: JSON.stringify(params),
                },
              })
              .then(() => {
                commissionsHistoryTableRef.current.refetchCommissionsTableData();
                setCommissionData({});
                return setSucessSnack(
                  `Commission group ${!isEmpty(commissionData) ? 'updated' : 'created'
                  } successfully.`,
                  config.notificationDuration
                );
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                )
              );
          };

          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: '98vw',
                width: '100%',
              }}
            >
              <FinalForm
                initialValues={{
                  commissions: get(commissionData, 'commissionDTOList', [
                    {
                      producerDetail: {},
                      subProducer: {},
                    },
                  ]),
                  ...commissionData,
                  effectiveDate: dateUtils.setOffset2(
                    get(commissionData, 'effectiveDate')
                  ),
                }}
                mutators={arrayMutators}
                onSubmit={onClickCreateCommission}
                render={(formContext) => {
                  return (
                    <form onSubmit={formContext.handleSubmit}>
                      <Box
                        sx={{
                          alignItems: 'center',
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          padding: 3,
                        }}
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            mb: 6,
                          }}
                        >
                          <Field
                            aria-label="Effective Date"
                            component={InputMonth}
                            dateFormat={config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                            disabled={!isEmpty(commissionData)}
                            label={labelHandler('Effective Date', true)}
                            minDate={initialMonth}
                            name="effectiveDate"
                            validate={required}
                            view="month"
                            wrapperSx={{ ...commonFieldSxHalf }}
                          />
                          <Box
                            sx={{
                              color: 'gray.4',
                              display: 'block',
                              fontSize: 1,
                              fontWeight: 'bold',
                              letterSpacing: 0,
                              maxWidth: '25rem',
                              mb: 1,
                              textTransform: 'uppercase',
                            }}
                          >
                            Effective Date will be the 1st of the month
                            selected.
                          </Box>
                          <Box
                            sx={{
                              color: 'gray.4',
                              display: 'block',
                              fontSize: 1,
                              fontWeight: 'bold',
                              letterSpacing: 0,
                              lineHeight: '12px',
                              maxWidth: '25rem',
                              mb: 1,
                              textAlign: 'center',
                              textTransform: 'uppercase',
                            }}
                          >
                            Expiration Date will be the Coverage End Date unless
                            a new commission group is created in the interm, in
                            which case it would the last day of the month prior
                            to the selected effective month of the new Effective
                            Date.
                          </Box>
                          <Field
                            aria-label="Total Commission in %"
                            component={InputMasked}
                            label={labelHandler('Total Commission in %', true)}
                            mask={createNumberMask({
                              allowDecimal: true,
                              includeThousandsSeparator: false,
                              integerLimit: 2,
                              prefix: '',
                            })}
                            name="totalCommissionPercentage"
                            validate={composeValidations(
                              required,
                              totalCommission
                            )}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                        </Box>
                        <CommissionFieldsArray
                          client={client}
                          commissionsData={get(
                            formContext.values,
                            'commissions'
                          )}
                          name="commissions"
                          state={state}
                          validation={commissionMatch}
                        />
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            mt: 6,
                          }}
                        >
                          <ToolbarButton
                            bg="primaryDark"
                            isDisabled={formContext.submitting}
                            label={`${!isEmpty(commissionData) ? 'Update' : 'Create'
                              } Commission Group`}
                            mr={0}
                            submitting={formContext.submitting}
                            type="submit"
                            width="25rem"
                          />
                        </Box>
                      </Box>
                      {deletedCommissionId && (
                        <Modal
                          closeText={t('common.no')}
                          isOpen={deletedCommissionId !== null}
                          onClose={() => {
                            setDeletedCommissionData(null);
                          }}
                          onSubmit={() => {
                            client
                              .query({
                                fetchPolicy: 'no-cache',
                                query: remoteActionQuery,
                                variables: {
                                  name: 'delete-commission-history',
                                  params: JSON.stringify({
                                    id: deletedCommissionId,
                                  }),
                                },
                              })
                              .then(async () => {
                                setDeletedCommissionData(null);
                                commissionsHistoryTableRef.current.refetchCommissionsTableData();
                              })
                              .catch((e) => {
                                setErrorSnack(
                                  `There was an error: ${e.message}`,
                                  config.notificationDuration
                                );
                                setDeletedCommissionData(null);
                              });
                          }}
                          submitText={t('common.yes')}
                          title="Remove Commission Group"
                        >
                          <Box>
                            By clicking yes, you are confirming that the
                            Commission will be removed from your group plan.
                            <Box
                              sx={{
                                display: 'flex',
                                fontWeight: 'bold',
                                justifyContent: 'center',
                                my: 5,
                              }}
                            >
                              THIS ACTION CAN NOT BE UNDONE.
                            </Box>
                            Do you wish to continue?
                          </Box>
                        </Modal>
                      )}
                    </form>
                  );
                }}
              />
              <Box
                sx={{
                  display: 'grid',
                }}
              >
                <Card>
                  <CommissionsHistoryTable
                    ref={commissionsHistoryTableRef}
                    commissionData={commissionData}
                    currentDate={today}
                    editable
                    location={location}
                    setCommissionData={setCommissionData}
                    setDeletedCommissionData={setDeletedCommissionData}
                    tableType="commissionsDashboard"
                  />
                </Card>
              </Box>
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

UpsertCommissions.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default UpsertCommissions;
