import get from 'lodash/get';
import {useTranslation} from "react-i18next";
import GetData from '../../../../utilities/get-data';

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const AccountType = ({ value }) => {
  const { i18n } = useTranslation();
  const data = [
    GetData(
      'get-enum-options',
      JSON.stringify([{ params: 'BillingAccountType' }])
    ),
  ];
  const type = get(data, '0.apiData.BillingAccountType', []);
  const accountType = type.find((item) => item.value === value);
  // eslint-disable-next-line no-nested-ternary
  return accountType ? (i18n.language === 'fr' && get(accountType, 'frenchValue', '') !== '') ?  get(accountType, 'frenchValue'): get(accountType, 'label') : value;
};

export default AccountType;
