import { useParams } from '@reach/router';
import { AuthContext } from '@basecomponents/Auth';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import React, { useContext, useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { required } from '@utils/validators';
import { labelHandler } from '@utils/label-utils';
import remoteActionQuery from '@queries/remote-action.gql';
import GetData from '@utils/get-data';
import useSnackbar from '@utils/use-snackbar';
import dateUtils from '@utils/date-utils';
import ButtonGroups from '@basecomponents/ButtonGroups';
import Card from '@basecomponents/Card';
import InputDate from '@basecomponents/InputDate';
import ToolbarButton from '@basecomponents/ToolbarButton';
import config from '@src/config.json';

const minOrToday = (minDate) => {
  const today = new Date();
  const resetDay = new Date(today.setDate(today.getDate() + 1));
  if (minDate) {
    return dateUtils.setOffset2(minDate);
  }
  return resetDay;
};

/**
 * @category Group
 * @param {*} rest
 * @returns {React.FC}
 */
const TimeTraversal = ({ ...rest }) => {
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const parameters = useParams();
  const [loaderButtonDisabled, setLoaderButtonDisabled] = useState(
    Array.from(Array(22), () => false)
  );
  const groupId = get(parameters, 'groupId', '');
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const { apiData, loading, refetch } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );

  const { apiData: waitingEmployees } = GetData(
    'get-all-group-employees',
    JSON.stringify({
      groupId,
      status: 'ACTIVE',
    }),
    !groupId
  );

  const { apiData: pendingEmployees } = GetData(
    'get-all-group-employees',
    JSON.stringify({
      groupId,
      status: 'PENDING',
    }),
    !groupId
  );

  const incompleteEnrollments =
    waitingEmployees.totalElements + pendingEmployees.totalElements > 0;

  const relativeDatetime = dateUtils.setOffset2(
    get(apiData, 'relativeDatetime')
  );

  const groupInactive = !['ACTIVE', 'POLICY_EXPIRED'].includes(
    get(apiData, 'status')
  );

  const setLoader = (type, value) => {
    const loaderData = [...loaderButtonDisabled];
    loaderData[type] = value;
    setLoaderButtonDisabled(loaderData);
  };

  return (
    <ApolloConsumer>
      {(client) => {
        const apiCall = (pathName, params) =>
          client.query({
            fetchPolicy: 'no-cache',
            query: remoteActionQuery,
            variables: {
              name: pathName,
              params: JSON.stringify(params),
            },
          });
        const onNextClick = async (values) => {
          const { relativeDatetime } = values;
          if (relativeDatetime) {
            return apiCall('update-group', {
              groupId,
              relativeDatetime: dateUtils.setAPIDateOnly(relativeDatetime),
              userId,
            })
              .then(() => {
                refetch();
                setSucessSnack(
                  'Group Updated successfully!',
                  config.notificationDuration
                );
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                )
              );
          }
        };
        const groupEnrollJob = async (type, apiUrl, extraParams = {}) => {
          setLoader(type, true);
          return apiCall(apiUrl, {
            groupId,
            newBillingApiVersionEnable: config.newBillingApiVersionEnable,
            relativeDate: dateUtils.setAPIDateOnly(relativeDatetime),
            userId,
            ...extraParams,
          })
            .then((response) => {
              const remoteAction = JSON.parse(
                get(response, 'data.remoteAction.data', [])
              );
              setLoader(type, false);
              if (remoteAction.length === 0) {
                return setErrorSnack(
                  'There was an error',
                  config.notificationDuration
                );
              }
              setSucessSnack(
                'Job Updated successfully!',
                config.notificationDuration
              );
            })
            .catch((e) => {
              setLoader(type, false);
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              );
            });
        };
        return (
          <Card loading={loading}>
            <Box
              sx={{
                color: 'primary',
                fontSize: 3,
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              Time Traversal
            </Box>
            <FinalForm
              initialValues={{
                relativeDatetime,
              }}
              onSubmit={onNextClick}
              render={({ submitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}
                  >
                    <Field
                      component={InputDate}
                      label={labelHandler('Select Date', true)}
                      minDate={minOrToday(relativeDatetime)}
                      name="relativeDatetime"
                      validate={required}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexBasis: ['100%', null, null, '0'],
                      }}
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={submitting || groupInactive}
                      label="Set Date"
                      submitting={submitting}
                      sx={{
                        mb: [3, null, null, 0],
                        mt: [3, null, null, 5],
                        width: ['auto', null, null, '150px'],
                      }}
                      type="submit"
                    />
                  </Box>

                  <ButtonGroups mt={6} wrap="wrap">
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[0] ||
                        !relativeDatetime ||
                        groupInactive
                      }
                      label="Run Group Enroll Job"
                      onClick={() => groupEnrollJob(0, 'get-enrollment-job')}
                      submitting={loaderButtonDisabled[0]}
                      type="submit"
                    />
                    {/* <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[1] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Run Employee Payment Job"
                      onClick={() =>
                        groupEnrollJob(1, 'employee-payment-record-job')
                      }
                      submitting={loaderButtonDisabled[1]}
                      type="submit"
                    /> */}
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[2] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Generate Bill"
                      onClick={() => groupEnrollJob(2, 'generate-bill-job')}
                      submitting={loaderButtonDisabled[2]}
                      type="submit"
                    />
                    {/* <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[3] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Run Group Payment Job"
                      onClick={() =>
                        groupEnrollJob(3, 'group-payment-record-job')
                      }
                      submitting={loaderButtonDisabled[3]}
                      type="submit"
                    /> */}
                    {/* <ToolbarButton */}
                    {/*  bg="accentDark" */}
                    {/*  isDisabled={ */}
                    {/*    loaderButtonDisabled[5] || */}
                    {/*    !relativeDatetime || */}
                    {/*    groupInactive || */}
                    {/*    incompleteEnrollments */}
                    {/*  } */}
                    {/*  label=" Run Billing Integrator Update Job" */}
                    {/*  onClick={() => */}
                    {/*    groupEnrollJob(5, 'billing-integrator-update') */}
                    {/*  } */}
                    {/*  submitting={loaderButtonDisabled[5]} */}
                    {/*  type="submit" */}
                    {/* /> */}
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[4] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label=" Run Group Commission Job"
                      onClick={() =>
                        groupEnrollJob(4, 'group-commission-record-job')
                      }
                      submitting={loaderButtonDisabled[4]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[6] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Run Cognito User Termination Job"
                      onClick={() =>
                        groupEnrollJob(6, 'cognito-user-termination')
                      }
                      submitting={loaderButtonDisabled[6]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[7] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Run Commission Integrator Job"
                      onClick={() => groupEnrollJob(7, 'commission-integrator')}
                      submitting={loaderButtonDisabled[7]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[8] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Run Commission Integrator By Producer Job"
                      onClick={() =>
                        groupEnrollJob(8, 'commission-integrator-by-producer')
                      }
                      submitting={loaderButtonDisabled[8]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[13] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Run Cash Premium Job"
                      onClick={() => groupEnrollJob(13, 'cash-premium-job')}
                      submitting={loaderButtonDisabled[13]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[14] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Run Employee Aging Report Job"
                      onClick={() =>
                        groupEnrollJob(14, 'employee-aging-report-job')
                      }
                      submitting={loaderButtonDisabled[14]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[22] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Run Group Aging Report Job"
                      onClick={() =>
                        groupEnrollJob(22, 'group-aging-report-job')
                      }
                      submitting={loaderButtonDisabled[22]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[9] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Run Pet Cash Premium Report Job"
                      onClick={() => groupEnrollJob(9, 'pet-cash-premium-job')}
                      submitting={loaderButtonDisabled[9]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[21] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Run Employee Cash Premium Report Job"
                      onClick={() =>
                        groupEnrollJob(21, 'employee-cash-premium-job')
                      }
                      submitting={loaderButtonDisabled[21]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[10] ||
                        !relativeDatetime ||
                        groupInactive
                      }
                      label="Run Group Renewal Activation Job"
                      onClick={() =>
                        groupEnrollJob(10, 'group-renewal-activation-job')
                      }
                      submitting={loaderButtonDisabled[10]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[15] ||
                        !relativeDatetime ||
                        groupInactive
                      }
                      label="Run Group Policy Expiration Job"
                      onClick={() =>
                        groupEnrollJob(15, 'group-policy-expiration')
                      }
                      submitting={loaderButtonDisabled[15]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[11] ||
                        !relativeDatetime ||
                        groupInactive
                      }
                      label="Run Group Termination Job"
                      onClick={() =>
                        groupEnrollJob(11, 'group-termination-job')
                      }
                      submitting={loaderButtonDisabled[11]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[16] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label="Run Enrollment Start Notification Job"
                      onClick={() =>
                        groupEnrollJob(16, 'enrollment-start-notification-job')
                      }
                      submitting={loaderButtonDisabled[16]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[17] ||
                        !relativeDatetime ||
                        groupInactive
                      }
                      label="Run Decline QLE By Group Job"
                      onClick={() =>
                        groupEnrollJob(17, 'decline-qle-by-group-job')
                      }
                      submitting={loaderButtonDisabled[17]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[18] ||
                        !relativeDatetime ||
                        groupInactive
                      }
                      label="Run Outstanding Balance Notification Job"
                      onClick={() =>
                        groupEnrollJob(
                          18,
                          'outstanding-balance-notification-job'
                        )
                      }
                      submitting={loaderButtonDisabled[18]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[19] ||
                        !relativeDatetime ||
                        groupInactive
                      }
                      label="Run Employee Dependent Migration Job"
                      onClick={() =>
                        groupEnrollJob(19, 'employee-dependent-migration-job', {
                          helper: false,
                        })
                      }
                      submitting={loaderButtonDisabled[19]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[20] ||
                        !relativeDatetime ||
                        groupInactive
                      }
                      label="Run Ben Admin Change File Job"
                      onClick={() =>
                        groupEnrollJob(20, 'ben-admin-change-file-job')
                      }
                      submitting={loaderButtonDisabled[20]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[23] ||
                        !relativeDatetime ||
                        groupInactive
                      }
                      label="Run Passive Enrollment Job"
                      onClick={() =>
                        groupEnrollJob(23, 'auto-enrollment-by-group', {
                          considerParentPetsOnly: true,
                        })
                      }
                      submitting={loaderButtonDisabled[23]}
                      type="submit"
                    />
                    <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[24] ||
                        !relativeDatetime ||
                        groupInactive
                      }
                      label="V2 Enroll Job"
                      onClick={() =>
                        groupEnrollJob(24, 'get-enrollment-v2-job')
                      }
                      submitting={loaderButtonDisabled[24]}
                      type="submit"
                      visible={config?.environment === 'dev3'}
                    />
                    {/* <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[9] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label=" Run Commission Integrator By Group Job"
                      onClick={() =>
                        groupEnrollJob(9, 'commission-report-by-group')
                      }
                      submitting={loaderButtonDisabled[9]}
                      type="submit"
                    /> */}
                    {/* <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[10] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label=" Run Commission Report By Producer Job"
                      onClick={() =>
                        groupEnrollJob(10, 'commission-report-by-producer')
                      }
                      submitting={loaderButtonDisabled[10]}
                      type="submit"
                    /> */}
                    {/* <ToolbarButton
                      bg="accentDark"
                      isDisabled={
                        loaderButtonDisabled[11] ||
                        !relativeDatetime ||
                        groupInactive ||
                        incompleteEnrollments
                      }
                      label=" Run Employee non-Payment Job"
                      onClick={() => groupEnrollJob(11, 'employee-non-payment')}
                      submitting={loaderButtonDisabled[11]}
                      type="submit"
                    /> */}
                  </ButtonGroups>
                </form>
              )}
            />
          </Card>
        );
      }}
    </ApolloConsumer>
  );
};

export default TimeTraversal;
