import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { get, keys } from 'lodash';
import { useTranslation } from 'react-i18next';
import ToolbarButton from '../../../../BaseComponents/ToolbarButton';
import localStorage from '../../../../../utilities/local-storage';
import { FORM_DEBUG } from '../../utils/constants';

/**
 * @category Components
 * @param {Function} dispatch Dispatches an action based on user events
 * @param {Function} handleSubmit Function to submit the form
 * @param {string} modified
 * @param {object} sectionOrder
 * @param {object} sectionOperations
 * @param {object} stateValues
 * @param {boolean} submitting Indicates whether the form is submitting
 * @param {string} submitError Indicates the error occurred while submitting
 * @param {object} values
 * @returns {React.FC}
 */
const Toolbar = ({
  dispatch,
  handleSubmit,
  modified,
  sectionOrder,
  sectionOperations,
  stateValues,
  showSubmitting,
  submitting,
  submitError,
  values,
}) => {
  const { t } = useTranslation();
  const lsDebug = localStorage.getItem(FORM_DEBUG) || '';
  const debugMode = lsDebug.split(',');
  const { canSkip } = sectionOperations;
  const sectionValues = { currentValues: values, stateValues };
  const bShowSkip = canSkip ? canSkip(sectionValues) : true;
  const formModified = keys(modified).reduce((res, key) => {
    return res || get(modified, key, false);
  }, false);
  return (
    <>
      <Box display="flex">
        {debugMode.includes('modified') && (
          <Box as="pre" p={4}>{`modified: ${JSON.stringify(
            modified,
            null,
            2
          )}`}</Box>
        )}
        {debugMode.includes('values') && (
          <Box as="pre" p={4}>{`values: ${JSON.stringify(
            values,
            null,
            2
          )}`}</Box>
        )}
        {debugMode.includes('stateValues') && (
          <Box as="pre" p={4}>{`stateValues: ${JSON.stringify(
            stateValues,
            null,
            2
          )}`}</Box>
        )}
      </Box>
      <Box color="error" p={4}>
        {submitError}
      </Box>
      <Box alignItems="center" display="flex" p={4}>
        {sectionOrder !== 'first' && (
          <ToolbarButton
            isLoading={submitting}
            label={t('common.previous')}
            onClick={() => dispatch({ type: 'onPrev' })}
          />
        )}
        {showSubmitting && (<ToolbarButton
          disabled={!formModified}
          icon=""
          isLoading={submitting}
          label="Save & Next"
          onClick={() => handleSubmit()}
        />)}
        {bShowSkip && (
          <ToolbarButton
            bg={formModified ? 'accentSecondary' : 'primary'}
            isLoading={submitting}
            label={t('common.next')}
            onClick={() => dispatch({ type: 'onNext' })}
          />
        )}
      </Box>
    </>
  );
};

Toolbar.defaultProps = {
  sectionOperations: {
    canSkip: () => true,
  },
  showSubmitting:false,
  submitError: '',
};

Toolbar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modified: PropTypes.shape({}).isRequired,
  sectionOperations: PropTypes.func,
  sectionOrder: PropTypes.string.isRequired,
  showSubmitting: PropTypes.bool,
  stateValues: PropTypes.shape({}).isRequired,
  submitError: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({}).isRequired,
};

export default Toolbar;
