import React from 'react';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import ModuleContainer from '../Container';
import dateUtils from '../../../../../utilities/date-utils';
import GetData from '../../../../../utilities/get-data';
import {getNotificationDays} from '../../../../../utilities/get-notifcation-days';
import {CA_PROVINCES, notificationTypes, settingLevel, US_STATES,} from '../../../../../utilities/constants';
import {TOOLTIP} from '../../../../BaseComponents/ListGrid/utils/constants';
import config from '../../../../../config.json';

function calculateColor(difference, {renewalNotificationDays}) {
    if (difference < 0) return 'lightgray';

    if (difference >= 0 && difference < 0.25 * renewalNotificationDays) {
        return 'yellow';
    }

    if (
        difference >= 0.25 * renewalNotificationDays &&
        difference < 0.75 * renewalNotificationDays
    ) {
        return 'orange';
    }

    if (
        difference >= 0.75 * renewalNotificationDays &&
        difference < renewalNotificationDays
    ) {
        return 'red';
    }

    return 'black';
}

/**
 *
 * @category Components
 * @param {Object} data
 * @returns {React.FC}
 */
const GroupNotificationStatus = ({data}) => {
    const {apiData: globalSettings = {}} = GetData(
        'get-all-system-settings',
        JSON.stringify({settingLevel: settingLevel.global})
    );

    const {apiData: stateSettings = {}} = GetData(
        'get-all-system-settings',
        JSON.stringify({
            settingLevel: settingLevel.state,
            size: config.canadaEnv ? CA_PROVINCES.length : US_STATES.length
        })
    );

    const {apiData: groupSettings = {}} = GetData(
        'get-all-system-settings',
        JSON.stringify({
            identifier: get(data, 'groupId'),
            settingLevel: settingLevel.group,
            settingType: notificationTypes.adminNotifications,
        })
    );

    const {apiData: constantsData = {}} = GetData(
        'get-enum-options',
        JSON.stringify([{params: 'GroupStatus '}])
    );

    const differenceInDaysFromToday = dateUtils?.getDateDifferenceInDays(
        getNotificationDays({
            apiData: {globalSettings, groupSettings, stateSettings},
            meta: {
                groupId: get(data, 'groupId'),
                groupState: get(data, 'locationDetails.address.state'),
            },
        }),
        get(data, 'plan.planChoices[0].coverageToDate'),
        config.developerMode
            ? get(data, 'relativeDatetime', new Date())
            : new Date()
    );

    const handleCalculateColor = () => {
        return calculateColor(
            differenceInDaysFromToday,
            getNotificationDays({
                apiData: {globalSettings, groupSettings, stateSettings},
                meta: {
                    groupId: get(data, 'groupId'),
                    groupState: get(data, 'locationDetails.address.state'),
                },
            })
        );
    };

    const isGroupRenewed = get(data, 'renewalStatus');

    const isGroupTerminatedOrExpired = get(data, 'status');

    const returnConstantsBasedOnType = (type) => {
        const {value = {}} =
        constantsData?.GroupStatus?.find(({value}) => value === type) ?? {};
        return value;
    };

    const displayValue = (
        <Box sx={{display: 'flex'}}>
            <Box
                data-for={TOOLTIP}
                data-tip={`${-differenceInDaysFromToday} days left for renewal`}
            >
                <Box
                    sx={{
                        background: calculateColor(
                            differenceInDaysFromToday,
                            getNotificationDays({
                                apiData: {globalSettings, groupSettings, stateSettings},
                                meta: {
                                    groupId: get(data, 'groupId'),
                                    groupState: get(data, 'locationDetails.address.state'),
                                },
                            })
                        ),
                        borderRadius: '5px',
                        boxShadow: `0px 0px 5px ${handleCalculateColor()}`,
                        display:
                            isGroupTerminatedOrExpired ===
                            returnConstantsBasedOnType('EXPIRED') ||
                            isGroupTerminatedOrExpired ===
                            returnConstantsBasedOnType('TERMINATED') ||
                            isGroupRenewed === 'RENEWED'
                                ? 'none'
                                : 'inline-block',
                        height: '10px',
                        mx: '5px',
                        width: '10px',
                    }}
                />
            </Box>
            <Box sx={{ml: 2}}>{get(data, 'groupName') || get(data, 'groupNote.groupName') || 'NA'}</Box>
        </Box>
    );
    return <ModuleContainer displayValue={displayValue}/>;
};
GroupNotificationStatus.propTypes = {
    data: PropTypes.shape({}).isRequired,
};

export default GroupNotificationStatus;
