import PropTypes from 'prop-types';
import React, {useContext, useEffect} from 'react';
import {navigate, useParams} from '@reach/router';
import { get } from 'lodash';
import Box from '@basecomponents/Box';
import { AuthContext } from '@basecomponents/Auth';
import {
  // DEFAULT_QLE_CERTIFICATE_LINK,
  USER_ROLE,
} from '@utils/constants';
// import Modal from '../../../BaseComponents/Modal';
import { useTranslation } from 'react-i18next';
import Route from '../../Route';
import PolicyHolderInfo from './PolicyHolderInfo';
import EmploymentInfo from './EmploymentInfo';
import PetInfo from './PetInfo';
import CoverageTab from './CoverageTab';
import EnrollmentSummary from './EnrollmentSummary';
import GetData from '../../../../utilities/get-data';
import Spinner from '../../../BaseComponents/Spinner';
import ProgressBar from '../../../BaseComponents/ProgressBar';
import dateUtils from '../../../../utilities/date-utils';

/**
 * @category Employee
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const ParticipantAdmin = ({ location, ...rest }) => {
  const { t } = useTranslation()
  const name = 'step';
  const queryParams = useParams();
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const employeeId = get(queryParams, 'employeeId', '');
  const isEditing = get(location, 'pathname', '').includes(
    '/group-employees/edit/'
  );
  // const isRenewals = get(location, 'pathname', '').includes('/renewalenroll');
  const isPetInfo = get(location, 'pathname', '').includes('/petInfo');
  const { apiData: employmentInfo, loading } = GetData(
    'get-employment-info',
    JSON.stringify({
      employeeId,
    })
  );
  
  useEffect(()=>{
    if( [
      'ENROLLED',
      'TERMINATED',
      'POLICY_EXPIRED',
      'ACTIVE',
      'PENDING',
      'FAILED_TO_ENROLL',
    ].includes(get(employmentInfo, '[0].employees.status', '')) && !isEditing && !loading){
       if( [
         USER_ROLE.EMPLOYEE,
         USER_ROLE.TERMINATED_EMPLOYEE,
         USER_ROLE.RENEWAL_POLICY_DELETED,
       ].includes(role)){
         
          navigate(`/group-employees/defaultEnrollee`,{ replace: true });
       }
          navigate(`/group-employees/${employeeId}/details`,{ replace: true });

    }
    
  },[loading])
  const employeeDetails = get(employmentInfo, '[0].employees', {});
  const currentDate =
    get(employeeDetails, 'relativeDatetime') === null
      ? dateUtils.getLocaleDateOnly(new Date())
      : dateUtils.getLocaleDateOnly(get(employeeDetails, 'relativeDatetime'));
  const enrolmentEndDate = dateUtils.getUTCDateOnly(
    get(employeeDetails, 'employeeDates.enrollmentEndDate')
  );
  // Prod #441 - Compare date values as dates and not strings
  const isLate = Date.parse(enrolmentEndDate) < Date.parse(currentDate);

  const displaySlides = () => {
    if (isEditing) {
      if (
        [
          USER_ROLE.EMPLOYEE,
          USER_ROLE.TERMINATED_EMPLOYEE,
          USER_ROLE.RENEWAL_POLICY_DELETED,
        ].includes(role)
      ) {
        return [t('groupEmployees.enrollmentPage.employeeInfo')];
      }
      return [t('groupEmployees.enrollmentPage.employeeInfo'), t('groupEmployees.enrollmentPage.employmentInfo')];
    }
    if (isPetInfo) {
      return [t('groupEmployees.enrollmentPage.petInformation')];
    }
    return [t('groupEmployees.enrollmentPage.petInformation'), 'Coverage', 'Enrollment Summary'];
  };
  // const [showModal, setShowModal] = useState(true);
  const { isResponsive } = rest;
  const renderSlides = () => {
    if (isEditing) {
      if (
        [
          USER_ROLE.EMPLOYEE,
          USER_ROLE.TERMINATED_EMPLOYEE,
          USER_ROLE.RENEWAL_POLICY_DELETED,
        ].includes(role)
      ) {
        return [
          {
            render: () => <PolicyHolderInfo location={location} {...rest} />,
            value: '0',
          },
        ];
      }
      return [
        {
          render: () => <PolicyHolderInfo location={location} {...rest} />,
          value: '0',
        },
        {
          render: () => <EmploymentInfo location={location} {...rest} />,
          value: '1',
        },
      ];
    }
    if (isPetInfo) {
      return [
        {
          render: () => <PetInfo location={location} {...rest} />,
          value: '0',
        },
      ];
    }
    return [
      {
        render: () => <PetInfo location={location} {...rest} />,
        value: '0',
      },
      {
        render: () => (
          <CoverageTab
            isResponsive={isResponsive}
            location={location}
            {...rest}
          />
        ),
        value: '1',
      },
      {
        render: () => <EnrollmentSummary location={location} {...rest} />,
        value: '2',
      },
    ];
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <Route
      header={{
        title: isEditing ? t('groupEmployees.enrollmentPage.editEmployee') : t('groupEmployees.enrollmentPage.enrollmentForm'),
        type: 'group-employees',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {isEditing ? t('groupEmployees.enrollmentPage.editEmployee') : t('groupEmployees.enrollmentPage.enrollmentForm')}
      </Box>
      {isLate && !isEditing && (
        <Box
          sx={{
            color: 'error',
            mb: 6,
            textAlign: 'center',
          }}
        >
        {t('groupEmployees.enrollmentPage.openEnrollmentNote')}
        </Box>
      )}
      <ProgressBar
        defaultTab="0"
        loading={false}
        name={name}
        slides={displaySlides()}
        tabs={renderSlides()}
      />
    </Route>
  );
};

ParticipantAdmin.propTypes = {
  isResponsive: PropTypes.bool.isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default ParticipantAdmin;
