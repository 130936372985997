import Box from '@basecomponents/Box';
import React, { useContext, useState, /* useRef */ } from 'react';
import { get, isArray, isEmpty } from 'lodash';
import arrayMutators from 'final-form-arrays';
import { Field, Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { navigate, useParams } from '@reach/router';
import { useApolloClient } from '@apollo/client';
import { AuthContext } from '@basecomponents/Auth';
import BlockDemographicClaimModal from '@petcomponents/BlockDemographicClaim';
import dateUtils from "@utils/date-utils";
import { useTranslation } from 'react-i18next';
import { required } from '../../../../utilities/validators';
import InputText from '../../../BaseComponents/Input';
import InputDropdown from '../../../BaseComponents/Dropdown';
import { labelHandler } from '../../../../utilities/label-utils';
import ToolbarButton from '../../../BaseComponents/ToolbarButton';
import MedicalHistory from './MedicalHistory';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql';
import {
  ACCEPTANCE_CODE,
  CLAIM_STATUS,
  USER_ROLE,
} from '../../../../utilities/constants';
import GetData from '../../../../utilities/get-data';
import Spinner from '../../../BaseComponents/Spinner';
import useSnackbar from '../../../../utilities/use-snackbar';
import Route from '../../Route';
import UploadDocumentPage from './UploadDocument';
import BankingPageInfo from './BankingInfo';
import gaEvent from '../../../../utilities/gaEvent';
import config from '../../../../config.json';

/**
 * @category Claims
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

function ClaimPageInfo({ ...rest }) {
  const start = Date.now();
  const { t } = useTranslation();
  const title = t('claimsScreen.upsertClaimPage.createClaim');
  const queryParams = useParams();
  const groupId = get(queryParams, 'groupId', '');
  const employeeId = get(queryParams, 'employeeId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [showModal, setShowModal] = useState(null);
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const role = get(user, 'customRole', '');
  const benAdminId = get(user, 'customSystemBenadminId', '');
  const benBrokerId = get(user, 'customSystemBrokerId');
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const queryParam = employeeId
    ? { employeeId }
    : { skipActiveCheck: true, userId };
  const { apiData: employer = {}, employerLoading } = GetData(
    'get-all-administrator-by-group-id',
    JSON.stringify({ ...queryParam, sort: 'created,desc' }),
    role !== USER_ROLE.EMPLOYER,
    'cache-first'
  );
  const { content: employerData = [] } = employer;
  const activeEmployerData = employerData.find(
    (item) => item.groupActive === true
  );
  const employerGroupId = activeEmployerData && isEmpty(activeEmployerData)
    ? get(employerData, '0.groupId', '')
    : get(activeEmployerData, 'groupId', '');
  const employerGroupRelativeDatetime = activeEmployerData && isEmpty(activeEmployerData)
    ? get(employerData, '0.relativeDatetime', '')
    : get(activeEmployerData, 'relativeDatetime', '');
  const { apiData: employee = {}, loading } = GetData(
    'get-all-employee',
    JSON.stringify({ ...queryParam }),
    ![
      USER_ROLE.EMPLOYEE,
      USER_ROLE.EMPLOYER,
      USER_ROLE.TERMINATED_EMPLOYEE,
      USER_ROLE.RENEWAL_POLICY_DELETED,
    ].includes(role),
    'cache-first'
  );
  const { content: userData = [] } = employee;
  const activeUser = userData.filter((v) => v.currentGroup.isActiveGroup);
  const inActiveData = userData.filter(
    (item) => get(item, 'user.inactiveDate') !== null &&
      new Date(get(item, 'user.inactiveDate')) > new Date()
  );
  const empData = activeUser.length > 0 ? activeUser : inActiveData;

  const claimsInfoResponse = {
    certificateNumber: get(
      empData,
      '0.employeePlanConfirmation.confirmationNumber',
      ''
    ),
    createdBy: userId,
    dependentClaims: [
      {
        dependentVisits: [{}],
      },
    ],
    employeeClaims: get(empData, '0.status', '') === 'ENROLLED' ? 'false' : 'true',
    employeeData: get(empData, '0', []),
    employeeId: [
      USER_ROLE.EMPLOYEE,
      USER_ROLE.TERMINATED_EMPLOYEE,
      USER_ROLE.RENEWAL_POLICY_DELETED,
    ].includes(role) || get(empData, '0.status', '') === 'ENROLLED'
      ? get(empData, '0.rootEmployeeId') ?? get(empData, '0.employeeId', '')
      : null,
    groupId: get(empData, '0.groupId', groupId || employerGroupId || ''),
    relativeDatetime: get(
      empData,
      '0.relativeDatetime',
      employerGroupRelativeDatetime
    ),
    selectedPlanChoiceId: get(empData, '0.planChoiceEmployees', []).filter((item) => item.acceptGroupPlanChoice)[0]?.planChoiceId,
    selectedPlanId: get(empData, '0.employeePlanConfirmation.planId'),
  };

  const client = useApolloClient()
  const apiCall = (pathName, params) => client.query({
    fetchPolicy: 'no-cache',
    query: remoteActionQuery,
    variables: {
      name: pathName,
      params: JSON.stringify(params),
    },
  });
  const onClickAction = async (values) => {
    async function updateReimbursementInfo() {
      if (get(values, 'acceptableMethods', '') === 'ach') {
        const reimbursementData = {
          accountNumber: get(values, 'accountNumber', ''),
          accountTypeId: get(values, 'accountType', ''),
          bankName: get(values, 'bankName', ''),
          employeeGeneratedNumber: get(values, 'employeeId', ''),
          institutionNumber: config.canadaEnv ? get(values, 'institutionNumber', '') : null,
          routingNumber: config.canadaEnv ? null : get(values, 'routingNumber', ''),
          transitNumber: config.canadaEnv ? get(values, 'transitNumber', '') : null,
        };
        const response = await apiCall('create-claims​-reimbursement', reimbursementData).then(async (response) => {
          const reimbursement = await JSON.parse(
            get(response, 'data.remoteAction.data', '{}')
          );
          if (get(reimbursement, 'name') === 'Error') {
            throw new Error(get(reimbursement, 'message'));
          }
        }).catch(async (e) => {
          setErrorSnack(
            t('snack.error.errorWithMessage', { message: e.message }),
            config.notificationDuration);
          return { e, failed: true };
        }
        );
        if (response?.failed) {
          return navigate('/claims/dashboard');
        }
        // eslint-disable-next-line no-irregular-whitespace
        // apiCall('create-claims​-reimbursement', reimbursementData)
        //   .then(() => updateClaimInfo());
      }
    }
    function updateClaimInfo() {
      const uploadDocuments = [];
      let fileName = '';
      if (values?.claimDocs?.length) {
        values.claimDocs.map((newFile) => {
          fileName = newFile.s3KeyName.split('/');
          return uploadDocuments.push({
            fileName: fileName[fileName.length - 1],
            filePath: newFile.s3KeyName,
          });
        });
      }
      const dependentClaims = get(values, 'dependentClaims', []).map((dependent) => {
        const { dependentVisits = [], ...rest } = dependent;
        const treatmentData = dependentVisits.map(o => {
          return {
            diagnosis: o.diagnosis,
            onsetDate: dateUtils.setAPIDateOnly(o.onsetDate),
            treatmentStartDate: dateUtils.setAPIDateOnly(o.treatmentStartDate),
          }
        })
        return {
          dependentVisits: treatmentData,
          ...rest,
        }
      })
      const params = {
        certificateNumber: values.certificateNumber,
        claimDocs: uploadDocuments,
        createdBy: userId,
        dependentClaims,
        employeeId: values.employeeId,
        status: CLAIM_STATUS.OPEN,
      };
      return apiCall('update-claims-info', params)
        .then((response) => {
          const createClaimResponse = JSON.parse(
            get(response, 'data.remoteAction.data', '{}')
          );
          const claimId = get(createClaimResponse, 'claimId');
          if (claimId) {
            navigate('/claims/dashboard', {
              replace: true,
            });
          } else {
            setErrorSnack(
              t('snack.error.applyClaim'),
              config.notificationDuration
            );
          }
        })
        .catch((e) => setErrorSnack(
          t('snack.error.errorWithMessage', { message: e.message }),
          config.notificationDuration
        )
        );
    }
    gaEvent('Claims', 'Claim Submitted', role, start);
    const updateDependentData = get(values, 'dependentClaims', []).find((dependent) => dependent?.changePriorCoverageCredit === true)
    if (updateDependentData && updateDependentData?.dependentVisits?.length > 0) {
      const employeeDependentDTOList = get(values, 'dependentClaims', []).map((dependent) => {
        return {
          "employeeDependentId": dependent?.employeeDependentId,
          "employeeDependentMap": {
            "priorCoverageCredit": dependent?.priorCoverageCredit
          }
        }
      })
      const updateData = {
        employeeDependentDTOList,
        userId
      }
      const updateApiResp = await apiCall('update-employee-dependent-batch', updateData).then(async () => {
        await updateReimbursementInfo();
        return updateClaimInfo()
      }).catch(async (e) => {
        setErrorSnack(
          t('snack.error.errorWithMessage', { message: e.message }),
          config.notificationDuration);
        return { e, failed: true };
      }
      );
      if (updateApiResp?.failed) {
        return navigate('/claims/dashboard');
      };
    } else {
      await updateReimbursementInfo();
      return updateClaimInfo();
    }
  };

  if (loading || employerLoading) {
    return <Spinner />;
  }
  return (
    <Route
      header={{
        icon: 'claims',
        title,
        type: 'claims',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {title}
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <FinalForm
          key="claimInformationForm"
          initialValues={{
            ...claimsInfoResponse,
          }}
          mutators={arrayMutators}
          onSubmit={async (formValues) => {
            await onClickAction(formValues);
          }}
          render={({ handleSubmit, form, submitting, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    alignItems: 'center',
                    bg: 'white',
                    borderRadius: 4,
                    boxShadow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    padding: 3,
                  }}
                >
                  {(role === USER_ROLE.ADMIN ||
                    role === USER_ROLE.BEN_ADMIN ||
                    role === USER_ROLE.BEN_BROKER ||
                    role === USER_ROLE.SUPER_ADMIN) && (
                      <Field
                        aria-label={t('claimsScreen.upsertClaimPage.selectGroup')}
                        component={InputDropdown}
                        data-cy="groupId"
                        isClearable
                        label={labelHandler(t('claimsScreen.upsertClaimPage.selectGroup'), true)}
                        loadOptions={async (data) => {
                          const options = await client
                            .query({
                              fetchPolicy: 'no-cache',
                              query: remoteActionQuery,
                              variables: {
                                name: 'get-all-groups',
                                params: JSON.stringify({
                                  benAdminId,
                                  benBrokerId,
                                  groupMga: config.canadaEnv ? 'PTZ_SODACO' : 'PPI',
                                  groupName: data,
                                  id: values.groupId,
                                  size: 5,
                                  sort: 'created,desc',
                                  status: 'ACTIVE',
                                }),
                              },
                            })
                            .then((response) => {
                              const { content } = JSON.parse(
                                get(response, 'data.remoteAction.data', {})
                              );
                              const temp = content.map((item) => {
                                return {
                                  label: `${item.groupName}(${item.uniqueIdentifier})`,
                                  value: item.groupId,
                                };
                              });
                              return temp;
                            });
                          return options;
                        }}
                        name="groupId"
                        onChange={() => {
                          form.change('employeeId', '');
                        }}
                        searchable
                        validate={required}
                        wrapperSx={commonFieldSxHalf}
                        {...rest} />
                    )}
                  {role === USER_ROLE.EMPLOYER && (
                    <Field
                      aria-label={t('claimsScreen.upsertClaimPage.applyClaims')}
                      component={InputDropdown}
                      disabled={get(empData, '0.status', '') !== 'ENROLLED'}
                      label={labelHandler(
                        t('claimsScreen.upsertClaimPage.applyClaims'),
                        true
                      )}
                      name="employeeClaims"
                      onChange={(values) => {
                        if (values === 'false') {
                          form.change(
                            'employeeData',
                            get(empData, '0', [])
                          );
                          form.change(
                            'employeeId',
                            get(empData, '0.rootEmployeeId') ??
                            get(empData, '0.employeeId', '')
                          );
                          form.change(
                            'certificateNumber',
                            get(
                              empData,
                              '0.employeePlanConfirmation.confirmationNumber',
                              ''
                            )
                          );
                        } else {
                          form.change('employeeData', []);
                          form.change('employeeId', '');
                          form.change('certificateNumber', '');
                        }
                      }}
                      options={ACCEPTANCE_CODE}
                      searchable={false}
                      wrapperSx={commonFieldSxHalf}
                      {...rest} />
                  )}
                  {(((role === USER_ROLE.ADMIN ||
                    role === USER_ROLE.BEN_ADMIN ||
                    role === USER_ROLE.BEN_BROKER ||
                    role === USER_ROLE.SUPER_ADMIN) &&
                    values.groupId) ||
                    (role === USER_ROLE.EMPLOYER &&
                      values.employeeClaims === 'true')) && (
                      <Field
                        key={values.groupId}
                        aria-label={t('claimsScreen.upsertClaimPage.selectEmployee')}
                        component={InputDropdown}
                        isClearable
                        label={labelHandler(t('claimsScreen.upsertClaimPage.selectEmployee'), true)}
                        loadOptions={async (data) => {
                          const userData = data.split(' ');
                          const options = await client
                            .query({
                              fetchPolicy: 'no-cache',
                              query: remoteActionQuery,
                              skip: !values.groupId,
                              variables: {
                                name: 'get-group-employees',
                                params: JSON.stringify({
                                  employeeId,
                                  firstName: userData[0],
                                  groupId: values.groupId,
                                  lastName: userData[1],
                                  size: 5,
                                  sort: 'created,desc',
                                  status: 'ENROLLED, TERMINATED',
                                }),
                              },
                            })
                            .then((response) => {
                              const { content } = JSON.parse(
                                get(response, 'data.remoteAction.data', {})
                              );
                              // userObject.current = content;
                              const temp = content
                                .filter(
                                  (v) => get(v, 'employeePlanConfirmation') !==
                                    null &&
                                    (get(v, 'user.inactiveDate') === null ||
                                      new Date(
                                        get(v, 'user.inactiveDate')
                                      ) > new Date())
                                )
                                .map((item) => {
                                  return {
                                    label: `${item.user.firstName} ${item.user.lastName}`,
                                    subValues: { employeeData: { ...item } },
                                    value: item?.rootEmployeeId ??
                                      item.employeeId,
                                  };
                                });
                              return temp;
                            });
                          return options;
                        }}
                        name="employeeId"
                        onChange={(values, meta, subValues) => {
                          // const employeeData = userObject.current.find(
                          //   (v) => v?.rootEmployeeId === values ||
                          //     v.employeeId === values
                          // );
                          const employeeData = get(subValues, 'employeeData', null);
                          const selectedPlan = employeeData?.planChoiceEmployees
                            .filter((item) => item.acceptGroupPlanChoice);
                          if (employeeData?.hasDemographicChanges) {
                            const groupNumber = employeeData?.currentGroup?.groupUniqueIdentifier.split('-');
                            return setShowModal({
                              employeeId: employeeData?.employeeId,
                              empId: employeeData?.empId,
                              groupNumber: `${groupNumber[0]}-${groupNumber[1]}`,
                              show: true,
                            });
                          }
                          form.change(
                            'relativeDatetime',
                            employeeData?.relativeDatetime
                          );
                          form.change(
                            'certificateNumber',
                            employeeData?.employeePlanConfirmation
                              ?.confirmationNumber
                          );
                          form.change('employeeData', employeeData);
                          form.change('selectedPlanChoiceId', get(selectedPlan, '[0]planChoiceId'));
                          form.change('selectedPlanId', employeeData?.employeePlanConfirmation
                            ?.planId);
                          form.change('dependentClaims', [
                            {
                              dependentVisits: [{}],
                            },
                          ]);
                        }}
                        searchable
                        validate={required}
                        wrapperSx={commonFieldSxHalf}
                        {...rest} />
                    )}
                  {(values.employeeClaims === 'false' ||
                    values.employeeId) && (
                      <>
                        <Field
                          aria-label={t('claimsScreen.upsertClaimPage.policyNumber')}
                          component={InputText}
                          disabled
                          label={labelHandler(t('claimsScreen.upsertClaimPage.policyNumber'), true)}
                          name="certificateNumber"
                          wrapperSx={{
                            textAlign: 'left',
                            ...commonFieldSxHalf,
                          }}
                          {...rest} />
                        <FieldArray name="dependentClaims" {...rest}>
                          {({ fields, meta }) => (
                            <>
                              <Box
                                sx={{
                                  display: [
                                    'inline-block',
                                    'inline-block',
                                    'inline-block',
                                    'flex',
                                  ],
                                  flexDirection: 'row',
                                  flexWrap: 'wrap',
                                  justifyContent: 'space-around',
                                  maxWidth: '98vw',
                                  mt: '0.25 rem !important',
                                }}
                              >
                                {fields.map((name, index) => {
                                  return (
                                    <Box
                                      key={index}
                                      mt={6}
                                      sx={{
                                        alignSelf: 'baseline',
                                        borderRadius: 3,
                                        boxShadow: 7,
                                        m: 1,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'flex-end',
                                          pr: 3,
                                          pt: 3,
                                        }}
                                      >
                                        {values?.dependentClaims?.length >
                                          1 && (
                                            <ToolbarButton
                                              bg="error"
                                              icon="close"
                                              onClick={() => fields.remove(index)} />
                                          )}
                                      </Box>
                                      <Box
                                        sx={{
                                          my: 4,
                                        }}
                                      >
                                        <MedicalHistory
                                          change={form.change}
                                          dependentClaims={values?.dependentClaims}
                                          groupId={values?.groupId}
                                          index={index}
                                          label={`${name}`}
                                          petInfo={get(
                                            values,
                                            'employeeData.employeeDependents',
                                            []
                                          )}
                                          relativeDatetime={values?.relativeDatetime}
                                          selectedPlanChoiceId={values?.selectedPlanChoiceId}
                                          selectedPlanId={values?.selectedPlanId}
                                          wrapperSx={commonFieldSxHalf}
                                          {...rest} />
                                      </Box>
                                    </Box>
                                  );
                                })}
                                {meta.error &&
                                  meta.touched &&
                                  !isArray(meta.error) && (
                                    <Box
                                      sx={{
                                        color: 'error',
                                        fontSize: 2,
                                        mt: 1,
                                      }}
                                    >
                                      {meta.error}
                                    </Box>
                                  )}
                              </Box>
                              {get(
                                values,
                                'employeeData.employeeDependents',
                                []
                              ).length >
                                get(values, 'dependentClaims', [])
                                  .length && (
                                  <Box my={6}>
                                    <ToolbarButton
                                      bg="accent"
                                      icon="add"
                                      iconSx={{ mr: [0, null, null, 4] }}
                                      label={t('claimsScreen.upsertClaimPage.addAnotherPet')}
                                      onClick={() => fields.push({
                                        dependentVisits: [{}],
                                      })} />
                                  </Box>
                                )}
                            </>
                          )}
                        </FieldArray>
                        <UploadDocumentPage
                          claimDocs={get(values, 'claimDocs', [])}
                          employeeId={get(values, 'employeeId', '')}
                          form={form}
                          groupId={get(values, 'groupId', '')}
                          wrapperSx={commonFieldSxHalf}
                          {...rest} />
                        <BankingPageInfo
                          acceptableMethods={get(
                            values,
                            'acceptableMethods',
                            ''
                          )}
                          wrapperSx={commonFieldSxHalf}
                          {...rest} />
                      </>
                    )}
                  <Box
                    sx={{
                      margin: '15px auto',
                    }}
                  >
                    <ToolbarButton
                      bg="primaryDark"
                      disabled={submitting}
                      label={t('common.submit')}
                      mr={0}
                      submitting={submitting}
                      type="submit"
                      width="150px" />
                  </Box>
                </Box>
                <BlockDemographicClaimModal
                  empId={showModal?.empId}
                  employeeId={showModal?.employeeId}
                  groupNumber={showModal?.groupNumber}
                  isOpen={showModal?.show}
                  onClose={() => setShowModal(null)} />
              </form>
            );
          }} />
      </Box>
    </Route>
  );
}

export default ClaimPageInfo;
