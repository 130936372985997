import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { diff } from 'deep-object-diff';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import Checkbox from '@basecomponents/InputCheckbox';
import { useTranslation } from 'react-i18next';
import { composeValidations, required } from '@utils/validators';
import GetData from '@utils/get-data';
import { labelHandler } from '@utils/label-utils';
import useSnackbar from '@utils/use-snackbar';
import { positive } from '@utils/validations';
import generateRedirectPath from '@utils/generate-redirect-path';
import InputNumber from '@basecomponents/InputNumber';
import Spinner from '@basecomponents/Spinner';
import ToolbarButton from '@basecomponents/ToolbarButton';
import InputDropdown from '@basecomponents/Dropdown';
import remoteActionQuery from '@queries/remote-action.gql';
import config from '@src/config.json';
import { produce } from 'immer';

/**
 * @category Group
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const EligibilityRules = ({ location, isRenewal, ...rest }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const groupId = get(queryParams, 'groupId', '');
  const {
    apiData: { HiringPeriod = [] },
  } = GetData('get-enum-options', JSON.stringify([{ params: 'HiringPeriod' }]));
  // const isEditing = get(location, 'pathname', '').includes('/groups/edit/');
  const name = 'step';
  const [setErrorSnack] = useSnackbar({ color: 'error' });

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  const { apiData: groups = {}, loading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );

  const noteId = groups?.groupNote?.groupNoteId

  const { apiData: groupNote, loading: groupNoteLoading } = GetData(
    'get-group-note',
    JSON.stringify({
      noteId
    }),
    noteId == null
  )

  const eligibilityRulesId = get(
    groups,
    'eligibilityRule.eligibilityRuleId',
    ''
  );

  const eligibilityRuleResponse = {
    coverageStartDateType: 'FIRST_DAY_OF_PAYROLL_PERIOD_FOLLOWING_ENROLLMENT',
    coverageStartDays: `${get(
      groups,
      'eligibilityRule.coverageStartDays',
      ''
    )} t('common.days')`,
    enrollmentPeriod: `${get(
      groups,
      'eligibilityRule.enrollmentPeriod',
      ''
    )} t('common.days')`,
    isFirstFollowingWaitingPeriod: get(
      groups,
      'eligibilityRule.isFirstFollowingWaitingPeriod',
      false
    ),
    newHireWaitingPeriod: get(
      groups,
      'eligibilityRule.newHireWaitingPeriod',
      ''
    ),
  }

  const initialValues = produce(eligibilityRuleResponse, draft => {
    draft.isFirstFollowingWaitingPeriod =
      groups?.eligibilityRule?.isFirstFollowingWaitingPeriod ?? groupNote?.followingWaitingPeriod
    const waitingPeriod = groups?.eligibilityRule?.newHireWaitingPeriod ?? groupNote?.newHireWaitingPeriod;
    draft.newHireWaitingPeriod = parseInt(waitingPeriod, 10)
  });

  if (loading) {
    return <Spinner />;
  }

  return (
    <ApolloConsumer>
      {(client) => {
        const onClickAction = async (values) => {
          // API call
          const apiCall = (pathName, params) =>
            client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: pathName,
                  params: JSON.stringify(params),
                },
              })
              .then(() => {
                navigate(
                  generateRedirectPath({
                    ...location,
                    queryParams: { [name]: isRenewal ? 4 : 3 },
                  })
                );
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                )
              );

          // Parameters
          const coverageStartDateType =
            'FIRST_DAY_OF_PAYROLL_PERIOD_FOLLOWING_ENROLLMENT';
          const params = {
            coverageStartDateType,
            coverageStartDays: 0,
            enrollmentPeriod: parseInt(get(values, 'enrollmentPeriod', ''), 10),
            isFirstFollowingWaitingPeriod: get(
              values,
              'isFirstFollowingWaitingPeriod',
              false
            ),
            newHireWaitingPeriod: parseInt(
              get(values, 'newHireWaitingPeriod', ''),
              10
            ),
          };

          // if (isEditing || eligibilityRulesId) {
          if (eligibilityRulesId) {
            const editResponse = {
              coverageStartDateType:
                'FIRST_DAY_OF_PAYROLL_PERIOD_FOLLOWING_ENROLLMENT',
              coverageStartDays: 0,
              enrollmentPeriod: get(
                groups,
                'eligibilityRule.enrollmentPeriod',
                ''
              ),
              isFirstFollowingWaitingPeriod: get(
                groups,
                'eligibilityRule.isFirstFollowingWaitingPeriod',
                false
              ),
              newHireWaitingPeriod: get(
                groups,
                'eligibilityRule.newHireWaitingPeriod',
                ''
              ),
            };

            const diffPayload = diff(editResponse, params);

            if (Object.keys(diffPayload).length) {
              return apiCall('update-eligibility-rules', {
                ...diffPayload,
                id: get(groups, 'eligibilityRule.eligibilityRuleId', ''),
              });
            }

            return navigate(
              generateRedirectPath({
                ...location,
                queryParams: { [name]: isRenewal ? 4 : 3 },
              })
            );
          }

          return apiCall('create-eligibility-rules', {
            ...params,
            groupId,
            status: 'IN_PROGRESS',
          });
        };
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={
                // isEditing || eligibilityRulesId
                initialValues
              }
              onSubmit={async (formValues) => {
                await onClickAction(formValues);
              }}
              render={(formContext) => {
                return (
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        padding: 3,
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box
                          as="h3"
                          color="accent"
                          display="flex"
                          justifyContent="flex-start"
                          sx={{
                            margin: '20px 0px 20px 0px;',
                            padding: 3,
                            width: '25rem',
                          }}
                        >
                          Eligibility Rules
                        </Box>
                        <Field
                          aria-label={t('groups.newHireWaitingPeriodInDays')}
                          component={InputDropdown}
                          label={labelHandler(
                            t('groups.newHireWaitingPeriodInDays'),
                            true
                          )}
                          name="newHireWaitingPeriod"
                          options={HiringPeriod}
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groups.firstMonthWaiting')}
                          component={Checkbox}
                          inputWrapperSx={{
                            display: 'inline-flex',
                            float: 'left',
                            marginTop: '7px',
                          }}
                          label={labelHandler(
                            t('groups.firstMonthWaiting'),
                            false
                          )}
                          // validate={composeValidations(required, positive)}
                          labelSx={{
                            display: 'inline-flex',
                          }}
                          name="isFirstFollowingWaitingPeriod"
                          type="checkbox"
                          wrapperSx={{ ...commonFieldSxHalf, display: 'flex' }}
                        />
                        <Field
                          aria-label={t('groups.newHireEnrollmentPeriodInDays')}
                          component={InputNumber}
                          label={labelHandler(
                            t('groups.newHireEnrollmentPeriodInDays'),
                            true
                          )}
                          name="enrollmentPeriod"
                          validate={composeValidations(required, positive)}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                      </Box>
                      <Box
                        sx={{
                          color: 'accent',
                          display: 'block',
                          fontSize: 1,
                          fontWeight: 'bold',
                          letterSpacing: 0,
                          lineHeight: '12px',
                          maxWidth: '25rem',
                          mb: 1,
                          mt: 6,
                          textAlign: 'center',
                          textTransform: 'uppercase',
                        }}
                      >
                        {t('groups.coverageStartWaitingPeriod')}
                      </Box>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          maxWidth: '25rem',
                          my: 6,
                          p: 3,
                          width: '100%',
                        }}
                      >
                        <ToolbarButton
                          bg="primaryLight"
                          label={t('common.previous')}
                          ml={0}
                          onClick={() =>
                            navigate(
                              generateRedirectPath({
                                ...location,
                                queryParams: {
                                  [name]: isRenewal ? 2 : 1,
                                },
                              })
                            )
                          }
                          width="10rem"
                        />
                        <ToolbarButton
                          bg="primaryDark"
                          isDisabled={formContext.submitting}
                          label={t('common.next')}
                          mr={0}
                          submitting={formContext.submitting}
                          type="submit"
                          width="10rem"
                        />
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

EligibilityRules.propTypes = {
  isRenewal: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

EligibilityRules.defaultProps = {
  isRenewal: false,
};

export default EligibilityRules;
