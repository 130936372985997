import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import Card from '../../../BaseComponents/Card';
import GetData from '../../../../utilities/get-data';
import dateUtils from '../../../../utilities/date-utils';
import Spinner from '../../../BaseComponents/Spinner';

/**
 *
 * @category Group
 * @param {string} groupPaymentRecordId
 * @returns {React.FC}
 */
const PaymentHistory = ({ groupPaymentRecordId }) => {
  const { apiData: paymentChangeLog = [], loading } = GetData(
    'get-group-payment-change-log',
    JSON.stringify({ id: groupPaymentRecordId })
  );

  const { apiData: chasePaymentChangeLog = [] } = GetData(
    'get-chase-payment-change-log',
    JSON.stringify({ groupPaymentRecordId })
  );
  const paymentData = Object.values(paymentChangeLog).filter(
    (data) =>
      get(data, 'groupData.billedAmount', null) !== null ||
      get(data, 'groupData.confirmedAmount', null) !== null ||
      get(data, 'groupData.remittedAmount', null) !== null
  );
  return loading ? (
    <Spinner />
  ) : (
    <Card cardSx={{ m: 5, p: 5 }}>
      <Box
        sx={{
          color: 'primary',
          fontSize: 3,
          fontWeight: 'bold',
          mb: 3,
          textTransform: 'uppercase',
        }}
      >
        Payment History
      </Box>
      <Box
        sx={{
          color: 'gray.6',
          display: 'content',
          fontSize: 2,
        }}
      >
        {paymentData.length > 0 && (
          <Box>
            {paymentData.reverse().map((data, index) => (
              <>
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '99%',
                  }}
                >
                  {data.changeType === 'NEW_REQUEST'
                    ? ` On ${dateUtils.getFormattedDate(
                        get(data, 'groupData.relativeDateTime')
                      )} an amount of $ ${get(
                        data,
                        'groupData.billedAmount',
                        ''
                      )} was BILLED`
                    : null}
                  {data.changeType === 'REMITTANCE_UPLOAD'
                    ? ` On ${dateUtils.getFormattedDate(
                        get(data, 'groupData.relativeDateTime')
                      )} an amount of $ ${get(
                        data,
                        'groupData.remittedAmount',
                        ''
                      )} was REMITTED`
                    : null}
                  {data.changeType === 'PROCESS_PAYMENT'
                    ? ` On ${dateUtils.getFormattedDate(
                        get(data, 'groupData.relativeDateTime')
                      )} an amount of $ ${get(
                        data,
                        'groupData.confirmedAmount',
                        ''
                      )} was PAID`
                    : null}
                  {![
                    'PROCESS_PAYMENT',
                    'REMITTANCE_UPLOAD',
                    'NEW_REQUEST',
                  ].includes(data.changeType)
                    ? ` On ${dateUtils.getFormattedDate(
                        get(data, 'groupData.relativeDateTime')
                      )} an amount of $ ${get(
                        data,
                        'groupData.confirmedAmount',
                        ''
                      )} was ADJUSTED`
                    : null}
                </Box>
              </>
            ))}
          </Box>
        )}
        {chasePaymentChangeLog.length === 0 && paymentData.length === 0 && (
          <Box
            sx={{
              color: 'accent',
              fontSize: 3,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            No Payment History
          </Box>
        )}
        {chasePaymentChangeLog.length > 0 ? (
          <Box>
            {chasePaymentChangeLog.map((data, index) => {
              const msg = JSON.parse(data.message);
              return (
                <>
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      width: '99%',
                    }}
                  >
                    {msg
                      ? ` On ${dateUtils.getFormattedDate(
                          get(msg, 'changeAt')
                        )} ${get(msg, 'message', '')} and the status is ${get(
                          data,
                          'status',
                          ''
                        )} `
                      : null}
                  </Box>
                </>
              );
            })}
          </Box>
        ) : null}
      </Box>
    </Card>
  );
};

PaymentHistory.propTypes = {
  groupPaymentRecordId: PropTypes.string.isRequired,
};

export default PaymentHistory;
