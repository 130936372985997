import React, { useContext } from 'react';
import { useParams } from '@reach/router';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import Box from '@basecomponents/Box';
import { useTranslation } from 'react-i18next';
import Route from '../../Route';
import Tabs from '../../../BaseComponents/Tabs';
import KeyValuePairs from '../../../BaseComponents/KeyValuePairs';
import GetData from '../../../../utilities/get-data';
import getMetaData from '../../../../utilities/get-meta-data';
import Card from '../../../BaseComponents/Card';
import SubProducerDashboardPage from '../../SubProducers/SubProducerDashboardPage';
import CommissionsDashboardPage from '../../Commissions/DashboardPage';
import config from '../../../../config.json';

/**
 * @category Producer
 * @param {*} rest
 * @returns {any}
 */
const ProducerDetails = ({ ...rest }) => {
  const theme = useContext(ThemeContext);
  const queryParams = useParams();
  const producerId = get(queryParams, 'producerId', '');
  const { apiData = {}, loading } = GetData(
    'get-producer-by-id',
    JSON.stringify({ id: producerId })
  );
  const meta = getMetaData({ moduleName: 'producers' });
  const { t } = useTranslation()

  const fields = [
    { name: 'name' },
    // { name: 'producerCode' },
    { name: 'type' },
    {
      label: config.canadaEnv ? t('groupEmployees.detailsPage.authorizedProvinces') : null,
      name: 'authorizedStates',
    },
    { name: 'status' },
    { name: 'address' },
    { name: 'contact' },
  ];

  if (config.canadaEnv) {
    fields.splice(1, 0, { dynamicLabel: t('component.canada.fsraAgentNumber'), name: 'producerCode' });
  } else {
    fields.splice(1, 0, { dynamicLabel: 'Producer Code/NPN', name: 'producerCode' });
  }

  return (
    <Route
      header={{
        title: get(apiData, 'name', producerId),
        type: 'producers',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {loading ? (
          <PulseLoader color={get(theme, 'colors.accent')} size={5} />
        ) : (
          `Details of ${get(apiData, 'name', producerId)}`
        )}
      </Box>
      <Card loading={loading}>
        <KeyValuePairs data={apiData} fields={fields} meta={meta} />
      </Card>
      <Tabs
        loading={loading}
        tabs={[
          {
            label: 'Sub Producers',
            render: () => <SubProducerDashboardPage producerId={producerId} />,
          },
          {
            label: 'Commissions',
            render: () => <CommissionsDashboardPage producerId={producerId} />,
          },
        ]}
      />
    </Route>
  );
};

ProducerDetails.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default ProducerDetails;
