import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { isEmpty } from 'lodash';
import { Form as FinalForm } from 'react-final-form';
import Box from '@basecomponents/Box';
import { ApolloConsumer, useApolloClient } from '@apollo/client';
import { AuthContext } from '@basecomponents/Auth';
import { USER_ROLE } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import Route from '../../Route';
import ListGrid from '../../../BaseComponents/ListGrid';
import GetData from '../../../../utilities/get-data';
import useSnackbar from '../../../../utilities/use-snackbar';
import Modal from '../../../BaseComponents/Modal';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql';
import ToolbarButton from '../../../BaseComponents/ToolbarButton';
import Spinner from '../../../BaseComponents/Spinner';
import config from '../../../../config.json';

/**
 * @category GroupAdmin
 * @param {boolean} actionDisabled
 * @param {string} groupId
 * @param {boolean} isBenBroker
 * @param {*} rest
 * @returns {React.FC}
 */
const GroupAdministratorsDashboard = ({
  actionDisabled,
  groupId,
  isBenBroker,
  ...rest
}) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const filters = [
    {
      label: t('filters.firstName'),
      name: 'firstName',
      type: 'Input',
    },
    {
      label: t('filters.lastName'),
      name: 'lastName',
      type: 'Input',
    },
  ];
  const { apiData: { content = [] } = {}, loading } = GetData(
    'get-all-group-employees',
    JSON.stringify({ groupId }),
    isBenBroker
  );
  const apolloClient = useApolloClient();

  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [terminatedData, setTermination] = useState({});

  async function getVisibility(userId, groupId) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: remoteActionQuery,
        variables: {
          name: 'validate-employee-for-ben-broker',
          params: JSON.stringify({
            groupId,
            userId,
          }),
        },
      });
      const parsed = JSON.parse(get(response, 'data.remoteAction.data', []));
      if (parsed.length > 0) return false;
      return true;
    } catch (error) {
      setErrorSnack(
        `There was an error: ${error.message}`,
        config.notificationDuration
      );
      return false;
    }
  }

  const grid = {
    actions: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(role)
      ? [
          {
            getProps: (data, refetch) => {
              // const { userId, groupId } = data;
              const groupId = get(data, 'groupId', '');
              const userId = get(data, 'subProducer.userId', '');
              return {
                icon: 'remove',
                isDisabled:
                  actionDisabled || get(data, 'active', true) === false,
                onClick: async () => {
                  const visibility = isBenBroker
                    ? await getVisibility(userId, groupId)
                    : true;
                  if (visibility) {
                    return setTermination({ ...data, refetch });
                  }
                  setErrorSnack('Cannot unassign this user');
                },
                // visible: isBenBroker ? visibility : true,
              };
            },
            label: 'unassigned',
            name: 'unassigned',
          },
        ]
      : [],
    fields: isBenBroker
      ? [
          { name: 'subProducer.user.firstName' },
          { name: 'subProducer.user.lastName' },
          { name: 'subProducer.user.email' },
          { name: 'subProducer.contact.contactType' },
          { name: 'subProducer.contact.phoneNumber' },
        ]
      : [
          { name: 'user.firstName' },
          { name: 'user.lastName' },
          { name: 'user.email' },
          { name: 'user.contacts[0].contactType' },
          { name: 'user.contacts[0].phoneNumber' },
        ],
  };
  const defaultFilterValues = groupId ? [{ groupId }] : [];
  if (isBenBroker) {
    defaultFilterValues.push({ deleted: false });
  }
  const uiContent = (
    <ApolloConsumer>
      {(client) => {
        const userName = isBenBroker
          ? `${get(terminatedData, 'subProducer.user.firstName')}  ${get(
              terminatedData,
              'subProducer.user.lastName'
            )}`
          : `${get(terminatedData, 'user.firstName')}  ${get(
              terminatedData,
              'user.lastName'
            )}`;
        return (
          <>
            <ListGrid
              defaultFilterValues={defaultFilterValues}
              filters={filters}
              grid={grid}
              gridQueryName={
                isBenBroker
                  ? 'get-all-subproducer-to-group'
                  : 'get-all-administrator-by-group-id'
              }
              moduleName={isBenBroker ? 'ben-broker' : 'group-admins'}
              {...rest}
            />
            {!isEmpty(terminatedData) && (
              <Modal
                isOpen={!isEmpty(terminatedData)}
                onClose={() => setTermination({})}
                title={isBenBroker ? 'Alert' : 'Terminate User'}
              >
                <FinalForm
                  initialValues={{}}
                  onSubmit={() => {
                    const employerInfo = isBenBroker
                      ? {}
                      : content.find(
                          (v) => v.userId === get(terminatedData, 'userId')
                        );
                    // eslint-disable-next-line no-nested-ternary
                    const params = isBenBroker
                      ? JSON.stringify({
                          groupId,
                          subProducerId: get(terminatedData, 'subProducerId'),
                        })
                      : get(employerInfo, 'employeeId')
                      ? JSON.stringify({
                          employeeId: get(employerInfo, 'employeeId'),
                        })
                      : JSON.stringify({
                          active: false,
                          id: get(terminatedData, 'administratorId', ''),
                          role: 'REVIEW_ADMIN',
                        });
                    client
                      .query({
                        fetchPolicy: 'no-cache',
                        query: remoteActionQuery,
                        variables: {
                          // eslint-disable-next-line no-nested-ternary
                          name: isBenBroker
                            ? 'unassign-broker'
                            : get(employerInfo, 'employeeId')
                            ? 'remove-group-admin'
                            : 'update-group-administrator',
                          params,
                        },
                      })
                      .then(async () => {
                        await terminatedData.refetch();
                        setTermination({});
                      })
                      .catch((e) => {
                        setTermination({});
                        setErrorSnack(
                          `There was an error: ${e.message}`,
                          config.notificationDuration
                        );
                      });
                  }}
                  render={(formContext) => (
                    <form onSubmit={formContext.handleSubmit}>
                      <Box>
                        {isBenBroker && (
                          <Box>
                            `You are going to unassign ${userName} as a ben
                            broker from this group. ` <br />
                            Are you sure?
                          </Box>
                        )}
                        By submitting, you are confirming the removal of &nbsp;
                        {userName}.
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            mt: '30px',
                          }}
                        >
                          <ToolbarButton
                            g="error"
                            label={t('common.cancel')}
                            onClick={() => setTermination({})}
                            sx={{
                              bg: 'error',
                            }}
                            width="150px"
                          />
                          <ToolbarButton
                            g="error"
                            label={t('common.submit')}
                            submitting={formContext.submitting}
                            type="submit"
                            width="150px"
                          />
                        </Box>
                      </Box>
                    </form>
                  )}
                />
              </Modal>
            )}
          </>
        );
      }}
    </ApolloConsumer>
  );
  if (loading) {
    return <Spinner />;
  }
  return groupId ? (
    uiContent
  ) : (
    <Route
      header={{
        title: 'Dashboard',
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      {uiContent}
    </Route>
  );
};

GroupAdministratorsDashboard.defaultProps = {
  actionDisabled: false,
  groupId: null,
  isBenBroker: false,
};

GroupAdministratorsDashboard.propTypes = {
  actionDisabled: PropTypes.bool,
  groupId: PropTypes.string,
  isBenBroker: PropTypes.bool,
};

export default GroupAdministratorsDashboard;
