import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import React from 'react';
import { get } from 'lodash';
import Icon from '../../../Icon';
import Route from '../../Route';
import GetData from '../../../../utilities/get-data';
import Spinner from '../../../BaseComponents/Spinner';

/**
 * Group settings dashboard page
 *
 * @category Group
 * @param {string} groupId
 * @param {*} rest
 * @returns {React.FC}
 */
const GroupSettingsDashboardPage = ({ groupId, ...rest }) => {
  const { apiData: groupData = {}, loading: groupDataLoading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );
  const settingsMeta = [
    {
      icon: 'notifications',
      link: `/groups/${groupId}/groupsettings/notifications`,
      name: 'Notifications',
    },
    {
      icon: 'reports',
      isHidden: get(groupData, 'groupType', '') !== 'BEN_ADMIN_SYSTEM',
      link: `/groups/${groupId}/groupsettings/reports`,
      name: 'Reports',
    },
    {
      icon: 'reports',
      link: `/groups/${groupId}/groupsettings/promotional-content`,
      name: 'Promotional Content',
    },
  ];

  return (
    <Route isPrivate permission="setting.page" {...rest}>
      <Box as="h2" sx={{ py: 3 }}>
        Group Settings
      </Box>
      {groupDataLoading && <Spinner />}
      {!groupDataLoading &&
        settingsMeta.map(({ link, icon, name, isHidden }) => (
          <>
            {!isHidden && (
              <Box
                key={`${icon}-${name}`}
                sx={{
                  '&:hover': {
                    boxShadow: 6,
                  },
                  bg: 'white',
                  borderRadius: 5,
                  m: 3,
                  p: 3,
                  transitionDuration: '0.25s',
                }}
              >
                <Link to={link}>
                  <Box
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box as="div" display="flex" flexDirection="row">
                      <Icon
                        height={25}
                        svg={icon}
                        sx={{ color: 'primary' }}
                        width={25}
                      />
                      <Box as="h3" marginLeft={2}>
                        {name}
                      </Box>
                    </Box>
                    <Icon
                      height={25}
                      svg="right-chevron"
                      sx={{ color: 'primary' }}
                      width={25}
                    />
                  </Box>
                </Link>
              </Box>
            )}
          </>
        ))}
    </Route>
  );
};

GroupSettingsDashboardPage.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export default GroupSettingsDashboardPage;
