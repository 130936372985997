import React, { useEffect, useState } from 'react';
import Modal from '@basecomponents/Modal';
import { useLocation } from '@reach/router';
import Box from '@basecomponents/Box';

import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

const InEligible = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { t } = useTranslation()

  const location = useLocation();

  useEffect(() => {
    const queryParams = location.search;
    const errorMessage = decodeURIComponent(queryParams)?.split('=')[1];

    if (errorMessage) {
      setErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, []);

  return (
    showErrorModal && (
      <Modal
        isOpen
        onClose={() => setShowErrorModal(!showErrorModal)}
        onSubmit={() => navigate('/login')}
        submitText={t('common.ok')}
        title={t('common.wrong')}
      >
        <Box>{errorMessage}</Box>
      </Modal>
    )
  );
};

export default InEligible;
