import Box from '@basecomponents/Box';
import { get, isArray } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useTranslation } from 'react-i18next';
import {
  composeValidations,
  required,
} from '../../../../../utilities/validators';
import InputDropdown from '../../../../BaseComponents/Dropdown';
import remoteActionQuery from '../../../../../graphql/queries/remote-action.gql';
import { labelHandler } from '../../../../../utilities/label-utils';
import { totalCommission } from '../../../../../utilities/validations';
import InputMasked from '../../../../BaseComponents/InputMasked';
import Spinner from '../../../../BaseComponents/Spinner';
import ToolbarButton from '../../../../BaseComponents/ToolbarButton';
import GetData from '../../../../../utilities/get-data';

const commonFieldSxHalf = {
  margin: 0,
  padding: 3,
  width: '25rem',
};

/**
 * @category ProductAdmin
 * @param {string} name
 * @param {function} validation
 * @param {Object} client
 * @param {Array<Object>} commissionsData
 * @param {string} state
 * @param {*} rest
 * @returns {React.FC}
 */
const CommissionFieldsArray = ({
  name,
  validation,
  client,
  commissionsData,
  state,
  ...rest
}) => {
  const { t } = useTranslation();
  const [selectedProducerValue, setSelectedProducerValue] = useState([]);
  const { apiData = {}, loading } = GetData(
    'get-all-producer',
    JSON.stringify([{ page: 0, size: 10000, state, status: 'ACTIVE' }])
  );
  const { content = [] } = apiData;

  return (
    <Box {...rest}>
      {loading ? (
        <Spinner />
      ) : (
        <FieldArray name={name} validate={validation} {...rest}>
          {({ fields, meta }) => {
            return (
              <>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around',
                    maxWidth: '98vw',
                  }}
                >
                  {fields.map((name, index) => {
                    return (
                      <Box
                        key={`${name}-${index}`}
                        sx={{
                          borderRadius: 3,
                          boxShadow: 7,
                          display: 'flex',
                          flexDirection: 'column',
                          m: 2,
                          p: 2,
                        }}
                      >
                        <Field
                          aria-label={t('groupEmployees.dashboardPage.producer')}
                          component={InputDropdown}
                          data-cy={`commissionName${index}`}
                          label={labelHandler(t('groupEmployees.dashboardPage.producer'), true)}
                          name={`${name}.producerDetail.producerId`}
                          onChange={(option) => {
                            const temp = selectedProducerValue;
                            temp[index] = option;
                            setSelectedProducerValue(temp);
                          }}
                          options={
                            content &&
                            content.map((item) => {
                              return {
                                canEnglishValue: item.name,
                                frenchValue: item.name,
                                label: item.name,
                                value: item.producerId,
                              };
                            })
                          }
                          searchable
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        {commissionsData[index] && (
                          <Field
                            key={get(
                              commissionsData,
                              `${index}.producerDetail.producerId`
                            )}
                            aria-label={t('groupEmployees.dashboardPage.subproducer')}
                            component={InputDropdown}
                            data-cy={`commissionName${index}`}
                            isClearable
                            label={labelHandler(t('groupEmployees.dashboardPage.subproducer'), true)}
                            loadOptions={async (data) => {
                              const options = await client
                                .query({
                                  fetchPolicy: 'no-cache',
                                  query: remoteActionQuery,
                                  skip: !get(
                                    commissionsData,
                                    `${index}.producerDetail.producerId`
                                  ),
                                  variables: {
                                    name: 'get-all-subproducer',
                                    params: JSON.stringify({
                                      active: true,
                                      name: data,
                                      producerId: get(
                                        commissionsData,
                                        `${index}.producerDetail.producerId`
                                      ),
                                      size: 5,
                                      state,
                                      status: 'ACTIVE',
                                    }),
                                  },
                                })
                                .then((response) => {
                                  const { content } = JSON.parse(
                                    get(response, 'data.remoteAction.data', {})
                                  );
                                  const temp = content.map((item) => {
                                    return {
                                      label: item.name,
                                      value: item.subProducerId,
                                    };
                                  });
                                  return temp;
                                });
                              return options;
                            }}
                            name={`${name}.subProducer.subProducerId`}
                            searchable
                            validate={required}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                        )}
                        <Field
                          aria-label="Commission in %"
                          component={InputMasked}
                          data-cy={`commissionValue${index}`}
                          label={labelHandler('Commission in %', true)}
                          mask={createNumberMask({
                            allowDecimal: true,
                            includeThousandsSeparator: false,
                            integerLimit: 2,
                            prefix: '',
                          })}
                          name={`${name}.commissionPercentage`}
                          validate={composeValidations(
                            required,
                            totalCommission
                          )}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Box
                          p={index === 0 ? 0 : 4}
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            width: '100%',
                          }}
                        >
                          {index > 0 && (
                            <ToolbarButton
                              aria-label="close"
                              bg="error"
                              buttonSx={{
                                borderRadius: '50%',
                                height: '30px',
                                width: '30px',
                              }}
                              icon="close"
                              iconSx={{
                                height: '20px',
                                width: '20px',
                              }}
                              onClick={() => fields.remove(index)}
                            />
                          )}
                        </Box>
                        {meta.error && meta.touched && !isArray(meta.error) && (
                          <Box
                            sx={{
                              color: 'error',
                              fontSize: 2,
                              mt: 1,
                            }}
                          >
                            {meta.error}
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </Box>
                <Box display="flex" justifyContent="center" mt={6}>
                  <ToolbarButton
                    bg="accentLight"
                    icon="add"
                    iconSx={{
                      height: '20px',
                      mr: [0, null, null, 4],
                      width: '20px',
                    }}
                    label="Add Another"
                    onClick={() => fields.push({})}
                  />
                </Box>
              </>
            );
          }}
        </FieldArray>
      )}
    </Box>
  );
};

CommissionFieldsArray.propTypes = {
  client: PropTypes.shape({
    query: PropTypes.func,
  }).isRequired,
  commissionsData: PropTypes.arrayOf(PropTypes.shape({})),
  name: PropTypes.string,
  state: PropTypes.string.isRequired,
  validation: PropTypes.func,
};

CommissionFieldsArray.defaultProps = {
  commissionsData: [{}],
  name: 'commissions',
  validation: () => {},
};

export default CommissionFieldsArray;
