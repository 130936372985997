import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import React from 'react';
import Route from '../Route';
import BasicInformation from './BasicInformation';
import BillingInformation from './BillingInformation';
import EligibilityRules from './EligibilityRules';
import GroupContacts from '../GroupContacts';
import InitialSetup from './InitialSetup';
import ProgressBar from '../../BaseComponents/ProgressBar';

/**
 * @category Group
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const UpsertGroups = ({ location, ...rest }) => {
  const title = 'Group Configuration';
  const name = 'step';
  return (
    <Route
      header={{
        title,
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {title}
      </Box>
      <ProgressBar
        defaultTab="0"
        name={name}
        slides={[
          'Initial Setup',
          'Basic Information',
          'Eligibility Rules',
          'Group Contacts',
          'Billing Information',
        ]}
        tabs={[
          {
            render: () => <InitialSetup location={location} {...rest} />,
            value: '0',
          },
          {
            render: () => <BasicInformation location={location} {...rest} />,
            value: '1',
          },
          {
            render: () => <EligibilityRules location={location} {...rest} />,
            value: '2',
          },
          {
            render: () => <GroupContacts location={location} {...rest} />,
            value: '3',
          },
          {
            render: () => <BillingInformation location={location} {...rest} />,
            value: '4',
          },
        ]}
      />
    </Route>
  );
};

UpsertGroups.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default UpsertGroups;
