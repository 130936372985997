import React, { useEffect, useReducer } from 'react';
import { PropTypes } from 'prop-types';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import reducer from './utils/reducer';
import Route from '../Route';
import Section from './Section';

/**
 * @category Components
 * @deprecated
 * @param {object} data
 * @param {string} mutationName Indicates the name of the mutation query
 * @param {Function} onComplete
 * @param {string} queryName Indicates the name of the query
 * @param {Array<Object>} sections
 * @param {string} title Indicates the title of the Form
 * @param {string} type Indicates the type of the section
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const DataFormPage = ({
  data,
  mutationName,
  onComplete,
  queryName,
  sections,
  title,
  type,
  ...rest
}) => {
  const [state, dispatch] = useReducer(reducer, {
    activeSection: 0,
    stateValues: data,
  });
  const { activeSection, stateValues } = state;
  useEffect(() => {
    if (activeSection === sections.length) {
      onComplete(stateValues);
    }
  }, [activeSection]);
  return (
    <Route
      header={{
        title,
        type,
      }}
      isPrivate
      {...rest}
    >
      <Box key="section-container" width="100%">
        {sections.map(
          ({ fields, sectionOperations, name, title, type }, sectionIx) => {
            let sectionOrder = 'middle';
            if (sectionIx === 0) {
              sectionOrder = 'first';
            } else if (sectionIx === sections.length - 1) {
              sectionOrder = 'last';
            }
            return (
              <Box
                key={`section-${sectionIx}`}
                fontSize={2}
                p={4}
                sx={{ transition: 'all 1s' }}
              >
                <Box
                  bg="white"
                  sx={{
                    transition: 'all 1s',
                  }}
                >
                  <Section
                    active={activeSection === sectionIx}
                    dispatch={dispatch}
                    fields={fields}
                    mutationName={mutationName}
                    name={name}
                    queryName={queryName}
                    sectionOperations={sectionOperations}
                    sectionOrder={sectionOrder}
                    sectionTitle={title}
                    stateValues={stateValues}
                    type={type}
                    userId={get(data, 'userId')}
                  />
                </Box>
              </Box>
            );
          }
        )}
      </Box>
    </Route>
  );
};

DataFormPage.defaultProps = {
  data: {},
  title: '',
  type: 'groups',
};

DataFormPage.propTypes = {
  data: PropTypes.shape({}),
  mutationName: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  queryName: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default DataFormPage;
