import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components';
import Spinner from '../../BaseComponents/Spinner';
import ToolbarButton from '../../BaseComponents/ToolbarButton';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledPage = styled(Page)`
  box-shadow: ${(p) => p.theme.shadows[2]};
`;

/**
 * @category Components
 * @param {(React.ReactNode|'loading')} file
 * @param {function} onDocumentLoad
 * @param {*} pageProps
 * @returns {React.FC}
 */
const PDFViewer = ({ file, onDocumentLoad, ...pageProps }) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(600);

  useEffect(
    (prevProps) => {
      if (prevProps) {
        if (file !== prevProps.file) {
          setNumPages(1);
          setPageNumber(1);
        }
      }
    },
    [file]
  );

  const onLoadDocument = ({ numPages }) => {
    setNumPages(numPages);
    onDocumentLoad();
  };

  const onResize = (resizedWidth) => {
    setWidth(resizedWidth);
  };

  const hasNextPage = () => {
    return pageNumber < numPages;
  };

  const hasPreviousPage = () => {
    return pageNumber > 1;
  };

  const nextPage = () => {
    if (!hasNextPage()) return;
    setPageNumber(pageNumber + 1);
  };

  const previousPage = () => {
    if (!hasPreviousPage()) return;
    setPageNumber(pageNumber - 1);
  };

  const paginator = () => {
    if (numPages > 1) {
      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            my: 7,
          }}
        >
          <ToolbarButton
            bg="accent"
            buttonSx={{
              borderRadius: '50%',
              height: '50px',
              width: '50px',
            }}
            icon="left-chevron"
            isDisabled={!hasPreviousPage()}
            onClick={previousPage}
          />
          <Box
            sx={{ fontWeight: 'bold', mx: 4 }}
          >{`Page ${pageNumber} of ${numPages}`}</Box>
          <ToolbarButton
            bg="accent"
            buttonSx={{
              borderRadius: '50%',
              height: '50px',
              width: '50px',
            }}
            icon="right-chevron"
            isDisabled={!hasNextPage()}
            onClick={nextPage}
          />
        </Box>
      );
    }

    return null;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
      }}
    >
      <ReactResizeDetector
        handleWidth
        onResize={onResize}
        refreshMode="debounce"
      />
      {file === 'loading' ? (
        <Spinner />
      ) : (
        <>
          {paginator()}
          <Document
            file={file}
            loading={<Spinner />}
            onLoadSuccess={onLoadDocument}
          >
            <StyledPage pageNumber={pageNumber} width={width} {...pageProps} />
          </Document>
          {paginator()}
        </>
      )}
    </Box>
  );
};

PDFViewer.propTypes = {
  file: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf(['loading'])])
    .isRequired,
  onDocumentLoad: PropTypes.func,
};

PDFViewer.defaultProps = {
  onDocumentLoad: () => {},
};

export default PDFViewer;
