import { get } from 'lodash';
import { useQuery } from '@apollo/client';
import remoteActionQuery from '../graphql/queries/remote-action.gql';

const GetData = (name, params, skip = false, fetchPolicy = 'network-only') => {
  const { data, loading, refetch, error } = useQuery(remoteActionQuery, {
    fetchPolicy,
    skip,
    variables: {
      name,
      params,
    },
  });
  const remoteActionData = JSON.parse(
    get(data, 'remoteAction.data', JSON.stringify({}))
  );
  let errorMsg = null;
  if (error) {
    errorMsg = error?.message;
  }
  return {
    apiData: remoteActionData,
    errorMsg,
    loading,
    refetch,
  };
};

export default GetData;
