import React from 'react';
import get from 'lodash/get';
import Box from '@basecomponents/Box';
import NotesCategory from '@petcomponents/Picklist/NotesCategory';
import RateFileFrequency from '@petcomponents/Picklist/RateFileFrequency';
import USState from './USState';
import ReportTypes from './ReportTypes';
import FileStatus from './FileStatus';
import BarrelStatus from './BarrelStatus';
import FileFlows from './FileFlow';
import BarrelFlows from './BarrelFlow';
import ExistingCondition from './ExistingCondition';
import QleType from './QleType';
import QleStatus from './QleStatus';
import BillingType from './BillingType';
import BillingCycle from './BillingCycle';
import AccountType from './AccountType';
import RenewalStatus from './RenewalStatus';
import RenewalType from './RenewalType';
import RenewalNotificationDate from './RenewalNotificationDate';
import CancellationReason from './CancellationReason';
import PayrollFrequency from './PayrollFrequency';
import {variation} from '../../../config.json';
import CreatedBy from './CreatedBy';

/**
 * @category Components
 * @param {Object} data
 * @param {string} name
 * @param {string} type
 * @returns {React.FC}
 */
const Picklist = ({ data, name, type, referenceName }) => {
  let result = null;

  switch (type) {
    case 'existing-condition': {
      result = <ExistingCondition value={get(data, name)} />;
      break;
    }
    case 'barrel-status': {
      result = <BarrelStatus value={get(data, name)} />;
      break;
    }
    case 'file-status': {
      result = <FileStatus value={get(data, name)} />;
      break;
    }
    case 'barrel-flows': {
      result = <BarrelFlows value={get(data, name)} />;
      break;
    }
    case 'file-flows': {
      result = <FileFlows value={get(data, name)} />;
      break;
    }
    case 'us-state': {
      result = <USState value={get(data, name) || get(data, referenceName)} />;
      break;
    }
    case 'report-types': {
      result = <ReportTypes value={get(data, name)} />;
      break;
    }
    case 'qle-type': {
      result = <QleType value={get(data, name)} />;
      break;
    }
    case 'billing-type': {
      result = <BillingType value={get(data, name)} />;
      break;
    }
    case 'billing-cycle': {
      result = <BillingCycle value={get(data, name)} />;
      break;
    }
    case 'payroll-frequency': {
      result = <PayrollFrequency value={get(data, name)} />;
      break;
    }
    case 'qle-status': {
      result = <QleStatus value={get(data, name)} />;
      break;
    }
    case 'account-type': {
      result = <AccountType value={get(data, name)} />;
      break;
    }
    case 'cancellation-reason': {
      result = <CancellationReason value={get(data, name)} />;
      break;
    }
    case 'renewal-status': {
      result = <RenewalStatus apiData={data} value={get(data, name)} />;
      break;
    }
    case 'billing-status': {
      if (get(data, 'status') === 'PAID' && !get(data, 'isSettle')) {
        result = 'UNSETTLED AMOUNT';
      } else if (get(data, 'status') === 'ADVANCE_PAYMENT') {
        result = 'ADVANCE PAYMENT';
      } else {
        result = get(data, name);
      }
      break;
    }
    case 'renewalType': {
      result = <RenewalType data={data} />;
      break;
    }
    case 'groupType': {
      if (variation === 'demo') {
        result = 'Fineos Administered';
      } else if (get(data, 'groupType') === 'IAIC_ADMINISTRATED') {
        result = 'IAIC Administered';
      } else {
        result = <Box color="accentSecondary">Ben Administered</Box>;
      }
      break;
    }
    case 'renewal-notification-date': {
      result = <RenewalNotificationDate data={data} />;
      break;
    }
    case 'created-by': {
      result = <CreatedBy data={data} />;
      break;
    }
    case 'rate-file-frequency': {
      result = <RateFileFrequency data={data} />;
      break;
    }
    case 'billCategories': {
      result = <NotesCategory value={get(data, name)} />;
      break;
    }
    case 'billChangeType': {
      if (get(data, name) === 'NEW_REQUEST') {
        result = 'New';
      } else if (get(data, name) === 'REMITTANCE_UPLOAD') {
        result = 'Remitted';
      } else if (get(data, name) === 'PROCESS_PAYMENT') {
        result = 'Paid';
      } else {
        result = 'Adjusted';
      }
      break;
    }
    case 'payroll-cycle-no': {
      result = get(data, 'payrollSchedule.cycleNo', 'NA');
      break;
    }
    default: {
      result = `#${type} missing`;
    }
  }
  return result;
};

export default Picklist;
