import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from '@reach/router';
import { get } from 'lodash';
import Box from '@basecomponents/Box';
import Route from '../../Route';
import GroupContacts from '../../GroupContacts';

/**
 * Used to add/edit group admins
 *
 * @category GroupAdmin
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const UpsertGroupAdministrators = ({ location, ...rest }) => {
  const params = useParams();
  const groupId = get(params, 'groupId', '');
  return (
    <Route
      header={{
        title: 'Edit Administrators',
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Add Group Administrator
      </Box>
      <GroupContacts
        location={location}
        redirectTo={`/groups/${groupId}/details`}
        {...rest}
      />
    </Route>
  );
};

UpsertGroupAdministrators.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default UpsertGroupAdministrators;
