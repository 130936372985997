export const groupNumberMask = [
  /[A-Z,a-z,0-9]/,
  /[A-Z,a-z,0-9]/,
  /[A-Z,a-z,0-9]/,
  /[A-Z,a-z,0-9]/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const legalIdentificationNumberMask = [
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const socialSecurityNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const writingNumberMask = [
  /[A-Z,a-z]/,
  /[A-Z,a-z]/,
  /[A-Z,a-z]/,
  /[A-Z,a-z]/,
];

export const phoneNumberMask = [
  '(',
  /[0-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const billingAccountNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const billingRoutingNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const transitNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const institutionNumberMask = [
  /\d/,
  /\d/,
  /\d/,
];

export const producerCodeMask = [
  /[A-Z,a-z,0-9]/,
  /[A-Z,a-z,0-9]/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const FSRAMask = [
  /[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/
];

export const SICCodeMask = [/\d/, /\d/, /\d/, /\d/];
export const NAICCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const employeeIDMask = [/[A-Z,a-z]/, /\d/, /\d/, /\d/, /\d/];

export const mmyyyyMask = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const businessNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
