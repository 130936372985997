import { Router } from '@reach/router';
import React from 'react';
import CommissionsDashboardPage from '@petcomponents/Commissions/DashboardPage';
import NotFoundPage from './404';

const Commissions = (props) => (
  <Router>
    <CommissionsDashboardPage {...props} path="/commissions/dashboard" />
    <NotFoundPage path="/404" />
  </Router>
);

export default Commissions;
