import React, { useRef, useState } from 'react';
import Box from '@basecomponents/Box';
import { get, isEmpty } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { refundAmount } from '@utils/validations';
import { SORT_VALUE } from '@utils/constants';
import { composeValidations, required } from '@utils/validators';
import { labelHandler } from '@utils/label-utils';
// import downloadData from '@utils/download-file';
import { useTranslation } from 'react-i18next';
import Route from '../Route';
import ListGrid from '../../BaseComponents/ListGrid';
import remoteActionQuery from '../../../graphql/queries/remote-action.gql';
import useSnackbar from '../../../utilities/use-snackbar';
import HeaderRightContainer from '../../BaseComponents/HeaderRightContainer';
import Modal from '../../BaseComponents/Modal';
import ToolbarButton from '../../BaseComponents/ToolbarButton';
import InputMoney from '../../BaseComponents/InputMoney';
import config from '../../../config.json';

/**
 * @category Billing
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const BillingDashboardPage = ({ ...rest }) => {
  const { t } = useTranslation()
  const dataTableRef = useRef();
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });
  const [groupPaymentRecordId, setGroupPaymentRecordId] = useState(null);
  const [refundGroupData, setRefundGroupData] = useState(null);

  const filters = [
    {
      name: 'groupName',
      type: 'Input',
    },
    {
      fieldEnd: 'billingEndDate',
      fieldStart: 'billingStartDate',
      label: t('metadata.billedDate'),
      name: 'billedDate',
      type: 'DatePicker',
    },
    {
      fieldEnd: 'remittanceEndDate',
      fieldStart: 'remittanceStartDate',
      label: t('metadata.remittanceDate'),
      name: 'remittanceDate',
      type: 'DatePicker',
    },
    {
      label: t('groups.billStatus'),
      name: 'status',
      options: [
        { canEnglishValue: null, frenchValue: 'Réglé', label: 'Remitted', value: 'REMITTED' },
        { canEnglishValue: null, frenchValue: 'Facturé', label: 'Billed', value: 'BILLED' },
        { canEnglishValue: null, frenchValue: 'Payé', label: 'Paid', value: 'PAID' },
        { canEnglishValue: null, frenchValue: 'Montant impayé', label: 'Unsettled Amount', value: 'UNSETTLED_AMOUNT' },
      ],
      type: 'Dropdown',
    },
    {
      label: t('filters.sortBy'),
      name: 'sort',
      options: SORT_VALUE.BILLING_DASHBOARD,
      type: 'Dropdown',
    },
  ];

  return (
    <ApolloConsumer>
      {(client) => {
        const reversePayment = async () => {
          client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'reverse-group-payment',
                params: JSON.stringify({ groupPaymentRecordId }),
              },
            })
            .then(() => {
              setSuccessSnack(
                `Payment successfully reversed`,
                config.notificationDuration
              );
              dataTableRef.current.refetchTableData();
              setGroupPaymentRecordId(null);
            })
            .catch((e) => {
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              );
              setGroupPaymentRecordId(null);
            });
        };
        const cleanMoney = (amount) =>
          parseFloat(String(amount).replace('$', '').replace(',', ''));
        const grid = {
          actions: [
            {
              getProps: (data) => {
                return {
                  buttonSx: {
                    marginRight: '0.375rem'
                  },
                  icon: 'details',
                  link: `/groups/${get(
                    data,
                    'groupId'
                  )}/details?groupPaymentRecordId=${get(
                    data,
                    'groupPaymentRecordId'
                  )}&payrollScheduleId=${get(data, 'payrollScheduleId')}`,
                };
              },
              label: 'View',
              name: 'view',
            },
            {
              getProps: (data) => {
                return {
                  icon: 'details',
                  isDisabled: get(data, 'status') !== 'PAID',
                  onClick: () =>
                    setGroupPaymentRecordId(get(data, 'groupPaymentRecordId')),
                  visible: false
                };
              },
              label: 'Reverse Payment',
              name: 'Reverse Payment',
            },
            // temp disabled
            // {
            //   getProps: (data) => {
            //     return {
            //       icon: 'download',
            //       onClick: async () => {
            //         await downloadData({
            //           s3BucketName: config.amplify.Storage.AWSS3.bucket,
            //           s3KeyName: get(data, 'reportUrl', ''),
            //         });
            //       },
            //       visible: get(data, 'reportUrl'),
            //     };
            //   },
            //   label: 'Download Invoice',
            //   name: 'download invoice',
            // },
            // {
            //   getProps: (data) => {
            //     return {
            //       icon: 'details',
            //       isDisabled:
            //         get(data, 'status') !== 'PAID' ||
            //         Number(get(data, 'unappliedFunds', 0)) <= 0,
            //       onClick: () => setRefundGroupData(data),
            //       permission: 'group.button.refundCredit',
            //     };
            //   },
            //   label: 'Refund Credit',
            //   name: 'Refund Credit',
            // },
          ],
          fields: [
            {
              name: 'payrollSchedule.group.groupName',
              sortable: true,
              sortName: 'groupNameSort',
            },
            {
              name: 'billedAmount',
              sortable: true,
            },
            {
              label: t('groups.billingCycle'),
              name: 'payrollSchedule.cycleNo',
              sortable: false,
            },
            {
              label: t('metadata.billedDate'),
              name: 'billedDate',
              sortable: true,
              sortDefault: 'desc',
              type: 'date',
            },
            {
              name: 'remittedAmount',
              sortable: true,
            },
            {
              label: t('metadata.remittanceDate'),
              name: 'remittanceDate',
              sortable: true,
              type: 'date',
            },
            {
              label: 'Amount Paid',
              name: 'confirmedAmount',
              sortable: true,
            },
            {
              label: 'Total Due Amount',
              name: 'totalAmountDue',
              sortable: true,
            },
            {
              name: 'generalCreditPaid',
              sortable: true,
            },
            // {
            //   name: 'creditValue',
            //   sortable: true,
            // },
            {
              name: 'unappliedFunds',
              sortable: false,
            },
            {
              name: 'receivableBalance',
              sortable: false,
            },
            {
              name: 'outstandingBalance',
              sortable: false,
            },
            {
              label: 'Group Credit Used',
              name: 'groupCreditShared',
              sortable: false,
            },
            {
              name: 'status',
            },
          ],
        };

        return (
          <Route
            header={{
              rightContainer: (
                <HeaderRightContainer
                  headerActions={[
                    {
                      action: '/billing/accountsBook',
                      icon: 'file',
                      label: 'Accounts Dashboard',
                    },
                    {
                      action: `/billing/agingReport`,
                      icon: 'file-description',
                      label: 'Aging Report',
                    },
                    {
                      action: `/billing/cashPremium`,
                      icon: 'file-description',
                      label: 'Cash Premium Report',
                    },
                    {
                      action: `/billing/petCashPremium`,
                      icon: 'file-description',
                      label: 'Pet Cash Premium Report',
                    },
                    {
                      action: `/billing/employeeCashPremium`,
                      icon: 'file-description',
                      label: 'Employee Cash Premium Report',
                    },
                    {
                      action: `/billing/terminatePetReport`,
                      icon: 'file-description',
                      label: 'Terminated Pet Report',
                    },
                    {
                      action: `/billing/commissionReport`,
                      icon: 'file-description',
                      label: 'Commission Report',
                    },
                    {
                      action: `/billing/masterCalendar`,
                      icon: 'file-description',
                      label: 'Master Calendar',
                    },
                    {
                      action: `/billing/masterCalendarReport`,
                      icon: 'file-description',
                      label: 'Master Billing Calendar Report',
                    },
                  ]}
                  {...rest}
                />
              ),
              title: 'Dashboard',
              type: 'billing',
            }}
            isPrivate
            permission="billing.page.dashboard"
            {...rest}
          >
            <Box aria-label="Billing Dashboard" as="h2" sx={{ py: 3 }}>
              Billing Dashboard
            </Box>
            <ListGrid
              ref={dataTableRef}
              filters={filters}
              grid={grid}
              gridQueryName="get-all-bills"
              moduleName="billing"
            />
            {groupPaymentRecordId !== null && (
              <Modal
                closeText={t('common.no')}
                isOpen={groupPaymentRecordId !== null}
                onClose={() => {
                  setGroupPaymentRecordId(null);
                }}
                onSubmit={() => {
                  reversePayment();
                }}
                submitText={t('common.yes')}
                title="Reverse Payment"
              >
                <Box>
                  By clicking yes, you are confirming payment reversal.
                  <Box
                    sx={{
                      display: 'flex',
                      fontWeight: 'bold',
                      justifyContent: 'center',
                      my: 5,
                    }}
                  >
                    THIS ACTION CAN NOT BE UNDONE.
                  </Box>
                  Do you wish to continue?
                </Box>
              </Modal>
            )}
            {!isEmpty(refundGroupData) && (
              <Modal
                isOpen
                onClose={() => {
                  setRefundGroupData(null);
                }}
                title="Refund Credit"
              >
                <FinalForm
                  initialValues={{}}
                  onSubmit={async (values) => {
                    const { amount } = values;
                    await client
                      .query({
                        fetchPolicy: 'no-cache',
                        query: remoteActionQuery,
                        variables: {
                          name: 'refund-group-credit',
                          params: JSON.stringify({
                            amount: cleanMoney(amount),
                            groupId: get(refundGroupData, 'groupId'),
                          }),
                        },
                      })
                      .then(() => {
                        setSuccessSnack(
                          'Refund credit successfully!',
                          config.notificationDuration
                        );
                        dataTableRef.current.refetchTableData();
                        setRefundGroupData(null);
                      })
                      .catch((e) => {
                        setErrorSnack(
                          `There was an error creating the user: ${e.message}`,
                          config.notificationDuration
                        );
                        setRefundGroupData(null);
                      });
                  }}
                  render={(formContext) => (
                    <form onSubmit={formContext.handleSubmit}>
                      <Box>
                        <Field
                          aria-label="Amount"
                          component={InputMoney}
                          label={labelHandler('Amount', false)}
                          name="amount"
                          validate={composeValidations(
                            required,
                            refundAmount(
                              get(refundGroupData, 'unappliedFunds', 0)
                            )
                          )}
                          wrapperSx={{ padding: 3, width: '25rem' }}
                          {...rest}
                        />
                        <Box>
                          Remaining Credit : $
                          {get(refundGroupData, 'unappliedFunds', 0)}
                        </Box>
                        <Box>
                          By clicking yes, you are confirming refund credit.
                          <Box
                            sx={{
                              display: 'flex',
                              fontWeight: 'bold',
                              justifyContent: 'center',
                              my: 5,
                            }}
                          >
                            THIS ACTION CAN NOT BE UNDONE.
                          </Box>
                          Do you wish to continue?
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            mt: '30px',
                          }}
                        >
                          <ToolbarButton
                            g="error"
                            label={t('common.cancel')}
                            onClick={() => {
                              setRefundGroupData(null);
                            }}
                            sx={{
                              bg: 'red',
                            }}
                            width="150px"
                          />
                          <ToolbarButton
                            g="error"
                            label="Yes"
                            submitting={formContext.submitting}
                            type="submit"
                            width="150px"
                          />
                        </Box>
                      </Box>
                    </form>
                  )}
                />
              </Modal>
            )}
          </Route>
        );
      }}
    </ApolloConsumer>
  );
};

export default BillingDashboardPage;
