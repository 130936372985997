import React, { useContext } from 'react';
import { Location } from '@reach/router';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Spinner from '../Spinner';

/**
 * @category BaseComponents
 * @param {string} defaultTab
 * @param {string} name
 * @param {Array<React.ReactNode>} slides
 * @param {Array<Object>} tabs
 * @param {boolean} loading
 * @returns {React.FC}
 */
const ProgressBar = ({ defaultTab, name, slides, tabs, loading }) => {
  const { t } = useTranslation();
  const { defaultStyle = {} } = useContext(ThemeContext);
  return (
    <>
      <Location>
        {({ location }) => {
          const queryParams = parse(location.search);
          const currentTab = get(queryParams, name, defaultTab);
          const {
            outerBox = {},
            innerBox = {},
            slideBox = {},
            stepBox = {},
          } = defaultStyle.defaultProgressBar(currentTab, slides);
          const currentTabDetails = tabs.find(
            (tab) => tab.value === currentTab
          );
          const getRenderContent = () => {
            if (loading) {
              return <Spinner />;
            }
            return currentTabDetails ? currentTabDetails.render() : null;
          };

          return (
            <>
              {
                <Box sx={{ ...outerBox }}>
                  <Box sx={{ ...innerBox }}>
                    <Box sx={{ ...slideBox }} />
                  </Box>
                </Box>
              }
              {slides.map(
                (slide, index) =>
                  index === Number(currentTab) && (
                    <Box key={`${slide}-${index}`} sx={{ ...stepBox }}>
                      {t('common.step')} {`${index + 1} of ${slides.length}`}
                    </Box>
                  )
              )}
              {getRenderContent()}
            </>
          );
        }}
      </Location>
    </>
  );
};

ProgressBar.propTypes = {
  defaultTab: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

ProgressBar.defaultProps = {
  defaultTab: null,
  loading: false,
};
export default ProgressBar;
