import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { navigate } from 'gatsby';
import Box from '@basecomponents/Box';
import { useApolloClient } from '@apollo/client';
import { useParams } from '@reach/router';
import remoteActionQuery from '@src/graphql/queries/remote-action.gql';
import getS3Url from '@src/utilities/get-s3urls';
import Spinner from '@basecomponents/Spinner';
import useSnackbar from '@src/utilities/use-snackbar';
import GetMasterPolicyS3 from '@petcomponents/GeMasterPolicyS3';
import ReactResizeDetector from 'react-resize-detector';
import ToolbarButton from '@basecomponents/ToolbarButton';
import config from '@src/config.json';
import GetData from '@utils/get-data';

const MasterPolicyPdfViewer = () => {
  const queryParams = useParams();
  const client = useApolloClient();
  const groupId = get(queryParams, 'groupId', '');
  const [isLoading, setIsLoading] = useState(true);
  const [pdfData, setpdfData] = useState(null);
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [width, setWidth] = useState(600);
  const { apiData: { content = [] } = {}, loading } = GetData(
    'get-files',
    JSON.stringify({
      groupId,
      isEducational: false,
      page: 0,
      size: 100,
    })
  );
  useEffect(() => {
    if (!loading) {
      (async () => {
        try {
          const apiCall = (pathName, params) =>
            client.query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: pathName,
                params: JSON.stringify(params),
              },
            });
          apiCall('generate-master-policy', { groupId })
            .then(async (data) => {
              const remoteActionData = JSON.parse(
                get(data, 'data.remoteAction.data', JSON.stringify({}))
              );
              const getPdfWithS3url = await Promise.all(
                remoteActionData?.map(async (item, index) => ({
                  pdfType: get(item, 'masterPolicyPath', '')
                    .split('/')
                    .pop()
                    .split('-master-policy.pdf')
                    .shift()
                    .replace(/-/g, ' '),
                  planId: get(item, 'planId', index),
                  s3KeyName: await getS3Url({
                    s3KeyName: get(item, 'masterPolicyPath', ''),
                  }),
                }))
              );
              if (
                content.length === 0 ||
                (!get(content, '0.s3Key', '').includes('master-policy.pdf') &&
                  !loading)
              ) {
                await Promise.all(
                  remoteActionData?.map(async (item, index) => {
                    const fileName = get(item, 'masterPolicyPath', '')
                      .split('/')
                      .pop()
                      .split('-master-policy.pdf')
                      .shift()
                      .replace(/-/g, ' ');
                    const params = JSON.stringify({
                      groupId,
                      name: fileName.replace(/_/g, ' '),
                      planId: get(item, 'planId', index),
                      s3Key: get(item, 'masterPolicyPath', ''),
                    });
                    await apiCall('set-file', params);
                  })
                );
              }
              setpdfData(getPdfWithS3url);
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        } catch (err) {
          setErrorSnack(err.message);
        } finally {
          setTimeout(() => setIsLoading(false), 3000);
        }
      })();
    }
  }, [loading]);

  const onResize = (resizedWidth) => {
    setWidth(resizedWidth);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
        margin: '0 auto',
        width: '50%',
      }}
    >
      <ReactResizeDetector
        handleWidth
        onResize={onResize}
        refreshMode="debounce"
      />
      {isLoading || loading ? (
        <Spinner />
      ) : (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {pdfData?.map((pdf, index) => {
            const file = get(pdf, 's3KeyName', null);
            return (
              <Box
                key={`${get(pdf, 'planId', '')}-${index}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 5,
                }}
              >
                <GetMasterPolicyS3
                  file={file}
                  pdfType={get(pdf, 'pdfType', '')}
                  width={width}
                />
              </Box>
            );
          })}
          <ToolbarButton
            aria-label="Done"
            bg="primaryDark"
            isDisabled={isLoading || loading}
            label="Done"
            mr={0}
            onClick={() =>
              navigate(`/groups/${get(queryParams, 'groupId')}/details`)
            }
            submitting={isLoading || loading}
            width="10rem"
          />
        </Box>
      )}
    </Box>
  );
};

export default MasterPolicyPdfViewer;
