import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Box from '@basecomponents/Box';
import Table from '../../Table';
import { CELL_DENSISTIS, LS_CELL_DENSITY, TOOLTIP } from '../utils/constants';
import ToolbarButton from '../../ToolbarButton';
import Variant from '../../../Pet/Variant';
import localStorage from '../../../../utilities/local-storage';
import TooltipBox from '../../TooltipBox';

const getTableData = (data, dataField) => {
  if (dataField) {
    const arr = get(data, dataField);
    return Array.isArray(arr) ? arr : [];
  }
  return Array.isArray(data) ? data : [];
};

/**
 * @category BaseComponents
 * @param {Array<Object>} data
 * @param {string} dataField
 * @param {Function} dispatch
 * @param {Function} getExpandedSection
 * @param {Array<Object>} grid
 * @param {Object} gridState
 * @param {Object} meta
 * @param {Function} refetch
 * @returns {React.FC}
 */
const TableSection = ({
  data,
  dataField,
  dispatch,
  getExpandedSection,
  grid,
  gridState: { cellDensity, sortDirection, sortField },
  meta,
  refetch,
}) => {
  const lsCellDensity = localStorage.getItem(LS_CELL_DENSITY);
  return (
    <>
      <Table
        cellSx={{
          py: get(
            CELL_DENSISTIS.find(({ id }) =>
              cellDensity ? id === cellDensity : id === lsCellDensity
            ),
            'py',
            5
          ),
        }}
        getExpandedSection={getExpandedSection}
        header={get(grid, 'fields', [])
          .filter((field) => get(field, 'visible', true))
          .map(({ label, name, sortable, sortName }) => {
            const headerLabel = label || get(meta, `${name}.label`, `#${name}`);
            if (name === '_thisRow') {
              return get(meta, `${dataField}.label`);
            }
            if (sortable) {
              return {
                label: headerLabel,
                sortName,
                value: name,
              };
            }
            return headerLabel;
          })
          .concat(get(grid, 'actions', []).length > 0 ? ['Actions'] : [])}
        onSortUpdate={(sortBy, orderBy) =>
          dispatch({
            params: {
              sortDirection: orderBy,
              sortField: sortBy,
            },
            type: 'onSortChange',
          })
        }
        orderBy={sortDirection}
        rows={getTableData(data, dataField).map((rowData, rowIx) => {
          const rowUIData = get(grid, 'fields', [])
            .filter((field) => get(field, 'visible', true))
            .map(({ name, ...gridMeta }) =>
              name === '_thisRow' ? (
                <Variant
                  key={`row-${rowIx}-field-thisRow`}
                  data={{ thisRow: rowData }}
                  meta={{ thisRow: { type: get(meta, `${dataField}.type`) } }}
                  name="thisRow"
                />
              ) : (
                <Variant
                  key={`row-${rowIx}-field-${name}`}
                  data={rowData}
                  gridMeta={gridMeta}
                  meta={meta}
                  name={name}
                />
              )
            );
          const rowActions = get(grid, 'actions', []);
          if (rowActions.length > 0) {
            const actionUI = [];
            rowActions.forEach((action, ix) => {
              const { getProps, label, permission } = action;
              const buttonProps = getProps(rowData, refetch);
              actionUI.push(
                <Box
                  key={`row-${rowIx}-action-${ix}`}
                  data-for={TOOLTIP}
                  data-tip={label}
                  mx={
                    (ix === 0 || ix === rowActions.length - 1) &&
                    rowActions.length !== 2
                      ? 0
                      : 2
                  }
                >
                  <ToolbarButton
                    bg={ix % 2 === 0 ? 'primary' : 'accentSecondary'}
                    {...buttonProps}
                    permission={permission}
                  />
                </Box>
              );
            });
            rowUIData.push(
              <Box alignItems="center" display="flex">
                {actionUI}
              </Box>
            );
          }

          return rowUIData;
        })}
        sortBy={sortField}
      />
      <TooltipBox
        key="ts"
        id={TOOLTIP}
        tooltipProps={{ effect: 'solid' }}
        tooltipSx={{ bg: 'accent' }}
      />
    </>
  );
};

TableSection.defaultProps = {
  data: [],
  dataField: '',
  dispatch: () => null,
  getExpandedSection: null,
  grid: {
    actions: [],
    expandedActions: [],
    fields: [],
  },
  gridState: {},
  meta: {},
};

TableSection.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  dataField: PropTypes.string,
  dispatch: PropTypes.func,
  getExpandedSection: PropTypes.func,
  grid: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  gridState: PropTypes.shape({
    cellDensity: PropTypes.string,
    sortDirection: PropTypes.string,
    sortField: PropTypes.string,
  }),
  meta: PropTypes.shape({}),
  refetch: PropTypes.func.isRequired,
};

export default TableSection;
