import { Field } from 'react-final-form';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { required } from '../../../../../utilities/validators';
import InputText from '../../../../BaseComponents/Input';
import SingleDatePicker from '../../../../BaseComponents/SingleDatePicker';
import { labelHandler } from '../../../../../utilities/label-utils';
import dateUtils from '../../../../../utilities/date-utils';

/**
 * @category Claims
 * @param {string} label Indicates the label for the form fields
 * @param {object} wrapperSx Inidcates the Styling for form field
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const MedicalVisit = ({ relativeDatetime, label, wrapperSx, ...rest }) => {
  const { t } = useTranslation()
  const currentDate =
    relativeDatetime === null
      ? new Date()
      : dateUtils.setOffset2(relativeDatetime);
  return (
    <>
      <Field
        aria-label={t('claimsScreen.upsertClaimPage.tellUsMoreInjury')}
        component={InputText}
        label={labelHandler(t('claimsScreen.upsertClaimPage.tellUsMoreInjury'), true)}
        name={`${label}.diagnosis`}
        validate={required}
        wrapperSx={{ ...wrapperSx, mt: 0 }}
        {...rest}
      />
      <Field
        aria-label={t('claimsScreen.upsertClaimPage.firstNotice')}
        component={SingleDatePicker}
        label={labelHandler(
          t('claimsScreen.upsertClaimPage.firstNotice'),
          true
        )}
        maxDate={currentDate}
        name={`${label}.onsetDate`}
        validate={required}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Field
        aria-label={t('claimsScreen.upsertClaimPage.treatmentStartDate')}
        component={SingleDatePicker}
        label={labelHandler(t('claimsScreen.upsertClaimPage.treatmentStartDate'), true)}
        maxDate={currentDate}
        name={`${label}.treatmentStartDate`}
        validate={required}
        wrapperSx={wrapperSx}
        {...rest}
      />
    </>
  );
};
export default MedicalVisit;
MedicalVisit.propTypes = {
  label: PropTypes.string.isRequired,
  relativeDatetime: PropTypes.string,
  wrapperSx: PropTypes.shape({}),
};

MedicalVisit.defaultProps = {
  relativeDatetime: null,
  wrapperSx: {},
};
