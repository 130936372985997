import { useParams } from '@reach/router';
import { get } from 'lodash';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import Dropdown from '@basecomponents/Dropdown';
import React, { useContext, useState } from 'react';
import { AuthContext } from '@basecomponents/Auth';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import { required } from '@utils/validators';
import { labelHandler } from '@utils/label-utils';
import { useTranslation } from 'react-i18next';
import Modal from '@basecomponents/Modal';
import Tabs from '@basecomponents/Tabs';
import InputText from '@basecomponents/Input';
import KeyValuePairs from '@basecomponents/KeyValuePairs';
import Card from '@basecomponents/Card';
import ToolbarButton from '@basecomponents/ToolbarButton';
import HeaderRightContainer from '@basecomponents/HeaderRightContainer';
import SingleDatePicker from '@basecomponents/SingleDatePicker';
import GetData from '@utils/get-data';
import getMetaData from '@utils/get-meta-data';
import useSnackbar from '@utils/use-snackbar';
import dateUtils from '@utils/date-utils';
import remoteActionQuery from '@queries/remote-action.gql';
import config from '@src/config.json';
import Route from '../../Route';
import EmployeeDetailPage from './EmployeeSection';
import PetDetailPage from './PetSection';
import CoverageDetailPage from './CoverageSection';
import HistorySection from './HistorySection';
import NotesSection from './NotesSection';
import UploadedDocumentsSection from '../../UploadedDocumentsSection';
import EditAccessRightsContext from '../../EditAccessRights/Context';

/**
 * @category QLE
 * @param {*} rest
 * @returns {React.FC}
 */
const LifeEventsDetailPage = ({ ...rest }) => {
  const theme = useContext(ThemeContext);
  const queryParams = useParams();
  const qleEventId = get(queryParams, 'qleEventId', '');
  const {
    apiData = {},
    loading,
    refetch,
  } = GetData('get-qle-event-by-id', JSON.stringify({ id: qleEventId }));

  const groupId = apiData?.groupId

  const { apiData: groupData } = GetData(
    'get-group-by-id',
    JSON.stringify({
      id: groupId
    }),
    groupId == null
  )
  const {
    apiData: { DeclinedReasonType = [], QleType = [] },
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: ['DeclinedReasonType', 'QleType'] }])
  );

  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const [showModal, setShowModal] = useState(false);
  const [showAcceptModal, setAcceptModal] = useState(false);

  const employeeId = get(apiData, 'employeeId', '');
  const created = get(apiData, 'created', '');

  const meta = getMetaData({ moduleName: 'life-events' });
  const documentsDataList = apiData?.supportingDocs || [];
  const ear = useContext(EditAccessRightsContext);
  const { t } = useTranslation()

  const { apiData: coverageData } = GetData(
    'get-coverage-info',
    JSON.stringify({
      employeeId,
    }),
    employeeId === null
  );

  const combApiDataCoverageData = { ...apiData, ...coverageData };
  if (groupData?.enrollmentDoe === false) {
    combApiDataCoverageData.doeType = "N/A"
  }
  const isQLEAdditionType = apiData?.qleType === "NEW_PET" || apiData?.qleType === "LATE_ENROLLMENT";
  if (isQLEAdditionType && groupData?.enrollmentDoe === true) {
    combApiDataCoverageData.doeType = "START"
  }
  const isQLERemovalType = apiData?.qleType === "PET_TERMINATION" || apiData?.qleType === "EMPLOYEE_TERMINATION";
  if (isQLERemovalType && groupData?.terminationDoe === true) {
    combApiDataCoverageData.doeType = "TERM"
  }

  const fields = [
    { name: 'qleNumber' },
    { name: 'groupName' },
    { name: 'requestedDate' },
    { name: 'acquisitionDate' },
    { name: 'certificateNumber' },
    { name: 'qleType' },
    { name: 'reasonType' },
    { name: 'assignedTo' },
    { name: 'requestStatus' },
    { name: 'lastModified' },
    { name: 'user.email' },
    { name: 'doeType' },
  ];

  const tabs = [
    {
      label: t('lifeEvents.details.employee'),
      render: () => <EmployeeDetailPage employeeId={employeeId} {...rest} />,
    },
    {
      label: t('lifeEvents.details.coverage'),
      render: () => <CoverageDetailPage employeeId={employeeId} {...rest} />,
    },
    {
      label: t('lifeEvents.details.documents'),
      render: () => (
        <UploadedDocumentsSection
          documentsList={
            Array.isArray(documentsDataList) &&
            documentsDataList.map((file) => {
              return {
                attachmentName: get(file, 'documentName'),
                s3Key: get(file, 's3KeyName', null),
              };
            })
          }
          setSignUrl
          {...rest}
        />
      ),
    },
  ];

  if (get(apiData, 'qleType') === get(QleType, '1.value')) {
    fields.push({ name: 'transactionDate' });
  }
  if (get(apiData, 'qleType') === 'PET_TERMINATION') {
    fields.push({ name: 'transactionDate' });
  }

  if (get(apiData, 'qleType') !== 'EMPLOYEE_TERMINATION') {
    tabs.push({
      label: t('lifeEvents.details.pet'),
      render: () => (
        <PetDetailPage
          dependentData={get(apiData, 'dependentData', []).filter(
            (dependentData) => !dependentData.deleted
          )}
          loading={loading}
          {...rest}
        />
      ),
    });
  }

  return (
    <ApolloConsumer>
      {(client) => {
        const updateQleCall = async (
          requestStatus,
          reason,
          declinedReasonType,
          transactionDate
        ) => {
          setShowModal(false);
          setAcceptModal(false);
          const apiDate = dateUtils.setAPIDateOnly(transactionDate);
          client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'update-qle',
                params: JSON.stringify({
                  declinedReasonType,
                  qleEventId,
                  ...reason,
                  requestStatus,
                  transactionDate: apiDate,
                  userId,
                }),
              },
            })
            .then(async () => {
              const message =
                requestStatus === 'DECLINED' ? t('snack.error.qleDeclined') : t('snack.success.qleApproved');
              if (
                requestStatus === 'DECLINED' ||
                requestStatus === 'REVIEWED'
              ) {
                await refetch();
              }
              setSucessSnack(message, config.notificationDuration);
            })
            .catch((e) => {
              setShowModal(false);
              setAcceptModal(false);
              return setErrorSnack(
                t('snack.error.errorWithMessage', { message: e.message }),
                config.notificationDuration
              );
            });
        };
        return (
          <Route
            header={{
              rightContainer: !['REVIEWED', 'DECLINED', 'UNASSIGNED'].includes(
                get(apiData, 'requestStatus', null)
              ) ? (
                <HeaderRightContainer
                  headerActions={[
                    {
                      action: () => setAcceptModal(true),
                      icon: 'check',
                      isDisabled: get(apiData, 'assignedTo') !== userId,
                      label: t('lifeEvents.details.approve'),
                      permission: 'lifeEvent.button.accept',
                    },
                    {
                      action: `/life-events/${qleEventId}/add-documents`,
                      icon: 'add',
                      isDisabled: get(apiData, 'assignedTo') !== userId,
                      label: t('lifeEvents.details.addDocuments'),
                      permission: 'lifeEvent.button.upload',
                    },
                    {
                      action: () => setShowModal(true),
                      icon: 'close',
                      isDisabled: get(apiData, 'assignedTo') !== userId,
                      label: t('lifeEvents.details.decline'),
                      permission: 'lifeEvent.button.decline',
                    },
                  ]}
                  menuZIndex={3}
                  {...rest}
                />
              ) : null,
              title: get(apiData, 'qleNumber', qleEventId),
              type: 'life-events',
            }}
            isPrivate
            {...rest}
          >
            <Box as="h2" sx={{ py: 3 }}>
              {loading ? (
                <PulseLoader color={get(theme, 'colors.accent')} size={5} />
              ) : (
                t('lifeEvents.details.title', { qle: get(apiData, 'qleNumber', qleEventId) })
              )}
            </Box>
            <Card loading={loading}>
              <KeyValuePairs
                data={combApiDataCoverageData}
                fields={fields}
                meta={meta}
              />
            </Card>
            {showModal === true && (
              <Modal isOpen title={t('lifeEvents.details.wantToDecline')}>
                <FinalForm
                  initialValues={{}}
                  onSubmit={async (values) => {
                    const params = {
                      declinedReasonType: values.declinedReasonType,
                      ...values,
                    };
                    updateQleCall('DECLINED', params);
                  }}
                  render={({ values, submitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Box>
                        <Field
                          aria-label={t('lifeEvents.details.declinationReason')}
                          component={Dropdown}
                          label={t('lifeEvents.details.declinationReason')}
                          name="declinedReasonType"
                          options={DeclinedReasonType}
                          validate={required}
                          {...rest}
                        />
                        {get(values, 'declinedReasonType', '') === 'OTHER' ? (
                          <>
                            <Field
                              aria-label={t('lifeEvents.details.reason')}
                              component={InputText}
                              label={t('lifeEvents.details.reason')}
                              name="reason"
                              validate={required}
                              {...rest}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                mt: '30px',
                                width: '100%',
                              }}
                            >
                              <ToolbarButton
                                g="error"
                                label={t('common.cancel')}
                                onClick={() => setShowModal(false)}
                                sx={{
                                  bg: 'error',
                                }}
                                width="150px"
                              />
                              <ToolbarButton
                                g="error"
                                label={t('common.submit')}
                                submitting={submitting}
                                type="submit"
                                width="150px"
                              />
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                mt: '30px',
                                width: '100%',
                              }}
                            >
                              <ToolbarButton
                                g="error"
                                label={t('common.cancel')}
                                onClick={() => setShowModal(false)}
                                sx={{
                                  bg: 'error',
                                }}
                                width="150px"
                              />
                              <ToolbarButton
                                g="error"
                                label={t('common.submit')}
                                submitting={submitting}
                                type="submit"
                                width="150px"
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                    </form>
                  )}
                />
              </Modal>
            )}
            {showAcceptModal === true && (
              <Modal
                footerSx={{
                  p: 0,
                }}
                isOpen
                title={t('lifeEvents.details.updateTransactionDate')}
              >
                <FinalForm
                  initialValues={{}}
                  onSubmit={async (values) => {
                    const apiCall = (pathName, params) => client.query({
                      fetchPolicy: 'no-cache',
                      query: remoteActionQuery,
                      variables: {
                        name: pathName,
                        params: JSON.stringify(params),
                      },
                    });
                    if (get(apiData, 'qleType') === 'EMPLOYEE_TERMINATION' || get(apiData, 'qleType') === 'PET_TERMINATION') {
                      const transactionDate = get(
                        values,
                        'transactionDate'
                      );
                      try {
                        await apiCall(
                        "get-write-off-used",
                        {
                          employeeId
                        }
                      ).then((response) => {
                        const writeOffResponse = JSON.parse(
                          get(response, 'data.remoteAction.data', '')
                        );
                        if (writeOffResponse === 0) {
                    updateQleCall(
                      'REVIEWED',
                      null,
                      null,
                      transactionDate
                    );
                  }
                      })
                     } catch (error) {
                      setAcceptModal({});
                      setErrorSnack(
                        `${error}`,
                        config.notificationDuration
                      );
                      }
                    } else {
                      const transactionDate = await get(
                        values,
                        'transactionDate'
                      );
                      await updateQleCall(
                        'REVIEWED',
                        null,
                        null,
                        transactionDate
                      );
                    }
                  }}
                  render={({ submitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Box>
                          {t('lifeEvents.details.updateTransactionLegend')}
                        </Box>
                        <Field
                          aria-label={t('lifeEvents.details.transactionDate')}
                          component={SingleDatePicker}
                          label={labelHandler(t('lifeEvents.details.transactionDate'))}
                          maxDate={dateUtils.setOffset2(
                            get(coverageData, 'coverages[0].coverageToDate')
                          )}
                          name="transactionDate"
                          wrapperSx={{
                            padding: 3,
                            width: '25rem',
                          }}
                          {...rest}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            mt: '30px',
                            width: '100%',
                          }}
                        >
                          <ToolbarButton
                            g="error"
                            label={t('common.cancel')}
                            onClick={() => setAcceptModal(false)}
                            sx={{
                              bg: 'error',
                            }}
                            width="150px"
                          />
                          <ToolbarButton
                            g="error"
                            label={t('common.submit')}
                            submitting={submitting}
                            type="submit"
                            width="150px"
                          />
                        </Box>
                      </Box>
                    </form>
                  )}
                />
              </Modal>
            )}
            <Tabs loading={loading} tabs={tabs} />
            {ear.isVisible('lifeEvent.forms.comment') && (
              <Card>
                <Box
                  sx={{
                    color: 'primary',
                    fontSize: 3,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('lifeEvents.details.comments')}
                </Box>
                <NotesSection qleEventId={qleEventId} {...rest} />
              </Card>
            )}
            <Card loading={loading}>
              <HistorySection
                created={created}
                qleEventId={qleEventId}
                {...rest}
              />
            </Card>
          </Route>
        );
      }}
    </ApolloConsumer>
  );
};

LifeEventsDetailPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default LifeEventsDetailPage;
