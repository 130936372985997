import React from 'react';
import Box from '@basecomponents/Box';
import { useTranslation } from 'react-i18next';
import GLBANoticeDocument from '../../assets/documents/IAIC_GLBA_Notice_2.2.21.pdf';
import PDFViewer from '../../components/Pet/PDFViewer';
import Route from '../../components/Pet/Route';

const GLBANotice = ({ ...rest }) => {
  const { t } = useTranslation()
  return (
    <Route
      header={{
        icon: 'dashboard',
        title: t('footer.footerLinks.glbaNoticeName'),
        typeLabel: 'Legal',
      }}
      isPrivate
      {...rest}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <PDFViewer file={GLBANoticeDocument} />
      </Box>
    </Route>
  );
};

export default GLBANotice;
