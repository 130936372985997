import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Box from '@basecomponents/Box';
import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';
import { useMutation } from '@apollo/client';
import FormField from '../FormField';
import Toolbar from '../Toolbar';
import mutationRemoteAction from '../../../../../graphql/mutations/mutation-remote-action.gql';
import GetData from '../../../../../utilities/get-data';
import FormHeader from '../FormHeader';
import CustomFormField from '../CustomFormField';

/**
 * @category Components
 * @param {boolean} active
 * @param {Function} dispatch
 * @param {Array<Object>} fields Indicates the form fields
 * @param {object} stateValues
 * @param {string} mutationName Indicates the name of the mutation query
 * @param {string} queryName Indicates the name of the query
 * @param {string} sectionName Indicates the name of the section
 * @param {object} sectionOperations
 * @param {object} sectionOrder
 * @param {string} sectionTitle Indicates the title of the section
 * @param {string} userId Indicates the unique user ID
 * @returns {React.FC}
 */
const DataForm = ({
  active,
  dispatch,
  fields,
  stateValues,
  mutationName,
  queryName,
  sectionName,
  sectionOperations,
  sectionOrder,
  sectionTitle,
  userId,
}) => {
  const [updateObject] = useMutation(mutationRemoteAction);
  const { apiData, loading } = GetData(
    queryName,
    JSON.stringify({
      section: sectionName,
      stateValues,
    }),
    !active
  );
  return (
    <>
      <FormHeader active={active} loading={loading} title={sectionTitle} />
      {active && !loading && (
        <Form
          initialValues={apiData}
          keepDirtyOnReinitialize
          onSubmit={async (values) => {
            try {
              const mutationResult = await updateObject({
                variables: {
                  name: mutationName,
                  params: JSON.stringify({
                    section: sectionName,
                    stateValues,
                    values: { ...values, userId },
                  }),
                },
              });
              const result = JSON.parse(
                get(mutationResult, 'data.mutationRemoteAction.data', '{}')
              );
              dispatch({ params: result, type: 'onNext' });
            } catch (e) {
              return { [FORM_ERROR]: e.message };
            }
          }}
          render={({
            handleSubmit,
            modified,
            submitting,
            submitError,
            values,
          }) => {
            const fieldValues = {
              currentValues: values,
              stateValues,
            };
            return (
              <Box>
                <Box fontSize={5} fontWeight="bold" p={4}>
                  {sectionTitle}
                </Box>
                <Box
                  sx={{
                    borderTopColor: 'accent',
                    borderTopStyle: 'solid',
                    borderTopWidth: 1,
                    transition: 'all 0.25s ease-in-out',
                  }}
                >
                  <Box alignItems="flex-start" display="flex" flexWrap="wrap">
                    {fields.map(
                      ({
                        isDisabled,
                        isVisible,
                        mask,
                        label,
                        maxLength,
                        name,
                        options,
                        render,
                        type,
                        validate,
                      }) => {
                        return (
                          <Box key={`field-${name}`} p={4} width="25%">
                            {type !== 'Custom' ? (
                              <FormField
                                disabled={
                                  isDisabled ? isDisabled(fieldValues) : false
                                }
                                label={label}
                                mask={mask}
                                maxLength={maxLength}
                                name={name}
                                options={options}
                                type={type}
                                validate={validate}
                                value={get(values, name)}
                                visible={
                                  isVisible ? isVisible(fieldValues) : true
                                }
                              />
                            ) : (
                              <CustomFormField
                                fieldValues={fieldValues}
                                render={render}
                              />
                            )}
                          </Box>
                        );
                      }
                    )}
                  </Box>
                  <Toolbar
                    dispatch={dispatch}
                    handleSubmit={handleSubmit}
                    modified={modified}
                    sectionOperations={sectionOperations}
                    sectionOrder={sectionOrder}
                    stateValues={stateValues}
                    submitError={submitError}
                    submitting={submitting}
                    values={values}
                  />
                </Box>
              </Box>
            );
          }}
        />
      )}
    </>
  );
};

DataForm.defaultProps = {
  fields: [],
  sectionOperations: {},
  sectionOrder: 'middle',
  sectionTitle: '',
  stateValues: {},
  userId: '',
};

DataForm.propTypes = {
  active: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  mutationName: PropTypes.string.isRequired,
  queryName: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired,
  sectionOperations: PropTypes.shape({}),
  sectionOrder: PropTypes.string,
  sectionTitle: PropTypes.string,
  stateValues: PropTypes.shape({}),
  userId: PropTypes.string,
};

export default DataForm;
