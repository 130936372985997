import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import { formLabelSx } from '../../DataFormPage/utils/constants';

/**
 * @category Group
 * @param {Object} currentValues
 * @returns {React.FC}
 */
const BillingInfoSitusAddress = ({ currentValues }) => {
  const useSitusAddress = get(currentValues, 'useSitusAddress');
  const situsAddress = get(currentValues, 'situsAddress');
  return (
    <>
      <Box sx={formLabelSx}>Situs Address</Box>
      <Box color={useSitusAddress ? 'primary' : 'accent'}>
        <Box>{get(situsAddress, 'addressLine1')}</Box>
        <Box>{get(situsAddress, 'addressLine2')}</Box>
        <Box>
          {`${get(situsAddress, 'city')}, ${get(situsAddress, 'state')}, ${get(
            situsAddress,
            'zipcode'
          )}`}
        </Box>
      </Box>
    </>
  );
};

BillingInfoSitusAddress.propTypes = {
  currentValues: PropTypes.shape({}).isRequired,
};

export default BillingInfoSitusAddress;
