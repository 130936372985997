import get from 'lodash/get';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import {useTranslation} from "react-i18next";
import GetData from '../../../../utilities/get-data';

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const QleType = ({ value }) => {
  const { colors } = useContext(ThemeContext);
  const { i18n } = useTranslation();
  const data = [
    GetData('get-enum-options', JSON.stringify([{ params: 'QleType' }])),
  ];
  if (get(data, '0.loading', false)) {
    return <PulseLoader color={`${colors.accentSecondary}`} size={7} />;
  }
  const type = get(data, '0.apiData.QleType', []);
  const qleType = type.find((item) => item.value === value);
  // eslint-disable-next-line no-nested-ternary
  return qleType ?  (i18n.language === 'fr' && get(qleType, 'frenchValue', '') !== '') ? get(qleType, 'frenchValue') : get(qleType, 'label') : value;
};

QleType.propTypes = {
  value: PropTypes.string.isRequired,
};

export default QleType;
