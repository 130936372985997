import { Router } from '@reach/router';
import React from 'react';
import BenAdminDashboardPage from '@petcomponents/BenAdmin/Dashboard';
import UpsertBenAdmin from '@petcomponents/BenAdmin/UpsertBenAdminPage';
import BenAdminDetailPage from '@petcomponents/BenAdmin/DetailPage';
import UserUpsertPage from '@petcomponents/Users/UpsertPage';
import NotFoundPage from './404';

const BenAdmin = (props) => (
  <Router>
    <BenAdminDashboardPage {...props} path="/ben-admin/dashboard" />
    <UpsertBenAdmin {...props} path="/ben-admin/create" type="upsertBenAdmin" />
    <UpsertBenAdmin
      {...props}
      path="/ben-admin/:benAdminId/edit"
      type="upsertBenAdmin"
    />
    <BenAdminDetailPage {...props} path="/ben-admin/:benAdminId/details" />
    <UserUpsertPage {...props} path="/ben-admin/:benAdminId/user-create" />
    <NotFoundPage default path="/404" />
  </Router>
);

export default BenAdmin;
