import { navigate } from '@reach/router';
import Box from '@basecomponents/Box';
import Button from '@basecomponents/Button';
import Input from '@basecomponents/Input';
import {
  composeValidations,
  email,
  minLength,
  required,
} from '@utils/validators';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Field, Form as FinalForm, Form } from 'react-final-form';
import styled, { ThemeContext } from 'styled-components';
import InputPassword from '@basecomponents/InputPassword';
import updateLastLogin from '@src/utilities/ihc/updateLastLogin';
import EmailSentModal from '@basecomponents/EmailSentModal';
import useWindowSize from '@utils/hooks/windowSize';
import { useTranslation } from 'react-i18next';
import { OnChange } from 'react-final-form-listeners';
import Dropdown from '@basecomponents/Dropdown';
import i18n from '@src/utilities/i18n';
import Logo from '../../components/Pet/Logo';
import ToolbarButton from '../../components/BaseComponents/ToolbarButton';
import config from '../../config.json';
import AuthContainer from '../../containers/AuthContainer';
import pixelToNumber from '../../utilities/pixel-to-number';
import useSnackbar from '../../utilities/use-snackbar';

const HoverableButton = styled(Button)`
  transition: 0.25s ease-in-out;

  &:hover {
    transition: 0.25s ease-in-out;
    background-color: ${(p) => p.theme.colors.accentLight};
  }
`;

const PasswordResetPage = ({ location }) => {
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [wasResetCodeSent, setWasResetCodeSent] = useState(false);
  const [isSendingPasscode, setIsSendingPasscode] = useState(false);
  const [modal, setModal] = useState(false);
  const { breakpoints, shadows } = useContext(ThemeContext);
  const { username } = location?.state;
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const selectedLang = localStorage.getItem('lang');

  const backgroundSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        backgroundImage: `url(${config.promoImageUrl})`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '95%',
      };
    }
    return {};
  };

  const boxShadowSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        boxShadow: shadows[4],
      };
    }
    return {};
  };

  const sendResetCode = async (username) => {
    await Auth.forgotPassword(username)
      .then(() => {
        setModal(
          t('addEmail.verificationCodeSentToEmail', { email: username })
        );
        setWasResetCodeSent(true);
      })
      .catch((error) => {
        setWasResetCodeSent(false);
        if (error.code === 'NotAuthorizedException') {
          setErrorSnack(
            t('reset.initialPasswordExpired'),
            config.notificationDuration
          );
        } else {
          setErrorSnack(
            t('snack.error.errorWithMessage', { message: error.message }),
            config.notificationDuration
          );
        }
      });
    setIsSendingPasscode(false);
  };

  return (
    <AuthContainer>
      {() => (
        <Box
          bg={{ _: 'primary', lg: 'white' }}
          display="flex"
          flexDirection={{ _: 'column', lg: 'row' }}
          height="100vh"
          width="100%"
        >
          <EmailSentModal modal={modal} setModal={setModal} />
          <Box
            bg={{ _: 'primary', lg: 'white' }}
            display="flex"
            height={{ lg: '100%' }}
            justifyContent={{ _: 'center', lg: 'flex-start' }}
            sx={{ ...backgroundSx() }}
            width={{ lg: '50%' }}
          >
            {width >= pixelToNumber(breakpoints[3]) ? (
              <Logo
                marginLeft={{ lg: 5 }}
                mono={width < pixelToNumber(breakpoints[3])}
                sx={{
                  mt: '30px',
                  top: '0',
                }}
              />
            ) : (
              <Logo
                mono
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: '50px',
                  p: 1,
                  svg: {
                    height: 'auto',
                  },
                  width: '20rem',
                }}
              />
            )}
            {width >= pixelToNumber(breakpoints[3]) && (
              <Box
                as="h1"
                color="primary"
                fontSize={7}
                sx={{
                  ml: 5,
                  mt: 5,
                  position: [null, null, null, null, 'absolute'],
                  top: '13rem',
                  width: '30%',
                }}
              >
                <Box>{t('auth.subtitle1')}</Box>
                <Box>{t('auth.subtitle2')}</Box>
              </Box>
            )}
          </Box>
          <Box
            alignItems={['flex-start', null, 'center']}
            bg="primary"
            display="flex"
            height="auto"
            justifyContent="center"
            p={5}
            sx={{
              ...boxShadowSx(),
            }}
            width={{ lg: '50%' }}
          >
            <FinalForm
              initialValues={{ username }}
              onSubmit={async (values) => {
                try {
                  await Auth.forgotPasswordSubmit(
                    values.username.toLowerCase().trim(),
                    values.code,
                    values.newPassword
                  )
                    .then(async () => {
                      setSucessSnack(
                        t('reset.passwordChanged'),
                        config.notificationDuration
                      );
                      await updateLastLogin(username);
                    })
                    .then(() => {
                      setTimeout(
                        () => navigate('/login/?redirect=/secondary-auth/'),
                        5500
                      );
                    })
                    .catch((error) =>
                      setErrorSnack(
                        t('reset.problemResetting', { message: error.message }),
                        config.notificationDuration
                      )
                    );
                } catch (e) {
                  return setErrorSnack(
                    t('reset.problemResetting', { message: e.message }),
                    config.notificationDuration
                  );
                }
              }}
              render={(formContext) => {
                return (
                  <Box maxWidth="maxWidths.smallForm" mx="auto" width="100%">
                    {config.canadaEnv && (
                      <Box
                        sx={{
                          position: 'fixed',
                          right: '1rem',
                          top: '1rem',
                          width: 300,
                        }}
                      >
                        <Form
                          initialValues={{
                            language: selectedLang || i18n.language,
                          }}
                          onSubmit={() => {}}
                          render={() => (
                            <>
                              <Field
                                component={Dropdown}
                                isSearchable={false}
                                name="language"
                                options={
                                  config.canadaEnv
                                    ? [
                                        {
                                          canEnglishValue: null,
                                          frenchValue: 'Français Canadien',
                                          label: 'Français Canadien',
                                          value: 'fr',
                                        },
                                        {
                                          canEnglishValue: null,
                                          frenchValue: null,
                                          label: 'Canadian English',
                                          value: 'caEn',
                                        },
                                      ]
                                    : [
                                        {
                                          canEnglishValue: null,
                                          frenchValue: 'Français Canadien',
                                          label: 'Français Canadien',
                                          value: 'fr',
                                        },
                                        {
                                          canEnglishValue: null,
                                          frenchValue: null,
                                          label: 'Canadian English',
                                          value: 'caEn',
                                        },
                                        {
                                          canEnglishValue: null,
                                          frenchValue: null,
                                          label: 'American English',
                                          value: 'en',
                                        },
                                      ]
                                }
                              />
                              <OnChange name="language">
                                {(lang) => {
                                  i18n.changeLanguage(lang);
                                  localStorage.setItem('lang', lang);
                                }}
                              </OnChange>
                            </>
                          )}
                        />
                      </Box>
                    )}
                    <Box
                      color="white"
                      fontSize={7}
                      mb={[4, null, 6]}
                      textAlign={{ _: 'center', lg: 'left' }}
                    >
                      {t('reset.resetPassword')}
                    </Box>
                    <form onSubmit={formContext.handleSubmit}>
                      <Field
                        component={Input}
                        disabled={wasResetCodeSent}
                        label={t('reset.email')}
                        labelSx={{
                          color: 'white',
                        }}
                        name="username"
                        validate={composeValidations(required, email)}
                      />
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          mt: 6,
                        }}
                      >
                        <HoverableButton
                          disabled={
                            (!username && !formContext.values.username) ||
                            !!formContext.errors.username ||
                            wasResetCodeSent
                          }
                          onClick={() => {
                            setIsSendingPasscode(true);
                            sendResetCode(
                              formContext.values.username.toLowerCase().trim()
                            );
                          }}
                          submitting={isSendingPasscode}
                          sx={{
                            bg: 'accent',
                            mr: 3,
                          }}
                          width="100%"
                        >
                          {t('reset.sendVerification')}
                        </HoverableButton>
                        {wasResetCodeSent && (
                          <CountdownCircleTimer
                            colors={[
                              ['#7ED321', 0.33],
                              ['#FF7F00', 0.33],
                              ['#FF0000', 0.33],
                            ]}
                            duration={300}
                            initialRemainingTime={300}
                            isPlaying
                            onComplete={() => setWasResetCodeSent(false)}
                            size={30}
                            strokeLinecap="square"
                            strokeWidth={3}
                          />
                        )}
                      </Box>
                      <Field
                        component={Input}
                        disabled={!wasResetCodeSent}
                        label={t('reset.verificationCode')}
                        labelSx={{
                          color: 'white',
                        }}
                        name="code"
                        validate={composeValidations(required)}
                        wrapperSx={{
                          bg: null,
                          opacity: !wasResetCodeSent ? '0.3' : null,
                          pointerEvents: !wasResetCodeSent ? 'none' : null,
                        }}
                      />
                      <Field
                        component={InputPassword}
                        disabled={!wasResetCodeSent}
                        label={t('reset.newPassword')}
                        labelSx={{
                          color: 'white',
                        }}
                        name="newPassword"
                        validate={composeValidations(
                          minLength(config.minPasswordLength),
                          required
                        )}
                      />
                      <ToolbarButton
                        bg="accentSecondaryDark"
                        data-cy="submit"
                        label={t('reset.resetPasswordField')}
                        submitting={formContext.submitting}
                        sx={{ mt: 6 }}
                        type="submit"
                        width="100%"
                      />
                    </form>
                  </Box>
                );
              }}
            />
          </Box>
        </Box>
      )}
    </AuthContainer>
  );
};

PasswordResetPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            username: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default PasswordResetPage;
