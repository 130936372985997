import React from 'react';
import {Field} from 'react-final-form';
import PropTypes from 'prop-types';
import {
    businessNumberMask,
    legalIdentificationNumberMask,
    NAICCodeMask,
    phoneNumberMask,
    SICCodeMask
} from '@utils/masks';
import {
    legalBusinessNumber,
    legalIdentificationNumber,
    NAICCodeNumber,
    phoneNumber,
    positive,
    SICCodeNumber,
    totalEmployerContribution
} from '@utils/validations';
import {labelHandler} from '@utils/label-utils';
import InputMasked from '@basecomponents/InputMasked';
import {composeValidations, email, required} from "@utils/validators";
import InputDropdown from "@basecomponents/Dropdown";
import {CONTACT_TYPE, GROUP_TYPE} from "@utils/constants";
import {useTranslation} from "react-i18next";
import AddressFormSection from "@petcomponents/GroupNotes/Upsert/Sections/AddressFormSection";
import Input from '@basecomponents/Input';
import config from '@src/config.json'
import InputNumber from "@basecomponents/InputNumber";
import {createNumberMask} from 'text-mask-addons';
import i18n from '@src/utilities/i18n';
import remoteActionQuery from "@queries/remote-action.gql";
import get from "lodash/get";
import useSsm from '@src/utilities/hooks/useSsm';

/**
 * @category Claims
 * @param {string} acceptableMethods Indicating acceptable methods for payment
 * @param {object} wrapperSx Styling for the section
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const contactTypeLabel = labelHandler(i18n.t('groups.contactType'));

const sicCodeLabel = labelHandler(config.canadaEnv ? 'NAIC Code' : 'SIC Code');
const sicValidate = config.canadaEnv ? NAICCodeNumber : SICCodeNumber;
const eligiblePersonLabel = labelHandler(
    'All Eligible Persons'
);
const empContribLabel = labelHandler('Employer Contribution in %');
const empContribMask = createNumberMask({
    allowDecimal: true,
    includeThousandsSeparator: false,
    integerLimit: 3,
    prefix: '',
});
const empContribValidate = composeValidations(
    positive,
    totalEmployerContribution
);
const contactNumberLabel = labelHandler('Contact Number');

const phoneNumberValidate = phoneNumber;
const emailValidate = email
const sicMask = config.canadaEnv ? NAICCodeMask : SICCodeMask;
const finLabel = labelHandler(config.canadaEnv ? i18n.t('groups.businessNumber') : 'Federal Number')
const finAriaLabel = config.canadaEnv ? i18n.t('groups.businessNumber') : "Federal Number";
const finValidate = config.canadaEnv ?
    legalBusinessNumber :
    legalIdentificationNumber;
const finMask = config.canadaEnv ? businessNumberMask : legalIdentificationNumberMask;
const BasicInformation = ({client, values, form, wrapperSx, readOnly, ...rest}) => {
    const postEnrollmentConfigEnabled = useSsm('iaic-post-enrollment')
    const {t} = useTranslation();
    return (
        <>
            <Field
                aria-label={t('groups.groupType')}
                component={InputDropdown}
                disabled={
                    get(values, 'disablePostEnrollmentGroup', false)
                }
                label={labelHandler(t('groups.groupType'))}
                name="groupType"
                onChange={(values) => {
                    if (values === 'IAIC_ADMINISTRATED') {
                        form?.change('postEnrollmentGroup', false);
                    }
                }}
                options={GROUP_TYPE}
                searchable={false}
                wrapperSx={{...wrapperSx}}
                {...rest}
            />
            {get(values, 'groupType') ===
                'BEN_ADMIN_SYSTEM' && (
                    <Field
                        component={InputDropdown}
                        data-cy="benAdminId"
                        disabled={
                            get(values, 'disablePostEnrollmentGroup', false)
                        }
                        isClearable
                        label={labelHandler(t('metadata.selectBenAdmin'))}
                        loadOptions={async (data) => {
                            const params = {
                                name: data,
                                size: 5,
                                sort: 'created,desc'
                            };
                            if (get(values, 'benAdminId', null) && data.length === 0) {
                                params.benAdminId = get(values, 'benAdminId', null);
                            }
                            const options = await client
                                ?.query({
                                    fetchPolicy: 'no-cache',
                                    query: remoteActionQuery,
                                    variables: {
                                        name: 'get-all-ben-admin',
                                        params: JSON.stringify(params),
                                    },
                                })
                                .then((response) => {
                                    const {content} = JSON.parse(
                                        get(response, 'data.remoteAction.data', {})
                                    );
                                    const temp = content.map((item) => {
                                        let label = '';
                                        if (
                                            (!['ACTIVE'].includes(
                                                    get(values, 'status')
                                                ) &&
                                                postEnrollmentConfigEnabled)

                                        ) {
                                            label = `${item.name} (Post Enrollment ${item.postEnrollment
                                                ? 'Included'
                                                : 'Not Included'
                                            })`;
                                        } else {
                                            label = item.name;
                                        }

                                        return {
                                            label,
                                            postEnrollment: item.postEnrollment,
                                            subValues: {postEnrollment: item.postEnrollment},
                                            value: item.benAdminId,
                                        };
                                    });
                                    return temp;
                                });
                            return options;
                        }}
                        name="benAdminId"
                        onChange={(values, meta, subValues) => {
                            form?.change(
                                'postEnrollmentGroup',
                                get(subValues, 'postEnrollment', false)
                            );
                        }}
                        searchable
                        validate={required}
                        wrapperSx={{...wrapperSx}}
                        {...rest}
                    />
                )}
            <AddressFormSection
                label="sit"
                readOnly={readOnly}
                wrapperSx={wrapperSx}
            />
            <Field
                aria-label={t('groups.contactType')}
                component={InputDropdown}
                disabled={readOnly}
                label={contactTypeLabel}
                name="sitPhoneType"
                options={CONTACT_TYPE}
                searchable={false}
                wrapperSx={wrapperSx}
            />
            <Field
                aria-label="Contact Number"
                component={InputMasked}
                disabled={readOnly}
                label={contactNumberLabel}
                mask={phoneNumberMask}
                name="sitPhoneNumber"
                validate={phoneNumberValidate}
                wrapperSx={wrapperSx}
            />
            <Field
                component={Input}
                disabled={readOnly}
                label="Email"
                name="sitEmail"
                validate={emailValidate}
                wrapperSx={wrapperSx}
            />
            <Field
                aria-label={finAriaLabel}
                component={InputMasked}
                disabled={readOnly}
                label={finLabel}
                mask={finMask}
                name="federalNumber"
                validate={finValidate}
                wrapperSx={wrapperSx}
            />
            <Field
                aria-label={sicCodeLabel}
                component={InputMasked}
                disabled={readOnly}
                label={sicCodeLabel}
                mask={sicMask}
                name="sicCode"
                searchable={false}
                validate={sicValidate}
                wrapperSx={wrapperSx}
            />
            <Field
                aria-label="All Eligible Persons"
                component={InputNumber}
                disabled={readOnly}
                integerLimit={7}
                label={eligiblePersonLabel}
                name="eligibleNumber"
                validate={positive}
                wrapperSx={wrapperSx}
            />
            <Field
                aria-label="Employer Contribution in %"
                component={InputMasked}
                disabled={readOnly}
                label={empContribLabel}
                mask={empContribMask}
                name="employerContribution"
                validate={empContribValidate}
                wrapperSx={wrapperSx}
            />
        </>
    );
};
BasicInformation.defaultProps = {
    form: {
        change: () => {
        }
    },
    values: {},
    readOnly: false,
    wrapperSx: {},
};
BasicInformation.propTypes = {
    client: PropTypes.shape({
        query: PropTypes.func.isRequired,
    }).isRequired,
    form: PropTypes.shape({
        change: PropTypes.func,
    }),
    values: PropTypes.shape({}),
    readOnly: PropTypes.bool,
    wrapperSx: PropTypes.shape({})
};
export default BasicInformation;
