import Box from '@basecomponents/Box';
import React from 'react';

/**
 * @category Components
 * @returns {React.FC}
 */
const NoEntry = () => (
  <Box
    as="h1"
    sx={{
      alignItems: 'center',
      color: 'error',
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 'xbold',
      height: '100%',
      justifyContent: 'center',
    }}
  >
    <span aria-label="working" role="img">
      ⛔
    </span>
    You do not have access to this page
  </Box>
);

export default NoEntry;
