import GetData from '@src/utilities/get-data';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';

const CreatedBy = ({ data }) => {
  const userId = get(data, 'userId');
  const { colors } = useContext(ThemeContext);

  const { apiData = {}, loading } = GetData(
    'get-user-by-id',
    JSON.stringify({ userId })
  );

  if (loading) {
    return <PulseLoader color={`${colors.accentSecondary}`} size={7} />;
  }

  return get(apiData, 'fullName', null);
};

CreatedBy.defaultProps = {
  data: {},
};

CreatedBy.propTypes = {
  data: PropTypes.shape({}),
};

export default CreatedBy;
