import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import Button from '@basecomponents/Button';
// import Icon from '../../../../Icon';

/**
 * @category Components
 * @param {string} link
 * @param {string} linkFr
 * @param {string} name
 * @param {string} nameFr
 * @param {string} type
 * @returns {React.FC}
 */
const FooterLinks = ({ link, linkFr, name, nameFr, type }) => {
  // const imgSx = {
  //   alignItems: 'center',
  //   display: 'flex',
  //   height: '20px',
  //   justifyContent: 'center',
  //   mr: 2,
  //   width: '20px',
  // };
  switch (type) {
    case 'external': {
      return (
        <Box as="a" fontSize={2} href={link || linkFr} target="_blank">
          {name || nameFr}
        </Box>
      );
    }
    case 'mailTo': {
      return (
        <Box
          as="a"
          display="flex"
          fontSize={2}
          href={`mailto:${link|| linkFr}`}
          rel="noopener norefferer"
          target="_blank"
        >
          {/* <Icon color="accentSecondaryDark" svg="alternate-email" sx={imgSx} /> */}
          <Box>{name || nameFr}</Box>
        </Box>
      );
    }
    case 'telTo': {
      return (
        <Box
          as="a"
          display="flex"
          fontSize={2}
          href={`tel:${link || linkFr}`}
          rel="noopener norefferer"
          target="_blank"
        >
          {/* <Icon color="accentSecondaryDark" svg="phone" sx={imgSx} /> */}
          <Box>{name || nameFr}</Box>
        </Box>
      );
    }
    default:
      return (
        <Button
          link={link|| linkFr}
          simple
          sx={{
            color: 'black',
            fontSize: 2,
            transition: 'all 0.25s ease-in-out',
          }}
        >
          {name || nameFr}
        </Button>
      );
  }
};

FooterLinks.propTypes = {
  link: PropTypes.string,
  linkFr: PropTypes.string,
  name: PropTypes.string.isRequired,
  nameFr: PropTypes.string.isRequired,
  type: PropTypes.string,
};

FooterLinks.defaultProps = {
  link: null,
  linkFr: null,
  type: null,
};

export default FooterLinks;
