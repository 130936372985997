import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Field } from 'react-final-form';
import { get } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import { AuthContext } from '@basecomponents/Auth';
import { useTranslation } from 'react-i18next';
import useSnackbar from '../../../utilities/use-snackbar';
import GetData from '../../../utilities/get-data';
import config from '../../../config.json';
import remoteActionQuery from '../../../graphql/queries/remote-action.gql';
import stripSpecialCharacters from '../../../utilities/strip-special-characters';
import FileUploadInput from '../../BaseComponents/FileUploadInput';

const required = (value) => {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && !value.length)
  ) {
    return 'Required';
  }
};

/**
 * @category Components
 * @param {boolean} allowImagePreview
 * @param {boolean} allowMultiple
 * @param {boolean} allowImageValidateSize
 * @param {boolean} allowReorder
 * @param {string} employeeId
 * @param {string} fieldName
 * @param {Object} context
 * @param {Array<Object>} files
 * @param {Array<string>} fileTypes
 * @param {string} flow
 * @param {string} groupId
 * @param {string} maxFileSize
 * @param {string} name
 * @param {string} planName
 * @param {function} onUploadComplete
 * @param {Object} form
 * @param {boolean} isEducational
 * @param {string} imageValidateSizeMinHeight
 * @param {string} imageValidateSizeMinWidth
 * @param {boolean} isLogo
 * @param {boolean} isPromoFlag
 * @param {*} rest
 * @returns {React.FC}
 */
const UploadFile = ({
  // alternateRegistration,
  allowImagePreview,
  allowMultiple,
  allowImageValidateSize,
  allowReorder,
  employeeId,
  fieldName,
  context,
  files,
  fileTypes,
  flow,
  groupId,
  maxFileSize,
  name,
  planName,
  onUploadComplete,
  form,
  isEducational,
  imageValidateSizeMinHeight,
  imageValidateSizeMinWidth,
  isLogo,
  isPromoFlag,
  uploadMethod,
  ...rest
}) => {
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const fileList = files || [];

  const [timestamp, setTimestamp] = useState(new Date().getTime());

  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const { t } = useTranslation();

  const { apiData: group = {} } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );

  const groupNumber = get(group, 'groupNumber', 'NEW');
  let groupName = get(group, 'groupName') || 'New Group';
  if (group.status === 'DRAFT') {
    groupName = get(group, 'groupNote.groupName') || 'New Group';
  }
  const policyTerm = get(group, 'policyTerm');
  let uniqueIdentifier = get(
    group,
    'plan.planChoices[0].coverageFromDate',
    null
  );
  if (uniqueIdentifier) {
    // uniqueIdentifier = new Date(uniqueIdentifier).getFullYear();
    uniqueIdentifier = uniqueIdentifier.substring(0, 4);
  } else {
    uniqueIdentifier = new Date().getFullYear();
  }
  const keyNames = {
    census: () => `${groupNumber}/census/${timestamp}`,
    employeeLevelPostEnrollment: () =>
      `${groupNumber}/emp-level-post-enrollment/${timestamp}`,
    enrollment: () => `${groupNumber}/enrollment/${timestamp}`,
    groupNote: () => `newGroups/groupAdmin/${timestamp}`,
    groupAdmin: () => `newGroups/groupAdmin/${timestamp}`,
    groupAdminCanada: () => `newGroups/groupAdmin/${timestamp}`,
    groupDetails: (name) => `${groupNumber}/groupDocuments/${name}`,
    lateEnrollment: (filename) =>
      `${groupNumber}/lateEnrollmentDocuments/${employeeId}/${timestamp}/${filename}`,
    postEnrollment: () => `${groupNumber}/post-enrollment/${timestamp}`,
    rating: () => `${groupNumber}/rating/${timestamp}`,
    ratingCanada: () => `${groupNumber}/rating/${timestamp}`,
    remittance: () => `${groupNumber}/remittance/${timestamp}`,
    universalRemittance: () =>
      `${groupNumber}/universalRemittance/${timestamp}`,
    renewalGroupAdmin: () => `renewal/groupAdmin/${timestamp}`,
    renewalGroupAdminCanada: () => `renewal/groupAdmin/${timestamp}`,
  };

  const labelNames = {
    census: 'Census Template Upload',
    employeeLevelPostEnrollment:
      'Employee Level Post Enrollment Template Upload',
    enrollment: 'Enrollment Template Upload',
    groupAdmin: 'Group Creation Template upload ',
    groupNote: 'Group Admin File Upload',
    groupAdminCanada: 'Group Creation Template upload ',
    groupDetails: 'Upload Group Files',
    lateEnrollment: t('common.uploadFiles'),
    postEnrollment: 'Pet Level Post Enrollment Template Upload',
    rating: 'Rating Table Template Upload',
    ratingCanada: 'Rating Table Template Upload',
    remittance: 'Remittance File Upload',
    universalRemittance: 'Universal Remittance File Upload',
    renewalGroupAdmin: 'Group Template Upload',
    renewalGroupAdminCanada: 'Group Template Upload',
  };

  const validations = {
    census: null,
    employeeLevelPostEnrollment: null,
    enrollment: null,
    groupAdmin: null,
    groupNote: null,
    groupAdminCanada: null,
    groupDetails: null,
    lateEnrollment: null,
    postEnrollment: null,
    rating: required,
    ratingCanada: required,
    remittance: null,
    universalRemittance: required,
    renewalGroupAdmin: required,
    renewalGroupAdminCanada: required,
  };

  return (
    <ApolloConsumer>
      {(client) => {
        return (
          <Field
            acceptedFileTypes={fileTypes}
            allowImagePreview={!allowImagePreview}
            allowImageValidateSize={allowImageValidateSize}
            allowMultiple={allowMultiple}
            allowReorder={allowReorder}
            allowRevert
            bucket={
              [
                'enrollment',
                'census',
                'postEnrollment',
                'employeeLevelPostEnrollment',
              ].includes(flow) &&
              uploadMethod === 'BarrelUpload' &&
              config.barrelS3Bucket
                ? config.barrelS3Bucket
                : config.amplify.Storage.AWSS3.bucket
            }
            component={FileUploadInput}
            credits={false}
            customPrefix={config.amplify.Storage.AWSS3.customPrefix}
            data-cy={`${flow}Upload`}
            fileRenameFunction={({ basename, extension }) => {
              const baseStripped = isLogo
                ? stripSpecialCharacters(name)
                : stripSpecialCharacters(basename);
              const filename = `${baseStripped}${extension}`;
              if (flow === 'lateEnrollment') {
                return `${keyNames[flow](filename)}`;
              }
              return `${keyNames[flow](baseStripped)}${extension}`;
            }}
            imageValidateSizeMinHeight={imageValidateSizeMinHeight}
            imageValidateSizeMinWidth={imageValidateSizeMinWidth}
            // instantUpload={false}
            label={labelNames[flow]}
            labelIdle={`
          <div class="filepond--heading">
            ${t('common.dragAndDrop')}
          </div>
          <div class="filepond--or">
            ${t('common.or')}
          </div>
          <div
            class="filepond--label-action"
          >
            ${t('common.addFiles')}
          </div>
        `}
            maxFileSize={maxFileSize}
            name={fieldName || 'fileUpload'}
            onRemoveComplete={({ name }) => {
              fileList.splice(
                fileList.findIndex((f) => f.s3KeyName === name),
                1
              );
              return form.change('fileUpload', [...fileList]);
            }}
            onUploadComplete={({ fileName }) => {
              const extension = fileName.split('.').pop();
              let displayName = name || fileName.split('/').pop();

              const suffix = fileList.filter(({ documentName }) => {
                const pattern = new RegExp(
                  `^${displayName}(-[0-9]+$){0,1}$`,
                  'gi'
                );
                return pattern.test(documentName);
              }).length;

              if (suffix) {
                displayName = `${displayName}-${suffix}`;
              }
              if (flow === 'groupDetails' || flow === 'lateEnrollment') {
                client
                  .query({
                    fetchPolicy: 'no-cache',
                    query: remoteActionQuery,
                    variables: {
                      name: 'set-file',
                      params: JSON.stringify({
                        employeeId,
                        groupId,
                        isEducational,
                        isLogo,
                        isPromoFlag,
                        name: displayName,
                        s3Key: fileName,
                      }),
                    },
                  })
                  .then(() => {
                    onUploadComplete();
                    setTimestamp(new Date().getTime());
                  })
                  .catch((e) => {
                    setErrorSnack(
                      `There was an error: ${e.message}`,
                      config.notificationDuration
                    );
                    setTimestamp(new Date().getTime());
                  });
              } else {
                client
                  .query({
                    fetchPolicy: 'no-cache',
                    query: remoteActionQuery,
                    variables: {
                      name:
                        [
                          'enrollment',
                          'census',
                          'postEnrollment',
                          'employeeLevelPostEnrollment',
                        ].includes(flow) &&
                        uploadMethod === 'BarrelUpload' &&
                        config.barrelS3Bucket
                          ? 'barrel-create-dataset'
                          : 'set-task',
                      params: JSON.stringify({
                        apiVersion: config.newBillingApiVersionEnable
                          ? 'v2'
                          : 'v1',
                        context: JSON.stringify(context),
                        flow,
                        groupId,
                        groupName,
                        groupNumber,
                        name: displayName,
                        planName,
                        policyTerm,
                        policyYear: uniqueIdentifier,
                        s3Bucket:
                          [
                            'enrollment',
                            'census',
                            'postEnrollment',
                            'employeeLevelPostEnrollment',
                          ].includes(flow) &&
                          uploadMethod === 'BarrelUpload' &&
                          config.barrelS3Bucket
                            ? config.barrelS3Bucket
                            : config.amplify.Storage.AWSS3.bucket,
                        s3Key: `${keyNames[flow]()}.${extension}`,
                        user: userId,
                      }),
                    },
                  })
                  .then((response) => {
                    let resData = '';
                    if (
                      [
                        'enrollment',
                        'census',
                        'postEnrollment',
                        'employeeLevelPostEnrollment',
                      ].includes(flow) &&
                      uploadMethod === 'BarrelUpload' &&
                      config.barrelS3Bucket
                    ) {
                      resData = get(
                        JSON.parse(get(response, 'data.remoteAction.data', '')),
                        'datasetId',
                        {}
                      );
                    }
                    const returnData =
                      [
                        'enrollment',
                        'census',
                        'postEnrollment',
                        'employeeLevelPostEnrollment',
                      ].includes(flow) &&
                      uploadMethod === 'BarrelUpload' &&
                      config.barrelS3Bucket
                        ? resData
                        : response;
                    onUploadComplete(returnData);
                    setTimestamp(new Date().getTime());
                  })
                  .catch((e) => {
                    setErrorSnack(
                      `There was an error: ${e.message}`,
                      config.notificationDuration
                    );
                    setTimestamp(new Date().getTime());
                  });
              }

              fileList.push({
                documentName: displayName,
                s3BucketName:
                  [
                    'enrollment',
                    'census',
                    'postEnrollment',
                    'employeeLevelPostEnrollment',
                  ].includes(flow) &&
                  uploadMethod === 'BarrelUpload' &&
                  config.barrelS3Bucket
                    ? config.barrelS3Bucket
                    : config.amplify.Storage.AWSS3.bucket,
                s3KeyName: `${fileName}`,
              });

              if (form) {
                if (fieldName) {
                  form.change(fieldName, [...fileList]);
                  form.change(fieldName, [...fileList]);
                } else {
                  form.change('fileUpload', [...fileList]);
                }
              }
            }}
            validate={validations[flow]}
            wrapperSx={{
              '.filepond--drop-label': {
                height: 'auto',
                padding: 3,
                py: [5, null, null, null, 8],
                width: '100%',
              },
              '.filepond--heading': {
                color: 'text.primary',
                fontSize: 5,
                fontWeight: 'bold',
                lineHeight: '1em',
              },
              '.filepond--label-action': (p) => ({
                mt: 5,
                textDecoration: 'none',
                ...p.variants.buttons.primary,
              }),
              '.filepond--or': {
                color: 'text.primary',
                fontSize: 2,
                fontWeight: 'bold',
                mt: 5,
                textTransform: 'uppercase',
              },
              '.filepond--supported': {
                fontSize: 2,
                mt: 5,
              },
              mb: 6,
              padding: 3,
              width: '25rem',
            }}
            {...rest}
          />
        );
      }}
    </ApolloConsumer>
  );
};

UploadFile.propTypes = {
  // alternateRegistration: PropTypes.string,
  allowImagePreview: PropTypes.bool,
  allowImageValidateSize: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  allowReorder: PropTypes.bool,
  context: PropTypes.shape({}),
  employeeId: PropTypes.string,
  fieldName: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object),
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  flow: PropTypes.string.isRequired,
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
  groupId: PropTypes.string.isRequired,
  imageValidateSizeMinHeight: PropTypes.string,
  imageValidateSizeMinWidth: PropTypes.string,
  isEducational: PropTypes.bool,
  isLogo: PropTypes.bool,
  isPromoFlag: PropTypes.bool,
  maxFileSize: PropTypes.string,
  name: PropTypes.string,
  onUploadComplete: PropTypes.func,
  planName: PropTypes.string,
  uploadMethod: PropTypes.string,
};

UploadFile.defaultProps = {
  // alternateRegistration: true,
  allowImagePreview: false,
  allowImageValidateSize: false,
  allowMultiple: false,
  allowReorder: false,
  context: {},
  employeeId: '',
  fieldName: null,
  files: [],
  fileTypes: [],
  form: {
    change: () => {},
  },
  imageValidateSizeMinHeight: null,
  imageValidateSizeMinWidth: null,
  isEducational: false,
  isLogo: false,
  isPromoFlag: false,
  maxFileSize: null,
  name: '',
  onUploadComplete: () => {},
  planName: '',
  uploadMethod: 'BarrelUpload',
};

export default UploadFile;
