import React from 'react';
import SectionForm from '@basecomponents/SectionForm'
import InitialSetup from "@petcomponents/GroupNotes/Upsert/Sections/InitialSetup";
import BasicInformation from "@petcomponents/GroupNotes/Upsert/Sections/BasicInformation";
import BillingInfo from "@petcomponents/GroupNotes/Upsert/Sections/BillingInformation";
import PropTypes from 'prop-types'

const UpsertGroupNotesPage = ({noteId}) => {
    const sections = [
        {
            componentData: ({wrapperSx, form, client, values}) => <InitialSetup client={client} form={form}
                                                                                values={values} wrapperSx={wrapperSx}/>,
            title: 'Group Information',
        },
        {
            componentData: ({wrapperSx, form, client, values}) => <BasicInformation client={client} form={form}
                                                                                    values={values}
                                                                                    wrapperSx={wrapperSx}/>,
            title: 'Sold Email Section'
        },
        {
            componentData: ({wrapperSx, form, client, values}) => <BillingInfo client={client} form={form}
                                                                               values={values} wrapperSx={wrapperSx}/>,
            title: 'Call Section'
        }
    ];
    return (
        <SectionForm
            noteId={noteId}
            sections={sections}
        />
    );
};

UpsertGroupNotesPage.defaultProps = {
    noteId: ''
};

UpsertGroupNotesPage.propTypes = {
    noteId: PropTypes.string
};

export default UpsertGroupNotesPage;
