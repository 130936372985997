import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import PropTypes from 'prop-types';
import { AuthContext } from '@basecomponents/Auth';
import { required } from '@utils/validators';
import { labelHandler } from '@utils/label-utils';
import { useTranslation } from 'react-i18next';
import InputDropdown from '../../BaseComponents/Dropdown';
import ButtonGroups from '../../BaseComponents/ButtonGroups';
import Route from '../Route';
import remoteActionQuery from '../../../graphql/queries/remote-action.gql';
import GetData from '../../../utilities/get-data';
import useSnackbar from '../../../utilities/use-snackbar';
import ToolbarButton from '../../BaseComponents/ToolbarButton';
import Spinner from '../../BaseComponents/Spinner';
import config from '../../../config.json';

/**
 * @category Components
 * @param {boolean} sftp
 * @param {*} rest
 * @returns {React.FC}
 */
const InferLabels = ({ sftp, ...rest }) => {
  const { t } = useTranslation()
  const i = 0;
  const queryParams = useParams();
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const fileId = sftp ? 'datasetId' : 'taskId';
  const [setErrorSnack] = useSnackbar({ color: 'error' });

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  // GET API Calls
  const { apiData, loading } = GetData(
    sftp ? 'barrel-files' : 'get-tasks',
    JSON.stringify({ [fileId]: get(queryParams, fileId), page: 0, size: 1 })
  );

  const dataset = apiData.content ? apiData.content[0] : false;

  const context = {};
  if (sftp) {
    context.labelMap = get(dataset, 'expectedMapping', false);
    context.provided = get(dataset, 'remainingHeaders', false);
  } else {
    context.labelMap = get(dataset, 'context.labelMap', false);
    context.provided = get(dataset, 'context.provided', false);
  }

  const options = {};
  if (context) {
    Object.keys(context.labelMap).forEach((label) => {
      options[label] = context.provided
        .map((field) => {
          return { label: field, value: field };
        })
        .filter((field) => {
          const usedSomewhere = Object.values(context.labelMap).includes(
            field.label
          );
          const usedByMe = context.labelMap[label] === field.label;

          return !usedSomewhere || usedByMe;
        });
    });
  }
  // options.push({label: undefined, value: undefined})

  return (
    <ApolloConsumer>
      {(client) => {
        const templatesClickAction = async (values) => {
          context.labelMap = values;

          const params = {
            apiVersion: config.newBillingApiVersionEnable ? 'v2' : 'v1',
            context: JSON.stringify(context),
            flow: dataset.flow,
            groupId: dataset.groupId,
            groupName: dataset.groupName,
            groupNumber: dataset.groupNumber,
            s3Key: dataset.s3Key,
            taskId: get(queryParams, fileId),
            user: userId,
            waiting: false,
          };

          client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'set-task',
                params: JSON.stringify(params),
              },
            })
            .then(() => {
              navigate('/groups/templateStatus');
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        };

        const barrelClickAction = async (values) => {
          const params = {
            datasetId: dataset.datasetId,
            expectedMapping: values,
            userId,
          };

          client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'barrel-set-columns',
                params: JSON.stringify(params),
              },
            })
            .then(() => {
              navigate('/files/dashboard');
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        };

        if (loading) {
          return <Spinner />;
        }

        return (
          <Route
            header={{
              rightContainer: (
                <ButtonGroups>
                  <ToolbarButton
                    bg="primaryDark"
                    icon="file"
                    label="view files"
                    link={sftp ? '/files/dashboard' : '/groups/templateStatus'}
                  />
                </ButtonGroups>
              ),
              title: 'File Dashboard',
              type: 'groups',
            }}
            isPrivate
            {...rest}
          >
            <Box as="h2" sx={{ py: 3 }}>
              Files Dashboard - Infer Labels
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                margin: 0,
                width: '100%',
              }}
            >
              <FinalForm
                initialValues={context.labelMap}
                onSubmit={async (formValues) => {
                  if (sftp) {
                    await barrelClickAction(formValues);
                  } else {
                    await templatesClickAction(formValues);
                  }
                }}
                render={(formContext) => {
                  return (
                    <form onSubmit={formContext.handleSubmit}>
                      <Box
                        sx={{
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          padding: 3,
                        }}
                      >
                        {Object.keys(context.labelMap).map((label) => (
                          <Box>
                            <Field
                              key={label + i}
                              component={InputDropdown}
                              label={labelHandler(label, true)}
                              name={label}
                              options={options[label]}
                              validate={required}
                              wrapperSx={{ ...commonFieldSxHalf }}
                              {...rest}
                            />
                            <OnChange name={label}>
                              {(value, previous) => {
                                // i += 1
                                Object.keys(context.labelMap).forEach(
                                  (expected) => {
                                    if (expected !== label) {
                                      options[expected] = options[
                                        expected
                                      ].filter(
                                        (field) => field.label !== value
                                      );
                                      if (previous) {
                                        options[expected].push({
                                          label: previous,
                                          value: previous,
                                        });
                                      } else {
                                        options[expected] = options[
                                          expected
                                        ].filter((field) => field.value);
                                        options[expected].push({
                                          label: '-',
                                          value: false,
                                        });
                                      }
                                    }
                                  }
                                );
                              }}
                            </OnChange>
                          </Box>
                        ))}
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            maxWidth: '25rem',
                            mt: 6,
                            width: '100%',
                          }}
                        >
                          <ToolbarButton
                            bg="primaryDark"
                            isDisabled={formContext.submitting}
                            label={t('common.submit')}
                            mr={0}
                            submitting={formContext.submitting}
                            type="submit"
                            width="150px"
                          />
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </Box>
          </Route>
        );
      }}
    </ApolloConsumer>
  );
};

InferLabels.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  sftp: PropTypes.bool,
};

InferLabels.defaultProps = {
  sftp: false,
};

export default InferLabels;
