import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { diff } from 'deep-object-diff';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '@basecomponents/Auth';
import { get, omit } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import PropTypes from 'prop-types';
import InputCheckbox from '@basecomponents/InputCheckbox';
import generateRedirectPath from '@utils/generate-redirect-path';
import { composeValidations, required } from '@utils/validators';
import {
  COMPANY_CODES,
  DEMO_GROUP_TYPE,
  GROUP_MGA,
  GROUP_TYPE,
  SIT_CODES,
} from '@utils/constants';
import { labelHandler } from '@utils/label-utils';
import {
  groupNumber,
  legalBusinessNumber,
  legalIdentificationNumber,
  writingNumber,
} from '@utils/validations';
import {
  businessNumberMask,
  groupNumberMask,
  legalIdentificationNumberMask,
  writingNumberMask,
} from '@utils/masks';
import { useTranslation } from 'react-i18next';
import InputDropdown from '@basecomponents/Dropdown';
import InputText from '@basecomponents/Input';
import InputMasked from '@basecomponents/InputMasked';
import ToolbarButton from '@basecomponents/ToolbarButton';
import Spinner from '@basecomponents/Spinner';
import config, { variation } from '@src/config.json';
import GetData from '@utils/get-data';
import useSnackbar from '@utils/use-snackbar';
import remoteActionQuery from '@queries/remote-action.gql';
import { produce } from 'immer';
import { OnChange } from 'react-final-form-listeners';
import useSsm from '@src/utilities/hooks/useSsm';

const commonFieldSxHalf = {
  padding: 3,
  width: '25rem',
};

const checkboxInputWrapperSx = {
  display: 'inline-flex',
  float: 'left',
  marginTop: '7px',
};
const labelSx = {
  display: 'inline-flex',
};

const finValidate = config.canadaEnv
  ? composeValidations(required, legalBusinessNumber)
  : composeValidations(required, legalIdentificationNumber);
const checkboxWrapperSx = { ...commonFieldSxHalf, display: 'flex' };
/**
 * @category Group
 *
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const InitialSetup = ({ location, isRenewal, ...rest }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const inputRef = useRef();
  const userId = get(user, 'customSystemUserId', '');
  const queryParams = useParams();
  const groupId = get(queryParams, 'groupId', '');
  const isEditing = get(location, 'pathname', '').includes('/groups/edit/');
  const name = 'step';
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [createGroup, setCreateGroup] = useState({ groupId });
  const [errorData, setErrorData] = useState(null);
  const [renderKey, setRenderKey] = useState(0);
  const [groupMga, setGroupMga] = useState([]);
  const isPassiveRenewalEnabled = useSsm('iaic-passive-renewal')

  const { apiData: { content: filesData = [] } = {}, loading: filesLoading } =
    GetData(
      'get-tasks',
      JSON.stringify({
        groupId,
        flow: 'renewalGroupAdmin',
        page: 0,
        size: 10,
        sort: 'created,desc',
      }),
      isRenewal === false
    );

  const showPetCountFlag = useSsm('iaic-show-pet-count')

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  // GET API Calls
  const { apiData: groups = {}, loading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );

  const noteId = groups?.groupNote?.groupNoteId;

  const { apiData: groupNote, loading: groupNoteLoading } = GetData(
    'get-group-note',
    JSON.stringify({
      noteId,
    }),
    noteId == null
  );
  const postEnrollmentConfigEnabled = useSsm('iaic-post-enrollment')

  const { apiData: parentGroups = {} } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: get(groups, 'parentGroupId') }),
    get(groups, 'parentGroupId') == null || !isRenewal || loading
  );
  const { errorMsg } = GetData(
    'get-transition-validation',
    JSON.stringify({ groupId }),
    !groupId && isEditing
  );
  const {
    apiData: { PayrollDeductionReportLevel = [], GroupMga = [] },
    loading: mgaLoading,
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: [' PayrollDeductionReportLevel', 'GroupMga'] }])
  );
  const enrollmentTerminationdoeConfigEnabled = useSsm('iaic-enrollment-termination-doe-flag')

  useEffect(() => {
    if (GroupMga.length > 0) {
      let mga = [];
      if (config.canadaEnv) {
        mga = GroupMga.filter((d) => d.value === 'PTZ_SODACO');
      } else {
        mga = GroupMga.filter((d) => d.value !== 'PTZ_SODACO');
      }
      setGroupMga(mga);
    }
  }, [mgaLoading]);

  const createGroupAPIResponse = {
    enrollmentCashCollectionDoe: get(
      groups,
      'enrollmentCashCollectionDoe',
      false
    ),
    agencyCode: get(groups, 'agencyCode', ''),
    benAdminId: get(groups, 'groupBenAdminXref.benAdminId', null),
    companyCode: get(groups, 'companyCode', ''),
    createPortalUsers: get(groups, 'createPortalUsers', true),
    enrollmentDoe: get(groups, 'enrollmentDoe', false),
    fin: get(groups, 'fin', ''),
    groupBenAdminXrefId: get(
      groups,
      'groupBenAdminXref.groupBenAdminXrefId',
      null
    ),
    groupNumber: get(groups, 'groupNumber', ''),
    groupType: get(groups, 'groupType', ''),
    mga: get(groups, 'mga', ''),
    passiveRenewal: get(groups, 'passiveRenewal', false),
    payrollDeductionInvoiceSplitBy: get(
      groups,
      'payrollDeductionInvoiceSplitBy',
      ''
    ),
    payrollDeductionReportLevel: get(groups, 'payrollDeductionReportLevel', ''),
    postEnrollmentGroup: get(groups, 'postEnrollmentGroup', false),
    showPetCount: get(groups, 'showPetCount', false),
    terminationDoe: get(groups, 'terminationDoe', false),
  };
  const setActiveRenewal =
    get(groups, 'passiveRenewal') &&
    isPassiveRenewalEnabled &&
    isRenewal &&
    filesData.length > 0;
  let initialValues = produce(
    {
      ...createGroupAPIResponse,
      passiveRenewal: setActiveRenewal ? false : get(groups, 'passiveRenewal'),
    },
    (draft) => {
      draft.groupNumber ??= groupNote?.groupNumber;
      draft.fin ??= groupNote?.federalNumber;
    }
  );
  let dropDown;
  if (variation === 'demo') {
    dropDown = 'DEMO';
  } else if (variation === 'ihc') {
    dropDown = 'ihc';
  } else {
    dropDown = 'default';
  }

  return (
    <ApolloConsumer>
      {(client) => {
        const onClickAction = async (values) => {
          const { benAdminId, ...rest } = values;
          const { benAdminId: groupBenAdminId = null, ...apiRestResponse } =
            createGroupAPIResponse;
          let params = {
            status: 'GROUP_CREATION',
            userId,
            ...rest,
          };
          if (!groupId && !params.enrollmentDoe) {
            params = omit(params, ['enrollmentCashCollectionDoe']);
          }
          if (
            get(groups, 'active') &&
            ['ACTIVE'].includes(get(groups, 'status')) &&
            postEnrollmentConfigEnabled &&
            !get(groups, 'postEnrollmentGroup', false) &&
            get(values, 'postEnrollmentGroup') &&
            errorMsg
          ) {
            setErrorData(errorMsg);
            return setTimeout(() => {
              inputRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
              });
            }, 100);
          }
          let action = '';
          if (
            groupBenAdminId !== null &&
            (benAdminId !== groupBenAdminId ||
              get(values, 'groupType') !== 'BEN_ADMIN_SYSTEM')
          ) {
            action =
              get(values, 'groupType') !== 'BEN_ADMIN_SYSTEM'
                ? 'DELETE'
                : 'UPDATE';
          } else if (
            get(values, 'groupType') === 'BEN_ADMIN_SYSTEM' &&
            benAdminId
          ) {
            action = 'CREATE';
          }

          const groupBenAdminXrefId = get(
            apiRestResponse,
            'groupBenAdminXrefId',
            ''
          );
          const apiCall = (pathName, params, isBenFlowCall = false) =>
            client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: pathName,
                  params: JSON.stringify(params),
                },
              })
              .then(async (response) => {
                const createGroupResponse = JSON.parse(
                  get(response, 'data.remoteAction.data', '{}')
                );
                if (get(createGroupResponse, 'groupId', '')) {
                  await setCreateGroup(createGroupResponse);
                  if (
                    benAdminId &&
                    benAdminId !== groupBenAdminId &&
                    isBenFlowCall
                  ) {
                    const apiBenData = {
                      action,
                      groupBenAdminXrefs: [
                        {
                          benAdminId,
                          groupBenAdminXrefId,
                          groupId: get(createGroupResponse, 'groupId'),
                        },
                      ],
                    };
                    // remove get(values, 'postEnrollmentGroup') after postenrollment to normal group transition active
                    if (
                      (get(values, 'groupType') === 'BEN_ADMIN_SYSTEM' &&
                        benAdminId &&
                        !['ACTIVE'].includes(get(groups, 'status'))) ||
                      (get(values, 'postEnrollmentGroup') === false &&
                        isEditing)
                    ) {
                      await apiCall('add-ben-admin-to-group', apiBenData).catch(
                        (e) =>
                          setErrorSnack(
                            `There was an error: ${e.message}`,
                            config.notificationDuration
                          )
                      );
                    }
                    if (
                      get(groups, 'active') &&
                      ['ACTIVE'].includes(get(groups, 'status')) &&
                      postEnrollmentConfigEnabled &&
                      !get(groups, 'postEnrollmentGroup', false) &&
                      get(values, 'postEnrollmentGroup') &&
                      isEditing
                    ) {
                      await apiCall('add-mid-year-transition', {
                        groupId,
                        groupTransitionTypes: 'IAIC_TO_PDBA',
                        processGroupBenAdminXrefDTO: {
                          action,
                          groupBenAdminXrefs: [
                            {
                              benAdminId,
                              groupBenAdminXrefId,
                              groupId,
                            },
                          ],
                        },
                      }).catch((e) =>
                        setErrorSnack(
                          `There was an error: ${e.message}`,
                          config.notificationDuration
                        )
                      );
                    }
                  }
                  if (isRenewal) {
                    return navigate(
                      generateRedirectPath({
                        ...location,
                        queryParams: {
                          [name]: 2,
                        },
                      })
                    );
                  }
                  return navigate(
                    `/groups/${isEditing ? 'edit' : 'create'}/${get(
                      createGroupResponse,
                      'groupId'
                    )}/${get(createGroupResponse, 'plan.planId')}?${name}=1`,
                    { replace: true }
                  );
                }
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                )
              );
          if (groupId) {
            const diffPayload = diff(apiRestResponse, rest);
            if (Object.keys(diffPayload).length) {
              let diffData = diffPayload;
              if (
                get(groups, 'active') &&
                ['ACTIVE'].includes(get(groups, 'status'))
              ) {
                diffData = omit(diffData, ['postEnrollmentGroup', 'groupType']);
              }
              if (
                get(groups, 'active') &&
                ['ACTIVE'].includes(get(groups, 'status'))
              ) {
                if (Object.keys(diffData).length > 0) {
                  return apiCall(
                    'update-group',
                    {
                      ...diffData,
                      groupId: get(groups, 'groupId', ''),
                      userId,
                    },
                    true
                  );
                }
                if (
                  postEnrollmentConfigEnabled &&
                  !get(groups, 'postEnrollmentGroup', false) &&
                  get(values, 'postEnrollmentGroup') &&
                  isEditing
                ) {
                  return apiCall('add-mid-year-transition', {
                    groupId,
                    groupTransitionTypes: 'IAIC_TO_PDBA',
                    processGroupBenAdminXrefDTO: {
                      action,
                      groupBenAdminXrefs: [
                        {
                          benAdminId,
                          groupBenAdminXrefId,
                          groupId,
                        },
                      ],
                    },
                  }).catch((e) =>
                    setErrorSnack(
                      `There was an error: ${e.message}`,
                      config.notificationDuration
                    )
                  );
                }
              } else {
                return apiCall(
                  'update-group',
                  {
                    ...diffPayload,
                    groupId: get(groups, 'groupId', ''),
                    userId,
                  },
                  true
                );
              }
            }
            if (isRenewal) {
              return navigate(
                generateRedirectPath({
                  ...location,
                  queryParams: {
                    [name]: 2,
                  },
                })
              );
            }
            return navigate(
              `/groups/${isEditing ? 'edit' : 'create'}/${get(
                groups,
                'groupId'
              )}/${get(groups, 'plan.planId')}?${name}=1`,
              { replace: true }
            );
          }
          apiCall('create-group', params, true);
        };

        if (loading || groupNoteLoading || filesLoading) {
          return <Spinner />;
        }

        initialValues = createGroup.groupId
          ? produce(
              {
                ...createGroupAPIResponse,
                passiveRenewal: setActiveRenewal
                  ? false
                  : get(groups, 'passiveRenewal'),
              },
              (draft) => {
                draft.groupNumber ??= groupNote?.groupNumber;
                draft.fin ??= groupNote?.federalNumber;
              }
            )
          : {
              createPortalUsers: isRenewal ? null : true,
            };
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              margin: 0,
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={initialValues}
              onSubmit={async (formValues) => {
                await onClickAction(formValues);
              }}
              render={(formContext) => {
                const isIAICAdministeredGroup =
                  formContext.values?.groupType === 'IAIC_ADMINISTRATED';
                const isBenAdminNotPostEnroll =
                  !formContext?.values?.postEnrollmentGroup;
                const isEnrollmentDOEEnabled =
                  formContext?.values?.enrollmentDoe === true;
                const isGroupInTransition = ['IN_PROGRESS'].includes(
                  get(groups, 'groupTransitionStatus')
                );
                const isPostEnrollGroup = get(
                  groups,
                  'postEnrollmentGroup',
                  false
                );
                const isPostEnrollParentGroup = get(
                  parentGroups,
                  'postEnrollmentGroup',
                  false
                );
                const isBenAdministeredGroup =
                  get(formContext, 'values.groupType') === 'BEN_ADMIN_SYSTEM';
                const shouldShowPassiveRenewalCheckbox =
                  isPassiveRenewalEnabled &&
                  isRenewal &&
                  filesData.length === 0 &&
                  (isIAICAdministeredGroup ||
                    (isBenAdministeredGroup && isBenAdminNotPostEnroll));
                const loadOptions = async (data) => {
                  const params = {
                    name: data,
                    size: 5,
                    sort: 'created,desc',
                  };
                  if (
                    get(formContext, 'values.benAdminId', null) !== null &&
                    data?.length === 0
                  ) {
                    params.benAdminId = get(
                      formContext,
                      'values.benAdminId',
                      null
                    );
                  }
                  if (isRenewal && isPostEnrollGroup) {
                    params.postEnrollment = true;
                  }
                  const options = await client
                    .query({
                      fetchPolicy: 'no-cache',
                      query: remoteActionQuery,
                      variables: {
                        name: 'get-all-ben-admin',
                        params: JSON.stringify(params),
                      },
                    })
                    .then((response) => {
                      const { content } = JSON.parse(
                        get(response, 'data.remoteAction.data', {})
                      );
                      const temp = content.map((item) => {
                        let label = '';
                        // const notActiveGroup =
                        //   ['IN_PROGRESS', 'GROUP_CREATION'].includes(
                        //     get(groups, 'status')
                        //   ) || !get(groups, 'active');
                        if (
                          (!['ACTIVE'].includes(get(groups, 'status')) &&
                            postEnrollmentConfigEnabled) ||
                          (config.midYearTransition &&
                            ['ACTIVE'].includes(get(groups, 'status')))
                        ) {
                          label = `${item.name} (Post Enrollment ${
                            item.postEnrollment
                              ? t('metadata.included')
                              : t('metadata.notIncluded')
                          })`;
                        } else {
                          label = item.name;
                        }

                        return {
                          label,
                          postEnrollment: item.postEnrollment,
                          subValues: {
                            postEnrollment: item.postEnrollment,
                          },
                          value: item.benAdminId,
                        };
                      });
                      return temp;
                    });
                  return options;
                };
                const isGroupActive = ['ACTIVE'].includes(
                  get(groups, 'status')
                );
                return (
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        padding: 3,
                      }}
                    >
                      <Box
                        ref={inputRef}
                        as="h3"
                        display="flex"
                        justifyContent="flex-start"
                        sx={{
                          color: 'accent',
                          margin: '20px 0px 20px 0px;',
                          padding: 3,
                          width: '25rem',
                        }}
                      >
                        {t('groups.initialSetup')}
                      </Box>
                      <Field
                        aria-label={t('groups.groupType')}
                        component={InputDropdown}
                        disabled={
                          get(groups, 'postEnrollmentGroup', false) ||
                          ['IN_PROGRESS'].includes(
                            get(groups, 'groupTransitionStatus')
                          ) ||
                          get(parentGroups, 'postEnrollmentGroup', false)
                        }
                        label={labelHandler(t('groups.groupType'), true)}
                        name="groupType"
                        onChange={(e) => {
                          if (
                            isPassiveRenewalEnabled &&
                            isRenewal &&
                            filesData.length === 0
                          ) {
                            if (e === 'IAIC_ADMINISTRATED') {
                              formContext.form.change('passiveRenewal', true);
                            } else {
                              formContext.form.change('passiveRenewal', false);
                            }
                          }
                          if (e === 'IAIC_ADMINISTRATED') {
                            formContext.form.change(
                              'postEnrollmentGroup',
                              false
                            );
                            // formContext.form.change('enrollmentDoe', false);
                            // formContext.form.change(
                            //   'enrollmentCashCollectionDoe',
                            //   false
                            // );
                          }
                        }}
                        options={
                          variation === 'demo' ? DEMO_GROUP_TYPE : GROUP_TYPE
                        }
                        searchable={false}
                        validate={required}
                        wrapperSx={commonFieldSxHalf}
                        {...rest}
                      />
                      {['IN_PROGRESS'].includes(
                        get(groups, 'groupTransitionStatus')
                      ) && (
                        <Box
                          sx={{
                            color: 'error',
                            fontSize: 2,
                            ml: 3,
                            mt: 1,
                            width: '25rem',
                          }}
                        >
                          Group Transition is under process. Please check back
                          later
                        </Box>
                      )}
                      {errorData && (
                        <Box
                          sx={{
                            color: 'error',
                            fontSize: 2,
                            ml: 3,
                            mt: 1,
                            width: '25rem',
                          }}
                        >
                          {errorData}
                        </Box>
                      )}
                      {isBenAdministeredGroup && (
                        <Field
                          key={renderKey}
                          component={InputDropdown}
                          data-cy="benAdminId"
                          disabled={
                            isRenewal
                              ? false
                              : isPostEnrollGroup ||
                                isGroupInTransition ||
                                isPostEnrollParentGroup
                          }
                          isClearable
                          label={labelHandler(
                            t('metadata.selectBenAdmin'),
                            true
                          )}
                          loadOptions={loadOptions}
                          name="benAdminId"
                          onChange={(values, meta, subValues) => {
                            if (
                              !['ACTIVE'].includes(get(groups, 'status')) ||
                              (config.midYearTransition &&
                                ['ACTIVE'].includes(get(groups, 'status')))
                            ) {
                              formContext.form.change(
                                'postEnrollmentGroup',
                                get(subValues, 'postEnrollment', false)
                              );
                            }
                            if (
                              createGroupAPIResponse.passiveRenewal &&
                              isRenewal &&
                              get(subValues, 'postEnrollment')
                            ) {
                              formContext.form.change('passiveRenewal', false);
                            }
                            if (!values && isRenewal) {
                              formContext.form.change('benAdminId', null);
                              setRenderKey(renderKey + 1);
                            }
                            if (
                              subValues?.postEnrollment !== true &&
                              isRenewal
                            ) {
                              formContext.form.change('passiveRenewal', true);
                            } else {
                              formContext.form.change('passiveRenewal', false);
                            }
                          }}
                          searchable
                          validate={required}
                          wrapperSx={commonFieldSxHalf}
                          {...rest}
                        />
                      )}
                      {variation !== 'ihc' && (
                        <Field
                          aria-label="MGA Group Number"
                          component={InputMasked}
                          label={labelHandler('MGA Group Number', true)}
                          mask={groupNumberMask}
                          name="groupNumber"
                          validate={composeValidations(required, groupNumber)}
                          wrapperSx={commonFieldSxHalf}
                          {...rest}
                        />
                      )}
                      {dropDown === 'DEMO' && (
                        <Field
                          component={InputDropdown}
                          disabled={
                            isRenewal ? !!groupId : isEditing || !!groupId
                          }
                          label={labelHandler(
                            t('groupEmployees.detailsPage.groupMGA'),
                            true
                          )}
                          name="mga"
                          options={[
                            { label: 'MGA I', value: 'PPI' },
                            {
                              label: 'MGA II',
                              value: 'FIGO',
                            },
                          ]}
                          searchable={false}
                          validate={required}
                          wrapperSx={commonFieldSxHalf}
                          {...rest}
                        />
                      )}
                      {dropDown === 'ihc' && (
                        <Field
                          aria-label={t('groupEmployees.detailsPage.groupMGA')}
                          component={InputDropdown}
                          disabled={
                            isRenewal ? !!groupId : isEditing || !!groupId
                          }
                          label={labelHandler(
                            t('groupEmployees.detailsPage.groupMGA'),
                            true
                          )}
                          name="mga"
                          options={groupMga.length > 0 ? groupMga : GROUP_MGA}
                          searchable={false}
                          validate={required}
                          wrapperSx={commonFieldSxHalf}
                          {...rest}
                        />
                      )}
                      <Field
                        aria-label="Employees of Group Have Access To Portal"
                        component={InputCheckbox}
                        disabled={['ACTIVE'].includes(get(groups, 'status'))}
                        inputWrapperSx={checkboxInputWrapperSx}
                        label="Employees of Group Have Access To Portal"
                        labelSx={labelSx}
                        name="createPortalUsers"
                        type="checkbox"
                        wrapperSx={checkboxWrapperSx}
                      />
                      <Field
                        aria-label={
                          config.canadaEnv
                            ? t('groups.businessNumber')
                            : 'Federal Number'
                        }
                        component={InputMasked}
                        label={labelHandler(
                          config.canadaEnv
                            ? t('groups.businessNumber')
                            : 'Federal Number',
                          true
                        )}
                        mask={
                          config.canadaEnv
                            ? businessNumberMask
                            : legalIdentificationNumberMask
                        }
                        name="fin"
                        validate={finValidate}
                        wrapperSx={commonFieldSxHalf}
                        {...rest}
                      />
                      <Field
                        aria-label={t('metadata.companyCode')}
                        component={InputDropdown}
                        label={labelHandler(t('metadata.companyCode'), true)}
                        name="companyCode"
                        options={COMPANY_CODES}
                        searchable={false}
                        validate={required}
                        wrapperSx={commonFieldSxHalf}
                        {...rest}
                      />
                      {enrollmentTerminationdoeConfigEnabled && (
                        <>
                          <Field
                            aria-label="Enrollment Date of Event"
                            component={InputCheckbox}
                            disabled={['ACTIVE'].includes(
                              get(groups, 'status')
                            )}
                            inputWrapperSx={checkboxInputWrapperSx}
                            label="Enrollment Date of Event"
                            labelSx={labelSx}
                            name="enrollmentDoe"
                            type="checkbox"
                            wrapperSx={checkboxWrapperSx}
                          />
                          <OnChange name="enrollmentDoe">
                            {(value) => {
                              formContext.form.change(
                                'enrollmentCashCollectionDoe',
                                value
                              );
                            }}
                          </OnChange>
                          {/* {isEnrollmentDOEEnabled && ( */}
                          <Field
                            aria-label="With Cash Collection"
                            component={InputCheckbox}
                            disabled={isGroupActive || !isEnrollmentDOEEnabled}
                            inputWrapperSx={checkboxInputWrapperSx}
                            label="With Cash Collection"
                            labelSx={labelSx}
                            name="enrollmentCashCollectionDoe"
                            type="checkbox"
                            wrapperSx={checkboxWrapperSx}
                          />
                          {/* )} */}
                          <Field
                            aria-label="Termination Date of Event"
                            component={InputCheckbox}
                            disabled={['ACTIVE'].includes(
                              get(groups, 'status')
                            )}
                            inputWrapperSx={checkboxInputWrapperSx}
                            label="Termination Date of Event"
                            labelSx={labelSx}
                            name="terminationDoe"
                            type="checkbox"
                            wrapperSx={checkboxWrapperSx}
                          />
                        </>
                      )}
                      <Field
                        aria-label={t('groups.agencyCode')}
                        component={InputText}
                        label={labelHandler(t('groups.agencyCode'), false)}
                        name="agencyCode"
                        wrapperSx={commonFieldSxHalf}
                        {...rest}
                      />
                      <Field
                        aria-label={t('groups.payrollDeductionReportLevel')}
                        component={InputDropdown}
                        label={labelHandler(
                          t('groups.payrollDeductionReportLevel')
                        )}
                        name="payrollDeductionReportLevel"
                        options={PayrollDeductionReportLevel}
                        searchable={false}
                        wrapperSx={commonFieldSxHalf}
                        {...rest}
                      />
                      {showPetCountFlag && (
                        <Field
                          aria-label="Include Pet Count in Payroll Deduction Report"
                          component={InputCheckbox}
                          inputWrapperSx={checkboxInputWrapperSx}
                          label="Include Pet Count in Payroll Deduction Report"
                          labelSx={labelSx}
                          name="showPetCount"
                          type="checkbox"
                          wrapperSx={checkboxWrapperSx}
                        />
                      )}
                      <Field
                        aria-label={t('groups.payrollDeductionInvoiceSplitBy')}
                        component={InputDropdown}
                        label={labelHandler(
                          t('groups.payrollDeductionInvoiceSplitBy')
                        )}
                        name="payrollDeductionInvoiceSplitBy"
                        options={[
                          {
                            frenchValue: 'DEPARTEMENT',
                            label: t(
                              'groupEmployees.enrollmentPage.departmentPayroll'
                            ),
                            value: 'DEPARTMENT',
                          },
                          {
                            frenchValue: 'LOCALISATION',
                            label: t('groupEmployees.enrollmentPage.location'),
                            value: 'LOCATION',
                          },
                          {
                            frenchValue: 'GENÉRIQUE',
                            label: t('groupEmployees.enrollmentPage.generic'),
                            value: 'GENERIC',
                          },
                        ]}
                        searchable={false}
                        wrapperSx={commonFieldSxHalf}
                        {...rest}
                      />
                      {shouldShowPassiveRenewalCheckbox && (
                        <Field
                          aria-label="Passive Enrollment"
                          component={InputCheckbox}
                          inputWrapperSx={checkboxInputWrapperSx}
                          label="Passive Enrollment"
                          labelSx={labelSx}
                          name="passiveRenewal"
                          type="checkbox"
                          wrapperSx={checkboxWrapperSx}
                        />
                      )}
                      {dropDown === 'default' && (
                        <>
                          <Field
                            aria-label="Writing Number"
                            component={InputMasked}
                            label={labelHandler('Writing Number', true)}
                            mask={writingNumberMask}
                            name="writingNumber"
                            validate={composeValidations(
                              required,
                              writingNumber
                            )}
                            wrapperSx={commonFieldSxHalf}
                            {...rest}
                          />
                          <Field
                            aria-label="SIT Code"
                            component={InputDropdown}
                            label={labelHandler('SIT Code', true)}
                            name="sitCode"
                            options={SIT_CODES}
                            searchable={false}
                            validate={required}
                            wrapperSx={commonFieldSxHalf}
                            {...rest}
                          />
                        </>
                      )}
                      <Box
                        sx={{
                          alignItems: isRenewal ? 'flex-end' : 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          maxWidth: '25rem',
                          my: 6,
                          p: 3,
                          width: '100%',
                        }}
                      >
                        <ToolbarButton
                          bg="primaryDark"
                          isDisabled={formContext.submitting}
                          label={t('common.next')}
                          mr={0}
                          submitting={formContext.submitting}
                          type="submit"
                          width="150px"
                        />
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};
InitialSetup.propTypes = {
  isRenewal: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

InitialSetup.defaultProps = {
  isRenewal: false,
};

export default InitialSetup;
