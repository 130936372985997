import { useEffect, useState } from "react";
import i18n from "@src/utilities/i18n";
import { useTranslation } from "react-i18next";

const useViews = () => {
  const { t } = useTranslation();

  const views = {
    barrelErrors: [
      {
        description: t('metadata.allErrors'),
        filterCriteria: {},
        id: 'all-barrel-errors',
        isInitialView: true,
      },
    ],
    barrelFiles: [
      {
        description: t('metadata.allFiles'),
        filterCriteria: {},
        id: 'all-barrel-files',
        isInitialView: true,
      },
      {
        description: t('metadata.columnCheckRequired'),
        filterCriteria: {
          status: 'column-check-required',
        },
        id: 'column-check-required-barrel-files',
      },
    ],
    'ben-admin': [
      {
        description: t('metadata.allBenAdmin'),
        filterCriteria: {},
        id: 'all-ben-admin',
        isInitialView: true,
      },
    ],
    'ben-broker': [
      {
        description: t('metadata.allBroker'),
        filterCriteria: {},
        id: 'all-broker',
        isAltInitialView: true,
      },
    ],
    billing: [
      {
        description: t('metadata.allBills'),
        filterCriteria: {},
        id: 'all-billing',
      },
    ],
    'billing-notes': [
      {
        description: t('metadata.allNotes'),
        id: 'group-notes',
        isInitialView: true,
        permission: 'group.page.notes',
      },
    ],
    claims: [
      {
        description: t('metadata.allClaims'),
        filterCriteria: {},
        id: 'all-claims',
      },
    ],
    commissions: [
      {
        description: t('metadata.allcommissions'),
        filterCriteria: {},
        id: 'all-commissions',
      },
    ],
    'enrollment-group-employees': [
      {
        description: t('metadata.allEnrollments'),
        filterCriteria: {
          skipActiveCheck: 'true',
        },
        id: 'all-group-employees',
      },
      {
        description: t('metadata.allActiveEnrollments'),
        filterCriteria: {
          skipActiveCheck: 'false',
        },
        id: 'all-active-group-employees',
        isInitialView: true,
      },
    ],
    epr: [
      {
        description: t('groups.allEmployeePaymentRecords'),
        filterCriteria: {},
        id: 'all-epr',
        isInitialView: true,
        permission: 'group.eprDashboard',
      }
    ],
    errors: [
      {
        description: t('metadata.allErrors'),
        filterCriteria: {},
        id: 'all-errors',
      },
    ],
    files: [
      {
        description: t('metadata.allFiles'),
        filterCriteria: {},
        id: 'all-files',
      },
    ],
    'group-admins': [
      {
        description: t('metadata.allAdministrators'),
        filterCriteria: {},
        id: 'all-group-admins',
        isInitialView: true,
      },
    ],
    'group-employees': [
      {
        description: t('metadata.allEnrollments'),
        filterCriteria: {},
        id: 'all-group-employees',
        isInitialView: true,
      },
    ],

    groups: [
      {
        description: t('metadata.allActiveGroups'),
        filterCriteria: {
          active: true,
          status: 'ACTIVE',
        },
        id: 'all-active-groups',
        isInitialView: true,
      },
      {
        description: t('metadata.allGroups'),
        filterCriteria: {},
        id: 'all-groups',
      },
    ],
    'life-events': [
      {
        description: t('metadata.myList'),
        filterCriteria: { filterRequest: 'userType' },
        id: 'my-life-events',
        isInitialView: true,
        permission: 'lifeEvent.list.myList',
      },
      {
        description: t('metadata.myList'),
        filterCriteria: { filterRequest: 'createdFor' },
        id: 'myList2',
        isAltInitialView: true,
        permission: 'lifeEvent.list.myList2',
      },
      {
        description: t('metadata.openLifeEvents'),
        filterCriteria: { includedRequestStatus: 'UNASSIGNED,REOPEN' },
        id: 'open-life-events',
        permission: 'lifeEvent.list.open',
      },
      {
        description: t('metadata.closedLifeEvents'),
        filterCriteria: { includedRequestStatus: 'REVIEWED,DECLINED' },
        id: 'closed-life-events',
        permission: 'lifeEvent.list.close',
      },
      {
        description: t('metadata.allLifeEvents'),
        filterCriteria: { sort: 'requestedDate,desc' },
        id: 'all-life-events',
        isAltInitialView: true,
        permission: 'lifeEvent.list.all',
      },
      {
        description: t('metadata.createdByMe'),
        filterCriteria: { filterRequest: 'created' },
        id: 'qle-created-by-me',
        permission: 'lifeEvent.list.createdBy',
      },
    ],
    masterCalendar: [
      {
        description: t('metadata.defaultView'),
        filterCriteria: {
          disableClearButtton: true,
          masterBillingCalendarView: 'DEFAULT_VIEW',
          relativeDate: new Date(),
        },
        id: 'default-view',
        isInitialView: true,
      },
      {
        description: t('metadata.pastView'),
        filterCriteria: {
          disableClearButtton: true,
          masterBillingCalendarView: 'PAST_VIEW',
          relativeDate: new Date(),
        },
        id: 'past-view',
      },
    ],
    producers: [
      {
        description: t('metadata.allProducers'),
        filterCriteria: {},
        id: 'all-producers',
      },
    ],
    'report-export': [
      {
        description: t('metadata.exportRecords'),
        filterCriteria: {},
        id: 'report-export',
        isInitialView: true,
      },
    ],
    sftpErrors: [
      {
        description: t('metadata.allErrors'),
        filterCriteria: {},
        id: 'all-barrel-errors',
        isInitialView: true,
      },
    ],
    'sub-producers': [
      {
        description: t('metadata.allSubProducers'),
        filterCriteria: {},
        id: 'all-sub-producers',
      },
    ],
    users: [
      {
        description: t('metadata.allUsers'),
        filterCriteria: {},
        id: 'all-users',
      },
      {
        description: t('metadata.allActiveUsers'),
        filterCriteria: { active: '1' },
        id: 'all-active-users',
      },
      {
        description: t('metadata.allDeletedUsers'),
        filterCriteria: { deleted: '1' },
        id: 'all-deleted-users',
      },
    ],
  }

  const [allViews, setViews] = useState(views);

  useEffect(() => {
    setViews(views)
  }, [i18n.language])

  return allViews
}

export default useViews;
