import { stringify } from 'query-string';
import moment from 'moment';

const setDashboardURL = ({ filters, filterValues, page, pathname, sort }) => {
  const values = Object.keys(filterValues).reduce((res, key) => {
    const value = filterValues[key];
    const { type } = filters[key];
    if (value !== null) {
      if (type === 'DatePicker') {
        res[key] = value.map((i) => moment(i).valueOf());
      } else {
        res[key] = value;
      }
    }
    return res;
  }, {});
  const searchParams = stringify({
    filters: stringify(values),
    page: stringify(page),
    sort,
  });
  return `${pathname}${searchParams ? '?' : ''}${searchParams}`;
};
export default setDashboardURL;
