import { AuthContext } from '@basecomponents/Auth';
import Box from '@basecomponents/Box';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import QRCode from 'qrcode.react';
import React, { useContext, useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { ThemeContext } from 'styled-components';
import { required } from '@utils/validators';
import useWindowSize from '@utils/hooks/windowSize';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/Pet/Logo';
import ToolbarButton from '../../components/BaseComponents/ToolbarButton';
import AuthContainer from '../../containers/AuthContainer';
import pixelToNumber from '../../utilities/pixel-to-number';
import config, { promoImageUrl } from '../../config.json';
import useSnackbar from '../../utilities/use-snackbar';
import InputPassword from '../../components/BaseComponents/InputPassword';

const Onboarding = () => {
  const { breakpoints, shadows, colors } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);
  const { width } = useWindowSize();
  const [code, setCode] = useState('');
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const { t } = useTranslation()

  useEffect(() => {
    const effect = async () => {
      await Auth.setupTOTP(user)
        .then((code) => {
          setCode(code);
        })
        .catch((e) =>
          setErrorSnack(
            `There was an error: ${e.message}`,
            config.notificationDuration
          )
        );
    };
    effect();
  }, []);

  const backgroundSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        backgroundImage: `url(${promoImageUrl})`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '95%',
      };
    }
    return {};
  };

  const boxShadowSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        boxShadow: shadows[4],
      };
    }
    return {};
  };

  return (
    <AuthContainer>
      {() => (
        <Box
          bg={{ _: 'primary', lg: 'white' }}
          display="flex"
          flexDirection={{ _: 'column', lg: 'row' }}
          height="100vh"
          width="100%"
        >
          <Box
            bg={{ _: 'primary', lg: 'white' }}
            display="flex"
            height={{ lg: '100%' }}
            justifyContent={{ _: 'center', lg: 'flex-start' }}
            sx={{ ...backgroundSx() }}
            width={{ lg: '50%' }}
          >
            {width >= pixelToNumber(breakpoints[3]) ? (
              <Logo
                marginLeft={{ lg: 5 }}
                mono={width < pixelToNumber(breakpoints[3])}
                sx={{
                  mt: '30px',
                  top: '0',
                }}
              />
            ) : (
              <Logo
                mono
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: '50px',
                  p: 1,
                  svg: {
                    height: 'auto',
                  },
                  width: '20rem',
                }}
              />
            )}
            {width >= pixelToNumber(breakpoints[3]) && (
              <Box
                as="h1"
                color="primary"
                fontSize={7}
                sx={{
                  ml: 5,
                  mt: 5,
                  position: [null, null, null, null, 'absolute'],
                  top: '13rem',
                  width: '30%',
                }}
              >
                <Box>{t('auth.subtitle1')}</Box>
                <Box>{t('auth.subtitle2')}</Box>
              </Box>
            )}
          </Box>
          <Box
            alignItems={['flex-start', null, 'center']}
            bg="primary"
            display="flex"
            height="auto"
            justifyContent="center"
            p={5}
            sx={{
              ...boxShadowSx(),
            }}
            width={{ lg: '50%' }}
          >
            <FinalForm
              onSubmit={async ({ passcode }) => {
                try {
                  await Auth.verifyTotpToken(user, passcode).then(async () => {
                    await Auth.setPreferredMFA(user, 'TOTP').then(() => {
                      setSucessSnack(
                        'Successfully Verified Passcode.',
                        config.notificationDuration
                      );
                      navigate('/groups/dashboard');
                    });
                  });
                } catch (e) {
                  return setErrorSnack(
                    `There was an error: ${e.message}`,
                    config.notificationDuration
                  );
                }
              }}
              render={(formContext) => (
                <Box maxWidth="maxWidths.smallForm" mx="auto" width="100%">
                  <Box
                    as="h1"
                    fontSize={7}
                    mb={[4, null, 6]}
                    mt={[4, null, 6]}
                    textAlign={{ _: 'center', lg: 'left' }}
                  >
                    Authentication Setup
                  </Box>
                  <Box
                    fontSize={3}
                    fontWeight="bold"
                    mb={6}
                    textAlign={{ _: 'center', lg: 'left' }}
                  >
                    Scan this code using your Google or Microsoft Authenticator
                    App.
                  </Box>
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <QRCode
                        bgColor={colors.primary}
                        fgColor={colors.white}
                        level="H"
                        renderAs="svg"
                        size={150}
                        value={`otpauth://totp/${config.appName}${config.variation}:${user.username}?secret=${code}&issuer=Cognito`}
                      />
                    </Box>
                    <Field
                      component={InputPassword}
                      data-cy="passcode"
                      label="Passcode"
                      labelSx={{
                        color: 'accentSecondaryDark',
                      }}
                      name="passcode"
                      validate={required}
                    />
                    <ToolbarButton
                      bg="accentSecondaryDark"
                      data-cy="submit"
                      label="Verify"
                      submitting={formContext.submitting}
                      sx={{ mt: 6 }}
                      type="submit"
                      width="100%"
                    />
                  </form>
                </Box>
              )}
            />
          </Box>
        </Box>
      )}
    </AuthContainer>
  );
};

// Onboarding.propTypes = {
//   location: PropTypes.shape({
//     state: PropTypes.shape({
//       user: PropTypes.shape({}).isRequired,
//     }).isRequired,
//   }).isRequired,
// };

export default Onboarding;
