import React from 'react';
import styled from 'styled-components';
import Box from '@basecomponents/Box';
import i18n from '@src/utilities/i18n';
import config from '../../../../config.json';
import CopyRight from './CopyRight';
import FooterLinks from './FooterLinks';

const StyledFooter = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: ${(p) => p.theme.space[3]};
  padding: ${(p) => p.theme.space[4]} 0;
  color: ${(p) => p.theme.colors.textSubtle};
  width: 100%;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows[2]};
  height: 'auto';

  @media screen and (max-width: ${(p) => p.theme.breakpoints[2]}) {
    justify-content: center;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${(p) => p.theme.colors.accentDark};
    }
  }
`;

const InnerBox = styled(Box)`
  width: calc(100vw - ${(p) => p.theme.sizes.widths.sidebarMin});
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: ${(p) => p.theme.space[4]};
  height: 100%;
  align-items: center;
`;

/**
 * @category Components
 * @returns {React.FC}
 */
const Footer = () => {
  return (
  <StyledFooter>
    <InnerBox>
    <CopyRight
      append={config.canadaEnv ? config.copyrightSuffixCanada : config.copyrightSuffix}
      sx={{
        display: 'flex',
        fontSize: 2,
        justifyContent: ['center', null, null, 'left'],
        px: [2, null, null, 5],
        textAlign: 'center',
        width: ['100%', null, null, '50%'],
      }}
    />
      
      <Box sx={{ mx: 'auto', width: ['100%', null, null, '50%'] }}>
        <nav>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: ['center', null, null, 'flex-end'],
            }}
          >
            {!config.canadaEnv && config.footerLinks.map((footer, i) => (
              <Box key={i} sx={{ alignSelf: 'center', mx: 5 }}>
                <FooterLinks
                  link={footer.link}
                  name={footer.name}
                  type={footer.type}
                />
              </Box>
            ))}
            {config.canadaEnv && config.footerCanadaLinks.map((footerCanada, i) => (
              <Box key={i} sx={{ alignSelf: 'center', mx: 5 }}>
                <FooterLinks
                  link={i18n.language === 'fr' ? footerCanada.linkFr : footerCanada.link}
                  name={i18n.language === 'fr' ? footerCanada.nameFr : footerCanada.name}
                  type={footerCanada.type}
                />
              </Box>
            ))}
          </Box>
        </nav>
      </Box>
    </InnerBox>
  </StyledFooter>
);
};

export default Footer;
