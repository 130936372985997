import { navigate, useParams } from '@reach/router';
import { AuthContext } from '@basecomponents/Auth';
import Box from '@basecomponents/Box';
import { diff } from 'deep-object-diff';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { required } from '@utils/validators';
import { labelHandler } from '@utils/label-utils';
import { useTranslation } from 'react-i18next';
import remoteActionQuery from '../../../../../graphql/queries/remote-action.gql';
import GetData from '../../../../../utilities/get-data';
import useSnackbar from '../../../../../utilities/use-snackbar';
import InputDropdown from '../../../../BaseComponents/Dropdown';
import EditAccessRightsContext from '../../../EditAccessRights/Context';
import InputText from '../../../../BaseComponents/Input';
import InputDate from '../../../../BaseComponents/InputDate';
import InputHours from '../../../../BaseComponents/InputHours';
// import InputMoney from '../../../BaseComponents/InputMoney';
import Spinner from '../../../../BaseComponents/Spinner';
import ToolbarButton from '../../../../BaseComponents/ToolbarButton';
import gaEvent from '../../../../../utilities/gaEvent';
import generateRedirectPath from '../../../../../utilities/generate-redirect-path';
import config from '../../../../../config.json';
import dateUtils from '../../../../../utilities/date-utils';

/**
 * @category Employee
 * @param {Object} location
 * @param {string} name
 * @param {*} rest
 * @returns {React.FC}
 */
const EmploymentInfo = ({ location, name, ...rest }) => {
  const start = Date.now();

  const queryParams = useParams();
  const ear = useContext(EditAccessRightsContext);
  const disabled = !ear.isEditable('groupEmployee.form.enroll.employmentInfo');
  const isEditing = get(location, 'pathname', '').includes(
    '/group-employees/edit/'
  );
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const employeeId = get(queryParams, 'employeeId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const { apiData: employmentInfo, loading } = GetData(
    'get-employment-info',
    JSON.stringify({ employeeId })
  );
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const userId = get(user, 'customSystemUserId', '');

  const employeeDetails = get(employmentInfo, '[0].employees', {});
  const locations = get(employmentInfo, '[0].locations.content', []);
  const options = get(employmentInfo, '[1]', {});
  const { t } = useTranslation()

  const employeeResponse = {
    avgHrsPerWeek: get(employeeDetails, 'avgHrsPerWeek', ''),
    dateOfHire: dateUtils.setOffset2(get(employeeDetails, 'dateOfHire', null)),
    department: get(employeeDetails, 'department', ''),
    fullTimePartTimeCode: get(employeeDetails, 'fullTimePartTimeCode', ''),
    jobTitle: get(employeeDetails, 'jobTitle', ''),
    locationId: get(employeeDetails, 'locationId', ''),
  };

  const fullTimePartTimeCode = get(options, 'FullTimePartTimeCode', []);

  const formatAvgHrs = (value) => {
    let newValue = value;
    if (typeof value === 'string') {
      newValue = parseInt(value.replace(/hr\(s\)/, ''), 10);
    }
    return newValue;
  };

  if (loading) {
    return <Spinner />;
  }

  const locationOptions = locations.map((item) => ({
    canEnglishValue: item.name,
    frenchValue: item.name,
    label: item.name,
    value: item.locationId,
  }));

  return (
    <ApolloConsumer>
      {(client) => {
        const onUpdateEmployee = (values) => {
          gaEvent('Enrollment', 'Employment Information Set', role, start);
          const employeeDiff = diff(employeeResponse, values);
          if (Object.keys(employeeDiff).length) {
            const updatedValues = {
              ...employeeDiff,
              avgHrsPerWeek: formatAvgHrs(values.avgHrsPerWeek),
              dateOfHire: dateUtils.setAPIDateOnly(values.dateOfHire),
            };
            return client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'update-employee',
                  params: JSON.stringify({
                    ...updatedValues,
                    employeeId,
                    userId,
                  }),
                },
              })
              .then(() => {
                return isEditing
                  ? navigate(`/group-employees/${employeeId}/details`)
                  : navigate(
                    generateRedirectPath({
                      ...location,
                      queryParams: { [name]: 2 },
                    })
                  );
              })
              .catch((e) =>
                setErrorSnack(
                  t('snack.error.errorWithMessage', { message: e.message }),
                  config.notificationDuration
                )
              );
          }
          return isEditing
            ? navigate(`/group-employees/${employeeId}/details`)
            : navigate(
              generateRedirectPath({
                ...location,
                queryParams: { [name]: 2 },
              })
            );
        };
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={{ ...employeeResponse }}
              onSubmit={(values) =>
                // eslint-disable-next-line no-nested-ternary
                disabled
                  ? isEditing
                    ? navigate(`/group-employees/${employeeId}/details`)
                    : navigate(
                      generateRedirectPath({
                        ...location,
                        queryParams: { [name]: 2 },
                      })
                    )
                  : onUpdateEmployee(values)
              }
              render={(formContext) => {
                return (
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        padding: 3,
                      }}
                    >
                      <Box
                        as="h3"
                        color="black"
                        display="flex"
                        justifyContent="flex-start"
                        sx={{
                          color: 'primary',
                          margin: '20px 0px 20px 0px;',
                          padding: 3,
                          width: '25rem',
                        }}
                      >
                        {t('groupEmployees.enrollmentPage.employmentInfo')}
                      </Box>
                      <Box>
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.officeLocation')}
                          component={InputDropdown}
                          disabled={disabled}
                          label={labelHandler(t('groupEmployees.enrollmentPage.officeLocation'), false)}
                          name="locationId"
                          options={locationOptions}
                          // validate={disabled ? null : required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.dateOfHire')}
                          component={InputDate}
                          disabled={disabled}
                          label={labelHandler(t('groupEmployees.enrollmentPage.dateOfHire'), true)}
                          name="dateOfHire"
                          validate={disabled ? null : required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.averageHrs')}
                          component={InputHours}
                          disabled={disabled}
                          label={labelHandler(t('groupEmployees.enrollmentPage.averageHrs'), false)}
                          name="avgHrsPerWeek"
                          wrapperSx={{
                            ...commonFieldSxHalf,
                          }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.fullTimePartTimeCode')}
                          component={InputDropdown}
                          disabled={disabled}
                          label={labelHandler(t('groupEmployees.enrollmentPage.fullTimePartTimeCode'), false)}
                          name="fullTimePartTimeCode"
                          options={fullTimePartTimeCode}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.department')}
                          component={InputText}
                          disabled={disabled}
                          label={labelHandler(t('groupEmployees.enrollmentPage.department'), false)}
                          name="department"
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.jobTitle')}
                          component={InputText}
                          disabled={disabled}
                          label={labelHandler(t('groupEmployees.enrollmentPage.jobTitle'), false)}
                          name="jobTitle"
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                      </Box>

                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          maxWidth: '25rem',
                          my: 6,
                          p: 3,
                          width: '100%',
                        }}
                      >
                        <ToolbarButton
                          bg="accent"
                          label={t('common.previous')}
                          ml={0}
                          onClick={() => {
                            return navigate(
                              generateRedirectPath({
                                ...location,
                                queryParams: {
                                  [name]: 0,
                                },
                              })
                            );
                          }}
                          width="10rem"
                        />
                        <ToolbarButton
                          bg="accentSecondary"
                          isDisabled={formContext.submitting}
                          label={isEditing ? t('common.submit') : t('common.next')}
                          mr={0}
                          submitting={formContext.submitting}
                          type="submit"
                          width="10rem"
                        />
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

EmploymentInfo.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
};

EmploymentInfo.defaultProps = {
  name: 'step',
};

export default EmploymentInfo;
