import React from 'react';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import InputMasked from '../InputMasked';

/**
 * @category BaseComponents
 * @param {string} defaultValue
 * @param {*} rest
 * @returns {React.FC}
 */
const InputDisabled = (props) => {
  const { defaultValue, ...rest } = props;

  return (
    <InputMasked
      // autoComplete="new-password"
      defaultValue={defaultValue}
      disabled
      mask={createNumberMask({
        allowDecimal: false,
        integerLimit: 3,
        prefix: '',
        suffix: '',
      })}
      {...rest}
    />
  );
};

InputDisabled.propTypes = {
  defaultValue: PropTypes.string,
};

InputDisabled.defaultProps = {
  defaultValue: '',
};

export default InputDisabled;
