import Button from '@basecomponents/Button';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';
import blacklistProps from '../../../../../utilities/blacklist-props';
import Icon from '../../../../Icon';
import config from '../../../../../config.json';

const { moduleMapping } = config;

const List = styled.ul`
  font-weight: ${(p) => p.theme.fontWeights.light};
  width: ${(p) => p.theme.sizes.widths.sidebar};
  ${(p) =>
    p.nested &&
    css`
      padding: ${(p) => p.theme.space[3]} 0 ${(p) => p.theme.space[3]}
        ${(p) => p.theme.space[6]};
      border-top: solid 1px ${(p) => p.theme.colors.borderSecondary};
    `}
`;

List.propTypes = {
  nested: PropTypes.bool.isRequired,
};

const ListItem = styled.li`
  width: 100%;
`;

ListItem.propTypes = {
  nested: PropTypes.bool.isRequired,
};

const Item = css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${(p) => p.theme.space[4]} ${(p) => p.theme.space[6]}
    ${(p) => p.theme.space[4]} calc(${(p) => p.theme.space[5]} + 0.6rem);
  color: ${(p) => p.theme.colors.primary};
  text-decoration: none;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  transition: color 0.25s ease-in-out;

  ${(p) =>
    !p.nested &&
    css`
      color: ${(p) => p.theme.colors.primary};
      transition: color 0.25s ease-in-out;
      .icon {
        color: ${(p) => p.theme.colors.primary};
        transition: color 0.25s ease-in-out;
      }
    `}

  ${(p) =>
    !p.nested &&
    p.active &&
    css`
      background-color: ${(p) => config.canadaEnv ? p.theme.colors.accentSecondary : p.theme.colors.primary};
      color: ${(p) => config.canadaEnv ? p.theme.colors.primary : p.theme.colors.white};
      transition: color 0.25s ease-in-out;
      .icon {
        color: ${(p) => config.canadaEnv ? p.theme.colors.primary : p.theme.colors.white};
        transition: color 0.25s ease-in-out;
      }
    `}
`;

const StyledButton = styled(
  blacklistProps({ as: Button, blacklist: ['active', 'toggled'] })
)`
  ${Item};
  justify-content: space-between;
  transition: color 0.25s ease-in-out;
  ${(p) =>
    !p.active &&
    css`
      color: ${(p) => p.theme.colors.primary};
      transition: color 0.25s ease-in-out;
      &:hover {
        svg {
          color: ${(p) => p.theme.colors.primary};
          transition: color 0.25s ease-in-out;
        }
      }
    `}
`;

StyledButton.propTypes = {
  active: PropTypes.bool.isRequired,
  toggled: PropTypes.bool.isRequired,
};

const StyledLink = styled(
  blacklistProps({ as: Link, blacklist: ['active', 'nested'] })
)`
  ${Item};
  transition: color 0.25s ease-in-out;
  ${(p) =>
    !p.active &&
    css`
      color: ${(p) => p.theme.colors.primary};
      svg {
        color: ${(p) => p.theme.colors.primary};
        transition: color 0.25s ease-in-out;
      }
      transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
      &:hover {
        background-color: ${(p) => config.canadaEnv ? p.theme.colors.accentSecondary : lighten(0, p.theme.colors.primary)};
        color: ${(p) => config.canadaEnv ? p.theme.colors.primary : p.theme.colors.white};
        transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
        svg {
          color: ${(p) => config.canadaEnv ? p.theme.colors.primary : p.theme.colors.white};
          transition: color 0.25s ease-in-out;
        }
      }
    `}
`;

StyledLink.propTypes = {
  active: PropTypes.bool.isRequired,
  nested: PropTypes.bool.isRequired,
};

/**
 * @category Components
 * @param {Object<any>} props
 * @returns {React.Node}
 */
const StyledIcon = (props) => (
  <Icon className="icon" minWidth="1.5rem" mr={6} width="1.5rem" {...props} />
);

/**
 * @category Components
 * @param {Object} item
 * @param {any} nested
 * @param {function} onLinkClick
 * @returns {React.FC}
 */
const renderedItem = (item, nested, onLinkClick, language) => {
  return (
    <ListItem key={item.id} nested={nested}>
      <StyledLink
        active={item.active}
        aria-label={get(moduleMapping, [item.id, `label-${language}`], get(moduleMapping, [item.id, 'label-en'], item.id))}
        nested={nested}
        onClick={onLinkClick}
        to={item.link || get(moduleMapping, [item.id, 'link'], '/')}
      >
        <StyledIcon svg={get(moduleMapping, [item.id, 'icon'], 'dashboard')} />
        {
          get(moduleMapping, [item.id, `label-${language}`], get(moduleMapping, [item.id, 'label-en'], item.id))
        }
      </StyledLink>
    </ListItem>
  );
};

/**
 * @category Components
 * @param {Array<any>} items
 * @param {boolean} nested
 * @returns {React.FC}
 */
const NavList = ({ items, nested }) => {
  const { i18n } = useTranslation()
  return (
    <List nested={nested}>
      {items.map((item) => {
        return renderedItem(item, nested, null, i18n.language);
      })}
    </List>
  );
};

NavList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      children: PropTypes.arrayOf(PropTypes.shape({})),
      icon: PropTypes.string,
      link: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  nested: PropTypes.bool,
};

NavList.defaultProps = {
  nested: false,
};

const StyledNav = styled.nav`
  border-bottom: none;
`;

/**
 * @category Components
 * @param {Array<Object>} items
 * @returns {React.FC}
 */
const Nav = ({ items }) => (
  <StyledNav>
    <NavList items={items} />
  </StyledNav>
);

Nav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      children: PropTypes.arrayOf(PropTypes.shape({})),
      icon: PropTypes.string,
      link: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default Nav;
