import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { navigate, useParams } from '@reach/router';
import { get, reject } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
import { AuthContext } from '@basecomponents/Auth';
import { useTranslation } from 'react-i18next';
import { required } from '../../../utilities/validators';
import Route from '../Route';
import InputMoney from '../../BaseComponents/InputMoney';
import InputDropdown from '../../BaseComponents/Dropdown';
import remoteActionQuery from '../../../graphql/queries/remote-action.gql';
import { labelHandler } from '../../../utilities/label-utils';
import ToolbarButton from '../../BaseComponents/ToolbarButton';
import useSnackbar from '../../../utilities/use-snackbar';
import GetData from '../../../utilities/get-data';
import config from '../../../config.json';


const cleanMoney = (amount) =>
  parseFloat(String(amount).replace('$', '').replace(',', ''));

/**
 * @category Billing
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const AddCredit = ({ ...rest }) => {
  const { t } = useTranslation()
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });

  const params = useParams();
  const groupId = get(params, 'groupId', '');
  const {
    isLoading,
    apiData: { BillingAccountType = [] },
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: ' BillingAccountType' }])
  );

  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId');

  if (isLoading) {
    return <h1>loading</h1>;
  }

  return (
    <Route
      header={{
        title: 'Add Credit',
        type: 'billing',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Add Credit
      </Box>
      <ApolloConsumer>
        {(client) => {
          const onAddCredit = async (values) => {
            const { amount, paymentType } = values;
            await client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'add-credit',
                  params: JSON.stringify({
                    amount: cleanMoney(amount),
                    groupId,
                    newBillingApiVersionEnable:
                      config.newBillingApiVersionEnable,
                    paymentType,
                    userId
                  }),
                },
              })
              .then(() => {
                setSucessSnack(
                  'Added credit successfully!',
                  config.notificationDuration
                );
                navigate(`/groups/${groupId}/details`);
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error creating the user: ${e.message}`,
                  config.notificationDuration
                )
              );
          };

          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                onSubmit={onAddCredit}
                render={({ handleSubmit, submitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          padding: 3,
                        }}
                      >
                        <Box flex="1" justifyContent="center">
                          <Field
                            aria-label={t('groups.paymentType')}
                            component={InputDropdown}
                            label={labelHandler(t('groups.paymentType'), false)}
                            name="paymentType"
                            options={reject(
                              BillingAccountType,
                              (v) => v.value === 'ACH_AUTOMATIC'
                            )}
                            validate={required}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                          <Field
                            aria-label="Amount"
                            component={InputMoney}
                            label={labelHandler('Amount', false)}
                            name="amount"
                            validate={required}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                          <ToolbarButton
                            bg="accentDark"
                            isDisabled={submitting}
                            label="Done"
                            mr={0}
                            my={6}
                            submitting={submitting}
                            type="submit"
                            width="150px"
                          />
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

AddCredit.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default AddCredit;
