export const formLabelSx = {
  color: 'accent',
  fontSize: 1,
  fontWeight: 'bold',
  letterSpacing: 0,
  textTransform: 'uppercase',
};

export const valueSx = {
  // borderBottomColor: 'accent',
  // borderBottomStyle: 'solid',
  // borderBottomWidth: 1,
  fontSize: 3,
  py: 4,
};

export const FORM_DEBUG = 'FORM_DEBUG';
export const MIN_WIDTH = 250;
