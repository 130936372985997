/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import Box from '@basecomponents/Box';
import {useApolloClient} from '@apollo/client';
import SectionData from "@basecomponents/SectionForm/SectionData";
import ToolbarButton from '@basecomponents/ToolbarButton';
import i18n from '@src/utilities/i18n';
import {navigate} from 'gatsby';
import generateRedirectPath from '@src/utilities/generate-redirect-path';
import {useLocation} from '@reach/router';
import Modal from '@basecomponents/Modal';
import React, {useContext, useState} from 'react';
import remoteActionQuery from '@queries/remote-action.gql'
import get from 'lodash/get'
import {AuthContext} from '@basecomponents/Auth';
import useSnackbar from '@src/utilities/use-snackbar';
import {produce} from 'immer';
import GetData from '@src/utilities/get-data';
import Spinner from '@basecomponents/Spinner';
import {parse} from 'query-string';
import dateUtils from '@src/utilities/date-utils';
import {isEmpty} from 'lodash';

const name = "step"

const apiCall = client => (name, params) => {
    return client.query({
        fetchPolicy: 'no-cache',
        query: remoteActionQuery,
        variables: {
            name,
            params: JSON.stringify(params),
        },
    });
};

function FillSectionButtons({submitting, handleSubmit}) {
    const [setErrorSnack] = useSnackbar({color: 'error'});
    const [setSuccessSnack] = useSnackbar({color: 'accent'});
    const location = useLocation()
    const [modal, setModal] = useState(false)
    return <Box alignItems="center" display="flex" p={4}>
        <Modal
            closeText="No"
            isOpen={modal}
            onClose={() => navigate("/groups/dashboard")}
            onSubmit={async () => {
                await handleSubmit()
                    .then(async () => {
                        return navigate("/groups/dashboard");
                    })
                    .catch(async (err) => {
                        setErrorSnack(`Error : ${err.message}`)
                    })
            }}
            submitText="Yes"
            title="Warning"
        >
            Do you want to save the changes?
        </Modal>
        <ToolbarButton
            isLoading={submitting}
            label="Close"
            onClick={() => {
                setModal(true)
            }}
        />
        <ToolbarButton
            isLoading={submitting}
            label="Save"
            onClick={async () => {
                await handleSubmit()
                    .then((groupId) => {
                        setSuccessSnack("Group note saved successfully.")
                        return navigate(
                            generateRedirectPath({
                                ...location,
                                queryParams: {[name]: 0, groupId},
                            })
                        );
                    })
                    .catch((err) => {
                        setErrorSnack(`Error : ${err.message}`)
                    })
            }}
        />
        <ToolbarButton
            bg="primary"
            isLoading={submitting}
            label={i18n.t('common.next')}
            onClick={async () => {
                await handleSubmit()?.then((groupId) => {
                    if (groupId) {
                        return navigate(
                            generateRedirectPath({
                                ...location,
                                queryParams: {[name]: 1, groupId},
                            })
                        );
                    }
                })
                    .catch((err) => {
                        setErrorSnack(`Error : ${err.message}`)
                    })
            }}
        />
    </Box>
}

const SectionForm = ({
                         sections,
                         noteId
                     }) => {
    const {user} = useContext(AuthContext);
    const userId = get(user, 'customSystemUserId', '');
    const client = useApolloClient()
    const location = useLocation()
    const groupId = parse(location.search)?.groupId
    const {apiData: groupData, loading: groupLoading} = GetData(
        'get-group-by-id',
        JSON.stringify({
            id: groupId
        }),
        groupId == null
    )
    const noteIdFromGroup = noteId || groupData?.groupNote?.groupNoteId
    const {apiData, loading} = GetData(
        'get-group-note',
        JSON.stringify({
            noteId: noteIdFromGroup
        }),
        noteIdFromGroup == null
    );
    const isUpdate = noteIdFromGroup != null
    const initialValues = produce(apiData, draft => {
        if (draft?.sit == null) {
            draft.sit = {}
        }
        draft.sit.city = draft?.sitCity
        let addresLine1
        let addresLine2;
        if (isEmpty(draft?.sitAddress)) {
            addresLine1 = ''
            addresLine2 = ''
        }
        const splitSitAddress = draft?.sitAddress?.split(',')
        if (splitSitAddress?.length === 1) {
            [addresLine1 = ''] = splitSitAddress
            addresLine2 = ''
        }
        if (splitSitAddress?.length === 2) {
            [addresLine1, addresLine2] = splitSitAddress
        }
        if (splitSitAddress?.length > 2) {
            [addresLine1, addresLine2] = [splitSitAddress[0], splitSitAddress(1).join('')]
        }
        draft.sit.addressLine1 = addresLine1?.trim()
        draft.sit.addressLine2 = addresLine2?.trim()
        draft.sit.city = draft?.sitCity
        draft.sit.name = draft.sitLocationName
        draft.sit.state = draft.sitState
        draft.sit.zipcode = draft.sitZipcode

        if (draft?.billing == null) {
            draft.billing = {}
        }
        draft.billing.city = draft?.billingCity
        let billingAddresLine1
        let billingAddresLine2;
        if (isEmpty(draft?.sitAddress)) {
            billingAddresLine1 = ''
            billingAddresLine2 = ''
        }
        const splitBillingAddress = draft?.billingAddress?.split(',')
        if (splitBillingAddress?.length === 1) {
            [billingAddresLine1 = ''] = splitBillingAddress
            billingAddresLine2 = ''
        }
        if (splitBillingAddress?.length === 2) {
            [billingAddresLine1, billingAddresLine2] = splitBillingAddress
        }
        if (splitBillingAddress?.length > 2) {
            [billingAddresLine1, billingAddresLine2] = [splitBillingAddress[0], splitBillingAddress.slice(1).join('')]
        }
        draft.billing.addressLine1 = billingAddresLine1?.trim() || ''
        draft.billing.addressLine2 = billingAddresLine2?.trim() || ''
        draft.billing.city = draft?.billingCity
        draft.billing.name = draft?.billingLocationName
        draft.billing.state = draft?.billingState
        draft.billing.zipcode = draft?.billingZipcode
        if (isEmpty(draft?.coverageStartDate) === false) {
            draft.coverageStartDate = dateUtils.setOffset2(draft?.coverageStartDate)
        }
        if (isEmpty(draft?.coverageEndDate) === false) {
            draft.coverageEndDate = dateUtils.setOffset2(draft?.coverageEndDate)
        }
        if (isEmpty(draft?.enrollmentStartDate) === false) {
            draft.enrollmentStartDate = dateUtils.setOffset2(draft?.enrollmentStartDate)
        }
        if (isEmpty(draft?.enrollmentEndDate) === false) {
            draft.enrollmentEndDate = dateUtils.setOffset2(draft?.enrollmentEndDate)
        }
        if (isEmpty(draft?.billingDate) === false) {
            draft.billingDate = dateUtils.setOffset2(draft?.billingDate)
        }
        if (isEmpty(draft?.dueDate) === false) {
            draft.dueDate = dateUtils.setOffset2(draft?.dueDate)
        }
        if (groupData?.groupType) {
            draft.groupType = groupData.groupType;
        }
        if (groupData?.groupBenAdminXref?.benAdminId) {
            draft.benAdminId = groupData?.groupBenAdminXref?.benAdminId;
        }
        if (groupData?.postEnrollmentGroup) {
            draft.postEnrollmentGroup = groupData.postEnrollmentGroup;
        }
        if (groupData?.postEnrollmentGroup) {
            draft.disablePostEnrollmentGroup = groupData.postEnrollmentGroup;
        }

        draft.newHireWaitingPeriod = parseInt(draft?.newHireWaitingPeriod, 10)
    })
    if (loading || groupLoading) {
        return <Spinner/>
    }
    return (
        <Form
            initialValues={initialValues}
            keepDirtyOnReinitialize
            onSubmit={async (values) => {
                let newValues = produce(values, draft => {
                    draft.userId = userId;

                    if (isEmpty(draft?.sit?.addressLine1) && isEmpty(draft?.sit?.addressLine2)) {
                        draft.sitAddress = ''
                    }
                    if (isEmpty(draft?.sit?.addressLine1)) {
                        draft.sitAddress = draft?.sit?.addressLine2
                    }
                    if (isEmpty(draft?.sit?.addressLine2)) {
                        draft.sitAddress = draft?.sit?.addressLine1
                    }
                    if (isEmpty(draft?.sit?.addressLine1) === false && isEmpty(draft?.sit?.addressLine2) === false) {
                        draft.sitAddress = `${draft?.sit?.addressLine1}, ${draft?.sit?.addressLine2}`
                    }
                    draft.sitCity = draft?.sit?.city
                    draft.sitLocationName = draft?.sit?.name
                    draft.sitState = draft?.sit?.state
                    draft.sitZipcode = draft?.sit?.zipcode

                    delete draft?.sit
                    if (isEmpty(draft?.billing?.addressLine1) && isEmpty(draft?.billing?.addressLine2)) {
                        draft.billingAddress = ''
                    }
                    if (isEmpty(draft?.billing?.addressLine1)) {
                        draft.billingAddress = draft?.billing?.addressLine2
                    }
                    if (isEmpty(draft?.billing?.addressLine2)) {
                        draft.billingAddress = draft?.billing?.addressLine1
                    }
                    if (isEmpty(draft?.billing?.addressLine1) === false && isEmpty(draft?.billing?.addressLine2) === false) {
                        draft.billingAddress = `${draft?.billing?.addressLine1}, ${draft?.billing?.addressLine2}`
                    }
                    draft.billingCity = draft?.billing?.city
                    draft.billingLocationName = draft?.billing?.name
                    draft.billingState = draft?.billing?.state
                    draft.billingZipcode = draft?.billing?.zipcode
                    if (draft?.coverageStartDate) {
                        draft.coverageStartDate = dateUtils.setAPIDateOnly(draft?.coverageStartDate)
                    }
                    if (draft?.coverageEndDate) {
                        draft.coverageEndDate = dateUtils.setAPIDateOnly(draft?.coverageEndDate)
                    }
                    if (draft?.enrollmentStartDate) {
                        draft.enrollmentStartDate = dateUtils.setAPIDateOnly(draft?.enrollmentStartDate)
                    }
                    if (draft?.enrollmentEndDate) {
                        draft.enrollmentEndDate = dateUtils.setAPIDateOnly(draft?.enrollmentEndDate)
                    }
                    if (draft?.billingDate) {
                        draft.billingDate = dateUtils.setAPIDateOnly(draft?.billingDate)
                    }
                    if (draft?.dueDate) {
                        draft.dueDate = dateUtils.setAPIDateOnly(draft?.dueDate)
                    }
                    delete draft?.billing
                    if (noteIdFromGroup) {
                        draft.noteId = noteIdFromGroup
                    }
                })

                if (newValues?.sitAddress?.trim() === ',') {
                    newValues = produce(newValues, draft => {
                        draft.sitAddress = ''
                    })
                }
                if (newValues?.billingAddress?.trim() === ',') {
                    newValues = produce(newValues, draft => {
                        draft.billingAddress = ''
                    })
                }

                let action = '';
                if (
                    get(groupData, 'benAdminId', null) !== null &&
                    (values?.benAdminId !== groupData?.benAdminId ||
                        get(values, 'groupType') !== 'BEN_ADMIN_SYSTEM')
                ) {
                    action =
                        get(values, 'groupType') !== 'BEN_ADMIN_SYSTEM'
                            ? 'DELETE'
                            : 'UPDATE';
                } else {
                    action = 'CREATE';
                }
                const groupBenAdminXrefId = get(
                    groupData,
                    'groupBenAdminXref.groupBenAdminXrefId'
                ) || null;
                if (isUpdate) {
                    return apiCall(client)(
                        "update-group-note",
                        newValues
                    ).then(async (response) => {
                        const groupNoteResponse = JSON.parse(
                            get(response, 'data.remoteAction.data', '{}')
                        );
                        const groupId = groupNoteResponse?.groupId
                        if (groupId == null || groupId === '') {
                            return Promise.reject(new Error("GroupId not present"))
                        }

                        const params = {
                            groupType: values.groupType,
                            postEnrollmentGroup: values.postEnrollmentGroup,
                            userId,
                            groupId,
                        }
                        await apiCall(client)(
                            "update-group",
                            params
                        )
                        if (values?.benAdminId !== groupData?.benAdminId) {
                            const benParams = {
                                action,
                                groupBenAdminXrefs: [
                                    {
                                        "benAdminId": values.benAdminId,
                                        groupBenAdminXrefId,
                                        groupId
                                    }
                                ]
                            };
                            await apiCall(client)(
                                "add-ben-admin-to-group",
                                benParams
                            )
                        }

                        return Promise.resolve(groupId)
                    })
                }
                return apiCall(client)(
                    "create-group-note",
                    newValues
                ).then(async (response) => {
                    const groupNoteResponse = JSON.parse(
                        get(response, 'data.remoteAction.data', '{}')
                    );
                    const groupId = groupNoteResponse?.groupId
                    if (groupId == null || groupId === '') {
                        return Promise.reject(new Error("GroupId not present"))
                    }
                    const params = {
                        groupType: values.groupType,
                        postEnrollmentGroup: values.postEnrollmentGroup,
                        userId,
                        groupId,
                    }
                    await apiCall(client)(
                        "update-group",
                        params
                    )
                    if (values?.benAdminId !== groupData?.benAdminId) {
                        const benParams = {
                            action,
                            groupBenAdminXrefs: [
                                {
                                    "benAdminId": values.benAdminId,
                                    groupBenAdminXrefId,
                                    groupId
                                }
                            ]
                        };
                        await apiCall(client)(
                            "add-ben-admin-to-group",
                            benParams
                        )
                    }
                    return Promise.resolve(groupId)
                })
            }}
            render={({
                         form,
                         submitting,
                         values,
                         handleSubmit
                     }) => {
                return (
                    <Box>
                        <SectionData
                            client={client}
                            form={form}
                            sections={sections}
                            submitting={submitting}
                            values={values}/>
                        <FillSectionButtons handleSubmit={handleSubmit} submitting={submitting}/>
                    </Box>
                );
            }}
        />
    );
};

SectionForm.defaultProps = {
    noteId: ''
};

SectionForm.propTypes = {
    sections: PropTypes.shape({
        componentData: PropTypes.element
    }).isRequired,
    noteId: PropTypes.string
};

export default SectionForm;
