import { navigate } from '@reach/router';
import Box from '@basecomponents/Box';
import Button from '@basecomponents/Button';
import InputCheckbox from '@basecomponents/InputCheckbox';
import Spinner from '@basecomponents/Spinner';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { diff } from 'deep-object-diff';
import Modal from '@basecomponents/Modal';
import { required, composeValidations, email } from '@utils/validators';
import { CA_PROVINCES, PRODUCER_SUB_PRODUCER_STATUS, US_STATES } from '@utils/constants';
import { labelHandler } from '@utils/label-utils';
import { FSRAMask, phoneNumberMask } from '@utils/masks';
import { FSRAcode, phoneNumber } from '@utils/validations';
import { useTranslation } from 'react-i18next';
import InputText from '../../../BaseComponents/Input';
import InputDropdown from '../../../BaseComponents/Dropdown';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql';
import useSnackbar from '../../../../utilities/use-snackbar';
import AddressFormSection from '../../AddressFormSection';
import Icon from '../../../Icon';
import Route from '../../Route';
import ToolbarButton from '../../../BaseComponents/ToolbarButton';
import ProgressBar from '../../../BaseComponents/ProgressBar';
import GetData from '../../../../utilities/get-data';
import InputMasked from '../../../BaseComponents/InputMasked';
import generateRedirectPath from '../../../../utilities/generate-redirect-path';
import config from '../../../../config.json';

/**
 * @category Subproducer
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const UpsertSubProducer = ({ location, ...rest }) => {
  const queryParams = qs.parse(location.search);
  const producerId = get(queryParams, 'producerId', '');
  const isEditing = get(queryParams, 'isEditing', false);
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [subProducerId, setSubProducerId] = useState(
    get(queryParams, 'subProducerId', '')
  );
  const [groupInfo, setModal] = useState(null);
  const { t } = useTranslation()

  const name = 'step';
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const { apiData = {}, loading } = GetData(
    'get-subproducer-by-id',
    JSON.stringify({ id: subProducerId })
  );
  const producerDataId = get(apiData, 'producerId', producerId);

  const { apiData: producerData = {}, loading: producerLoading } = GetData(
    'get-producer-by-id',
    JSON.stringify({ id: producerDataId }),
    !producerDataId
  );
  const {
    apiData: { ContactType = [] },
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: 'ContactType' }]),
    !producerDataId
  );
  const { apiData: { content: employeeData = [] } = {} } = GetData(
    'get-all-employee',
    JSON.stringify({
      email: get(apiData, 'email', ''),
      sort: 'created,desc',
    }),
    !isEditing
  );
  const title = isEditing
    ? `Edit Sub-Producer for (${get(producerData, 'name', '')} ${get(
      producerData,
      'producerCode',
      ''
    )})`
    : `Create Sub-Producer for (${get(producerData, 'name', '')} ${get(
      producerData,
      'producerCode',
      ''
    )})`;
  const subProducerResponse = {
    authorizedStates: get(apiData, 'authorizedStates', []),
    contactId: get(apiData, 'contact.contactId'),
    contactType: get(apiData, 'contact.contactType', ''),
    email: get(apiData, 'email', ''),
    firstName: get(apiData, 'subProducerData.firstName', ''),
    isBroker: get(apiData, 'isBroker', false),
    lastName: get(apiData, 'subProducerData.lastName', ''),
    name: get(apiData, 'name', ''),
    paidToProducer: get(apiData, 'paidToProducer', false),
    phoneNumber: get(apiData, 'contact.phoneNumber', ''),
    status: get(apiData, 'status', ''),
    subProducerCode: get(apiData, 'subProducerCode', ''),
  };

  const CreateSubproducerFormSection = ({ ...rest }) => {
    return (
      <ApolloConsumer>
        {(client) => {
          const onClickAction = async (values) => {
            const {
              authorizedStates: newlyAddedStates,
              contactId,
              contactType,
              phoneNumber,
              firstName,
              lastName,
              ...restValues
            } = values;
            const apiCall = (pathName, params) =>
              client.query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: pathName,
                  params: JSON.stringify(params),
                },
              });
            if (subProducerId) {
              const {
                authorizedStates,
                contactId: savedContactId,
                contactType: savedContactType,
                firstName: savedFirstName,
                lastName: savedLastName,
                phoneNumber: savedPhoneNumber,
                ...rest
              } = subProducerResponse;

              const subProducerDiff = diff(rest, restValues);
              const subProducerNameDiff = diff(
                { firstName: savedFirstName, lastName: savedLastName },
                { firstName, lastName }
              );
              const stateDiffPayload = newlyAddedStates
                .filter((x) => !authorizedStates.includes(x))
                .concat(
                  authorizedStates.filter((x) => !newlyAddedStates.includes(x))
                );
              const contactDiff = diff(
                {
                  contactId: savedContactId,
                  contactType: savedContactType,
                  phoneNumber: savedPhoneNumber,
                },
                {
                  contactId,
                  contactType,
                  phoneNumber,
                }
              );
              if (
                contactId &&
                (Object.keys(contactDiff).length || contactDiff.length)
              ) {
                apiCall('update-subproducer-contact', {
                  ...contactDiff,
                  id: contactId,
                }).catch((e) => {
                  setErrorSnack(
                    `There was an error: ${e.message}`,
                    config.notificationDuration
                  );
                });
              }
              // Start Remove this code once old subproducer contacts get added
              if (!contactId) {
                return apiCall('create-subproducer-contact', {
                  contactType: values.contactType,
                  phoneNumber: values.phoneNumber,
                }).then((contactResponse) => {
                  const resContactId = get(
                    JSON.parse(
                      get(contactResponse, 'data.remoteAction.data', '')
                    ),
                    'contactId',
                    ''
                  );
                  if (!resContactId) {
                    return setErrorSnack(
                      'Something went wrong. Please try again.',
                      config.notificationDuration
                    );
                  }
                  const paramData = {
                    deleted: false,
                    id: subProducerId,
                    isBroker: get(values, 'isBroker', false),
                  };
                  if (stateDiffPayload.length) {
                    paramData.authorizedStates = newlyAddedStates;
                  }
                  if (Object.keys(subProducerNameDiff).length) {
                    paramData.name = `${firstName} ${lastName}`;
                    paramData.subProducerData = {
                      firstName,
                      lastName,
                    };
                  }
                  return apiCall('update-subproducer', {
                    ...subProducerDiff,
                    contactId: resContactId,
                    ...paramData,
                  })
                    .then((response) => {
                      const subProducerId = get(
                        JSON.parse(get(response, 'data.remoteAction.data', '')),
                        'subProducerId',
                        ''
                      );
                      setSubProducerId(subProducerId);
                      if (!subProducerId) {
                        return setErrorSnack(
                          'Something went wrong. Please try again.',
                          config.notificationDuration
                        );
                      }
                      return navigate(
                        generateRedirectPath({
                          ...location,
                          queryParams: { [name]: 1, subProducerId },
                        })
                      );
                    })
                    .catch((e) =>
                      setErrorSnack(
                        `There was an error: ${e.message}`,
                        config.notificationDuration
                      )
                    );
                });
              }
              // End Remove this code once old subproducer contacts get added
              if (
                Object.keys(subProducerDiff).length ||
                Object.keys(subProducerNameDiff).length ||
                stateDiffPayload.length
              ) {
                const paramData = {
                  deleted: false,
                  id: subProducerId,
                  isBroker: get(values, 'isBroker', false),
                };
                if (stateDiffPayload.length) {
                  paramData.authorizedStates = newlyAddedStates;
                }
                if (Object.keys(subProducerNameDiff).length) {
                  paramData.name = `${firstName} ${lastName}`;
                  paramData.subProducerData = {
                    firstName,
                    lastName,
                  };
                }
                return apiCall('update-subproducer', {
                  ...subProducerDiff,
                  ...paramData,
                })
                  .then((response) => {
                    const subProducerId = get(
                      JSON.parse(get(response, 'data.remoteAction.data', '')),
                      'subProducerId',
                      ''
                    );
                    setSubProducerId(subProducerId);
                    if (!subProducerId) {
                      return setErrorSnack(
                        'Something went wrong. Please try again.',
                        config.notificationDuration
                      );
                    }
                    return navigate(
                      generateRedirectPath({
                        ...location,
                        queryParams: { [name]: 1, subProducerId },
                      })
                    );
                  })
                  .catch((e) =>
                    setErrorSnack(
                      `There was an error: ${e.message}`,
                      config.notificationDuration
                    )
                  );
              }
              return navigate(
                generateRedirectPath({
                  ...location,
                  queryParams: { [name]: 1 },
                })
              );
            }
            return apiCall('create-subproducer-contact', {
              contactType: values.contactType,
              phoneNumber: values.phoneNumber,
            }).then((contactResponse) => {
              const resContactId = get(
                JSON.parse(get(contactResponse, 'data.remoteAction.data', '')),
                'contactId',
                ''
              );
              if (!resContactId) {
                return setErrorSnack(
                  'Something went wrong. Please try again.',
                  config.notificationDuration
                );
              }
              return apiCall('create-subproducer', {
                ...values,
                contactId: resContactId,
                isPromoteEmployee: false,
                name: `${firstName} ${lastName}`,
                producerId,
                subProducerData: {
                  firstName,
                  lastName,
                },
              })
                .then((response) => {
                  const subProducerId = get(
                    JSON.parse(get(response, 'data.remoteAction.data', '')),
                    'subProducerId',
                    ''
                  );
                  setSubProducerId(subProducerId);
                  if (!subProducerId) {
                    return setErrorSnack(
                      'Something went wrong. Please try again.',
                      config.notificationDuration
                    );
                  }
                  return navigate(
                    generateRedirectPath({
                      ...location,
                      queryParams: { [name]: 1, subProducerId },
                    })
                  );
                })
                .catch((e) => {
                  const groupId = get(e, 'message', '').split('with groupId');
                  if (groupId && groupId.length > 1) {
                    const groupName = groupId[0].split('group ')[1];
                    return setModal({ groupId: groupId[1].trim(), groupName });
                  }
                  return setErrorSnack(
                    `There was an error: ${e.message}`,
                    config.notificationDuration
                  );
                });
            });
          };
          if (!isEmpty(groupInfo)) {
            return (
              <Modal
                closeText={t('common.cancel')}
                isOpen
                onClose={() => {
                  setModal(null);
                }}
                onSubmit={() => {
                  navigate(`/groups/${groupInfo?.groupId}/details`);
                }}
                submitText="promote"
                title="User already exists"
              >
                <Box>
                  This user is already employee of {groupInfo?.groupName}. Do
                  you wish to promote this user to broker?
                </Box>
              </Modal>
            );
          }
          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                initialValues={{ ...subProducerResponse }}
                onSubmit={onClickAction}
                render={(formContext) => {
                  return (
                    <form onSubmit={formContext.handleSubmit}>
                      <Box
                        sx={{
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          padding: 3,
                        }}
                      >
                        <Box
                          as="h3"
                          color="black"
                          display="flex"
                          justifyContent="flex-start"
                          sx={{
                            color: 'primary',
                            margin: '20px 0px 20px 0px;',
                            padding: 3,
                            width: '25rem',
                          }}
                        >
                          Create Sub-Producer
                        </Box>
                        <Field
                          aria-label={config.canadaEnv ? t('component.canada.fsraAgentNumber') : "Sub Producer Code/NPN"}
                          component={config.canadaEnv ? InputMasked : InputText}
                          label={labelHandler(config.canadaEnv ? t('component.canada.fsraAgentNumber') : 'Sub Producer Code/NPN', true)}
                          mask={config.canadaEnv ? FSRAMask : ''}
                          name="subProducerCode"
                          validate={composeValidations(config.canadaEnv ? (required, FSRAcode) : required)}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.firstName')}
                          component={InputText}
                          disabled={!isEmpty(get(apiData, 'user', {}))}
                          label={labelHandler(t('groupEmployees.enrollmentPage.firstName'), true)}
                          name="firstName"
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.lastName')}
                          component={InputText}
                          disabled={!isEmpty(get(apiData, 'user', {}))}
                          label={labelHandler(t('groupEmployees.enrollmentPage.lastName'), true)}
                          name="lastName"
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label="Email Address"
                          component={InputText}
                          disabled={isEditing}
                          label={labelHandler('Email Address', true)}
                          name="email"
                          validate={composeValidations(required, email)}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={config.canadaEnv ? t('groupEmployees.detailsPage.authorizedProvinces') : 'Authorized States'}
                          checkIcon={<Icon svg="checkbox" width="1rem" />}
                          component={InputDropdown}
                          isMulti
                          label={labelHandler(config.canadaEnv ? t('groupEmployees.detailsPage.authorizedProvinces') : 'Authorized States', true)}
                          name="authorizedStates"
                          options={config.canadaEnv ? CA_PROVINCES : US_STATES}
                          searchable
                          uncheckIcon={<Icon svg="uncheck" width="1rem" />}
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label="Commission Assigned to Producer"
                          component={InputCheckbox}
                          label={labelHandler(
                            'Commission Assigned to Producer',
                            false
                          )}
                          name="paidToProducer"
                          type="checkbox"
                          wrapperSx={{ display: 'flex', ...commonFieldSxHalf }}
                        />
                        <Field
                          aria-label="Assigned as Broker"
                          component={InputCheckbox}
                          disabled={employeeData.length > 0}
                          label={labelHandler('Assigned as Broker', false)}
                          name="isBroker"
                          type="checkbox"
                          wrapperSx={{ display: 'flex', ...commonFieldSxHalf }}
                        />
                        <Field
                          aria-label={t('metadata.status')}
                          component={InputDropdown}
                          label={labelHandler(t('metadata.status'), true)}
                          name="status"
                          options={PRODUCER_SUB_PRODUCER_STATUS}
                          searchable={false}
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.phoneNumberType')}
                          component={InputDropdown}
                          label={labelHandler(t('groupEmployees.enrollmentPage.phoneNumberType'), true)}
                          name="contactType"
                          options={ContactType}
                          searchable={false}
                          validate={required}
                          wrapperSx={commonFieldSxHalf}
                          {...rest}
                        />
                        <Field
                          aria-label="Primary Phone Number"
                          component={InputMasked}
                          label={labelHandler('Primary Phone Number', true)}
                          mask={phoneNumberMask}
                          name="phoneNumber"
                          validate={composeValidations(required, phoneNumber)}
                          wrapperSx={commonFieldSxHalf}
                          {...rest}
                        />
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            my: 6,
                            p: 2,
                          }}
                        >
                          <ToolbarButton
                            bg="primaryDark"
                            label={t('common.next')}
                            mr={0}
                            submitting={formContext.submitting}
                            type="submit"
                            width="10rem"
                          />
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
    );
  };

  const CreateSubproducerSitusFormSection = ({ ...rest }) => {
    return (
      <ApolloConsumer>
        {(client) => {
          const onClickAction = async (values) => {
            const { address } = values;
            const addressId = get(address, 'addressId', '');
            const apiCall = (pathName, params) =>
              client
                .query({
                  fetchPolicy: 'no-cache',
                  query: remoteActionQuery,
                  variables: {
                    name: pathName,
                    params: JSON.stringify(params),
                  },
                })
                .then((response) => {
                  const savedAddressId = get(
                    JSON.parse(get(response, 'data.remoteAction.data', '')),
                    'addressId',
                    ''
                  );
                  if (!savedAddressId) {
                    return setErrorSnack(
                      'Something went wrong. Please try again.',
                      config.notificationDuration
                    );
                  }
                  if (addressId !== savedAddressId) {
                    return client
                      .query({
                        fetchPolicy: 'no-cache',
                        query: remoteActionQuery,
                        variables: {
                          name: 'update-subproducer',
                          params: JSON.stringify({
                            active: true,
                            addressId: savedAddressId,
                            deleted: false,
                            id: subProducerId,
                            isBroker: get(apiData, 'isBroker', false),
                          }),
                        },
                      })
                      .then((response) => {
                        const updatedAddressId = get(
                          JSON.parse(
                            get(response, 'data.remoteAction.data', '')
                          ),
                          'addressId',
                          ''
                        );
                        if (savedAddressId !== updatedAddressId) {
                          return setErrorSnack(
                            'Something went wrong. Please try again.',
                            config.notificationDuration
                          );
                        }
                        return navigate('/sub-producers/dashboard');
                      })
                      .catch((e) =>
                        setErrorSnack(
                          `There was an error: ${e.message}`,
                          config.notificationDuration
                        )
                      );
                  }
                  return navigate('/sub-producers/dashboard');
                })
                .catch((e) =>
                  setErrorSnack(
                    `There was an error: ${e.message}`,
                    config.notificationDuration
                  )
                );
            if (addressId) {
              const subProducerAddDiff = diff(apiData.address, address);
              if (Object.keys(subProducerAddDiff).length) {
                return apiCall('update-subproducer-address', {
                  ...subProducerAddDiff,
                  id: addressId,
                });
              }
              return navigate('/sub-producers/dashboard');
            }
            return apiCall(
              'create-subproducer-address',
              JSON.stringify({
                ...address,
              })
            );
          };
          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                initialValues={{ ...apiData }}
                onSubmit={(values) => onClickAction(values)}
                render={(formContext) => {
                  return (
                    <form onSubmit={formContext.handleSubmit}>
                      <Box
                        sx={{
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          padding: 3,
                        }}
                      >
                        <Box
                          as="h3"
                          color="black"
                          display="flex"
                          justifyContent="flex-start"
                          sx={{
                            color: 'primary',
                            margin: '20px 0px 20px 0px;',
                            padding: 3,
                            width: '25rem',
                          }}
                        >
                          Sub-Producer Address
                        </Box>
                        <AddressFormSection
                          label="address"
                          wrapperSx={commonFieldSxHalf}
                          {...rest}
                        />
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            my: 6,
                          }}
                        >
                          <Button
                            onClick={() =>
                              navigate(
                                generateRedirectPath({
                                  ...location,
                                  queryParams: { [name]: 0 },
                                })
                              )
                            }
                            sx={{
                              '&:hover': { bg: 'primaryLight' },
                              bg: 'primary',
                              mx: 'auto',
                              width: '10rem',
                            }}
                          >
                            Previous
                          </Button>
                          <ToolbarButton
                            bg="primaryDark"
                            label={t('common.submit')}
                            mr={0}
                            submitting={formContext.submitting}
                            type="submit"
                            width="10rem"
                          />
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
    );
  };

  return (
    <Route
      header={{
        title,
        type: 'sub-producers',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {title}
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {loading || producerLoading ? (
          <Spinner />
        ) : (
          <ProgressBar
            defaultTab="0"
            name={name}
            slides={['Create Sub-Producer', 'Sub-Producer Address']}
            tabs={[
              {
                render: () => <CreateSubproducerFormSection {...rest} />,
                value: '0',
              },
              {
                render: () => <CreateSubproducerSitusFormSection {...rest} />,
                value: '1',
              },
            ]}
          />
        )}
      </Box>
    </Route>
  );
};

UpsertSubProducer.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default UpsertSubProducer;
