import React from 'react';
import get from 'lodash/get';
import Box from '@basecomponents/Box';
import config from '@src/config.json';
import useSsm from '@src/utilities/hooks/useSsm';
import Route from '../../Route';
import ListGrid from '../../../BaseComponents/ListGrid';
import HeaderRightContainer from '../../../BaseComponents/HeaderRightContainer';

/**
 * @category BenAdmin
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const BenAdminDashboardPage = ({ ...rest }) => {
  const filters = [
    {
      label: 'Ben Admin Name',
      name: 'name',
      type: 'Input',
    },
  ];

  const defaultFilterValues = [{ sort: 'created,desc' }];
  const postEnrollmentConfigEnabled = useSsm('iaic-post-enrollment')

  const grid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'details',
            link: `/ben-admin/${get(data, 'benAdminId')}/details`,
          };
        },
        label: 'View Details',
        name: 'details',
      },
      {
        getProps: (data) => {
          return {
            icon: 'manage',
            link: `/ben-admin/${get(data, 'benAdminId')}/user-create`,
          };
        },
        label: 'Add Users',
        name: 'details',
      },
      {
        getProps: (data) => {
          return {
            icon: 'edit',
            link: `/ben-admin/${get(data, 'benAdminId')}/edit`,
          };
        },
        label: 'Edit',
        name: 'upsert ben admin',
      },
    ],
    fields: [
      {
        name: 'name',
      },
      {
        label: config.canadaEnv ? 'Province' : 'State',
        name: 'address.state',
      },
      {
        name: 'contact.phoneNumber',
      },
      {
        name: 'active',
      },
    ],
  };
  if (postEnrollmentConfigEnabled) {
    grid.fields.push({ name: 'postEnrollment' });
  }
  return (
    <Route
      header={{
        rightContainer: (
          <HeaderRightContainer
            headerActions={[
              {
                action: `/ben-admin/create`,
                icon: 'users-add',
                label: 'Create Ben Admin',
                permission: 'benAdmin.button.create',
              },
            ]}
          />
        ),
        title: 'Dashboard',
        type: 'ben-admin',
      }}
      isPrivate
      permission="benAdmin.page.dashboard"
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Ben Admin Dashboard
      </Box>
      <ListGrid
        defaultFilterValues={defaultFilterValues}
        filters={filters}
        grid={grid}
        gridQueryName="get-all-ben-admin"
        moduleName="ben-admin"
      />
    </Route>
  );
};

export default BenAdminDashboardPage;
