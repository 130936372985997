/* eslint-disable no-console */
import { get } from 'lodash';
import { LS_CELL_DENSITY } from './constants';
import localStorage from '../../../../utilities/local-storage';

const reducer = (state, { params, type }) => {
  let result = {};
  try {
    switch (type) {
      case 'toggleFilter': {
        const LS_DISPLAY_FILTERS = `default-${get(
          state,
          'moduleName'
        )}-show-filters`;
        const showFilters = get(state, 'showFilters');
        result = {
          showFilters: !showFilters,
        };
        localStorage.setItem(LS_DISPLAY_FILTERS, String(!showFilters));
        break;
      }

      case 'onFilterChange': {
        result = {
          filterValues: params,
          offset: 0,
        };
        break;
      }

      case 'onPageClick': {
        result = {
          offset: params,
        };
        break;
      }

      case 'onPageNext': {
        result = {
          offset: get(state, 'offset', 0) + 1,
        };
        break;
      }

      case 'onPagePrev': {
        result = {
          offset: get(state, 'offset', 0) - 1,
        };
        break;
      }
      case 'onSortChange': {
        result = {
          offset: 0,
          sortDirection: get(params, 'sortDirection'),
          sortField: get(params, 'sortField'),
        };
        break;
      }
      case 'setRefreshing': {
        result = {
          isRefreshing: params,
        };
        break;
      }

      case 'setMasterBillingCalendarRefreshing': {
        result = {
          isMasterBillingCalendarRefreshing: params,
        };
        break;
      }

      case 'setExportStatus': {
        result = {
          isExportStatus: params,
        };
        break;
      }

      case 'selectView': {
        const view = params;
        result = {
          currentViewId: view.id,
          filterValues: get(view, 'filterCriteria', {}),
        };
        const LS_DEFAULT_VIEW = `default-${get(state, 'moduleName')}-view`;
        localStorage.setItem(LS_DEFAULT_VIEW, String(view.id));
        break;
      }

      case 'setDensity': {
        result = {
          cellDensity: params,
        };
        localStorage.setItem(LS_CELL_DENSITY, String(params));
        break;
      }

      default: {
        console.error(`Action ${type} not defined`);
      }
    }
  } catch (e) {
    console.error(e.message);
  }
  return { ...state, ...result };
};
export default reducer;
