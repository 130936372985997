import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import InputCheckbox from '@basecomponents/InputCheckbox';
import { diff } from 'deep-object-diff';
import arrayMutators from 'final-form-arrays';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AuthContext } from '@basecomponents/Auth';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import InputNumber from "@basecomponents/InputNumber";
import { useTranslation } from 'react-i18next';
import { composeValidations, required } from '@utils/validators';
import { labelHandler } from '@utils/label-utils';
import { groupNumberMask, NAICCodeMask, SICCodeMask } from "@utils/masks";
import { groupNumber, NAICCodeNumber, positive, SICCodeNumber } from "@utils/validations";
import { produce } from 'immer';
import InputText from '@basecomponents/Input';
import InputMasked from '@basecomponents/InputMasked';
import Spinner from '@basecomponents/Spinner';
import ToolbarButton from '@basecomponents/ToolbarButton';
import GetData from '@utils/get-data';
import useSnackbar from '@utils/use-snackbar';
import generateRedirectPath from '@utils/generate-redirect-path';
import GroupLocationsFormSection from '@petcomponents/GroupLocationsFormSection';
import remoteActionQuery from '@queries/remote-action.gql';
import config from '@src/config.json';

/**
 * @category Group
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const BasicInformation = ({ location, isRenewal, ...rest }) => {
    const { t } = useTranslation();
    const queryParams = useParams();
    const groupId = get(queryParams, 'groupId', '');
    const { user } = useContext(AuthContext);
    const userId = get(user, 'customSystemUserId', '');
    const isEditing = get(location, 'pathname', '').includes('/groups/edit/');
    const name = 'step';
    const [setErrorSnack] = useSnackbar({ color: 'error' });
    const commonFieldSxHalf = {
        padding: 3,
        width: '25rem',
    };

    const { apiData: groups = {}, loading: groupsLoading } = GetData(
        'get-group-by-id',
        JSON.stringify({ id: groupId })
    );

    const noteId = groups?.groupNote?.groupNoteId

    const { apiData: groupNote, loading: groupNoteLoading } = GetData(
        'get-group-note',
        JSON.stringify({
            noteId
        }),
        noteId == null
    )

    const {
        apiData: { content = [] },
        loading: locationLoading,
    } = GetData(
        'get-all-group-locations',
        JSON.stringify({
            groupId,
            queryParams: [{ page: 0 }],
        })
    );

    const primaryAddressIndex = content.findIndex((v) => v.isSitus);
    if (content.length) {
        content.splice(0, 0, content.splice(primaryAddressIndex, 1)[0]);
    }
    const basicInformationResponse = {
        allEligiblePersons: get(groups, 'allEligiblePersons', 0),
        alternateRegistration: get(groups, 'alternateRegistration', true),
        contributionMessage: get(groups, 'contributionMessage', ''),
        defaultEConsent: get(groups, 'defaultEConsent', false),
        groupName: get(groups, 'groupName', ''),
        groupNumber: get(groups, 'groupNumber', ''),
        isIdpInitiated: get(groups, 'isIdpInitiated', false),
        locations: content.length ? content : [{}],
        payrollDeductionGenerationEmailSend: get(
            groups,
            'payrollDeductionGenerationEmailSend',
            false
        ),
        sicCode: get(groups, 'sicCode', null),
    }

    const initialValues = content.length > 1 ? basicInformationResponse : produce(basicInformationResponse, draft => {
        draft.allEligiblePersons ??= parseInt(groupNote?.eligibleNumber, 10)
        draft.groupName ??= groupNote?.groupName
        draft.groupNumber ??= groupNote?.groupNumber
        draft.sicCode ??= groupNote?.sicCode
        const [line1, line2] = groupNote?.sitAddress?.split(',').map(x => x?.trim()) || ['', '']
        draft.locations = [{
            address: {},
            contact: {}
        }]
        draft.locations = [{
            ...get(content, '0'),
            name: isEmpty(get(basicInformationResponse, 'locations.0.name')) ? groupNote?.sitLocationName : get(basicInformationResponse, 'locations.0.name'),
            isSitus: basicInformationResponse?.locations?.[0]?.isSitus ?? true,
            address: {
                ...get(content, '0.address'),
                addressLine1: isEmpty(get(basicInformationResponse, 'locations.0.address.addressLine1')) ? line1 : get(basicInformationResponse, 'locations.0.address.addressLine1'),
                addressLine2: isEmpty(get(basicInformationResponse, 'locations.0.address.addressLine2')) ? line2 : get(basicInformationResponse, 'locations.0.address.addressLine2'),
                city: isEmpty(get(basicInformationResponse, 'locations.0.address.city')) ? groupNote?.sitCity : get(basicInformationResponse, 'locations.0.address.city'),
                state: isEmpty(get(basicInformationResponse, 'locations.0.address.state')) ? get(groupNote, 'sitState', null) : get(basicInformationResponse, 'locations.0.address.state'),
                zipcode: isEmpty(get(basicInformationResponse, 'locations.0.address.zipcode')) ? groupNote?.sitZipcode : get(basicInformationResponse, 'locations.0.address.zipcode'),
            },
            contact: {
                ...get(content, '0.contact'),
                contactType: isEmpty(get(basicInformationResponse, 'locations.0.contact.contactType')) ? groupNote?.sitPhoneType : get(basicInformationResponse, 'locations.0.contact.contactType'),
                phoneNumber: isEmpty(get(basicInformationResponse, 'locations.0.contact.phoneNumber')) ? groupNote?.sitPhoneNumber : get(basicInformationResponse, 'locations.0.contact.phoneNumber'),
            }
        }]
    })

    if (groupsLoading || locationLoading) {
        return <Spinner />;
    }

    return (
        <ApolloConsumer>
            {(client) => {
                // Helper Function
                const checkIfLocationsExist = (locations) => {
                    if (locations.length) {
                        return locations.every((item) => {
                            return !!Object.keys(item).length;
                        });
                    }
                    return false;
                };

                const isBasicInformationFilled = checkIfLocationsExist(
                    get(basicInformationResponse, 'locations', [])
                );

                const onClickAction = async (values) => {
                    if (typeof values?.allEligiblePersons === 'string') {
                        // eslint-disable-next-line no-param-reassign
                        values.allEligiblePersons = parseInt(values?.allEligiblePersons.replaceAll(",", ""), 10);
                    }
                    // API Calls
                    const locationApiCall = (path, params) =>
                        client.query({
                            fetchPolicy: 'no-cache',
                            query: remoteActionQuery,
                            variables: {
                                name: path,
                                params: JSON.stringify(params),
                            },
                        });

                    const updateGroupApiCall = (params) =>
                        client.query({
                            fetchPolicy: 'no-cache',
                            query: remoteActionQuery,
                            variables: {
                                name: 'update-group',
                                params: JSON.stringify(params),
                            },
                        });

                    // Checking of it is in edit Mode
                    let updateGroupCondition = isEditing || isBasicInformationFilled;
                    if (isRenewal) {
                        updateGroupCondition = isBasicInformationFilled;
                    }
                    if (updateGroupCondition) {
                        // Getting group related parameters
                        const updateGroupParams = {
                            allEligiblePersons: get(values, 'allEligiblePersons', 0),
                            alternateRegistration: get(values, 'alternateRegistration'),
                            contributionMessage: get(values, 'contributionMessage', ''),
                            defaultEConsent: get(values, 'defaultEConsent', false),
                            groupName: get(values, 'groupName', ''),
                            isIdpInitiated: get(values, 'alternateRegistration')
                                ? get(values, 'isIdpInitiated')
                                : false,
                            payrollDeductionGenerationEmailSend: get(
                                values,
                                'payrollDeductionGenerationEmailSend'
                            ),
                            sicCode: get(values, 'sicCode', ''),
                        };

                        // Getting group parameters from the API Response
                        const groupInfo = {
                            allEligiblePersons: get(basicInformationResponse, 'allEligiblePersons', 0),
                            alternateRegistration:
                                basicInformationResponse.alternateRegistration,
                            contributionMessage: get(
                                basicInformationResponse,
                                'contributionMessage',
                                ''
                            ),
                            defaultEConsent: basicInformationResponse.defaultEConsent,
                            groupName: basicInformationResponse.groupName,
                            isIdpInitiated: basicInformationResponse.isIdpInitiated,
                            payrollDeductionGenerationEmailSend:
                                basicInformationResponse.payrollDeductionGenerationEmailSend,
                            sicCode: basicInformationResponse.sicCode,
                        };

                        // Diffing the objects above
                        const updateGroupDiffPayload = diff(groupInfo, updateGroupParams);

                        const promises = [];

                        // Checking if diff above has any values
                        if (Object.keys(updateGroupDiffPayload).length) {
                            // Updating Group
                            const updateGroupPromise = new Promise((resolve, reject) => {
                                updateGroupApiCall({
                                    ...updateGroupDiffPayload,
                                    groupId,
                                    userId,
                                })
                                    .then((response) => {
                                        resolve(response);
                                    })
                                    .catch((error) => {
                                        reject(error);
                                        setErrorSnack(
                                            `There was an error updating : ${error.message}`,
                                            config.notificationDuration
                                        );
                                    });
                            });
                            promises.push(updateGroupPromise);
                        }

                        // Locations
                        // Getting values from locations
                        const updatedLocationValues = get(values, 'locations', []);

                        if (!updatedLocationValues.some((v) => v.isSitus)) {
                            updatedLocationValues[0].isSitus = true;
                        }

                        // Checking for deleted locations
                        const deletedLocations = content
                            .filter(
                                (v) =>
                                    !updatedLocationValues.some(
                                        (a) => a.locationId === v.locationId
                                    )
                            )
                            .map((item) => {
                                return {
                                    ...item,
                                    address: { ...item.address, deleted: true },
                                    contact: { ...item.contact, deleted: true },
                                    deleted: true,
                                };
                            });

                        // Checking for added locations
                        const addedLocations = updatedLocationValues.filter(
                            (v) => !v.locationId
                        );

                        // Checking for updated locations
                        const updatedLocations = updatedLocationValues.filter((v) =>
                            content.some((a) => {
                                if (a.locationId === v.locationId) {
                                    const updatedLocationDiff = diff(a, v);
                                    return !!Object.keys(updatedLocationDiff).length;
                                }
                                return false;
                            })
                        );
                        // Get All Existing Locations with updated value
                        const updatedAllLocations = updatedLocationValues.filter(
                            (v) => v.locationId
                        );

                        // Checking if there are any updates
                        if (updatedLocations.length || deletedLocations.length) {
                            const params = {
                                groupId,
                                locations: [...updatedAllLocations, ...deletedLocations].map(
                                    (item) => {
                                        if (item.isSitus) {
                                            return item;
                                        }
                                        return {
                                            ...item,
                                            isSitus: false,
                                        };
                                    }
                                ),
                            };

                            // Update locations API call
                            const updateLocationsPromise = new Promise((resolve, reject) => {
                                locationApiCall('update-group-locations', params)
                                    .then((response) => {
                                        resolve(response);
                                    })
                                    .catch((error) => {
                                        reject(error);
                                        setErrorSnack(
                                            `There was an error updating locations: ${error.message}`,
                                            config.notificationDuration
                                        );
                                    });
                            });
                            promises.push(updateLocationsPromise);
                        }

                        // Checking for New locations
                        if (addedLocations.length) {
                            const params = {
                                groupId,
                                locations: addedLocations.map((item) => {
                                    if (item.isSitus) {
                                        return item;
                                    }
                                    return {
                                        ...item,
                                        isSitus: false,
                                    };
                                }),
                            };

                            // Create locations API call
                            const createLocationsPromise = new Promise((resolve, reject) => {
                                locationApiCall('create-group-locations', params)
                                    .then((response) => {
                                        resolve(response);
                                    })
                                    .catch((error) => {
                                        reject(error);
                                        setErrorSnack(
                                            `There was an error creating new locations: ${error.message}`,
                                            config.notificationDuration
                                        );
                                    });
                            });
                            promises.push(createLocationsPromise);
                        }

                        // Making all requests
                        return Promise.all(promises)
                            .then(() => {
                                navigate(
                                    generateRedirectPath({
                                        ...location,
                                        queryParams: { [name]: isRenewal ? 3 : 2 },
                                    })
                                );
                            })
                            .catch((e) =>
                                setErrorSnack(
                                    `There was an error: ${e.message}`,
                                    config.notificationDuration
                                )
                            );
                    }

                    if (!isRenewal) {
                        // If not editing or not updating,
                        const locations = get(values, 'locations', []);
                        if (!locations.some((v) => v.isSitus)) {
                            locations[0].isSitus = true;
                        }

                        // Getting all parameters
                        const params = {
                            groupId,
                            locations: locations.map((item) => {
                                if (item.isSitus) {
                                    return item;
                                }
                                return {
                                    ...item,
                                    isSitus: false,
                                };
                            }),
                        };

                        // Creating locations
                        return locationApiCall('create-group-locations', params)
                            .then(() => {
                                // Updating groups
                                const updateGroupParams = {
                                    allEligiblePersons: get(values, 'allEligiblePersons'),
                                    alternateRegistration: get(values, 'alternateRegistration'),
                                    contributionMessage: get(values, 'contributionMessage', ''),
                                    defaultEConsent: get(values, 'defaultEConsent'),
                                    groupId,
                                    groupName: get(values, 'groupName', ''),
                                    isIdpInitiated: get(values, 'alternateRegistration')
                                        ? get(values, 'isIdpInitiated')
                                        : false,
                                    payrollDeductionGenerationEmailSend: get(
                                        values,
                                        'payrollDeductionGenerationEmailSend'
                                    ),
                                    sicCode: get(values, 'sicCode', ''),
                                    userId,
                                };

                                // Making the update call
                                return updateGroupApiCall(updateGroupParams)
                                    .then(() => {
                                        // On success, navigate to the next screen
                                        return navigate(
                                            generateRedirectPath({
                                                ...location,
                                                queryParams: { [name]: 2 },
                                            })
                                        );
                                    })
                                    .catch((e) =>
                                        setErrorSnack(
                                            `There was an error: ${e.message}`,
                                            config.notificationDuration
                                        )
                                    );
                            })
                            .catch((e) =>
                                setErrorSnack(
                                    `There was an error: ${e.message}`,
                                    config.notificationDuration
                                )
                            );
                    }
                };
                return (
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <FinalForm
                            key="basicInformationForm"
                            initialValues={
                                isEditing || isBasicInformationFilled || isRenewal
                                    ? initialValues
                                    : {
                                        alternateRegistration: get(
                                            groups,
                                            'alternateRegistration',
                                            true
                                        ).toString(),
                                        groupNumber: get(groups, 'groupNumber', ''),
                                        locations: [{}],
                                    }
                            }
                            mutators={arrayMutators}
                            onSubmit={async (formValues) => {
                                await onClickAction(formValues);
                            }}
                            render={({ handleSubmit, submitting, values }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                bg: 'white',
                                                borderRadius: 4,
                                                boxShadow: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexWrap: 'wrap',
                                                justifyContent: 'center',
                                                padding: 3,
                                            }}
                                        >
                                            <Box
                                                as="h3"
                                                color="accent"
                                                display="flex"
                                                justifyContent="flex-start"
                                                sx={{
                                                    margin: '20px 0px 20px 0px;',
                                                    padding: 3,
                                                    width: '25rem',
                                                }}
                                            >
                                                {t('groups.basicInformation')}
                                            </Box>
                                            <Field
                                                aria-label={config.canadaEnv ? 'NAIC Code' : "SIC Code"}
                                                component={InputMasked}
                                                label={labelHandler(config.canadaEnv ? 'NAIC Code' : 'SIC Code', true)}
                                                mask={config.canadaEnv ? NAICCodeMask : SICCodeMask}
                                                name="sicCode"
                                                searchable={false}
                                                validate={config.canadaEnv ? composeValidations(required, NAICCodeNumber) : composeValidations(required, SICCodeNumber)}
                                                wrapperSx={{ ...commonFieldSxHalf }}
                                                {...rest}
                                            />
                                            <Field
                                                aria-label="MGA Group Number"
                                                component={InputMasked}
                                                disabled
                                                label={labelHandler('MGA Group Number', true)}
                                                mask={groupNumberMask}
                                                name="groupNumber"
                                                validate={composeValidations(groupNumber)}
                                                wrapperSx={{ ...commonFieldSxHalf }}
                                                {...rest}
                                            />
                                            <Field
                                                aria-label={t('groups.nameCompany')}
                                                component={InputText}
                                                label={labelHandler(t('groups.nameCompany'), true)}
                                                name="groupName"
                                                validate={required}
                                                wrapperSx={{ ...commonFieldSxHalf }}
                                                {...rest}
                                            />
                                            <Field
                                                aria-label={t('groups.eligiblePerson')}
                                                component={InputNumber}
                                                integerLimit={7}
                                                label={labelHandler(
                                                    t('groups.eligiblePerson'),
                                                )}
                                                name="allEligiblePersons"
                                                validate={positive}
                                                wrapperSx={{ ...commonFieldSxHalf }}
                                                {...rest}
                                            />
                                            <Field
                                                aria-label={t('groups.alternateRegistration')}
                                                component={InputCheckbox}
                                                disabled={
                                                    isRenewal
                                                        ? false
                                                        : !['IN_PROGRESS', 'GROUP_CREATION'].includes(
                                                            get(groups, 'status')
                                                        )
                                                }
                                                inputWrapperSx={{
                                                    display: 'inline-flex',
                                                    float: 'left',
                                                    marginTop: '7px',
                                                }}
                                                label={t('groups.alternateRegistration')}
                                                labelSx={{
                                                    display: 'inline-flex',
                                                }}
                                                name="alternateRegistration"
                                                type="checkbox"
                                                wrapperSx={{ ...commonFieldSxHalf, display: 'flex' }}
                                            />
                                            {get(values, `alternateRegistration`) && (
                                                <>
                                                    <Field
                                                        aria-label={t('groups.idpRegistration')}
                                                        component={InputCheckbox}
                                                        disabled={
                                                            isRenewal
                                                                ? false
                                                                : !['IN_PROGRESS', 'GROUP_CREATION'].includes(
                                                                    get(groups, 'status')
                                                                )
                                                        }
                                                        inputWrapperSx={{
                                                            display: 'inline-flex',
                                                            float: 'left',
                                                            marginTop: '7px',
                                                        }}
                                                        label={t('groups.idpRegistration')}
                                                        labelSx={{
                                                            display: 'inline-flex',
                                                        }}
                                                        name="isIdpInitiated"
                                                        type="checkbox"
                                                        wrapperSx={{
                                                            ...commonFieldSxHalf,
                                                            display: 'flex',
                                                        }}
                                                    />
                                                </>
                                            )}
                                            <Field
                                                aria-label={t('groups.contributionMessage')}
                                                component={InputText}
                                                label={labelHandler(t('groups.contributionMessage'), false)}
                                                maxLength={100}
                                                name="contributionMessage"
                                                wrapperSx={{ ...commonFieldSxHalf }}
                                                {...rest}
                                            />
                                            {config.sendNotification && (
                                                <>
                                                    <Field
                                                        aria-label={t('groups.defaultEConsent')}
                                                        component={InputCheckbox}
                                                        inputWrapperSx={{
                                                            display: 'inline-flex',
                                                            float: 'left',
                                                            marginTop: '7px',
                                                        }}
                                                        label={t('groups.defaultEConsent')}
                                                        labelSx={{
                                                            display: 'inline-flex',
                                                        }}
                                                        name="defaultEConsent"
                                                        type="checkbox"
                                                        wrapperSx={{ ...commonFieldSxHalf, display: 'flex' }}
                                                    />
                                                </>
                                            )}
                                            <Field
                                                aria-label={t('groups.sendPayrollEmail')}
                                                component={InputCheckbox}
                                                inputWrapperSx={{
                                                    display: 'inline-flex',
                                                    float: 'left',
                                                    marginTop: '7px',
                                                }}
                                                label={t('groups.sendPayrollEmail')}
                                                labelSx={{
                                                    display: 'inline-flex',
                                                }}
                                                name="payrollDeductionGenerationEmailSend"
                                                type="checkbox"
                                                wrapperSx={{ ...commonFieldSxHalf, display: 'flex' }}
                                            />

                                            <GroupLocationsFormSection
                                                isStateEditing={
                                                    isRenewal
                                                        ? null
                                                        : isEditing &&
                                                        // get(
                                                        //     basicInformationResponse,
                                                        //     'locations.0.address.state',
                                                        //     null
                                                        // ) !== null
                                                        get(groups, 'status', '') === 'ACTIVE'
                                                }
                                                {...rest}
                                            />
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    maxWidth: '25rem',
                                                    my: 6,
                                                    p: 3,
                                                    width: '100%',
                                                }}
                                            >
                                                <ToolbarButton
                                                    bg="primaryLight"
                                                    label={t('common.previous')}
                                                    ml={0}
                                                    onClick={() => {
                                                        return navigate(
                                                            generateRedirectPath({
                                                                ...location,
                                                                queryParams: {
                                                                    [name]: isRenewal ? 1 : 0,
                                                                },
                                                            })
                                                        );
                                                    }}
                                                    width="10rem"
                                                />
                                                <ToolbarButton
                                                    bg="primaryDark"
                                                    isDisabled={submitting}
                                                    label={t('common.next')}
                                                    mr={0}
                                                    submitting={submitting}
                                                    type="submit"
                                                    width="10rem"
                                                />
                                            </Box>
                                        </Box>
                                    </form>
                                );
                            }}
                        />
                    </Box>
                );
            }}
        </ApolloConsumer>
    );
};

BasicInformation.propTypes = {
    isRenewal: PropTypes.bool,
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }).isRequired,
};

BasicInformation.defaultProps = {
    isRenewal: false,
};

export default BasicInformation;
