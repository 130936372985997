import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { phoneNumberMask } from '@utils/masks';
import { phoneNumber } from '@utils/validations';
import { labelHandler } from '@utils/label-utils';
import config from '@src/config.json';
import { useTranslation } from 'react-i18next';
import InputDropdown from '@basecomponents/Dropdown';
import InputMasked from '@basecomponents/InputMasked';
import GetData from '@utils/get-data';
import AddressFormSection from '@petcomponents/GroupNotes/Upsert/Sections/AddressFormSection';
import { get, isEmpty, reject } from 'lodash';
import Input from '@basecomponents/Input';
import { email } from '@utils/validators';
import TextArea from '@basecomponents/TextArea';
import SingleDatePicker from '@basecomponents/SingleDatePicker';
import Checkbox from '@basecomponents/InputCheckbox';
import i18n from '@src/utilities/i18n';
import useSsm from '@src/utilities/hooks/useSsm';

const payrollFreqLabel = labelHandler(i18n.t('metadata.payrollFrequency'));
const billingCycleLabel = labelHandler(i18n.t('groups.billingCycle'));
const billingTypeLabel = labelHandler(i18n.t('groups.billingType'));
const paymentMethodLabel = labelHandler(i18n.t('groups.paymentMethod'));
const hiringPeriodLabel = labelHandler(
  i18n.t('groups.newHireWaitingPeriodInDays')
);
const firstCoverageStartDate = labelHandler(
  i18n.t('groups.firstCoverageStartDate')
);
const effectiveEndDateLabel = labelHandler('Effective End Date');
const enrollStartDateLabel = labelHandler('Enrollment Start Date');
const enrollEndDateLabel = labelHandler('Enrollment End Date');
const waitingPeriodLabel = labelHandler(
  i18n.t('groups.firstMonthWaiting'),
  false
);
const adminFirstNameLabel = labelHandler('Admin First Name');
const adminLastNameLabel = labelHandler('Admin Last Name');
const adminEmailLabel = labelHandler('Admin Email');
const adminPhoneTypeLabel = labelHandler('Admin Phone Number Type');
const adminPrimaryPhoneLabel = labelHandler('Admin Primary Phone Number');
const adminAltPhoneType = labelHandler('Admin Alternate Phone Number Type');
const adminNoteLabel = labelHandler('Group Admin Notes');
const billingDateLabel = labelHandler('Billing Date');
const dueDateLabel = labelHandler('Due Date');
const implNotesLabel = labelHandler('Implementation Notes');
const textAreaSx = { minHeight: 100 };
const benAdminNotesLabel = labelHandler('Ben Admin Notes');
const actionNotesLabel = labelHandler('Action Notes');
/**
 * @category Claims
 * @param {string} acceptableMethods Indicating acceptable methods for payment
 * @param {object} wrapperSx Styling for the section
 * @returns {React.FC}
 */
const BillingInfo = ({ wrapperSx, readOnly }) => {
  const { t } = useTranslation();
  const {
    apiData: { HiringPeriod = [] },
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: [' HiringPeriod'] }])
  );
  const chaseEnabled = useSsm('iaic-chase-enabled')
  const {
    apiData: {
      BillingAccountType = [],
      BillingType = [],
      PayrollCycleFrequency = [],
      BillingCycle = [],
      ContactType = [],
    },
  } = GetData(
    'get-enum-options',
    JSON.stringify([
      {
        params: [
          ' BillingAccountType',
          'BillingType',
          'PayrollCycleFrequency',
          'BillingCycle',
          'ContactType',
        ],
      },
    ])
  );

  const hiringPeriodAriaLabel = t('groups.newHireWaitingPeriodInDays');
  const payrollFrequencyLabel = t('metadata.payrollFrequency');
  const billingCycleAriaLabel = t('groups.billingCycle');
  const billingTypeAriaLabel = t('groups.billingType');
  const paymentMethodAriaLabel = t('groups.paymentMethod');
  const accTypeOptions = chaseEnabled
    ? BillingAccountType
    : reject(BillingAccountType, (v) => v.value === 'ACH_AUTOMATIC');
  return (
    <>
      <Field
        aria-label={t('groups.firstCoverageStartDate')}
        component={SingleDatePicker}
        disabled={readOnly}
        label={firstCoverageStartDate}
        name="coverageStartDate"
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Effective End Date"
        component={SingleDatePicker}
        disabled={readOnly}
        label={effectiveEndDateLabel}
        name="coverageEndDate"
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Enrollment Start Date"
        component={SingleDatePicker}
        disabled={readOnly}
        label={enrollStartDateLabel}
        name="enrollmentStartDate"
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Enrollment End Date"
        component={SingleDatePicker}
        disabled={readOnly}
        label={enrollEndDateLabel}
        name="enrollmentEndDate"
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label={t('groups.firstMonthWaiting')}
        component={Checkbox}
        disabled={readOnly}
        label={waitingPeriodLabel}
        name="followingWaitingPeriod"
        type="checkbox"
        wrapperSx={{
          display: 'flex',
          ...wrapperSx,
        }}
      />
      <Field
        aria-label={hiringPeriodAriaLabel}
        component={InputDropdown}
        disabled={readOnly}
        label={hiringPeriodLabel}
        name="newHireWaitingPeriod"
        options={HiringPeriod}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Admin First Name"
        component={Input}
        data-cy="adminFirstName"
        disabled={readOnly}
        label={adminFirstNameLabel}
        name="adminFirstName"
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Admin Last Name"
        component={Input}
        data-cy="adminLastName"
        disabled={readOnly}
        label={adminLastNameLabel}
        name="adminLastName"
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Admin Email"
        component={Input}
        data-cy="adminEmail"
        disabled={readOnly}
        label={adminEmailLabel}
        name="adminEmail"
        validate={email}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Admin Phone Number Type"
        component={InputDropdown}
        disabled={readOnly}
        label={adminPhoneTypeLabel}
        name="adminPrimaryPhoneNumberType"
        options={ContactType}
        searchable={false}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Admin Primary Phone Number"
        component={InputMasked}
        disabled={readOnly}
        label={adminPrimaryPhoneLabel}
        mask={phoneNumberMask}
        name="adminPrimaryPhoneNumber"
        validate={phoneNumber}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Admin Alternate Phone Number Type"
        component={InputDropdown}
        disabled={readOnly}
        label={adminAltPhoneType}
        name="adminAlternatePhoneNumberType"
        options={ContactType}
        searchable={false}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Admin Alternate Phone Number"
        component={InputMasked}
        disabled={readOnly}
        label="Admin Alternate Phone Number"
        mask={phoneNumberMask}
        name="adminAlternatePhoneNumber"
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Group Admin Notes"
        component={TextArea}
        disabled={readOnly}
        label={adminNoteLabel}
        name="groupAdminNotes"
        textAreaSx={{ minHeight: 100 }}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label={payrollFrequencyLabel}
        component={InputDropdown}
        disabled={readOnly}
        label={payrollFreqLabel}
        name="payrollFrequency"
        options={PayrollCycleFrequency}
        searchable={false}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label={billingCycleAriaLabel}
        component={InputDropdown}
        disabled={readOnly}
        label={billingCycleLabel}
        name="billingFrequency"
        options={BillingCycle}
        searchable={false}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label={billingTypeAriaLabel}
        component={InputDropdown}
        disabled={readOnly}
        label={billingTypeLabel}
        name="billingType"
        options={BillingType}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Billing Date"
        component={SingleDatePicker}
        disabled={readOnly}
        label={billingDateLabel}
        name="billingDate"
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Due Date"
        component={SingleDatePicker}
        disabled={readOnly}
        label={dueDateLabel}
        name="dueDate"
        wrapperSx={wrapperSx}
      />
      <AddressFormSection
        label="billing"
        readOnly={readOnly}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label={paymentMethodAriaLabel}
        component={InputDropdown}
        disabled={readOnly}
        label={paymentMethodLabel}
        name="accountType"
        options={accTypeOptions}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Implementation Notes"
        component={TextArea}
        data-cy="implementationNotes"
        disabled={readOnly}
        label={implNotesLabel}
        name="implementationNotes"
        textAreaSx={textAreaSx}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Ben Admin Notes"
        component={TextArea}
        data-cy="benAdminNotes"
        disabled={readOnly}
        label={benAdminNotesLabel}
        name="benAdminNotes"
        textAreaSx={textAreaSx}
        wrapperSx={wrapperSx}
      />
      <Field
        aria-label="Action Notes"
        component={TextArea}
        data-cy="actionNotes"
        disabled={readOnly}
        label={actionNotesLabel}
        name="actionItem"
        textAreaSx={textAreaSx}
        wrapperSx={wrapperSx}
      />
    </>
  );
};
BillingInfo.defaultProps = {
  readOnly: false,
  wrapperSx: {},
};
BillingInfo.propTypes = {
  wrapperSx: PropTypes.shape({}),
  readOnly: PropTypes.bool,
};
export default BillingInfo;
