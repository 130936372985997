/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from '@basecomponents/Box';
import { Form as FinalForm } from 'react-final-form';
import { navigate, useLocation } from '@reach/router';
import config from '@src/config.json';
import PropTypes from "prop-types";
import i18n from '@src/utilities/i18n';
import generateRedirectPath from '@src/utilities/generate-redirect-path';
import { parse } from 'query-string';
import Route from '../Route';
import UploadFile from '../UploadFile';
import ToolbarButton from '../../BaseComponents/ToolbarButton';

const name = "step"

function GroupAdminUploadButtons({ submitting }) {
    const location = useLocation()
    return <Box alignItems="center" display="flex" justifyContent="center" p={4}>
        <ToolbarButton
            bg="primary"
            isLoading={submitting}
            label={i18n.t('common.previous')}
            onClick={() => {
                return navigate(
                    generateRedirectPath({
                        ...location,
                        queryParams: { [name]: 0 },
                    })
                );
            }}
        />
        <ToolbarButton
            isLoading={submitting}
            label="Submit"
            onClick={() => {
                navigate(`/groups/templateStatus`)
            }}
        />
    </Box>
}

/**
 * @category TBD
 * @param {*} rest
 * @returns {React.FC}
 */
const GroupTemplate = ({ flow }) => {
    const location = useLocation();
    const groupId = parse(location.search)?.groupId || "NEW"
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const flowValue = config.canadaEnv ? "groupAdminCanada" : "groupAdmin";
    const groupNoteFlow = flow === 'groupNote' ? 'groupNote' : flowValue;
    const childrenData = () => (
        <>
            {flow !== 'groupNote' && (
                <Box as="h2" sx={{ py: 3 }}>
                    Group Template Upload
                </Box>
            )}
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        alignItems: 'center',
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 3,
                    }}
                >
                    <FinalForm
                        onSubmit={
                            () => navigate(`/groups/templateStatus`)
                        }
                        render={({ submitting, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    sx={{
                                        padding: 3,
                                    }}
                                >
                                    <UploadFile
                                        fileTypes={[
                                            'text/plain',
                                            'application/vnd.ms-excel',
                                            'text/x-csv',
                                            'application/csv',
                                            'application/x-csv',
                                            'text/csv',
                                            'text/comma-separated-values',
                                            'text/x-comma-separated-values',
                                            'text/tab-separated-values',
                                        ]}
                                        flow={groupNoteFlow}
                                        groupId={groupId}
                                        onUploadComplete={() => setButtonDisabled(false)}
                                    />
                                    <Box>
                                        <ToolbarButton
                                            bg="primaryLight"
                                            isDisabled={submitting || buttonDisabled}
                                            label="Done"
                                            mr={0}
                                            submitting={submitting}
                                            sx={{
                                                margin: '15px auto !important',
                                            }}
                                            type="submit"
                                            width="150px"
                                        />
                                    </Box>

                                    {
                                        flow === 'groupNote' &&
                                        <GroupAdminUploadButtons submitting={submitting} />
                                    }
                                </Box>
                            </form>
                        )}
                    />
                </Box>
            </Box>
        </>
    );
    return flow === 'groupNote' ? childrenData() : (
        <Route
            header={{
                icon: 'groups',
                title: 'Group Template',
                type: 'groups',
            }}
            isPrivate
        >
            {childrenData()}
        </Route>
    );
};
GroupTemplate.defaultProps = {
    flow: '',
};
GroupTemplate.propTypes = {
    flow: PropTypes.string,
};
export default GroupTemplate;
