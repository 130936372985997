import { useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import Spinner from '@basecomponents/Spinner';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Route from '../../Route';
import InitialSetup from './InitialSetup';
import ProducerAddress from './ProducerAddress';
import ProducerContact from './ProducerContact';
import GetData from '../../../../utilities/get-data';
import ProgressBar from '../../../BaseComponents/ProgressBar';

/**
 * Used to add/edit producers
 *
 * @category Producer
 * @param {Object} location URI path
 * @param {*} rest
 * @returns {React.FC}
 */
const UpsertProducer = ({ location, ...rest }) => {
  const queryParams = useParams();
  const producerId = get(queryParams, 'producerId', '');
  const title = 'Producer Configuration';
  const name = 'step';
  const { apiData, loading } = GetData(
    'get-producer-by-id',
    JSON.stringify({ id: producerId }),
    !producerId
  );

  return (
    <Route
      header={{
        title,
        type: 'producers',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {title}
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <ProgressBar
            defaultTab="0"
            name={name}
            slides={['Create Producer', 'Producer Address', 'Point Of Contact']}
            tabs={[
              {
                render: () => (
                  <InitialSetup
                    location={location}
                    producerInfo={apiData}
                    {...rest}
                  />
                ),
                value: '0',
              },
              {
                render: () => (
                  <ProducerAddress
                    address={get(apiData, 'address', {})}
                    location={location}
                    {...rest}
                  />
                ),
                value: '1',
              },
              {
                render: () => (
                  <ProducerContact
                    location={location}
                    producerInfo={apiData}
                    {...rest}
                  />
                ),
                value: '2',
              },
            ]}
          />
        )}
      </Box>
    </Route>
  );
};

UpsertProducer.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
};

export default UpsertProducer;
