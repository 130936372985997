import Box from '@basecomponents/Box';
import Icon from '@src/components/Icon';
import React from 'react';
import { produce } from 'immer';

export const containerSx = {
  alignItems: 'center',
  borderRadius: 5,
  boxShadow: 5,
  display: 'flex',
  justifyContent: 'center',
  m: 5,
  p: 2,
};
export const imgSx = {
  alignItems: 'center',
  display: 'flex',
  height: '35px',
  justifyContent: 'center',
  mr: 2,
  width: '35px',
};

// eslint-disable-next-line react/prop-types
export const PhoneButton = ({ number, sx = {} }) => {
  const style = produce(containerSx, (draft) => {
    Object.assign(draft, sx)
  })
  return (
    <Box
      as="a"
      bg="accentSecondaryDark"
      color="white"
      href={`tel:${number}`}
      m={0}
      mb={2}
      sx={style}
    >
      <Icon color="accentPrimary" svg="phone" sx={imgSx} />
      <Box>{number}</Box>
    </Box>
  );
};
