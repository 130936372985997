import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import get from 'lodash/get';
import EmptyVariant from '../EmptyVariant';
import Icon from '../../../Icon';

const iconMap = {
  CELL: 'phone',
  HOME: 'home',
  WORK: 'work',
};

/**
 * Display Contact
 * @category Components
 *
 * @param {Object} value Contact object
 * @returns {React.FC}
 */
const Contact = ({ value }) =>
  value ? (
    <Box alignItems="center" display="flex">
      <Box>
        <Icon
          height="1.25rem"
          svg={get(iconMap, get(value, 'contactType'), 'info')}
          width="1.25rem"
        />
      </Box>
      <Box mx={2}>{get(value, 'contactType')}</Box>
      <Box mx={2}>{get(value, 'phoneNumber')}</Box>
    </Box>
  ) : (
    <EmptyVariant />
  );

Contact.defaultProps = {
  value: {},
};

Contact.propTypes = {
  value: PropTypes.shape({}),
};

export default Contact;
