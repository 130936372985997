import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
// import GetData from '../../../../../utilities/get-data';
import dateUtils from '../../../../../utilities/date-utils';
import EmptyVariant from '../../EmptyVariant';
import ModuleContainer from '../Container';

/**
 * Employee variant
 *
 * @category Components
 * @param {string} id
 * @param {string} type
 * @returns {React.FC}
 */
const DOEDates = ({ data, type }) => {
  // const { apiData, loading: employeeLoading } = GetData(
  //   'get-employee-by-id',
  //   JSON.stringify({ id: get(data, 'employeeId') }),
  //   get(data, 'employeeId', null) == null,
  // );
  // const { apiData: GroupData = {}, loading: groupLoading } = GetData(
  //   'get-group-by-id',
  //   JSON.stringify({ id: get(apiData, 'groupId') }),
  //   get(apiData, 'groupId', null) == null,
  // );
  let result = 'NA';
  if (type === 'enrollmentDOE') {
    if (get(data, 'enrollmentEventDate') !== null /* && get(GroupData, 'enrollmentDoe') */) {
      result = dateUtils.getUTCDateOnly(get(data, 'enrollmentEventDate'));
    } else if (get(data, 'coverageStartDate')) {
      result = dateUtils.getUTCDateOnly(get(data, 'coverageStartDate'));
    }
  } else if (type === 'terminationDOE') {
    if (get(data, 'removalEventDate') !== null /* && get(GroupData, 'terminationDoe') */) {
      result = dateUtils.getUTCDateOnly(get(data, 'removalEventDate'));
    } else if (get(data, 'coverageEndDate')) {
      result = dateUtils.getUTCDateOnly(get(data, 'coverageEndDate'));
    }
  } else {
    return <EmptyVariant value="Invalid Date" />;
  }
  return <ModuleContainer displayValue={result} /* loading={employeeLoading || groupLoading} */ />
};

DOEDates.propTypes = {
  data: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
};

export default DOEDates;
