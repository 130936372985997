import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, noop } from 'lodash';
import { navigate, useParams } from '@reach/router';
import { ApolloConsumer } from '@apollo/client';
import Box from '@basecomponents/Box';
import { AuthContext } from '@basecomponents/Auth';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import { Field, Form as FinalForm, Form } from 'react-final-form';
import { composeValidations, required } from '@utils/validators';
import { USER_ROLE } from '@utils/constants';
import { labelHandler } from '@utils/label-utils';
import { refundAmount } from '@utils/validations';
import InputDate from '@basecomponents/InputDate';
import localStorage from '@src/utilities/local-storage';
import InputDropdown from '@basecomponents/Dropdown';
import { useTranslation } from 'react-i18next';
import useSsm from '@src/utilities/hooks/useSsm';
import Modal from '../../../BaseComponents/Modal';
import Route from '../../Route';
import Tabs from '../../../BaseComponents/Tabs';
import KeyValuePairs from '../../../BaseComponents/KeyValuePairs';
import GetData from '../../../../utilities/get-data';
import getMetaData from '../../../../utilities/get-meta-data';
import Card from '../../../BaseComponents/Card';
import UploadedDocumentsSection from '../../UploadedDocumentsSection';
import FileViewSection from '../../FileViewSection';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql';
import useSnackbar from '../../../../utilities/use-snackbar';
import ListItem from '../../../BaseComponents/ListGrid/ListItem';
import HeaderRightContainer from '../../../BaseComponents/HeaderRightContainer';
import gaEvent from '../../../../utilities/gaEvent';
import InputMoney from '../../../BaseComponents/InputMoney';
import ToolbarButton from '../../../BaseComponents/ToolbarButton';
import convertCurrencyToInt from '../../../../utilities/convert-currency-to-int';
import Spinner from '../../../BaseComponents/Spinner';
import config from '../../../../config.json';
import dateUtils from '../../../../utilities/date-utils';

function isPetDemographicEmployee(employee) {
  const isPostEnrollmentEmployee = get(employee, 'postEnrollmentGroup', false);
  return isPostEnrollmentEmployee;
}

/**
 * @category Employee
 * @param {Array<Object>} employeeData
 * @param {string} employeeId
 * @param {boolean} showCoverage
 * @param {Object} client
 * @param {*} rest
 * @returns {React.FC}
 */
const Details = ({
  employeeData,
  employeeId,
  showCoverage,
  client,
  ...rest
}) => {
  const start = Date.now();
  const theme = useContext(ThemeContext);
  const queryParams = useParams();
  const empId = get(queryParams, 'employeeId', employeeId || '');
  const { user } = useContext(AuthContext);
  const groupMgaName = get(user, 'customMga', 'ppi').toLowerCase();
  const role = get(user, 'customRole', '');
  const userId = get(user, 'customSystemUserId', '');
  const {
    apiData = {},
    loading,
    refetch: employeeRefetch,
  } = GetData('get-group-employee-by-id', JSON.stringify({ id: empId }));
  const planId = get(apiData, 'employeePlanConfirmation.planId', '');
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [changeEmployeePlanModal, setChangeEmployeePlanModal] = useState(false);
  const [creditType, setCreditType] = useState(null);
  const [disclaimerStep, setDisclaimerStep] = useState(1);
  const [isSubmitModalOpen, setSubmitModalOpen] = useState({});
  const [editPetNameModal, setEditPetNameModal] = useState(false);
  const [submitPetName, setSubmitPetName] = useState({});
  const [updatedPetName, setUpdatedPetName] = useState(null);
  const [removedDogCount, setRemovedDogCount] = useState(0);
  const [removedCatCount, setRemovedCatCount] = useState(0);
  const [isUpdateSubmitting, setIsUpdateSubmitting] = useState(false);
  const [removePetData, setRemovePetData] = useState({});
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const [showModalEnrollment, setShowModalEnrollment] = useState(false);
  const { apiData: empData, loading: apiDataLoading } = GetData(
    'get-employee-by-id',
    JSON.stringify({ id: empId }),
    !empId
  );
  const { t } = useTranslation();
  const postEnrollmentConfigEnabled = useSsm('iaic-post-enrollment');

  const parentData = get(apiData, 'parentEmployee');
  const groupId = parentData?.groupId || get(empData, 'groupId', '');
  const { apiData: oldGroupData = {} } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );
  const {
    apiData: { content: groupBillingData = [] },
    loading: billLoading,
  } = GetData(
    'get-all-bills',
    JSON.stringify({
      groupId,
      page: 0,
      size: 1,
    })
  );

  const groupNumber = get(oldGroupData, 'groupNumber', '');
  const employeeEmpId = get(apiData, 'empId', null);
  const {
    apiData: missingDemographics = [],
    loading: missingDemographicsLoading,
  } = GetData(
    'get-all-missing-demographics',
    JSON.stringify({
      empId: employeeEmpId,
      groupNumber,
    }),
    isEmpty(groupNumber) || isEmpty(employeeEmpId)
  );

  const {
    apiData: amountOnCredit = 0,
    loading: getAllEmployeepaymentRecordLoading,
  } = GetData(
    'employee-amount-on-credit',
    JSON.stringify({
      employeeId: empId,
      groupId,
    }),
    !groupId
  );
  const { apiData: planPricing = [], loading: planPricingLoading } = GetData(
    'get-plan-pricing',
    JSON.stringify({ planId }),
    !planId
  );

  const { apiData: documentsList = [], loading: documentLoading } = GetData(
    'get-all-employee-attachments',
    JSON.stringify({
      id: get(apiData, 'rootEmployeeId') || empId,
    }),
    !['ENROLLED'].includes(get(apiData, 'status'))
  );
  const employeeDetails = [
    { name: 'empId' },
    { name: 'user' },
    { name: 'user.email' },
    { name: 'user.secondaryEmail' },
    { name: 'groupId' },
    {
      label: t('metadata.enrollmentStatus'),
      name: 'enrollmentStatus',
    },
    { name: 'terminationDate' },
  ];
  useEffect(() => {
    const pathname = window.location.href; // this gives me current Url
    localStorage.setItem('pageUrl', JSON.stringify(pathname));
  }, []);
  if (
    ![
      USER_ROLE.EMPLOYEE,
      USER_ROLE.TERMINATED_EMPLOYEE,
      USER_ROLE.RENEWAL_POLICY_DELETED,
    ].includes(role)
  ) {
    employeeDetails.push({
      name: 'paidThroughDate',
    });
  }
  const {
    apiData: dependentData = [],
    dependentLoading,
    refetch,
  } = GetData('get-employee-dependents', JSON.stringify({ employeeId: empId }));

  useEffect(() => {
    if (get(dependentData, 'content', []).length > 0) {
      const activePets = get(dependentData, 'content', []).filter(
        (dependent) => dependent.status === 'ACTIVE'
      );
      const noOfDogRemoved = activePets.filter(
        ({ postEnrollmentPetRemoved, dependentData }) =>
          postEnrollmentPetRemoved === true && dependentData?.species === 'Dog'
      );
      const noOfCatRemoved = activePets.filter(
        ({ postEnrollmentPetRemoved, dependentData }) =>
          postEnrollmentPetRemoved === true && dependentData?.species === 'Cat'
      );
      setRemovedCatCount(noOfCatRemoved.length);
      setRemovedDogCount(noOfDogRemoved.length);
    }
  }, [dependentLoading, dependentData]);

  const employeePlanChoices = get(apiData, 'planChoiceEmployees', []);
  const selectedPlan = employeePlanChoices
    .filter((item) => item.acceptGroupPlanChoice)
    .map((item) => {
      if (Array.isArray(planPricing)) {
        const plan =
          planPricing.find((v) => v.planChoiceId === item.planChoiceId) || {};
        return { ...item, ...plan };
      }
      return item;
    });
  const selectedPlanName = get(selectedPlan, '[0].name');
  const meta = getMetaData({ moduleName: 'group-employees' });
  const currentDate =
    get(apiData, 'relativeDatetime') === null
      ? new Date()
      : dateUtils.setOffset2(get(apiData, 'relativeDatetime'));
  const enrollmentStartDate =
    get(apiData, 'employeeDates.enrollmentStartDate') === null
      ? new Date()
      : dateUtils.setOffset2(get(apiData, 'employeeDates.enrollmentStartDate'));
  const enrollmentEndDate =
    get(apiData, 'employeeDates.enrollmentEndDate') === null
      ? new Date()
      : dateUtils.setOffset2(get(apiData, 'employeeDates.enrollmentEndDate'));

  const { apiData: groupData = {}, loading: getGroupByIdLoading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: get(empData, 'groupId', '') })
  );
  const { apiData: plansData = [], loading: planConfigLoading } = GetData(
    'get-plan-config',
    JSON.stringify([
      {
        group: groupId,
      },
    ])
  );
  const allPlansData = showCoverage
    ? get(groupData, 'plan.planChoices', []).map((item) => {
        if (Array.isArray(plansData)) {
          const product = plansData.find(
            (plan) => plan.planId === item.productPlanId
          );
          return {
            ...item,
            ...product,
          };
        }
        return item;
      })
    : [];
  const userName = t('groupEmployees.detailsPage.title', {
    firstName: get(apiData, 'user.firstName', ''),
    lastName: get(apiData, 'user.lastName', ''),
  });

  const ifStatusIsNotEnrolled = (data) => {
    return !['ENROLLED'].includes(get(data, 'status', ''));
  };

  const groupPolicyIsExpiredOrTerminated = (oldGroupData) => {
    return ['POLICY_EXPIRED', 'TERMINATED'].includes(
      get(oldGroupData, 'groupStatus')
    );
  };
  const ifTheEmployeeIsTerminatedInCurrentGroup = (data) => {
    return ['TERMINATED'].includes(get(data, 'employmentStatus', ''));
  };
  const ifEmployeeIsEnrolledInPetDemographics = (data) => {
    const postEnrollmentEnabled = postEnrollmentConfigEnabled;
    const postEnrollmentGroup = get(data, 'postEnrollmentGroup', false);
    const hasPetBeenNotAdded = get(data, 'hasDemographicChanges', false);
    return postEnrollmentEnabled && (hasPetBeenNotAdded || postEnrollmentGroup);
  };
  const canBeMGAIntegrated = (data) => {
    const status = get(data, 'status', '');
    return [
      'ACTIVE', // AWAITING_MGA_CONFIRMATION
      'FAILED_TO_ENROLL',
      'ENROLLED',
    ].includes(status);
  };

  const ifTheUserHasNotBeenCreatedInCognito = (data) => {
    return !get(data, 'user.cognitoCreated');
  };

  const ifTheEmployeeStatusIs = (data, conditionalArray) => {
    return conditionalArray.includes(get(data, 'status', ''));
  };

  const coverageDateEnded = (data) => {
    const { coverageEndDate: coverageEndDateString } = data;
    if (coverageEndDateString == null) {
      return false;
    }
    const currentDate =
      get(apiData, 'relativeDatetime') === null
        ? dateUtils.getLocaleDateOnly(new Date())
        : dateUtils.setOffset2(get(apiData, 'relativeDatetime'));
    const coverageEndDate = dateUtils.getUTCDateOnly(coverageEndDateString);
    const converageHasEnded =
      Date.parse(coverageEndDate) < Date.parse(currentDate);
    return converageHasEnded;
  };

  const ifTheEnrolmentHasNotStarted = () => enrollmentStartDate > currentDate;

  const ifTheEmployeeHasAPlanConfirmed = (data) => {
    return get(data, 'employeePlanConfirmation') !== null;
  };

  const ifEmployeeHasEnrolledInPreviousPolicyAndPreviousPolicyHasRenewed = (
    groupData,
    employeeData
  ) => {
    return (
      ['RENEWED'].includes(get(groupData, 'renewalStatus')) &&
      ['ENROLLED'].includes(get(employeeData, 'status'))
    );
  };

  const ifTheRenewalHasBeenCompleted = (data, oldGroupData) => {
    return (
      get(oldGroupData, 'status') === 'RENEWED' &&
      (get(data, 'employeePlanConfirmation') !== null ||
        ['ENROLLED', 'ACTIVE', 'PENDING'].includes(
          get(data, 'enrollmentStatus')
        ))
    );
  };
  const hideGroupAction = (apiData) => {
    return (
      (get(apiData, 'currentGroup.renewalStatus', '') === 'RENEWED' &&
        get(apiData, 'currentGroup.isActiveGroup') === false) ||
      ['POLICY_EXPIRED', 'TERMINATED'].includes(
        get(apiData, 'parentGroup.groupStatus')
      )
    );
  };
  const ifTheCurrentPolicyIsNotTheRenewalPolicy = (data, oldGroupData) => {
    return (
      ['RENEWED'].includes(get(oldGroupData, 'renewalStatus')) &&
      get(oldGroupData, 'groupId') === get(data, 'parentGroupId')
    );
  };
  const ifTheEmployeeIsNotEligibleForRenewal = (
    data,
    oldGroupData,
    parentData
  ) => {
    return (
      ifTheEmployeeIsTerminatedInCurrentGroup(data) ||
      ifTheEmployeeIsTerminatedInCurrentGroup(oldGroupData) ||
      get(data, 'parentEmployeeId', null) === null ||
      !['ENROLLED'].includes(get(parentData, 'status')) ||
      ['ENROLLED', 'ACTIVE', 'PENDING', 'FAILED_TO_ENROLL'].includes(
        get(data, 'status')
      )
    );
  };

  const apiCall = (pathName, params) =>
    client.query({
      fetchPolicy: 'no-cache',
      query: remoteActionQuery,
      variables: {
        name: pathName,
        params: JSON.stringify(params),
      },
    });
  const petInformationFields = [
    { name: 'dependentData.petName' },
    { name: 'dependentData.species' },
    { name: 'dependentData.breed' }, // { name: 'dependentData.age' },
    { name: 'dependentData.weight' },
    { name: 'enrollmentDOE' },
    { name: 'terminationDOE' },
    ...([USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(role)
      ? [{ name: 'priorCoverageCredit' }]
      : []),
    {
      name: 'payPeriodPremiumEmployer',
      dynamicLabel:
        get(groupData, 'billingInfo.payrollFrequency') === '12'
          ? t('metadata.employerContributionMonthly')
          : null,
    },
    {
      name: 'payPeriodPremiumEmployee',
      dynamicLabel:
        get(groupData, 'billingInfo.payrollFrequency') === '12'
          ? t('metadata.monthlyPremiumEmployee')
          : null,
    }, // { name: 'rstAmount' },
  ];

  const inActivePetInformationFields = [
    { name: 'dependentData.petName' },
    { name: 'dependentData.species' },
    { name: 'dependentData.breed' },
    { name: 'dependentData.age' },
    { name: 'dependentData.weight' },
    { name: 'enrollmentDOE' },
    { name: 'terminationDOE' },
    ...([USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(role)
      ? [{ name: 'priorCoverageCredit' }]
      : []),
    {
      name: 'payPeriodPremiumEmployer',
      dynamicLabel:
        get(groupData, 'billingInfo.payrollFrequency') === '12'
          ? 'Employer Contribution Monthly'
          : null,
    },
    {
      name: 'payPeriodPremiumEmployee',
      dynamicLabel:
        get(groupData, 'billingInfo.payrollFrequency') === '12'
          ? 'Employee Contribution Monthly'
          : null,
    },
    { name: 'status' },
    { name: 'reason' },
  ];

  if (postEnrollmentConfigEnabled) {
    petInformationFields.splice(3, 0, {
      defaultValue: '',
      name: 'dependentData.age',
    });
  } else {
    petInformationFields.splice(3, 0, {
      defaultValue: t('metadata.under1Year'),
      name: 'dependentData.age',
    });
  }
  if (config.canadaEnv) {
    petInformationFields.splice(0, 0, {
      label: t('groupEmployees.detailsPage.policyNumber'),
      name: 'certificateNumber',
    });
    inActivePetInformationFields.splice(0, 0, {
      label: t('groupEmployees.detailsPage.policyNumber'),
      name: 'certificateNumber',
    });
    petInformationFields.splice(10, 0, {
      label: t('groupEmployees.detailsPage.rstAmount'),
      name: 'rstAmount',
    });
    inActivePetInformationFields.splice(10, 0, {
      label: t('groupEmployees.detailsPage.rstAmount'),
      name: 'rstAmount',
    });
  } else {
    petInformationFields.splice(0, 0, {
      name: 'certificateNumber',
    });
    inActivePetInformationFields.splice(0, 0, {
      name: 'certificateNumber',
    });
  }
  const otherPlans = employeePlanChoices
    .filter((item) => !item.acceptGroupPlanChoice)
    .map((item) => {
      if (Array.isArray(planPricing)) {
        const plan =
          planPricing.find((v) => v.planChoiceId === item.planChoiceId) || {};
        return { ...item, ...plan };
      }
      return item;
    });
  const pdbaRenewalConfigEnabled = useSsm('iaic-pdba-renewal');

  const isWithinOEDate =
    currentDate >= enrollmentStartDate && currentDate <= enrollmentEndDate;
  const isRoleEmployee = USER_ROLE.EMPLOYEE === role;
  const otherPlanName = get(otherPlans, '[0].name');
  const isBillNotGenerated = groupBillingData?.length === 0;
  const showPlanChangeForEmployee =
    isRoleEmployee &&
    isWithinOEDate &&
    selectedPlanName != null &&
    isBillNotGenerated;
  const changePlanConfigEnabled = useSsm('iaic-change-plan');
  const hideChangePlan =
    !get(oldGroupData, 'active', false) ||
    otherPlans.length === 0 ||
    !changePlanConfigEnabled ||
    get(apiData, 'status') !== 'ENROLLED';
  const groupsMetaData = getMetaData({ moduleName: 'groups' }).plan;
  const employeeDependentsMetadata = getMetaData({
    moduleName: 'employee-dependents',
  });
  const dependentsMetaData = getMetaData({ moduleName: 'employee-dependents' });
  const retroPetRemoveConfigEnabled = useSsm('iaic-retro-pet-removal-enabled');
  if (
    apiDataLoading ||
    getGroupByIdLoading ||
    getAllEmployeepaymentRecordLoading ||
    planPricingLoading ||
    planConfigLoading ||
    documentLoading ||
    dependentLoading ||
    missingDemographicsLoading ||
    billLoading
  ) {
    return <Spinner />;
  }
  const hideAddPet =
    ifEmployeeIsEnrolledInPetDemographics(apiData) ||
    ifTheEmployeeIsTerminatedInCurrentGroup(apiData) ||
    groupPolicyIsExpiredOrTerminated(
      get(apiData, 'parentGroup') || get(apiData, 'currentGroup')
    );
  const disableAddPet =
    ifStatusIsNotEnrolled(apiData) ||
    ifEmployeeIsEnrolledInPetDemographics(apiData) ||
    ['IN_PROGRESS'].includes(get(apiData, 'groupTransitionStatus')) ||
    hideGroupAction(apiData);
  return (
    <Route
      header={{
        rightContainer: (
          <HeaderRightContainer
            headerActions={[
              // Edit Employee
              {
                action: `/group-employees/edit/${empId}`,
                icon: 'edit',
                isDisabled:
                  ifTheEmployeeIsTerminatedInCurrentGroup(apiData) ||
                  groupPolicyIsExpiredOrTerminated(
                    get(apiData, 'parentGroup') || get(apiData, 'currentGroup')
                  ),
                isHidden:
                  ifTheEmployeeIsTerminatedInCurrentGroup(apiData) ||
                  ['IN_PROGRESS'].includes(
                    get(apiData, 'groupTransitionStatus')
                  ) ||
                  hideGroupAction(apiData),
                label: t('groupEmployees.detailsPage.editEmployee'),
                permission: 'groupEmployee.button.edit',
              }, // Add Pet
              {
                action: () =>
                  !get(apiData, 'disableImportEmployee', '')
                    ? navigate(`/group-employees/${empId}/life-event`)
                    : setShowModalEnrollment(true),
                icon: 'pet-paw',
                isDisabled: hideAddPet,
                isHidden: disableAddPet,
                label: t('groupEmployees.detailsPage.addPet'),
              }, // Add Pet Details
              {
                action: async () => {
                  if (
                    (Array.isArray(missingDemographics) &&
                      missingDemographics.filter((o) => o.hasDemographicChanges)
                        .length > 1 &&
                      apiData?.hasDemographicChanges) ||
                    (!get(apiData, 'isPetSwapPerformed', false) &&
                      isEmpty(get(apiData, 'renewalEmployee', {})) &&
                      !isEmpty(get(apiData, 'parentEmployee', {})) &&
                      pdbaRenewalConfigEnabled === true)
                  ) {
                    await Object.keys(localStorage)
                      .filter((x) => x.startsWith('EmployeeDependentData-'))
                      .forEach((x) => localStorage.removeItem(x));
                    navigate(
                      `/group-employees/petInfo/${oldGroupData?.groupNumber}/${apiData?.empId}`
                    );
                  } else {
                    await Object.keys(localStorage)
                      .filter((x) => x.startsWith('employeeDependentData_'))
                      .forEach((x) => localStorage.removeItem(x));
                    localStorage.setItem(
                      `employeeDependentData_${empId}`,
                      JSON.stringify([])
                    );
                    navigate(`/group-employees/${empId}/petInfo`);
                  }
                },
                icon: 'pet-paw',
                isDisabled:
                  (get(apiData, 'isPetSwapPerformed', false) &&
                    !get(apiData, 'hasDemographicChanges', false)) ||
                  ifTheEmployeeStatusIs(apiData, [
                    'TERMINATED',
                    'POLICY_EXPIRED',
                    'PENDING',
                    'FAILED_TO_ENROLL',
                  ]),
                isHidden:
                  !['ENROLLED', 'POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status', '')
                  ) ||
                  !ifEmployeeIsEnrolledInPetDemographics(apiData) ||
                  ['IN_PROGRESS'].includes(
                    get(apiData, 'groupTransitionStatus')
                  ) ||
                  hideGroupAction(apiData),
                label:
                  pdbaRenewalConfigEnabled === true &&
                  ((Array.isArray(missingDemographics) &&
                    missingDemographics.filter((o) => o.hasDemographicChanges)
                      .length > 1 &&
                    apiData?.hasDemographicChanges) ||
                    (!get(apiData, 'isPetSwapPerformed', false) &&
                      isEmpty(get(apiData, 'renewalEmployee', {})) &&
                      !isEmpty(get(apiData, 'parentEmployee', {}))))
                    ? t('groupEmployees.detailsPage.renewPolicy')
                    : t('groupEmployees.detailsPage.petRegistration'),
                permission: 'group.button.petRegistration',
              }, // Enroll
              {
                action: () =>
                  !get(apiData, 'disableImportEmployee', '')
                    ? navigate(`/group-employees/${empId}/enroll`)
                    : setShowModalEnrollment(true),
                icon: 'create-group',
                isDisabled:
                  groupPolicyIsExpiredOrTerminated(
                    get(apiData, 'parentGroup') || get(apiData, 'currentGroup')
                  ) ||
                  ifTheUserHasNotBeenCreatedInCognito(apiData) ||
                  ifTheEmployeeStatusIs(apiData, [
                    'ENROLLED',
                    'TERMINATED',
                    'POLICY_EXPIRED',
                    'ACTIVE',
                    'PENDING',
                    'FAILED_TO_ENROLL',
                  ]) ||
                  ifTheEnrolmentHasNotStarted() ||
                  ifTheEmployeeHasAPlanConfirmed(apiData) ||
                  ifTheEmployeeIsTerminatedInCurrentGroup(apiData),
                isHidden:
                  ifEmployeeHasEnrolledInPreviousPolicyAndPreviousPolicyHasRenewed(
                    get(apiData, 'parentGroup') || get(apiData, 'currentGroup'),
                    parentData
                  ) ||
                  ifEmployeeIsEnrolledInPetDemographics(apiData) ||
                  ['IN_PROGRESS'].includes(
                    get(apiData, 'groupTransitionStatus')
                  ) ||
                  hideGroupAction(apiData),
                label: t('groupEmployees.detailsPage.enroll'),
              }, // Renew Policy
              {
                action: `/group-employees/${empId}/renewalenroll`,
                icon: 'renewal',
                isDisabled:
                  ifTheEnrolmentHasNotStarted() ||
                  ifTheRenewalHasBeenCompleted(
                    apiData,
                    get(apiData, 'parentGroup') || get(apiData, 'currentGroup')
                  ) ||
                  ifTheEmployeeIsTerminatedInCurrentGroup(apiData) ||
                  hideGroupAction(apiData),
                isHidden:
                  ifTheCurrentPolicyIsNotTheRenewalPolicy(
                    apiData,
                    get(apiData, 'parentGroup') || get(apiData, 'currentGroup')
                  ) ||
                  ifTheEmployeeIsNotEligibleForRenewal(
                    apiData,
                    get(apiData, 'parentGroup') || get(apiData, 'currentGroup'),
                    parentData
                  ) ||
                  ifEmployeeIsEnrolledInPetDemographics(apiData) ||
                  ['IN_PROGRESS'].includes(
                    get(apiData, 'groupTransitionStatus')
                  ) ||
                  hideGroupAction(apiData),
                label: t('groupEmployees.detailsPage.renewPolicy'),
                permission: 'groupEmployee.button.renewPolicy',
              }, // Alternate Communication Email
              {
                action: `/group-employees/${empId}/secondary-email`,
                icon: 'alternate-email',
                isHidden:
                  ifTheEmployeeIsTerminatedInCurrentGroup(apiData) ||
                  ['IN_PROGRESS'].includes(
                    get(apiData, 'groupTransitionStatus')
                  ) ||
                  hideGroupAction(apiData),
                label: t('groupEmployees.detailsPage.alternateCommunication', {
                  action: !get(apiData, 'user.secondaryEmail', '')
                    ? 'Set'
                    : 'Edit',
                }),
              }, // Edit Primary Email
              {
                action: `/group-employees/${empId}/edit-primary-email`,
                icon: 'edit',
                isHidden: ['IN_PROGRESS'].includes(
                  get(apiData, 'groupTransitionStatus')
                ),
                label: t('groupEmployees.detailsPage.updateEmail'),
              },
              {
                action: () => {
                  const paramsData = {
                    employeeId: empId,
                    helper: false,
                    sendEnrollmentEmail: false,
                    sendNotification: false,
                  };
                  return apiCall('resend-enrollment-by-employee-id', paramsData)
                    .then(() =>
                      setSuccessSnack(t('snack.success.resendMgaSuccess'))
                    )
                    .catch((e) =>
                      setErrorSnack(
                        t('snack.error.errorWithMessage', {
                          message: e.message,
                        }),
                        config.notificationDuration
                      )
                    );
                },
                icon: 'edit',
                isHidden:
                  !canBeMGAIntegrated(apiData) ||
                  ['IN_PROGRESS'].includes(
                    get(apiData, 'groupTransitionStatus')
                  ) ||
                  hideGroupAction(apiData),
                label: t('groupEmployees.detailsPage.resendMGA'),
                permission: 'groupEmployee.button.resendMga',
              },
              {
                action: () => setCreditType('transfer'),
                icon: 'refresh',
                isHidden:
                  ifStatusIsNotEnrolled(apiData) ||
                  amountOnCredit === 0 ||
                  hideGroupAction(apiData),
                label: t('groupEmployees.detailsPage.transferCreditToGroup'),
                permission: 'group.button.refundCredit',
              },
              {
                action: () => setOpenChangePlanModal(true),
                icon: 'file',
                isHidden: hideChangePlan || hideGroupAction(apiData),
                label: t('groupEmployees.detailsPage.changeEmployeePlan'),
                permission: 'groupEmployee.button.changePlan',
              }, // {
              // 	action: () => {
              // 		setChangeEmployeePlanModal(true)
              // 	},
              // 	isHidden: !showPlanChangeForEmployee,
              // 	icon: 'file',
              // 	label: 'Change Plan',
              // },
            ]}
            permission="groupEmployee.actions"
            {...rest}
          />
        ),
        title: `${get(apiData, 'user.firstName', '')} ${get(
          apiData,
          'user.lastName',
          ''
        )}`,
        type: 'group-employees',
      }}
      isPrivate
      {...rest}
    >
      <Box aria-label={userName} as="h2" sx={{ py: 3 }}>
        {loading ? (
          <PulseLoader color={get(theme, 'colors.accent')} size={5} />
        ) : (
          userName
        )}
      </Box>
      {employeeData.length > 0 && (
        <Card cardSx={{ width: 'auto' }} loading={loading}>
          <Form
            initialValues={{ versionId: empId }}
            onSubmit={noop}
            render={() => {
              return (
                <form onSubmit={noop}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}
                  >
                    <Field
                      aria-label="version"
                      component={InputDropdown}
                      label={labelHandler(
                        t('groupEmployees.detailsPage.selectPolicyVersion'),
                        true
                      )}
                      name="versionId"
                      onChange={(val) => {
                        navigate(`/group-employees/defaultEnrollee/${val}`);
                      }}
                      options={employeeData
                        .filter(
                          (x) => x.currentGroup.groupUniqueIdentifier !== null
                        )
                        .map((item) => {
                          return {
                            canEnglishValue:
                              item.currentGroup.groupUniqueIdentifier,
                            frenchValue:
                              item.currentGroup.groupUniqueIdentifier,
                            label: item.currentGroup.groupUniqueIdentifier,
                            value: item.employeeId,
                          };
                        })}
                      wrapperSx={{ padding: 3, width: '25rem' }}
                    />
                  </Box>
                </form>
              );
            }}
          />
        </Card>
      )}
      {['IN_PROGRESS'].includes(get(apiData, 'groupTransitionStatus')) && (
        <Box
          as="h4"
          sx={{
            color: 'error',
            margin: '10px 0px 0px 5px;',
            wordWrap: 'break-word',
          }}
        >
          {t('groupEmployees.detailsPage.groupMaintenance')}
        </Box>
      )}
      <Card loading={loading || documentLoading}>
        <KeyValuePairs data={apiData} fields={employeeDetails} meta={meta} />
      </Card>
      <Tabs
        loading={
          loading ||
          planPricingLoading ||
          planConfigLoading ||
          documentLoading ||
          dependentLoading
        }
        tabs={[
          {
            label: t('groupEmployees.detailsPage.educationalMaterials'),
            render: () => (
              <FileViewSection
                actionDisabled={['POLICY_EXPIRED', 'TERMINATED'].includes(
                  get(oldGroupData, 'status')
                )}
                groupId={groupId}
                isEducational
              />
            ),
          },
          {
            label: t('groupEmployees.detailsPage.petInformation'),
            render: () => (
              <>
                {
                  // isConsentAgreed &&
                  // get(oldGroupData, 'active', false) &&
                  Array.isArray(missingDemographics) && // missingDemographics.length === 1 &&
                    (removedDogCount > 0 || removedCatCount > 0) && (
                      <Box
                        as="h4"
                        sx={{
                          color: 'error',
                          margin: '10px 0px 0px 5px;',
                          wordWrap: 'break-word',
                        }}
                      >
                        {removedDogCount > 0 && (
                          <>
                            {removedDogCount} {t('common.dog')}&nbsp;
                          </>
                        )}
                        {removedDogCount > 0 && removedCatCount > 0 && (
                          <> &&nbsp; </>
                        )}
                        {removedCatCount > 0 && (
                          <>
                            {removedCatCount} {t('common.cat')}&nbsp;
                          </>
                        )}
                        &nbsp;
                        {t('groupEmployees.detailsPage.arePendingRemoval')}.
                        {Array.isArray(missingDemographics) &&
                          (removedDogCount > 0 || removedCatCount > 0) &&
                          !ifTheEmployeeStatusIs(apiData, [
                            'TERMINATED',
                            'POLICY_EXPIRED',
                            'PENDING',
                            'FAILED_TO_ENROLL',
                          ]) && (
                            <Box
                              as="h4"
                              onClick={() => {
                                localStorage.setItem(
                                  `employeeDependentData_${empId}`,
                                  JSON.stringify([])
                                );
                                if (
                                  (Array.isArray(missingDemographics) &&
                                    missingDemographics.filter(
                                      (o) => o.hasDemographicChanges
                                    ).length > 1 &&
                                    apiData?.hasDemographicChanges) ||
                                  (!get(apiData, 'isPetSwapPerformed', false) &&
                                    !isEmpty(
                                      get(apiData, 'parentEmployee', {})
                                    ) &&
                                    isEmpty(
                                      get(apiData, 'renewalEmployee', {})
                                    ) &&
                                    pdbaRenewalConfigEnabled === true)
                                ) {
                                  Object.keys(localStorage)
                                    .filter((x) =>
                                      x.startsWith('EmployeeDependentData-')
                                    )
                                    .forEach((x) => localStorage.removeItem(x));
                                  navigate(
                                    `/group-employees/petInfo/${oldGroupData?.groupNumber}/${apiData?.empId}`
                                  );
                                } else {
                                  navigate(`/group-employees/${empId}/petInfo`);
                                }
                              }}
                              sx={{
                                alignItems: 'flex-start',
                                bg: 'white',
                                borderRadius: 2,
                                display: 'flex',
                                flexWrap: 'wrap',
                                mb: 5,
                                width: '100%',
                              }}
                            >
                              <ToolbarButton
                                bg="primaryLight"
                                label={t(
                                  'groupEmployees.detailsPage.identifyRemovedPets'
                                )}
                                m={0}
                                style={{
                                  marginTop: '1em',
                                  whiteSpace: 'normal',
                                }}
                                submitting={false}
                                type="submit"
                              />
                            </Box>
                          )}
                      </Box>
                    )
                }
                {
                  // (!ifStatusIsNotEnrolled(apiData) ||
                  //     ifEmployeeIsEnrolledInPetDemographics(apiData) ||
                  //     !hideGroupAction(apiData)) &&
                  // Array.isArray(missingDemographics) &&
                  // missingDemographics.filter(o => o.hasDemographicChanges).length > 1 &&
                  // apiData?.hasDemographicChanges && config.pdbaRenewal === true &&
                  // <Box
                  //     as="h4"
                  //     onClick={async () => {
                  //         await Object.keys(localStorage).filter(x => x.startsWith('EmployeeDependentData-')).forEach(x => localStorage.removeItem(x));
                  //         navigate(`/group-employees/petInfo/${oldGroupData?.groupNumber}/${apiData?.empId}`);
                  //     }}
                  //     sx={{
                  //         alignItems: 'flex-start',
                  //         bg: 'white',
                  //         borderRadius: 2,
                  //         display: 'flex',
                  //         flexWrap: 'wrap',
                  //         mb: 5,
                  //         width: '100%',
                  //     }}
                  // >
                  //     <ToolbarButton
                  //         bg="primaryLight"
                  //         label={t('groupEmployees.detailsPage.renewPolicy')}
                  //         m={0}
                  //         style={{marginTop: '1em', whiteSpace: 'normal'}}
                  //         submitting={false}
                  //         type="submit"
                  //     />
                  // </Box>
                }
                <ListItem
                  data={get(dependentData, 'content', []).filter(
                    (dependent) => dependent.status === 'ACTIVE'
                  )}
                  grid={{
                    actions: [
                      {
                        getProps: (data) => {
                          const groupStatusNotActive =
                            get(data, 'status') !== 'ACTIVE';
                          const oldGroupExpiredOrTermed = [
                            'POLICY_EXPIRED',
                            'TERMINATED',
                          ].includes(get(oldGroupData, 'status'));
                          const employeePendingOrFailedToEnroll = [
                            'PENDING',
                            'FAILED_TO_ENROLL',
                          ].includes(get(apiData, 'status', ''));
                          return !get(apiData, 'disableImportEmployee', '')
                            ? {
                                icon: 'remove',
                                isDisabled:
                                  groupStatusNotActive ||
                                  oldGroupExpiredOrTermed ||
                                  employeePendingOrFailedToEnroll ||
                                  coverageDateEnded(data) ||
                                  (postEnrollmentConfigEnabled &&
                                    isPetDemographicEmployee(apiData)) ||
                                  ['IN_PROGRESS'].includes(
                                    get(apiData, 'groupTransitionStatus')
                                  ),
                                onClick: () => {
                                  setSubmitModalOpen({ ...data });
                                  setDisclaimerStep(2);
                                },
                              }
                            : {
                                icon: 'remove',
                                isDisabled:
                                  get(data, 'status') !== 'ACTIVE' ||
                                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                                    get(oldGroupData, 'status')
                                  ) ||
                                  ifTheEmployeeStatusIs(apiData, [
                                    'PENDING',
                                    'FAILED_TO_ENROLL',
                                  ]) ||
                                  coverageDateEnded(data) ||
                                  (postEnrollmentConfigEnabled &&
                                    isPetDemographicEmployee(apiData)) ||
                                  ['IN_PROGESS'].includes(
                                    get(apiData, 'groupTransitionStatus')
                                  ),
                                onClick: () => {
                                  setShowModalEnrollment(true);
                                },
                              };
                        },
                        label: t('common.remove'),
                        name: 'remove',
                      },
                      {
                        getProps: (data) => {
                          return {
                            icon: 'edit',
                            isDisabled:
                              get(data, 'dependentData.petName') === '' ||
                              get(data, 'dependentData.petName') === null ||
                              get(data, 'status') !== 'ACTIVE' ||
                              ['POLICY_EXPIRED', 'TERMINATED'].includes(
                                get(oldGroupData, 'status')
                              ) ||
                              ifTheEmployeeStatusIs(apiData, [
                                'PENDING',
                                'FAILED_TO_ENROLL',
                              ]) ||
                              ['IN_PROGRESS'].includes(
                                get(apiData, 'groupTransitionStatus')
                              ),
                            onClick: () => {
                              setEditPetNameModal(!editPetNameModal);
                              setUpdatedPetName(data?.dependentData?.petName);
                              setSubmitPetName({ ...data });
                            },
                            permission: 'groupEmployee.button.updatePet',
                          };
                        },
                        label: t('common.edit'),
                        name: 'edit',
                      },
                    ],
                    fields: petInformationFields,
                  }}
                  meta={employeeDependentsMetadata}
                />
              </>
            ),
          },
          {
            label: t('groupEmployees.detailsPage.inactivePetInfo'),
            render: () => (
              <ListItem
                data={get(dependentData, 'content', []).filter((dependent) =>
                  [
                    'INACTIVE',
                    'TERMINATED',
                    'INELIGIBLE',
                    'INELIGIBLE_INACTIVE',
                  ].includes(dependent.status)
                )}
                grid={{
                  actions: [],
                  fields: inActivePetInformationFields,
                }}
                meta={dependentsMetaData}
              />
            ),
          },
          ...selectedPlan.map((plan) => {
            const dynamicFields = {
              'Accident & Illness': [
                { name: 'productDetails.name' }, // { name: 'coverageFromDate' },
                // { name: 'coverageToDate' },
                { name: 'annualLimit' },
                { name: 'annualDeductible' },
                { name: 'coinsurance' },
                { name: 'diminishingDeductible' },
                { name: 'copay' },
                { name: '' },
                { name: 'wellness' },
                { name: 'riderRPT' }, // { name: 'riderICC' },
                { name: 'riderOETC' },
                { name: 'riderABC' },
                { name: 'riderFR' },
                { name: 'riderRD' },
                { name: 'poaAR' },
                { name: '' },
                { name: 'bwpInjuries' },
                { name: 'bwpIllness' },
                { name: 'bwpCLI' },
                { name: 'existingCondition' },
                { name: 'maxAge' },
                { name: 'coverageCredit' }, // { name: 'enrollmentFromDate' },
                // { name: 'enrollmentToDate' },
                { name: 'planPrice' },
              ],
              'Accident Only': [
                { name: 'productDetails.name' }, // { name: 'coverageFromDate' },
                // { name: 'coverageToDate' },
                { name: 'annualLimit' },
                { name: 'annualDeductible' },
                { name: 'coinsurance' },
                { name: 'riderRPT' },
                { name: 'riderOETC' },
                { name: 'riderAC' },
                { name: 'riderFR' },
                { name: 'riderRD' },
                { name: 'poaAR' },
                { name: 'bwpInjuries' },
                { name: 'bwpCLI' },
                { name: 'existingCondition' }, // { name: 'enrollmentFromDate' },
                // { name: 'enrollmentToDate' },
                { name: 'planPrice' },
              ],
            };
            if (config.canadaEnv) {
              dynamicFields['Accident & Illness'].splice(1, 0, {
                dynamicLabel: t('metadata.coverageFromDateCanada'),
                name: 'coverageFromDate',
              });
              dynamicFields['Accident Only'].splice(1, 0, {
                dynamicLabel: t('metadata.coverageFromDateCanada'),
                name: 'coverageFromDate',
              });
              dynamicFields['Accident & Illness'].splice(2, 0, {
                dynamicLabel: t('metadata.coverageToDateCanada'),
                name: 'coverageToDate',
              });
              dynamicFields['Accident Only'].splice(2, 0, {
                dynamicLabel: t('metadata.coverageToDateCanada'),
                name: 'coverageToDate',
              });
              dynamicFields['Accident & Illness'].splice(24, 0, {
                name: 'enrollmentFromDate',
              });
              dynamicFields['Accident & Illness'].splice(25, 0, {
                name: 'enrollmentToDate',
              });
              dynamicFields['Accident Only'].splice(15, 0, {
                name: 'enrollmentFromDate',
              });
              dynamicFields['Accident Only'].splice(16, 0, {
                name: 'enrollmentToDate',
              });
              dynamicFields['Accident & Illness'].splice(11, 0, {
                dynamicLabel: t('metadata.inheritedConditionRider'),
                name: 'riderICC',
              });
            } else {
              dynamicFields['Accident & Illness'].splice(1, 0, {
                name: 'coverageFromDate',
              });
              dynamicFields['Accident Only'].splice(1, 0, {
                name: 'coverageFromDate',
              });
              dynamicFields['Accident & Illness'].splice(2, 0, {
                name: 'coverageToDate',
              });
              dynamicFields['Accident Only'].splice(2, 0, {
                name: 'coverageToDate',
              });
              dynamicFields['Accident & Illness'].splice(24, 0, {
                name: 'enrollmentFromDate',
              });
              dynamicFields['Accident & Illness'].splice(25, 0, {
                name: 'enrollmentToDate',
              });
              dynamicFields['Accident Only'].splice(15, 0, {
                name: 'enrollmentFromDate',
              });
              dynamicFields['Accident Only'].splice(16, 0, {
                name: 'enrollmentToDate',
              });
              dynamicFields['Accident & Illness'].splice(11, 0, {
                name: 'riderICC',
              });
            }
            return {
              label: t('groupEmployees.detailsPage.planDetails', {
                planName: plan.name ? plan.name : 'Plan',
              }),
              render: () => (
                <KeyValuePairs
                  columnCount={3}
                  data={selectedPlan[0]}
                  fields={dynamicFields[get(plan, 'productDetails.name')]}
                  meta={groupsMetaData}
                />
              ),
            };
          }),
          ...allPlansData.map((plan, index) => {
            const dynamicFields = {
              'Accident & Illness': [
                { name: 'productDetails.name' },
                { name: 'coverageFromDate' },
                { name: 'coverageToDate' },
                { name: 'annualLimit' },
                { name: 'annualDeductible' },
                { name: 'coinsurance' },
                { name: 'diminishingDeductible' },
                { name: 'copay' },
                { name: '' },
                { name: 'wellness' },
                { name: 'riderRPT' },
                { name: 'riderICC' },
                { name: 'riderOETC' },
                { name: 'riderABC' },
                { name: 'riderFR' },
                { name: 'riderRD' },
                { name: 'poaAR' },
                { name: '' },
                { name: 'bwpInjuries' },
                { name: 'bwpIllness' },
                { name: 'bwpCLI' },
                { name: 'existingCondition' },
                { name: 'maxAge' },
                { name: 'coverageCredit' },
                { name: 'enrollmentFromDate' },
                { name: 'enrollmentToDate' },
              ],
              'Accident Only': [
                { name: 'productDetails.name' },
                { name: 'coverageFromDate' },
                { name: 'coverageToDate' },
                { name: 'annualLimit' },
                { name: 'annualDeductible' },
                { name: 'coinsurance' },
                { name: 'riderRPT' },
                { name: 'riderOETC' },
                { name: 'riderAC' },
                { name: 'riderFR' },
                { name: 'riderRD' },
                { name: 'poaAR' },
                { name: 'bwpInjuries' },
                { name: 'bwpCLI' },
                { name: 'existingCondition' },
                { name: 'enrollmentFromDate' },
                { name: 'enrollmentToDate' },
              ],
            };

            return {
              label: t('groupEmployees.detailsPage.planDetails', {
                planName: plan.name,
              }),
              render: () => (
                <KeyValuePairs
                  columnCount={3}
                  data={allPlansData[index]}
                  fields={dynamicFields[plan.productDetails.name]}
                  meta={meta.plan}
                />
              ),
            };
          }),
          {
            label: t('groupEmployees.detailsPage.documents'),
            render: () => (
              <UploadedDocumentsSection
                apiPath="get-claims-attachment-by-id"
                documentsList={documentsList}
                setFigoMsg={
                  [USER_ROLE.EMPLOYER, USER_ROLE.EMPLOYEE].includes(role) &&
                  groupMgaName === 'figo'
                }
              />
            ),
          },
        ]}
      />
      {creditType && (
        <Modal
          isOpen
          onClose={() => {
            setCreditType(null);
          }}
          title={
            creditType === 'transfer'
              ? t('groupEmployees.detailsPage.transferCreditToGroup')
              : 'Refund Employee Credit'
          }
        >
          <FinalForm
            initialValues={{}}
            onSubmit={async (values) => {
              const { amount = 0 } = values;
              await client
                .query({
                  fetchPolicy: 'no-cache',
                  query: remoteActionQuery,
                  variables: {
                    name:
                      creditType === 'transfer'
                        ? 'employee-credit-transfer'
                        : 'refund-employee-credit',
                    params: JSON.stringify({
                      employeeId,
                      transferAmount:
                        amountOnCredit === convertCurrencyToInt(amount)
                          ? 0
                          : convertCurrencyToInt(amount),
                    }),
                  },
                })
                .then(() => {
                  setSuccessSnack(
                    t('snack.success.actionCreditSuccess', {
                      action: creditType === 'transfer' ? 'Transfer' : 'Refund',
                    }),
                    config.notificationDuration
                  );
                  setCreditType(null);
                })
                .catch((e) => {
                  setErrorSnack(
                    t('snack.error.creatingUser', { message: e.message }),
                    config.notificationDuration
                  );
                  setCreditType(null);
                });
            }}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box>
                  <Field
                    aria-label={t('groupEmployees.detailsPage.amount')}
                    component={InputMoney}
                    label={labelHandler(
                      t('groupEmployees.detailsPage.amount'),
                      false
                    )}
                    name="amount"
                    validate={composeValidations(
                      required,
                      refundAmount(amountOnCredit)
                    )}
                    wrapperSx={{ padding: 3, width: '25rem' }}
                    {...rest}
                  />
                  <Box>
                    <Box>
                      {t('groupEmployees.detailsPage.employeeCredit', {
                        amount: amountOnCredit,
                      })}
                    </Box>
                    {t('groupEmployees.detailsPage.byClickingYes', {
                      creditAction:
                        creditType === 'transfer'
                          ? 'Transfer Employee Credit To Group'
                          : 'Refund Employee Credit',
                    })}
                    <Box
                      sx={{
                        display: 'flex',
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        my: 5,
                      }}
                    >
                      {t('common.cannotBeUndone')}
                    </Box>
                    {t('common.wishToContinue')}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      mt: '30px',
                    }}
                  >
                    <ToolbarButton
                      g="error"
                      label={t('common.cancel')}
                      onClick={() => {
                        setCreditType(null);
                      }}
                      sx={{
                        bg: 'red',
                      }}
                      width="150px"
                    />
                    <ToolbarButton
                      g="error"
                      label={t('common.yes')}
                      submitting={formContext.submitting}
                      type="submit"
                      width="150px"
                    />
                  </Box>
                </Box>
              </form>
            )}
          />
        </Modal>
      )}
      <Modal
        isOpen={changeEmployeePlanModal}
        onClose={() => {
          setChangeEmployeePlanModal(false);
        }}
        title="Change Plan"
      >
        <FinalForm
          initialValues={{}}
          onSubmit={async () => {
            await apiCall('change-plan', {
              userId,
              employeeId,
              helper: false,
              planChoiceEmployeeId: get(otherPlans, '[0].planChoiceEmployeeId'),
            })
              .then(() => {
                employeeRefetch();
                setSuccessSnack(
                  t('snack.success.changePlanSuccess'),
                  config.notificationDuration
                );
              })
              .catch((e) => {
                setErrorSnack(e?.message, config.notificationDuration);
              })
              .finally(() => {
                return setChangeEmployeePlanModal(false);
              });
          }}
          render={(formContext) => {
            return (
              <form onSubmit={formContext.handleSubmit}>
                <Box>
                  You are about to change your selected plan from{' '}
                  {selectedPlanName} to {otherPlanName}. This change will take
                  effect at the start of the upcoming policy term. Do you wish
                  to proceed?
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      mt: '30px',
                    }}
                  >
                    <ToolbarButton
                      g="error"
                      label={t('common.cancel')}
                      onClick={() => {
                        setChangeEmployeePlanModal(false);
                      }}
                      sx={{
                        bg: 'red',
                      }}
                      width="150px"
                    />
                    <ToolbarButton
                      g="error"
                      label={t('common.submit')}
                      submitting={formContext.submitting}
                      type="submit"
                      width="150px"
                    />
                  </Box>
                </Box>
              </form>
            );
          }}
        />
      </Modal>
      {openChangePlanModal && (
        <Modal
          isOpen
          onClose={() => {
            setOpenChangePlanModal(false);
          }}
          title={t('groupEmployees.detailsPage.changeEmployeePlan')}
        >
          <FinalForm
            initialValues={{}}
            onSubmit={async () => {
              await apiCall('change-plan', {
                userId,
                employeeId,
                helper: false,
                planChoiceEmployeeId: get(
                  otherPlans,
                  '[0].planChoiceEmployeeId'
                ),
              })
                .then(() => {
                  employeeRefetch();
                  setSuccessSnack(
                    t('snack.success.changePlanSuccess'),
                    config.notificationDuration
                  );
                })
                .catch((e) => {
                  setErrorSnack(e?.message, config.notificationDuration);
                })
                .finally(() => setOpenChangePlanModal(false));
            }}
            render={(formContext) => {
              return (
                <form onSubmit={formContext.handleSubmit}>
                  <Box>
                    {t('groupEmployees.detailsPage.bySubmitting', {
                      planName: get(otherPlans, '[0].name'),
                    })}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        mt: '30px',
                      }}
                    >
                      <ToolbarButton
                        g="error"
                        label={t('common.cancel')}
                        onClick={() => {
                          setOpenChangePlanModal(false);
                        }}
                        sx={{
                          bg: 'red',
                        }}
                        width="150px"
                      />
                      <ToolbarButton
                        g="error"
                        label={t('common.submit')}
                        submitting={formContext.submitting}
                        type="submit"
                        width="150px"
                      />
                    </Box>
                  </Box>
                </form>
              );
            }}
          />
        </Modal>
      )}
      <Modal
        isOpen={disclaimerStep === 2}
        submitText={t('common.confirm')}
        title={
          (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) &&
          retroPetRemoveConfigEnabled
            ? t('groupEmployees.detailsPage.removalDate')
            : null
        }
      >
        <FinalForm
          initialValues={{}}
          onSubmit={(values) => {
            // TODO:- Call API
            const { removePetDate } = values;
            const updatedRemovePetData =
              dateUtils.setAPIDateOnly(removePetDate);
            setRemovePetData(updatedRemovePetData);
            setDisclaimerStep(3);
          }}
          render={({ submitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {(role === USER_ROLE.SUPER_ADMIN ||
                role === USER_ROLE.ADMIN ||
                role === USER_ROLE.EMPLOYEE ||
                role === USER_ROLE.EMPLOYER) &&
                retroPetRemoveConfigEnabled && (
                  <Field
                    component={InputDate}
                    label={labelHandler('Select Date', true)}
                    maxDate={currentDate}
                    name="removePetDate"
                    validate={required}
                    {...rest}
                  />
                )}
              <Box mt={3}>
                {t('groupEmployees.detailsPage.petWillBeRemoved')}
              </Box>
              <Box mt={4}>{t('common.wishToContinue')}</Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  mt: '30px',
                }}
              >
                <ToolbarButton
                  g="error"
                  label={t('common.cancel')}
                  onClick={() => {
                    setSubmitModalOpen({});
                    setDisclaimerStep(1);
                  }}
                  sx={{
                    bg: 'red',
                  }}
                  width="150px"
                />
                <ToolbarButton
                  g="error"
                  label={t('common.submit')}
                  submitting={submitting}
                  type="submit"
                  width="150px"
                />
              </Box>
            </form>
          )}
        />
      </Modal>
      <Modal
        closeText={t('groupEmployees.detailsPage.cancelRequest')}
        isOpen={!isEmpty(isSubmitModalOpen) && disclaimerStep === 3}
        onClose={() => {
          setSubmitModalOpen({});
          setDisclaimerStep(1);
        }}
        onSubmit={() => {
          gaEvent('Remove Pet', 'Remove Pet', role, start);
          const params = {
            dependentId: get(isSubmitModalOpen, 'employeeDependentId', ''),
            status: 'INACTIVE',
            userId,
          };
          if (
            (role === USER_ROLE.ADMIN ||
              role === USER_ROLE.SUPER_ADMIN ||
              role === USER_ROLE.EMPLOYEE ||
              role === USER_ROLE.EMPLOYER) &&
            retroPetRemoveConfigEnabled
          ) {
            params.removalDate = removePetData;
          } else {
            params.removalDate = dateUtils.setAPIDateOnly(currentDate);
          }
          if (get(isSubmitModalOpen, 'status') === 'INACTIVE') {
            params.deleted = true;
          }
          client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'update-employee-dependent',
                params: JSON.stringify(params),
              },
            })
            .then(async (response) => {
              const results = JSON.parse(
                get(response, 'data.remoteAction.data', '{}')
              );
              if (get(results, 'petTerminationQle', false)) {
                setSuccessSnack(
                  t('snack.success.eventCreatedSuccess'),
                  config.notificationDuration
                );
              }
              setDisclaimerStep(1);
              setSubmitModalOpen({});
              refetch();
            })
            .catch((e) => {
              setErrorSnack(
                t('snack.error.errorWithMessage', { message: e.message }),
                config.notificationDuration
              );
              setDisclaimerStep(1);
              setSubmitModalOpen({});
            });
        }}
        submitText={t('groupEmployees.detailsPage.removePet')}
        title={t('groupEmployees.detailsPage.removePet')}
      >
        <Box>
          {t('groupEmployees.detailsPage.petWillBeCovered')}
          <Box
            sx={{
              display: 'flex',
              fontWeight: 'bold',
              justifyContent: 'center',
              my: 5,
            }}
          >
            {t('common.cannotBeUndone')}
          </Box>
        </Box>
      </Modal>
      <Modal
        closeText={t('common.cancel')}
        isOpen={editPetNameModal}
        onClose={() => {
          setEditPetNameModal(!editPetNameModal);
        }}
        onSubmit={async () => {
          setIsUpdateSubmitting(true);
          client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'update-employee-dependent',
                params: JSON.stringify({
                  dependentData: {
                    ...get(submitPetName, 'dependentData'),
                    petName: updatedPetName,
                  },
                  dependentId: get(submitPetName, 'employeeDependentId', ''),
                  userId,
                }),
              },
            })
            .then(async () => {
              setEditPetNameModal(!editPetNameModal);
              setUpdatedPetName(null);
              setSubmitPetName(null);
              refetch();
              setIsUpdateSubmitting(false);
            })
            .catch((e) => {
              setIsUpdateSubmitting(false);
              setErrorSnack(
                t('snack.error.errorWithMessage', { message: e.message }),
                config.notificationDuration
              );
              setEditPetNameModal(!editPetNameModal);
              setUpdatedPetName(null);
              setSubmitPetName(null);
            });
        }}
        submitText={t('common.update')}
        submitting={isUpdateSubmitting}
        title={t('groupEmployees.detailsPage.updatePetName')}
      >
        <Box>
          <Box style={{ alignItems: 'center', display: 'flex' }}>
            <Box>{t('groupEmployees.detailsPage.existingPetName')}</Box>
            <Box as="h4" ml={3}>
              {submitPetName?.dependentData?.petName}
            </Box>
          </Box>
          <Box
            as="input"
            onChange={({ target: { value } }) => setUpdatedPetName(value)}
            sx={{ marginTop: 20 }}
            value={updatedPetName || null}
            variant="inputs.primary"
            width="100%"
          />
        </Box>
      </Modal>
      <Modal
        closeText="OK"
        isOpen={showModalEnrollment}
        onClose={() => {
          setShowModalEnrollment(false);
        }}
        shouldCloseOnOverlayClick={false}
      >
        <Box>{t('groupEmployees.detailsPage.wait')}</Box>
      </Modal>
    </Route>
  );
};

Details.defaultProps = {
  showCoverage: null,
};
Details.propTypes = {
  client: PropTypes.shape({
    query: PropTypes.func.isRequired,
  }).isRequired,
  employeeData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  employeeId: PropTypes.string.isRequired,
  showCoverage: PropTypes.bool,
};

const DetailsPage = ({ employeeId, employeeData, showCoverage, ...rest }) => (
  <ApolloConsumer>
    {(client) => (
      <Details
        client={client}
        employeeData={employeeData}
        employeeId={employeeId}
        showCoverage={showCoverage}
        {...rest}
      />
    )}
  </ApolloConsumer>
);
DetailsPage.defaultProps = {
  employeeData: [],
  employeeId: null,
  showCoverage: null,
};
DetailsPage.propTypes = {
  employeeData: PropTypes.arrayOf(PropTypes.shape({})),
  employeeId: PropTypes.string,
  showCoverage: PropTypes.bool,
};
export default DetailsPage;
