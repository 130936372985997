import React, { useContext, useRef, useState } from 'react';
import get from 'lodash/get';
import { ApolloConsumer } from '@apollo/client';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import { AuthContext } from '@basecomponents/Auth';
import { isEmpty } from 'lodash';
import { Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Route from '@petcomponents/Route/index';
import ListGrid from '@basecomponents/ListGrid';
import { CA_PROVINCES, SORT_VALUE, US_STATES, USER_ROLE, } from '@utils/constants';
import GetData from '@utils/get-data';
import downloadData from '@utils/download-file';
import useSnackbar from '@utils/use-snackbar';
import remoteActionQuery from '@queries/remote-action.gql';
import HeaderRightContainer from '@basecomponents/HeaderRightContainer';
import Modal from '@basecomponents/Modal';
import ToolbarButton from '@basecomponents/ToolbarButton';
import config from '@src/config.json';
import useSsm from '@src/utilities/hooks/useSsm';

/**
 * @category Group
 * @param {string} extBenAdminId
 * @param {string} extBrokerId
 * @param {*} rest
 * @returns {React.FC}
 */
const GroupsDashboardPage = ({
    brokerGroupId,
    extBenAdminId,
    extBrokerId,
    ...rest
}) => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const {
        apiData: { GroupStatus = [] },
    } = GetData(
        'get-enum-options',
        JSON.stringify([{ params: 'GroupStatus,RenewalStatus' }])
    );
    const [setErrorSnack] = useSnackbar({ color: 'error' });
    const [setSuccessSnack] = useSnackbar({ color: 'accent' });
    const [showLoading, setLoading] = useState(false);
    const [unassignAllGroup, setUnassignAllGroup] = useState(false);
    const [terminatedData, setTermination] = useState({});
    // const [showDeleteGroupModal, setShowDeleteGroupModal] = useState({});
    const exportEmployeeConfigEnabled = useSsm('iaic-export-employee')
    const groupNotesConfigEnabled = useSsm('iaic-group-notes')

    const dataTableRef = useRef();
    let benBrokerId = extBrokerId;
    let benAdminId = extBenAdminId;
    const role = get(user, 'customRole', '');
    if (role === USER_ROLE.BEN_ADMIN) {
        benAdminId = get(user, 'customSystemBenadminId');
    }
    if (role === USER_ROLE.BEN_BROKER) {
        benBrokerId = get(user, 'customSystemBrokerId');
    }
    let defaultFilterValues = [];
    if (benAdminId) {
        defaultFilterValues = [{ benAdminId, sort: 'created,desc' }];
    }
    if (benBrokerId) {
        defaultFilterValues = [{ benBrokerId, sort: 'created,desc' }];
    }
    const filters = [
        {
            name: 'groupName',
            type: 'Input',
        },
        {
            name: 'groupNumber',
            type: 'Input',
        },
        {
            label: config.canadaEnv ? 'PROVINCES' : null,
            name: 'state',
            options: config.canadaEnv ? CA_PROVINCES : US_STATES,
            type: 'Dropdown',
        },
        {
            name: 'status',
            options: GroupStatus,
            type: 'Dropdown',
        },
        {
            label: t('filters.daysNextRenewal'),
            name: 'daysNextRenewal',
            options: [
                {
                    canEnglishValue: null,
                    frenchValue: '30 Jours',
                    label: '30 Days',
                    value: '30',
                },
                {
                    canEnglishValue: null,
                    frenchValue: '60 Jours',
                    label: '60 Days',
                    value: '60',
                },
                {
                    canEnglishValue: null,
                    frenchValue: '90 Jours',
                    label: '90 Days',
                    value: '90',
                },
                {
                    canEnglishValue: null,
                    frenchValue: '180 Jours',
                    label: '180 Days',
                    value: '180',
                },
                {
                    canEnglishValue: null,
                    frenchValue: '270  Jours',
                    label: '270 Days',
                    value: '270',
                },
            ],
            type: 'Dropdown',
        },
        {
            label: t('filters.sortBy'),
            name: 'sort',
            options: SORT_VALUE.GROUP_DASHBOARD,
            type: 'Dropdown',
        },
        // {
        //   label: 'Renewal Status',
        //   name: 'renewalStatus',
        //   options: RenewalStatus,
        //   type: 'Dropdown',
        // },
    ];
    const grid = {
        actions: [
            {
                getProps: (data) => {
                    const isStatusBillingInfoRequiredOrDraft = ['BILLING_INFO_REQUIRED', 'DRAFT'].includes(get(data, 'status'));
                    return {
                        icon: 'details',
                        isDisabled: isStatusBillingInfoRequiredOrDraft,
                        link: `/groups/${get(data, 'groupId')}/details`,
                    };
                },
                label: t('groups.view'),
                name: 'view',
            },
            {
                getProps: (data) => {
                    const isStatusDraft = data?.status === 'DRAFT'
                    return {
                        icon: 'manage',
                        isDisabled:
                            [
                                'ACTIVE',
                                'INACTIVE',
                                'GROUP_CREATION',
                                'IN_PROGRESS',
                                'BILLING_INFO_REQUIRED',
                                'TERMINATED',
                                'POLICY_EXPIRED',
                            ].includes(get(data, 'status')) ||
                            !get(data, 'locationDetails.isSitus', false) || isStatusDraft,
                        link: `/groups/activate/${get(data, 'groupId')}/${get(
                            data,
                            'plan.planId'
                        )}`,
                        permission: 'group.button.activate',
                    };
                },
                label: 'Activate',
                name: 'activate',
            },
            {
                getProps: (data) => {
                    const isStatusTerminatedOrPolicyExpired = ['TERMINATED', 'POLICY_EXPIRED'].includes(get(data, 'status'));
                    const isStatusDraft = data?.status === 'DRAFT'
                    const policyTerm = data?.policyTerm
                    const isActiveOrRenewalTerm = policyTerm > 1 || (get(data, 'active') === true);
                    const isStatusBillingInfoRequired = ['BILLING_INFO_REQUIRED'].includes(get(data, 'status'));
                    const groupId = get(data, 'groupId');
                    const planId = get(data, 'plan.planId');
                    const editUrl = isStatusBillingInfoRequired
                        ? `/groups/edit/${groupId}/${planId}?step=4`
                        : `/groups/edit/${groupId}/${planId}`;
                    return {
                        icon: 'edit',
                        isDisabled:
                            isStatusTerminatedOrPolicyExpired || !isActiveOrRenewalTerm || isStatusDraft,
                        link: editUrl,
                        permission: 'group.button.create',
                    };
                },
                label: t('common.edit'),
                name: 'edit',
            },
            {
                getProps: (data) => {
                    const noteId = data?.groupNote?.groupNoteId
                    return {
                        icon: 'details',
                        link: `/groups/note/${noteId}`,
                        visible: noteId != null,
                    };
                },
                label: 'View Group Note',
                name: 'view',
            },
            {
                getProps: (data) => {
                    const isStatusDraft = data?.status === 'DRAFT'
                    const noteId = data?.groupNote?.groupNoteId
                    return {
                        icon: 'edit',
                        link: `/groups/notes/upsert/${noteId}`,
                        visible: isStatusDraft,
                    };
                },
                label: "Edit Group Notes",
                name: 'edit',
                permission: 'group.note.edit'
            },
            {
                getProps: (data, refetch) => {
                    return {
                        icon: 'remove',
                        onClick: () => setTermination({ ...data, refetch }),
                        visible:
                            extBrokerId !== null && brokerGroupId !== get(data, 'groupId'),
                    };
                },
                label: 'unassigned',
                name: 'unassigned',
            },
            // {
            //   getProps: (data) => {
            //     return {
            //       icon: 'remove',
            //       onClick: () => {
            //         setShowDeleteGroupModal({
            //           groupId: data?.groupId,
            //           visibility: true,
            //         });
            //       },
            //     };
            //   },
            //   icon: 'remove',
            //   label: 'Delete Group',
            //   name: 'deleteGroup',
            //   permission: 'group.button.deleteGroup',
            // },
        ],
        fields: [
            {
                name: 'groupNameWithNotification',
                sortable: true,
            },
            {
                name: 'groupNumber',
                sortable: true,
            },
            {
                name: 'groupType',
            },
            {
                name: 'created',
                sortable: true,
                sortDefault: 'desc',
                type: 'date',
            },
            {
                label: config.canadaEnv ? t('groups.businessNumber') : 'Federal Number',
                name: 'fin',
            },
            {
                label: config.canadaEnv ? 'PROVINCES' : null,
                name: 'locationDetails.address.state',
            },
            {
                name: 'noOfEmployees',
            },
            {
                name: 'renewalStatus',
            },
            {
                name: 'status',
                sortable: true,
            },
        ],
    };
    if (config.developerMode) {
        grid.fields.splice(3, 0, { name: 'policyTerm' });
    }
    const postEnrollmentConfigEnabled = useSsm('iaic-post-enrollment')
    if (postEnrollmentConfigEnabled) {
        grid.fields.push({ name: 'postEnrollmentGroup' });
    }
    return (
        <ApolloConsumer>
            {(client) => {
                const downloadStats = async () => {
                    setLoading(true);
                    client
                        .query({
                            fetchPolicy: 'no-cache',
                            query: remoteActionQuery,
                            variables: {
                                name: 'employee-enrollment-statistics',
                                params: JSON.stringify({}),
                            },
                        })
                        .then((response) => {
                            const results = JSON.parse(
                                get(response, 'data.remoteAction.data', '{}')
                            );
                            downloadData({
                                s3BucketName: config.amplify.Storage.AWSS3.bucket,
                                s3KeyName: get(results, 's3Url'),
                            })
                                .then(() => {
                                    setSuccessSnack(
                                        'Report successfully downloaded',
                                        config.notificationDuration
                                    );
                                    setLoading(false);
                                })
                                .catch(() => {
                                    setErrorSnack(`No report found`, config.notificationDuration);
                                    setLoading(false);
                                });
                        })
                        .catch((e) => {
                            setLoading(false);
                            return setErrorSnack(
                                `There was an error: ${e.message}`,
                                config.notificationDuration
                            );
                        });
                };
                // const handleDeleteGroup = async () => {
                //   return client
                //     .query({
                //       fetchPolicy: 'no-cache',
                //       query: remoteActionQuery,
                //       variables: {
                //         name: 'delete-group',
                //         params: JSON.stringify({
                //           groupId: showDeleteGroupModal?.groupId,
                //         }),
                //       },
                //     })
                //     .then(() => {
                //       setSuccessSnack(
                //         'Successfully deleted a Group',
                //         config.notificationDuration
                //       );
                //       setShowDeleteGroupModal({
                //         ...showDeleteGroupModal,
                //         visibility: false,
                //       });
                //     })
                //     .catch((e) => {
                //       setShowDeleteGroupModal({
                //         ...showDeleteGroupModal,
                //         visibility: false,
                //       });
                //       return setErrorSnack(
                //         `There was an error: ${e.message}`,
                //         config.notificationDuration
                //       );
                //     });
                // };
                return extBenAdminId || extBrokerId ? (
                    <>
                        {extBrokerId && brokerGroupId === null && (
                            <ToolbarButton
                                bg="error"
                                label="Unassigned All Groups"
                                mb={5}
                                onClick={() => setUnassignAllGroup(true)}
                            />
                        )}
                        <ListGrid
                            ref={dataTableRef}
                            defaultFilterValues={defaultFilterValues}
                            exportReport={
                                exportEmployeeConfigEnabled &&
                                [
                                    USER_ROLE.ADMIN,
                                    USER_ROLE.SUPER_ADMIN,
                                    USER_ROLE.BEN_ADMIN,
                                ].includes(role)
                            }
                            exportReportType="BEN_ADMIN"
                            extBenAdminId={extBenAdminId}
                            filters={filters}
                            grid={grid}
                            gridQueryName="get-all-groups"
                            moduleName="groups"
                            {...rest}
                        />
                        {(!isEmpty(terminatedData) || unassignAllGroup) && (
                            <Modal
                                isOpen={!isEmpty(terminatedData) || unassignAllGroup}
                                onClose={() => {
                                    setTermination({});
                                    setUnassignAllGroup(false);
                                }}
                                title="Alert"
                            >
                                <FinalForm
                                    initialValues={{}}
                                    onSubmit={() => {
                                        const params = JSON.stringify({
                                            groupId: get(terminatedData, 'groupId'),
                                            subProducerId: extBrokerId,
                                        });
                                        client
                                            .query({
                                                fetchPolicy: 'no-cache',
                                                query: remoteActionQuery,
                                                variables: {
                                                    name: 'unassign-broker',
                                                    params,
                                                },
                                            })
                                            .then(async () => {
                                                if (!isEmpty(terminatedData)) {
                                                    await terminatedData.refetch();
                                                } else {
                                                    await dataTableRef.current.refetchTableData();
                                                }
                                                setTermination({});
                                                setUnassignAllGroup(false);
                                            })
                                            .catch((e) => {
                                                setTermination({});
                                                setUnassignAllGroup(false);
                                                setErrorSnack(
                                                    `There was an error: ${e.message}`,
                                                    config.notificationDuration
                                                );
                                            });
                                    }}
                                    render={(formContext) => (
                                        <form onSubmit={formContext.handleSubmit}>
                                            <Box>
                                                {unassignAllGroup
                                                    ? `You are going to unassign all groups from this ben broker.`
                                                    : `You are going to unassign ${get(
                                                        terminatedData,
                                                        'groupName'
                                                    )} from this ben broker.`}
                                                <br />
                                                Are you sure?
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-around',
                                                        mt: '30px',
                                                    }}
                                                >
                                                    <ToolbarButton
                                                        g="error"
                                                        label={t('common.cancel')}
                                                        onClick={() => {
                                                            setTermination({});
                                                            setUnassignAllGroup(false);
                                                        }}
                                                        sx={{
                                                            bg: 'red',
                                                        }}
                                                        width="150px"
                                                    />
                                                    <ToolbarButton
                                                        g="error"
                                                        label={t('common.remove')}
                                                        submitting={formContext.submitting}
                                                        type="submit"
                                                        width="150px"
                                                    />
                                                </Box>
                                            </Box>
                                        </form>
                                    )}
                                />
                            </Modal>
                        )}
                    </>
                ) : (
                    <Route
                        header={{
                            rightContainer: (
                                <HeaderRightContainer
                                    headerActions={[
                                        {
                                            action: '/groups/create',
                                            icon: 'create-group',
                                            label: 'Create Group',
                                            permission: 'group.button.create',
                                        },
                                        {
                                            action: '/groups/templateStatus',
                                            icon: 'file',
                                            label: t('groups.viewTemplateFiles'),
                                            permission: 'group.button.viewFiles',
                                        },
                                        {
                                            action: '/groups/notes/create',
                                            icon: 'create-group',
                                            label: 'Create Group Notes',
                                            isHidden: !groupNotesConfigEnabled,
                                            permission: 'group.note.create',
                                        },
                                        {
                                            action: '/groups/templateUpload',
                                            icon: 'upload',
                                            label: t('groups.uploadTemplate'),
                                            permission: 'group.button.templateUpload',
                                        },
                                        {
                                            action: '/groups/exportFile',
                                            icon: 'file',
                                            label: t('groups.viewExportFiles'),
                                            permission: 'group.button.benExportTab',
                                        },
                                        {
                                            action: () => {
                                                downloadStats();
                                            },
                                            icon: 'download',
                                            isLoading: {
                                                showLoading,
                                            },
                                            label: t('groups.groupEnrollmentStats'),
                                            permission: 'group.button.templateUpload',
                                        },
                                    ]}
                                    permission="group.dashboardActions"
                                    {...rest}
                                />
                            ),
                            title: 'Dashboard',
                            type: 'groups',
                        }}
                        isPrivate
                        permission="group.page.dashboard"
                        {...rest}
                    >
                        <Box as="h2" sx={{ py: 3 }}>
                            {t('groups.groupDashboard')}
                        </Box>
                        {/* {showDeleteGroupModal?.visibility && ( */}
                        {/*  <Modal */}
                        {/*    closeText={t('common.no')} */}
                        {/*    isOpen */}
                        {/*    onClose={() => */}
                        {/*      setShowDeleteGroupModal({ */}
                        {/*        ...showDeleteGroupModal, */}
                        {/*        visibility: false, */}
                        {/*      }) */}
                        {/*    } */}
                        {/*    onSubmit={() => handleDeleteGroup()} */}
                        {/*    submitText={t('common.yes')} */}
                        {/*    title="Confirm Submission" */}
                        {/*  > */}
                        {/*    <Box> */}
                        {/*      By clicking yes, you are confirming to delete the group */}
                        {/*      <Box */}
                        {/*        sx={{ */}
                        {/*          display: 'flex', */}
                        {/*          fontWeight: 'bold', */}
                        {/*          justifyContent: 'center', */}
                        {/*          my: 5, */}
                        {/*        }} */}
                        {/*      > */}
                        {/*        THIS ACTION CAN NOT BE UNDONE. */}
                        {/*      </Box> */}
                        {/*      Do you wish to continue? */}
                        {/*    </Box> */}
                        {/*  </Modal> */}
                        {/* )} */}
                        <ListGrid
                            defaultFilterValues={defaultFilterValues}
                            exportReport={
                                exportEmployeeConfigEnabled &&
                                [
                                    USER_ROLE.ADMIN,
                                    USER_ROLE.SUPER_ADMIN,
                                    USER_ROLE.BEN_ADMIN,
                                ].includes(role)
                            }
                            exportReportType={
                                [USER_ROLE.BEN_ADMIN].includes(role) ? 'BEN_ADMIN' : 'GLOBAL'
                            }
                            extBenAdminId={benAdminId}
                            filters={filters}
                            grid={grid}
                            gridQueryName="get-all-groups"
                            moduleName="groups"
                            {...rest}
                        />
                    </Route>
                );
            }}
        </ApolloConsumer>
    );
};

export default GroupsDashboardPage;

GroupsDashboardPage.defaultProps = {
    brokerGroupId: null,
    extBenAdminId: null,
    extBrokerId: null,
};

GroupsDashboardPage.propTypes = {
    brokerGroupId: PropTypes.string,
    extBenAdminId: PropTypes.string,
    extBrokerId: PropTypes.string,
};
