import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    height: undefined,
    width: undefined,
  });
  useEffect(() => {
    const handleResize = () => {
      let actualWidth = window.outerWidth;
      let actualHeight = window.outerHeight;
      if (window.outerWidth > window.innerWidth) {
        actualWidth = window.innerWidth;
      }
      if (window.outerHeight > window.innerHeight) {
        actualHeight = window.innerHeight;
      }
      setWindowSize({
        height: actualHeight,
        width: actualWidth,
        // width: screen.width,
      });
    };

    window.addEventListener('resize', throttle(handleResize, 50));
    window.addEventListener(
      'orientationchange',
      () => {
        // Generate a resize event if the device doesn't do it
        window.dispatchEvent(new Event('resize'));
      },
      false
    );
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};

export default useWindowSize;
