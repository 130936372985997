import React from 'react';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import get from 'lodash/get';

/**
 * Display Address
 *
 * @category Components
 * @param {Object} value Address object
 * @returns {React.FC}
 */
const Address = ({ value }) => (
  <Box>
    <Box>{get(value, 'addressLine1')}</Box>
    <Box>{get(value, 'addressLine2')}</Box>
    <Box>{get(value, 'city')}</Box>
    <Box>{get(value, 'state')}</Box>
    <Box>{get(value, 'zipcode')}</Box>
  </Box>
);

Address.defaultProps = {
  value: {},
};

Address.propTypes = {
  value: PropTypes.shape({}),
};

export default Address;
