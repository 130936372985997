const integerToRoman = ({ number, type = 'caps' }) => {
  if (typeof number !== 'number') {
    return false;
  }

  const key = [
    '',
    'C',
    'CC',
    'CCC',
    'CD',
    'D',
    'DC',
    'DCC',
    'DCCC',
    'CM',
    '',
    'X',
    'XX',
    'XXX',
    'XL',
    'L',
    'LX',
    'LXX',
    'LXXX',
    'XC',
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
  ];
  const digits = String(+number).split('');
  let romanNum = '';
  let i = 3;
  while (i >= 0) {
    i -= 1;
    romanNum = (key[+digits.pop() + i * 10] || '') + romanNum;
  }

  const roman = Array(+digits.join('') + 1).join('M') + romanNum;
  return type === 'smallcase' ? roman.toLowerCase() : roman;
};

export default integerToRoman;
