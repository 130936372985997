import { Storage } from 'aws-amplify';
import config from '../config.json';

const getS3Url = ({ s3KeyName, s3BucketName }) => {
  Storage.configure({
    AWSS3: { bucket: s3BucketName || config.amplify.Storage.AWSS3.bucket },
  });
  return new Promise((resolve, reject) => {
    Storage.get(decodeURIComponent(s3KeyName), { expires: 60 * 60 * 24 * 7 })
      .then((result) => {
        fetch(result).then((response) => {
          resolve(response.url);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getS3Url;
