import React from 'react';
import PropTypes from 'prop-types';
import get from "lodash/get";
import {useTranslation} from "react-i18next";
import GetData from '../../../../../utilities/get-data';
import ModuleContainer from '../Container';

/**
 * Enrollment status variant
 *
 * @category Components
 * @param {string} status
 * @returns {React.FC}
 */
const EnrollmentStatus = ({ status }) => {
  const { i18n } = useTranslation();
  const {
    apiData: { EnrollmentStatus = [] },
    loading,
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: 'EnrollmentStatus' }]),
    false,
    'cache-first'
  );
  const enrollmentStatus = EnrollmentStatus.find((v) => v.value === status);

  return (
    <ModuleContainer displayValue={(i18n.language === 'fr' && get(enrollmentStatus, 'frenchValue', '') !== null) ? get(enrollmentStatus, 'frenchValue') : enrollmentStatus?.label} loading={loading} />
  );
};
EnrollmentStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default EnrollmentStatus;
