import { Router } from '@reach/router';
import React from 'react';
import GroupEmployeesDashboardPage from '@petcomponents/GroupEmployees/Dashboard';
import GroupEmployeesDetailPage from '@petcomponents/GroupEmployees/DetailsPage';
import GroupEmployeesEnrollmentPage from '@petcomponents/GroupEmployees/Upsert';
import GroupEmployeesDefaultPage from '@petcomponents/GroupEmployees/DefaultEnrolee';
import AddSecondaryEmail from '@petcomponents/AddSecondaryEmail';
import LateEnrollmentPage from '@petcomponents/GroupEmployees/LateEnrollment';
import UpdatePrimaryEmail from '@petcomponents/UpdatePrimaryEmail';
import PostEnrollmentPets from '@petcomponents/PostEnrollmentPets';
import PostEnrollmentPetsRenewal from '@petcomponents/PostEnrollmentRenewal';
import NotFoundPage from './404';

const GroupEmployees = (props) => (
  <Router>
    <GroupEmployeesDashboardPage {...props} path="/group-employees/dashboard" />
    <GroupEmployeesDetailPage
      {...props}
      path="/group-employees/:employeeId/details"
    />
    <GroupEmployeesEnrollmentPage
      {...props}
      path="/group-employees/:employeeId/enroll"
    />
    <PostEnrollmentPets
      {...props}
      path="/group-employees/:employeeId/petInfo"
    />
    <PostEnrollmentPetsRenewal
      {...props}
      path="/group-employees/petInfo/:groupNumber/:employeeId"
    />
    <GroupEmployeesEnrollmentPage
      {...props}
      path="/group-employees/edit/:employeeId"
    />
    <LateEnrollmentPage
      {...props}
      path="/group-employees/:employeeId/life-event"
    />
    <GroupEmployeesDefaultPage
      {...props}
      path="/group-employees/defaultEnrollee"
    />
    <GroupEmployeesDefaultPage
      {...props}
      path="/group-employees/defaultEnrollee/:employeeId"
    />
    <AddSecondaryEmail
      {...props}
      path="/group-employees/:employeeId/secondary-email"
    />
    <UpdatePrimaryEmail
      {...props}
      path="/group-employees/:employeeId/edit-primary-email"
    />
    <NotFoundPage default path="/404" />
    <GroupEmployeesEnrollmentPage
      {...props}
      path="/group-employees/:employeeId/renewalenroll"
    />
  </Router>
);

export default GroupEmployees;
