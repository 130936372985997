import isEmail from 'validator/lib/isEmail';
import moment from 'moment';
import i18n from './i18n'

export const alpha = (value) => {
  if (!/^[a-z]*$/i.test(value)) return i18n.t('validation.onlyLetters');
};

export const checkFileExtension = (allowExtensions) => (value) => {
  if (!allowExtensions.includes(/(?:\.([^.]+))?$/.exec(value)[1])) {
    return i18n.t('validation.allowedExtensions', { extensions: allowExtensions.join(', ') })
  }
};

export const composeValidations =
  (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );

export const date = (format) => {
  return (value) => {
    if (value && !moment(value, format, true).isValid()) return i18n.t('validation.invalidDate')
  };
};

export const email = (value) => {
  if (value && !isEmail(value)) return i18n.t('validation.invalidEmail');
};

export const float = (precision) => (value) => {
  const maxPrecision = precision || 2;
  if (
    value &&
    !new RegExp(`^[-+]?[0-9]+.[0-9]{1,${maxPrecision}}$`).test(value)
  ) {
    return i18n.t('validation.invalidFloat');
  }
};

export const integer = (value) => {
  if (value && !/^[-+]?[0-9]+$/.test(value)) return i18n.t('validation.invalidInteger');
};

export const json = (value) => {
  if (!value) return;

  try {
    JSON.parse(value);
  } catch (e) {
    return i18n.t('validation.invalidJson');
  }
};

export const match = (name, match) => (value) => {
  if (value !== match) return i18n.t('validation.mustMatch', { match: name });
};

export const minLength = (length) => (value) => {
  if (value && value.length < length) {
    return i18n.t('validation.atLeastXCharacters', {length})
  }
};

export const checkEmail = (emailData) => (value) => {
  if (value && emailData && value !== emailData) {
    return i18n.t('validation.invalidEmail')
  }
};

export const required = (value) => {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && !value.length)
  ) {
    return i18n.t('validation.required');
  }
};

export const checkRequired = (value) => {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    value === false ||
    (Array.isArray(value) && !value.length)
  ) {
    return i18n.t('validation.required');
  }
};

export const versionNumber = (value) => {
  if (value && !/^[0-9]+(\.[0-9]+)?$/.test(value)) {
    return i18n.t('validation.invalidVersion');
  }
};

export function lessThanValidation(maxArg) {
  return (valArg) => {
    const maxValue = parseInt(maxArg, 10);
    const value = parseInt(valArg, 10);

    if (maxValue && value && maxValue < value) {
      return i18n.t('validation.lessThanEqual', {max: maxArg})
    }
  };
}
