const reducer = (state, { params, type }) => {
  let result = {};
  try {
    const { activeSection, stateValues } = state;
    switch (type) {
      case 'onFormValueChange': {
        result = {
          stateValues: params,
        };
        break;
      }

      case 'onNext': {
        result = {
          activeSection: activeSection + 1,
          stateValues: {
            ...stateValues,
            ...params,
          },
        };
        break;
      }

      case 'onPrev': {
        result = {
          activeSection: activeSection - 1,
        };
        break;
      }

      default: {
        // noop
        // console.error(`Action ${type} not defined`);
      }
    }
  } catch (e) {
    // noop
    // console.error(e.message);
  }
  return { ...state, ...result };
};
export default reducer;
