import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import InputText from '@basecomponents/Input';
import InputDropdown from '@basecomponents/Dropdown';
import { CA_PROVINCES, US_STATES } from '@utils/constants';
import { labelHandler } from '@utils/label-utils';
import { postalCode, zipcode } from '@utils/validations';
import config from '@src/config.json';

/**
 * @category Group
 * @param {boolean} isStateEditing Indicating whether the form should be editable or not
 * @param {string} title  Title of the form
 * @param {string} label Label for the form fields
 * @param {string} situs Situs address for the address fields
 * @param {object} wrapperSx Styling on per field basis
 * @param {boolean} disabled Indicating whether the field should be disabled or not
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const AddressFormSection = ({
  readOnly,
  isStateEditing,
  title,
  label,
  labelSx,
  situs,
  wrapperSx,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Field
        aria-label="Location Name"
        component={InputText}
        disabled={readOnly}
        label={labelHandler('Location Name')}
        name={`${label}.name`}
        wrapperSx={{ ...wrapperSx }}
        {...rest}
      />
      <Field
        aria-label={t('groupEmployees.enrollmentPage.addressLine', { number: 1 })}
        component={InputText}
        disabled={readOnly}
        label={labelHandler(t('groupEmployees.enrollmentPage.addressLine', { number: 1 }))}
        name={`${label}.addressLine1`}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Field
        aria-label={t('groupEmployees.enrollmentPage.addressLine', { number: 2 })}
        component={InputText}
        disabled={readOnly}
        label={labelHandler(t('groupEmployees.enrollmentPage.addressLine', { number: 2 }))}
        name={`${label}.addressLine2`}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Field
        aria-label={t('groupEmployees.enrollmentPage.city')}
        component={InputText}
        disabled={readOnly}
        label={labelHandler(t('groupEmployees.enrollmentPage.city'))}
        name={`${label}.city`}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Field
        aria-label={config.canadaEnv ? "provinces" : "state"}
        component={InputDropdown}
        disabled={readOnly}
        label={labelHandler(config.canadaEnv ? t('groupEmployees.enrollmentPage.provinces') : t('groupEmployees.enrollmentPage.state'))}
        name={`${label}.state`}
        options={config.canadaEnv ? CA_PROVINCES : US_STATES}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Box sx={{
        boxSizing: 'border-box',
        display: 'block',
        flex: 'inherit',
        margin: '0 !important',
        width: '25%',
      }}>
        <Field
          aria-label={config.canadaEnv ? t('groupEmployees.enrollmentPage.postalCode') : t('groupEmployees.enrollmentPage.zipCode')}
        component={InputText}
          disabled={readOnly}
          label={labelHandler(config.canadaEnv ? t('groupEmployees.enrollmentPage.postalCode') : t('groupEmployees.enrollmentPage.zipCode'),)}
          maxLength={config.canadaEnv ? 7 : 5}
          name={`${label}.zipcode`}
          validate={config.canadaEnv ? postalCode : zipcode}
          wrapperSx={{ ...wrapperSx, width: '100%', }}
          {...rest}
        />
      </Box>
    </>
  );
};

export default AddressFormSection;

AddressFormSection.propTypes = {
  disabled: PropTypes.bool,
  isStateEditing: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelSx: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  situs: PropTypes.bool,
  title: PropTypes.string,
  wrapperSx: PropTypes.shape({})
};

AddressFormSection.defaultProps = {
  disabled: false,
  isStateEditing: false,
  labelSx: {},
  readOnly: false,
  situs: false,
  title: '',
  wrapperSx: {}
};
