import React from "react"
import { useParams } from "@reach/router"
import GetData from "@src/utilities/get-data"
import Card from "@basecomponents/Card"
import KeyValuePairs from "@basecomponents/KeyValuePairs"
import Route from "@petcomponents/Route"
import Spinner from "@basecomponents/Spinner"

export default function ViewNote() {
    const params = useParams()
    const noteId = params?.noteId
    const { apiData, loading } = GetData(
        'get-group-note',
        JSON.stringify({
            noteId
        }),
        noteId == null
    )

    const groupId = apiData?.groupId
    const { apiData: groupData, loading: groupLoading } = GetData(
        'get-group-by-id',
        JSON.stringify({
            id: groupId,
        }),
        groupId == null
    )
    const benAdminId = groupData?.groupBenAdminXref?.benAdminId
    const isNotBenAdministeredGroup = groupData?.groupType !== 'BEN_ADMIN_SYSTEM'
    const { apiData: benAdminData, loading: benAdminLoading } = GetData(
        'get-ben-admin-by-id',
        JSON.stringify({ id: benAdminId }),
        isNotBenAdministeredGroup
    );
    const benAdminName = benAdminData?.name

    const groupInfoFields = [
        {
            name: 'groupName',
            label: 'Group Name'
        },
        {
            name: 'groupNumber',
            label: 'Group Number'
        },
        {
            name: 'mga',
            label: 'Group MGA'
        },
        {
            name: 'federalNumber',
            label: 'Federal Number'
        },
    ];

    const basicInfoFields = [
        {
            name: 'groupType',
            label: 'Group Type'
        },
        {
            name: 'sitLocationName',
            label: 'Location Name'
        },
        {
            name: 'sitAddress',
            label: 'Address Line'
        },
        {
            name: 'sitCity',
            label: 'City'
        },
        {
            name: 'sitState',
            label: 'State'
        },
        {
            name: 'sitZipcode',
            label: 'ZipCode'
        },
        {
            name: 'sitPhoneType',
            label: 'Phone Type'
        },
        {
            name: 'sitPhoneNumber',
            label: 'Phone Number'
        },
        {
            name: 'sitEmail',
            label: 'Email'
        },
        {
            name: 'sicCode',
            label: 'SIC Code'
        },
        {
            name: 'eligibleNumber',
            label: 'All Eligible Persons'
        },
        {
            name: 'employerContribution',
            label: 'Employer Contribution'
        },
    ]
    const billingInfoFields = [
        {
            name: 'coverageStartDate',
            label: 'Coverage Start Date',
            type: 'date'
        },
        {
            name: 'coverageEndDate',
            label: 'Effective End Date',
        },
        {
            name: 'enrollmentStartDate',
            label: 'Enrollment Start Date',
        },
        {
            name: 'enrollmentEndDate',
            label: 'Enrollment End Date',
        },
        {
            name: 'followingWaitingPeriod',
            label: 'First of Month Following Waiting Period',
        },
        {
            name: 'newHireWaitingPeriod',
            label: 'New Hire Waiting Period in Days',
        },
        {
            name: 'adminFirstName',
            label: 'Admin First Name',
        },
        {
            name: 'adminLastName',
            label: 'Admin Last Name',
        },
        {
            name: 'adminEmail',
            label: 'Admin Email',
        },
        {
            name: 'adminPrimaryPhoneNumberType',
            label: 'Admin Phone Number Type',
        },
        {
            name: 'adminPrimaryPhoneNumber',
            label: 'Admin Primary Phone Number',
        },
        {
            name: 'adminAlternatePhoneNumberType',
            label: 'Admin Alternate Phone Number Type',
        },
        {
            name: 'adminAlternatePhoneNumber',
            label: 'Admin Alternate Phone Number',
        },
        {
            name: 'groupAdminNotes',
            label: 'Group Admin Notes',
        },
        {
            name: 'payrollFrequency',
            label: 'Payroll Frequency',
        },
        {
            name: 'billingFrequency',
            label: 'Billing Cycle',
        },
        {
            name: 'billingType',
            label: 'Billing Type',
        },
        {
            name: 'billingDate',
            label: 'Billing Date',
        },
        {
            name: 'dueDate',
            label: 'Due Date',
        },
        {
            name: 'billingLocationName',
            label: 'Location Name'
        },
        {
            name: 'billingAddress',
            label: 'Address Line'
        },
        {
            name: 'billingCity',
            label: 'City'
        },
        {
            name: 'billingState',
            label: 'State'
        },
        {
            name: 'billingZipcode',
            label: 'ZipCode'
        },
        {
            name: 'accountType',
            label: 'Payment Method'
        },
        {
            name: 'implementationNotes',
            label: 'Implementation Notes'
        },
        {
            name: 'benAdminNotes',
            label: 'Ben Admin Notes'
        },
        {
            name: 'actionItem',
            label: 'Action Notes'
        },
    ]

    if (groupData?.groupType === 'BEN_ADMIN_SYSTEM') {
        apiData.groupType = "Ben Administered"
        if (benAdminData?.name != null) {
            apiData.benAdmin = benAdminName
            basicInfoFields.splice(1, 0, {
                name: 'benAdmin',
                label: 'Ben Admin'
            })
        }
    }
    if (groupData?.groupType === "IAIC_ADMINISTERED") {
        apiData.groupType = "IAIC Administered"
    }

    const billingMeta = {
        coverageStartDate: {
            type: 'localDate'
        },
        billingType: {
            picklistId: "billing-type",
            type: "picklist"
        },
        coverageEndDate: {
            type: 'localDate'
        },
        enrollmentStartDate: {
            type: 'localDate'
        },
        enrollmentEndDate: {
            type: 'localDate'
        },
        billingDate: {
            type: 'localDate'
        },
        dueDate: {
            type: 'localDate'
        },
        followingWaitingPeriod: {
            type: 'boolean',
        }
    }

    if (loading || groupLoading || benAdminLoading) {
        return <Spinner />
    }
    return <Route
        header={{
            icon: 'info',
            title: 'View Group Note',
            type: 'Group Note',
            typeLabel: 'Group Note',
        }}
        isPrivate
    >
        <Card loading={loading} title="Group Information">
            <KeyValuePairs data={apiData} fields={groupInfoFields} />
        </Card>
        <Card loading={loading} title="Sold Email Section">
            <KeyValuePairs data={apiData} fields={basicInfoFields} />
        </Card>
        <Card loading={loading} title="Call Section">
            <KeyValuePairs data={apiData} fields={billingInfoFields} meta={billingMeta} />
        </Card>
    </Route>

}

ViewNote.propTypes = {
};
