import moment from 'moment';
import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import { PulseLoader } from 'react-spinners';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import config from "@src/config.json";
import { useTranslation } from 'react-i18next';
import {
    settingLevel,
    US_STATES,
    notificationTypes, CA_PROVINCES,
} from '../../../../utilities/constants';
import GetData from '../../../../utilities/get-data';
import { getNotificationDays } from '../../../../utilities/get-notifcation-days';


/**
 * @category Utils
 * @param {any} {data}
 * @returns {any}
 */
const RenewalNotificationDate = ({ data }) => {
  const { i18n } = useTranslation()
  const { colors } = useContext(ThemeContext);
  const { apiData: globalSettings = {}, loading: globalSettingsLoading } =
    GetData(
      'get-all-system-settings',
      JSON.stringify({ settingLevel: settingLevel.global })
    );

  const { apiData: stateSettings = {}, loading: stateSettingsLoading } =
    GetData(
      'get-all-system-settings',
      JSON.stringify({
        settingLevel: settingLevel.state,
        size: config.canadaEnv ? CA_PROVINCES.length :US_STATES.length,
      })
    );

  const { apiData: groupSettings = {}, loading: groupSettingsLoading } =
    GetData(
      'get-all-system-settings',
      JSON.stringify({
        identifier: get(data, 'groupId'),
        settingLevel: settingLevel.group,
        settingType: notificationTypes.adminNotifications,
      })
    );

  if (globalSettingsLoading || stateSettingsLoading || groupSettingsLoading) {
    return <PulseLoader color={`${colors.accentSecondary}`} size={7} />;
  }

  const getDateDifferenceInDateFormat = (
    { renewalNotificationDays },
    coverageDate
  ) => {
    const days =
      parseInt(renewalNotificationDays, 10) === -1
        ? 0
        : parseInt(renewalNotificationDays, 10);
    return config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? moment(coverageDate)
      .subtract(days, 'days')
      .utc()
      .format('DD/MM/YYYY') :
      moment(coverageDate)
      .subtract(days, 'days')
      .utc()
      .format('MM/DD/YYYY');
  };

  const renewalNotificationDate = getDateDifferenceInDateFormat(
    getNotificationDays({
      apiData: { globalSettings, groupSettings, stateSettings },
      meta: {
        groupId: get(data, 'groupId'),
        groupState: get(data, 'locationDetails.address.state'),
      },
    }),
    get(data, 'plan.planChoices[0].coverageToDate')
  );

  return renewalNotificationDate ?? get(data, 'groupId');
};

RenewalNotificationDate.propTypes = {
  data: PropTypes.shape({
    groupId: PropTypes.string.isRequired,
  }).isRequired,
};

export default RenewalNotificationDate;
