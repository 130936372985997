import ReactGA from 'react-ga4';

export default (category, action, role, start) => {
  if (start) {
    ReactGA.event({
      action,
      category: `${category} - ${role}`,
      value: Date.now() - start,
    });
  }
  // useEffect
  return () => {
    const start = Date.now();
    return () => {
      ReactGA.event({
        action,
        category: `${category} - ${role}`,
        value: Date.now() - start,
      });
    };
  };
};
