import config from '@src/config.json';

export const IS_BROWSER = typeof window !== 'undefined' && window;

export const DEFAULT_LOCALE = config.canadaEnv ? 'en-GB' : 'en-US';

export const DEFAULT_LOCALE_CA = 'en-GB';

export const DEFAULT_LOCALE_US = 'en-US';

export const MGA = 'mga';

export const DEFAULT_QLE_CERTIFICATE_LINK =
  'https://www.independenceamerican.com/wp-content/uploads/2022/01/Special-Enrollment-Period-Pet-Certification-Form.pdf';

export const REPORT_TYPES = [
  {
    label: 'Payroll Deduction - Scheduled',
    value: 'AUTO_GENERATED_PAYROLL_DEDUCTION',
  },
  {
    label: 'Payroll Deduction - Generated',
    value: 'ADMIN_PAYROLL_DEDUCTION',
  },
];

export const BARREL_STATUS = [
  {
    canEnglishValue: null,
    frenchValue: 'Jeu de données vide',
    label: 'Empty Dataset',
    value: 'empty-dataset',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Normalisation terminée',
    label: 'Standardization Completed',
    value: 'standardization-completed',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Pipeline en erreur',
    label: 'Pipeline Errored',
    value: 'pipeline-errored',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Échec de la normalisation',
    label: 'Standardization Failed',
    value: 'standardization-failed',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Normalisation en attente',
    label: 'Standardization Pending',
    value: 'standardization-pending',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Normalisation terminée',
    label: 'Standardization Complete',
    value: 'standardization-complete',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Exécution terminée',
    label: 'Execution Completed',
    value: 'pipeline-completed',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Exécution lancée',
    label: 'Execution Started',
    value: 'pipeline-started',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Vérification de colonne terminée',
    label: 'Column Check Completed',
    value: 'column-check-completed',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Progression de la vérification des colonnes',
    label: 'Column Check In Progress',
    value: 'column-check-in-progress',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Exécution en attente',
    label: 'Execution Pending',
    value: 'execution-pending',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Erreur de vérification de colonne',
    label: 'Column Check Error',
    value: 'column-check-error',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Vérification de colonne requise',
    label: 'Column Check Required',
    value: 'column-check-required',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Vérification des colonnes en attente',
    label: 'Column Check Pending',
    value: 'column-check-pending',
  },
];

export const FILE_STATUS = [
  {
    canEnglishValue: null,
    frenchValue: 'Erreurs de dossier',
    label: 'Record Errors',
    value: 'errors-fail',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Erreurs de fichier',
    label: 'File Error',
    value: 'file-error',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Fichier supprimé',
    label: 'File Deleted',
    value: 'file-missing',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Terminé',
    label: 'Complete',
    value: 'errors-pass',
  },
  {
    canEnglishValue: null,
    frenchValue: 'En traitement',
    label: 'Processing',
    value: 'columns-pass',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Colonnes incorrectes',
    label: 'Incorrect Columns',
    value: 'columns-fail',
  },
  {
    canEnglishValue: null,
    frenchValue: 'En attente',
    label: 'Pending',
    value: 'pending',
  },
];

export const BARREL_FLOWS = [
  {
    canEnglishValue: null,
    frenchValue: 'Recensement des employés',
    label: 'Employee Census',
    value: 'KCCENSUS',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Inscription des employés',
    label: 'Employee Enrollment',
    value: 'KCENROLL',
  },
  {
    canEnglishValue: 'Pet Level Post Enrolment',
    frenchValue: 'Niveau de l’animal de compagnie après l’inscription',
    label: 'Pet Level Post Enrollment',
    value: 'KCPOSTENROLL',
  },
  {
    canEnglishValue: 'Employee Level Post Enrolment',
    frenchValue: 'Niveau de l’employé après l’inscription',
    label: 'Employee Level Post Enrollment',
    value: 'KCEMPPOSTENROLL',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Création de groupe',
    label: 'Group Creation',
    value: 'KCGROUPCONFIG',
  },
  {
    canEnglishValue: null,
    frenchValue: "Création d'un groupe de renouvellement",
    label: 'Renewal Group Creation',
    value: 'KCRENEWAL',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Versement',
    label: 'Remittance',
    value: 'KCREMITTANCE',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Évaluation',
    label: 'Rating',
    value: 'KCRATING',
  },
];

export const FILE_FLOWS = [
  {
    canEnglishValue: null,
    frenchValue: 'Évaluation',
    label: 'Rating',
    value: config.canadaEnv ? 'ratingCanada' : 'rating',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Recensement des employés',
    label: 'Employee Census',
    value: 'census',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Inscription des employés',
    label: 'Employee Enrollment',
    value: 'enrollment',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Versement',
    label: 'Remittance',
    value: 'remittance',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Création de groupe',
    label: 'Group Creation',
    value: config.canadaEnv ? 'groupAdminCanada' : 'groupAdmin',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Renouvellement de groupe',
    label: 'Group Renewal',
    value: config.canadaEnv ? 'renewalGroupAdminCanada' : 'renewalGroupAdmin',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Versement automatique',
    label: 'Auto Remittance',
    value: 'autoRemittance',
  },
  {
    canEnglishValue: null,
    frenchValue: null,
    label: 'Universal Remittance',
    value: 'universalRemittance',
  },
];

export const US_STATES = [
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const EXISTING_CONDITION = [
  {
    canEnglishValue: null,
    frenchValue:
      "Couvert après 12 mois (la période de rétrospection est à compter de la date de naissance de l'animal)",
    label:
      'Covered after 12 months (look back period is from date of birth of pet)',
    value: 0,
  },
  {
    canEnglishValue: null,
    frenchValue:
      'Période rétrospective de 6 mois, puis la couverture entre en vigueur après 12 mois',
    label: '6 months look back, then covered after 12 months',
    value: 6,
  },
  {
    canEnglishValue: null,
    frenchValue:
      'Période rétrospective de 12 mois, puis la couverture entre en vigueur après 12 mois',
    label: '12 months look back, then covered after 12 months',
    value: 12,
  },
  {
    canEnglishValue: null,
    frenchValue: '20 nombre de jours d’attente, puis couvert après 12 mois',
    label: '20 day look back, then covered after 12 months',
    value: 20,
  },
  {
    canEnglishValue: null,
    frenchValue: '30 nombre de jours d’attente, puis couvert après 12 mois',
    label: '30 day look back, then covered after 12 months',
    value: 30,
  },
  {
    canEnglishValue: null,
    frenchValue: '60 nombre de jours d’attente, puis couvert après 12 mois',
    label: '60 day look back, then covered after 12 months',
    value: 60,
  },
];

export const COMPANY_CODES = [
  {
    canEnglishValue: null,
    frenchValue: null,
    label: 'US',
    value: 'US',
  },
  { canEnglishValue: null, frenchValue: null, label: 'CANADA', value: 'CA' },
];

export const GROUP_MGA = [
  { label: 'FIGO', value: 'FIGO' },
  { label: 'PetPartners', value: 'PPI' },
];

export const SIT_CODES = [
  { label: 'A - Paid in advance', value: 'A-PAID IN ADVANCE' },
  {
    label: 'V - Verified',
    value: 'V-VERIFIED',
  },
  { label: 'D - Deactivated', value: 'D-DEACTIVATED' },
  { label: 'C - Canceled', value: 'C-CANCELED' },
];

export const PRODUCER_TYPE = [
  {
    canEnglishValue: null,
    frenchValue: 'Agent Général Gérant',
    label: 'MGA',
    value: 'MGA',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Société de Gestion des Avantages Sociaux',
    label: 'Ben Admin Co',
    value: 'Ben Admin Co',
  },
  {
    canEnglishValue: null,
    frenchValue: "Société d'Inscription",
    label: 'Enrollment Firm',
    value: 'Enrollment Firm',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Partenaire de Distribution',
    label: 'Distribution Partner',
    value: 'Distribution Partner',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Agent Général',
    label: 'General Agent',
    value: 'General Agent',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Agente',
    label: 'Producer',
    value: 'Producer',
  },
];

export const REQUEST_STATUS = [
  {
    label: 'Unassigned',
    value: 'UNASSIGNED',
  },
  {
    label: 'Under Review',
    value: 'UNDER REVIEW',
  },
  {
    label: 'Reviewed',
    value: 'REVIEWED',
  },
  { label: 'Declined', value: 'DECLINED' },
];

export const GROUP_SIZES = [
  {
    label: '0-25',
    value: '0-25',
  },
  {
    label: '25-50',
    value: '25-50',
  },
  {
    label: '50-100',
    value: '50-100',
  },
];

export const CONTACT_TYPE = [
  {
    canEnglishValue: null,
    frenchValue: 'TRAVAIL',
    label: 'Home',
    value: 'HOME',
  },
  {
    canEnglishValue: null,
    frenchValue: 'DOMICILE',
    label: 'Work',
    value: 'WORK',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Cellulaire',
    label: 'Cell',
    value: 'CELL',
  },
];

export const PRODUCER_SUB_PRODUCER_STATUS = [
  {
    canEnglishValue: null,
    frenchValue: 'ACTIF',
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    canEnglishValue: null,
    frenchValue: 'EN SUSPENS',
    label: 'Suspended',
    value: 'SUSPENDED',
  },
  {
    canEnglishValue: null,
    frenchValue: 'INACTIF',
    label: 'Inactive',
    value: 'INACTIVE',
  },
];

export const CLASSES_STATUS = [
  { label: 'ACTIVE', value: 'ACTIVE' },
  {
    label: 'CLOSED',
    value: 'CLOSED',
  },
  { label: 'IN-PROGRESS', value: 'IN-PROGRESS' },
];
export const CLAIM_STATUS_FILTER = [
  { label: 'COMPLETED', value: 'Completed' },
  {
    label: 'OPEN',
    value: 'Open',
  },
  { label: 'IN-PROGRESS', value: 'In Progress' },
  { label: 'NEW', value: 'New' },
  {
    label: 'DOCUMENT UPLOAD',
    value: 'Document Upload',
  },
];
export const CLAIM_STATUS = {
  COMPLETED: 'Completed',
  DOCUMENT_UPLOAD: 'Document Upload',
  IN_PROGRESS: 'In Progress',
  NEW: 'New',
  OPEN: 'Open',
};
export const GENDER = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const MOCK_PAY_FREQUENCY = [
  { label: 'Hourly', value: 'HOURLY' },
  {
    label: 'Bi-weekly',
    value: 'BIWEEKLY',
  },
  { label: 'Monthly', value: 'MONTHLY' },
];

export const TIME_CODE = [
  {
    canEnglishValue: null,
    frenchValue: 'Temps Plein',
    label: 'Full Time',
    value: 'FT',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Temps Partiel',
    label: 'Part Time',
    value: 'PT',
  },
];

export const ELIG_CLASS_DRIVERS = [
  {
    canEnglishValue: null,
    frenchValue: 'Salaire annuel',
    label: 'Annual Salary',
    value: 'annualSalary',
  },
  {
    canEnglishValue: null,
    frenchValue: "Moyenne d'heures par semaine",
    label: 'Avg hours per week',
    value: 'avgHrsPerWeek',
  },
  {
    canEnglishValue: null,
    frenchValue: "Date d'embauche",
    label: 'Date of hire',
    value: 'dateOfHire',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Statut à temps plein',
    label: 'Full time status',
    value: 'Full time status',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Intitulé du poste',
    label: 'Job title',
    value: 'Job title',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Dernière date d’embauche',
    label: 'Latest date of hire',
    value: 'Latest date of hire',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Fréquence de Paie',
    label: 'Payroll frequency',
    value: 'Payroll frequency',
  },
  {
    canEnglishValue: null,
    frenchValue: null,
    label: 'Salary rate',
    value: 'Salary rate',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Statut',
    label: 'Status',
    value: 'Status',
  },
];

export const EMPLOYEE_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const PET_SPECIES = [
  {
    canEnglishValue: null,
    frenchValue: 'Chats',
    label: 'Cat',
    value: 'Cat',
  },
  { canEnglishValue: null, frenchValue: 'Chiens', label: 'Dog', value: 'Dog' },
];

export const PET_AGE = {
  Cat: [
    {
      canEnglishValue: null,
      frenchValue: "Moins d'un an",
      label: 'Under 1 year',
      value: 0,
    },
    { canEnglishValue: null, frenchValue: null, label: '1', value: 1 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '2',
      value: 2,
    },
    { canEnglishValue: null, frenchValue: null, label: '3', value: 3 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '4',
      value: 4,
    },
    { canEnglishValue: null, frenchValue: null, label: '5', value: 5 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '6',
      value: 6,
    },
    { canEnglishValue: null, frenchValue: null, label: '7', value: 7 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '8',
      value: 8,
    },
    { canEnglishValue: null, frenchValue: null, label: '9', value: 9 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '10',
      value: 10,
    },
    { canEnglishValue: null, frenchValue: null, label: '11', value: 11 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '12',
      value: 12,
    },
    { canEnglishValue: null, frenchValue: null, label: '13', value: 13 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '14',
      value: 14,
    },
    { canEnglishValue: null, frenchValue: null, label: '15', value: 15 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '16',
      value: 16,
    },
    { canEnglishValue: null, frenchValue: null, label: '17', value: 17 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '18',
      value: 18,
    },
    { canEnglishValue: null, frenchValue: null, label: '19', value: 19 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '20',
      value: 20,
    },
    { canEnglishValue: null, frenchValue: null, label: '21', value: 21 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '22',
      value: 22,
    },
    { canEnglishValue: null, frenchValue: null, label: '23', value: 23 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '24+',
      value: 24,
    },
  ],
  Dog: [
    {
      canEnglishValue: null,
      frenchValue: "Moins d'un an",
      label: 'Under 1 year',
      value: 0,
    },
    { canEnglishValue: null, frenchValue: null, label: '1', value: 1 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '2',
      value: 2,
    },
    { canEnglishValue: null, frenchValue: null, label: '3', value: 3 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '4',
      value: 4,
    },
    { canEnglishValue: null, frenchValue: null, label: '5', value: 5 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '6',
      value: 6,
    },
    { canEnglishValue: null, frenchValue: null, label: '7', value: 7 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '8',
      value: 8,
    },
    { canEnglishValue: null, frenchValue: null, label: '9', value: 9 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '10',
      value: 10,
    },
    { canEnglishValue: null, frenchValue: null, label: '11', value: 11 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '12',
      value: 12,
    },
    { canEnglishValue: null, frenchValue: null, label: '13', value: 13 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '14',
      value: 14,
    },
    { canEnglishValue: null, frenchValue: null, label: '15', value: 15 },
    {
      canEnglishValue: null,
      frenchValue: null,
      label: '16+',
      value: 16,
    },
  ],
};

export const ROLE = [
  { canEnglishValue: null, frenchValue: null, label: 'ADMIN', value: 'ADMIN' },
  {
    canEnglishValue: null,
    frenchValue: 'ADMIN AV. SOCIAUX',
    label: 'BEN ADMIN',
    value: 'BEN_ADMIN',
  },
  {
    canEnglishValue: null,
    frenchValue: 'COURTIER AV. SOCIAUX',
    label: 'BEN BROKER',
    value: 'BEN_BROKER',
  },
  {
    canEnglishValue: null,
    frenchValue: 'EMPLOYÉ',
    label: 'EMPLOYEE',
    value: 'EMPLOYEE',
  },
  {
    canEnglishValue: null,
    frenchValue: 'EMPLOYEUR',
    label: 'EMPLOYER',
    value: 'EMPLOYER',
  },
  {
    canEnglishValue: null,
    frenchValue: 'ADMIN SUP.',
    label: 'SUPER ADMIN',
    value: 'SUPER_ADMIN',
  },
];

export const ADMIN_ROLE = {
  ADMIN: 'ADMIN',
  BEN_ADMIN: 'BEN-ADMIN',
  SUPER_ADMIN: 'SUPER-ADMIN',
};
export const EMPLOYEE_DEPENDENT_OLDVSNEW = {
  NEW: 'NEW',
  OLD: 'OLD',
};

export const ACCEPTANCE_CODE = [
  {
    canEnglishValue: null,
    frenchValue: 'Oui',
    label: 'Yes',
    value: 'true',
  },
  { canEnglishValue: null, frenchValue: 'Non', label: 'No', value: 'false' },
];

export const PRODUCT_PLAN = [
  {
    canEnglishValue: null,
    frenchValue: 'Accident et maladie',
    label: 'Accident & Illness',
    value: 'Accident and Illness',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Accident seulement',
    label: 'Accident Only',
    value: 'Accident Only',
  },
];
export const USER_ROLE = {
  ADMIN: 'admin',
  BEN_ADMIN: 'ben-admin',
  BEN_BROKER: 'ben-broker',
  EMPLOYEE: 'employee',
  EMPLOYER: 'employer',
  RENEWAL_POLICY_DELETED: 'renewal-policy-deleted',
  SUPER_ADMIN: 'super-admin',
  TERMINATED_EMPLOYEE: 'terminated-employee',
};

export const E_CONSENT_URLS = {
  DEMO: 'https://kc-demo-dev-data.s3.us-east-2.amazonaws.com/Group+Pet+Insurance+-+Statement+of+eConsent.pdf',
  FIGO: 'https://figofileshare.blob.core.windows.net/gpi/IAIC%20Electronic%20Consent%20Spraoi%20-%20Figo.pdf',
  PPI: 'https://www.petpartners.com/res/common/files/electronic_consent_group.pdf',
  PTZ_SODACO: 'https://www.onepackplan.ca/electronic-consent',
  PTZ_SODACO_FR: 'https://www.onepackplan.ca/fr/consentement-electronique',
};
// export const ADDITIONAL_INSURANCE = [
//   { label: 'Yes', value: true },
//   { label: 'No', value: false },
// ];
export const CNF_GROUP_TYPE = [
  {
    canEnglishValue: null,
    frenchValue: "Administré par l'administrateur des avantages sociaux",
    label: 'Ben Admin Administered',
    value: 'BEN_ADMIN_SYSTEM',
  },
  {
    canEnglishValue: null,
    frenchValue: "Administré par l'IAIC",
    label: 'CNF Administered',
    value: 'IAIC_ADMINISTRATED',
  },
];
export const DEMO_GROUP_TYPE = [
  {
    canEnglishValue: null,
    frenchValue: "Administré par l'administrateur des avantages sociaux",
    label: 'Ben Admin Administered',
    value: 'BEN_ADMIN_SYSTEM',
  },
  {
    canEnglishValue: null,
    frenchValue: "Administré par l'IAIC",
    label: 'Portal Administered',
    value: 'IAIC_ADMINISTRATED',
  },
];
export const GROUP_TYPE = [
  {
    canEnglishValue: null,
    frenchValue: "Administré par l'administrateur des avantages sociaux",
    label: 'Ben Admin Administered',
    value: 'BEN_ADMIN_SYSTEM',
  },
  {
    canEnglishValue: null,
    frenchValue: "Administré par l'IAIC",
    label: 'IAIC Administered',
    value: 'IAIC_ADMINISTRATED',
  },
];
export const QLE_TYPE = {
  EMPLOYEE_TERMINATION: 'EMPLOYEE_TERMINATION',
  LATE_ENROLLMENT: 'LATE_ENROLLMENT',
  NEW_PET: 'NEW_PET',
};

export const SORT_VALUE = {
  ACCOUNTS_BOOK_DASHBOARD: [
    {
      canEnglishValue: null,
      frenchValue: 'Nom du groupe: croissant',
      label: 'Group Name: ascending ',
      value: 'groupNameSort,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Nom du groupe: décroissant',
      label: 'Group Name: descending',
      value: 'groupNameSort,desc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date de règlement: croissante',
      label: 'Remittance Date: ascending',
      value: 'remittanceDate,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date de règlement: décroissant',
      label: 'Remittance Date: descending',
      value: 'remittanceDate,desc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Montant confirmé: croissant',
      label: 'Confirmed Amount: ascending',
      value: 'confirmedAmount,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Montant confirmé: décroissant',
      label: 'Confirmed Amount: descending',
      value: 'confirmedAmount,desc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date confirmée: croissant',
      label: 'Confirmed Date: ascending',
      value: 'confirmedDate,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date confirmée: décroissante',
      label: 'Confirmed Date: descending',
      value: 'confirmedDate,desc',
    },
  ],
  BILLING_DASHBOARD: [
    {
      canEnglishValue: null,
      frenchValue: 'Nom du groupe: croissant',
      label: 'Group Name: ascending',
      value: 'groupNameSort,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Nom du groupe: décroissant',
      label: 'Group Name: descending',
      value: 'groupNameSort,desc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date de facturation : croissante',
      label: 'Billed Date: ascending',
      value: 'billedDate,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date de facturation : décroissante',
      label: 'Billed Date: descending',
      value: 'billedDate,desc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date de règlement : croissante',
      label: 'Remittance Date: ascending',
      value: 'remittanceDate,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date de règlement : décroissante',
      label: 'Remittance Date: descending',
      value: 'remittanceDate,desc',
    },
  ],
  GROUP_DASHBOARD: [
    {
      canEnglishValue: null,
      frenchValue: 'Nom du groupe: croissant',
      label: 'Group Name: ascending',
      value: 'groupName,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Nom du groupe: décroissant',
      label: 'Group Name: descending',
      value: 'groupName,desc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Numéro du groupe: croissant',
      label: 'Group Number: ascending',
      value: 'groupNumber,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Numéro du groupe: décroissant',
      label: 'Group Number: descending',
      value: 'groupNumber,desc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date de création : croissant',
      label: 'Created: ascending',
      value: 'created,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date de création : décroissant',
      label: 'Created: descending',
      value: 'created,desc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Statut du groupe: croissant',
      label: 'Group Status: ascending',
      value: 'status,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Statut du groupe: décroissant',
      label: 'Group Status: descending',
      value: 'status,desc',
    },
  ],
  LIFE_EVENTS_DASHBOARD: [
    {
      canEnglishValue: null,
      frenchValue: 'Numéro QLE: croissant',
      label: 'QLE Number: ascending',
      value: 'qleNumber,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Numéro QLE: décroissant',
      label: 'QLE Number: descending',
      value: 'qleNumber,desc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Nom du groupe: croissant',
      label: 'Group Name: ascending',
      value: 'groupName,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Nom du groupe: décroissant',
      label: 'Group Name: descending',
      value: 'groupName,desc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date demandée : croissante',
      label: 'Requested Date: ascending',
      value: 'requestedDate,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Date demandée : décroissant',
      label: 'Requested Date: descending',
      value: 'requestedDate,desc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Dernière modification: croissant',
      label: 'Last Modified: ascending',
      value: 'lastModified,asc',
    },
    {
      canEnglishValue: null,
      frenchValue: 'Dernière modification: décroissant',
      label: 'Last Modified: descending',
      value: 'lastModified,desc',
    },
  ],
};

export const settingLevel = {
  global: 'GLOBAL',
  group: 'GROUP',
  state: 'STATE',
};

export const notificationTypes = {
  adminNotifications: 'ADMIN_NOTIFICATION',
  benAdminChangeFileSchedule: 'BEN_ADMIN_CHANGE_FILE_SCHEDULE',
  enrollmentStartNotification: 'ENROLL_START_NOTIFICATION',
  outstandingBalanceNotification: 'OUTSTANDING_BALANCE_NOTIFICATION',
};

export const DAYS_IN_A_WEEK = [
  {
    id: 'SUNDAY',
    label: 'Sunday',
    value: 'sunday',
  },
  {
    id: 'MONDAY',
    label: 'Monday',
    value: 'Monday',
  },
  {
    id: 'Tuesday',
    label: 'Tuesday',
    value: 'Tuesday',
  },
  {
    id: 'Wednesday',
    label: 'Wednesday',
    value: 'wednesday',
  },
  {
    id: 'Thursday',
    label: 'Thursday',
    value: 'thursday',
  },
  {
    id: 'FRIDAY',
    label: 'Friday',
    value: 'friday',
  },
  {
    id: 'SATURDAY',
    label: 'Saturday',
    value: 'saturday',
  },
];

export const uploadOption = [
  {
    canEnglishValue: null,
    frenchValue: 'Baril',
    label: 'Barrel',
    value: 'BarrelUpload',
  },
  {
    canEnglishValue: null,
    frenchValue: 'Gabarit',
    label: 'Template',
    value: 'TemplateUpload',
  },
];
export const CA_PROVINCES = [
  {
    canEnglishValue: null,
    frenchValue: 'l’Alberta',
    label: 'Alberta',
    value: 'AB',
  },
  {
    canEnglishValue: null,
    frenchValue: 'la Colombie-Britannique',
    label: 'British Columbia',
    value: 'BC',
  },
  {
    canEnglishValue: null,
    frenchValue: 'le Manitoba',
    label: 'Manitoba',
    value: 'MB',
  },
  {
    canEnglishValue: null,
    frenchValue: 'le Nouveau-Brunswick',
    label: 'New Brunswick',
    value: 'NB',
  },
  {
    canEnglishValue: null,
    frenchValue: 'la Terre-Neuve-et-Labrador',
    label: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    canEnglishValue: null,
    frenchValue: 'la Nouvelle-Écosse',
    label: 'Nova Scotia',
    value: 'NS',
  },
  {
    canEnglishValue: null,
    frenchValue: 'les Territoires du Nord-Ouest',
    label: 'Northwest Territories',
    value: 'NT',
  },
  {
    canEnglishValue: null,
    frenchValue: 'le Nunavut',
    label: 'Nunavut',
    value: 'NU',
  },
  {
    canEnglishValue: null,
    frenchValue: 'l’Ontario',
    label: 'Ontario',
    value: 'ON',
  },
  {
    canEnglishValue: null,
    frenchValue: 'l’île du Prince-Édouard',
    label: 'Prince Edward Island',
    value: 'PE',
  },
  {
    canEnglishValue: null,
    frenchValue: 'le Québec',
    label: 'Québec',
    value: 'QC',
  },
  {
    canEnglishValue: null,
    frenchValue: 'la Saskatchewan',
    label: 'Saskatchewan',
    value: 'SK',
  },
  {
    canEnglishValue: null,
    frenchValue: 'le Yukon',
    label: 'Yukon',
    value: 'YT',
  },
];
