import React, { forwardRef, useImperativeHandle } from 'react';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

/**
 * @category BaseComponents
 * @param {number} hours
 * @param {number} minutes
 * @param {number} seconds
 * @param {React.Ref} ref
 * @returns {React.FC}
 */
const CountDown = forwardRef(({ hours = 0, minutes = 0, seconds = 0 }, ref) => {
  const [paused, setPaused] = React.useState(false);
  const [over, setOver] = React.useState(false);
  const [time, setTime] = React.useState({
    hours: Number(hours),
    minutes: Number(minutes),
    seconds: Number(seconds),
  });

  const tick = () => {
    if (paused || over) return;
    if (time.minutes === 59 && time.seconds === 59) {
      setTime({
        hours: time.hours + 1,
        minutes: 0,
        seconds: 0,
      });
    } else if (time.seconds === 59) {
      setTime({
        hours: time.hours,
        minutes: time.minutes + 1,
        seconds: 0,
      });
    } else {
      setTime({
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds + 1,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    reset() {
      setTime({
        hours: Number(hours),
        minutes: Number(minutes),
        seconds: Number(seconds),
      });
      setPaused(false);
      setOver(false);
    },
  }));

  React.useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Box>Please Wait...</Box>
        <Box>{`${time.hours.toString().padStart(2, '0')}:${time.minutes
          .toString()
          .padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`}</Box>
        <Box>{over ? "Time's up!" : ''}</Box>
      </Box>
      <Box flexDirection="row" mt={2}>
        {time.minutes > 2 && (
          <Box
            onClick={() => navigate(`/groups/templateStatus`)}
            sx={{
              color: 'accentDark',
              cursor: 'pointer',
              fontSize: 3,
            }}
          >
            Track the file status over here
          </Box>
        )}
      </Box>
    </Box>
  );
});
CountDown.propTypes = {
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
};
export default CountDown;
