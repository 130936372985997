import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import ToolbarButton from '@basecomponents/ToolbarButton';
import Spinner from '@basecomponents/Spinner';
import convertCamelCaseToTitleCase from '@utils/convert-camel-case-to-title-case';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledPage = styled(Page)`
  box-shadow: ${(p) => p.theme.shadows[2]};
`;

const GetMasterPolicyS3 = ({
  file,
  pdfType,
  location,
  width,
  ...pageProps
}) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const onLoadDocument = ({ numPages }) => {
    setNumPages(numPages);
  };

  const hasNextPage = () => {
    return pageNumber < numPages;
  };

  const hasPreviousPage = () => {
    return pageNumber > 1;
  };

  const nextPage = () => {
    if (!hasNextPage()) return;
    setPageNumber(pageNumber + 1);
  };

  const previousPage = () => {
    if (!hasPreviousPage()) return;
    setPageNumber(pageNumber - 1);
  };

  const paginator = () => {
    if (numPages > 1) {
      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            my: 7,
          }}
        >
          <ToolbarButton
            bg="accent"
            buttonSx={{
              borderRadius: '50%',
              height: '50px',
              width: '50px',
            }}
            icon="left-chevron"
            isDisabled={!hasPreviousPage()}
            onClick={previousPage}
          />
          <Box
            sx={{ fontWeight: 'bold', mx: 4 }}
          >{`Page ${pageNumber} of ${numPages}`}</Box>
          <ToolbarButton
            bg="accent"
            buttonSx={{
              borderRadius: '50%',
              height: '50px',
              width: '50px',
            }}
            icon="right-chevron"
            isDisabled={!hasNextPage()}
            onClick={nextPage}
          />
        </Box>
      );
    }

    return null;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          color: 'primary',
          fontSize: 6,
          textAlign: 'center',
        }}
      >
        {convertCamelCaseToTitleCase(pdfType.replace(/_/g, ' '))}
      </Box>
      {paginator()}
      <Document
        file={file}
        loading={<Spinner />}
        onLoadSuccess={onLoadDocument}
      >
        <StyledPage pageNumber={pageNumber} width={width} {...pageProps} />
      </Document>
      {paginator()}
    </Box>
  );
};

GetMasterPolicyS3.propTypes = {
  file: PropTypes.string,
  location: PropTypes.shape({}),
  pdfType: PropTypes.string,
  width: PropTypes.number,
};

GetMasterPolicyS3.defaultProps = {
  file: '',
  location: {},
  pdfType: '',
  width: 600,
};

export default GetMasterPolicyS3;
