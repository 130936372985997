import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledContent = styled.div`
  background-color: ${(p) => p.theme.colors.gray[5]};
  margin: 0 auto 0 auto;
  padding-top: calc(5rem + 20px);
  padding-right: 2rem;
  padding-bottom: 8rem;
  ${({ isResponsive, theme }) =>
    isResponsive
      ? 'padding-left: 2rem;'
      : `padding-left: calc(2rem + ${theme.sizes.widths.sidebarMin});`};
  overflow-y: hidden;
  -ms-overflow-style: none;
  min-height: 100vh;
  position: relative;
`;

StyledContent.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
};

/**
 * @category Components
 * @param {React.Component} children
 * @param {boolean} isSidebarOpen
 * @param {boolean} isResponsive
 * @returns {React.FC}
 */
const Content = ({ children, isSidebarOpen, isResponsive }) => (
  <StyledContent
    className={isSidebarOpen ? 'isSidebarOpen' : ''}
    isResponsive={isResponsive}
    isSidebarOpen={isSidebarOpen}
  >
    {children}
  </StyledContent>
);

Content.propTypes = {
  children: PropTypes.node.isRequired,
  isResponsive: PropTypes.bool.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
};

export default Content;
