import { useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import { lighten } from 'polished';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import HeaderRightContainer from '@basecomponents/HeaderRightContainer';
import { useApolloClient } from '@apollo/client';
import useSnackbar from '@src/utilities/use-snackbar';
import { useTranslation } from 'react-i18next';
import { AuthContext } from "@basecomponents/Auth";
import { USER_ROLE } from "@utils/constants";
import Icon from '@src/components/Icon'
import config from '@src/config.json'
import GetData from '../../../../utilities/get-data';
import getMetaData from '../../../../utilities/get-meta-data';
import Card from '../../../BaseComponents/Card';
import KeyValuePairs from '../../../BaseComponents/KeyValuePairs';
import Route from '../../Route';
import Tabs from '../../../BaseComponents/Tabs';
import UploadedDocumentsSection from '../../UploadedDocumentsSection';
import ClaimDependentsSection from './ClaimDependentsSection';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql'

/**
 * @category Claims
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const ClaimsDetailsPage =  ({ ...rest }) => {
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const theme = useContext(ThemeContext);
  const queryParams = useParams();
  const claimData = get(queryParams, 'claimId');
  const { apiData:{ content: employeeData = [] } = {}   } =  GetData(
    'get-all-claims',
    JSON.stringify({ externalClaimId: claimData }),
    ![
      USER_ROLE.EMPLOYEE,
      USER_ROLE.TERMINATED_EMPLOYEE,
      USER_ROLE.RENEWAL_POLICY_DELETED,
    ].includes(role),
  );
  const claimId = get(employeeData,'0.claimId') || get(queryParams, 'claimId')  ;
  const { apiData, loading } = GetData(
    'get-claims-by-id',
    JSON.stringify({ id: claimId })
  );
  const client = useApolloClient()
  const { t } = useTranslation()

  const { apiData: documentsList = [], documentLoading } = GetData(
    'get-all-claims-attachments',
    JSON.stringify({ id: claimId })
  );
  const claimsMetaData = getMetaData({ moduleName: 'claims' });

  // Match PPI's claimId, were created externally
  if ((config.canadaEnv === false) && claimId.match(/^\d+$/)) {
    return <Route
      isPrivate
      {...rest}
    >
      <Box
        sx={{
          alignItems: 'center',
          color: 'error',
          display: 'flex',
          fontSize: 6,
          fontWeight: 'bold',
          justifyContent: 'center',
          overflow: 'auto'
        }}
      >
        <Box sx={{
          fontWeight: 'normal',
          m: 5,
          mt: '10vh'
        }}>
          This claims was created outside of the pet portal. For additional information regarding this claim please contact customer care at <Box as="a" href="tel:8009562495" sx={{
            alignItems: 'center',
            borderRadius: 5,
            boxShadow: 5,
            display: 'inline-flex',
            justifyContent: 'center',
            m: 5,
            p: 1,
            width: 'fit-content'
          }}>
            <Icon color="accentSecondaryDark" svg="phone" sx={{
              alignItems: 'center',
              display: 'flex',
              height: '35px',
              justifyContent: 'center',
              mr: 2,
              width: '35px',
            }} />
            <Box>800-956-2495</Box></Box> or by email at
          <Box as="a" href="mailto:mypolicy@petpartners.com" sx={{
            alignItems: 'center',
            borderRadius: 5,
            boxShadow: 5,
            display: 'inline-flex',
            justifyContent: 'center',
            m: 5,
            p: 1,
            width: 'fit-content'
          }}>
            <Icon color="accentSecondaryDark" svg="mail" sx={{
              alignItems: 'center',
              display: 'flex',
              height: '35px',
              justifyContent: 'center',
              mr: 2,
              width: '35px',
            }} />
            <Box>mypolicy@petpartners.com</Box></Box>
        </Box>
      </Box>
    </Route >
  }

  return (
    <Route
      header={{
        rightContainer: (
          <HeaderRightContainer
            headerActions={[
              {
                action: async () => {
                  if (!get(apiData, 'externalClaimId')) {
                    return client.query({
                      fetchPolicy: 'no-cache',
                      query: remoteActionQuery,
                      variables: {
                        name: "resend-claim",
                        params: JSON.stringify({ claimId }),
                      },
                    }).then(() => {
                      setSuccessSnack(
                        t('snack.success.claimResend'),
                      )
                    }).catch((e) => {
                      setErrorSnack(
                        t('snack.error.errorWithMessage', { message: e.message })
                      )
                    })
                  }
                },
                icon: 'refresh',
                isDisabled: get(apiData, 'externalClaimId', false),
                label: 'Resend Claim Request',
                permission: "claims.button.resend"
              }
            ]}
            {...rest}
          />
        ),
        title: get(apiData, 'externalClaimId', ''),
        type: 'claims',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {loading ? (
          <PulseLoader
            color={lighten(0.1, `${get(theme, 'colors.accentLight')}`)}
            size={5}
          />
        ) : (
          `Details of Claim ${get(apiData, 'externalClaimId', '')}`
        )}
      </Box>
      <Card loading={loading || documentLoading}>
        <KeyValuePairs
          columnCount={2}
          data={apiData}
          fields={[
            { name: 'externalClaimId' },
            { name: 'paidAmount' },
            { name: 'createdBy' },
            { name: 'employeeId' },
            { name: 'status' },
            { name: 'lastModified' },
          ]}
          meta={claimsMetaData}
        />
      </Card>
      <Tabs
        loading={loading}
        tabs={[
          {
            label: t('claimsScreen.claimsDetailsPage.claimsDependents'),
            render: () => (
              <ClaimDependentsSection
                dependentClaims={get(apiData, 'dependentClaims', [])}
                employeeDependentIds={get(apiData, 'dependentClaims', []).map(
                  ({ employeeDependentId }) => employeeDependentId
                )}
              />
            ),
          },
          {
            label: 'Documents',
            render: () => (
              <UploadedDocumentsSection
                apiPath="get-claims-attachment-url-by-id"
                documentsList={documentsList}
              />
            ),
          },
        ]}
      />
    </Route>
  );
};

ClaimsDetailsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default ClaimsDetailsPage;
