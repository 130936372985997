import {navigate, useParams} from '@reach/router';
import Box from '@basecomponents/Box';
import {get} from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import {ApolloConsumer} from '@apollo/client';
import {AuthContext} from '@basecomponents/Auth';
import React, {useContext, useState} from 'react';
import {ThemeContext} from 'styled-components';
import {useTranslation} from 'react-i18next';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql';
import GetData from '../../../../utilities/get-data';
import useSnackbar from '../../../../utilities/use-snackbar';
import Icon from '../../../Icon';
import Modal from '../../../BaseComponents/Modal';
import Spinner from '../../../BaseComponents/Spinner';
import ToolbarButton from '../../../BaseComponents/ToolbarButton';
import generateRedirectPath from '../../../../utilities/generate-redirect-path';
import config from '../../../../config.json';
import dateUtils from '../../../../utilities/date-utils';

/**
 * @category ProductAdmin
 * @param {Object} location
 * @param {string} name
 * @returns {React.FC}
 */
const SummaryPage = ({ location, name, isRenewal }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiSubmitting, setApiSubmitting] = useState(false);
  const queryParams = useParams();
  const planId = get(queryParams, 'planId', '');
  const groupId = get(queryParams, 'groupId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const { user } = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const userId = get(user, 'customSystemUserId', '');

  const { apiData: planData = [], loading: planPricingLoading } = GetData(
    'get-plan-pricing',
    JSON.stringify({
      planId,
    })
  );
  const { apiData: group = {}, loading: groupLoading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );
  const { apiData: files = {}, loading: fileLoading } = GetData(
    'get-tasks',
    JSON.stringify({ flow: 'census', groupId, page: 0, size: 10 }),
    !groupId
  );
  const censusData = files.content ? files.content[0] : null;
  if (planPricingLoading || groupLoading || fileLoading) {
    return <Spinner />;
  }

  const noOfEmployees = get(group, 'noOfEmployees', 0);
  const summaryKeyStyled = {
    border: `1px solid ${theme.colors.accent}`,
    borderRadius: 1,
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 2,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 0,
    m: 3,
    maxWidth: '200px',
    mr: 4,
    p: 2,
    textTransform: 'uppercase',
    width: '100%',
  };

  const summaryValueStyled = { fontSize: 3, width: '100%' };

  return (
    <ApolloConsumer>
      {(client) => {
        const apiCall = (pathName, params) =>
          client.query({
            fetchPolicy: 'no-cache',
            query: remoteActionQuery,
            variables: {
              name: pathName,
              params: JSON.stringify(params),
            },
          });
        const updateGroupAPICall = async () => {
          if (isRenewal) {
            setApiSubmitting(true);
            if (
              get(group, 'status') !== 'ACTIVE' &&
              get(group, 'postEnrollmentGroup') !== true
            ) {
              await apiCall('employee-migration', {
                groupIds: [groupId],
                runDependentMigration: false,
              }).catch((e) => {
                setApiSubmitting(false);
                setErrorSnack(`There was an error: ${e.message}`);
              });
            }
          }
          setShowModal(false);
          client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'update-group',
                params: JSON.stringify({
                  groupId,
                  status: 'ACTIVE',
                  userId,
                }),
              },
            })
            .then(() => {
              if (isRenewal) {
                setSucessSnack('Group Renewed successfully.');
                setShowModal(false);
                setApiSubmitting(false);
                return navigate(
                  generateRedirectPath(
                    {
                      ...location,
                      queryParams: {
                        [name]: 12,
                      },
                    },
                    { replace: true }
                  )
                );
              }

              setSucessSnack('Group activated successfully.');
              return navigate(
                generateRedirectPath({
                  ...location,
                  queryParams: { [name]: 7 },
                })
              );
            })
            .catch((e) => {
              setLoading(false);
              setShowModal(false);
              setApiSubmitting(false);
              return setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              );
            });
        };
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              mb: 8,
              width: '100%',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                bg: 'white',
                borderRadius: 4,
                boxShadow: 1,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '25rem',
                padding: 3,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Icon
                  svg="groups"
                  sx={{
                    color: 'primaryDark',
                    height: '30px',
                    mr: 4,
                    width: '30px',
                  }}
                />
                <Box
                  sx={{
                    fontSize: 4,
                    fontWeight: 'bold',
                  }}
                >
                  Summary
                </Box>
              </Box>
              <Box
                sx={{
                  display: isRenewal ? 'none' : 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  mt: 5,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Box sx={summaryKeyStyled}>Employees</Box>
                  <Box sx={summaryValueStyled}>{noOfEmployees}</Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: isRenewal ? 'none' : 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Box sx={summaryKeyStyled}>Census Uploaded On</Box>
                  <Box
                    sx={{
                      fontSize: 3,
                      width: '100%',
                    }}
                  >
                    {censusData
                      ? Moment(get(censusData, 'created', '')).format(
                          'D MMMM yyyy'
                        )
                      : '-'}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    mt: isRenewal ? 5 : 0,
                  }}
                >
                  <Box sx={summaryKeyStyled}>Plans</Box>
                  <Box sx={summaryValueStyled}>
                    {planData
                      .map((element) => {
                        return `${get(element, 'name', '')}: ${get(
                          element,
                          'productDetails.name',
                          ''
                        )}`;
                      })
                      .join(', ')}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Box sx={summaryKeyStyled}>Coverage Starts On</Box>
                  <Box sx={summaryValueStyled}>
                    {dateUtils.getUTCDateOnly(
                      get(planData[0], 'coverageFromDate')
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Box sx={summaryKeyStyled}>Coverage Ends On</Box>
                  <Box sx={summaryValueStyled}>
                    {dateUtils.getUTCDateOnly(
                      get(planData[0], 'coverageToDate')
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Box sx={summaryKeyStyled}>Enrollment Starts On</Box>
                  <Box sx={summaryValueStyled}>
                    {dateUtils.getUTCDateOnly(
                      get(planData[0], 'enrollmentFromDate')
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Box sx={summaryKeyStyled}>Enrollment Ends On</Box>
                  <Box sx={summaryValueStyled}>
                    {dateUtils.getUTCDateOnly(
                      get(planData[0], 'enrollmentToDate')
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  my: 6,
                  px: 2,
                  width: '100%',
                }}
              >
                <ToolbarButton
                  bg="primaryLight"
                  label={t('common.previous')}
                  ml={0}
                  onClick={() =>
                    navigate(
                      generateRedirectPath({
                        ...location,
                        queryParams: { [name]: isRenewal ? 10 : 5 },
                      })
                    )
                  }
                  width="10rem"
                />
                <ToolbarButton
                  bg="primaryDark"
                  isDisabled={loading}
                  label={t('common.submit')}
                  mr={0}
                  onClick={() => {
                    setShowModal(true);
                    setLoading(true);
                  }}
                  submitting={loading}
                  width="10rem"
                />
                {showModal === true && (
                  <Modal
                    closeText={t('common.no')}
                    isOpen
                    onClose={() => {
                      setShowModal(false);
                      setLoading(false);
                    }}
                    onSubmit={updateGroupAPICall}
                    shouldCloseOnOverlayClick={isRenewal && false}
                    submitText={t('common.yes')}
                    submitting={isRenewal && apiSubmitting}
                    title={t('groupEmployees.dashboardPage.confirmSubmission')}
                  >
                    <Box>
                      By clicking yes, you are agreeing to the current plan
                      configuration.
                      <Box
                        sx={{
                          display: 'flex',
                          fontWeight: 'bold',
                          justifyContent: 'center',
                          my: 5,
                        }}
                      >
                        THIS ACTION CAN NOT BE UNDONE.
                      </Box>
                      Do you wish to continue?
                    </Box>
                  </Modal>
                )}
              </Box>
            </Box>
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

SummaryPage.propTypes = {
    isRenewal: PropTypes.bool,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
    }).isRequired,
    name: PropTypes.string,
};

SummaryPage.defaultProps = {
    isRenewal: false,
    name: 'step',
};

export default SummaryPage;
