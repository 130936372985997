import React, { useContext, useState, useEffect } from 'react';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import { AuthContext } from '@basecomponents/Auth';
import { USER_ROLE } from '@utils/constants';
import config, { variation } from '@src/config.json';
import GetData from '@src/utilities/get-data';
import BlockDemographicClaimModal from '@petcomponents/BlockDemographicClaim';
import Spinner from '@basecomponents/Spinner';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import useSsm from '@src/utilities/hooks/useSsm';
import Route from '../../Route';
import ListGrid from '../../../BaseComponents/ListGrid';
import FigoInfo from './FigoInfo';
import getEmployeeStatus from '../../../../utilities/get-default-employee-status';
import HeaderRightContainer from '../../../BaseComponents/HeaderRightContainer';
import getAdminGroup from '../../../../utilities/get-default-group-admin';

const containerSx = {
  bg: 'white',
  borderRadius: 2,
  overflow: 'auto',
  p: 5,
};

/**
 * @category Claims
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const ClaimsDashboardPage = ({ ...rest }) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const { employeeId, userLoginRootEmployeeId,empId,groupNumber:grpNumber } = getEmployeeStatus(
    get(user, 'customSystemUserId', ''),
    role
  );
  const userId = get(user, 'customSystemUserId', '');
  const groupMgaName = get(user, 'customMga', 'ppi').toLowerCase();
  const { status } = getEmployeeStatus(userId, role);
  const { groupNumber } = getAdminGroup(userId, role);
  const [isClaimsBlockedForPetDemographic, setBlockedClaims] = useState(false);
  const { apiData = {}, loading } = GetData(
    'get-group-employee-by-id',
    JSON.stringify({ id: employeeId }),
    role !== USER_ROLE.EMPLOYEE || employeeId === null
  );
  const [isOpen, setOpen] = useState(false);
  const postEnrollmentEnabled = useSsm('iaic-post-enrollment')
  useEffect(() => {
    const hasPetBeenNotAdded = get(apiData, 'hasDemographicChanges', false);
    setBlockedClaims(postEnrollmentEnabled && hasPetBeenNotAdded);
  }, [apiData]);

  let defaultFilterValues = [];

  const CLAIM_STATUS_FILTER = [
    { canEnglishValue: null, frenchValue: 'COMPLÉTÉ', label: t('filters.completed'), value: 'Completed' },
    { canEnglishValue: null, frenchValue: 'OUVRIR', label: t('filters.open'), value: 'Open' },
    { canEnglishValue: null, frenchValue: 'EN COURS', label: t('filters.inProgress'), value: 'In Progress' },
    { canEnglishValue: null, frenchValue: 'NOUVEAU', label: t('filters.new'), value: 'New' },
    { canEnglishValue: null, frenchValue: 'TÉLÉCHARGEMENT DE DOCUMENTS', label: t('filters.documentUpload'), value: 'Document Upload' },
  ];
  const EMP_CLAIM_STATUS_FILTER = [
    { label: t('filters.Assigned'), value: 'Assigned' },
    { label: t('filters.Processing'), value: 'Processing' },
    { label: t('filters.Reopened'), value: 'Reopened' },
    { label: t('filters.Received'), value: 'Received' },
    { label: t('filters.Closed'), value: 'Closed' },
    { label: t('filters.Claim_Completed'), value: 'Completed' },
  ];
  if (role === USER_ROLE.BEN_ADMIN) {
    const benAdminId = get(user, 'customSystemBenadminId', '');
    defaultFilterValues = [{ benAdminId, sort: 'created,desc' }];
  } else if (role === USER_ROLE.EMPLOYER) {
    defaultFilterValues = [{ groupNumber }];
  } else if (role === USER_ROLE.BEN_BROKER) {
    const benBrokerId = get(user, 'customSystemBrokerId');
    defaultFilterValues = [{ benBrokerId, sort: 'created,desc' }];
  } else if (
    [
      USER_ROLE.EMPLOYEE,
      USER_ROLE.TERMINATED_EMPLOYEE,
      USER_ROLE.RENEWAL_POLICY_DELETED,
    ].includes(role)
  ) {

    // defaultFilterValues = [{ employeeUserId: userId }];
    defaultFilterValues = [{ employeeIds: userLoginRootEmployeeId }];
  }
  const renderPPIInfo = () => {
    return (
      <Box
        sx={{
          ...containerSx,
          mb: 5,
          mt: 5,
        }}
      >
        {t('claimsScreen.dashboardPage.petPartners.contactCustomer')}&nbsp;
        {variation === 'demo' ? (
          <>(xxx) xxx-xxxx </>
        ) : (
          <Box as="a" href={`tel:+${config.canadaEnv ? '8333107225' : '8009562495'}`}>
            {config.canadaEnv ? '(833) 310-7225' : '(800) 956-2495'}
          </Box>
        )}
        &nbsp;{t('claimsScreen.dashboardPage.petPartners.checkStatus')}
      </Box>
    );
  };
  const renderFigoBrokerInfo = () => {
    return (
      <Box
        sx={{
          ...containerSx,
          mb: 5,
          mt: 5,
        }}
      >
        {t('claimsScreen.dashboardPage.figo.note')}&nbsp;
        <Box as="a" href="https://www.mypetcloud.com/">
          FIGO Pet Cloud.
        </Box>
        <br />
        {['ppi','ptz_sodaco'].includes(groupMgaName) && (
          <>
            {t('claimsScreen.dashboardPage.figo.contactCustomer')}&nbsp;
            {variation === 'demo' ? (
              <>(xxx) xxx-xxxx </>
            ) : (
              <Box as="a" href="tel:+8009562495">
                (800) 956-2495
              </Box>
            )}
            &nbsp;{t('claimsScreen.dashboardPage.figo.checkStatus')}
          </>
        )}
      </Box>
    );
  };
  const filters = [
    {
      label: t('filters.claimNumber'),
      name: 'externalClaimId',
      type: 'Input',
    },
    {
      label: t('filters.firstName'),
      name: 'userFirstName',
      type: 'Input',
    },
    {
      label: t('filters.lastName'),
      name: 'userLastName',
      type: 'Input',
    },
    {
      label: t('filters.employeeNumber'),
      name: 'empId',
      type: 'Input',
    },
    {
      label: t('filters.groupName'),
      name: 'groupName',
      type: 'Input',
    },
    {
      label: t('filters.groupNumber'),
      name: 'groupNumber',
      type: 'Input',
    },
    {
      name: 'status',
      options:   [
        USER_ROLE.EMPLOYEE,
        USER_ROLE.TERMINATED_EMPLOYEE,
        USER_ROLE.RENEWAL_POLICY_DELETED,
      ].includes(role)? EMP_CLAIM_STATUS_FILTER : CLAIM_STATUS_FILTER,
      type: 'Dropdown',
    },
    {
      label: t('filters.submittedDate'),
      name: 'created',
      type: 'Date',
    },
  ];
  if ([
    USER_ROLE.EMPLOYEE,
    USER_ROLE.TERMINATED_EMPLOYEE,
    USER_ROLE.RENEWAL_POLICY_DELETED,
  ].includes(role)) {
    filters.splice(1, 5);
  }
  if ([
    USER_ROLE.EMPLOYER,
  ].includes(role)) {
    filters.splice(4, 2);
  }
  const grid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'details',
            link: `/claims/view/${get(data, 'claimId')}`,
          };
        },
        label: t('claimsScreen.dashboardPage.viewClaim'),
        name: 'detail',
      },
    ],
    // created,
    fields: [
      USER_ROLE.EMPLOYEE,
      USER_ROLE.TERMINATED_EMPLOYEE,
      USER_ROLE.RENEWAL_POLICY_DELETED,
    ].includes(role) ? [
      {
        name: 'claimId',
      },
      {
        name: 'paidAmount',
      },
      {
        name: 'claimStatusDisplay',
      },
      {
        name: 'receiveDate',
      },
    ] : [
      {
        name: 'externalClaimId',
      },
      {
        name: 'paidAmount',
      },
      {
        name: 'status',
      },
      {
        // name: [
        //   USER_ROLE.EMPLOYEE,
        //   USER_ROLE.TERMINATED_EMPLOYEE,
        //   USER_ROLE.RENEWAL_POLICY_DELETED,
        // ].includes(role)
        //   ? 'created'
        //   : 'lastModified',
        name:'lastModified'
      },
    ],
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <Route
      header={{
        rightContainer: ['ppi','ptz_sodaco'].includes(groupMgaName) && (
          <HeaderRightContainer
            headerActions={[
              {
                action: () => {
                  if (isClaimsBlockedForPetDemographic) {
                    setOpen(true);
                  } else {
                    navigate(`/claims/create`);
                  }
                },
                icon: 'claims',
                isDisabled: ['active', 'inactive', 'pending'].includes(status),
                label: t('claimsScreen.dashboardPage.createClaim'),
              },
            ]}
            permission="claims.actions"
            {...rest}
          />
        ),
        title: t('claimsScreen.dashboardPage.title'),
        type: 'claims',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {t('claimsScreen.dashboardPage.claimsDashboard')}
      </Box>
      {groupMgaName === 'figo' && role !== USER_ROLE.BEN_BROKER && (
        <FigoInfo wrapperSx={containerSx} />
      )}
      {['ppi','ptz_sodaco'].includes(groupMgaName) &&
        role !== USER_ROLE.BEN_BROKER &&
        renderPPIInfo()}
      {role === USER_ROLE.BEN_BROKER && renderFigoBrokerInfo()}
      {['ppi','ptz_sodaco'].includes(groupMgaName) && (
        <ListGrid
          defaultFilterValues={defaultFilterValues}
          filters={filters}
          grid={grid}
          gridQueryName={
            [
              USER_ROLE.EMPLOYEE,
              USER_ROLE.TERMINATED_EMPLOYEE,
              USER_ROLE.RENEWAL_POLICY_DELETED,
            ].includes(role)
              ? 'get-all-claims-by-employee-id'
              : 'get-all-claims'
          }
          moduleName="claims"
          {...rest}
        />
      )}
      <BlockDemographicClaimModal
        empId={empId}
        employeeId={employeeId}
        groupNumber={grpNumber}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </Route>
  );
};
export default ClaimsDashboardPage;
