import React from 'react';
import SpraoiFileUpload from '../../Pet/FileUpload';
import InputWrapper from '../InputWrapper';

const FileUploadInput = (props) => (
  <InputWrapper {...props}>
    {(rest) => <SpraoiFileUpload {...rest} />}
  </InputWrapper>
);

export default FileUploadInput;
