import {navigate, useParams} from '@reach/router';
import Box from '@basecomponents/Box';
import {get} from 'lodash';
import React, {useContext, useState} from 'react';
import {AuthContext} from '@basecomponents/Auth';
import {Field, Form as FinalForm} from 'react-final-form';
import {ApolloConsumer} from '@apollo/client';
import {PulseLoader} from 'react-spinners';
import {ThemeContext} from 'styled-components';
import {labelHandler} from '@utils/label-utils';
import {useTranslation} from 'react-i18next';
import UploadFile from '../../UploadFile';
import ToolbarButton from '../../../BaseComponents/ToolbarButton';
import Route from '../../Route';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql';
import useSnackbar from '../../../../utilities/use-snackbar';
import GetData from '../../../../utilities/get-data';
import Spinner from '../../../BaseComponents/Spinner';
import InputText from '../../../BaseComponents/Input';
import config from '../../../../config.json';

/**
 * @category QLE
 * @param {*} rest
 * @returns {React.FC}
 */
const AddQLEDocumentsPage = ({ ...rest }) => {
  const theme = useContext(ThemeContext);
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const queryParams = useParams();
  const qleEventId = get(queryParams, 'qleEventId', '');
  const { apiData = {}, loading } = GetData(
    'get-qle-event-by-id',
    JSON.stringify({ id: qleEventId })
  );
  const { t } = useTranslation();

  const employeeId = get(apiData, 'employeeId', '');
  const groupId = get(apiData, 'groupId', '');
  const documentsDataList = apiData?.supportingDocs || [];
  if (loading) {
    return <Spinner />;
  }
  return (
    <Route
      header={{
        title: get(apiData, 'qleNumber', qleEventId),
        type: 'life-events',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {loading ? (
          <PulseLoader color={get(theme, 'colors.accent')} size={5} />
        ) : (
          `Add Documents of ${get(apiData, 'qleNumber', qleEventId)}`
        )}
      </Box>
      <ApolloConsumer>
        {(client) => {
          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  bg: 'white',
                  borderRadius: 4,
                  boxShadow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 3,
                }}
              >
                <FinalForm
                  onSubmit={async (values) => {
                    const { fileUpload } = values;
                    client
                      .query({
                        fetchPolicy: 'no-cache',
                        query: remoteActionQuery,
                        variables: {
                          name: 'update-qle',
                          params: JSON.stringify({
                            qleEventId: get(apiData, 'qleEventId', ''),
                            supportingDocs: fileUpload,
                            userId,
                          }),
                        },
                      })
                      .then(() => {
                        setSuccessSnack(
                          t('snack.success.eventCreatedSuccess'),
                          config.notificationDuration
                        );
                        navigate(`/life-events/${qleEventId}/details`);
                      })
                      .catch((e) =>
                        setErrorSnack(
                          t('snack.error.errorWithMessage', {
                            message: e.message,
                          }),
                          config.notificationDuration
                        )
                      );
                  }}
                  render={({ form, submitting, handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                      <Field
                        component={InputText}
                        label={labelHandler(
                          t(
                            'groupEmployees.enrollmentPage.lateEnrollmentPage.fileName'
                          ),
                          true
                        )}
                        name="fileName"
                        wrapperSx={{ ...commonFieldSxHalf }}
                        {...rest}
                      />
                      {values.fileName && (
                        <UploadFile
                          allowMultiple
                          employeeId={employeeId}
                          files={
                            values.fileUpload
                              ? [...values.fileUpload, ...documentsDataList]
                              : documentsDataList
                          }
                          flow="lateEnrollment"
                          form={form}
                          groupId={groupId}
                          name={values.fileName}
                          onUploadComplete={() => setButtonDisabled(false)}
                          {...rest}
                        />
                      )}
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          padding: 3,
                        }}
                      >
                        <ToolbarButton
                          bg="accentDark"
                          isDisabled={submitting || buttonDisabled}
                          label={t('common.submit')}
                          mr={0}
                          submitting={submitting}
                          type="submit"
                          width="150px"
                        />
                      </Box>
                    </form>
                  )}
                />
              </Box>
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

export default AddQLEDocumentsPage;
