const gridHeaders = {
  commissionsDashboard: [
    {
      format: 'dd/MM/yyyy',
      name: 'effectiveDate',
      sortable: false,
      type: 'date',
    },
    {
      format: 'dd/MM/yyyy',
      name: 'expirationDate',
      sortable: false,
      type: 'date',
    },
    {
      name: 'commissionDTOList.producerDetail.name',
      sortable: false,
    },
    {
      name: 'commissionDTOList.subProducer.name',
      sortable: false,
    },
    {
      name: 'totalCommissionPercentage',
      sortable: false,
    },
    {
      name: 'commissionDTOList.commissionPercentage',
      sortable: false,
    },
  ],
  configureClassesSection: [
    {
      name: 'eligibilityClass.eligibilityClassName',
      sortable: false,
    },
    {
      name: 'eligibilityClass.driverValue',
      sortable: false,
    },
    {
      name: 'eligibilityClass.active',
      sortable: false,
    },
  ],
  enrolleeDashboardPage: [
    {
      name: 'user.firstName',
      sortable: false,
    },
    {
      name: 'user.lastName',
      sortable: false,
    },
    {
      name: 'empId',
      sortable: true,
    },
    {
      name: 'department',
      sortable: true,
    },
    {
      name: 'jobTitle',
      sortable: true,
    },
    {
      name: 'dateOfHire',
      sortable: true,
      type: 'date',
    },
  ],
  groupBillingInformationSection: [
    { name: 'cycleNo', sortable: false },
    { name: 'startDate', sortable: false },
    { name: 'endDate', sortable: false },
    { name: 'payrollCalendarStartDate', sortable: false },
    { name: 'payrollCalendarEndDate', sortable: false },
    { name: 'generatedPaidDate', sortable: false },
    { name: 'paidDate', sortable: false },
    { name: 'generatedBillCreationDate', sortable: false },
    { name: 'billCreationDate', sortable: false },
    { name: 'generatedDueDate', sortable: false },
    { name: 'dueDate', sortable: false },
  ],
  groupContactsBrokerSection: [
    { name: 'user.firstName', sortable: false },
    { name: 'user.lastName', sortable: false },
    { name: 'user.email', sortable: false },
    { name: 'contact.contactType', sortable: false },
    { name: 'contact.phoneNumber', sortable: false },
  ],
  groupContactsSection: [
    { name: 'firstName', sortable: false },
    { name: 'lastName', sortable: false },
    { name: 'email', sortable: false },
    { name: 'contacts[0].contactType', sortable: false },
    { name: 'contacts[0].phoneNumber', sortable: false },
  ],
};

const TABLE_LISTING = {
  billingCalender: {
    actions: [
      {
        action: 'view',
        label: 'View',
      },
      {
        action: 'payment',
        label: 'Payment',
      },
      {
        action: 'notes',
        label: 'Notes',
      },
      {
        action: 'viewNotes',
        label: 'View Notes',
      },
    ],
    gridHeaders: gridHeaders.groupBillingInformationSection,
    headerTitle: 'Payroll Information',
  },
  commissionsDashboard: {
    gridHeaders: gridHeaders.commissionsDashboard,
    headerTitle: 'Commission Dashboard',
  },
  configureClassesSection: {
    gridHeaders: gridHeaders.configureClassesSection,
  },
  groupBillingInformationSection: {
    gridHeaders: gridHeaders.groupBillingInformationSection,
    headerTitle: 'Payroll Information',
  },
  groupContactsBrokerSection: {
    actions: [],
    gridHeaders: gridHeaders.groupContactsBrokerSection,
  },
  groupContactsSection: {
    actions: [
      {
        action: 'edit',
        label: 'Edit',
      },
    ],
    gridHeaders: gridHeaders.groupContactsSection,
  },
  groupDetailsPage: {
    headerTitle: 'Group Details',
  },
};

export default function listingConfig(type){
  const meta = TABLE_LISTING[type];
  const result = {};
  result.actions = meta.actions;
  result.gridHeaders = meta.gridHeaders;
  result.headerTitle = meta.headerTitle;
  return result;
};
