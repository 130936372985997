import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import Spinner from '@basecomponents/Spinner';
import { TOOLTIP } from '@basecomponents/ListGrid/utils/constants';
import { useTranslation } from 'react-i18next';
import GetData from '../../../../../utilities/get-data';
import dateUtils from '../../../../../utilities/date-utils';
import User from '../../../Variant/ModuleInfo/User';
import TooltipBox from '../../../../BaseComponents/TooltipBox';

/**
 * @category QLE
 * @param {string} qleEventId
 * @param {string} created
 * @param {*} rest
 * @returns {React.FC}
 */
const QleHistorySection = ({ qleEventId, created, ...rest }) => {
  const { t } = useTranslation()
  const { apiData = {}, loading = false } = GetData(
    'get-qle-history-by-id',
    JSON.stringify({ id: qleEventId, sort: 'created' })
  );
  const { apiData: changeLogData = {}, changeLogLoading = false } = GetData(
    'get-qle-change-log-by-id',
    JSON.stringify({ id: qleEventId, sort: 'created' })
  );

  const { isHighContrastOn } = rest;

  const type = get(apiData, '0.eventData', '');
  return loading || changeLogLoading ? (
    <Spinner />
  ) : (
    <Box
      sx={{
        color: `${isHighContrastOn ? 'primary' : 'accent'}`,
        fontSize: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '99%',
        }}
      >
        <User id={get(apiData, '0.eventData.createdBy', '')} /> &nbsp;
        {t('lifeEvents.details.createdThisQle', {
          qle: get(apiData, '0.eventData.qleType', '')
            .replace('_', ' ')
            .toUpperCase()
        })}
        {type?.qleType === 'NEW_PET' && type?.reasonType !== null
          ? t('lifeEvents.details.dueToReason', { reason: get(type, 'reasonType', '').replaceAll('_', ' ') })
          : null}
        {t('lifeEvents.details.atDate', { date: dateUtils.getFormattedDate(created) })}
      </Box>
      {changeLogData.length > 0 &&
        changeLogData.map((data, index) => (
          <>
            <Box
              key={index}
              sx={{ display: 'flex', flexWrap: 'wrap', width: '99%' }}
            >
              <User id={get(data, 'changeBy', null)} /> &nbsp;
              {data.changes.requestStatus
                ? t('lifeEvents.details.hasChangedStatus', {
                  status: get(data, 'changes.requestStatus', '') === 'REVIEWED'
                    ? 'APPROVED'
                    : get(data, 'changes.requestStatus', '')
                      .replace('_', ' ')
                      .toUpperCase()
                })
                : null}
              {data.changes.assignedTo && <> {t('lifeEvents.details.assignedQle')}</>}
              {data.changes.assignedTo && (
                <User id={get(data, 'changes.assignedTo', '')} />
              )}
              {data.changes.requestStatus === 'DECLINED'
                ? `as  "${get(data, 'changes.declinedReasonType', '')
                  .replaceAll('_', ' ')
                  .toUpperCase()}"`
                : null}
              {data.changes.declinedReasonType === 'OTHER'
                ? t('lifeEvents.details.dueToReason', {
                  reason: get(data, 'changes.reason', '')
                    .replaceAll('_', ' ')
                    .toUpperCase()
                })
                : null}
              {data.changes.reopenReasonType
                ? t('lifeEvents.details.reopendQle', {
                  reason: get(
                    data,
                    'changes.reopenReasonType',
                    ''
                  )
                    .replaceAll('_', ' ')
                    .toUpperCase()
                })
                : null}
              {data.changes.reopenReasonType === 'OTHER'
                ? t('lifeEvents.details.asReason', {
                  reason: get(data, 'changes.reason', '')
                    .replaceAll('_', ' ')
                    .toUpperCase()
                }) : null}
              <Box
                data-for={TOOLTIP}
                data-tip={dateUtils.getFormattedDateTime(
                  get(data, 'changeAt', '')
                )}
              >
                .&nbsp;
                {dateUtils.fromNow(get(data, 'changeAt', ''))}
              </Box>
            </Box>
          </>
        ))}
      <TooltipBox
        key="ts"
        id={TOOLTIP}
        tooltipProps={{ effect: 'solid' }}
        tooltipSx={{ bg: 'accent' }}
      />
    </Box>
  );
};

QleHistorySection.propTypes = {
  created: PropTypes.string.isRequired,
  qleEventId: PropTypes.string.isRequired,
};

export default QleHistorySection;
