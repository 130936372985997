import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

/**
 * @category BaseComponents
 * @param {Object} sx
 * @param {*} rest
 * @returns {React.FC}
 */
const TableWrapper = ({ sx, ...rest }) => {
  const { defaultStyle = {} } = useContext(ThemeContext);
  return <Box sx={defaultStyle.defaultTableWrapper(sx)} {...rest} />;
};

TableWrapper.propTypes = {
  sx: PropTypes.shape({}),
};

TableWrapper.defaultProps = {
  sx: {},
};

export default TableWrapper;
