import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import Spinner from '../../../../BaseComponents/Spinner';

/**
 * @category Components
 * @param {boolean} active Indicates whether title or spinner is active
 * @param {boolean} loading Indicates whether spinner is loading
 * @param {string} title Indicates the title for the header
 * @returns {React.FC}
 */
const FormHeader = ({ active, loading, title }) => {
  return (
    <Box>
      {(loading || !active) && (
        <Box fontSize={active ? 5 : 4} fontWeight="bold" p={4}>
          {title}
        </Box>
      )}
      {active && loading && (
        <Box
          sx={{
            borderTopColor: 'accent',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            transition: 'all 0.25s ease-in-out',
          }}
        >
          <Spinner />
        </Box>
      )}
    </Box>
  );
};

FormHeader.propTypes = {
  active: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default FormHeader;
