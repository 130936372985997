import React from 'react';
import dateUtils from '@utils/date-utils';
import Box from '@basecomponents/Box';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Spinner from '@basecomponents/Spinner';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { labelHandler } from '@utils/label-utils';
import SingleDatePicker from '@basecomponents/SingleDatePicker';
import Icon from '@src/components/Icon';
import {
  minDateVal,
  positive,
  totalEmployerContribution,
  composeValidations,
  required,
} from '@utils/validations';
import GetData from '@utils/get-data';
import InputMasked from '@basecomponents/InputMasked';
import { variation } from '@src/config.json';

const commonFieldSxHalf = {
  padding: 3,
  width: '25rem',
};

const Disclaimer = ({ text }) => {
  return (
    <Box
      sx={{
        color: 'black',
        display: 'block',
        fontSize: 1,
        fontWeight: 'bold',
        letterSpacing: 0,
        maxWidth: '25rem',
        mb: 6,
        textAlign: 'center',
        textTransform: 'uppercase',
      }}
    >
      {text}
    </Box>
  );
};

Disclaimer.propTypes = {
  text: PropTypes.string.isRequired,
};

/**
 * @category ProductAdmin
 * @param {string} id
 * @param {boolean} isEditing
 * @param {any} values
 * @param {string} title
 * @param {string} icon
 * @param {string} groupId
 * @returns {React.FC}
 */
const ClassTimeline = ({
  id,
  isEditing,
  noOfEmployees,
  values,
  title,
  icon,
  groupId,
  isRenewal,
}) => {
  const { t } = useTranslation()
  const { apiData = {}, loading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !isEditing
  );

  const relativeDatetime = dateUtils.setOffset2(
    get(apiData, 'relativeDatetime', null)
  );
  const maxDate = new Date(values?.coverageFromDate?.getTime());
  maxDate.setFullYear(maxDate?.getFullYear() + 1)
  if (loading) {
    return <Spinner />;
  }
  return (
    <Box
      sx={{
        borderRadius: 3,
        boxShadow: 7,
        mb: 6,
        mt: 2,
        mx: 2,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          my: 4,
        }}
      >
        {variation === 'ihc' ? (
          <Icon
            svg={icon}
            sx={{
              height: '40px',
              width: '40px',
            }}
          />
        ) : null}
        <Box
          sx={{
            fontSize: 3,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Box>
      </Box>
      <Box>
        {(!isEditing || isRenewal) && (
          <>
            <Field
              aria-label="Master Policy Date of Issue"
              component={SingleDatePicker}
              disabled
              label={labelHandler('Master Policy Date of Issue', true)}
              maxDate={values.coverageToDate}
              name="coverageFromDate"
              validate={required}
              wrapperSx={{ ...commonFieldSxHalf }}
            />

            <Field
              aria-label="Master Policy Renewal Date"
              component={SingleDatePicker}
              disabled={isEditing || (noOfEmployees > 0)}
              label={labelHandler('Master Policy Renewal Date', true)}
              maxDate={maxDate}
              minDate={values.coverageFromDate}
              name="coverageToDate"
              validate={required}
              wrapperSx={{ ...commonFieldSxHalf }}
            />
          </>
        )}
        <Field
          aria-label={t('metadata.plan.enrollmentFromDate')}
          component={SingleDatePicker}
          disabled={!isEditing && (noOfEmployees > 0)}
          label={labelHandler(t('metadata.plan.enrollmentFromDate'), true)}
          maxDate={
            isRenewal ? values.coverageFromDate : values.enrollmentToDate
          }
          name="enrollmentFromDate"
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
        />
        <Field
          aria-label={t('metadata.plan.enrollmentToDate')}
          component={SingleDatePicker}
          disabled={!isEditing && (noOfEmployees > 0)}
          label={labelHandler(t('metadata.plan.enrollmentToDate'), true)}
          minDate={values.enrollmentFromDate}
          name="enrollmentToDate"
          validate={
            // eslint-disable-next-line no-nested-ternary
            isRenewal
              ? required
              : isEditing
                ? composeValidations(required, minDateVal(relativeDatetime))
                : required
          }
          wrapperSx={{ ...commonFieldSxHalf }}
        />
        {(!isEditing || isRenewal) && (
          <Field
            aria-label="Employer Contribution in %"
            component={InputMasked}
            disabled={isEditing || (noOfEmployees > 0)}
            label={labelHandler('Employer Contribution in %', true)}
            mask={createNumberMask({
              allowDecimal: true,
              includeThousandsSeparator: false,
              integerLimit: 3,
              prefix: '',
            })}
            name={`pet-${id}.employerContribution`}
            validate={composeValidations(
              required,
              positive,
              totalEmployerContribution
            )}
            wrapperSx={{ ...commonFieldSxHalf }}
          />
        )}
      </Box>
    </Box>
  );
};

ClassTimeline.propTypes = {
  groupId: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isRenewal: PropTypes.bool,
  noOfEmployees: PropTypes.number,
  title: PropTypes.string.isRequired,
  values: PropTypes.shape({
    coverageFromDate: PropTypes.instanceOf(Date),
    coverageToDate: PropTypes.instanceOf(Date),
    enrollmentFromDate: PropTypes.instanceOf(Date),
    enrollmentToDate: PropTypes.instanceOf(Date),
  }).isRequired,
};

ClassTimeline.defaultProps = {
  isRenewal: false,
  noOfEmployees: 0,
};

export default ClassTimeline;
