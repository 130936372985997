import { Router } from '@reach/router';
import React from 'react';
import UserDashboardPage from '@petcomponents/Users/DashboardPage';
import UserDetailPage from '@petcomponents/Users/DetailPage';
import UserUpsertPage from '@petcomponents/Users/UpsertPage';
import NotFoundPage from './404';

const Users = (props) => (
  <Router>
    <UserDashboardPage {...props} path="/users/dashboard" />
    <UserUpsertPage {...props} path="/users/create" />
    <UserDetailPage {...props} path="/users/:userId/details" />
    <NotFoundPage default path="/404" />
  </Router>
);

export default Users;
