import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { composeValidations, required } from '../../../utilities/validators';
import InputText from '../../BaseComponents/Input';
import InputDropdown from '../../BaseComponents/Dropdown';
import { CA_PROVINCES, US_STATES } from '../../../utilities/constants';
import { labelHandler } from '../../../utilities/label-utils';
import { postalCode, zipcode } from '../../../utilities/validations';
import config from '../../../config.json';

/**
 * @category Group
 * @param {boolean} isStateEditing Indicating whether the form should be editable or not
 * @param {string} title  Title of the form
 * @param {string} label Label for the form fields
 * @param {string} situs Situs address for the address fields
 * @param {object} wrapperSx Styling on per field basis
 * @param {boolean} disabled Indicating whether the field should be disabled or not
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const AddressFormSection = ({
  isStateEditing,
  title,
  label,
  labelSx,
  situs,
  wrapperSx,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <>
      {title && <Box>{title}</Box>}
      <Field
        aria-label={t('groupEmployees.enrollmentPage.addressLine', { number: 1 })}
        component={InputText}
        disabled={disabled}
        label={labelHandler(t('groupEmployees.enrollmentPage.addressLine', { number: 1 }), true)}
        labelSx={labelSx}
        name={`${label}.addressLine1`}
        validate={required}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Field
        aria-label={t('groupEmployees.enrollmentPage.addressLine', { number: 2 })}
        component={InputText}
        disabled={disabled}
        label={labelHandler(t('groupEmployees.enrollmentPage.addressLine', { number: 2 }), false)}
        labelSx={labelSx}
        name={`${label}.addressLine2`}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Field
        aria-label={t('groupEmployees.enrollmentPage.city')}
        component={InputText}
        disabled={disabled}
        label={labelHandler(t('groupEmployees.enrollmentPage.city'), true)}
        labelSx={labelSx}
        name={`${label}.city`}
        validate={required}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Field
        aria-label={config.canadaEnv ? "provinces" : "state"}
        component={InputDropdown}
        disabled={disabled || (situs && isStateEditing)}
        label={labelHandler(config.canadaEnv ? t('groupEmployees.enrollmentPage.provinces') : t('groupEmployees.enrollmentPage.state'), true)}
        labelSx={labelSx}
        name={`${label}.state`}
        options={config.canadaEnv ? CA_PROVINCES : US_STATES}
        validate={required}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Field
        aria-label={config.canadaEnv ? t('groupEmployees.enrollmentPage.postalCode') : t('groupEmployees.enrollmentPage.zipCode')}
        component={InputText}
        disabled={disabled}
        label={labelHandler(config.canadaEnv ? t('groupEmployees.enrollmentPage.postalCode') : t('groupEmployees.enrollmentPage.zipCode'), true)}
        labelSx={labelSx}
        maxLength={config.canadaEnv ? 7 : 5}
        name={`${label}.zipcode`}
        validate={config.canadaEnv ? composeValidations(required, postalCode) : composeValidations(required, zipcode)}
        wrapperSx={wrapperSx}
        {...rest}
      />
    </>
  );
};

export default AddressFormSection;

AddressFormSection.propTypes = {
  disabled: PropTypes.bool,
  isStateEditing: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelSx: PropTypes.shape({}),
  situs: PropTypes.bool,
  title: PropTypes.string,
  wrapperSx: PropTypes.shape({}),
};

AddressFormSection.defaultProps = {
  disabled: false,
  isStateEditing: false,
  labelSx: {},
  situs: false,
  title: '',
  wrapperSx: {},
};
