import { useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import React from 'react';
import arrayMutators from 'final-form-arrays';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { composeValidations, required } from '@utils/validators';
import {CA_PROVINCES, notificationTypes, settingLevel, US_STATES} from '@utils/constants';
import { getNotificationDays } from '@utils/get-notifcation-days';
import remoteActionQuery from '../../../../../graphql/queries/remote-action.gql';
import GetData from '../../../../../utilities/get-data';
import useSnackbar from '../../../../../utilities/use-snackbar';
import InputMasked from '../../../../BaseComponents/InputMasked';
import InputCheckbox from '../../../../BaseComponents/InputCheckbox';
import Spinner from '../../../../BaseComponents/Spinner';
import ToolbarButton from '../../../../BaseComponents/ToolbarButton';
import Route from '../../../Route';
import config from '../../../../../config.json';

const isGreaterThanState =
  ({ renewalNotificationDays }, name) =>
  (value) => {
    if (parseInt(value, 10) < parseInt(renewalNotificationDays, 10)) {
      return `Must be greater than ${renewalNotificationDays} days, which is the state level notification setting for the state of ${name}`;
    }
  };

/**
 * Group Notifications page
 *
 * @category Group
 * @param {*} rest
 * @returns {React.FC}
 */
const Notifications = ({ ...rest }) => {
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  const queryParams = useParams();
  const groupId = get(queryParams, 'groupId', '');

  let groupState;

  const { apiData: constantsData = {}, loading: enumLoading } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: ['SettingType', 'SettingLevel'] }])
  );

  const { apiData: globalSettings = {}, loading: globalSettingsLoading } =
    GetData(
      'get-all-system-settings',
      JSON.stringify({ settingLevel: settingLevel.global })
    );

  const { apiData: stateSettings = {}, loading: stateSettingsLoading } =
    GetData(
      'get-all-system-settings',
      JSON.stringify({
        settingLevel: settingLevel.state,
        size: config.canadaEnv ? CA_PROVINCES.length :US_STATES.length,
      })
    );

  const { apiData: groupSettings = {}, loading: groupSettingsLoading } =
    GetData(
      'get-all-system-settings',
      JSON.stringify({
        settingLevel: settingLevel.group,
        settingType: notificationTypes.adminNotifications,
      })
    );

  const { apiData: enrollSettings, loading: enrollmentLoading } = GetData(
    'get-all-system-settings',
    JSON.stringify({
      identifier: groupId,
      settingLevel: settingLevel.group,
      settingType: notificationTypes.enrollmentStartNotification,
    })
  );

  const { apiData: groupData = {} } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );

  if (get(groupData, 'locationDetails.isSitus')) {
    groupState = get(groupData, 'locationDetails.address.state');
  }

  const getSettingLevel = (settingLevel) => {
    const { value } = constantsData?.SettingLevel?.find(
      ({ value }) => value === settingLevel
    );
    return value;
  };

  const getSettingType = (settingType) => {
    const { value } = constantsData?.SettingType?.find(
      ({ value }) => value === settingType
    );
    return value;
  };
    const stateList = config.canadaEnv ? CA_PROVINCES : US_STATES;
  const stateData = stateList.find((item) => item.value === groupState);

  const getDays = getNotificationDays({
    apiData: { globalSettings, groupSettings, stateSettings },
    meta: { groupId, groupState },
  })?.renewalNotificationDays;

  const initialValues = {
    enrolmentEmailNotificationDays:
      enrollSettings?.content?.[0]?.settingValue === '-1'
        ? '0 Days'
        : enrollSettings?.content?.[0]?.settingValue,
    'enrolmentEmailNotificationDays-checkbox':
      enrollSettings?.content?.[0]?.settingValue === '-1',
    renewalNotificationDays: getDays === '-1' ? 0 : getDays,
  };

  return (
    <Route
      header={{
        title: 'Group Notifications',
        type: 'billing',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Notifications
      </Box>
      {enumLoading ||
      globalSettingsLoading ||
      stateSettingsLoading ||
      groupSettingsLoading ||
      enrollmentLoading ? (
        <Spinner />
      ) : (
        <ApolloConsumer>
          {(client) => {
            const onAddNotification = async (values) => {
              const enrolmentEmailNotificationDaysValue =
                values?.enrolmentEmailNotificationDays
                  ?.match?.(/\d/g)
                  ?.join('');

              const enrollMentData = {
                identifier: groupId,
                settingLevel: getSettingLevel(settingLevel.group),
                settingType: getSettingType(
                  notificationTypes.enrollmentStartNotification
                ),
                settingValue: values?.[
                  'enrolmentEmailNotificationDays-checkbox'
                ]
                  ? -1
                  : enrolmentEmailNotificationDaysValue,
              };

              await client
                .query({
                  fetchPolicy: 'no-cache',
                  query: remoteActionQuery,
                  variables: {
                    name: 'create-system-settings',
                    params: JSON.stringify({ ...enrollMentData }),
                  },
                })
                .then(() => {
                  setSuccessSnack(
                    'Enrollment notification days updated successfully',
                    config.notificationDuration
                  );
                })
                .catch((e) => {
                  setErrorSnack(
                    `There was an error: ${e.message}`,
                    config.notificationDuration
                  );
                });
              // });
            };

            const onRenewalUpdate = async (values) => {
              const renewalNotificationDaysValue =
                values?.renewalNotificationDays?.match?.(/\d/g)?.join('');
              const newlyAddedData = {
                identifier: groupId,
                settingLevel: getSettingLevel(settingLevel.group),
                settingType: getSettingType(
                  notificationTypes.adminNotifications
                ),
                settingValue: renewalNotificationDaysValue,
              };

              const isRedundantData =
                renewalNotificationDaysValue ===
                getNotificationDays({
                  apiData: { stateSettings },
                  meta: { groupState },
                })?.renewalNotificationDays;

              const data = groupSettings?.content?.find(
                (item) => item.identifier === groupId
              );

              if (getDays !== '-1') {
                if (isRedundantData && !data?.systemSettingsId) {
                  setSuccessSnack(
                    'Renewal notification days updated successfully',
                    config.notificationDuration
                  );
                } else {
                  await client
                    .query({
                      fetchPolicy: 'no-cache',
                      query: remoteActionQuery,
                      variables: {
                        name:
                          isRedundantData && data?.systemSettingsId
                            ? 'delete-system-settings'
                            : 'create-system-settings',
                        params:
                          isRedundantData && data?.systemSettingsId
                            ? JSON.stringify({
                                systemSettingId: data?.systemSettingsId,
                              })
                            : JSON.stringify({ ...newlyAddedData }),
                      },
                    })
                    .then(() => {
                      setSuccessSnack(
                        'Renewal notification days updated successfully',
                        config.notificationDuration
                      );
                    })
                    .catch((e) => {
                      setErrorSnack(
                        `There was an error : ${e.message}`,
                        config.notificationDuration
                      );
                    });
                }
              }
            };

            return (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    bg: 'white',
                    borderRadius: 5,
                    boxShadow: 1,
                    maxWidth: '30rem',
                    padding: '2rem',
                  }}
                >
                  <FinalForm
                    initialValues={{
                      renewalNotificationDays:
                        initialValues.renewalNotificationDays,
                    }}
                    mutators={{
                      ...arrayMutators,
                      setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                      },
                    }}
                    onSubmit={async (values) => {
                      await onRenewalUpdate(values);
                    }}
                    render={({ handleSubmit, submitting }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Box as="h4">
                              Set the number of days prior to the policy expiry
                              the Group Administrator will be notified about
                              policy renewal.
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Field
                                aria-label="Input"
                                component={InputMasked}
                                mask={createNumberMask({
                                  allowDecimal: false,
                                  integerLimit: 3,
                                  prefix: '',
                                  suffix: ' Days',
                                })}
                                name="renewalNotificationDays"
                                sx={{ height: '35px' }}
                                wrapperSx={{ ...commonFieldSxHalf }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <ToolbarButton
                              bg="accentDark"
                              isDisabled={submitting}
                              label="Save"
                              mr={0}
                              my={5}
                              submitting={submitting}
                              type="submit"
                              width="150px"
                            />
                          </Box>
                        </form>
                      );
                    }}
                    validate={(values) => {
                      const errors = {};
                      errors.renewalNotificationDays = composeValidations(
                        required,
                        isGreaterThanState(
                          getNotificationDays({
                            apiData: {
                              globalSettings,
                              groupSettings,
                              stateSettings,
                            },
                            meta: { groupState },
                          }),
                          stateData?.label
                        )
                      )(values?.renewalNotificationDays);
                      return errors;
                    }}
                  />
                  <FinalForm
                    initialValues={{
                      enrolmentEmailNotificationDays:
                        initialValues.enrolmentEmailNotificationDays,
                      'enrolmentEmailNotificationDays-checkbox':
                        initialValues[
                          'enrolmentEmailNotificationDays-checkbox'
                        ],
                    }}
                    mutators={{
                      ...arrayMutators,
                      setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                      },
                    }}
                    onSubmit={async (values) => {
                      await onAddNotification(values);
                    }}
                    render={({
                      handleSubmit,
                      submitting,
                      values: formValues,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Box as="h4" sx={{ mt: 6 }}>
                              Set the number of days prior to the enrolment
                              starting date the employees will be notified about
                              the upcoming enrolment via email.
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Field
                                aria-label="Input"
                                component={InputMasked}
                                disabled={
                                  formValues?.[
                                    'enrolmentEmailNotificationDays-checkbox'
                                  ]
                                }
                                mask={createNumberMask({
                                  allowDecimal: false,
                                  integerLimit: 3,
                                  prefix: '',
                                  suffix: ' Days',
                                })}
                                name="enrolmentEmailNotificationDays"
                                sx={{ height: '35px' }}
                                wrapperSx={{ ...commonFieldSxHalf }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Box>
                              <Field
                                aria-label="Do not send email"
                                component={InputCheckbox}
                                label="Do not send email."
                                name="enrolmentEmailNotificationDays-checkbox"
                                type="checkbox"
                                wrapperSx={{
                                  display: 'flex',
                                  mt: 0,
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <ToolbarButton
                              bg="accentDark"
                              isDisabled={submitting}
                              label="Save"
                              mr={0}
                              my={5}
                              submitting={submitting}
                              type="submit"
                              width="150px"
                            />
                          </Box>
                        </form>
                      );
                    }}
                    validate={(values) => {
                      const errors = {};
                      const dontSendEmailChecked =
                        values?.['enrolmentEmailNotificationDays-checkbox'];
                      if (dontSendEmailChecked) {
                        return errors;
                      }
                      errors.enrolmentEmailNotificationDays = required(
                        values?.enrolmentEmailNotificationDays
                      );
                      return errors;
                    }}
                  />
                </Box>
              </Box>
            );
          }}
        </ApolloConsumer>
      )}
    </Route>
  );
};

export default Notifications;
