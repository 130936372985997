import {Field} from 'react-final-form';
import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import {FieldArray} from 'react-final-form-arrays';
import {ThemeContext} from 'styled-components';
import GetData from '@utils/get-data';
import {required} from '@utils/validators';
import {labelHandler} from '@utils/label-utils';
import {useTranslation} from 'react-i18next';
import {get, isEmpty} from 'lodash';
import useSsm from '@src/utilities/hooks/useSsm';
import InputText from '../../../../BaseComponents/Input';
import InputDropdown from '../../../../BaseComponents/Dropdown';
import MedicalVisit from '../MedicalVisit';
import ToolbarButton from '../../../../BaseComponents/ToolbarButton';
import config from '../../../../../config.json';

/**
 * @category Claims
 * @param {Function} change Trigger form change based on onChange event listener
 * @param {number} index Indicates the index for the From Array Fields
 * @param {string} label Indicates the label for form fields
 * @param {Array<Object>} petInfo Indicates the options for the Input Dropdown
 * @param {Array<Object>} dependentClaims
 * @param {object} wrapperSx Indicates Styling for the each field
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const MedicalHistory = ({
  change,
  index,
  label,
  petInfo,
  dependentClaims,
  selectedPlanChoiceId,
  selectedPlanId,
  wrapperSx,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  const [planName, setPlanName] = useState(null);
  const claimPriorCoverageCredit = useSsm('iaic-claim-prior-coverage-credit' )

  const { apiData: planPricing = [], loading: planPricingLoading } = GetData(
    'get-plan-pricing',
    JSON.stringify({ planId: selectedPlanId }),
    selectedPlanId === null
  );

  useEffect(() => {
    if (Array.isArray(planPricing)) {
      const plan =
        planPricing.find((v) => v.planChoiceId === selectedPlanChoiceId) || {};
      setPlanName(plan?.productDetails?.name);
    }
  }, [planPricingLoading]);

  const { t } = useTranslation();

  const ADDITIONAL_INSURANCE = [
    {
      canEnglishValue: null,
      frenchValue: 'Oui',
      label: t('common.yes'),
      value: true,
    },
    {
      canEnglishValue: null,
      frenchValue: 'Non',
      label: t('common.no'),
      value: false,
    },
  ];
  return (
    <>
      <Field
        aria-label={t('claimsScreen.upsertClaimPage.selectPet')}
        component={InputDropdown}
        label={labelHandler(t('claimsScreen.upsertClaimPage.selectPet'), true)}
        name={`${label}.employeeDependentId`}
        onChange={(values) => {
          const dependentObj = petInfo.find(
            (v) => v.employeeDependentId === values
          );
          change(
            `${label}.employeeDependentName`,
            dependentObj?.dependentData.petName
          );
          change(
            `${label}.employeeDependentId`,
            dependentObj?.employeeDependentId
          );
          change(
            `${label}.priorCoverageCredit`,
            dependentObj?.priorCoverageCredit
          );
          if (dependentObj?.priorCoverageCredit !== null) {
            change(`${label}.displayPriorCoverageCredit`, false);
          } else {
            change(`${label}.displayPriorCoverageCredit`, true);
          }
          change(`${label}.changePriorCoverageCredit`, null);
        }}
        options={
          petInfo &&
          petInfo
            .filter((item) => !item.deleted)
            .filter(
              (item) =>
                item?.coverageStartDate !== null &&
                item?.coverageEndDate !== null
            )
            .filter((item) =>
              ['ACTIVE', 'INACTIVE', 'TERMINATED'].includes(item.status)
            )
            .map((item) => {
              return {
                canEnglishValue: item.dependentData.petName,
                frenchValue: item.dependentData.petName,
                isDisabled: dependentClaims?.some(
                  (e) => e.employeeDependentId === item.employeeDependentId
                ),
                label: item.dependentData.petName,
                value: item.employeeDependentId,
              };
            })
        }
        searchable={false}
        validate={required}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Field
        aria-label={t('claimsScreen.upsertClaimPage.additionalCoverage')}
        component={InputDropdown}
        label={labelHandler(
          t('claimsScreen.upsertClaimPage.additionalCoverage'),
          true
        )}
        name={`${label}.additionalInsurance`}
        options={ADDITIONAL_INSURANCE}
        searchable={false}
        validate={required}
        wrapperSx={wrapperSx}
        {...rest}
      />
      {dependentClaims[index]?.additionalInsurance === true && (
        <>
          <Box
            sx={{
              color: 'error',
              fontSize: 2,
              margin: '10px 0px 0px 5px;',
              width: '25rem',
              wordWrap: 'break-word',
            }}
          >
            {' '}
            {t('claimsScreen.upsertClaimPage.reachOut')}{' '}
          </Box>
          <Field
            aria-label={t('claimsScreen.upsertClaimPage.insuranceCompanyName')}
            component={InputText}
            label={labelHandler(
              t('claimsScreen.upsertClaimPage.insuranceCompanyName'),
              true
            )}
            name={`${label}.additionalInsuranceCompany`}
            validate={required}
            wrapperSx={{
              ...wrapperSx,
            }}
            {...rest}
          />
        </>
      )}
      {claimPriorCoverageCredit &&
        dependentClaims[index]?.displayPriorCoverageCredit &&
        planName !== 'Accident Only' &&
        planName !== null && (
          <>
            <Field
              aria-label={t('claimsScreen.upsertClaimPage.coverageCredit')}
              component={InputDropdown}
              label={labelHandler(
                t('claimsScreen.upsertClaimPage.coverageCredit'),
                true
              )}
              name={`${label}.priorCoverageCredit`}
              onChange={() => {
                change(`${label}.changePriorCoverageCredit`, true);
              }}
              options={ADDITIONAL_INSURANCE}
              searchable={false}
              validate={required}
              wrapperSx={wrapperSx}
              {...rest}
            />
          </>
        )}
      <FieldArray
        name={`${label}.dependentVisits`}
        visitIndex={index}
        {...rest}
      >
        {({ fields }) => (
          <Box
            sx={{
              alignItems: 'center',
              display: 'inline-block',
              justifyContent: 'space-around',
              maxWidth: '98vw',
              mt: '0.25 rem !important',
            }}
          >
            {fields.map((name, index2) => (
              <Box
                key={index2}
                sx={{
                  border: `1px solid ${theme.colors.accentSecondary}`,
                  borderRadius: 4,
                  margin: '25px 0',
                  mx: 1,
                  pb: 3,
                }}
              >
                <Box
                  p={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  {dependentClaims[index].dependentVisits.length > 1 && (
                    <ToolbarButton
                      bg="error"
                      icon="close"
                      label={t('claimsScreen.upsertClaimPage.deleteVisit')}
                      onClick={() => fields.remove(index2)}
                    />
                  )}
                </Box>
                <MedicalVisit
                  label={`${name}`}
                  wrapperSx={wrapperSx}
                  {...rest}
                />
              </Box>
            ))}
            <Box display="flex" justifyContent="center" my={4}>
              <ToolbarButton
                bg="accentSecondary"
                icon="add"
                label={t('claimsScreen.upsertClaimPage.addVisit')}
                onClick={() => fields.push({})}
              />
            </Box>
          </Box>
        )}
      </FieldArray>
    </>
  );
};
export default MedicalHistory;
MedicalHistory.defaultProps = {
  change: null,
  selectedPlanChoiceId: null,
  selectedPlanId: null,
  wrapperSx: {},
};
MedicalHistory.propTypes = {
  change: PropTypes.func,
  dependentClaims: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape([])]))
    .isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  petInfo: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape([])]))
    .isRequired,
  selectedPlanChoiceId: PropTypes.string,
  selectedPlanId: PropTypes.string,
  wrapperSx: PropTypes.shape({}),
};
