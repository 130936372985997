import PropTypes from 'prop-types';
import React from 'react';
import { navigate } from '@reach/router';
import { get } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
// import { OnChange } from 'react-final-form-listeners';
import { required } from '../../../utilities/validators';
import Route from '../Route';
import InputDropdown from '../../BaseComponents/Dropdown';
import remoteActionQuery from '../../../graphql/queries/remote-action.gql';
import { labelHandler } from '../../../utilities/label-utils';
import ToolbarButton from '../../BaseComponents/ToolbarButton';
import useSnackbar from '../../../utilities/use-snackbar';
// import getData from '../../../utilities/get-data';
import InputMonth from '../../BaseComponents/InputMonth';
import config, { variation } from '../../../config.json';
import downloadData from '../../../utilities/download-file';
import dateUtils from '../../../utilities/date-utils';

/**
 * @category Billing
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const CommissionReport = ({ ...rest }) => {
  // const [dates, setDates] = useState([]);

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });

  return (
    <Route
      header={{
        title: 'Download Commission Report',
        type: 'billing',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Commission Report
      </Box>
      <ApolloConsumer>
        {(client) => {
          const downloadReport = async (values) => {
            const newDate = new Date(values?.selectedDate).setMonth(
              new Date(values?.selectedDate).getMonth() + 1
            );
            const fixedDate = `${dateUtils.setAPIDateOnly(newDate)} 00:00:00`;
            await client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'download-commission-report-job',
                  params: JSON.stringify({
                    groupMga: values?.groupMGA,
                    selectedDate: fixedDate,
                  }),
                },
              })
              .then((response) => {
                const results = JSON.parse(
                  get(response, 'data.remoteAction.data', '{}')
                );
                downloadData({
                  s3BucketName: config.amplify.Storage.AWSS3.bucket,
                  s3KeyName: get(results, 's3Key', ''),
                })
                  .then(() => {
                    setSuccessSnack(
                      'Report successfully downloaded',
                      config.notificationDuration
                    );
                  })
                  .catch(() => {
                    setErrorSnack(
                      `No report found`,
                      config.notificationDuration
                    );
                  });
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error downloading the report: ${e.message}`,
                  config.notificationDuration
                )
              );
          };

          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                onSubmit={downloadReport}
                render={({ handleSubmit, submitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          padding: 3,
                        }}
                      >
                        <Box flex="1" justifyContent="center">
                          <Field
                            aria-label="MGA"
                            component={InputDropdown}
                            label={labelHandler('MGA', false)}
                            name="groupMGA"
                            options={
                              variation === 'demo'
                                ? [
                                    { canEnglishValue: null, frenchValue: null, label: 'PetCo', value: 'FIGO' },
                                    { canEnglishValue: null, frenchValue: null, label: 'ASPCA', value: 'PPI' },
                                  ]
                                : [
                                    { canEnglishValue: null, frenchValue: null, label: 'FIGO', value: 'FIGO' },
                                    { canEnglishValue: null, frenchValue: null, label: 'PPI', value: 'PPI' },
                                  ]
                            }
                            validate={required}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                          {/* <OnChange name="producerId">
                            {(value) => getCommissionOptions(value)}
                          </OnChange> */}

                          {/* <Field */}
                          {/*  aria-label="Report Date" */}
                          {/*  component={InputMonth} */}
                          {/*  dateFormat="MM-yyyy" */}
                          {/*  label={labelHandler('Report Date', true)} */}
                          {/*  minDate={null} */}
                          {/*  name="reflectionDate" */}
                          {/*  validate={required} */}
                          {/*  view="month" */}
                          {/*  wrapperSx={{ ...commonFieldSxHalf }} */}
                          {/*  {...rest} */}
                          {/* /> */}
                          <Field
                            aria-label="Report Date"
                            component={InputMonth}
                            dateFormat="MM/dd/yyyy"
                            label={labelHandler('Report Date', true)}
                            minDate={null}
                            name="selectedDate"
                            validate={required}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                          <ToolbarButton
                            bg="accentLight"
                            isDisabled={submitting}
                            label="Download"
                            m="20px auto 0"
                            px="0"
                            submitting={submitting}
                            sx={{ width: '150px' }}
                            type="submit"
                          />

                          <ToolbarButton
                            bg="accentDark"
                            label="Done"
                            m="20px auto"
                            onClick={() => navigate(`/billing/dashboard`)}
                            sx={{ width: '150px' }}
                          />
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

CommissionReport.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default CommissionReport;
