import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Dropdown from '../../../Dropdown';
import GetData from '../../../../../utilities/get-data';

/**
 * @category BaseComponents
 * @param {string} label
 * @param {string} name
 * @param {Object} labelSx
 * @param {string} placeholder
 * @param {string} resolver
 * @param {Object} resolverParams
 * @returns {React.FC}
 */
const DropdownQuery = ({
  label,
  name,
  labelSx,
  placeholder,
  resolver,
  resolverParams,
}) => {
  const { apiData, loading } = GetData(
    resolver,
    JSON.stringify(resolverParams)
  );

  return (
    <Field
      aria-label={label}
      component={Dropdown}
      isClearable
      label={label}
      labelSx={labelSx}
      loading={loading}
      name={name}
      options={loading ? [] : apiData}
      placeholder={placeholder}
    />
  );
};

DropdownQuery.defaultProps = {
  label: '',
  labelSx: {},
  placeholder: 'Select',
  resolverParams: {},
};
DropdownQuery.propTypes = {
  label: PropTypes.string,
  labelSx: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  resolver: PropTypes.string.isRequired,
  resolverParams: PropTypes.shape({}),
};

export default DropdownQuery;
