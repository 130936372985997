import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';
import { useLocation } from '@reach/router';
import Icon from '../../Icon';
import Spinner from '../Spinner';
import DropdownMenu from '../DropdownMenu';

const iconSize = 24;

/**
 * @category BaseComponents
 * @param {number} defaultTab
 * @param {boolean} loading
 * @param {Array<Object>} tabs
 * @returns {React.FC}
 */
const Tabs = ({ defaultTab, loading, tabs }) => {
  const [currentTab, setCurrentTab] = useState(defaultTab || 0);
  const currentTabDetails = get(tabs, currentTab, 0);
  const { defaultStyle = {} } = useContext(ThemeContext);
  const { renderTab = {}, tabContainer = {} } = defaultStyle.defaultTab();

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.payrollScheduleId) {
      setCurrentTab(5);
    }
  }, [location.state]);

  return (
    <Box sx={tabContainer}>
      {tabs.length > 0 ? (
        <>
          <Box sx={{ display: ['block', null, null, null, 'none'] }}>
            <DropdownMenu
              button={
                <Box alignItems="center" color="accent" display="flex" mx={2}>
                  <Box fontSize={4} fontWeight="bold">
                    {get(tabs, `${currentTab}.label`)}
                  </Box>
                  <Box height="1.5rem" mx={4} width="1.5rem">
                    <Icon height="1.5rem" svg="expand-more" width="1.5rem" />
                  </Box>
                </Box>
              }
              itemActions={tabs.map((_, index) => () => setCurrentTab(index))}
              items={tabs.map(({ label }) => label)}
              menuZIndex={3}
            />
          </Box>
          <Box
            alignItems="center"
            sx={{
              borderBottomStyle: 'solid',
              borderColor: 'accent',
              borderWidth: 1,
              display: ['none', null, null, null, 'flex'],
            }}
          >
            {tabs.map(({ icon, label }, ix) => {
              const current = ix === currentTab;
              const { tabHeaderLabelContainer = {} } =
                defaultStyle.defaultTab(current);
              return (
                <Box
                  key={`tab-${ix}`}
                  onClick={() => setCurrentTab(ix)}
                  sx={tabHeaderLabelContainer}
                >
                  {icon && (
                    <Box>
                      <Icon height={iconSize} svg={icon} width={iconSize} />
                    </Box>
                  )}
                  <Box mx={2}>{label}</Box>
                </Box>
              );
            })}
          </Box>
          {loading ? (
            <Box
              bg="white"
              color="accentLight"
              fontSize="6"
              fontWeight="bold"
              sx={renderTab}
              textAlign="center"
            >
              <Spinner height="auto" p={6} />
            </Box>
          ) : (
            <Box p={5}>
              {currentTabDetails.render
                ? currentTabDetails.render()
                : 'No render defined'}
            </Box>
          )}
        </>
      ) : (
        <Box>No tabs defined</Box>
      )}
    </Box>
  );
};

Tabs.propTypes = {
  defaultTab: PropTypes.number,
  loading: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      render: PropTypes.func,
    })
  ),
};

Tabs.defaultProps = {
  defaultTab: null,
  loading: false,
  tabs: [],
};
export default Tabs;
