import React from 'react';
import {Field} from 'react-final-form';
import PropTypes from 'prop-types';
import {groupNumberMask} from '@utils/masks';
import {composeValidations, groupNumber} from '@utils/validations';
import {labelHandler} from '@utils/label-utils';
import config from '@src/config.json';
import InputMasked from '@basecomponents/InputMasked';
import InputText from "@basecomponents/Input";
import i18n from '@src/utilities/i18n';

const mgaLabel = labelHandler(i18n.t('groupEmployees.detailsPage.groupMGA'));
const groupNameLabel = labelHandler('Group Name');
const groupNumberLabel = labelHandler('Group Number');
const groupNumberValidate = composeValidations(groupNumber);


/**
 * @category Claims
 * @param {string} acceptableMethods Indicating acceptable methods for payment
 * @param {object} wrapperSx Styling for the section
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const InitialSetup = ({wrapperSx, readOnly}) => {

    return (
        <>
            <Field
                aria-label="Group Name"
                component={InputText}
                disabled={readOnly}
                label={groupNameLabel}
                name="groupName"
                wrapperSx={wrapperSx}
            />
            <Field
                aria-label="Group Number"
                component={InputMasked}
                disabled
                label={groupNumberLabel}
                mask={groupNumberMask}
                name="groupNumber"
                validate={groupNumberValidate}
                wrapperSx={wrapperSx}
            />
            <Field
                aria-label={mgaLabel}
                component={InputText}
                defaultValue={config.canadaEnv ? "PTZ_SODACO" : "PPI"}
                disabled
                label={mgaLabel}
                name="mga"
                wrapperSx={wrapperSx}
            />
        </>
    );
};
InitialSetup.defaultProps = {
    readOnly: false,
    wrapperSx: {},
};
InitialSetup.propTypes = {
    readOnly: PropTypes.bool,
    wrapperSx: PropTypes.shape({})
};
export default InitialSetup;

