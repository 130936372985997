import React, { useContext, useEffect, useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { get } from 'lodash';
import Box from '@basecomponents/Box';
import { Form } from 'react-final-form';
import Checkbox from '@basecomponents/InputCheckbox';
import ToolbarButton from '@basecomponents/ToolbarButton';
import { labelHandler } from '@src/utilities/label-utils';
import { navigate } from 'gatsby';
import { AuthContext } from '@basecomponents/Auth';
import useSnackbar from '@src/utilities/use-snackbar';
import Route from '@petcomponents/Route';
import { E_CONSENT_URLS, USER_ROLE } from '@utils/constants';
import config, { variation } from '@src/config.json';
import GetData from '@src/utilities/get-data';
import Spinner from '@basecomponents/Spinner';
import remoteActionQuery from '@queries/remote-action.gql';
import PropTypes from 'prop-types';
import localStorage from '@src/utilities/local-storage';

import { useTranslation } from 'react-i18next';
import i18n from '@src/utilities/i18n';

const commonFieldSxHalf = {
  padding: 3,
  width: '25rem',
};

/**
 * @category Post Enrollment
 * @param {*} rest
 * @returns {React.FC}
 */
function PDBAConsent({ location, ...rest }) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [loading, setLoading] = useState(false);
  const userId = get(user, 'customSystemUserId', '');
  const { employeeId } = location?.state;
  const { apiData: summary = {}, loading: summaryLoading } = GetData(
    'get-enrollment-summary',
    JSON.stringify({ employeeId })
  );

  const { groupResponse = {} } = summary;
  const groupMga = get(groupResponse, 'mga', '');
  let url = '';
  if (i18n.language === 'fr' && groupMga === 'PTZ_SODACO') {
    url = E_CONSENT_URLS.PTZ_SODACO_FR;
  } else {
    url = variation === 'demo' ? 'DEMO' : groupMga;
  }

  const [agreeEC, setAgreeEC] = useState(null);
  const [agreeCASL, setAgreeCASL] = useState(null);
  const [misrepresentation, setMisrepresentation] = useState(false);
  const [fraudWarning, setFraudWarning] = useState(false);
  const [acknowledgement, setAcknowledgement] = useState(false);
  const [premiumChanges, setPremiumChanges] = useState(false);
  const employeeDependentData = JSON.parse(
    localStorage.getItem(`employeeDependentData_${employeeId}`)
  );

  const { apiData: paramsData = {}, PALoading } = GetData(
    'get-ssm-params',
    JSON.stringify({ paramName: `${config.environment}_modelLaw_PA` })
  );

  const { apiData: paramsOHData = {}, loading: OHLoading } = GetData(
    'get-ssm-params',
    JSON.stringify({ paramName: `${config.environment}_modelLaw_OH` })
  );

  const renderDisclosureContent = () => {
    let disclosureContent;

    switch (true) {
      case paramsOHData.value === 'true' &&
        paramsOHData.name === `${config.environment}_modelLaw_OH`:
        disclosureContent = t('common.insurerDisclosureOH', {
          url: config.insurerDisclosuresUrlV3 || '#',
        });
        break;

      case paramsData.value === 'true':
        disclosureContent = t('common.insurerDisclosurePA', {
          url: config.insurerDisclosuresUrlV2 || '#',
        });
        break;

      default:
        disclosureContent = t('common.insurerDisclosure', {
          url: config.insurerDisclosuresUrl || '#',
        });
        break;
    }
    return disclosureContent;
  };
  
  useEffect(() => {
    const pathname = localStorage.getItem('pageUrl');
    if (pathname) {
      // eslint-disable-next-line no-restricted-globals
      history.back();
      localStorage.removeItem('pageUrl');
    }
  }, []);

  if (summaryLoading || PALoading || OHLoading) {
    return <Spinner />;
  }

  return (
    <Route isPrivate {...rest}>
      <ApolloConsumer>
        {
          // eslint-disable-next-line no-unused-vars
          (client) => (
            <Box
              alignItems="center"
              display={{
                lg: 'flex',
                sm: 'block',
              }}
              flexDirection="column"
              fontSize={4}
              justifyContent="center"
            >
              <Box
                width={{
                  lg: '40%',
                  sm: '100%',
                }}
              >
                <Box
                  as="h2"
                  color="black"
                  fontSize={7}
                  my={6}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 0,
                    width: '100%',
                  }}
                >
                  {t('econsent.documentation')}
                </Box>
                <Form
                  onSubmit={async () => {
                    const apiCall = (pathName, params) =>
                      client.query({
                        fetchPolicy: 'no-cache',
                        query: remoteActionQuery,
                        variables: {
                          name: pathName,
                          params: JSON.stringify(params),
                        },
                      });
                    const agreedToAll =
                      misrepresentation &&
                      fraudWarning &&
                      acknowledgement &&
                      agreeEC !== null &&
                      config.canadaEnv
                        ? agreeCASL !== null
                        : agreeCASL === null;
                    if (agreedToAll && premiumChanges) {
                      if (employeeDependentData.length > 0) {
                        const payload = {
                          employeeDependentList: employeeDependentData,
                          employeeId,
                          userId,
                        };
                        await apiCall('update-pet-demographic', payload)
                          // eslint-disable-next-line no-unused-vars
                          .then(async (response) => {
                            localStorage.setItem(
                              `employeeDependentData_${employeeId}`,
                              JSON.stringify([])
                            );
                            return client
                              .query({
                                fetchPolicy: 'no-cache',
                                query: remoteActionQuery,
                                variables: {
                                  name: 'update-employee',
                                  params: JSON.stringify({
                                    agree: agreeEC,
                                    caslAgree: agreeCASL,
                                    employeeId,
                                    isEconsentSubmitted: true,
                                    userId,
                                  }),
                                },
                              })
                              .then(async () => {
                                await apiCall(
                                  'update-employee-demographic-confirmation',
                                  {
                                    employeeId,
                                    hasDemographicChanges: false,
                                  }
                                )
                                  .then(async () => {
                                    if (
                                      ![
                                        USER_ROLE.EMPLOYEE,
                                        USER_ROLE.TERMINATED_EMPLOYEE,
                                        USER_ROLE.RENEWAL_POLICY_DELETED,
                                      ].includes(role)
                                    ) {
                                      return navigate(
                                        `/group-employees/${employeeId}/details`,
                                        { replace: true }
                                      );
                                    }
                                    navigate(`/home`, { replace: true });
                                  })
                                  .catch((e) =>
                                    setErrorSnack(
                                      t('snack.error.errorWithMessage', {
                                        message: e.message,
                                      }),
                                      config.notificationDuration
                                    )
                                  );
                              })
                              .catch((e) => {
                                setErrorSnack(
                                  t('snack.error.errorWithMessage', {
                                    message: e.message,
                                  }),
                                  config.notificationDuration
                                );
                                return setLoading(false);
                              });
                          })
                          .catch((e) => {
                            setErrorSnack(
                              t('snack.error.errorWithMessage', {
                                message: e.message,
                              }),
                              config.notificationDuration
                            );
                            return setLoading(false);
                          });
                      }
                    } else {
                      setErrorSnack(
                        <Box fontSize={3}>{t('validation.completeAll')}</Box>,
                        4000
                      );
                    }
                  }}
                  render={({ submitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Box as="h3" color="black" sx={{ textWidth: 300 }}>
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.misstatementPetInfo'
                        )}
                      </Box>
                      <Box>
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.agreeLegend'
                        )}
                      </Box>
                      <Checkbox
                        aria-label="Agree"
                        checked={misrepresentation}
                        data-testid="agree"
                        input={{
                          name: 'misrepresentation',
                          value: 'misrepresentation',
                        }}
                        label={labelHandler(t('common.agree'), true)}
                        meta={{ error: '', touched: true }}
                        onChange={() =>
                          setMisrepresentation(!misrepresentation)
                        }
                        type="checkbox"
                        wrapperSx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          ...commonFieldSxHalf,
                        }}
                      />
                      <Box as="h3" color="black" sx={{ textWidth: 300 }}>
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.fraudWarning'
                        )}
                      </Box>
                      {config.canadaEnv && (
                        <Box as="p">
                          {t(
                            'groupEmployees.enrollmentPage.addPetSection.fraudWarningLegend'
                          )}
                        </Box>
                      )}
                      {!config.canadaEnv && (
                        <Box as="p">
                          I acknowledge and understand that any person who
                          knowingly provides false, incomplete, or misleading
                          information to an insurance company for the purpose of
                          defrauding the company is guilty of a crime and may be
                          subject to fines, imprisonment, and denial of
                          insurance benefits. See state specific fraud notice:{' '}
                          <a
                            href="https://www.petpartners.com/insurance-fraud-warnings"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Insurance Fraud Warnings
                          </a>
                        </Box>
                      )}
                      <Checkbox
                        checked={fraudWarning}
                        data-testid="fraud"
                        input={{
                          name: 'fraudWarning',
                          value: 'fraudWarning',
                        }}
                        label={labelHandler(t('common.agree'), true)}
                        meta={{ error: '', touched: true }}
                        name="fraud"
                        onChange={() => setFraudWarning(!fraudWarning)}
                        type="checkbox"
                        wrapperSx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          ...commonFieldSxHalf,
                        }}
                      />
                      <Box as="h3" color="black" sx={{ textWidth: 300 }}>
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreement'
                        )}
                      </Box>
                      <Box as="p">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreementLegend'
                        )}
                      </Box>
                      <Checkbox
                        checked={acknowledgement}
                        data-testid="acknowledgement"
                        input={{
                          name: 'acknowledgement',
                          value: 'acknowledgement',
                        }}
                        label={labelHandler(t('common.agree'), true)}
                        meta={{ error: '', touched: true }}
                        onChange={() => setAcknowledgement(!acknowledgement)}
                        type="checkbox"
                        wrapperSx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          ...commonFieldSxHalf,
                        }}
                      />
                      <Box as="h3" color="black" sx={{ textWidth: 300 }}>
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.premiumChanges'
                        )}
                      </Box>
                      <Box as="p">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.premiumChangesLegend'
                        )}
                      </Box>
                      <Checkbox
                        checked={premiumChanges}
                        data-testid="premiumChanges"
                        input={{
                          name: 'premiumChanges',
                          value: 'premiumChanges',
                        }}
                        label={labelHandler(t('common.agree'), true)}
                        meta={{ error: '', touched: true }}
                        onChange={() => setPremiumChanges(!premiumChanges)}
                        type="checkbox"
                        wrapperSx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          ...commonFieldSxHalf,
                        }}
                      />
                      <Box>
                        <Box as="h3" color="black" sx={{ textWidth: 300 }}>
                          {t(
                            'groupEmployees.enrollmentPage.addPetSection.eConsent'
                          )}
                        </Box>
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: t(
                              'groupEmployees.enrollmentPage.addPetSection.eConsentLegend',
                              { url: E_CONSENT_URLS[url] || url }
                            ),
                          }}
                        />
                      </Box>
                      <Checkbox
                        checked={agreeEC === true}
                        input={{
                          name: 'agreeEC',
                          value: 'agreeEC',
                        }}
                        label={labelHandler(t('common.agree'))}
                        meta={{ error: '', touched: true }}
                        onChange={() => setAgreeEC(true)}
                        type="radio"
                        value="agree"
                        wrapperSx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          m: 0,
                          ...commonFieldSxHalf,
                        }}
                      />
                      <Checkbox
                        checked={agreeEC === false}
                        input={{
                          name: 'disagreeEC',
                          value: 'disagreeEC',
                        }}
                        label={labelHandler(t('common.disagree'))}
                        meta={{ error: '', touched: true }}
                        onChange={() => setAgreeEC(false)}
                        type="radio"
                        value="disagree"
                        wrapperSx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          m: 0,
                          ...commonFieldSxHalf,
                        }}
                      />
                      {config.canadaEnv && (
                        <Box>
                          <Box>
                            <Box fontWeight={600} mb="10px">
                              {t('component.caslDisclosure')}
                            </Box>
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  'groupEmployees.enrollmentPage.addPetSection.eConsentLegendCanada',
                                  {
                                    url:
                                      i18n.language === 'fr'
                                        ? 'https://www.onepackplan.ca/fr/politique-protection-renseignements'
                                        : 'https://www.onepackplan.ca/privacy-policy',
                                  }
                                ),
                              }}
                            />
                          </Box>
                          <Checkbox
                            checked={agreeCASL === true}
                            input={{
                              name: 'agreeCASL',
                              value: 'agreeCASL',
                            }}
                            label={labelHandler(t('common.agree'))}
                            meta={{ error: '', touched: true }}
                            onChange={() => setAgreeCASL(true)}
                            type="radio"
                            value="agree"
                            wrapperSx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              m: 0,
                              ...commonFieldSxHalf,
                            }}
                          />
                          <Checkbox
                            checked={agreeCASL === false}
                            input={{
                              name: 'disagreeCASL',
                              value: 'disagreeCASL',
                            }}
                            label={labelHandler(t('common.disagree'))}
                            meta={{ error: '', touched: true }}
                            onChange={() => setAgreeCASL(false)}
                            type="radio"
                            value="disagree"
                            wrapperSx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              m: 0,
                              ...commonFieldSxHalf,
                            }}
                          />
                        </Box>
                      )}
                      {!config.canadaEnv && (
                        <Box>
                          <Box as="h3" color="black" sx={{ textWidth: 300 }}>
                            {t('common.insurerDisclosureTitle')}
                          </Box>

                          <Box
                            dangerouslySetInnerHTML={{
                              __html: renderDisclosureContent(),
                            }}
                          />
                          {/* <Box
                            dangerouslySetInnerHTML={{
                              __html: t('common.insurerDisclosure', {
                                url: config.insurerDisclosuresUrl || '#',
                              }),
                            }}
                          /> */}
                        </Box>
                      )}
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                      >
                        <ToolbarButton
                          isDisabled={loading || submitting}
                          label={t('common.back')}
                          onClick={() => {
                            // eslint-disable-next-line no-restricted-globals
                            history.back();
                          }}
                          sx={{
                            mr: 10,
                            my: 6,
                          }}
                          type="button"
                          width="200px"
                        />
                        <ToolbarButton
                          isDisabled={loading}
                          label={t('common.submit')}
                          submitting={submitting}
                          sx={{
                            ml: 0,
                            my: 6,
                          }}
                          type="submit"
                          width="200px"
                        />
                      </Box>
                      {/* eslint-enable */}
                    </form>
                  )}
                />
              </Box>
            </Box>
          )
        }
      </ApolloConsumer>
    </Route>
  );
}

PDBAConsent.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      employeeId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
PDBAConsent.propTypes = {};

export default PDBAConsent;
