import React, { useContext } from 'react';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm } from 'react-final-form';
import Box from '@basecomponents/Box';
import Notes from '@basecomponents/Notes';
import { required } from '@src/utilities/validators';
import Route from '@petcomponents/Route';
import { get } from 'lodash';
import { useParams } from '@reach/router';
import ToolbarButton from '@basecomponents/ToolbarButton';
import { ApolloConsumer } from '@apollo/client';
import { AuthContext } from '@basecomponents/Auth';
import useSnackbar from '@src/utilities/use-snackbar';
import remoteActionQuery from '@src/graphql/queries/remote-action.gql';
import config from '@src/config.json';
import { navigate } from 'gatsby';
import GetData from '@src/utilities/get-data';
import { useTranslation } from 'react-i18next';

const AddGroupNotes = ({ ...rest }) => {
  const { t } = useTranslation()
  const queryParams = useParams();
  const groupId = get(queryParams, 'groupId', '');
  const billingNotesId = get(queryParams, 'billingNotesId', '');

  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });

  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId');

  const { apiData = {} } = GetData(
    'get-billing-notes-detail',
    JSON.stringify({ billingNotesId }),
    !billingNotesId
  );
  const { apiData: groups = {} } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );

  const billingInformationId = get(groups, 'billingInfo.billingInfoId', '');

  const {
    apiData: { content: payrollData = [] },
  } = GetData(
    'get-payroll-schedule',
    JSON.stringify({
      billingInfoId: billingInformationId,
      groupId,
      size: get(groups, 'billingInfo.billingCycle', ''),
      sort: 'cycleNo,asc',
    }),
    !groupId || !billingInformationId
  );

  const getPayrollCycles = () => {
    return payrollData?.map((item) => ({
      canEnglishValue: item?.cycleNo,
      frenchValue: item?.cycleNo,
      label: item?.cycleNo,
      value: item?.payrollScheduleId,
    }));
  };

  const getFormValues = () => ({
    categories: get(apiData, 'categories', ''),
    comment: get(apiData, 'billingComment', ''),
    fileUrls: get(apiData, 'fileUrls', null)
      ? get(apiData, 'fileUrls', '').split(',')
      : [],
    payrollScheduleId: get(apiData, 'payrollScheduleId', ''),
  });

  return (
    <Route isPrivate permission="group.page.notes" {...rest}>
      <ApolloConsumer>
        {(client) => {
          const onSubmit = async (values) => {
            let billingNotesData = {};
            const fileUrls =
              get(values, 'fileUrls', []).length > 0
                ? get(values, 'fileUrls')?.join?.(',')
                : null;
            billingNotesData = {
              categories: values?.categories,
              comment: values?.comment,
              fileUrls,
              groupId,
              userId,
            };

            if (values?.payrollScheduleId) {
              billingNotesData = {
                ...billingNotesData,
                payrollScheduleId: values?.payrollScheduleId,
              };
            }

            if (billingNotesId) {
              billingNotesData = {
                ...billingNotesData,
                billingNotesId,
              };
            }

            await client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: billingNotesId
                    ? 'update-billing-note'
                    : 'create-billing-notes',
                  params: JSON.stringify({ ...billingNotesData }),
                },
              })
              .then(() => {
                setSuccessSnack(
                  'Notes added successfully',
                  config.notificationDuration
                );
                navigate(-1);
              })
              .catch((e) => {
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                );
              });
          };

          return (
            <Box>
              <Box as="h2" sx={{ marginY: 10, textAlign: 'center' }}>
                {t('notes.addNotes')}
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <FinalForm
                  initialValues={getFormValues}
                  keepDirtyOnReinitialize
                  mutators={{
                    ...arrayMutators,
                  }}
                  onSubmit={async (values) => onSubmit(values)}
                  render={({ handleSubmit, submitting, form, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Box
                          sx={{
                            bg: 'white',
                            borderRadius: 4,
                            boxShadow: 1,
                            mx: 'auto',
                            p: 5,
                          }}
                        >
                          <Notes
                            key="addNotes"
                            fileUrls={get(values, 'fileUrls') || []}
                            form={form}
                            groupId={groupId}
                            payrollCycles={getPayrollCycles()}
                            uploadedFiles={
                              get(apiData, 'fileUrls', null)
                                ? get(apiData, 'fileUrls', '').split(',')
                                : []
                            }
                            validators={required}
                            values={values}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: '15px auto',
                            }}
                          >
                            <ToolbarButton
                              bg="primaryDark"
                              disabled={submitting}
                              label={t('common.submit')}
                              mr={0}
                              submitting={submitting}
                              type="submit"
                              width="150px"
                            />
                          </Box>
                        </Box>
                      </form>
                    );
                  }}
                />
              </Box>
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

export default AddGroupNotes;
