import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloConsumer } from '@apollo/client';
import { navigate, useParams } from '@reach/router';
import { capitalCase } from 'change-case';
import { get, isEmpty, isObject, omit, pick } from 'lodash';
import Box from '@basecomponents/Box';
import flatten from 'flat';
import { AuthContext } from '@basecomponents/Auth';
import { Field, Form as FinalForm } from 'react-final-form';
import { ThemeContext } from 'styled-components';
import arrayMutators from 'final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { diff } from 'deep-object-diff';
import {
  CONTACT_TYPE,
  E_CONSENT_URLS,
  EXISTING_CONDITION,
  QLE_TYPE,
  USER_ROLE,
} from '@utils/constants';
import { labelHandler } from '@utils/label-utils';
import { phoneNumberMask } from '@utils/masks';
import { phoneNumber } from '@utils/validations';
import { required } from '@utils/validators';
import { useTranslation } from 'react-i18next';
import i18n from '@src/utilities/i18n';
import Modal from '../../../../BaseComponents/Modal';
import Spinner from '../../../../BaseComponents/Spinner';
import GetData from '../../../../../utilities/get-data';
import numberWithCommas from '../../../../../utilities/number-with-commas';
import remoteActionQuery from '../../../../../graphql/queries/remote-action.gql';
import useSnackbar from '../../../../../utilities/use-snackbar';
import InputCheckbox from '../../../../BaseComponents/InputCheckbox';
import ToolbarButton from '../../../../BaseComponents/ToolbarButton';
import InputDropdown from '../../../../BaseComponents/Dropdown';
import UploadFile from '../../../UploadFile';
import InputText from '../../../../BaseComponents/Input';
import dateUtils from '../../../../../utilities/date-utils';
import SingleDatePicker from '../../../../BaseComponents/SingleDatePicker';
import gaEvent from '../../../../../utilities/gaEvent';
import config, { variation } from '../../../../../config.json';
import Icon from '../../../../Icon';
import InputMasked from '../../../../BaseComponents/InputMasked';
import AddressFormSection from '../../../AddressFormSection';
import generateRedirectPath from '../../../../../utilities/generate-redirect-path';

/**
 * @category Employee
 * @param {Object} location
 * @param {string} name
 * @param {*} rest
 * @returns {React.FC}
 */
const EnrollmentSummary = ({ location, name, ...rest }) => {
  const { t } = useTranslation();
  const start = Date.now();
  const queryParams = useParams();
  const employeeId = get(queryParams, 'employeeId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [loading, setLoading] = useState(false);
  const [premiumChanges, setPremiumChanges] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reasonType, setReasonType] = useState(false);
  const [fileUpload, setFileUpload] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [showTCModal, setShowTCModal] = useState(false);
  const [agreeMPI, setAgreeMPI] = useState(false);
  const [agreeFW, setAgreeFW] = useState(false);
  const [agreeAA, setAgreeAA] = useState(false);
  const [agreeEC, setAgreeEC] = useState(null);
  const [agreeCASL, setAgreeCASL] = useState(null);
  const [acquisitionDate, setAcquisitionDate] = useState(null);
  const [employeeInformationVisible, setEmployeeInformationVisible] =
    useState(false);
  const [employmentInformationVisible, setEmploymentInformationVisible] =
    useState(false);
  const [petInformationVisible, setPetInformationVisible] = useState(false);
  const [planInformationVisible, setPlanInformationVisible] = useState(false);
  const [changeContact, setChangeContact] = useState(false);
  const [transactionDate, setTransactionDate] = useState(null);
  const [isLate, setLateEnrollment] = useState(null);
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const role = get(user, 'customRole', '');
  const isRenewals = get(location, 'pathname', '').includes('/renewalenroll');
  const {
    apiData: { ReasonType = [] },
  } = GetData('get-enum-options', JSON.stringify([{ params: 'ReasonType' }]));

  const { apiData: paramsData = {}, PALoading } = GetData(
    'get-ssm-params',
    JSON.stringify({ paramName: `${config.environment}_modelLaw_PA` })
  );

  const { apiData: paramsOHData = {}, loading: OHLoading } = GetData(
    'get-ssm-params',
    JSON.stringify({ paramName: `${config.environment}_modelLaw_OH` })
  );

  const {
    apiData: summary = {},
    loading: summaryLoading,
    refetch,
  } = GetData('get-enrollment-summary', JSON.stringify({ employeeId }));
  const {
    coverageInfo = [],
    policyHolderInfo = {},
    employmentInfo = {},
    planDetails = [],
    groupResponse = {},
  } = summary;
  const keyValueOrder = {
    employmentInfo: [
      'dateOfHire',
      // 'annualSalary',
      'avgHrsPerWeek',
      'fullTimePartTimeCode',
      'department',
      'jobTitle',
    ],
    petInfo: [
      'species',
      'breed',
      'age',
      'weight',
      // 'employerContribution',
      // 'employeeContribution',
      'payPeriodPremiumEmployer',
      'payPeriodPremiumEmployee',
    ],
    planDetails: [
      'name',
      'productDetails.name',
      'annualLimit',
      'annualDeductible',
      'coinsurance',
      'wellness',
      'riderRPT',
      'riderICC',
      'riderOETC',
      'riderABC',
      'riderAC',
      'riderFR',
      'riderRD',
      'poaAR',
      'bwpInjuries',
      'bwpIllness',
      'maxAge',
      'existingCondition',
      'coverageCredit',
      'diminishingDeductible',
      'copay',
      'totalEmployeeContribution',
      'totalEmployerContribution',
      'planPrice',
    ],
    policyHolderInfo: [
      'firstName',
      'middleName',
      'lastName',
      'email',
      'phoneNumber',
      'address.addressLine1',
      'address.addressLine2',
      'address.city',
      'address.state',
      'address.zipcode',
    ],
  };

  const valueMap = {
    fullTimePartTimeCode: { FT: 'Full Time', PT: 'Part Time' },
  };

  const keyMap = {
    'address.addressLine1': {
      label: t('groupEmployees.enrollmentPage.addressLine', { number: 1 }),
    },
    'address.addressLine2': {
      label: t('groupEmployees.enrollmentPage.addressLine', { number: 2 }),
    },
    'address.city': {
      label: t('groupEmployees.enrollmentPage.city'),
    },
    'address.state': {
      label: t('groupEmployees.enrollmentPage.stateOnly'),
    },
    'address.zipcode': {
      label: t('groupEmployees.enrollmentPage.zipCode'),
    },
    age: {
      label: t(
        'groupEmployees.enrollmentPage.addPetSection.ageAtEffectiveDate'
      ),
    },
    annualDeductible: {
      label: t('metadata.annaulDeductible'),
      type: 'amountZero',
    },
    annualLimit: {
      label: t('metadata.annualPolicyLimit'),
      type: 'limit',
    },
    avgHrsPerWeek: {
      label: t('metadata.avgHoursPerWeek'),
    },
    breed: {
      label: t('metadata.breed'),
    },
    bwpCLI: {
      default: t('metadata.sixMonths'),
      label: t('metadata.bwpCLI'),
    },
    bwpIllness: {
      exclude: 'Accident Only',
      label: t('metadata.bwpIllness'),
      type: 'days',
    },
    bwpInjuries: {
      label: t('metadata.bwpInjuries'),
      type: 'days',
    },
    coinsurance: {
      label: t('metadata.coinsurance'),
      type: 'percentage',
    },
    copay: {
      exclude: 'Accident Only',
      label: t('metadata.copay'),
      type: 'amount',
    },
    coverageCredit: {
      exclude: 'Accident Only',
      label: t('metadata.coverageCredit'),
      type: 'boolean',
    },
    dateOfHire: {
      label: t('metadata.dateOfHire'),
    },
    department: {
      label: t('metadata.department'),
    },
    diminishingDeductible: {
      exclude: 'Accident Only',
      label: t('metadata.diminishingDeductible'),
      type: 'amount',
    },
    employeeContribution: {
      label: t('metadata.employeePremium'),
      type: 'money',
    },
    employerContribution: {
      label: t('metadata.employerContribution'),
      showIfPositive: true,
      type: 'money',
    },
    existingCondition: {
      label: t('metadata.preExistingConditions'),
      type: 'existingCondition',
    },
    firstName: {
      label: t('groupEmployees.enrollmentPage.firstName'),
    },
    fullTimePartTimeCode: {
      label: t('metadata.fullTimePartTime'),
    },
    jobTitle: {
      label: t('metadata.jobTitle'),
    },
    lastName: {
      label: t('groupEmployees.enrollmentPage.lastName'),
    },
    maxAge: {
      label: t('metadata.maxAge'),
    },
    middleName: {
      label: t('groupEmployees.enrollmentPage.middleName'),
    },
    name: {
      label: t('metadata.planName'),
    },
    payPeriodPremiumEmployee: {
      label:
        get(groupResponse, 'billingInfo.payrollFrequency') === '12'
          ? t(
              'groupEmployees.enrollmentPage.addPetSection.monthlyPremiumEmployee'
            )
          : t('groupEmployees.enrollmentPage.addPetSection.empPremiumPayCycle'),
      type: 'money',
    },
    payPeriodPremiumEmployer: {
      label:
        get(groupResponse, 'billingInfo.payrollFrequency') === '12'
          ? t(
              'groupEmployees.enrollmentPage.addPetSection.employerContributionMonthly'
            )
          : t(
              'groupEmployees.enrollmentPage.addPetSection.empContributionPayCycle'
            ),
      showIfPositive: true,
      type: 'money',
    },
    phoneNumber: {
      label: t('groupEmployees.enrollmentPage.phoneNumber'),
    },
    planPrice: {
      label: 'Total Premium Per Pay Cycle',
      type: 'amount',
    },
    poaAR: {
      label: t('metadata.poaAR'),
      type: 'boolean',
    },
    'productDetails.name': {
      label: t('metadata.planChoicesName'),
    },
    riderABC: {
      exclude: 'Accident Only',
      label: t('metadata.alternateBehavioralCare'),
      type: 'boolean',
    },
    riderAC: {
      exclude: 'Accident & Illness',
      label: t('metadata.alternateCare'),
      type: 'boolean',
    },
    riderFR: {
      label: t('metadata.finalRespects'),
      type: 'amount',
    },
    riderICC: {
      exclude: 'Accident Only',
      label: config.canadaEnv
        ? t('metadata.inheritedConditionRider')
        : 'Inherited and Congenital Care',
      type: 'boolean',
    },
    riderOETC: {
      label: t('metadata.nonriderOETC'),
      type: 'boolean',
    },
    riderRD: {
      label: t('metadata.routineDental'),
      type: 'amount',
    },
    riderRPT: {
      label: t('metadata.nonriderRPT'),
      type: 'boolean',
    },
    species: {
      label: t('metadata.species'),
    },
    totalEmployeeContribution: {
      label:
        get(groupResponse, 'billingInfo.payrollFrequency') === '12'
          ? t('metadata.totalEmployeePremiumMonthly')
          : t('metadata.totalEmployeePremiumPerPayCycle'),
      type: 'amount',
    },
    totalEmployerContribution: {
      label:
        get(groupResponse, 'billingInfo.payrollFrequency') === '12'
          ? t('metadata.totalEmployerContributionMonthly')
          : t('metadata.totalEmployerContributionPerPayCycle'),
      showIfPositive: true,
      type: 'amount',
    },
    weight: {
      label: t('groupEmployees.enrollmentPage.addPetSection.weightAdulthood'),
    },
    wellness: {
      exclude: 'Accident Only',
      label: t('metadata.nonriderWellness'),
    },
  };

  const commonFieldSxHalf = {
    py: 3,
    width: '25rem',
  };

  const groupMga = get(groupResponse, 'mga', '');
  const parentGroupId = get(groupResponse, 'parentGroupId');
  const parentEmpId = get(policyHolderInfo, 'parentEmployeeId');
  let url = '';
  if (i18n.language === 'fr' && groupMga === 'PTZ_SODACO') {
    url = E_CONSENT_URLS.PTZ_SODACO_FR;
  } else {
    url = variation === 'demo' ? 'DEMO' : groupMga;
  }
  const contact = get(policyHolderInfo.user, 'contacts[0]', {}) || {};
  const theme = useContext(ThemeContext);
  const selectedCoverageDetails = get(coverageInfo, 'premiumValues', []).filter(
    (item) => item.productPlanId === get(planDetails, '0.productPlanId')
  );
  const relativeDatetime =
    dateUtils.setOffset2(get(groupResponse, 'relativeDatetime')) || new Date();
  const maxDate = dateUtils.setOffset2(
    get(groupResponse, 'plan.planChoices[0].coverageToDate')
  );
  const updatedPets = get(
    selectedCoverageDetails,
    '0.dependentDatas',
    []
  ).filter((dependent) => dependent.status === 'PENDING');
  const prevAddPet = get(coverageInfo, 'employeeDependents', []).filter(
    (item) =>
      updatedPets.find((element) => {
        return (
          element.dependentId === item.employeeDependentId &&
          item.dependentData.addLateEnrollment
        );
      })
  );
  const newlyAddPet = get(coverageInfo, 'employeeDependents', []).filter(
    (item) =>
      updatedPets.find((element) => {
        return (
          element.dependentId === item.employeeDependentId &&
          item.parentEmployeeDependentId === null
        );
      })
  );
  if (!summaryLoading) {
    if (planDetails && planDetails.length > 0) {
      planDetails[0].totalPlanPrice = get(
        selectedCoverageDetails,
        '0.totalPremium'
      );
      planDetails[0].totalEmployeeContribution = get(
        selectedCoverageDetails,
        '0.payPeriodPremiumEmployee'
      );
      planDetails[0].totalEmployerContribution = get(
        selectedCoverageDetails,
        '0.payPeriodPremiumEmployer'
      );
      planDetails[0].planPrice =
        get(selectedCoverageDetails, '0.payPeriodPremiumEmployee', 0) +
        get(selectedCoverageDetails, '0.payPeriodPremiumEmployer', 0);
    }
  }
  const selectedPlan =
    planDetails.find((item) => item.acceptGroupPlanChoice) || {};

  const planConfirmation = get(
    policyHolderInfo,
    'employeePlanConfirmation',
    {}
  );

  const currentDate =
    get(groupResponse, 'relativeDatetime') === null
      ? dateUtils.getLocaleDateOnly(new Date())
      : dateUtils.getLocaleDateOnly(get(groupResponse, 'relativeDatetime'));

  const enrolmentEndDate = dateUtils.getUTCDateOnly(
    get(coverageInfo, 'employeeDates.enrollmentEndDate')
  );

  useEffect(() => {
    const isLateFlow = Date.parse(enrolmentEndDate) < Date.parse(currentDate);
    setLateEnrollment(isLateFlow);
  }, [enrolmentEndDate, currentDate]);

  let transactionDateSubmit;
  let qleReasonSubmit;

  const onSubmit = () => {
    if (isLate) {
      qleReasonSubmit();
    }
    if (
      !transactionDate &&
      [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(role)
    ) {
      return transactionDateSubmit();
    }

    let agreementsOK = false;
    let reasonTypeOK = false;
    // const fileuploadOK = false;

    if (
      (premiumChanges &&
        (agreeMPI && agreeFW && agreeAA && agreeEC !== null && config.canadaEnv
          ? agreeCASL !== null
          : agreeCASL === null)) ||
      isRenewals
    ) {
      agreementsOK = true;
    } else {
      agreementsOK = false;
      setErrorSnack(
        <Box
          dangerouslySetInnerHTML={{
            __html: t('snack.error.pleaseAgreeLegend'),
          }}
        />,
        15000
      );
    }
    if (newlyAddPet.length > 0 && (!agreeMPI || agreeEC === null)) {
      setErrorSnack(
        <Box
          dangerouslySetInnerHTML={{
            __html: t('snack.error.pleaseAgreeTerms'),
          }}
        />,
        config.notificationDuration
      );
      return null;
    }
    if (isLate) {
      if (
        !transactionDate &&
        (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN)
      ) {
        setErrorSnack(
          t('snack.error.transacionDate'),
          config.notificationDuration
        );
      }
      if (acquisitionDate === null) {
        agreementsOK = false;
        setErrorSnack(
          t('snack.error.acquisitionDate'),
          config.notificationDuration
        );
      }
      if (ReasonType.map((item) => item.value).includes(reasonType)) {
        reasonTypeOK = true;
      } else {
        reasonTypeOK = false;
        setErrorSnack(
          t('snack.error.reasonLateEnrollment'),
          config.notificationDuration
        );
      }
      if (agreementsOK && reasonTypeOK) {
        if ([USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(role)) {
          if (transactionDate) {
            setLoading(true);
            setShowModal(true);
          }
        } else {
          setLoading(true);
          setShowModal(true);
        }
      }
    } else if (agreementsOK) {
      setLoading(true);
      setShowModal(true);
    }
  };

  const getValue = (key, value, type) => {
    if (type === 'boolean') {
      return value === 0 ? t('metadata.notIncluded') : t('metadata.included');
    }
    if (key.toLowerCase().includes('date')) {
      return value ? dateUtils.getUTCDateOnly(value) : null;
    }
    if (type === 'days') {
      return value > 0 ? `${value} days(s)` : t('metadata.waived');
    }
    if (type === 'existingCondition') {
      return EXISTING_CONDITION.find(
        (s) => s.value === (parseInt(value, 10) ? parseInt(value, 10) : 0)
      )[i18n.language === 'fr' ? 'frenchValue' : 'label'];
    }
    if (type === 'amountZero') {
      return `$${value.toFixed(2) ? numberWithCommas(value.toFixed(2)) : '0'}`;
    }
    if (type === 'amount') {
      return value > 0
        ? `$${value.toFixed(2) ? numberWithCommas(value.toFixed(2)) : '0'}`
        : t('metadata.notIncluded');
    }
    if (type === 'limit') {
      return value > 0
        ? `$${value.toFixed(2) ? numberWithCommas(value.toFixed(2)) : '0'}`
        : t('metadata.unlimited');
    }
    if (type === 'money') {
      return value % 1 === 0
        ? `$${value ? numberWithCommas(value) : '0'}`
        : `$${value.toFixed(2) ? numberWithCommas(value.toFixed(2)) : '0'}`;
    }
    if (key.toLowerCase().includes('age')) {
      if (value === 0) {
        return t('metadata.none');
      }
      return value
        ? `${value} ${t('metadata.years')}`
        : t('metadata.under1Year');
    }
    if (key.toLowerCase().includes('weight')) {
      return `${value} lbs`;
    }
    if (type === 'percentage') {
      return `${value}%`;
    }
    if (valueMap[key]) {
      return valueMap[key][value];
    }
    return value;
  };

  const renderValues = (section) => {
    const product = get(section, 'productDetails.name', '');
    return (
      <Box>
        {Object.keys(section).map((key) => {
          const value = section[key];
          const keyObject = keyMap[key] || '';
          if (keyObject.exclude === product) {
            return null;
          }
          if (keyObject.showIfPositive && !value) {
            return null;
          }
          if (isObject(value)) {
            return (
              <Box key={key}>
                {Object.keys(value).map((key) => {
                  const keyObject = keyMap[key] || '';
                  return (
                    <Box
                      key={key}
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Box
                        sx={{
                          bg: 'accent',
                          borderRadius: 1,
                          color: 'white',
                          display: 'flex',
                          fontSize: 2,
                          fontWeight: 'bold',
                          justifyContent: 'center',
                          letterSpacing: 0,
                          m: 3,
                          mr: 4,
                          p: 2,
                          textTransform: 'uppercase',
                          width: '120px',
                        }}
                      >
                        {keyObject.label || capitalCase(key)}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '60%',
                        }}
                      >
                        {getValue(key, value[key], keyObject.type)}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            );
          }
          return (
            <Box
              key={key}
              sx={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap' }}
            >
              <Box
                sx={{
                  width: '50%',
                }}
              >
                <Box
                  sx={{
                    bg: 'accent',
                    borderRadius: 1,
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 2,
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    letterSpacing: 0,
                    m: 3,
                    mr: 4,
                    p: 2,
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  {keyObject.label || capitalCase(key)}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                  wordWrap: 'break-word',
                }}
              >
                {getValue(key, value, keyObject.type)}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderSection = (
    title,
    key,
    values,
    donotDisplayArrow,
    visibilityState,
    visibilityStateToggle
  ) => {
    return (
      <>
        {visibilityState || donotDisplayArrow ? (
          <Box
            sx={{
              borderRadius: 4,
              padding: 3,
            }}
          >
            <Box
              as="h4"
              onClick={() => visibilityStateToggle(!visibilityState)}
              sx={{
                alignItems: 'center',
                color: 'primary',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mb: '10px',
              }}
            >
              {title}
              {!donotDisplayArrow && (
                <Icon
                  svg="expand-less"
                  sx={{
                    height: '30px',
                    width: '30px',
                  }}
                />
              )}
            </Box>
            {renderValues(flatten(pick(values, keyValueOrder[key])))}
          </Box>
        ) : (
          <Box
            sx={{
              borderRadius: 4,
              padding: 3,
            }}
          >
            <Box
              as="h4"
              onClick={() => visibilityStateToggle(!visibilityState)}
              sx={{
                alignItems: 'center',
                color: 'primary',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {title}
              <Icon
                svg="expand-more"
                sx={{
                  height: '30px',
                  width: '30px',
                }}
              />
            </Box>
          </Box>
        )}
      </>
    );
  };

  if (summaryLoading || PALoading || OHLoading) {
    return <Spinner />;
  }

  const renderDisclosureContent = () => {
    let disclosureContent;

    switch (true) {
      case paramsOHData.value === 'true' &&
        paramsOHData.name === `${config.environment}_modelLaw_OH`:
        disclosureContent = t('common.insurerDisclosureOH', {
          url: config.insurerDisclosuresUrlV3 || '#',
        });
        break;

      case paramsData.value === 'true':
        disclosureContent = t('common.insurerDisclosurePA', {
          url: config.insurerDisclosuresUrlV2 || '#',
        });
        break;

      default:
        disclosureContent = t('common.insurerDisclosure', {
          url: config.insurerDisclosuresUrl || '#',
        });
        break;
    }
    return disclosureContent;
  };

  return (
    <ApolloConsumer>
      {(client) => {
        const apiCall = (pathName, params) =>
          client.query({
            fetchPolicy: 'no-cache',
            query: remoteActionQuery,
            variables: {
              name: pathName,
              params: JSON.stringify(params),
            },
          });
        const onUpdatePolicyHolder = (values) => {
          gaEvent('Enrollment', 'Employee Information Set', role, start);
          const params = {};
          const contactValues = get(values, 'contact', {});
          const updatedContactValues = contactValues.phoneNumber
            ? {
                contactId: contactValues.contactId,
                contactType: contactValues.contactType || 'CELL',
                // deleted: get(contactValues, 'deleted', false),
                phoneNumber: contactValues.phoneNumber,
              }
            : {};
          const contactData = omit(contact, ['created', 'lastModified']);
          const contactDiff = diff(contactData, updatedContactValues);
          if (!isEmpty(contactDiff)) {
            params.contacts = contactValues.phoneNumber
              ? [{ ...updatedContactValues }]
              : [];
          }
          const updatedAddressValues = {
            addressId: get(contactValues, 'addressId', ''),
            addressLine1: get(contactValues, 'addressLine1', ''),
            addressLine2: get(contactValues, 'addressLine2', ''),
            city: get(contactValues, 'city', ''),
            country: get(contactValues, 'country', ''),
            county: get(contactValues, 'county', ''),
            deleted: get(contactValues, 'deleted', ''),
            state: get(contactValues, 'state', ''),
            zipcode: get(contactValues, 'zipcode', ''),
          };
          let addressData = get(policyHolderInfo, 'user.address', {});
          addressData = omit(addressData, ['created', 'lastModified']);
          const addressDiff = diff(addressData, updatedAddressValues);
          if (Object.keys(addressDiff).length) {
            params.address = updatedAddressValues;
          }
          if (Object.keys(params).length) {
            return client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'update-core-user',
                  params: JSON.stringify({
                    userId: get(policyHolderInfo, 'userId'),
                    ...params,
                  }),
                },
              })
              .then(async () => {
                await refetch();
                return setChangeContact(false);
              })
              .catch((e) =>
                setErrorSnack(
                  t('snack.error.errorWithMessage', { message: e.message }),
                  config.notificationDuration
                )
              );
          }
          return setChangeContact(false);
        };

        const updateEmployeeData = async () => {
          const params = {
            employeeId,
            employeePlanConfirmationId: get(
              planConfirmation,
              'employeePlanConfirmationId',
              ''
            ),
            isLateEnrollment: isLate,
            planId: get(planDetails, '[0].planId', ''),
            totalPrice: get(planDetails, '[0].totalPlanPrice', ''),
            userId
          };
          const created = transactionDate || relativeDatetime;
          if (created) {
            params.created = dateUtils.setAPIDateOnly(created);
          }

          client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'update-enrollment-summary',
                params: JSON.stringify(params),
              },
            })
            .then(async () => {
              client
                .query({
                  fetchPolicy: 'no-cache',
                  query: remoteActionQuery,
                  variables: {
                    name: 'update-employee',
                    params: JSON.stringify({
                      agree: agreeEC,
                      caslAgree: agreeCASL,
                      employeeId,
                      userId,
                    }),
                  },
                })
                .then(() => {
                  return navigate(`/group-employees/${employeeId}/details`);
                })
                .catch((e) => {
                  setErrorSnack(
                    t('snack.error.errorWithMessage', { message: e.message }),
                    config.notificationDuration
                  );
                  setLoading(false);
                  setSubmitting(false);
                });
            })
            .catch((e) => {
              setErrorSnack(
                t('snack.error.errorWithMessage', { message: e.message }),
                config.notificationDuration
              );
              setLoading(false);
              setSubmitting(false);
            });
        };
        const onSummarySubmit = async () => {
          if (prevAddPet.length > 0) {
            await Promise.all(
              prevAddPet.map(async (petInfo) => {
                const { dependentData = {} } = petInfo;
                const addedPets = {
                  addedDependents: [
                    {
                      deleted: false,
                      dependentData: {
                        age: dependentData.age,
                        breed: dependentData.breed,
                        petName: dependentData.petName,
                        species: dependentData.species,
                        weight: dependentData.weight,
                      },
                      employeeId: parentEmpId,
                      relation: 'pet',
                      status: 'INACTIVE',
                    },
                  ],
                  employeeId: parentEmpId,
                  isWithinGroupOpenEnrollment: false,
                  relativeDate: dateUtils.setAPIDateOnly(currentDate),
                  updatedDependents: [],
                  userId,
                };
                await apiCall('update-employee-dependents', addedPets)
                  .then(async (response) => {
                    const dependentResponse = JSON.parse(
                      get(response, 'data.remoteAction.data', '{}')
                    );
                    const employeeDependentId = get(
                      dependentResponse,
                      '0.0.employeeDependentId'
                    );
                    if (employeeDependentId) {
                      const qleData = {
                        acquisitionDate: dateUtils.setAPIDateOnly(
                          petInfo.dependentData.acquisitionDate
                        ),
                        createdBy: userId,
                        createdFor: get(policyHolderInfo, 'userId'),
                        dependentData: get(dependentResponse, '0'),
                        employeeId: parentEmpId,
                        groupId: parentGroupId,
                        groupName: get(groupResponse, 'groupName'),
                        qleType: QLE_TYPE.NEW_PET,
                        reasonType: petInfo?.reasonType,
                        requestedDate: new Date(),
                        requestStatus: 'UNASSIGNED',
                        supportingDocs: get(dependentData, 'fileUpload', []),
                        userId,
                      };
                      await apiCall('create-qle', qleData)
                        .then(() => {})
                        .catch((e) => {
                          setErrorSnack(
                            t('snack.error.errorWithMessage', {
                              message: e.message,
                            }),
                            config.notificationDuration
                          );
                          setSubmitting(false);
                          setLoading(false);
                        });
                    }
                  })
                  .catch((e) => {
                    setErrorSnack(
                      t('snack.error.errorWithMessage', { message: e.message }),
                      config.notificationDuration
                    );
                    setSubmitting(false);
                    setLoading(false);
                  });
                return {};
              })
            );
          }
          gaEvent('Enrollment', 'Enrollment Submitted', role, start);
          setShowModal(false);
          if (isLate) {
            const dependentData = get(
              policyHolderInfo,
              'employeeDependents',
              []
            ).filter((dependent) => dependent.status === 'PENDING');
            const qleData = {
              acquisitionDate: dateUtils.setAPIDateOnly(acquisitionDate),
              createdBy: userId,
              createdFor: get(policyHolderInfo, 'userId'),
              dependentData,
              employeeId,
              groupId: get(groupResponse, 'groupId'),
              groupName: get(groupResponse, 'groupName'),
              qleType: QLE_TYPE.LATE_ENROLLMENT,
              reasonType,
              requestedDate: new Date(),
              requestStatus: 'UNASSIGNED',
              supportingDocs: fileUpload,
              userId,
            };
            await client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'create-qle',
                  params: JSON.stringify(qleData),
                },
              })
              .then(async () => {
                await updateEmployeeData();
              })
              .catch((e) => {
                setErrorSnack(
                  t('snack.error.errorWithMessage', { message: e.message }),
                  config.notificationDuration
                );
                setLoading(false);
                setSubmitting(false);
              });
          } else {
            await updateEmployeeData();
          }
        };
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              mb: 8,
              width: '100%',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                bg: 'white',
                borderRadius: 4,
                boxShadow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: '860px',
                padding: 3,
                width: { _: '100%', md: '27rem' },
              }}
            >
              <Box as="h2" sx={{ color: 'primary', mx: 4 }}>
                {t('groupEmployees.enrollmentPage.summary')}
              </Box>
              <Box as="h4" sx={{ color: 'accentSecondary', mx: 4 }}>
                {t('groupEmployees.enrollmentPage.contactHR')}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    border: `1px solid ${theme.colors.accent}`,
                    borderRadius: 4,
                    height: 'fit-content',
                    m: '10px',
                    minWidth: '25rem',
                    width: 'calc(50% - 20px)',
                  }}
                >
                  {renderSection(
                    t('groupEmployees.enrollmentPage.employeeInfo'),
                    'policyHolderInfo',
                    {
                      ...policyHolderInfo,
                      ...get(policyHolderInfo.user, 'contacts[0]', ''),
                      ...policyHolderInfo.user,
                    },
                    false,
                    employeeInformationVisible,
                    setEmployeeInformationVisible
                  )}
                  {employeeInformationVisible && (
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        my: 4,
                      }}
                    >
                      <ToolbarButton
                        alt={t(
                          'groupEmployees.enrollmentPage.updateContactInfo'
                        )}
                        aria-label={t(
                          'groupEmployees.enrollmentPage.updateContactInfo'
                        )}
                        bg="accentSecondary"
                        icon="edit"
                        label={t(
                          'groupEmployees.enrollmentPage.updateContactInfo'
                        )}
                        ml={0}
                        onClick={() => setChangeContact(true)}
                        type="button"
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    border: `1px solid ${theme.colors.accent}`,
                    borderRadius: 4,
                    height: 'fit-content',
                    m: '10px',
                    minWidth: '25rem',
                    width: 'calc(50% - 20px)',
                  }}
                >
                  {renderSection(
                    t('groupEmployees.enrollmentPage.employmentInfo'),
                    'employmentInfo',
                    employmentInfo,
                    false,
                    employmentInformationVisible,
                    setEmploymentInformationVisible
                  )}
                </Box>
                <Box
                  sx={{
                    border: `1px solid ${theme.colors.accent}`,
                    borderRadius: 4,
                    height: 'fit-content',
                    m: '10px',
                    minWidth: '25rem',
                    width: 'calc(50% - 20px)',
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: 4,
                      padding: 3,
                    }}
                  >
                    <Box
                      as="h4"
                      onClick={() =>
                        setPetInformationVisible(!petInformationVisible)
                      }
                      sx={{
                        alignItems: 'center',
                        color: 'primary',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      {t('groupEmployees.enrollmentPage.petInformation')}
                      <Icon
                        svg={
                          petInformationVisible ? 'expand-less' : 'expand-more'
                        }
                        sx={{
                          height: '30px',
                          width: '30px',
                        }}
                      />
                    </Box>
                  </Box>
                  {petInformationVisible &&
                    updatedPets.map((item) => (
                      <React.Fragment key={item.employeeDependentId}>
                        {renderSection(
                          get(item, 'petName', ''),
                          'petInfo',
                          item,
                          true,
                          false,
                          () => {}
                        )}
                      </React.Fragment>
                    ))}
                </Box>
                <Box
                  sx={{
                    border: `1px solid ${theme.colors.accent}`,
                    borderRadius: 4,
                    height: 'fit-content',
                    m: '10px',
                    minWidth: '25rem',
                    width: 'calc(50% - 20px)',
                  }}
                >
                  {renderSection(
                    t('groupEmployees.detailsPage.planDetailsDropDown'),
                    'planDetails',
                    selectedPlan,
                    false,
                    planInformationVisible,
                    setPlanInformationVisible
                  )}
                </Box>
              </Box>
              <>
                {changeContact && (
                  <Modal
                    footerSx={{
                      p: 0,
                    }}
                    isOpen
                    onClose={() => {
                      setChangeContact(false);
                    }}
                    title={t('groupEmployees.enrollmentPage.updateContactInfo')}
                  >
                    <FinalForm
                      initialValues={{
                        contact: {
                          ...get(policyHolderInfo.user, 'contacts[0]', ''),
                          ...get(policyHolderInfo.user, 'address', {}),
                        },
                      }}
                      onSubmit={(values) => onUpdatePolicyHolder(values)}
                      render={(formContext) => {
                        return (
                          <form onSubmit={formContext.handleSubmit}>
                            <Box sx={{ padding: '0 2rem' }}>
                              <Field
                                aria-label={t(
                                  'groupEmployees.enrollmentPage.phoneNumberType'
                                )}
                                component={InputDropdown}
                                label={labelHandler(
                                  t(
                                    'groupEmployees.enrollmentPage.phoneNumberType'
                                  )
                                )}
                                name="contact.contactType"
                                options={CONTACT_TYPE}
                                searchable={false}
                                wrapperSx={{ ...commonFieldSxHalf }}
                                {...rest}
                              />
                              <Field
                                aria-label={t(
                                  'groupEmployees.enrollmentPage.phoneNumber'
                                )}
                                component={InputMasked}
                                label={labelHandler(
                                  t('groupEmployees.enrollmentPage.phoneNumber')
                                )}
                                mask={phoneNumberMask}
                                name="contact.phoneNumber"
                                validate={phoneNumber}
                                wrapperSx={{
                                  ...commonFieldSxHalf,
                                }}
                                {...rest}
                              />

                              <AddressFormSection
                                label="contact"
                                wrapperSx={{ ...commonFieldSxHalf }}
                                {...rest}
                              />
                            </Box>

                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                p: 3,
                              }}
                            >
                              <ToolbarButton
                                bg="accent"
                                label="Close"
                                ml={0}
                                onClick={() => setChangeContact(false)}
                                width="10rem"
                              />
                              <ToolbarButton
                                bg="accentSecondary"
                                isDisabled={formContext.submitting}
                                label={t('common.submit')}
                                mr={0}
                                submitting={formContext.submitting}
                                type="submit"
                                width="10rem"
                              />
                            </Box>
                          </form>
                        );
                      }}
                    />
                  </Modal>
                )}
              </>
              {(role === USER_ROLE.ADMIN ||
                role === USER_ROLE.SUPER_ADMIN ||
                prevAddPet.length > 0 ||
                newlyAddPet.length > 0 ||
                isLate) && (
                <Box
                  as="h2"
                  sx={{
                    color: 'primary',
                    justifyContent: 'flex-start',
                    pt: 6,
                    px: 3,
                    width: '100%',
                  }}
                >
                  {t('groupEmployees.enrollmentPage.documentation')}
                </Box>
              )}

              {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
                <FinalForm
                  initialValues={{}}
                  mutators={arrayMutators}
                  onSubmit={() => {}}
                  render={({ handleSubmit }) => {
                    transactionDateSubmit = handleSubmit;
                    return (
                      <Box>
                        <Field
                          aria-label={t(
                            'groupEmployees.enrollmentPage.transactionDate'
                          )}
                          component={SingleDatePicker}
                          label={labelHandler(
                            t('groupEmployees.enrollmentPage.transactionDate'),
                            true
                          )}
                          maxDate={maxDate}
                          name="transactionDate"
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <OnChange name="transactionDate">
                          {(value) => {
                            setTransactionDate(value);
                          }}
                        </OnChange>
                      </Box>
                    );
                  }}
                />
              )}

              <Box>
                {isLate && (
                  <FinalForm
                    initialValues={{}}
                    mutators={arrayMutators}
                    onSubmit={() => {}}
                    render={({ values, form, handleSubmit }) => {
                      qleReasonSubmit = handleSubmit;
                      return (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            my: 4,
                          }}
                        >
                          <Field
                            aria-label={t(
                              'groupEmployees.enrollmentPage.petAcquisitionDate'
                            )}
                            component={SingleDatePicker}
                            label={labelHandler(
                              t(
                                'groupEmployees.enrollmentPage.petAcquisitionDate'
                              ),
                              true
                            )}
                            name="acquisitionDate"
                            validate={required}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                          <OnChange name="acquisitionDate">
                            {(value) => {
                              setAcquisitionDate(value);
                            }}
                          </OnChange>
                          <Field
                            aria-label={t(
                              'groupEmployees.enrollmentPage.reasonLateEnrollment'
                            )}
                            component={InputDropdown}
                            label={labelHandler(
                              t(
                                'groupEmployees.enrollmentPage.reasonLateEnrollment'
                              ),
                              true
                            )}
                            name="reasonType"
                            options={ReasonType}
                            searchable={false}
                            validate={required}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                          <OnChange name="reasonType">
                            {(value) => {
                              setReasonType(value);
                            }}
                          </OnChange>
                          <Box maxWidth="20rem" ml={3}>
                            <Box fontSize={3} mt={3}>
                              {t(
                                'groupEmployees.enrollmentPage.provideProofEvent'
                              )}
                              <br />
                              {t(
                                'groupEmployees.enrollmentPage.ifYouAdpotedPet'
                              )}
                              <Box ml={6}>
                                <ul style={{ listStyleType: 'disc' }}>
                                  <li>
                                    {t(
                                      'groupEmployees.enrollmentPage.billBreeder'
                                    )}
                                  </li>
                                  <li>
                                    {t(
                                      'groupEmployees.enrollmentPage.invoiceShelter'
                                    )}
                                  </li>
                                </ul>
                              </Box>
                            </Box>
                          </Box>
                          <Field
                            aria-label={t(
                              'groupEmployees.enrollmentPage.lateEnrollmentPage.fileName'
                            )}
                            component={InputText}
                            label={labelHandler(
                              t(
                                'groupEmployees.enrollmentPage.lateEnrollmentPage.fileName'
                              )
                            )}
                            name="fileName"
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                          {values.fileName && (
                            <>
                              <Box fontSize={3} m={3} maxWidth="20rem">
                                {t(
                                  'groupEmployees.enrollmentPage.provideProofLegend'
                                )}
                              </Box>
                              <UploadFile
                                allowMultiple
                                employeeId={employeeId}
                                files={get(values, 'fileUpload', [])}
                                flow="lateEnrollment"
                                form={form}
                                groupId={get(groupResponse, 'groupId')}
                                name={values.fileName}
                                {...rest}
                              />
                              <OnChange name="fileUpload">
                                {(value) => {
                                  setFileUpload([...value]);
                                }}
                              </OnChange>
                            </>
                          )}
                        </Box>
                      );
                    }}
                  />
                )}
              </Box>
              {!isRenewals && (
                <Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.misstatementPetInfo'
                        )}
                      </Box>
                      {t(
                        'groupEmployees.enrollmentPage.addPetSection.agreeLegend'
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '20px 0 0 0',
                      }}
                    >
                      <InputCheckbox
                        input={{
                          name: 'agreeMpi',
                          value: agreeMPI ? '1' : '0',
                        }}
                        label={<Box ml={3}>{t('common.agree')}</Box>}
                        labelSx={{
                          fontSize: '12px',
                          margin: '0 0 3px',
                          ml: 0,
                          textTransform: 'capitalize',
                        }}
                        meta={{ error: '', touched: true }}
                        onChange={() => setAgreeMPI(!agreeMPI)}
                        wrapperSx={{ alignItems: 'center', display: 'flex' }}
                        {...rest}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.fraudWarning'
                        )}
                      </Box>
                      {config.canadaEnv && (
                        <Box>
                          {t(
                            'groupEmployees.enrollmentPage.addPetSection.fraudWarningLegend'
                          )}
                        </Box>
                      )}
                      {!config.canadaEnv && (
                        <Box>
                          I acknowledge and understand that any person who
                          knowingly provides false, incomplete, or misleading
                          information to an insurance company for the purpose of
                          defrauding the company is guilty of a crime and may be
                          subject to fines, imprisonment, and denial of
                          insurance benefits. See state specific fraud notice:{' '}
                          <a
                            href="https://www.petpartners.com/insurance-fraud-warnings"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Insurance Fraud Warnings
                          </a>
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '20px 0 0 0',
                      }}
                    >
                      <InputCheckbox
                        input={{ name: 'agreeFW', value: agreeFW ? '1' : '0' }}
                        label={<Box ml={3}>{t('common.agree')}</Box>}
                        labelSx={{
                          fontSize: '12px',
                          margin: '0 0 3px',
                          ml: 0,
                          textTransform: 'capitalize',
                        }}
                        meta={{ error: '', touched: true }}
                        onChange={() => setAgreeFW(!agreeFW)}
                        wrapperSx={{ alignItems: 'center', display: 'flex' }}
                        {...rest}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreement'
                        )}
                      </Box>
                      {t(
                        'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreementLegend'
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '20px 0 0 0',
                      }}
                    >
                      <InputCheckbox
                        input={{ name: 'agreeAA', value: agreeAA ? '1' : '0' }}
                        label={<Box ml={3}>{t('common.agree')}</Box>}
                        labelSx={{
                          fontSize: '12px',
                          margin: '0 0 3px',
                          ml: 0,
                          textTransform: 'capitalize',
                        }}
                        meta={{ error: '', touched: true }}
                        onChange={() => setAgreeAA(!agreeAA)}
                        wrapperSx={{ alignItems: 'center', display: 'flex' }}
                        {...rest}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.premiumChanges'
                        )}
                      </Box>
                      {t(
                        'groupEmployees.enrollmentPage.addPetSection.premiumChangesLegend'
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '20px 0 0 0',
                      }}
                    >
                      <InputCheckbox
                        input={{
                          name: 'premiumChanges',
                          value: premiumChanges ? '1' : '0',
                        }}
                        label={<Box ml={3}>{t('common.agree')}</Box>}
                        labelSx={{
                          fontSize: '12px',
                          margin: '0 0 3px',
                          ml: 0,
                          textTransform: 'capitalize',
                        }}
                        meta={{ error: '', touched: true }}
                        onChange={() => setPremiumChanges(!premiumChanges)}
                        wrapperSx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.eConsent'
                        )}
                      </Box>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'groupEmployees.enrollmentPage.addPetSection.eConsentLegend',
                            { url: E_CONSENT_URLS[url] || url }
                          ),
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        margin: '20px 0 0 0',
                      }}
                    >
                      <InputCheckbox
                        checked={agreeEC}
                        input={{ name: 'agreeEC', value: 'agreeEC' }}
                        label={<Box ml={3}>{t('common.agree')}</Box>}
                        labelSx={{
                          fontSize: '12px',
                          margin: '0 0 3px',
                          ml: 0,
                          textTransform: 'capitalize',
                        }}
                        meta={{ error: '', touched: true }}
                        onChange={() => setAgreeEC(true)}
                        type="radio"
                        wrapperSx={{ alignItems: 'center', display: 'flex' }}
                        {...rest}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        margin: '10px 0 0 40px',
                      }}
                    >
                      <InputCheckbox
                        checked={agreeEC === false}
                        input={{ name: 'disagreeEC', value: 'disagreeEC' }}
                        label={<Box ml={3}>{t('common.disagree')}</Box>}
                        labelSx={{
                          fontSize: '12px',
                          margin: '0 0 3px',
                          ml: 0,
                          textTransform: 'capitalize',
                        }}
                        meta={{ error: '', touched: true }}
                        onChange={() => setAgreeEC(false)}
                        type="radio"
                        wrapperSx={{ alignItems: 'center', display: 'flex' }}
                        {...rest}
                      />
                    </Box>
                  </Box>
                  {config.canadaEnv && (
                    <Box
                      sx={{
                        margin: '20px 10px 0',
                        padding: '0 20px',
                        textAlign: 'justify',
                      }}
                    >
                      <Box>
                        <Box fontWeight={600} mb="10px">
                          {t('component.caslDisclosure')}
                        </Box>
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: t(
                              'groupEmployees.enrollmentPage.addPetSection.eConsentLegendCanada',
                              {
                                url:
                                  i18n.language === 'fr'
                                    ? 'https://www.onepackplan.ca/fr/politique-protection-renseignements'
                                    : 'https://www.onepackplan.ca/privacy-policy',
                              }
                            ),
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'inline-flex',
                          flexDirection: 'row',
                          margin: '20px 0 0 0',
                        }}
                      >
                        <InputCheckbox
                          checked={agreeCASL}
                          input={{ name: 'agreeCASL', value: 'agreeCASL' }}
                          label={<Box ml={3}>{t('common.agree')}</Box>}
                          labelSx={{
                            fontSize: '12px',
                            margin: '0 0 3px',
                            ml: 0,
                            textTransform: 'capitalize',
                          }}
                          meta={{ error: '', touched: true }}
                          onChange={() => setAgreeCASL(true)}
                          type="radio"
                          wrapperSx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'inline-flex',
                          flexDirection: 'row',
                          margin: '10px 0 0 40px',
                        }}
                      >
                        <InputCheckbox
                          checked={agreeCASL === false}
                          input={{
                            name: 'agreeCASL',
                            value: 'agreeCASL',
                          }}
                          label={<Box ml={3}>{t('common.disagree')}</Box>}
                          labelSx={{
                            fontSize: '12px',
                            margin: '0 0 3px',
                            ml: 0,
                            textTransform: 'capitalize',
                          }}
                          meta={{ error: '', touched: true }}
                          onChange={() => setAgreeCASL(false)}
                          type="radio"
                          wrapperSx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  {!config.canadaEnv && (
                    <Box
                      sx={{
                        margin: '20px 10px 0',
                        padding: '0 20px',
                        textAlign: 'justify',
                      }}
                    >
                      <Box fontWeight={600} mb="10px">
                        {t('common.insurerDisclosureTitle')}
                      </Box>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html:  renderDisclosureContent() ,
                        }}
                      />
                      {/* <Box
                        dangerouslySetInnerHTML={{
                          __html: t('common.insurerDisclosure', {
                            url: config.insurerDisclosuresUrl || '#',
                          }),
                        }}
                      /> */}
                    </Box>
                  )}
                </Box>
              )}
              {(prevAddPet.length > 0 || newlyAddPet.length > 0) &&
                isRenewals && (
                  <Box>
                    <Box
                      sx={{
                        margin: '20px 10px 0',
                        padding: '0 20px',
                        textAlign: 'justify',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          margin: '20px 0 0 0',
                        }}
                      >
                        <InputCheckbox
                          input={{
                            name: 'agreeMpi',
                            value: agreeMPI ? '1' : '0',
                          }}
                          label={
                            <Box ml={3}>
                              I agree to all Terms and Conditions which can be
                              accessed{' '}
                              <Box
                                as="a"
                                onClick={() => setShowTCModal(true)}
                                sx={{
                                  color: `${theme.colors.accentSecondary}`,
                                }}
                              >
                                here
                              </Box>
                              .
                            </Box>
                          }
                          labelSx={{
                            fontSize: '15px',
                            fontWeight: 'normal',
                            letterSpacing: '0em',
                            margin: '0 0 3px',
                            ml: 0,
                            textTransform: 'normal',
                          }}
                          meta={{ error: '', touched: true }}
                          onChange={() => setAgreeMPI(!agreeMPI)}
                          wrapperSx={{ alignItems: 'center', display: 'flex' }}
                          {...rest}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        margin: '20px 10px 0',
                        padding: '0 20px',
                        textAlign: 'justify',
                      }}
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'groupEmployees.enrollmentPage.addPetSection.eConsentLegend',
                            { url: E_CONSENT_URLS[url] || '#' }
                          ),
                        }}
                      />
                      <Box
                        sx={{
                          display: 'inline-flex',
                          flexDirection: 'row',
                          margin: '20px 0 0 0',
                        }}
                      >
                        <InputCheckbox
                          checked={agreeEC}
                          input={{ name: 'agreeEC', value: 'agreeEC' }}
                          label={<Box ml={3}>{t('common.agree')}</Box>}
                          labelSx={{
                            fontSize: '12px',
                            margin: '0 0 3px',
                            ml: 0,
                            textTransform: 'capitalize',
                          }}
                          meta={{ error: '', touched: true }}
                          onChange={() => setAgreeEC(true)}
                          type="radio"
                          wrapperSx={{ alignItems: 'center', display: 'flex' }}
                          {...rest}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'inline-flex',
                          flexDirection: 'row',
                          margin: '10px 0 0 40px',
                        }}
                      >
                        <InputCheckbox
                          checked={agreeEC === false}
                          input={{ name: 'disagreeEC', value: 'disagreeEC' }}
                          label={<Box ml={3}>{t('common.disagree')}</Box>}
                          labelSx={{
                            fontSize: '12px',
                            margin: '0 0 3px',
                            ml: 0,
                            textTransform: 'capitalize',
                          }}
                          meta={{ error: '', touched: true }}
                          onChange={() => setAgreeEC(false)}
                          type="radio"
                          wrapperSx={{ alignItems: 'center', display: 'flex' }}
                          {...rest}
                        />
                      </Box>
                    </Box>
                    {!config.canadaEnv && (
                      <Box
                        sx={{
                          margin: '20px 10px 0',
                          padding: '0 20px',
                          textAlign: 'justify',
                        }}
                      >
                        <Box fontWeight={600} mb="10px">
                          {t('common.insurerDisclosureTitle')}
                        </Box>
                        <Box
                          dangerouslySetInnerHTML={{
                            __html:  renderDisclosureContent() ,
                          }}
                        />
                        {/* <Box
                          dangerouslySetInnerHTML={{
                            __html: t('common.insurerDisclosure', {
                              url: config.insurerDisclosuresUrl || '#',
                            }),
                          }}
                        /> */}
                      </Box>
                    )}
                  </Box>
                )}
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: ['center', null, 'space-between'],
                  my: 6,
                  width: '25rem',
                }}
              >
                <ToolbarButton
                  bg="accent"
                  isDisabled={loading}
                  label={t('common.previous')}
                  my={3}
                  onClick={() =>
                    navigate(
                      generateRedirectPath({
                        ...location,
                        queryParams: { [name]: 1 },
                      })
                    )
                  }
                  width="10rem"
                />
                <ToolbarButton
                  bg="accentSecondary"
                  isDisabled={loading}
                  label={t('common.submit')}
                  mr={0}
                  my={3}
                  onClick={() => {
                    onSubmit();
                  }}
                  submitting={loading}
                  width="10rem"
                />
              </Box>
            </Box>
            {showTCModal && (
              <Modal
                closeText="Okay"
                isOpen
                onClose={() => setShowTCModal(false)}
                title={t(
                  'groupEmployees.enrollmentPage.addPetSection.termsConditions'
                )}
              >
                <Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.misstatementPetInfo'
                        )}
                      </Box>
                      {t(
                        'groupEmployees.enrollmentPage.addPetSection.agreeLegend'
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.fraudWarning'
                        )}
                      </Box>
                      {config.canadaEnv && (
                        <Box>
                          {t(
                            'groupEmployees.enrollmentPage.addPetSection.fraudWarningLegend'
                          )}
                        </Box>
                      )}
                      {!config.canadaEnv && (
                        <Box>
                          I acknowledge and understand that any person who
                          knowingly provides false, incomplete, or misleading
                          information to an insurance company for the purpose of
                          defrauding the company is guilty of a crime and may be
                          subject to fines, imprisonment, and denial of
                          insurance benefits. See state specific fraud notice:{' '}
                          <a
                            href="https://www.petpartners.com/insurance-fraud-warnings"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Insurance Fraud Warnings
                          </a>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreement'
                        )}
                      </Box>
                      {t(
                        'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreementLegend'
                      )}
                    </Box>
                  </Box>
                </Box>
              </Modal>
            )}
            {showModal === true && (
              <Modal
                closeText={t('common.no')}
                isOpen
                onClose={() => {
                  setLoading(false);
                  setShowModal(false);
                }}
                onSubmit={() => {
                  setSubmitting(true);
                  onSummarySubmit();
                }}
                submitText={t('common.yes')}
                submitting={isSubmitting}
                title={t('groupEmployees.enrollmentPage.confirmSubmission')}
              >
                <Box>
                  {t('groupEmployees.enrollmentPage.agreeToEnroll')}
                  <Box
                    sx={{
                      display: 'flex',
                      fontWeight: 'bold',
                      justifyContent: 'center',
                      my: 5,
                    }}
                  >
                    {t('common.cannotBeUndone')}
                  </Box>
                  {t('common.wishToContinue')}
                </Box>
              </Modal>
            )}
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

EnrollmentSummary.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string,
};

EnrollmentSummary.defaultProps = {
  name: 'step',
};

export default EnrollmentSummary;
