import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '@basecomponents/Auth';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';
import config from '../../../config.json';

/**
 * @category Components
 * @param {boolean} mono
 * @returns {React.FC}
 */
const Logo = (props) => {
  const { i18n } = useTranslation()
  const { user } = useContext(AuthContext);
  const { mono, ...rest } = props;
  const { logos } = config;
  let mga = '';

  if (config.canadaEnv && (i18n.language === 'en' ||  i18n.language === 'caEn')) {
    mga = 'ptz_sodaco_en';
  } else if (config.canadaEnv && i18n.language === 'fr') {
    mga = 'ptz_sodaco'
  } else {
    mga = get(user, 'customMga', 'default').toLowerCase();
  }
  if (mono) {
    return <Icon svg={logos[mga].mono} {...rest} />;
  }
  return <Icon svg={logos[mga].color} {...rest} />;
};

Logo.propTypes = {
  mono: PropTypes.bool,
};

Logo.defaultProps = {
  mono: false,
};

export default Logo;
