import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import styled from 'styled-components';

const EllipsisBox = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * @category BaseComponents
 * @param {React.ReactNode} children
 * @param {*} props
 * @returns {React.FC}
 */
const Truncate = ({ children, ...props }) => (
  <EllipsisBox {...props}>{children}</EllipsisBox>
);

Truncate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Truncate;
