import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Box from '@basecomponents/Box';
import { Field, Form } from 'react-final-form';
import { capitalCase } from 'change-case';
import AutoSave from '@basecomponents/AutoSave';
import { useTranslation } from 'react-i18next';
import Input from '../../Input';
import InputDropdown from '../../Dropdown';
import DatePicker from '../../DatePicker';
import SingleDatePicker from '../../SingleDatePicker';
import MultiSelectDropdown from '../../MultiSelectDropdown';
import DropdownQuery from './DropdownQuery';

const filterLabelSx = {
  color: 'white',
  fontSize: 2,
};

/**
 * @category BaseComponents
 * @param {Array<Object>} filters
 * @param {Object} filterValues
 * @param {function} onChange
 * @param {*} rest
 * @returns {React.FC}
 */
const ListFilterSection = ({ filters, filterValues, onChange, ...rest }) => {
  const { t } = useTranslation();
  const filtersPerRow = 4;
  const filterWidth = [
    '100%',
    null,
    null,
    filters.length > filtersPerRow
      ? `calc(100%/${filtersPerRow})`
      : `calc(100%/${filters.length})`,
  ];

  return (
    <Form
      initialValues={filterValues}
      onSubmit={noop}
      render={() => {
        return (
          <>
            <AutoSave
              save={(all, changed, added, deleted, values) =>
                onChange({ params: values })
              }
            />
            <Box
              alignItems="center"
              display="flex"
              flexDirection={{ _: 'column', md: 'row' }}
              fontSize={2}
              justifyContent="center"
              sx={{ flexWrap: 'wrap' }}
            >
              {filters.map((filter) => {
                let result = null;
                const {
                  label,
                  loadOptions,
                  name,
                  options,
                  resolver,
                  resolverParams,
                  type,
                } = filter;
                const filterLabel = label || t(`filters.${name}`, t(`metadata.${name}`, capitalCase(name)));

                const placeholder = ['Input'].includes(type)
                  ? t('component.search')
                  : t('component.select');

                switch (type) {
                  case 'Input': {
                    result = (
                      <Field
                        aria-label={filterLabel}
                        component={Input}
                        label={filterLabel}
                        labelSx={filterLabelSx}
                        name={name}
                        placeholder={placeholder}
                        sx={{
                          borderRadius: 1,
                        }}
                        {...rest}
                      />
                    );
                    break;
                  }

                  case 'Dropdown':
                  case 'DropdownRange': {
                    result = (
                      <Field
                        aria-label={filterLabel}
                        component={InputDropdown}
                        isClearable
                        label={filterLabel}
                        labelSx={filterLabelSx}
                        loadOptions={loadOptions}
                        name={name}
                        options={options}
                        placeholder={placeholder}
                        {...rest}
                      />
                    );
                    break;
                  }
                  case 'UserDropdown': {
                    result = (
                      <Field
                        aria-label={filterLabel}
                        component={InputDropdown}
                        isClearable
                        label={filterLabel}
                        labelSx={filterLabelSx}
                        loadOptions={loadOptions}
                        name={name}
                        options={options}
                        placeholder={t('component.typeToSearch')}
                        {...rest}
                      />
                    );
                    break;
                  }

                  case 'DropdownBoolean': {
                    result = (
                      <Field
                        aria-label={filterLabel}
                        component={InputDropdown}
                        isClearable
                        label={filterLabel}
                        labelSx={filterLabelSx}
                        name={name}
                        options={[
                          { canEnglishValue: null, frenchValue: 'Oui', label: t('common.yes'), value: '1' },
                          { canEnglishValue: null, frenchValue: 'Non', label: t('common.no'), value: '0' },
                        ]}
                        placeholder={placeholder}
                        {...rest}
                      />
                    );
                    break;
                  }

                  case 'DropdownQuery': {
                    result = (
                      <DropdownQuery
                        label={filterLabel}
                        labelSx={filterLabelSx}
                        name={name}
                        placeholder={placeholder}
                        resolver={resolver}
                        resolverParams={resolverParams}
                      />
                    );
                    break;
                  }
                  case 'MultiselectDropdown': {
                    result = (
                      <Field
                        aria-label={filterLabel}
                        component={MultiSelectDropdown}
                        isClearable
                        label={filterLabel}
                        labelSx={filterLabelSx}
                        name={name}
                        options={options}
                        placeholder={placeholder}
                        {...rest}
                      />
                    );
                    break;
                  }
                  case 'DatePicker': {
                    result = (
                      <Field
                        aria-label={filterLabel}
                        component={DatePicker}
                        label={filterLabel}
                        labelSx={filterLabelSx}
                        name={name}
                        {...rest}
                      />
                    );
                    break;
                  }
                  case 'Date': {
                    result = (
                      <Field
                        aria-label={filterLabel}
                        component={SingleDatePicker}
                        filterValues={filterValues}
                        label={filterLabel}
                        labelSx={filterLabelSx}
                        name={name}
                        placeholder={placeholder}
                        {...rest}
                      />
                    );
                    break;
                  }
                  default: {
                    result = null;
                  }
                }
                return (
                  <Box key={name} minWidth={200} p={5} width={filterWidth}>
                    {result}
                  </Box>
                );
              })}
            </Box>
          </>
        );
      }}
    />
  );
};

ListFilterSection.defaultProps = {
  filters: [],
  filterValues: {},
};

ListFilterSection.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  filterValues: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
};

export default ListFilterSection;
