import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import InputDropdown from '@basecomponents/Dropdown';
import TextArea from '@basecomponents/TextArea';
import { Field } from 'react-final-form';
import { labelHandler } from '@src/utilities/label-utils';
import config from '@src/config.json';
import FileUploadInput from '@basecomponents/FileUploadInput';
import stripSpecialCharacters from '@src/utilities/strip-special-characters';
import GetData from '@src/utilities/get-data';
import { get } from 'lodash';
import UploadedDocumentsSection from '@petcomponents/UploadedDocumentsSection';
import { useTranslation } from 'react-i18next';

const Notes = ({
  validators,
  groupId,
  fileUrls,
  form,
  uploadedFiles,
  values,
  payrollCycles,
  ...rest
}) => {
  const filesCount = useRef([]);
  const { t } = useTranslation()
  const { apiData: constantsData = {} } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: 'BillingNotesCategory' }])
  );

  useEffect(() => {
    if (fileUrls.length > 0) {
      filesCount.current = fileUrls;
    }
  }, [fileUrls]);

  return (
    <Box>
      <Field
        aria-label={t('notes.categories')}
        component={InputDropdown}
        data-testid="category"
        initialValue="uploadFile"
        label={labelHandler(t('notes.categories'), true)}
        name="categories"
        options={constantsData?.BillingNotesCategory}
        validate={validators}
        {...rest}
      />
      <Field
        aria-label={t('notes.payrollCycle')}
        component={InputDropdown}
        data-testid="payrollCycles"
        initialValue="uploadFile"
        label={labelHandler(t('notes.payrollCycle'), false)}
        name="payrollScheduleId"
        options={payrollCycles}
        {...rest}
      />
      <Field
        aria-label={t('lifeEvents.details.comment')}
        component={TextArea}
        label={labelHandler(t('lifeEvents.details.comment'), true)}
        maxLength={250}
        name="comment"
        textAreaSx={{ minHeight: 100 }}
        validate={validators}
        {...rest}
      />
      <Box
        sx={{
          fontSize: '14px',
          mt: 3,
          textAlign: 'end',
        }}
      >
        {values?.comment?.length ?? 0}/250
      </Box>
      <Field
        acceptedFileTypes={[
          'application/pdf',
          'image/jpg',
          'image/jpeg',
          'image/png',
          'application/vnd.ms-excel',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ]}
        allowMultiple
        allowRevert
        aria-label={t('common.dragAndDropAdd')}
        bucket={config.amplify.Storage.AWSS3.bucket}
        component={FileUploadInput}
        credits={false}
        customPrefix={{ public: '' }}
        data-cy="fileUrls"
        fileRenameFunction={({ basename, extension }) => {
          const baseStripped = stripSpecialCharacters(basename);
          const timestamp = new Date().getTime();
          const filename = `${baseStripped}${extension}`;
          return `${groupId}/billNotes/uploadedFiles/${timestamp}/${filename}`;
        }}
        fileValidateTypeDetectType={(source, type) =>
          new Promise((resolve) =>
            source?.name?.split('.').pop() === 'edi'
              ? resolve('application/edi-x12')
              : resolve(type)
          )
        }
        labelIdle={`
                      <div class="filepond--heading">
                       ${t('common.dragAndDrop')}
                      </div>
                      <div class="filepond--or">
                        ${t('common.or')}
                      </div>
                      <div
                       class="filepond--label-action"
                      >
                        ${t('common.addFiles')}
                      </div>
                      <div class="filepond--sub-heading" style="margin-top:20px">
                        ${t('common.fileSizeLimit')}
                      </div>
                    `}
        maxFiles={6}
        maxFileSize="50MB"
        name="fileUrls"
        onRemoveComplete={({ name }) => {
          filesCount.current.splice(
            filesCount.current.findIndex((f) => f === name),
            1
          );
          return form.change('fileUrls', filesCount.current);
        }}
        onUploadComplete={async ({ fileName }) => {
          filesCount.current.push(fileName);
          return form.change('fileUrls', filesCount.current);
        }}
        wrapperSx={{
          '.filepond--drop-label': {
            height: 'auto',
            padding: 3,
            py: [5, null, null, null, 8],
            width: '100%',
          },
          '.filepond--heading': {
            color: 'text.primary',
            fontSize: 5,
            fontWeight: 'bold',
            lineHeight: '1em',
          },
          '.filepond--label-action': (p) => ({
            mt: 5,
            textDecoration: 'none',
            ...p.variants.buttons.primary,
          }),
          '.filepond--or': {
            color: 'text.primary',
            fontSize: 2,
            fontWeight: 'bold',
            mt: 5,
            textTransform: 'uppercase',
          },
          '.filepond--supported': {
            fontSize: 2,
            mt: 5,
          },
          mb: 2,
          mt: 20,
          padding: 3,
          width: '25rem',
        }}
        {...rest}
      />
      {uploadedFiles.length > 0 && filesCount?.current?.length > 0 && (
        <UploadedDocumentsSection
          deleted={(s3Key) => s3Key !== null}
          documentsList={
            Array.isArray(get(values, `fileUrls`, [])) &&
            get(values, `fileUrls`, []).map((file) => {
              const documentName = file.split('/').pop();
              return {
                attachmentName: documentName,
                s3Key: file,
              };
            })
          }
          fileSx={{
            flexDirection: 'column!important',
          }}
          setData={(fileName, s3Key) => {
            const updatedFiles = get(values, `fileUrls`, []).filter(
              (file) => file !== s3Key
            );
            form.change(`fileUrls`, updatedFiles);
          }}
          setSignUrl
          {...rest}
        />
      )}
    </Box>
  );
};

Notes.defaultProps = {
  fileUrls: [],
  form: {},
  groupId: '',
  payrollCycles: [],
  uploadedFiles: [],
  validators: () => { },
  values: {},
};

Notes.propTypes = {
  fileUrls: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
  groupId: PropTypes.string,
  payrollCycles: PropTypes.arrayOf(PropTypes.shape({})),
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  validators: PropTypes.func,
  values: PropTypes.shape({
    comment: PropTypes.string,
  }),
};

export default Notes;
