import { get } from 'lodash';
import GetData from './get-data';
import { USER_ROLE } from './constants';

const getEmployeeStatus = (userId, role) => {
  const { apiData: { content: userData = [] } = {} } = GetData(
    'get-all-employee',
    JSON.stringify({
      skipActiveCheck: true,
      sort: 'created,desc',
      userId,
    }),
    ![
      USER_ROLE.EMPLOYEE,
      USER_ROLE.TERMINATED_EMPLOYEE,
      USER_ROLE.RENEWAL_POLICY_DELETED,
    ].includes(role),
    'cache-first'
  );
  const activeUser = userData.filter((v) => v.currentGroup.isActiveGroup);
  const inActiveData =
    userData.length > 1
      ? userData.filter(
          (v) =>
            get(v, 'user.inactiveDate') !== null &&
            new Date(get(v, 'user.inactiveDate')) > new Date()
        )
      : userData;
  const currentUser = activeUser.length > 0 ? activeUser : inActiveData;
  const groupNumber = get(
    currentUser,
    '0.currentGroup.groupUniqueIdentifier',
    ''
  ).split('-');
  // TODO: Make this recognize which is which policy instead of 0 and 1.
  return {
    employeeId: get(currentUser, '0.employeeId', null),
    isCurrentUserActive: activeUser.length > 0,
    enrollmentStartDate: get(
      currentUser,
      '0.employeeDates.enrollmentStartDate',
      new Date()
    ), // employeePlanConfirmation: get(activeUser, '0.employeePlanConfirmation'),
    groupStatus: get(currentUser, '0.groupStatus', ''),
    renewalEmployeeId: get(currentUser, '0.renewalEmployee.employeeId', null),
    status: get(currentUser, '0.status', '').toLowerCase(),
    userLoginRootEmployeeId:
      get(currentUser, '0.rootEmployeeId', null) ||
      get(currentUser, '0.employeeId', null),
    empId: get(currentUser, '0.empId', null),
    groupNumber: `${groupNumber[0]}-${groupNumber[1]}`,
  };
};

export default getEmployeeStatus;
