import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import Arial from '../../../../assets/fonts/Arial.woff';
import ArialBold from '../../../../assets/fonts/ArialBold.woff';
import ASPCA from '../../../../images/brands/aspca.png';
// import PETCO from '../../../images/brands/petco.png';
import GeneratedPDFViewer from '../../GeneratedPDFViewer';

Font.register({ family: 'Arial', src: Arial });
Font.register({ family: 'ArialBold', src: ArialBold });

const MasterPolicyGenerator = ({
  product,
  groupName,
  groupNumber,
  planChoiceId,
  planId,
  coverageFromDate,
  renewalDate,
  stateOfIssue,
}) => {
  const productName = () => {
    if (product === 'Accident Only') {
      return 'Accident Coverage';
    }
    return 'Accident and Illness Coverage';
  };

  const icon = () => {
    // if (groupNumber.includes('GFPI')) {
    //   if (variation === 'demo') {
    //     return PETCO;
    //   }
    //   return FIGO;
    // }
    // if (variation === 'demo') {
    return ASPCA;
    // }
    // return PPI;
  };

  const styles = StyleSheet.create({
    common: {
      backgroundColor: 'white',
      color: 'black',
      fontFamily: 'Arial',
      padding: '50px 50px 20px 50px',
      userSelect: 'none !important',
    },
    fontLargeBold: {
      fontFamily: 'ArialBold',
      fontSize: '20px',
    },
    fontMediumBold: {
      fontFamily: 'ArialBold',
      fontSize: '12px',
    },
    fontNormal: {
      fontFamily: 'Arial',
      fontSize: '10px',
    },
    fontNormalBold: {
      fontFamily: 'ArialBold',
      fontSize: '10px',
    },
    fontXLargeBold: {
      fontFamily: 'ArialBold',
      fontSize: '24px',
    },
    positionAdminLogo: {
      width: '153px',
    },
    positionContentTitle: {
      margin: '0 0 10px 0',
      textAlign: 'center',
    },
    positionListItem: {
      margin: '0 0 0 17px',
    },
    positionPage1Header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    positionPage1HeaderRightSection: {
      textAlign: 'right',
    },
    positionPage1HeaderSection: {
      display: 'flex',
      flexDirection: 'column',
      width: '130px',
      wordWrap: 'break-word',
    },
    positionParagraph: {
      margin: '0 0 10px 0',
      textAlign: 'justify',
      textJustify: 'inter-word',
    },
    positionSecondaryTitle: {
      margin: '10px 0 10px 0',
      textAlign: 'center',
    },
    positionSignature: {
      width: '120px',
    },
    positionSignatureSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    positionSignatureSubSection: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    positionSignatureView: {
      alignItems: 'center',
      display: 'flex',
      height: '70px',
      justifyContent: 'flex-end',
      width: '150px',
    },
    positionSubTitle: {
      margin: '10px 0 10px 0',
      textAlign: 'center',
    },
    positionSummarySection: {
      margin: '10px 0 10px 0',
    },
    positionTableOfContents: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    positionTableSpacing: {
      marginTop: '20px',
    },
    positionTitle: {
      margin: '30px 0 10px 0',
      textAlign: 'center',
    },
  });

  const baseMasterPolicy = () => (
    <Document>
      <Page style={styles.common}>
        <View>
          <View style={styles.positionPage1Header}>
            <View style={styles.positionPage1HeaderSection} />
            <View>
              <Image src={icon()} style={styles.positionAdminLogo} />
            </View>
            <View
              style={[
                styles.positionPage1HeaderSection,
                styles.positionPage1HeaderRightSection,
              ]}
            />
          </View>
          <View style={styles.positionTitle}>
            <Text style={styles.fontXLargeBold}>CRUM AND FORSTER</Text>
          </View>
          <View style={styles.positionSecondaryTitle}>
            <Text style={styles.fontLargeBold}>Group Pet Insurance Policy</Text>
            <Text style={styles.fontLargeBold}>{productName()}</Text>
          </View>
          <View style={styles.positionSummarySection}>
            <Text style={styles.fontNormalBold}>
              GROUP POLICYHOLDER:{' '}
              <Text style={styles.fontNormal}>{groupName}</Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              GROUP NUMBER: <Text style={styles.fontNormal}>{groupNumber}</Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              MINIMUM PARTICIPATION REQUIREMENT:{' '}
              <Text style={styles.fontNormal}>
                2 Enrolled{' '}
                <Text style={styles.fontNormalBold}>Eligible Persons</Text>
              </Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              DATE OF ISSUE:{' '}
              <Text style={styles.fontNormal}>{coverageFromDate}</Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              RENEWAL DATE:{' '}
              <Text style={styles.fontNormal}>
                {`Renewals occur annually beginning ${renewalDate}`}
              </Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              STATE OF ISSUE:{' '}
              <Text style={styles.fontNormal}>{stateOfIssue}</Text>
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              Crum and Forster Insurance (hereafter referred to as{' '}
              <Text style={styles.fontNormalBold}>We, Our</Text> or{' '}
              <Text style={styles.fontNormalBold}>Us</Text>) agrees to pay group
              insurance <Text style={styles.fontNormalBold}>Coverage</Text> with
              respect to each{' '}
              <Text style={styles.fontNormalBold}>Eligible Person</Text>{' '}
              according and subject to the terms and conditions of this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>. Benefits
              are payable in United States dollars only. The{' '}
              <Text style={styles.fontNormalBold}>Coverage</Text> and insurance
              provisions approved under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text> are
              contained in the{' '}
              <Text style={styles.fontNormalBold}>Certificate</Text> of
              Insurance (hereafter referred to as{' '}
              <Text style={styles.fontNormalBold}>“Certificate”</Text>).
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              This <Text style={styles.fontNormalBold}>Group Policy</Text> is
              issued to the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> in
              consideration of the{' '}
              <Text style={styles.fontNormalBold}>Group</Text> application and
              payment of premiums, as provided herein, to take effect as of the
              date of issue. A copy of the completed{' '}
              <Text style={styles.fontNormalBold}>Group</Text> application is
              attached. This{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text> will
              terminate as described herein. Date of issue and termination will
              begin and end at 12:01 A.M. Standard Time at the address of the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>. The{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> is
              not <Text style={styles.fontNormalBold}>Our</Text> agent for any
              purpose under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>.
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              <Text style={styles.fontNormalBold}>
                This Policy includes any Group applications, certificates, and
                riders.
              </Text>{' '}
              This <Text style={styles.fontNormalBold}>Group Policy</Text> is
              issued by <Text style={styles.fontNormalBold}>Us</Text> and
              delivered in the state shown above and governed by the laws of
              that state. All terms are defined, and benefits are provided, in
              accordance with the terms, conditions and provisions of these
              documents, and applicable state laws.{' '}
              <Text style={styles.fontNormalBold}>
                All terms are as defined in the Certificate, unless otherwise
                shown herein.
              </Text>
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              Signed by Crum and Forster Insurance
            </Text>
          </View>
          <View style={styles.positionSubTitle}>
            <Text style={styles.fontNormalBold}>
              CRUM AND FORSTER INSURANCE
            </Text>
          </View>

          <View style={styles.positionSubTitle}>
            <Text style={styles.fontMediumBold}>NON-PARTICIPATING</Text>
            <Text style={styles.fontMediumBold}>
              This Policy does not share in Our profits or surplus earnings.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <GeneratedPDFViewer
      groupNumber={groupNumber}
      planChoiceId={planChoiceId}
      planId={planId}
      product={product}
    >
      {baseMasterPolicy()}
    </GeneratedPDFViewer>
  );
};

MasterPolicyGenerator.propTypes = {
  address: PropTypes.shape({
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
  }).isRequired,
  coverageFromDate: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  groupNumber: PropTypes.string.isRequired,
  planChoiceId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  renewalDate: PropTypes.string.isRequired,
  stateOfIssue: PropTypes.string.isRequired,
};

export default MasterPolicyGenerator;
