const eligibiltyRules = {
  fields: [
    {
      label: 'New Hire Waiting Period (Days)',
      name: 'newHireWaitingPeriod',
      type: 'InputNumber',
    },
    {
      label: 'First of Month Following Waiting Period',
      name: 'isFirstFollowingWaitingPeriod',
      type: 'Checkbox',
    },
    {
      label: 'Enrollment Period (Days)',
      name: 'enrollmentPeriod',
      type: 'InputNumber',
    },
  ],
  name: 'eligibility-rule',
  title: 'Eligibilty Rule',
  type: 'Form',
};

export default eligibiltyRules;
