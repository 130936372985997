import React, { useEffect, useState, useRef } from 'react';
import Moment from 'moment';
import { Field, Form as FinalForm } from 'react-final-form';
import InputText from '@basecomponents/Input';
import PropTypes from 'prop-types';
import { get, orderBy } from 'lodash';
import Box from '@basecomponents/Box';
import { ApolloConsumer } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { required } from '../../../../../utilities/validators';
import { labelHandler, labelUtils } from '../../../../../utilities/label-utils';
import Table from '../../../../BaseComponents/Table';
import TableWrapper from '../../../../BaseComponents/TableWrapper';
import listingConfig from '../../../../../utilities/listing-config';
import InputDate from '../../../../BaseComponents/InputDate';
import {
  payrollScheduleBeforeAndAfterDays,
  composeValidations,
  dateBeforeAndAfterDays,
} from '../../../../../utilities/validations';
import Spinner from '../../../../BaseComponents/Spinner';
import dateUtils from '../../../../../utilities/date-utils';
import ToolbarButton from '../../../../BaseComponents/ToolbarButton';
import ButtonGroups from '../../../../BaseComponents/ButtonGroups';
import Modal from '../../../../BaseComponents/Modal';
import PaymentHistory from '../../../GroupDetails/PaymentHistory';
import InputMoney from '../../../../BaseComponents/InputMoney';
import remoteActionQuery from '../../../../../graphql/queries/remote-action.gql';
import useSnackbar from '../../../../../utilities/use-snackbar';
import config from '../../../../../config.json';

const commonFieldSxHalf = {
  padding: 3,
  width: '25rem',
};

const commonDisabledFieldSx = {
  'div > div': {
    backgroundColor: 'transparent !important',
    border: 0,
    'div > button': {
      display: 'none',
    },
  },
  opacity: 1,
  width: '15rem',
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value || {};
  });
  return ref.current;
};

/**
 * @category Group
 * @param {boolean} actionDisabled
 * @param {Object} values
 * @param {function} change
 * @param {boolean} chasePayment
 * @param {Array<Object>} chaseStatus
 * @param {Array<Object>} groupBillingData
 * @param {string} groupId
 * @param {boolean} loading
 * @param {Array<Object>} payrollData
 * @param {boolean} readOnly
 * @param {function} refetch
 * @param {boolean} showGeneratedPayrollSchedule
 * @param {*} rest
 * @returns {React.FC}
 */
const PayrollSchedule = ({
  actionDisabled,
  values,
  change,
  chasePayment,
  chaseStatus,
  groupBillingData,
  groupId,
  loading,
  payrollData,
  readOnly,
  refetch,
  showGeneratedPayrollSchedule,
  ...rest
}) => {
  const { t, i18n } = useTranslation()
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const {
    billingCycle,
    firstCoverageDate,
    firstPayrollDate,
    billDaysPriorToPayroll,
    firstBillCreationDate,
    firstDueDate,
  } = values;
  const type = readOnly ? 'billingCalender' : 'groupBillingInformationSection';
  const list = listingConfig(type);
  const [payrollSchedule, setPayrollSchedule] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [groupPaymentRecordId, setGroupPaymentRecordId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const prevBilling = usePrevious({
    billDaysPriorToPayroll,
    billingCycle,
    firstCoverageDate,
    firstPayrollDate,
    firstBillCreationDate,
    firstDueDate,
    loading,
  });

  useEffect(() => {
    if (
      prevBilling?.firstPayrollDate?.toString() !==
        firstPayrollDate?.toString() ||
      prevBilling?.firstCoverageDate?.toString() !==
        firstCoverageDate?.toString() ||
      prevBilling?.firstBillCreationDate?.toString() !== firstBillCreationDate?.toString() ||
      prevBilling?.firstDueDate?.toString() !== firstDueDate?.toString() ||
      prevBilling?.billingCycle !== billingCycle ||
      prevBilling?.billDaysPriorToPayroll !== billDaysPriorToPayroll ||
      prevBilling?.loading !== loading
    ) {
      if (!isLoading) {
        setIsLoading(true);
      }
      if (!loading) {
        const getTableData = () => {
          const { billingCycle: billingCycleFormValues } = values;
          const addTableFields = [];

          if (readOnly) {
            payrollData.forEach((data) =>
              addTableFields.push([
                <Box>{data?.cycleNo}</Box>,
                <Box>{(config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getUTCDateOnlyCA(data.startDate) : dateUtils.getUTCDateOnlyUS(data.startDate))}</Box>,
                <Box>{(config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getUTCDateOnlyCA(data.endDate) : dateUtils.getUTCDateOnlyUS(data.endDate))}</Box>,
                <Box>{(config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getUTCDateOnlyCA(data.payrollCalendarStartDate) : dateUtils.getUTCDateOnlyUS(data.payrollCalendarStartDate))}</Box>,
                <Box>{(config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getUTCDateOnlyCA(data.payrollCalendarEndDate) : dateUtils.getUTCDateOnlyUS(data.payrollCalendarEndDate))}</Box>,
                <Box>{(config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getUTCDateOnlyCA(data.generatedPaidDate) : dateUtils.getUTCDateOnlyUS(data.generatedPaidDate))}</Box>,
                <Box>{(config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getUTCDateOnlyCA(data.paidDate) : dateUtils.getUTCDateOnlyUS(data.paidDate))}</Box>,
                <Box>{(config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getUTCDateOnlyCA(data.generatedBillCreationDate) : dateUtils.getUTCDateOnlyUS(data.generatedBillCreationDate))}</Box>,
                <Box>{(config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getUTCDateOnlyCA(data.billCreationDate) : dateUtils.getUTCDateOnlyUS(data.billCreationDate))}</Box>,
                <Box>{(config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getUTCDateOnlyCA(data.generatedDueDate) : dateUtils.getUTCDateOnlyUS(data.generatedDueDate))}</Box>,
                <Box>{(config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getUTCDateOnlyCA(data.dueDate) : dateUtils.getUTCDateOnlyUS(data.dueDate))}</Box>,
              ])
            );
          } else {
            Array(Number(billingCycleFormValues))
              .fill()
              .forEach((_, index) => {
                addTableFields.push([
                  <Field
                    key={index}
                    aria-label={index + 1}
                    component={InputText}
                    disabled
                    name={`payrollSchedule[${index}].cycleNo`}
                    wrapperSx={{
                      ...commonDisabledFieldSx,
                      'div > input': {
                        backgroundColor: 'transparent',
                        border: 0,
                      },
                      width: 'auto',
                    }}
                    {...rest}
                  />,
                  <Field
                    key={index}
                    component={InputDate}
                    disabled
                    name={`payrollSchedule[${index}].startDate`}
                    wrapperSx={commonDisabledFieldSx}
                    {...rest}
                  />,
                  <Field
                    key={index}
                    component={InputDate}
                    disabled
                    name={`payrollSchedule[${index}].endDate`}
                    wrapperSx={commonDisabledFieldSx}
                    {...rest}
                  />,
                  <Field
                    key={index}
                    component={InputDate}
                    name={`payrollSchedule[${index}].payrollCalendarStartDate`}
                    validate={composeValidations(required)}
                    wrapperSx={{ ...commonFieldSxHalf, border: 0 }}
                    {...rest}
                  />,
                  <Field
                    key={index}
                    component={InputDate}
                    name={`payrollSchedule[${index}].payrollCalendarEndDate`}
                    validate={composeValidations(required, dateBeforeAndAfterDays)}
                    wrapperSx={{ ...commonFieldSxHalf, border: 0 }}
                    {...rest}
                  />,
                  <Field
                    key={index}
                    component={InputDate}
                    disabled
                    name={`payrollSchedule[${index}].generatedPaidDate`}
                    wrapperSx={commonDisabledFieldSx}
                    {...rest}
                  />,
                  <Field
                    key={index}
                    component={InputDate}
                    disabled={
                      new Date(get(payrollData, `${index}.paidDate`, new Date())) <
                      new Date() || readOnly
                    }
                    name={`payrollSchedule[${index}].paidDate`}
                    validate={composeValidations(
                      required,
                      payrollScheduleBeforeAndAfterDays
                    )}
                    wrapperSx={{ ...commonFieldSxHalf, border: 0 }}
                    {...rest}
                  />,
                  <Field
                    key={index}
                    component={InputDate}
                    disabled
                    name={`payrollSchedule[${index}].generatedBillCreationDate`}
                    wrapperSx={commonDisabledFieldSx}
                    {...rest}
                  />,
                  <Field
                    key={index}
                    component={InputDate}
                    disabled={
                      new Date(get(payrollData, `${index}.paidDate`, new Date())) <
                      new Date() || readOnly
                    }
                    name={`payrollSchedule[${index}].billCreationDate`}
                    validate={composeValidations(
                      required,
                      payrollScheduleBeforeAndAfterDays
                    )}
                    wrapperSx={{ ...commonFieldSxHalf, border: 0 }}
                    {...rest}
                  />,
                  <Field
                    key={index}
                    component={InputDate}
                    disabled
                    name={`payrollSchedule[${index}].generatedDueDate`}
                    wrapperSx={commonDisabledFieldSx}
                    {...rest}
                  />,
                  <Field
                    key={index}
                    component={InputDate}
                    disabled={
                      new Date(get(payrollData, `${index}.paidDate`, new Date())) <
                      new Date() || readOnly
                    }
                    name={`payrollSchedule[${index}].dueDate`}
                    validate={composeValidations(required)}
                    wrapperSx={{ ...commonFieldSxHalf, border: 0 }}
                    {...rest}
                  />,
                  <Field
                    key={index}
                    component={InputText}
                    disabled
                    name={`payrollSchedule[${index}].payrollScheduleId`}
                    wrapperSx={{ display: 'none' }}
                    {...rest}
                  >
                    {({ input }) => (
                      <div>
                        <div className="field-wrapper">
                          <input
                            {...input}
                            name={`payrollSchedule[${index}].payrollScheduleId`}
                            type="hidden"
                          />
                        </div>
                      </div>
                    )}
                  </Field>,
                ]);
              });
          }
          setPayrollSchedule(addTableFields);
        };
        getTableData();
      }
    }
  }, [
    firstPayrollDate,
    firstCoverageDate,
    billingCycle,
    billDaysPriorToPayroll,
    firstBillCreationDate,
    firstDueDate,
    loading,
  ]);

  useEffect(() => {
    if (showGeneratedPayrollSchedule || payrollData.length === 0) {
      const payrollData = [];
      change(`payrollSchedule`, []);
      Array(Number(billingCycle))
        .fill()
        .forEach((_, index) => {
          let payrollGaps = {};
          switch (billingCycle) {
            case '12':
              payrollGaps = { frequency: 1, timePeriod: 'months' };
              break;
            case '24':
              payrollGaps = { frequency: 15, timePeriod: 'days' };
              break;
            case '26':
              payrollGaps = { frequency: 14, timePeriod: 'days' };
              break;
            case '52':
              payrollGaps = { frequency: 7, timePeriod: 'days' };
              break;
            default:
              payrollGaps = { frequency: 1, timePeriod: 'months' };
              break;
          }

          let startDate = null;
          let payrollCalendarStartDate = null;

          if (billingCycle !== '24') {
            startDate = new Date(
              Moment(firstPayrollDate)
                .add(payrollGaps.frequency * index, payrollGaps.timePeriod)
                .format('MM/DD/YYYY')
            );
            payrollCalendarStartDate = new Date(
              Moment(firstCoverageDate)
                .add(payrollGaps.frequency * index, payrollGaps.timePeriod)
                .format('MM/DD/YYYY')
            );
          } else if (index % 2 === 0) {
            startDate = new Date(
              Moment(firstPayrollDate)
                .add(Math.floor(index / 2), 'months')
                .format('MM/DD/YYYY')
            );
            payrollCalendarStartDate = new Date(
              Moment(firstCoverageDate)
                .add(Math.floor(index / 2), 'months')
                .format('MM/DD/YYYY')
            );
          } else {
            const previousStartDate = Moment(firstPayrollDate).add(
              Math.floor(index / 2),
              'months'
            );
            const nextStartDate = Moment(firstPayrollDate).add(
              Math.floor(index / 2) + 1,
              'months'
            );

            const firstCoveragePreviousStartDate = Moment(
              firstCoverageDate
            ).add(Math.floor(index / 2), 'months');
            const firstCoverageNextStartDate = Moment(firstCoverageDate).add(
              Math.floor(index / 2) + 1,
              'months'
            );

            startDate = new Date(
              Moment(firstPayrollDate)
                .add(Math.floor(index / 2), 'months')
                .add(
                  Math.ceil(nextStartDate.diff(previousStartDate, 'days') / 2),
                  'days'
                )
                .format('MM/DD/YYYY')
            );

            payrollCalendarStartDate = new Date(
              Moment(firstCoverageDate)
                .add(Math.floor(index / 2), 'months')
                .add(
                  Math.ceil(
                    firstCoverageNextStartDate.diff(
                      firstCoveragePreviousStartDate,
                      'days'
                    ) / 2
                  ),
                  'days'
                )
                .format('MM/DD/YYYY')
            );
          }

          let generatedPaidDate = null;
          let payrollCalendarEndDate = null;
          if (payrollGaps.timePeriod === 'months') {
            if (Moment(firstPayrollDate).date() === 1) {
              generatedPaidDate = new Date(
                Moment(firstPayrollDate)
                  .add(index, 'months')
                  .endOf('month')
                  .format('MM/DD/YYYY')
              );
            } else {
              generatedPaidDate = new Date(
                Moment(firstPayrollDate)
                  .add(index + 1, 'months')
                  .subtract(1, 'days')
                  .format('MM/DD/YYYY')
              );
            }
            if (Moment(firstCoverageDate).date() === 1) {
              payrollCalendarEndDate = new Date(
                Moment(firstCoverageDate)
                  .add(index, 'months')
                  .endOf('month')
                  .format('MM/DD/YYYY')
              );
            } else {
              payrollCalendarEndDate = new Date(
                Moment(firstCoverageDate)
                  .add(index + 1, 'months')
                  .subtract(1, 'days')
                  .format('MM/DD/YYYY')
              );
            }
          } else if (billingCycle === '24') {
            const previousStartDate = Moment(firstPayrollDate).add(
              Math.floor(index / 2),
              'months'
            );
            const nextStartDate = Moment(firstPayrollDate).add(
              Math.floor(index / 2) + 1,
              'months'
            );

            const payrollPreviousStartDate = Moment(firstCoverageDate).add(
              Math.floor(index / 2),
              'months'
            );
            const payrollNextStartDate = Moment(firstCoverageDate).add(
              Math.floor(index / 2) + 1,
              'months'
            );

            if (index % 2 === 0) {
              generatedPaidDate = new Date(
                Moment(firstPayrollDate)
                  .add(Math.floor(index / 2), 'months')
                  .add(
                    Math.ceil(
                      nextStartDate.diff(previousStartDate, 'days') / 2
                    ) - 1,
                    'days'
                  )
                  .format('MM/DD/YYYY')
              );
              payrollCalendarEndDate = new Date(
                Moment(firstCoverageDate)
                  .add(Math.floor(index / 2), 'months')
                  .add(
                    Math.ceil(
                      payrollNextStartDate.diff(
                        payrollPreviousStartDate,
                        'days'
                      ) / 2
                    ) - 1,
                    'days'
                  )
                  .format('MM/DD/YYYY')
              );
            } else {
              generatedPaidDate = new Date(
                Moment(nextStartDate).subtract(1, 'days').format('MM/DD/YYYY')
              );
              payrollCalendarEndDate = new Date(
                Moment(payrollNextStartDate)
                  .subtract(1, 'days')
                  .format('MM/DD/YYYY')
              );
            }
          } else {
            generatedPaidDate = new Date(
              Moment(firstPayrollDate)
                .add(
                  payrollGaps.frequency * index + payrollGaps.frequency - 1,
                  payrollGaps.timePeriod
                )
                .format('MM/DD/YYYY')
            );
            payrollCalendarEndDate = new Date(
              Moment(firstCoverageDate)
                .add(
                  payrollGaps.frequency * index + payrollGaps.frequency - 1,
                  payrollGaps.timePeriod
                )
                .format('MM/DD/YYYY')
            );
          }
          let generatedBillCreationDate = null;
          if (payrollGaps.timePeriod === 'months') {
            generatedBillCreationDate = new Date(
              Moment(firstBillCreationDate).add(index, 'months').format('MM/DD/YYYY')
            );
          } else {
            generatedBillCreationDate = new Date(
              Moment(generatedPaidDate)
                .subtract(billDaysPriorToPayroll, payrollGaps.timePeriod)
                .format('MM/DD/YYYY')
            );
          }
          // const generatedBillCreationDate = new Date(
          //   Moment(firstPayrollDate)
          //     .add(
          //       payrollGaps.frequency * index +
          //         payrollGaps.frequency -
          //         billDaysPriorToPayroll -
          //         1,
          //       payrollGaps.timePeriod
          //     )
          //     .format('MM/DD/YYYY')
          // );
           

          let generatedDueDate = null;
          let dueDate = null;
          
          if (payrollGaps.timePeriod === 'months') {
            generatedDueDate = new Date(
              Moment(firstDueDate).add(index, 'months').format('MM/DD/YYYY')
            );
            dueDate = new Date(
              Moment(firstDueDate).add(index, 'months').format('MM/DD/YYYY')
            );
          } else {
            generatedDueDate = new Date(
              Moment(generatedBillCreationDate)
                .add(10, 'days')
                .format('MM/DD/YYYY')
            );
            dueDate = new Date(
              Moment(generatedBillCreationDate)
                .add(10, 'days')
                .format('MM/DD/YYYY')
            );
          }

          
          change(`payrollSchedule[${index}].cycleNo`, index + 1);
          change(
            `payrollSchedule[${index}].startDate`,
            payrollCalendarStartDate
          );
          change(`payrollSchedule[${index}].endDate`, payrollCalendarEndDate);

          change(
            `payrollSchedule[${index}].payrollCalendarStartDate`,
            startDate
          );
          change(
            `payrollSchedule[${index}].payrollCalendarEndDate`,
            generatedPaidDate
          );

          change(
            `payrollSchedule[${index}].generatedPaidDate`,
            generatedPaidDate
          );
          change(`payrollSchedule[${index}].paidDate`, generatedPaidDate);
          change(
            `payrollSchedule[${index}].generatedBillCreationDate`,
            generatedBillCreationDate
          );
          change(
            `payrollSchedule[${index}].billCreationDate`,
            generatedBillCreationDate
          );
          change(`payrollSchedule[${index}].dueDate`, dueDate);
          change(
            `payrollSchedule[${index}].generatedDueDate`,
            generatedDueDate
          );
          if( prevBilling?.billingCycle !== billingCycle ){
            change(`payrollSchedule[${index}].payrollScheduleId`, null);
          }
          payrollData.push({
            billCreationDate: generatedBillCreationDate.toLocaleDateString(),
            cycleNo: index + 1,
            dueDate: dueDate.toLocaleDateString(),
            endDate: generatedPaidDate.toLocaleDateString(),
            generatedBillCreationDate:
              generatedBillCreationDate.toLocaleDateString(),
            generatedDueDate: generatedDueDate.toLocaleDateString(),
            generatedPaidDate: generatedPaidDate.toLocaleDateString(),
            paidDate: generatedPaidDate.toLocaleDateString(),
            payrollCalendarEndDate: payrollCalendarEndDate.toLocaleDateString(),
            payrollCalendarStartDate:
              payrollCalendarStartDate.toLocaleDateString(),
            startDate: startDate.toLocaleDateString(),
          });
        });
      setIsLoading(false);
    }
  }, [payrollSchedule, payrollData]);

  useEffect(() => {
    if (!loading && !showGeneratedPayrollSchedule && payrollData.length) {
      change(`payrollSchedule`, []);
      payrollData.forEach((payrollCycleData, index) => {
        change(`payrollSchedule[${index}].cycleNo`, payrollCycleData.cycleNo);
        change(
          `payrollSchedule[${index}].startDate`,
          dateUtils.setOffset2(payrollCycleData.startDate)
        );
        change(
          `payrollSchedule[${index}].endDate`,
          dateUtils.setOffset2(payrollCycleData.endDate)
        );
        change(
          `payrollSchedule[${index}].payrollCalendarStartDate`,
          dateUtils.setOffset2(payrollCycleData.payrollCalendarStartDate)
        );
        change(
          `payrollSchedule[${index}].payrollCalendarEndDate`,
          dateUtils.setOffset2(payrollCycleData.payrollCalendarEndDate)
        );
        change(
          `payrollSchedule[${index}].generatedPaidDate`,
          dateUtils.setOffset2(payrollCycleData.generatedPaidDate)
        );
        change(
          `payrollSchedule[${index}].paidDate`,
          dateUtils.setOffset2(payrollCycleData.paidDate)
        );
        change(
          `payrollSchedule[${index}].generatedBillCreationDate`,
          dateUtils.setOffset2(payrollCycleData.generatedBillCreationDate)
        );
        change(
          `payrollSchedule[${index}].billCreationDate`,
          dateUtils.setOffset2(payrollCycleData.billCreationDate)
        );
        change(
          `payrollSchedule[${index}].generatedDueDate`,
          dateUtils.setOffset2(payrollCycleData?.generatedDueDate)
        );
        change(
          `payrollSchedule[${index}].dueDate`,
          dateUtils.setOffset2(payrollCycleData?.dueDate)
        );
        change(
          `payrollSchedule[${index}].payrollScheduleId`,
          payrollCycleData.payrollScheduleId
        );
      });
      setIsLoading(false);
    }
  }, [loading, payrollSchedule, isLoading]);



  const getHeaders = ({ headers }) => {
    const { actions, gridHeaders } = headers;
    const result = gridHeaders.map(({ name, sortable }) => {
      const type = 'groupBillingInformationSection';
      const label = labelUtils({ key: name, type });
      if (sortable) {
        return {
          label,
          value: Array.isArray(name) ? name.join('.') : name,
        };
      }
      return label;
    });
    if (actions && actions.length > 0) {
      result.push('Actions');
    }
    return result;
  };
  const getRowData = ({ data, key }) => {
    const { actions } = list;
    const row = [...data];
    const payrollScheduleId = get(payrollData, `${key}.payrollScheduleId`, '');
    const groupBillObj = groupBillingData.filter(
      (v) => v.payrollScheduleId === payrollScheduleId
    );
    const chaseTransactionData = orderBy(
      groupBillingData,
      (v) => v.payrollSchedule.cycleNo
    ).filter((o) => o.status === 'BILLED');
    const achAutomaticPaymentStatus = get(
      chaseTransactionData,
      `0.achAutomaticPaymentStatus`,
      ''
    );
    if (actions && actions.length > 0) {
      const actionOptions = actions.map((actionItem) => {
        const { action } = actionItem;
        switch (action) {
          case 'view':
            return (
              <ToolbarButton
                key={`${key}-edit`}
                aria-label="Payment History"
                bg="primaryLight"
                icon="details"
                onClick={() => {
                  setGroupPaymentRecordId(
                    get(groupBillObj, '0.groupPaymentRecordId', null)
                  );
                }}
                permission="group.paymentHistory"
                round
                title="Payment History"
                visible={groupBillObj.length > 0}
              />
            );
          case 'payment':
            return (
              <ToolbarButton
                key={`${key}-payment`}
                aria-label="Make Payment"
                bg="accentSecondary"
                icon="billing"
                isDisabled={
                  achAutomaticPaymentStatus === get(chaseStatus, '0.value') ||
                  actionDisabled
                }
                onClick={() => {
                  setTransactionData(chaseTransactionData);
                }}
                permission="group.makePayment"
                round
                title="Make Payment"
                visible={
                  chasePayment &&
                  chaseTransactionData.length > 0 &&
                  get(chaseTransactionData, '0.payrollScheduleId', null) ===
                    payrollScheduleId
                }
              />
            );
          default:
        }
        return null;
      });
      row.push(<ButtonGroups leftAlign>{actionOptions}</ButtonGroups>);
    }
    return row;
  };

  return loading || !payrollSchedule || isLoading ? (
    <Spinner />
  ) : (
    <TableWrapper>
      <Table
        header={getHeaders({
          headers: list,
        })}
        rows={
          readOnly
            ? payrollSchedule &&
              payrollSchedule.map((rowItem, index) =>
                getRowData({
                  data: rowItem,
                  key: index,
                })
              )
            : payrollSchedule
        }
        tableSx={{ fontSize: 2 }}
      />
      {groupPaymentRecordId && (
        <Modal
          isOpen
          onSubmit={() => setGroupPaymentRecordId(null)}
          submitText="Done"
        >
          <PaymentHistory
            groupId={groupId}
            groupPaymentRecordId={groupPaymentRecordId}
          />
        </Modal>
      )}
      {transactionData.length > 0 && (
        <ApolloConsumer>
          {(client) => {
            return (
              <FinalForm
                onSubmit={async (values) => {
                  await client
                    .query({
                      fetchPolicy: 'no-cache',
                      query: remoteActionQuery,
                      variables: {
                        name: 'chase-payment',
                        params: JSON.stringify({
                          groupPaymentRecordId:
                            transactionData[0].groupPaymentRecordId,
                          transferAmount: Number(
                            get(values, 'transferAmount').replaceAll('$', '')
                          ).toFixed(2),
                        }),
                      },
                    })
                    .then(async () => {
                      await refetch();
                      setTransactionData([]);
                    })
                    .catch((e) => {
                      setTransactionData([]);
                      setErrorSnack(
                        `There was an error: ${e.message}`,
                        config.notificationDuration
                      );
                    });
                }}
                render={({ handleSubmit, submitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Modal
                        closeText={t('common.cancel')}
                        isOpen
                        onClose={() => {
                          setTransactionData([]);
                        }}
                        onSubmit={handleSubmit}
                        submitText="Pay"
                        submitting={submitting}
                        title="Make Payment"
                      >
                        <Box>
                          <Box>
                            Note:
                            <Box>
                              If you have any debit block id set on the account
                              please remove it for successful transfer.
                            </Box>
                            <Box>
                              Payment&apos;s submitted after 12 PM est will be
                              reflected on the next day.
                            </Box>
                          </Box>
                          <Field
                            aria-label="Transfer Amount"
                            component={InputMoney}
                            label={labelHandler('Transfer Amount', true)}
                            name="transferAmount"
                            validate={required}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              fontWeight: 'bold',
                              justifyContent: 'center',
                              my: 5,
                            }}
                          >
                            THIS ACTION CANNOT BE UNDONE
                          </Box>
                          Add payment confirmations?
                        </Box>
                      </Modal>
                    </form>
                  );
                }}
              />
            );
          }}
        </ApolloConsumer>
      )}
    </TableWrapper>
  );
};

PayrollSchedule.propTypes = {
  actionDisabled: PropTypes.bool,
  change: PropTypes.func.isRequired,
  chasePayment: PropTypes.bool,
  chaseStatus: PropTypes.arrayOf(PropTypes.shape({})),
  groupBillingData: PropTypes.arrayOf(PropTypes.shape({})),
  groupId: PropTypes.string,
  loading: PropTypes.bool,
  payrollData: PropTypes.arrayOf(PropTypes.shape({})),
  readOnly: PropTypes.bool,
  refetch: PropTypes.func,
  showGeneratedPayrollSchedule: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    billDaysPriorToPayroll: PropTypes.string,
    billingCycle: PropTypes.string,
    firstCoverageDate: PropTypes.string,
    firstPayrollDate: PropTypes.shape({}),
    firstBillCreationDate: PropTypes.string,
    firstDueDate: PropTypes.string,
  }).isRequired,
};

PayrollSchedule.defaultProps = {
  actionDisabled: false,
  chasePayment: false,
  chaseStatus: [],
  groupBillingData: [],
  groupId: '',
  loading: false,
  payrollData: [],
  readOnly: false,
  refetch: () => {},
};
export default PayrollSchedule;
