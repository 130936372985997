import { Router } from '@reach/router';
import React from 'react';
import SubProducerDashboardPage from '@petcomponents/SubProducers/SubProducerDashboardPage';
import UpsertSubProducerPage from '@petcomponents/SubProducers/UpsertSubProducerPage';
import SubProducerDetails from '@petcomponents/SubProducers/SubProducerDetails';
import NotFoundPage from './404';

const Subproducers = (props) => (
  <Router>
    <SubProducerDashboardPage {...props} path="/sub-producers/dashboard" />
    <UpsertSubProducerPage
      {...props}
      path="/sub-producers/upsert"
      type="upsertSubProducerPage"
    />
    <SubProducerDetails
      {...props}
      path="/sub-producers/:subProducerId/details"
      type="subProducerDetailsPage"
    />
    <NotFoundPage default path="/404" />
  </Router>
);

export default Subproducers;
