import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {Form as FinalForm} from 'react-final-form';
import {get, noop, sumBy} from 'lodash';
import {navigate, useParams} from '@reach/router';
import {ApolloConsumer} from '@apollo/client';
import {AuthContext} from '@basecomponents/Auth';
import {useTranslation} from 'react-i18next';
import CoverageItem from './CoverageItem';
import Spinner from '../../../../BaseComponents/Spinner';
import GetData from '../../../../../utilities/get-data';
import ToolbarButton from '../../../../BaseComponents/ToolbarButton';
import remoteActionQuery from '../../../../../graphql/queries/remote-action.gql';
import useSnackbar from '../../../../../utilities/use-snackbar';
import gaEvent from '../../../../../utilities/gaEvent';
import generateRedirectPath from '../../../../../utilities/generate-redirect-path';
import config from '../../../../../config.json';

/**
 * @category Employee
 * @param {boolean} isResponsive
 * @param {Object} location
 * @param {string} name
 * @returns {React.FC}
 */
const CoverageTab = ({ isResponsive, location, name }) => {
  const { t } = useTranslation();
  const start = Date.now();
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');

  const queryParams = useParams();
  const employeeId = get(queryParams, 'employeeId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [selectedPlanChoiceId, setSelectedPlanChoiceId] = useState('');
  const [prevSelectedPlanChoiceId, setPrevSelectedPlanChoiceId] =
    useState(null);

  const isRenewals = get(location, 'pathname', '').includes('/renewalenroll');

  const onPlanSelection = (planChoiceId, amount) => {
    if (planChoiceId === selectedPlanChoiceId) {
      setSelectedPlanChoiceId('');
    } else if (amount) {
      setSelectedPlanChoiceId(planChoiceId);
    }
  };

  const {
    apiData = {},
    loading,
    refetch,
  } = GetData(
    'get-coverage-info',
    JSON.stringify({
      employeeId,
    }),
    employeeId === null
  );
  const parentEmployeeId = get(apiData, 'parentEmployee.employeeId');

  const { apiData: parentApiData = {}, loading: parentLoading } = GetData(
    'get-coverage-info',
    JSON.stringify({
      employeeId: parentEmployeeId,
    }),
    parentEmployeeId === null
  );

  const refresh = () => {
    refetch();
  };
  const {
    planChoiceEmployees = [],
    coverages = [],
    premiumValues = [],
  } = apiData;
  const {
    planChoiceEmployees: parentPlanChoiceEmployees = [],
    coverages: parentCoverages = [],
    premiumValues: parentPremiumValues = [],
  } = parentApiData;

  let selectedPlan = {};
  if (planChoiceEmployees && planChoiceEmployees.length) {
    selectedPlan = planChoiceEmployees.find((v) => v.acceptGroupPlanChoice);
  }

  const planChoiceId = get(selectedPlan, 'planChoiceId', '');

  let parentSelectedPlan = {};
  if (parentPlanChoiceEmployees && parentPlanChoiceEmployees.length) {
    parentSelectedPlan = parentPlanChoiceEmployees.find(
      (v) => v.acceptGroupPlanChoice
    );
  }

  const parentPlanChoiceId = get(parentSelectedPlan, 'planChoiceId', '');

  useEffect(() => {
    if (planChoiceEmployees && planChoiceEmployees.length) {
      setSelectedPlanChoiceId(planChoiceId);
    }
    if (parentPlanChoiceEmployees && parentPlanChoiceEmployees.length) {
      setPrevSelectedPlanChoiceId(parentPlanChoiceId);
    }
  }, [loading || parentLoading]);
  if (loading || parentLoading) {
    return <Spinner />;
  }
  const coverageHistory = parentCoverages.find(
    (v) => v?.planChoiceId === prevSelectedPlanChoiceId
  );
  const primiumData = parentPremiumValues.find(
    (pv) => pv?.productPlanId === coverageHistory?.productPlanId
  );
  const prevSelectedDependent = get(primiumData, 'dependentDatas', []).filter(
    (pv) => pv.status === 'ACTIVE'
  );
  const employeeContributionVal = sumBy(
    prevSelectedDependent,
    'payPeriodPremiumEmployee'
  );
  const employerContributionVal = sumBy(
    prevSelectedDependent,
    'payPeriodPremiumEmployer'
  );
  const totalPremiumVal = sumBy(prevSelectedDependent, 'premium');
  const displayAmount = (
    employeeContributionVal + employerContributionVal
  ).toFixed(2);
  const prevCoverageResponse = {
    amount: totalPremiumVal,
    annualDeductible: get(coverageHistory, 'annualDeductible', ''),
    annualPolicyLimit: get(coverageHistory, 'annualLimit', ''),
    bwpIllness: get(coverageHistory, 'bwpIllness', ''),
    bwpInjuries: get(coverageHistory, 'bwpInjuries', ''),
    coInsurance: get(coverageHistory, 'coinsurance', ''),
    copay: get(coverageHistory, 'copay', ''),
    coverageCredit:
      get(coverageHistory, 'coverageCredit', 0) === 1
        ? t('metadata.included')
        : t('metadata.notIncluded'),
    diminishingDeductible: get(coverageHistory, 'diminishingDeductible', ''),
    displayAmount,
    existingCondition: get(coverageHistory, 'existingCondition', ''),
    maxAge: get(coverageHistory, 'maxAge', ''),
    poaAR:
      get(coverageHistory, 'poaAR', 0) === 1
        ? t('metadata.included')
        : t('metadata.notIncluded'),
    riderABC:
      get(coverageHistory, 'riderABC', 0) === 1
        ? t('metadata.included')
        : t('metadata.notIncluded'),
    riderAC:
      get(coverageHistory, 'riderAC', 0) === 1
        ? t('metadata.included')
        : t('metadata.notIncluded'),
    riderFR: get(coverageHistory, 'riderFR', ''),
    riderICC:
      get(coverageHistory, 'riderICC', 0) === 1
        ? t('metadata.included')
        : t('metadata.notIncluded'),
    riderOETC:
      get(coverageHistory, 'riderOETC', 0) === 1
        ? t('metadata.included')
        : t('metadata.notIncluded'),
    riderRD: get(coverageHistory, 'riderRD', ''),
    riderRPT:
      get(coverageHistory, 'riderRPT', 0) === 1
        ? t('metadata.included')
        : t('metadata.notIncluded'),
    wellness:
      get(coverageHistory, 'wellness', '') === 'Not Included'
        ? t('metadata.notIncluded')
        : get(coverageHistory, 'wellness'),
  };
  return (
    <ApolloConsumer>
      {(client) => {
        const onUpdateCoverage = () => {
          gaEvent('Enrollment', 'Coverage Selected', role, start);
          const selectedPremium = get(
            premiumValues,
            '0.dependentDatas',
            []
          ).filter((pv) => pv.status === 'PENDING');
          if (selectedPremium.length === 0) {
            setErrorSnack(
              t('snack.error.selectOnePet'),
              config.notificationDuration
            );
            return;
          }
          if (planChoiceId !== selectedPlanChoiceId) {
            const planChoices = coverages.map((item) => {
              const premium = premiumValues.find(
                (pv) => pv.productPlanId === item.productPlanId
              );
              const planChoice = {
                acceptGroupPlanChoice: false,
                approvedAmount: get(premium, 'totalPremium', 0).toFixed(2),
                coverageAmount: get(premium, 'totalPremium', 0).toFixed(2),
                planChoiceId: item.planChoiceId,
                planPrice: get(premium, 'totalPremium', 0).toFixed(2),
              };
              if (item.planChoiceId === selectedPlanChoiceId) {
                planChoice.acceptGroupPlanChoice = true;
              }
              return planChoice;
            });

            return client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'update-coverage-info',
                  params: JSON.stringify({
                    employeeId,
                    planChoiceEmployees,
                    planChoices,
                  }),
                },
              })
              .then(() => {
                return navigate(
                  generateRedirectPath({
                    ...location,
                    queryParams: { [name]: 2 },
                  })
                );
              })
              .catch((e) =>
                setErrorSnack(
                  t('snack.error.errorWithMessage', { message: e.message }),
                  config.notificationDuration
                )
              );
          }
          return navigate(
            generateRedirectPath({
              ...location,
              queryParams: { [name]: 2 },
            })
          );
        };

        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={{}}
              onSubmit={onUpdateCoverage}
              render={(formContext) => {
                return (
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 3,
                        width: 'auto',
                      }}
                    >
                      <Box
                        display={{ _: 'block', md: 'flex' }}
                        sx={{
                          width: 'auto',
                        }}
                      >
                        {prevSelectedPlanChoiceId && isRenewals && (
                          <Box>
                            <Box as="h3" sx={{ p: 3, width: '100%' }}>
                              Your Current Coverage
                            </Box>
                            <CoverageItem
                              contributionMessage={get(
                                parentApiData,
                                'contributionMessage',
                                null
                              )}
                              coverageResponse={prevCoverageResponse}
                              coverageSx={{
                                height: 'auto',
                                mr: 6,
                                pointerEvent: 'none',
                              }}
                              dependents={prevSelectedDependent}
                              disabledClick
                              disclaimers={[]}
                              employeeContributionVal={employeeContributionVal}
                              employerContributionVal={employerContributionVal}
                              isResponsive={isResponsive}
                              maxAgeError={[]}
                              onSelect={noop}
                              payrollFrequency={get(
                                parentApiData,
                                'frequency',
                                null
                              )}
                              planChoiceId={coverageHistory?.planChoiceId}
                              planPrice={coverageHistory?.planPrice}
                              premium={primiumData}
                              product={coverageHistory?.productDetails?.name}
                              refetch={noop}
                              selectedPlanChoiceId={prevSelectedPlanChoiceId}
                              title={coverageHistory?.name}
                            />
                          </Box>
                        )}
                        <Box>
                          <Box as="h3" sx={{ p: 3, width: '100%' }}>
                            {t('groupEmployees.enrollmentPage.selectPlan')}
                          </Box>
                          <Box
                            display={{ _: 'block', md: 'flex' }}
                            sx={{
                              width: 'auto',
                            }}
                          >
                            {coverages.length
                              ? coverages.map((plan) => {
                                  const premium = premiumValues.find(
                                    (pv) =>
                                      pv.productPlanId === plan.productPlanId
                                  );

                                  const disclaimers = premium.dependentDatas
                                    .filter(
                                      (pet) =>
                                        (pet.premium === 0 &&
                                          pet.status === 'PENDING') ||
                                        (pet.age > plan.maxAge &&
                                          plan.maxAge !== 0)
                                    )
                                    .map(
                                      (pet) =>
                                        `${pet.petName} is not eligible for coverage under this plan.`
                                    );

                                  const maxAgeError = premium.dependentDatas
                                    .filter(
                                      (pet) =>
                                        pet.age > plan.maxAge &&
                                        plan.maxAge !== 0
                                    )
                                    .map(
                                      (pet) => `${pet.petName} is over max age.`
                                    );
                                  if (
                                    disclaimers.length &&
                                    selectedPlanChoiceId === plan.planChoiceId
                                  ) {
                                    setSelectedPlanChoiceId('');
                                  }

                                  const coverageResponse = {
                                    amount: get(premium, 'totalPremium', ''),
                                    annualDeductible: get(
                                      plan,
                                      'annualDeductible',
                                      ''
                                    ),
                                    annualPolicyLimit: get(
                                      plan,
                                      'annualLimit',
                                      ''
                                    ),
                                    bwpIllness: get(plan, 'bwpIllness', ''),
                                    bwpInjuries: get(plan, 'bwpInjuries', ''),
                                    coInsurance: get(plan, 'coinsurance', ''),
                                    copay: get(plan, 'copay', ''),
                                    coverageCredit:
                                      get(plan, 'coverageCredit', 0) === 1
                                        ? t('metadata.included')
                                        : t('metadata.notIncluded'),
                                    diminishingDeductible: get(
                                      plan,
                                      'diminishingDeductible',
                                      ''
                                    ),
                                    displayAmount: (
                                      get(
                                        premium,
                                        'payPeriodPremiumEmployee',
                                        0
                                      ) +
                                      get(
                                        premium,
                                        'payPeriodPremiumEmployer',
                                        0
                                      )
                                    ).toFixed(2),
                                    existingCondition: get(
                                      plan,
                                      'existingCondition',
                                      ''
                                    ),
                                    maxAge: get(plan, 'maxAge', ''),
                                    poaAR:
                                      get(plan, 'poaAR', 0) === 1
                                        ? t('metadata.included')
                                        : t('metadata.notIncluded'),
                                    riderABC:
                                      get(plan, 'riderABC', 0) === 1
                                        ? t('metadata.included')
                                        : t('metadata.notIncluded'),
                                    riderAC:
                                      get(plan, 'riderAC', 0) === 1
                                        ? t('metadata.included')
                                        : t('metadata.notIncluded'),
                                    riderFR: get(plan, 'riderFR', ''),
                                    riderICC:
                                      get(plan, 'riderICC', 0) === 1
                                        ? t('metadata.included')
                                        : t('metadata.notIncluded'),
                                    riderOETC:
                                      get(plan, 'riderOETC', 0) === 1
                                        ? t('metadata.included')
                                        : t('metadata.notIncluded'),
                                    riderRD: get(plan, 'riderRD', ''),
                                    riderRPT:
                                      get(plan, 'riderRPT', 0) === 1
                                        ? t('metadata.included')
                                        : t('metadata.notIncluded'),
                                    wellness:
                                      get(plan, 'wellness', '') ===
                                      'Not Included'
                                        ? t('metadata.notIncluded')
                                        : get(plan, 'wellness'),
                                  };
                                  return (
                                    <CoverageItem
                                      key={plan.planId}
                                      contributionMessage={get(
                                        apiData,
                                        'contributionMessage',
                                        null
                                      )}
                                      coverageResponse={coverageResponse}
                                      dependents={get(
                                        premium,
                                        'dependentDatas',
                                        []
                                      )}
                                      disclaimers={disclaimers}
                                      isResponsive={isResponsive}
                                      maxAgeError={maxAgeError}
                                      onSelect={() =>
                                        onPlanSelection(
                                          plan.planChoiceId,
                                          coverageResponse.amount
                                        )
                                      }
                                      payrollFrequency={get(
                                        apiData,
                                        'frequency',
                                        null
                                      )}
                                      planChoiceId={plan.planChoiceId}
                                      planPrice={plan.planPrice}
                                      premium={premium}
                                      product={plan.productDetails.name}
                                      refetch={refresh}
                                      selectedPlanChoiceId={
                                        selectedPlanChoiceId
                                      }
                                      title={plan.name}
                                    />
                                  );
                                })
                              : ''}
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          my: 6,
                          p: 3,
                          width: '25rem',
                        }}
                      >
                        <ToolbarButton
                          bg="accent"
                          isDisabled={formContext.submitting}
                          label={t('common.previous')}
                          onClick={() =>
                            navigate(
                              generateRedirectPath({
                                ...location,
                                queryParams: { [name]: 0 },
                              })
                            )
                          }
                          width="25rem"
                        />
                        <ToolbarButton
                          bg="accentSecondary"
                          isDisabled={
                            formContext.submitting || !selectedPlanChoiceId
                          }
                          label={t('common.next')}
                          mr={0}
                          submitting={formContext.submitting}
                          type="submit"
                          width="25rem"
                        />
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

CoverageTab.propTypes = {
  isResponsive: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
};

CoverageTab.defaultProps = {
  name: 'step',
};

export default CoverageTab;
