import get from 'lodash/get';
import { REPORT_TYPES } from '../../../../utilities/constants';

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const ReportTypes = ({ value }) => {
  const reportTypes = REPORT_TYPES.find((s) => s.value === value);
  return reportTypes ? get(reportTypes, 'label') : `#${value}`;
};

export default ReportTypes;
