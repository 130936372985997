import Box from '@basecomponents/Box';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { MenuButton, MenuItem, MenuList } from 'react-menu-list';
import styled, { css, ThemeContext } from 'styled-components';
import EditAccessRightsContext from '../../Pet/EditAccessRights/Context';
import Icon from '../../Icon';

const StyledMenuButton = styled(MenuButton).attrs({
  openedClassName: 'open',
})`
  display: flex;
  align-items: center;

  ${(p) =>
    p.chevron &&
    css`
      padding-right: 1em;
      &::after {
        content: '';
        display: inline-block;
        position: relative;
        top: -0.12em;
        left: 0.55rem;
        width: 0.45em;
        height: 0.45em;
        border-style: solid;
        border-width: 1px 1px 0 0;
        transform: rotate(135deg);
        vertical-align: middle;
      }

      &.open::after {
        top: 0.18em;
        transform: rotate(-45deg);
      }
    `}
`;

const StyledMenuItem = styled(MenuItem).attrs({
  highlightedClassName: 'highlighted',
})`
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  user-select: none;
  transition: all ease-in-out 0.25s;
  color: ${(p) =>
    p.isDisabled ? p.theme.colors.gray[1] : p.theme.colors.textColor};
  a {
    color: inherit;
    text-decoration: inherit;
  }

  svg {
    color: ${(p) =>
      p.isDisabled ? p.theme.colors.gray[1] : p.theme.colors.accent};
    transition: all ease-in-out 0.25s;
  }

  &.highlighted {
    background: ${(p) =>
      p.isDisabled ? p.theme.colors.gray[1] : p.theme.colors.accent};
    color: ${(p) => p.theme.colors.white};
    transition: all ease-in-out 0.25s;

    svg {
      color: ${(p) => p.theme.colors.white};
      transition: all ease-in-out 0.25s;
    }
  }
`;

const StyledMenuItemChild = styled(Box)`
  align-items: center;
  display: flex;
  padding: ${(p) => p.theme.space[2]} ${(p) => p.theme.space[5]};
  white-space: nowrap;
  width: 100%;
`;

/**
 * @category BaseComponents
 * @param {React.ReactElement} chevron
 * @param {string} dividerPositions
 * @param {Array<Object>} items
 * @param {number} menuZIndex
 * @param {string} permission
 * @param {object} sx
 * @param {*} rest
 * @returns {React.FC}
 */
const ActionDropdownMenu = ({
  chevron,
  dividerPositions,
  items,
  menuZIndex,
  permission,
  sx,
  ...rest
}) => {
  const ear = useContext(EditAccessRightsContext);
  const { defaultStyle = {} } = useContext(ThemeContext);
  const { outerBox = {} } = defaultStyle.defaultActionDropDown(sx);

  const iconStyled = {
    height: '24px',
    mr: 4,
    width: '24px',
  };

  return (
    <>
      {ear.isVisible(permission) && (
        <StyledMenuButton
          chevron={chevron}
          menu={
            <Box
              alignItems="center"
              bg="white"
              display="flow"
              sx={{ ...outerBox }}
              {...rest}
            >
              <MenuList>
                {items.map((item, i) => {
                  const linkRef = React.createRef();
                  const { innerBox = {} } =
                    defaultStyle.defaultActionDropDown(item);
                  if (ear.isVisible(item.permission) && !item.isHidden) {
                    return (
                      <Box key={`${item.label}`} sx={{ ...innerBox }}>
                        {dividerPositions.includes(i) && <Box as="hr" my={4} />}
                        <StyledMenuItem
                          isDisabled={item.isDisabled}
                          onItemChosen={(e) => {
                            if (typeof item.action === 'string') {
                              return e.byKeyboard
                                ? linkRef.current.click()
                                : () => {};
                            }
                            return item.action(e);
                          }}
                        >
                          {
                            // eslint-disable-next-line no-nested-ternary
                            typeof item.action === 'function' &&
                            !item.isDisabled ? (
                              <StyledMenuItemChild
                                onClick={(e) => item.action(e)}
                              >
                                <Icon
                                  svg={item.icon || 'kebab-menu'}
                                  sx={{ ...iconStyled }}
                                />
                                {item.label}
                              </StyledMenuItemChild>
                            ) : typeof item.action === 'string' &&
                              !item.isDisabled ? (
                              <StyledMenuItemChild
                                ref={linkRef}
                                as={Link}
                                to={item.action}
                              >
                                <Icon
                                  svg={item.icon || 'kebab-menu'}
                                  sx={{ ...iconStyled }}
                                />
                                {item.label}
                              </StyledMenuItemChild>
                            ) : (
                              <StyledMenuItemChild>
                                <Icon
                                  svg={item.icon || 'kebab-menu'}
                                  sx={{ ...iconStyled }}
                                />
                                {item.label}
                              </StyledMenuItemChild>
                            )
                          }
                        </StyledMenuItem>
                      </Box>
                    );
                  }
                  return null;
                })}
              </MenuList>
            </Box>
          }
          menuZIndex={menuZIndex || 4}
          title="Actions"
        >
          <Icon svg="kebab-menu" sx={iconStyled} />
        </StyledMenuButton>
      )}
    </>
  );
};

ActionDropdownMenu.propTypes = {
  chevron: PropTypes.bool,
  dividerPositions: PropTypes.arrayOf(PropTypes.number),
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  menuZIndex: PropTypes.number,
  permission: PropTypes.string,
  sx: PropTypes.shape({}),
};

ActionDropdownMenu.defaultProps = {
  chevron: false,
  dividerPositions: [],
  menuZIndex: null, // Defaults to null, so css can't be applied
  permission: '',
  sx: {},
};

export default ActionDropdownMenu;
