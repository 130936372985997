import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import Arial from '../../../../assets/fonts/Arial.woff';
import ArialBold from '../../../../assets/fonts/ArialBold.woff';
import PPI from '../../../../images/brands/ppi.png';
import FIGO from '../../../../images/brands/figo.png';
import JonD from '../../../../images/png/JohnD.png';
import SignatureMary from '../../../../images/png/mary.png';
import GeneratedPDFViewer from '../../GeneratedPDFViewer';

Font.register({ family: 'Arial', src: Arial });
Font.register({ family: 'ArialBold', src: ArialBold });

const MasterPolicyGenerator = ({
  groupName,
  groupNumber,
  planChoiceId,
  planId,
  product,
  coverageFromDate,
  renewalDate,
  stateOfIssue,
  address,
}) => {
  // const productName = () => {
  //   if (product === 'Accident Only') {
  //     return 'Accident Coverage';
  //   }
  //   return 'Accident and Illness Coverage';
  // };

  const icon = () => {
    if (groupNumber.includes('GFPI')) {
      return FIGO;
    }
    return PPI;
  };

  const styles = StyleSheet.create({
    common: {
      backgroundColor: 'white',
      color: 'black',
      fontFamily: 'Arial',
      padding: '50px 50px 20px 50px',
      userSelect: 'none !important',
    },
    fontLargeBold: {
      fontFamily: 'ArialBold',
      fontSize: '20px',
    },
    fontMediumBold: {
      fontFamily: 'ArialBold',
      fontSize: '12px',
    },
    fontNormal: {
      fontFamily: 'Arial',
      fontSize: '10px',
    },
    fontNormalBold: {
      fontFamily: 'ArialBold',
      fontSize: '10px',
    },
    fontXLargeBold: {
      fontFamily: 'ArialBold',
      fontSize: '24px',
    },
    positionAdminLogo: {
      width: '153px',
    },
    positionContentTitle: {
      margin: '0 0 10px 0',
      textAlign: 'center',
    },
    positionListItem: {
      margin: '0 0 0 17px',
    },
    positionPage1Header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    positionPage1HeaderRightSection: {
      textAlign: 'right',
    },
    positionPage1HeaderSection: {
      display: 'flex',
      flexDirection: 'column',
      width: '130px',
      wordWrap: 'break-word',
    },
    positionParagraph: {
      margin: '0 0 10px 0',
      textAlign: 'justify',
      textJustify: 'inter-word',
    },
    positionSecondaryTitle: {
      margin: '10px 0 10px 0',
      textAlign: 'center',
    },
    positionSignature: {
      width: '120px',
    },
    positionSignatureSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    positionSignatureSubSection: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    positionSignatureView: {
      alignItems: 'center',
      display: 'flex',
      height: '70px',
      justifyContent: 'flex-end',
      width: '150px',
    },
    positionSubTitle: {
      margin: '10px 0 10px 0',
      textAlign: 'center',
    },
    positionSummarySection: {
      margin: '10px 0 10px 0',
    },
    positionTableOfContents: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    positionTableSpacing: {
      marginTop: '20px',
    },
    positionTitle: {
      margin: '30px 0 10px 0',
      textAlign: 'center',
    },
  });

  const ORMasterPolicy = () => (
    <Document>
      <Page style={styles.common}>
        <View>
          <View style={styles.positionPage1Header}>
            <View style={styles.positionPage1HeaderSection}>
              <Text style={styles.fontNormal}>Insurer Information:</Text>
              <Text style={styles.fontNormal}>Independence American</Text>
              <Text style={styles.fontNormal}>Insurance Company</Text>
              <Text style={styles.fontNormal}>11333 N. Scottsdale Rd.,</Text>
              <Text style={styles.fontNormal}>Ste. 160</Text>
              <Text style={styles.fontNormal}>Scottsdale, AZ 85254</Text>
            </View>
            <View>
              <Image src={icon()} style={styles.positionAdminLogo} />
            </View>
            <View
              style={[
                styles.positionPage1HeaderSection,
                styles.positionPage1HeaderRightSection,
              ]}
            >
              <Text style={styles.fontNormal}>Administrator Information:</Text>
              <Text style={styles.fontNormal}>
                {get(address, 'addressLine1', '')}
              </Text>
              <Text style={styles.fontNormal}>
                {get(address, 'addressLine2', '')}
              </Text>
              <Text style={styles.fontNormal}>
                {get(address, 'city', '')}, {get(address, 'state', '')}{' '}
                {get(address, 'zipcode', '')}
              </Text>
            </View>
          </View>
          <View style={styles.positionTitle}>
            <Text style={styles.fontXLargeBold}>
              INDEPENDENCE AMERICAN INSURANCE COMPANY
            </Text>
          </View>
          <View style={styles.positionSubTitle}>
            <Text style={styles.fontMediumBold}>
              A Delaware Insurance Company
            </Text>
            <View>
              <Text style={styles.fontMediumBold}>
                Home Office:{' '}
                <Text style={styles.fontMediumBold}>
                  11333 N. Scottsdale Rd., Ste. 160, Scottsdale, AZ 85254
                </Text>
              </Text>
            </View>
          </View>
          <View style={styles.positionSecondaryTitle}>
            <Text style={styles.fontLargeBold}>Group Pet Insurance Policy</Text>
          </View>
          <View style={styles.positionSummarySection}>
            <Text style={styles.fontNormalBold}>
              GROUP POLICYHOLDER:{' '}
              <Text style={styles.fontNormal}>{groupName}</Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              GROUP NUMBER: <Text style={styles.fontNormal}>{groupNumber}</Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              MINIMUM PARTICIPATION REQUIREMENT:{' '}
              <Text style={styles.fontNormal}>
                2 Enrolled{' '}
                <Text style={styles.fontNormalBold}>Eligible Persons</Text>
              </Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              DATE OF ISSUE:{' '}
              <Text style={styles.fontNormal}>{coverageFromDate}</Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              RENEWAL DATE:{' '}
              <Text style={styles.fontNormal}>
                {`Renewals occur annually beginning ${renewalDate}`}
              </Text>
            </Text>
            <Text style={styles.fontNormalBold}>
              STATE OF ISSUE:{' '}
              <Text style={styles.fontNormal}>{stateOfIssue}</Text>
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              Independence American Insurance Company (hereafter referred to as{' '}
              <Text style={styles.fontNormalBold}>We, Our</Text> or{' '}
              <Text style={styles.fontNormalBold}>Us</Text>) agrees to pay group
              insurance <Text style={styles.fontNormalBold}>Coverage</Text> with
              respect to each{' '}
              <Text style={styles.fontNormalBold}>Eligible Person</Text>{' '}
              according and subject to the terms and conditions of this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>. Benefits
              are payable in United States dollars only. The{' '}
              <Text style={styles.fontNormalBold}>Coverage</Text> and insurance
              provisions approved under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text> are
              contained in the{' '}
              <Text style={styles.fontNormalBold}>Certificate</Text> of
              Insurance (hereafter referred to as{' '}
              <Text style={styles.fontNormalBold}>“Certificate”</Text>).
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              This <Text style={styles.fontNormalBold}>Group Policy</Text> is
              issued to the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> in
              consideration of the{' '}
              <Text style={styles.fontNormalBold}>Group</Text> application and
              payment of premiums, as provided herein, to take effect as of the
              date of issue. A copy of the completed{' '}
              <Text style={styles.fontNormalBold}>Group</Text> application is
              attached. This{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text> will
              terminate as described herein. Date of issue and termination will
              begin and end at 12:01 A.M. Standard Time at the address of the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>. The{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> is
              not <Text style={styles.fontNormalBold}>Our</Text> agent for any
              purpose under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>.
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              <Text style={styles.fontNormalBold}>
                This Policy includes any Group applications, certificates, and
                riders.
              </Text>{' '}
              This <Text style={styles.fontNormalBold}>Group Policy</Text> is
              issued by <Text style={styles.fontNormalBold}>Us</Text> and
              delivered in the state shown above and governed by the laws of
              that state. All terms are defined, and benefits are provided, in
              accordance with the terms, conditions and provisions of these
              documents, and applicable state laws.{' '}
              <Text style={styles.fontNormalBold}>
                All terms are as defined in the Certificate, unless otherwise
                shown herein.
              </Text>
            </Text>
          </View>
          <View style={styles.positionParagraph}>
            <Text style={styles.fontNormal}>
              Signed by Independence American Insurance Company
            </Text>
          </View>
          <View style={styles.positionSubTitle}>
            <Text style={styles.fontNormalBold}>
              INDEPENDENCE AMERICAN INSURANCE COMPANY
            </Text>
          </View>
          <View style={styles.positionSignatureSection}>
            <View style={styles.positionSignatureSubSection}>
              <View style={styles.positionSignatureView}>
                <Image src={JonD} style={styles.positionSignature} />
              </View>
              <Text style={styles.fontNormal}>Jon Dubauskas</Text>
              <Text style={styles.fontNormal}>President</Text>
            </View>
            <View style={styles.positionSignatureSubSection}>
              <View style={styles.positionSignatureView}>
                <Image src={SignatureMary} style={styles.positionSignature} />
              </View>
              <Text style={[styles.fontNormal]}>Mary Morandini</Text>
              <Text style={styles.fontNormal}>Secretary</Text>
            </View>
          </View>
          <View style={styles.positionSubTitle}>
            <Text style={styles.fontMediumBold}>NON-PARTICIPATING</Text>
            <Text style={styles.fontMediumBold}>
              This Policy does not share in Our profits or surplus earnings.
            </Text>
          </View>
          <View style={[styles.positionPage1Header, { marginTop: '10px' }]}>
            <View style={styles.positionPage1HeaderSection}>
              <Text style={styles.fontNormal}>IAIC GPI POL OR 0321</Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>1</Text>
            </View>
            <View
              style={[
                styles.positionPage1HeaderSection,
                styles.positionPage1HeaderRightSection,
              ]}
            >
              <Text style={styles.fontNormal}>012022</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page style={styles.common}>
        <View style={styles.positionTableOfContents}>
          <Text style={[styles.fontMediumBold, styles.positionTableSpacing]}>
            TABLE OF CONTENTS
          </Text>
          <Text style={[styles.fontNormal, styles.positionTableSpacing]}>
            The following appear within this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> in the
            following order:
          </Text>
          <Text style={[styles.fontNormalBold, styles.positionTableSpacing]}>
            Insurance Requirements
          </Text>
          <Text style={[styles.fontNormalBold, styles.positionTableSpacing]}>
            Policy Premiums
          </Text>
          <Text style={[styles.fontNormalBold, styles.positionTableSpacing]}>
            Termination of Group Policy
          </Text>
          <Text style={[styles.fontNormalBold, styles.positionTableSpacing]}>
            General Provisions
          </Text>
          <Text style={[styles.fontNormalBold, styles.positionTableSpacing]}>
            Certificate
          </Text>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '530px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL OR 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>2</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={styles.common}>
        <View style={styles.positionContentTitle}>
          <Text style={styles.fontMediumBold}>Insurance Requirements</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            The <Text style={styles.fontNormalBold}>Group Policyholder’s</Text>{' '}
            insurance elections are shown in the{' '}
            <Text style={styles.fontNormalBold}>Group</Text> application.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            Insurance benefits for the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> are as agreed
            upon between <Text style={styles.fontNormalBold}>Us</Text>
            and the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder.</Text> All
            insurance and actual benefit amounts in effect, with respect to each{' '}
            <Text style={styles.fontNormalBold}>Insured</Text>, are described in
            the <Text style={styles.fontNormalBold}>Certificate</Text>(s) issued
            by <Text style={styles.fontNormalBold}>Us</Text> to the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> for
            distribution to the{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text>, or directly to
            the <Text style={styles.fontNormalBold}>Insureds</Text>. This
            includes any applicable amendments,{' '}
            <Text style={styles.fontNormalBold}>Riders</Text> or endorsements,
            superseding or changing the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> in any way,
            and/or the essential features of insurance the{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> is entitled to
            under this <Text style={styles.fontNormalBold}>Group Policy</Text>,
            and to whom the insurance benefits are payable in the event of a
            covered loss.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            The <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            will furnish all information reasonably necessary to administer this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>, including
            but not limited to the following:
          </Text>
          <View style={styles.positionListItem}>
            <View>
              <Text style={styles.fontNormal}>
                1. At least one{' '}
                <Text style={styles.fontNormalBold}>Census Report</Text> during
                each <Text style={styles.fontNormalBold}>Contract Year</Text>,
                no later than three months prior to the next insurance benefit
                renewal date.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                2. A list of all{' '}
                <Text style={styles.fontNormalBold}>Eligible Persons</Text> and
                documentation supporting eligibility for{' '}
                <Text style={styles.fontNormalBold}>Eligible Persons</Text>{' '}
                under the{' '}
                <Text style={styles.fontNormalBold}>Group Policy</Text>.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                3. Information about persons who become eligible and any amounts
                of insurance they may change and/or end.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                4. Any other information required to manage a{' '}
                <Text style={styles.fontNormalBold}>Claim</Text>.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                5. Notification of the{' '}
                <Text style={styles.fontNormalBold}>Group Policyholder’s</Text>{' '}
                change in legal status, expansion of business, dissolution,
                merger, buyout or any other significant business operational
                change.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                6. Notice of any additional eligible insurance classes.
              </Text>
            </View>
            <View>
              <Text style={styles.fontNormal}>
                7. Any other information reasonably required.
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            <Text style={styles.fontNormalBold}>Census Report</Text> means a
            report providing the following information for each{' '}
            <Text style={styles.fontNormalBold}>Insured</Text> under the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>: name,
            address and class, and name(s) of their{' '}
            <Text style={styles.fontNormalBold}>Insured Pet(s)</Text>, including
            age, weight and breed, as defined in the{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text> and the
            amount of insurance.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            <Text style={styles.fontNormalBold}>Contract Years</Text> means
            successive 12-month periods computed from the end of the initial
            rate guarantee period, or from a time agreed to in writing by the
            <Text style={styles.fontNormalBold}>
              Group Policyholder
            </Text> and <Text style={styles.fontNormalBold}>Us</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            The <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            must provide such information to{' '}
            <Text style={styles.fontNormalBold}>Us</Text> or{' '}
            <Text style={styles.fontNormalBold}>Our </Text>
            agents in a regular and timely manner as reasonably specified by Us
            and/or Our agents. <Text style={styles.fontNormalBold}>
              We
            </Text> or <Text style={styles.fontNormalBold}>Our</Text> agents
            have the right at all reasonable times to inspect the payroll (if
            applicable) and records of the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            relating to insurance under this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>.
          </Text>
        </View>
        <View style={[styles.positionContentTitle, { marginTop: '10px' }]}>
          <Text style={styles.fontMediumBold}>
            GROUP POLICYHOLDER RESPONSIBILITIES
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            The <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            agrees:
          </Text>
          <View>
            <Text style={styles.fontNormal}>
              1. To offer each{' '}
              <Text style={styles.fontNormalBold}>Eligible Person</Text> the
              opportunity to elect coverage under the{' '}
              <Text style={styles.fontNormalBold}>Certificate</Text> when he or
              she attains the status of{' '}
              <Text style={styles.fontNormalBold}>Employed</Text> or{' '}
              <Text style={styles.fontNormalBold}>Contracted</Text> as provided
              for in the <Text style={styles.fontNormalBold}>Certificate</Text>.
            </Text>
          </View>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            2. To furnish Independence American Insurance Company or its
            authorized <Text style={styles.fontNormalBold}>Administrator</Text>,
            on a monthly basis and on Independence American Insurance Company
            approved forms, such information as may reasonably be required by
            Independence American Insurance Company for the administration of{' '}
            <Text style={styles.fontNormalBold}>Coverage</Text> under the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>, including
            any change in an <Text style={styles.fontNormalBold}>Insured</Text>{' '}
            person’s eligibility status.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            3. To comply with all policies and procedures established by
            Independence American Insurance Company in administering and
            interpreting coverage under the{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text style={styles.fontNormal}>
            4. To furnish all enrollment and termination change notifications to
            Independence American Insurance Company or its authorized{' '}
            <Text style={styles.fontNormalBold}>Administrator</Text> within 31
            days after the{' '}
            <Text style={styles.fontNormalBold}>Effective Date</Text> of such
            additions, changes and terminations. An{' '}
            <Text style={styles.fontNormalBold}>Eligible Person</Text> no longer
            eligible, as defined, must be terminated on the premium due date
            coinciding with the date on which the{' '}
            <Text style={styles.fontNormalBold}>Eligible Person</Text> ceases to
            qualify as an{' '}
            <Text style={styles.fontNormalBold}>Eligible Person</Text>. If the
            <Text style={styles.fontNormalBold}> Group Policyholder</Text> fails
            to send notification to{' '}
            <Text style={styles.fontNormalBold}>Us</Text> of any ineligible
            insured’s termination within 31 days of the date the person ceases
            to be an <Text style={styles.fontNormalBold}>Eligible Person</Text>,
            the <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            shall forfeit any premium refund/credit that would otherwise have
            been due.
          </Text>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '100px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL OR 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>3</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View style={styles.positionParagraph}>
          <View style={styles.positionContentTitle}>
            <Text style={styles.fontMediumBold}>Policy Premium</Text>
          </View>
          <View>
            <Text style={styles.fontNormalBold}>Payment of Premiums</Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              1. Premiums are due on the first of the month for which the
              premium applies, unless otherwise approved by{' '}
              <Text style={styles.fontNormalBold}>Us</Text>.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              2. The premium due on each premium due date is the sum of the
              premiums for all{' '}
              <Text style={styles.fontNormalBold}>Insureds </Text>under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              3. The{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
              determines the amount, if any, of each{' '}
              <Text style={styles.fontNormalBold}>Insured’s</Text> contribution
              toward the cost of insurance.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              4. Each premium is payable on or before its premium due date
              directly to <Text style={styles.fontNormalBold}>Us</Text> or{' '}
              <Text style={styles.fontNormalBold}>Our</Text> legal
              representative.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              5. Premium is due for an{' '}
              <Text style={styles.fontNormalBold}>Insured</Text> for each month
              the <Text style={styles.fontNormalBold}>Insured</Text> is insured
              under this <Text style={styles.fontNormalBold}>Group Policy</Text>
              . The{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> must
              notify <Text style={styles.fontNormalBold}>Us</Text> immediately
              whenever someone becomes eligible or ceases to be eligible for
              insurance.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              6. All premiums are based upon information provided in the{' '}
              <Text style={styles.fontNormalBold}>Census Reports</Text>.
            </Text>
          </View>
        </View>
        <View style={styles.positionParagraph}>
          <View>
            <Text style={styles.fontNormalBold}>Changes in Premium Rates</Text>
          </View>
          <View>
            <Text>
              <Text style={styles.fontNormalBold}>
                Special Circumstances. We
              </Text>{' '}
              may change premium rates, to be effective on the next premium due
              date, if any of the following occur:
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              1. A change or clarification in a law or governmental regulation
              affects the amount payable under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>. Any
              change in premium rates will reflect only the change in{' '}
              <Text style={styles.fontNormalBold}>Our</Text> obligations.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              2. One or more changes occur in the factors material to the
              underwriting risk <Text style={styles.fontNormalBold}>We</Text>{' '}
              assumed with respect to the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>,
              including, but not limited to, changes to the location of the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> the
              number of <Text style={styles.fontNormalBold}>Pet(s)</Text>{' '}
              insured, age, and breed classification.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              3. The premium contribution arrangement for{' '}
              <Text style={styles.fontNormalBold}>Certificate Holder</Text>{' '}
              changes or varies by the{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text> provisions
              when issued or last renewed.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              4. The{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
              requests an insurance benefit design change.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              5. <Text style={styles.fontNormalBold}>We</Text>, and the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>,
              mutually agree to change premium rates.
            </Text>
          </View>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            In all other cases, and subject to a period{' '}
            <Text style={styles.fontNormalBold}>We</Text> have provided the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> with a
            written rate guarantee,{' '}
            <Text style={styles.fontNormalBold}>We</Text> may change premium
            rates upon 60 days advance written notice to the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text>. Any
            change in premium rates may be made effective on any premium due
            date, but no change will be made more than once in any{' '}
            <Text style={styles.fontNormalBold}>Contract Year</Text>.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <View>
            <Text style={styles.fontNormalBold}>Premium Adjustments</Text>
          </View>
          <View>
            <Text>
              Premium adjustments involving a return of unearned premiums to a{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> may
              be limited to the 12 months just before the date{' '}
              <Text style={styles.fontNormalBold}>We</Text> receive a request
              for premium adjustment.
            </Text>
          </View>
        </View>
        <View style={styles.positionParagraph}>
          <View>
            <Text style={styles.fontNormalBold}>
              Grace Period, Termination for Nonpayment and Reinstatement
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              1. If a premium is not paid on or before its premium due date, it
              may be paid during the grace period. The insurance will remain in
              force during the grace period.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              2. Grace period means the [60] days following the premium due
              date.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              3. If the premium is not paid during the grace period, insurance
              under this <Text style={styles.fontNormalBold}>Group Policy</Text>{' '}
              will terminate automatically at the end of the grace period,
              subject to a 10 day notice of cancellation.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              4. The{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> is
              liable for the payment of a pro rata premium, if applicable,
              during the grace period.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              5. If any premium is not paid and insurance is terminated, the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> may
              request a reinstatement by reapplying and submitting the required
              premium. Insurance will be reinstated upon{' '}
              <Text style={styles.fontNormalBold}>Our</Text> approval, if the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
              requests reinstatement within 31 days of the termination date.{' '}
              <Text style={styles.fontNormalBold}>We</Text> shall approve or
              disapprove the reinstatement within 15 calendar days following
              receipt of the reinstatement request and premium.{' '}
              <Text style={styles.fontNormalBold}>We</Text> and the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text> have
              the same rights as <Text style={styles.fontNormalBold}>We</Text>{' '}
              did under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>{' '}
              immediately before the due date of the defaulted premium, subject
              to any provisions in connection with the reinstatement.
            </Text>
          </View>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '150px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL OR 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>4</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View style={styles.positionParagraph}>
          <View style={styles.positionContentTitle}>
            <Text style={styles.fontMediumBold}>Termination</Text>
          </View>
          <View>
            <Text>
              <Text style={styles.fontNormalBold}>By Us: We</Text> can end this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>:
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              1. After the first anniversary (of the effective) date, or earlier
              if due to nonpayment of premium or the failure to meet continued
              underwriting guidelines, if any.{' '}
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              2. On any premium due date if the number of{' '}
              <Text style={styles.fontNormalBold}>Insureds</Text> is less than
              the Minimum Participation Requirements.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              3. On any premium due date if{' '}
              <Text style={styles.fontNormalBold}>We</Text> determine the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder </Text>
              has failed to promptly furnish any necessary information requested
              or has failed to perform any other obligations relating to this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text> or
              insurance under this{' '}
              <Text style={styles.fontNormalBold}>Group Policy</Text>.
            </Text>
          </View>
          <View style={styles.positionListItem}>
            <Text>
              4. On the date the{' '}
              <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
              breaches any part of the Entire Contract.
            </Text>
          </View>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>We</Text> will give a 10-day
            notice prior to termination for non-payment of premium, subject to
            the grace period, and a 30-day notice prior to termination for all
            other reasons.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            Any termination by <Text style={styles.fontNormalBold}>Us</Text> is
            without prejudice to any expenses originating prior to the
            termination date. An expense will be considered incurred on the date
            of the occurrence.
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>
              By the Group Policyholder:
            </Text>{' '}
            The <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            can end this <Text style={styles.fontNormalBold}>Group Policy</Text>{' '}
            by giving <Text style={styles.fontNormalBold}>Us</Text> a 60-day
            advance written notice.
          </Text>
        </View>

        <View style={styles.positionContentTitle}>
          <Text style={styles.fontMediumBold}>General Provisions</Text>
        </View>

        <View>
          <Text style={styles.fontNormalBold}>Certificates</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            <Text style={styles.fontNormalBold}>We</Text> will prepare the{' '}
            <Text style={styles.fontNormalBold}>Certificates</Text> setting
            forth the main features of this Group Policy applicable to each
            Insured. <Text style={styles.fontNormalBold}>We</Text> and the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> may
            agree to distribute the{' '}
            <Text style={styles.fontNormalBold}>Certificates</Text> to{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text> in paper format,
            or to make the document available and accessible for review by{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text> on the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder’s</Text>{' '}
            website. The{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> will
            be responsible for providing sufficient notice to the{' '}
            <Text style={styles.fontNormalBold}>Insureds</Text> of the existence
            and availability of the{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text>, including
            instructions on how to view the document, and a statement that a
            paper copy is available upon request. Upon receiving such a request
            from either the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> or{' '}
            <Text style={styles.fontNormalBold}>Insured</Text>,{' '}
            <Text style={styles.fontNormalBold}>We</Text> will provide a written
            copy of the <Text style={styles.fontNormalBold}>Certificate</Text>{' '}
            to the <Text style={styles.fontNormalBold}>Group Policyholder</Text>{' '}
            for distribution to the Insured. If the terms of the{' '}
            <Text style={styles.fontNormalBold}>Certificate</Text> differ from
            the terms of insurance under this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text>, the latter
            will govern.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Clerical Error</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            A clerical error may be made by{' '}
            <Text style={styles.fontNormalBold}>Us</Text> or the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text> in
            keeping data. If so, when the error is found the premium and/or
            benefits will be adjusted according to the correct data. An error
            will not end insurance validly in force, nor will it continue
            insurance validly ended.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>
            Conformity with State and Federal Laws
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            If any provisions of <Text style={styles.fontNormalBold}>Our</Text>{' '}
            forms are contrary to any law to which it is subject, such provision
            is hereby amended to conform to the minimum requirements of such
            law.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Entire Contract, Changes</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            This <Text style={styles.fontNormalBold}>Group Policy</Text>,
            including any applications,{' '}
            <Text style={styles.fontNormalBold}>
              Certificates, Declarations Pages, Riders,{' '}
            </Text>
            or endorsements constitutes the entire contract of Insurance. No
            change in this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> shall be
            valid until approved by one of our executive officers and unless
            such approval is endorsed hereon or attached hereto. No agent has
            authority to change this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> or waive any
            of its provisions. <Text style={styles.fontNormalBold}>We</Text>{' '}
            have the right to determine all questions arising in connection with
            this <Text style={styles.fontNormalBold}>Group Policy</Text>,
            including its interpretation.{' '}
            <Text style={styles.fontNormalBold}>Our</Text> failure to enforce
            any provision shall not waive, modify or render such provision
            unenforceable at any other time; at any given time; or under any
            given set of circumstances, whether the circumstances are or are not
            the same.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>Legal Actions</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            No action shall be brought to recover on this{' '}
            <Text style={styles.fontNormalBold}>Group Policy</Text> prior to the
            expiration of 60 days after written notice and the expiration of 3
            years after written application for insurance.
          </Text>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '50px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL OR 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>5</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
      <Page style={[styles.common, styles.fontNormal]}>
        <View>
          <Text style={styles.fontNormalBold}>Misstatement</Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            Misstatement If premiums for a{' '}
            <Text style={styles.fontNormalBold}>Pet</Text> are based on
            enrollment information and the{' '}
            <Text style={styles.fontNormalBold}>Pet</Text> enrollment
            information has been misstated, there will be a fair adjustment of
            premiums based on his or her correct information. If the benefits
            for the <Text style={styles.fontNormalBold}>Pet</Text> are based on
            the enrollment information which has been misstated, there will be
            an adjustment of said benefit based on the{' '}
            <Text style={styles.fontNormalBold}>Pet</Text> correct information.
          </Text>
        </View>
        <View>
          <Text style={styles.fontNormalBold}>
            Time Limit on Certain Defenses
          </Text>
        </View>
        <View style={styles.positionParagraph}>
          <Text>
            All statements made in the{' '}
            <Text style={styles.fontNormalBold}>Group</Text> application are, in
            the absence of fraud, representations and not warranties. We cannot
            contest this <Text style={styles.fontNormalBold}>Policy</Text> after
            it has been in force for 2 years from its date of issue. No
            statement shall be used to contest the validity of insurance or
            reduce benefits, unless it is in writing, signed by the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder</Text>, and a
            copy of the statement is furnished to the{' '}
            <Text style={styles.fontNormalBold}>Group Policyholder.</Text>
          </Text>
        </View>
        <View style={[styles.positionPage1Header, { marginTop: '600px' }]}>
          <View style={styles.positionPage1HeaderSection}>
            <Text style={styles.fontNormal}>IAIC GPI POL OR 0321</Text>
          </View>
          <View>
            <Text style={styles.fontNormal}>6</Text>
          </View>
          <View
            style={[
              styles.positionPage1HeaderSection,
              styles.positionPage1HeaderRightSection,
            ]}
          >
            <Text style={styles.fontNormal}>012022</Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <GeneratedPDFViewer
      groupNumber={groupNumber}
      planChoiceId={planChoiceId}
      planId={planId}
      product={product}
    >
      {ORMasterPolicy()}
    </GeneratedPDFViewer>
  );
};

MasterPolicyGenerator.propTypes = {
  address: PropTypes.shape({
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
  }).isRequired,
  coverageFromDate: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  groupNumber: PropTypes.string.isRequired,
  planChoiceId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  renewalDate: PropTypes.string.isRequired,
  stateOfIssue: PropTypes.string.isRequired,
};

export default MasterPolicyGenerator;
