import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from '@reach/router';
import { get } from 'lodash';
import Box from '@basecomponents/Box';
import Route from '../Route';
import EmployeeCensus from '../EmployeeCensus';

/**
 * @category Employee
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const AddEmployee = ({ location, ...rest }) => {
  const params = useParams();
  const groupId = get(params, 'groupId', '');
  return (
    <Route
      header={{
        title: 'Add Employee',
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Add Employee
      </Box>
      <EmployeeCensus
        location={location}
        redirectTo={`/groups/${groupId}/details`}
        {...rest}
      />
    </Route>
  );
};

AddEmployee.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default AddEmployee;
