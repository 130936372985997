import {navigate, useParams} from '@reach/router';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React, {useContext, useRef, useState} from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {get, reject} from 'lodash';
import {ApolloConsumer} from '@apollo/client';
import {composeValidations, required} from '@utils/validators';
import {AuthContext} from '@basecomponents/Auth';
import {useTranslation} from 'react-i18next';
import useSsm from '@src/utilities/hooks/useSsm';
import InputMoney from '@basecomponents/InputMoney';
import {moreThanZero} from '@utils/validations';
import {cleanMoney} from '@petcomponents/GroupDetails';
import ToolbarButton from '../../BaseComponents/ToolbarButton';
import UploadFile from '../UploadFile';
import Route from '../Route';
import InputDropdown from '../../BaseComponents/Dropdown';
import {labelHandler} from '../../../utilities/label-utils';
import remoteActionQuery from '../../../graphql/queries/remote-action.gql';
import Modal from '../../BaseComponents/Modal';
import useSnackbar from '../../../utilities/use-snackbar';
import CountDown from '../../BaseComponents/Counter';
import GetData from '../../../utilities/get-data';
import config from '../../../config.json';

/**
 * @category Group
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const AddRemittance = ({ ...rest }) => {
  const { t } = useTranslation();
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId');
  const conterRef = useRef();
  const parameters = useParams();
  const groupId = get(parameters, 'groupId', '');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [fileError, setFileError] = useState(false);
  const [processing, setProcessing] = useState(false);
  // const [datasetIdData, setDatasetId] = useState(null);
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const chaseEnabled = useSsm('iaic-chase-enabled');
  const universalRemittanceData = useSsm('iaic-universal-remittance');
  const {
    apiData: { BillingAccountType = [] },
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: ' BillingAccountType' }])
  );
  return (
    <Route
      header={{
        title: 'Upload Remittance',
        type: 'billing',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {t('groups.uploadRemittance')}
      </Box>
      <ApolloConsumer>
        {(client) => {
          const checkFile = async () => {
            // const datasetId = datasetIdData || response;
            client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'get-tasks',
                  params: JSON.stringify({
                    flow: 'remittance',
                    groupId,
                    page: 0,
                    size: 1,
                    sort: 'created,desc',
                  }), // name: config.barrelS3Bucket
                  //   ? 'barrel-file-detail'
                  //   : 'get-tasks',
                  // params: config.barrelS3Bucket
                  //   ? JSON.stringify({
                  //       datasetId,
                  //       page: 0,
                  //       size: 1,
                  //       sort: 'created,desc',
                  //     })
                  //   : JSON.stringify({
                  //       flow: 'remittance',
                  //       groupId,
                  //       page: 0,
                  //       size: 1,
                  //       sort: 'created,desc',
                  //     }),
                },
              })
              .then((response) => {
                // const task = config.barrelS3Bucket
                //   ? JSON.parse(response.data.remoteAction.data)
                //   : get(
                //       JSON.parse(get(response, 'data.remoteAction.data', '')),
                //       'content.0',
                //       {}
                //     );
                const task = get(
                  JSON.parse(get(response, 'data.remoteAction.data', '')),
                  'content.0',
                  {}
                );
                if (
                  task?.complete ||
                  (task.status === 'pipeline-completed' &&
                    task?.errorCount === 0)
                ) {
                  setProcessing(false);
                  return setButtonDisabled(false);
                }
                if (task?.errorCount > 0) {
                  return setErrorSnack(
                    `There was an error please check the file`,
                    config.notificationDuration
                  );
                }
                if (get(task, 'status') === 'file-error') {
                  setButtonDisabled(false);
                  setProcessing(false);
                  return setFileError(task.fileError);
                }
                if (
                  [
                    'pending',
                    'errors-pass',
                    'columns-pass',
                    'pipeline-started',
                    'column-check-completed',
                    'column-check-in-progress',
                    'execution-pending',
                    'column-check-required',
                    'column-check-pending',
                    'standardization-completed',
                    'standardization-pending',
                    'standardization-complete',
                  ].includes(get(task, 'status'))
                ) {
                  return checkFile();
                }
                if (
                  [
                    'errors-fail',
                    'columns-fail',
                    'column-check-error',
                    'empty-dataset',
                    'pipeline-errored',
                    'standardization-failed',
                  ].includes(get(task, 'status'))
                ) {
                  setProcessing(false);
                  setButtonDisabled(false);
                  return setErrorSnack(
                    'Unable to process file. Please contact support',
                    config.notificationDuration
                  );
                }
                setButtonDisabled(false);
                return setProcessing(false);
              })
              .catch((e) => {
                setProcessing(false);
                setButtonDisabled(false);
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                );
              });
          };

          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                initialValues={{}}
                onSubmit={async (values) => {
                  const { paymentType, remittanceMethodType } = values;
                  if (remittanceMethodType === 'autoRemittance') {
                    await client
                      .query({
                        fetchPolicy: 'no-cache',
                        query: remoteActionQuery,
                        variables: {
                          name: 'basic-auto-remittance',
                          params: JSON.stringify({
                            groupId,
                            paymentType,
                            userId,
                          }),
                        },
                      })
                      .then(async () => {
                        navigate(`/groups/${groupId}/details`);
                      })
                      .catch((e) => {
                        setErrorSnack(
                          `There was an error: ${e.message}`,
                          config.notificationDuration
                        );
                      });
                  } else {
                    navigate(`/groups/${groupId}/details`);
                  }
                }}
                render={({ values, submitting, handleSubmit, form }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          padding: 3,
                        }}
                      >
                        <Field
                          aria-label={t('groups.paymentType')}
                          component={InputDropdown}
                          label={labelHandler(t('groups.paymentType'), false)}
                          name="paymentType"
                          options={
                            chaseEnabled
                              ? BillingAccountType
                              : reject(
                                  BillingAccountType,
                                  (v) => v.value === 'ACH_AUTOMATIC'
                                )
                          }
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groups.remittanceMethodType')}
                          component={InputDropdown}
                          label={labelHandler(
                            t('groups.remittanceMethodType'),
                            false
                          )}
                          name="remittanceMethodType"
                          options={
                            universalRemittanceData
                              ? [
                                  {
                                    canEnglishValue: null,
                                    frenchValue: 'Téléverser un fichier',
                                    label: t('groups.uploadFile'),
                                    value: 'uploadFile',
                                  },
                                  {
                                    canEnglishValue: null,
                                    frenchValue: 'Versement automatique',
                                    label: t('groups.autoRemittance'),
                                    value: 'autoRemittance',
                                  },
                                  {
                                    canEnglishValue: null,
                                    frenchValue: null,
                                    label: t('groups.universalRemittance'),
                                    value: 'universalRemittance',
                                  },
                                ]
                              : [
                                  {
                                    canEnglishValue: null,
                                    frenchValue: 'Téléverser un fichier',
                                    label: t('groups.uploadFile'),
                                    value: 'uploadFile',
                                  },
                                  {
                                    canEnglishValue: null,
                                    frenchValue: 'Versement automatique',
                                    label: t('groups.autoRemittance'),
                                    value: 'autoRemittance',
                                  },
                                ]
                          }
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        {get(values, 'paymentType', false) &&
                          ['universalRemittance'].includes(
                            get(values, 'remittanceMethodType')
                          ) && (
                            <>
                              <Field
                                aria-label="cashAmountReceived"
                                component={InputMoney}
                                label={labelHandler(
                                  'Cash Amount Received',
                                  false
                                )}
                                name="cashAmountReceived"
                                validate={composeValidations(
                                  required,
                                  moreThanZero
                                )}
                                wrapperSx={{ padding: 3, width: '25rem' }}
                                {...rest}
                              />
                            </>
                          )}
                        {(get(values, 'cashAmountReceived') ||
                          !['universalRemittance'].includes(
                            get(values, 'remittanceMethodType')
                          )) &&
                          get(values, 'paymentType', false) &&
                          ['universalRemittance', 'uploadFile'].includes(
                            get(values, 'remittanceMethodType')
                          ) && (
                            <UploadFile
                              context={{
                                cashAmountReceived: cleanMoney(
                                  get(values, 'cashAmountReceived', null)
                                ),
                                paymentType: get(values, 'paymentType', null),
                              }}
                              fileTypes={[
                                'text/plain',
                                'application/vnd.ms-excel',
                                'text/x-csv',
                                'application/csv',
                                'application/x-csv',
                                'text/csv',
                                'text/comma-separated-values',
                                'text/x-comma-separated-values',
                                'text/tab-separated-values',
                              ]}
                              flow={
                                get(values, 'remittanceMethodType') ===
                                'universalRemittance'
                                  ? 'universalRemittance'
                                  : 'remittance'
                              }
                              form={form}
                              groupId={groupId}
                              onUploadComplete={async (response) => {
                                // eslint-disable-next-line no-unused-expressions
                                // config.barrelS3Bucket && response
                                //   ? await setDatasetId(response)
                                //   : null;
                                await setProcessing(true);
                                conterRef?.current?.reset();
                                checkFile(response);
                              }}
                              {...rest}
                            />
                          )}
                        {fileError && (
                          <Modal
                            closeText="Close"
                            isOpen
                            onClose={() => setFileError(false)}
                            title="Issue Uploading File"
                          >
                            <Box fontWeight="bold" padding="1rem 0">
                              {fileError}
                            </Box>
                            <Box>
                              If this problem persists please contact support.
                            </Box>
                          </Modal>
                        )}
                        {processing && (
                          <Box sx={{ textAlign: 'center' }}>
                            <Box>Remittance Data Is Processing.</Box>
                            <CountDown
                              ref={conterRef}
                              hours={0}
                              minutes={0}
                              seconds={0}
                            />
                          </Box>
                        )}
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            my: 6,
                          }}
                        >
                          <ToolbarButton
                            bg="primaryDark"
                            disabled={
                              (buttonDisabled &&
                                get(values, 'remittanceMethodType') ===
                                  'uploadFile') ||
                              submitting
                            }
                            label="Done"
                            mr={0}
                            submitting={submitting}
                            type="submit"
                            width="150px"
                          />
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

AddRemittance.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default AddRemittance;
