import React, { useContext } from 'react';
import { BarLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import Box from '@basecomponents/Box';

/**
 * @category BaseComponents
 * @param {Object} props
 * @returns {React.FC}
 */
const Spinner = (props) => {
  const { defaultStyle = {} } = useContext(ThemeContext);

  return (
    <Box
      alignItems="center"
      data-testid="spinner"
      display="flex"
      height="10rem"
      justifyContent="center"
      sx={defaultStyle.defaultSpinner.baseContainer}
      {...props}
    >
      <BarLoader color={defaultStyle.defaultSpinner.loaderColor} />
    </Box>
  );
};

export default Spinner;
