import React, { useRef, useState, useContext } from 'react';
import get from 'lodash/get';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
import { ApolloConsumer } from '@apollo/client';
import PropTypes from 'prop-types';
import { AuthContext } from '@basecomponents/Auth';
import { required } from '@utils/validators';
import { labelHandler } from '@utils/label-utils';
import { SORT_VALUE } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import Modal from '../../BaseComponents/Modal';
import Route from '../Route';
import ListGrid from '../../BaseComponents/ListGrid';
import ToolbarButton from '../../BaseComponents/ToolbarButton';
import InputMoney from '../../BaseComponents/InputMoney';
import InputDate from '../../BaseComponents/InputDate';
import numberWithCommas from '../../../utilities/number-with-commas';
import remoteActionQuery from '../../../graphql/queries/remote-action.gql';
import useSnackbar from '../../../utilities/use-snackbar';
import HeaderRightContainer from '../../BaseComponents/HeaderRightContainer';
import config from '../../../config.json';
import dateUtils from '../../../utilities/date-utils';

const cleanMoney = (amount) =>
  parseFloat(String(amount).replace('$', '').replace(',', ''));

// const remittanceBuffer = (remittedAmount) => (value) => {
//   if (cleanMoney(value) < 0.85 * remittedAmount) {
//     return `Confirmed amount must be at least 85% of remitted amount`;
//   }
// };

const getTotal = (confirmData) => {
  const value = Object.values(confirmData)
    .map((obj) => obj.confirmAmount)
    .reduce((prev, curr) => cleanMoney(prev) + cleanMoney(curr), '0');

  return `$${numberWithCommas(parseFloat(value).toFixed(2))}`;
};

const transformConfirmAmount = (data) => {
  const newData = {};
  Object.entries(data).forEach(([key, value]) => {
    newData[key] = value.confirmAmount;
  });
  return newData;
};

const transformConfirmDate = (data) => {
  const newData = {};
  Object.entries(data).forEach(([key, value]) => {
    newData[key] = value.confirmDate.toLocaleDateString();
  });
  return newData;
};

const Label = ({ text }) => {
  return (
    <p
      style={{
        color: '#232323',
        fontSize: '0.8rem',
        fontWeight: 600,
        letterSpacing: '0.05em',
        marginBottom: '0.5em',
        maxWidth: 'fit-content',
        textTransform: 'uppercase',
      }}
    >
      {text}
    </p>
  );
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
};

/**
 * @category Billing
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const AccountsBook = ({ ...rest }) => {
  const { t } = useTranslation()
  const [confirmData, setConfirmData] = useState({});
  const [modalData, setModalData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [total, setTotal] = useState('$0.00');

  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });
  const dataTableRef = useRef();

  const defaultFilterValues = [{ status: 'REMITTED' }];

  const filters = [
    {
      name: 'groupName',
      type: 'Input',
    },
    {
      fieldEnd: 'remittanceEndDate',
      fieldStart: 'remittanceStartDate',
      label: t('metadata.remittanceDate'),
      name: 'remittanceDate',
      type: 'DatePicker',
    },
    {
      label: t('filters.sortBy'),
      name: 'sort',
      options: SORT_VALUE.ACCOUNTS_BOOK_DASHBOARD,
      type: 'Dropdown',
    },
  ];
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId');

  const grid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'billing',
            isDisabled:
              get(data, 'status') !== 'REMITTED' ||
              get(data, 'payrollSchedule.group.disableProcessPayment', false),
            onClick: () => {
              setModalData(data);
              setShowModal(true);
            },
          };
        },
        label: 'Confirm',
        name: 'confirm',
      },
    ],
    fields: [
      {
        name: 'payrollSchedule.group.groupName',
        sortable: true,
        sortName: 'groupNameSort',
      },
      {
        label: t('groups.billingCycle'),
        name: 'payrollSchedule.cycleNo',
        sortable: false,
      },
      {
        name: 'employeeRemittedAmount',
        sortable: true,
      },
      {
        name: 'employerRemittedAmount',
        sortable: true,
      },
      {
        label: t('metadata.remittanceDate'),
        name: 'remittanceDate',
        sortable: true,
        type: 'date',
      },
      {
        name: 'unappliedFunds',
        sortable: true,
      },
      {
        lookupKey: 'groupPaymentRecordId',
        lookupTable: transformConfirmAmount(confirmData),
        name: 'confirmedAmount',
        sortable: true,
        type: 'lookup',
      },
      {
        lookupKey: 'groupPaymentRecordId',
        lookupTable: transformConfirmDate(confirmData),
        name: 'confirmedDate',
        sortable: true,
        type: 'lookup',
      },
    ],
  };

  return (
    <ApolloConsumer>
      {(client) => {
        // const downloadReport = async (groupNumber, payrollScheduleId) => {
        //   await client
        //     .query({
        //       fetchPolicy: 'no-cache',
        //       query: remoteActionQuery,
        //       variables: {
        //         name: 'get-exception-report',
        //         params: JSON.stringify({ groupNumber, payrollScheduleId }),
        //       },
        //     })
        //     .then(async (response) => {
        //       const { s3Key } = JSON.parse(
        //         get(response, 'data.remoteAction.data', '')
        //       );

        //       if (s3Key) {
        //         await downloadData({
        //           s3BucketName: config.amplify.Storage.AWSS3.bucket,
        //           s3KeyName: s3Key,
        //         });
        //         setSuccessSnack(`Exception report successfully downloaded` ,config.notificationDuration);
        //       } else {
        //         setErrorSnack(`No exception report available` ,config.notificationDuration);
        //       }
        //     })
        //     .catch((e) => setErrorSnack(`There was an error: ${e.message}`) ,config.notificationDuration);
        // };

        const onClickAction = async () => {
          const tempConfirmData = { ...confirmData };
          setConfirmData({});
          setTotal('$0');
          const params = Object.entries(confirmData).map(
            ([key, { confirmAmount, confirmDate }]) => {
              return {
                confirmedAmount: cleanMoney(confirmAmount),
                confirmedDate: dateUtils.setAPIDateOnly(confirmDate),
                groupPaymentRecordId: key,
              };
            }
          );
          try {
            await client.query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'process-payments',
                params: JSON.stringify({
                  billedData: params,
                  newBillingApiVersionEnable: config.newBillingApiVersionEnable,
                  userId
                }),
              },
            });
            dataTableRef.current.refetchTableData();
            setSuccessSnack(
              `Exception report successfully created in billing reports section`,
              config.notificationDuration
            );
          } catch (e) {
            setConfirmData(tempConfirmData);
            setTotal(getTotal(tempConfirmData));
            setErrorSnack(
              `There was an error: ${e.message}`,
              config.notificationDuration
            );
          }
        };
        return (
          <Route
            header={{
              rightContainer: (
                <HeaderRightContainer
                  headerActions={[
                    {
                      action: '/billing/dashboard',
                      icon: 'file',
                      label: 'Billing Dashboard',
                    },
                    {
                      action: `/billing/agingReport`,
                      icon: 'file-description',
                      label: 'Aging Report',
                    },
                    {
                      action: `/billing/cashPremium`,
                      icon: 'file-description',
                      label: 'Cash Premium Report',
                    },
                    {
                      action: `/billing/commissionReport`,
                      icon: 'file-description',
                      label: 'Commission Report',
                    },
                  ]}
                  {...rest}
                />
              ),
              title: 'Accounts Book',
              type: 'billing',
            }}
            isPrivate
            permission="billing.page.dashboard"
            {...rest}
          >
            <Box as="h2" sx={{ py: 3 }}>
              Accounts Book
            </Box>
            <FinalForm
              onSubmit={onClickAction}
              render={({ handleSubmit,submitting, values, form }) => {
                return (
                  <form
                    onSubmit={(event) => handleSubmit(event).then(form.reset())}
                    style={{ width: '100%' }}
                  >
                    <Box
                      sx={{
                        alignItems: 'flex-start',
                        bg: 'white',
                        borderRadius: 2,
                        display: 'flex',
                        flexWrap: 'wrap',
                        mb: 5,
                        p: 5,
                        width: '100%',
                      }}
                    >
                      <Field
                        aria-label="Control Amount"
                        component={InputMoney}
                        label={labelHandler('Control Amount')}
                        name="controlAmount"
                        style={{ width: '75%' }}
                        {...rest}
                      />

                      <Box style={{ width: '30%' }}>
                        <Label text="Total Amount" />
                        {total}
                      </Box>

                      <ToolbarButton
                        bg="primaryLight"
                        isDisabled={
                          cleanMoney(values.controlAmount) !== cleanMoney(total)
                        }
                        label="Submit Confirmations"
                        m={0}
                        style={{ marginTop: '1em' }}
                        submitting={submitting}
                        type="submit"
                        width="30%"
                      />
                    </Box>
                  </form>
                );
              }}
            />

            <ListGrid
              ref={dataTableRef}
              defaultFilterValues={defaultFilterValues}
              filters={filters}
              grid={grid}
              gridQueryName="get-all-bills"
              moduleName="billing"
            />

            {showModal === true && (
              <FinalForm
                onSubmit={(values) => {
                  const {
                    payrollSchedule,
                    payrollScheduleId,
                    groupPaymentRecordId,
                  } = modalData;
                  const { group } = payrollSchedule;
                  const { groupNumber } = group;
                  confirmData[groupPaymentRecordId] = {
                    groupNumber,
                    payrollScheduleId,
                    ...values,
                  };
                  setConfirmData(confirmData);
                  setShowModal(false);
                  setTotal(getTotal(confirmData));
                }}
                render={({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Modal
                        closeText={t('common.cancel')}
                        isOpen
                        onClose={() => {
                          delete confirmData[modalData.groupPaymentRecordId];
                          setConfirmData(confirmData);
                          setShowModal(false);
                          setTotal(getTotal(confirmData));
                        }}
                        onSubmit={handleSubmit}
                        submitText="Add"
                        title={t('groupEmployees.dashboardPage.confirmSubmission')}
                      >
                        <Box>
                          <Field
                            aria-label="Confirmed Amount"
                            component={InputMoney}
                            initialValue={
                              confirmData[modalData.groupPaymentRecordId]
                                ? confirmData[modalData.groupPaymentRecordId]
                                  .confirmAmount
                                : null
                            }
                            label={labelHandler('Confirmed Amount', true)}
                            name="confirmAmount"
                            // validate={composeValidations(
                            //   required,
                            //   remittanceBuffer(modalData.remittedAmount)
                            // )}
                            validate={required}
                            {...rest}
                          />
                          <Field
                            aria-label="Confirmed Date"
                            component={InputDate}
                            initialValue={
                              confirmData[modalData.groupPaymentRecordId]
                                ? confirmData[modalData.groupPaymentRecordId]
                                  .confirmDate
                                : null
                            }
                            label={labelHandler('Confirmed Date', true)}
                            name="confirmDate"
                            validate={required}
                            {...rest}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              fontWeight: 'bold',
                              justifyContent: 'center',
                              my: 5,
                            }}
                          >
                            THIS ACTION CANNOT BE UNDONE
                          </Box>
                          Add payment confirmations?
                        </Box>
                      </Modal>
                    </form>
                  );
                }}
              />
            )}
          </Route>
        );
      }}
    </ApolloConsumer>
  );
};

export default AccountsBook;
