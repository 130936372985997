import { useParams } from '@reach/router';
import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import Route from '../Route';
import ListGrid from '../../BaseComponents/ListGrid';
import HeaderRightContainer from '../../BaseComponents/HeaderRightContainer';

const filters = [
  {
    name: 'message',
    type: 'Input',
  },
  {
    name: 'column',
    type: 'dateTime',
  },
];
const grid = {
  actions: [],
  fields: [
    {
      name: 'message',
    },
    {
      name: 'column',
    },
    {
      name: 'row',
    },
    {
      name: 'value',
    },
  ],
};

/**
 * @category File
 * @param {Object} location
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const ErrorPage = ({ location, ...rest }) => {
  const queryParams = useParams();
  const isFiles = get(location, 'pathname', '').includes('/groups/errors');
  return (
    <Route
      header={{
        rightContainer: (
          <HeaderRightContainer
            headerActions={[
              {
                action: isFiles ? '/groups/templateStatus' : '/files/dashboard',
                icon: 'file',
                label: 'View Files',
              },
            ]}
          />
        ),
        title: 'File Dashboard',
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Files Dashboard - Errors
      </Box>
      <ListGrid
        defaultFilterValues={[{ taskId: get(queryParams, 'taskId') }]}
        filters={filters}
        grid={grid}
        gridQueryName="get-errors"
        moduleName="errors"
      />
    </Route>
  );
};

ErrorPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
};
export default ErrorPage;
