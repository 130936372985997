import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';

/**
 * @category BaseComponents
 * @param {boolean} isSelected
 * @param {string} label
 * @param {object} selectProps
 * @returns {React.FC}
 */
const Option = (props) => {
  const { isSelected, label, selectProps } = props;
  const { checkIcon, uncheckIcon } = selectProps;
  const { defaultStyle = {} } = useContext(ThemeContext);
  const { dropDownOptionBox = {} } = defaultStyle.defaultDropDown;
  return (
    <components.Option {...props}>
      <Box sx={{ ...dropDownOptionBox }}>
        {checkIcon && uncheckIcon && (
          <Box mr={2}>{isSelected ? checkIcon : uncheckIcon}</Box>
        )}
        <Box>{label}</Box>
      </Box>
    </components.Option>
  );
};

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  selectProps: PropTypes.shape({
    checkIcon: PropTypes.node.isRequired,
    uncheckIcon: PropTypes.node.isRequired,
  }).isRequired,
};

export default Option;
