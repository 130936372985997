import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import Arial from '../../../../assets/fonts/Arial.woff';
import ArialBold from '../../../../assets/fonts/ArialBold.woff';
import integerToRoman from '../../../../utilities/number-to-roman';

Font.register({ family: 'Arial', src: Arial });
Font.register({ family: 'ArialBold', src: ArialBold });

/**
 * @category TBD
 * @param {(React.ReactNode|string)} points
 * @param {('number'|'alpha')} type
 * @param {Object} parentStyleObject
 * @param {Object} pointStyleObject
 * @param {number} startNumber
 * @returns {React.FC}
 */
const OrderedList = ({
  points,
  type,
  parentStyleObject,
  pointStyleObject,
  startNumber,
}) => {
  const styles = StyleSheet.create({
    bulletItem: {
      flexDirection: 'row',
    },
    bulletPoint: {
      width: 20,
    },
  });

  return (
    <View style={parentStyleObject}>
      {points.map((point, index) => {
        let pointIndex = startNumber + index;
        if (type === 'alpha') {
          pointIndex = String.fromCharCode(startNumber + index + 96);
        } else if (type === 'rs') {
          pointIndex = integerToRoman({
            number: startNumber + index,
            type: 'smallcase',
          });
        }
        return (
          <View
            key={`oli-${index}`}
            style={[styles.bulletItem, pointStyleObject]}
          >
            <Text style={[styles.bulletPoint]}>{pointIndex}.</Text>
            {point}
          </View>
        );
      })}
    </View>
  );
};

export default OrderedList;

OrderedList.propTypes = {
  parentStyleObject: PropTypes.shape({}),
  points: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ).isRequired,
  pointStyleObject: PropTypes.shape({}),
  startNumber: PropTypes.number,
  type: PropTypes.oneOf(['number', 'alpha']).isRequired,
};

OrderedList.defaultProps = {
  parentStyleObject: {},
  pointStyleObject: {},
  startNumber: 1,
};
