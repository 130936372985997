import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import GetData from '../../../../../utilities/get-data';
import getMetaData from '../../../../../utilities/get-meta-data';
import Spinner from '../../../../BaseComponents/Spinner';
import ListItem from '../../../../BaseComponents/ListGrid/ListItem';

/**
 * @category QLE
 * @param {string} {employeeId}
 * @returns {React.FC}
 */
const CoverageDetailPage = ({ employeeId }) => {
  const { apiData = {}, loading } = GetData(
    'get-coverage-info',
    JSON.stringify({ employeeId }),
    employeeId === null
  );
  const planId = get(apiData, 'employeePlanConfirmation.planId', '');
  const { apiData: planPricing = [] } = GetData(
    'get-plan-pricing',
    JSON.stringify({ planId }),
    !planId
  );
  const employeePlanChoices = get(apiData, 'planChoiceEmployees', []);
  const selectedPlan = employeePlanChoices
    .filter((item) => item.acceptGroupPlanChoice)
    .map((item) => {
      if (Array.isArray(planPricing)) {
        const plan =
          planPricing.find((v) => v.planChoiceId === item.planChoiceId) || {};
        return { ...item, ...plan };
      }
      return item;
    });

  const dynamicFields = {
    'Accident & Illness': [
      { name: 'productDetails.name' },
      { name: 'coverageFromDate' },
      { name: 'coverageToDate' },
      { name: 'annualLimit' },
      { name: 'annualDeductible' },
      { name: 'coinsurance' },
      { name: 'diminishingDeductible' },
      { name: 'copay' },
      { name: 'wellness' },
      { name: 'riderRPT' },
      { name: 'riderICC' },
      { name: 'riderOETC' },
      { name: 'riderABC' },
      { name: 'riderFR' },
      { name: 'riderRD' },
      { name: 'poaAR' },
      { name: 'bwpInjuries' },
      { name: 'bwpIllness' },
      { name: 'bwpCLI' },
      { name: 'existingCondition' },
      { name: 'maxAge' },
      { name: 'coverageCredit' },
      { name: 'enrollmentFromDate' },
      { name: 'enrollmentToDate' },
      { name: 'planPrice' },
    ],
    'Accident Only': [
      { name: 'productDetails.name' },
      { name: 'coverageFromDate' },
      { name: 'coverageToDate' },
      { name: 'annualLimit' },
      { name: 'annualDeductible' },
      { name: 'coinsurance' },
      { name: 'riderRPT' },
      { name: 'riderOETC' },
      { name: 'riderAC' },
      { name: 'riderFR' },
      { name: 'riderRD' },
      { name: 'poaAR' },
      { name: 'bwpInjuries' },
      { name: 'bwpCLI' },
      { name: 'existingCondition' },
      { name: 'enrollmentFromDate' },
      { name: 'enrollmentToDate' },
      { name: 'planPrice' },
    ],
  };

  const {plan} = getMetaData({ moduleName: 'groups' });
  return loading ? (
    <Spinner />
  ) : (
    <ListItem
      data={selectedPlan}
      grid={{
        fields: dynamicFields[get(selectedPlan, '0.productDetails.name')],
      }}
      meta={plan}
    />
  );
};

CoverageDetailPage.propTypes = {
  employeeId: PropTypes.string.isRequired,
};

export default CoverageDetailPage;
