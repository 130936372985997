import { useQuery } from '@apollo/client';
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import Box from '@basecomponents/Box';
import remoteActionQuery from '../../../../graphql/queries/remote-action.gql';
import ToolbarButton from '../../../BaseComponents/ToolbarButton';

const POLL_INTERVAL = 10000;

/**
 * @category Files
 * @param {string} datasetId Indicates the unique data set ID
 * @param {Function} onClick
 * @param {string} timestamp
 * @returns {React.FC}
 */

const DataModifiedButton = ({ datasetId, onClick, timestamp }) => {
  const { data, refetch, startPolling, stopPolling } = useQuery(
    remoteActionQuery,
    {
      fetchPolicy: 'network-only',
      skip: !timestamp || !datasetId,
      variables: {
        name: 'barrel-file-modified',
        params: JSON.stringify({ datasetId, timestamp }),
      },
    }
  );
  const result = get(data, 'remoteAction.data') === 'true';
  if (result) {
    stopPolling();
  } else {
    startPolling(POLL_INTERVAL);
  }

  useIdleTimer({
    onActive: () => (result ? stopPolling() : startPolling(POLL_INTERVAL)),
    onIdle: () => stopPolling(),
    timeout: 1000 * 60 * 2.5,
  });

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      width="100%"
    >
      <ToolbarButton
        icon="refresh"
        label="New Events available, Click here to refresh"
        onClick={async () => {
          await onClick();
          await refetch();
        }}
        visible={result}
      />
    </Box>
  );
};
DataModifiedButton.defaultProps = {
  onClick: () => null,
  timestamp: null,
};
DataModifiedButton.propTypes = {
  datasetId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  timestamp: PropTypes.number,
};

export default DataModifiedButton;
