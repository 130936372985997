import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import Button from '@basecomponents/Button';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { required } from '@utils/validators';
import GetData from '@utils/get-data';
import { labelHandler } from '@utils/label-utils';
import useSnackbar from '@utils/use-snackbar';
import generateRedirectPath from '@utils/generate-redirect-path';
import InputText from '@basecomponents/Input';
import InputDropdown from '@basecomponents/Dropdown';
import InputDisabled from '@basecomponents/InputDisabled';
import InputWeight from '@basecomponents/InputWeight';
import Spinner from '@basecomponents/Spinner';
import ToolbarButton from '@basecomponents/ToolbarButton';
import ButtonGroups from '@basecomponents/ButtonGroups';
import remoteActionQuery from '@queries/remote-action.gql';
import config from '@src/config.json';
import useSsm from '@src/utilities/hooks/useSsm';

const greaterThanValidation = (minArg) => (valArg) => {
  const min = parseInt(minArg, 10);
  const value = parseInt(valArg, 10);

  if (min && value && min >= value) {
    return `Must be greater than ${minArg}`;
  }
};

const commonFieldSxHalf = {
  padding: 3,
  width: '25rem',
};

const uncommonFieldSxHalf = {
  display: 'inline-block',
  float: 'left',
  margin: 0,
  padding: 2,
  width: '50%',
};

const seq = (start, skip, count, label) => {
  return Array(count)
    .fill()
    .map((_, index) => {
      const v = start + index * skip;
      const labelValue = label.right ? v + label.icon : label + v;
      return {
        canEnglishValue: `${labelValue}`,  // Assuming this format for demonstration
        frenchValue: `${labelValue}`,       // Assuming this format for demonstration
        label: labelValue,
        value: v,
      };
    });
};

const Disclaimer = ({ text }) => {
  return (
    <p
      style={{
        color: '#7A7A7A',
        fontSize: '0.8rem',
        maxWidth: '25rem',
        padding: '0.5rem',
        textTransform: 'uppercase',
      }}
    >
      {text}
    </p>
  );
};

Disclaimer.propTypes = {
  text: PropTypes.string.isRequired,
};

const WeightTitles = ({ text }) => {
  return (
    <p
      style={{
        color: '#232323',
        fontSize: '0.8rem',
        fontWeight: 600,
        letterSpacing: '0.05em',
        marginLeft: '0.375rem',
        marginTop: '2rem',
        maxWidth: 'fit-content',
        textTransform: 'uppercase',
      }}
    >
      {text}
    </p>
  );
};

WeightTitles.propTypes = {
  text: PropTypes.string.isRequired,
};

const PlanSelectionForm = ({
  isPassiveRenewal,
  instance,
  values,
  noOfEmployees,
  productOptions,
  products,
  ...rest
}) => {
  const { t } = useTranslation();
  const isPassiveRenewalEnabled = useSsm('iaic-passive-renewal')
  return (
    <Box
      sx={{
        borderRadius: 3,
        boxShadow: 7,
        height: 'fit-content',
        m: 4,
        p: 3,
      }}
    >
      <Field
        aria-label={t('metadata.name')}
        component={InputText}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.name'), true)}
        name={`planName_${instance}`}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      <Field
        aria-label={t('metadata.product')}
        component={InputDropdown}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.product'), true)}
        name={`productId_${instance}`}
        options={productOptions}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      <Field
        aria-label={t('metadata.annualLimit')}
        component={InputDropdown}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.annualLimit'), true)}
        name={`annualLimit_${instance}`}
        options={[
          { canEnglishValue: null, frenchValue: null, label: '$500', value: 500 },
          ...seq(1000, 1000, 25, '$'),
          { canEnglishValue: null, frenchValue: 'Illimité', label: 'Unlimited', value: 0 },
        ]}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      <Field
        aria-label={t('metadata.annaulDeductible')}
        component={InputDropdown}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.annaulDeductible'), true)}
        name={`annualDeductible_${instance}`}
        options={[...seq(0, 50, 21, '$'), ...seq(1250, 250, 6, '$')]}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      <Field
        aria-label={t('metadata.coinsurance')}
        component={InputDropdown}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.coinsurance'), true)}
        name={`coinsurance_${instance}`}
        options={[...seq(50, 10, 6, { icon: '%', right: true })]}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      {values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Field
          aria-label={t('metadata.diminishingDeductible')}
          component={InputDropdown}
          disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
          label={labelHandler(t('metadata.diminishingDeductible'), true)}
          name={`diminishingDeductible_${instance}`}
          options={[...seq(25, 25, 2, '$'), { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 0 }]}
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      ) : null}
      {values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Field
          aria-label={t('metadata.copay')}
          component={InputDropdown}
          disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
          label={labelHandler(t('metadata.copay'), true)}
          name={`copay_${instance}`}
          options={[...seq(25, 25, 3, '$'), { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 0 }]}
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      ) : null}
      {values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Field
          aria-label={t('metadata.nonriderWellness')}
          component={InputDropdown}
          disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
          label={labelHandler(t('metadata.nonriderWellness'), true)}
          name={`wellness_${instance}`}
          options={[
            { canEnglishValue: null, frenchValue: 'Barème A', label: 'Schedule A', value: 'Schedule A' },
            { canEnglishValue: null, frenchValue: 'Barème B', label: 'Schedule B', value: 'Schedule B' },
            { canEnglishValue: null, frenchValue: 'Barème C', label: 'Schedule C', value: 'Schedule C' },
            { canEnglishValue: null, frenchValue: 'Barème D', label: 'Schedule D', value: 'Schedule D' },
            { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 'Not Included' },
          ]}
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      ) : null}
      <Field
        aria-label={t('metadata.nonriderRPT')}
        component={InputDropdown}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.nonriderRPT'), true)}
        name={`riderRPT_${instance}`}
        options={[
          { canEnglishValue: null, frenchValue: 'Inclus', label: 'Included', value: 1 },
          { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 0 },
        ]}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      {values[`riderRPT_${instance}`] === 1 &&
        values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Disclaimer text="Subject to Deductible Coinsurance and, if applicable, Per Incident Copay" />
      ) : null}
      <Field
        aria-label={t('metadata.nonriderOETC')}
        component={InputDropdown}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.nonriderOETC'), true)}
        name={`riderOETC_${instance}`}
        options={[
          { canEnglishValue: null, frenchValue: 'Inclus', label: 'Included', value: 1 },
          { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 0 },
        ]}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      {values[`riderOETC_${instance}`] === 1 &&
        values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Disclaimer text="Subject to Deductible Coinsurance and, if applicable, Per Incident Copay" />
      ) : null}
      {values[`productId_${instance}`] === products['Accident Only'] ? (
        <Field
          aria-label={t('metadata.nonriderAc')}
          component={InputDropdown}
          disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
          label={labelHandler(t('metadata.nonriderAc'), true)}
          name={`riderAC_${instance}`}
          options={[
            { canEnglishValue: null, frenchValue: 'Inclus', label: 'Included', value: 1 },
            { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 0 },
          ]}
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      ) : null}
      {values[`riderAC_${instance}`] === 1 &&
        values[`productId_${instance}`] === products['Accident Only'] ? (
        <Disclaimer text="Subject to Deductible Coinsurance and, if applicable, Per Incident Copay. Behavioral Care benefit subject to $1000 annual limit and 14-day Benefit Waiting Period" />
      ) : null}
      {values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Field
          aria-label={t('metadata.riderAc')}
          component={InputDropdown}
          disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
          label={labelHandler(t('metadata.riderAc'), true)}
          name={`riderABC_${instance}`}
          options={[
            { canEnglishValue: null, frenchValue: 'Inclus', label: 'Included', value: 1 },
            { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 0 },
          ]}
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      ) : null}
      {values[`riderABC_${instance}`] === 1 &&
        values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Disclaimer text="Subject to Deductible Coinsurance and, if applicable, Per Incident Copay. Behavioral Care benefit subject to $1000 annual limit and 14-day Benefit Waiting Period" />
      ) : null}
      <Field
        aria-label={t('metadata.finalRespects')}
        component={InputDropdown}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.finalRespects'), true)}
        name={`riderFR_${instance}`}
        options={[
          { canEnglishValue: null, frenchValue: null, label: '$250', value: 250 },
          { canEnglishValue: null, frenchValue: null, label: '$300', value: 300 },
          { canEnglishValue: null, frenchValue: null, label: '$500', value: 500 },
          { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 0 },
        ]}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />{' '}
      {values[`riderFR_${instance}`] > 0 &&
        values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Disclaimer text="Not subject to Deductible, Coinsurance or Per Incident Copay. Paid in excess of Annual Limit" />
      ) : null}
      <Field
        aria-label={t('metadata.routineDental')}
        component={InputDropdown}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.routineDental'), true)}
        name={`riderRD_${instance}`}
        options={[...seq(100, 50, 11, '$'), { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 0 }]}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      {values[`riderRD_${instance}`] > 0 &&
        values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Disclaimer text="Not subject to Deductible, Coinsurance or Per Incident Copay. Paid in excess of Annual Limit" />
      ) : null}
      <Field
        aria-label={t('metadata.poaAR')}
        component={InputDropdown}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.poaAR'), true)}
        name={`poa_${instance}`}
        options={[
          { canEnglishValue: null, frenchValue: 'Inclus', label: 'Included', value: 1 },
          { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 0 },
        ]}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />{' '}
      {values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Field
          aria-label={config.canadaEnv ? t('metadata.inheritedConditionRider') : "Inherited and Congenital Care"}
          component={InputDropdown}
          disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
          label={labelHandler(config.canadaEnv ? t('metadata.inheritedConditionRider') : 'Inherited and Congenital Care', true)}
          name={`riderICC_${instance}`}
          options={[
            { canEnglishValue: null, frenchValue: 'Inclus', label: 'Included', value: 1 },
            { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 0 },
          ]}
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      ) : null}
      {values[`riderICC_${instance}`] === 1 &&
        values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Disclaimer text="Subject to Deductible Coinsurance and, if applicable, Per Incident Copay, 30-day benefit waiting period applies" />
      ) : null}
      <Field
        aria-label={t('metadata.bwpInjuries')}
        component={InputDropdown}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.bwpInjuries'), true)}
        name={`bwpInjuries_${instance}`}
        options={[
          { canEnglishValue: null, frenchValue: '1 jour', label: '1 day', value: 1 },
          { canEnglishValue: null, frenchValue: 'Renonciation', label: 'Waived', value: 0 },
        ]}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      {values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Field
          aria-label={t('metadata.bwpIllness')}
          component={InputDropdown}
          disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
          label={labelHandler(t('metadata.bwpIllness'), true)}
          name={`bwpIllness_${instance}`}
          options={[
            { canEnglishValue: null, frenchValue: '7 jour', label: '7 days', value: 7 },
            { canEnglishValue: null, frenchValue: '10 jour', label: '10 days', value: 10 },
            { canEnglishValue: null, frenchValue: '14 jour', label: '14 days', value: 14 },
            { canEnglishValue: null, frenchValue: 'Renonciation', label: 'Waived', value: 0 },
          ]}
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      ) : null}
      {values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Field
          aria-label={t('metadata.maxAge')}
          component={InputDropdown}
          disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
          label={labelHandler(t('metadata.maxAge'), true)}
          name={`maxAge_${instance}`}
          options={[
            ...seq(5, 1, 9, { icon: t('metadata.years'), right: true }),
            { canEnglishValue: null, frenchValue: 'Aucune', label: 'None', value: 0 },
          ]}
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      ) : null}
      <Field
        aria-label={t('metadata.preExistingCondition')}
        component={InputDropdown}
        disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
        label={labelHandler(t('metadata.preExistingCondition'), true)}
        name={`existingCondition_${instance}`}
        options={[
          {
            canEnglishValue: null, frenchValue: "Couverture après 12 mois (la période rétrospective débute à la date de naissance)",
            label:
              'Covered after 12 months (look back period is from date of birth of pet)',
            value: 0,
          },
          {
            canEnglishValue: null, frenchValue: "Période rétrospective de 6 mois, puis la couverture entre en vigueur après 12 mois",
            label: '6 months look back, then covered after 12 months',
            value: 6,
          },
          {
            canEnglishValue: null, frenchValue: "Période rétrospective de 12 mois, puis la couverture entre en vigueur après 12 mois",
            label: '12 months look back, then covered after 12 months',
            value: 12,
          },
          {
            canEnglishValue: null, frenchValue: "20 nombre de jours d’attente, puis couvert après 12 mois",
            label: '20 day look back, then covered after 12 months',
            value: 20,
          },
          {
            canEnglishValue: null, frenchValue: "30 nombre de jours d’attente, puis couvert après 12 mois",
            label: '30 day look back, then covered after 12 months',
            value: 30,
          },
          {
            canEnglishValue: null, frenchValue: "60 nombre de jours d’attente, puis couvert après 12 mois",
            label: '60 day look back, then covered after 12 months',
            value: 60,
          },
        ]}
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      {values[`productId_${instance}`] === products['Accident & Illness'] ? (
        <Field
          aria-label={t('metadata.coverageCredit')}
          component={InputDropdown}
          disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
          label={labelHandler(t('metadata.coverageCredit'), true)}
          name={`coverageCredit_${instance}`}
          options={[
            { canEnglishValue: null, frenchValue: 'Inclus', label: 'Included', value: 1 },
            { canEnglishValue: null, frenchValue: 'Non inclus', label: 'Not Included', value: 0 },
          ]}
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      ) : null}
      <div style={commonFieldSxHalf}>
        <WeightTitles text="Weight Band A (lbs)" />
        <InputDisabled
          input={{ name: {} }}
          label={labelHandler('Minimum', false)}
          meta={{ error: {} }}
          value={0}
          wrapperSx={uncommonFieldSxHalf}
          {...rest}
        />
        <Field
          aria-label="Maximum"
          component={InputWeight}
          disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
          label={labelHandler('Maximum', false)}
          name={`weightLimitA_${instance}`}
          validate={(required, greaterThanValidation(0))}
          wrapperSx={{ ...uncommonFieldSxHalf }}
          {...rest}
        />
      </div>
      {values[`weightLimitA_${instance}`] ? (
        <div style={commonFieldSxHalf}>
          <WeightTitles text="Weight Band B (lbs)" />
          <InputDisabled
            input={{ name: {} }}
            label={labelHandler('Minimum', false)}
            meta={{ error: {} }}
            value={parseInt(values[`weightLimitA_${instance}`], 10) + 1}
            wrapperSx={uncommonFieldSxHalf}
            {...rest}
          />
          <Field
            aria-label="Maximum"
            component={InputWeight}
            data-testId="weightLimitB_0"
            disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
            label={labelHandler('Maximum', false)}
            name={`weightLimitB_${instance}`}
            validate={greaterThanValidation(values[`weightLimitA_${instance}`])}
            wrapperSx={{ ...uncommonFieldSxHalf }}
            {...rest}
          />
        </div>
      ) : null}
      {values[`weightLimitA_${instance}`] &&
        values[`weightLimitB_${instance}`] ? (
        <div style={commonFieldSxHalf}>
          <WeightTitles text="Weight Band C (lbs)" />
          <InputDisabled
            input={{ name: {} }}
            label={labelHandler('Minimum', false)}
            meta={{ error: {} }}
            value={parseInt(values[`weightLimitB_${instance}`], 10) + 1}
            wrapperSx={uncommonFieldSxHalf}
            {...rest}
          />
          <Field
            aria-label="Maximum"
            component={InputWeight}
            data-testId="weightLimitC_0"
            disabled={isPassiveRenewalEnabled && isPassiveRenewal ? true : noOfEmployees > 0}
            label={labelHandler('Maximum', false)}
            name={`weightLimitC_${instance}`}
            validate={greaterThanValidation(values[`weightLimitB_${instance}`])}
            wrapperSx={{ ...uncommonFieldSxHalf }}
            {...rest}
          />
        </div>
      ) : null}
      {values[`weightLimitA_${instance}`] &&
        values[`weightLimitB_${instance}`] &&
        values[`weightLimitC_${instance}`] ? (
        <div style={commonFieldSxHalf}>
          <WeightTitles text="Weight Band D (lbs)" />
          <InputDisabled
            data-testId="weightLimitD_0"
            input={{ name: {} }}
            label={labelHandler('Minimum', false)}
            meta={{ error: {} }}
            value={parseInt(values[`weightLimitC_${instance}`], 10) + 1}
            wrapperSx={uncommonFieldSxHalf}
            {...rest}
          />
        </div>
      ) : null}
    </Box>
  )
};

PlanSelectionForm.propTypes = {
  isPassiveRenewal: PropTypes.bool,
  instance: PropTypes.number.isRequired,
  noOfEmployees: PropTypes.number,
  productOptions: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  products: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
};
PlanSelectionForm.defaultProps = {
  isPassiveRenewal: false,
  noOfEmployees: 0,
};
const parsePlans = (plans) => {
  const response = {};
  if (plans.length) {
    plans.forEach((plan, index) => {
      const { planId, name, group, product, ...coverage } = plan;
      const newPlan = coverage;
      newPlan.planName = name;
      newPlan.planId = planId;
      newPlan.groupId = group;
      newPlan.productId = product;
      Object.keys(newPlan).forEach((key) => {
        if (key.startsWith('poa')) {
          response[`poa_${index}`] = newPlan[key];
        } else {
          response[`${key}_${index}`] = newPlan[key];
        }
      });
      response[`weightMinA_${index}`] = 0;
    });
  }
  return response;
};

/**
 * @category ProductAdmin
 * @param {Object} location
 * @param {string} name
 * @param {function} setProducts
 * @param {*} rest
 * @returns {React.FC}
 */
const PlanConfiguration = ({
  location,
  name,
  setProducts,
  isRenewal,
  ...rest
}) => {
  const { t } = useTranslation();
  const parameters = useParams();
  const groupId = get(parameters, 'groupId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'primary' });

  const products = {};
  const productOptions = [];
  const productObject = GetData('get-product', JSON.stringify({})).apiData;

  if (Array.isArray(productObject)) {
    productObject.forEach((product) => {
      if (config.canadaEnv && product.envFlag === 'CA') {
        products[product.name] = product.productId;
        productOptions.push({
          canEnglishValue: null,
          frenchValue: product.frenchValue,
          label: product.name,
          value: product.productId
        });
      } else if (!config.canadaEnv && product.envFlag === 'US') {
        products[product.name] = product.productId;
        productOptions.push({ label: product.name, value: product.productId });
      }
    });
  }

  const { apiData = [], loading } = GetData(
    'get-plan-config',
    JSON.stringify({
      group: groupId,
    })
  );
  const { apiData: groupData = {}, loading: groupLoading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );

  const isIAICAdministeredGroup = groupData?.groupType === "IAIC_ADMINISTRATED";
  const isPassiveRenewalEnabled = useSsm('iaic-passive-renewal')
  const isPassiveRenewal = groupData?.passiveRenewal
  const isRemovePlanDisabled = isIAICAdministeredGroup && isPassiveRenewalEnabled && isPassiveRenewal && isRenewal
  const [planCount, setPlanCount] = useState(1);


  useEffect(() => {
    if (apiData && apiData.length) {
      setPlanCount(Math.max(apiData.length, 1));
    }
  }, [loading]);

  const maxPlans = 2;

  const formatPlans = (values) => {
    const trfVal = {};

    Object.keys(values).forEach((field) => {
      const planNo = field.charAt(field.length - 1);
      if (!(planNo in trfVal)) {
        trfVal[planNo] = {};
      }
      const fieldName = field.slice(0, -2);
      if (fieldName === 'poa') {
        trfVal[planNo].poaTTPDL = values[field];
        trfVal[planNo].poaAR = values[field];
        trfVal[planNo].poaVC = values[field];
        trfVal[planNo].poaLP = values[field];
        trfVal[planNo].poaBF = values[field];
      } else {
        trfVal[planNo][fieldName] = values[field];
      }
    });

    Object.keys(trfVal).forEach((planNo) => {
      trfVal[planNo].groupId = groupId;
    });

    const plans = Object.entries(trfVal)
      .map(([key, dict]) => {
        const temp = dict;
        temp.instance = key;
        return temp;
      })
      .slice(0, planCount);

    setProducts(plans);
    return plans;
  };

  if (loading || groupLoading) {
    return <Spinner />;
  }

  return (
    <ApolloConsumer>
      {(client) => {
        const apiCall = async (name, values) => {
          return client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name,
                params: JSON.stringify(values),
              },
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        };

        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={parsePlans(apiData)}
              onSubmit={async (values) => {
                await apiCall('create-plan-config', formatPlans(values)).then(
                  () => {
                    setSuccessSnack(
                      'Created plan configuration successfully.',
                      5000
                    );
                    navigate(
                      generateRedirectPath({
                        ...location,
                        queryParams: { [name]: isRenewal ? 8 : 2 },
                      })
                    );
                  }
                );
              }}
              render={(formContext) => {
                return (
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 3,
                      }}
                    >
                      <Box
                        as="h3"
                        color="black"
                        display="flex"
                        justifyContent="flex-start"
                        sx={{
                          alignSelf: 'flex-start',
                          color: 'primary',
                          margin: '20px 0px 20px 0px;',
                          padding: 3,
                          width: '25rem',
                        }}
                      >
                        Plan Setup
                      </Box>
                      <ButtonGroups>
                        {planCount !== maxPlans ? (
                          <Button
                            disabled={get(groupData, 'noOfEmployees', 0) > 0}
                            mx="auto"
                            my={4}
                            onClick={() => {
                              setPlanCount(planCount + 1);
                            }}
                            sx={{ bg: 'primary', width: '10rem' }}
                          >
                            Add Plan
                          </Button>
                        ) : null}

                        {planCount !== 1 ? (
                          <Button
                            disabled={(get(groupData, 'noOfEmployees', 0) > 0) || isRemovePlanDisabled}
                            marginLeft="auto"
                            mr={0}
                            onClick={() => {
                              setPlanCount(planCount - 1);
                            }}
                            sx={{ bg: 'secondary', width: '10rem' }}
                          >
                            Remove Plan
                          </Button>
                        ) : null}
                      </ButtonGroups>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: ['column', null, null, null, 'row'],
                        }}
                      >
                        {Array(planCount)
                          .fill()
                          .map((el, i) => {
                            return (
                              <PlanSelectionForm
                                key={i}
                                instance={i}
                                isPassiveRenewal={isPassiveRenewal}
                                noOfEmployees={get(groupData, 'noOfEmployees', 0)}
                                productOptions={productOptions}
                                products={products}
                                values={formContext.values}
                                {...rest}
                              />
                            );
                          })}
                      </Box>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          my: 6,
                          width: '25rem',
                        }}
                      >
                        <ToolbarButton
                          bg="primaryLight"
                          label={t('common.previous')}
                          ml={0}
                          onClick={() =>
                            navigate(
                              generateRedirectPath({
                                ...location,
                                queryParams: { [name]: isRenewal ? 6 : 0 },
                              })
                            )
                          }
                          width="10rem"
                        />
                        <ToolbarButton
                          bg="primaryDark"
                          isDisabled={formContext.submitting}
                          label={t('common.next')}
                          mr={0}
                          submitting={formContext.submitting}
                          type="submit"
                          width="10rem"
                        />
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

PlanConfiguration.propTypes = {
  isRenewal: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  setProducts: PropTypes.func.isRequired,
};

PlanConfiguration.defaultProps = {
  isRenewal: false,
  name: 'step',
};

export default PlanConfiguration;
