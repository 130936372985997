import { isEmpty, get } from "lodash";
import config from '@src/config.json'
import GetData from "../get-data";

export default function useSsm(paramName) {
    const { apiData: paramsData = {} } = GetData(
        'get-ssm-params',
        JSON.stringify({ paramName })
    );
    const enabledFlag = !isEmpty(get(paramsData, 'value'))
        ? JSON.parse(get(paramsData, 'value', '{}'))
        : false;
    return get(enabledFlag, `${config.environment}`, false)
}
