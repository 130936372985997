import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ApolloConsumer } from '@apollo/client';
import Box from '@basecomponents/Box';
import Modal from '@basecomponents/Modal';
import { useTranslation } from 'react-i18next';
import downloadData from '../../../utilities/download-file';
import config from '../../../config.json';
import ToolbarButton from '../../BaseComponents/ToolbarButton';
import remoteActionQuery from '../../../graphql/queries/remote-action.gql';
import useSnackbar from '../../../utilities/use-snackbar';
import ListGrid from '../../BaseComponents/ListGrid';

/**
 * @category Billing
 * @param {boolean} actionDisabled Inidcating whether the button is disabled
 * @param {string} groupId Indicating unique Group ID
 * @param {string} groupName Indicating unique Group Number
 * @param {string} payrollScheduleId Indicating Payroll Schedule ID
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const BillingReports = ({
  actionDisabled,
  groupId,
  groupNumber,
  payrollDeductionReportLevel,
  payrollScheduleId,
}) => {
  const { t } = useTranslation()
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [filterPayrollScheduleId, clearPayrollSchedule] = useState(true);
  const [setSuccessSnack] = useSnackbar({ color: 'primary' });
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dataTableRef = useRef();

  const defaultFilterValues =
    filterPayrollScheduleId && payrollScheduleId
      ? [
        { groupId },
        { groupNumber },
        { payrollScheduleId },
        { sort: 'created,desc' },
      ]
      : [{ groupId }, { groupNumber }, { sort: 'created,desc' }];

  const grid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'download',
            onClick: async () => {
              await downloadData({
                s3BucketName: config.amplify.Storage.AWSS3.bucket,
                s3KeyName: get(data, 'url', ''),
              });
            },
          };
        },
        label: 'Download',
        name: 'download',
      },
      // [Prod] [#583] Temp commented out until we have a way to download the invoice for
      {
        getProps: (data) => {
          return {
            icon: 'download',
            onClick: async () => {
              await downloadData({
                s3BucketName: config.amplify.Storage.AWSS3.bucket,
                s3KeyName: get(data, 'invoiceUrl', ''),
              });
            },
            visible:
              get(data, 'invoiceUrl') && config.newBillingApiVersionEnable,
          };
        },
        label: 'Download Invoice',
        name: 'download invoice',
      },
    ],
    fields: [{ name: 'name' }, { name: 'reportType' }, { name: 'created' }],
  };

  return (
    <ApolloConsumer>
      {(client) => {
        const generateReport = () =>
          client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'generate-payroll-deduction',
                params: JSON.stringify({
                  groupId,
                  newBillingApiVersionEnable: config.newBillingApiVersionEnable,
                }),
              },
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            )
            .finally(() => { setShowModal(false); setSubmitting(false); });

        const generatePayrollDeductionChanges = () =>
          client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'payroll-deduction-changes',
                params: JSON.stringify({
                  groupId,
                  newBillingApiVersionEnable: config.newBillingApiVersionEnable,
                }),
              },
            })
            .then(async (response) => {
              const urlResponse = await JSON.parse(
                get(response, 'data.remoteAction.data', '{}')
              );
              const { reportUrl = '' } = urlResponse;
              if (reportUrl) {
                setSuccessSnack(
                  'Please wait for report to generate. Once your report is ready it will be available in the “Billing Reports” tab.',
                  config.notificationDuration
                );
              } else {
                setErrorSnack(
                  'There was an error generating the report.',
                  config.notificationDuration
                );
              }
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                mb: 5,
              }}
            >
              <ToolbarButton
                bg="accentDark"
                isDisabled={actionDisabled}
                label={t('groups.reGeneratePayrollDeductionReport')}
                mr={0}
                onClick={() => setShowModal(!showModal)}
                permission="group.button.generatePayroll"
              />
              {filterPayrollScheduleId && (
                <ToolbarButton
                  bg="error"
                  label={t('groups.viewAll')}
                  mr={0}
                  onClick={() => {
                    clearPayrollSchedule(false);
                    dataTableRef.current.refetchTableData();
                  }}
                />
              )}
              <ToolbarButton
                bg="accentDark"
                isDisabled={actionDisabled}
                label={t('groups.generatePayrollDeductionChangeReport')}
                mr={0}
                onClick={generatePayrollDeductionChanges}
                permission="group.button.generatePayroll"
                visible={payrollDeductionReportLevel === 'EMPLOYEE'}
              />
            </Box>
            <ListGrid
              ref={dataTableRef}
              defaultFilterValues={defaultFilterValues}
              grid={grid}
              gridQueryName="get-payroll-deduction"
              moduleName="billing"
              scrollTo={Boolean(payrollScheduleId)}
            />
            {showModal && (
              <Modal
                closeText={t('common.no')}
                isOpen
                onClose={() => setShowModal(false)}
                onSubmit={() => {
                  setSubmitting(true);
                  generateReport();
                }}
                submitText={t('common.yes')}
                submitting={submitting}
              >
                <Box>
                  {t('groups.billingReportStatement')}
                </Box>
              </Modal>
            )}
          </>
        );
      }}
    </ApolloConsumer>
  );
};

BillingReports.defaultProps = {
  actionDisabled: false,
  groupId: null,
  groupNumber: null,
  payrollDeductionReportLevel: null,
  payrollScheduleId: null,
};

BillingReports.propTypes = {
  actionDisabled: PropTypes.bool,
  groupId: PropTypes.string,
  groupNumber: PropTypes.string,
  payrollDeductionReportLevel: PropTypes.string,
  payrollScheduleId: PropTypes.string,
};
export default BillingReports;
