import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Box from '@basecomponents/Box';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import get from 'lodash/get';
import { useMutation } from '@apollo/client';
import ToolbarButton from '../../../../BaseComponents/ToolbarButton';
import Toolbar from '../Toolbar';
import FormField from '../FormField';
import mutationRemoteAction from '../../../../../graphql/mutations/mutation-remote-action.gql';
import GetData from '../../../../../utilities/get-data';
import { MIN_WIDTH } from '../../utils/constants';
import Icon from '../../../../Icon';
import FormHeader from '../FormHeader';

/**
 * @category Components
 * @param {boolean} active Indicates whether the list should be visible
 * @param {Function} dispatch Dispatches an action based on user events
 * @param {object} stateValues
 * @param {object} sectionOperations
 * @param {string} mutationName Indicates the name of the mutation query
 * @param {string} queryName Indicates the name of the query
 * @param {string} sectionFields Indicates the fields of the sections
 * @param {string} sectionName Indicates the name of the section
 * @param {object} sectionOrder
 * @param {string} sectionTitle Indicates the title of the section
 * @param {string} userId Indicates the unique user ID
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const ListForm = ({
  active,
  dispatch,
  stateValues,
  sectionOperations,
  mutationName,
  queryName,
  sectionFields,
  sectionName,
  sectionOrder,
  sectionTitle,
  userId,
  ...rest
}) => {
  const [updateObject] = useMutation(mutationRemoteAction);
  const { apiData, loading } = GetData(
    queryName,
    JSON.stringify({
      section: sectionName,
      stateValues,
    }),
    !active
  );
  const getCardTitle = get(sectionOperations, 'getCardTitle');
  const isRemoveable = get(sectionOperations, 'isRemoveable');
  const isRemoveDisabled = get(sectionOperations, 'isRemoveDisabled');
  return (
    <>
      <FormHeader active={active} loading={loading} title={sectionTitle} />
      {active && !loading && (
        <Form
          initialValues={{ list: apiData }}
          keepDirtyOnReinitialize
          mutators={arrayMutators}
          onSubmit={async (values) => {
            try {
              const mutationResult = await updateObject({
                variables: {
                  name: mutationName,
                  params: JSON.stringify({
                    section: sectionName,
                    stateValues,
                    values: { ...get(values, 'list', []), userId },
                  }),
                },
              });
              const result = JSON.parse(
                get(mutationResult, 'data.mutationRemoteAction.data', '{}')
              );
              dispatch({ params: result, type: 'onNext' });
            } catch (e) {
              return { [FORM_ERROR]: e.message };
            }
          }}
          render={({
            handleSubmit,
            modified,
            submitting,
            submitError,
            values,
          }) => {
            return (
              <Box>
                <FieldArray key={sectionName} name="list" {...rest}>
                  {({ fields }) => {
                    return (
                      <Box>
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box fontSize={5} fontWeight="bold" p={4}>
                            {sectionTitle}
                          </Box>
                          <Box p={4}>
                            <ToolbarButton
                              icon="add"
                              label="Add"
                              onClick={() => fields.push({})}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            borderTopColor: 'accent',
                            borderTopStyle: 'solid',
                            borderTopWidth: 1,
                            transition: 'all 0.25s ease-in-out',
                          }}
                        >
                          <Box
                            alignItems="flex-start"
                            display="flex"
                            flexWrap="wrap"
                          >
                            {fields.map((name, index) => {
                              const rowDetails = {
                                currentValues: values,
                                rowIndex: index,
                                rowValues: get(values, `list.${index}`, {}),
                                stateValues,
                              };
                              return (
                                <Box key={name} p={4} width="50%">
                                  <Box
                                    alignItems="center"
                                    bg="accent"
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    {getCardTitle ? (
                                      <Box
                                        color="primaryDark"
                                        fontSize={4}
                                        fontWeight="bold"
                                        p={4}
                                        textAlign="center"
                                      >
                                        {getCardTitle(rowDetails)}
                                      </Box>
                                    ) : null}
                                    <Box p={4}>
                                      <Field name={`${name}.deleted`} {...rest}>
                                        {({ input: { onChange, value } }) => {
                                          const disabled = isRemoveDisabled
                                            ? isRemoveDisabled(rowDetails)
                                            : false;
                                          return (
                                            <Box
                                              color={
                                                disabled ? 'accent' : 'error'
                                              }
                                              onClick={
                                                disabled
                                                  ? () => null
                                                  : () =>
                                                      isRemoveable(rowDetails)
                                                        ? onChange(!value)
                                                        : fields.remove(index)
                                              }
                                              p={4}
                                              sx={{
                                                cursor: 'pointer',
                                              }}
                                            >
                                              <Icon
                                                height="1.5rem"
                                                svg={
                                                  isRemoveable &&
                                                  isRemoveable(rowDetails) &&
                                                  value
                                                    ? 'trash-restore'
                                                    : 'trash'
                                                }
                                                width="1.5rem"
                                              />
                                            </Box>
                                          );
                                        }}
                                      </Field>
                                    </Box>
                                  </Box>
                                  <Box
                                    alignItems="center"
                                    bg="accent"
                                    display="flex"
                                    flexWrap="wrap"
                                    justifyContent="space-between"
                                    p={4}
                                  >
                                    {sectionFields.map((sectionField) => {
                                      const isDisabled = get(
                                        sectionField,
                                        'isDisabled'
                                      );
                                      const isVisible = get(
                                        sectionField,
                                        'isVisible'
                                      );
                                      const disabled = isDisabled
                                        ? isDisabled(rowDetails)
                                        : false;
                                      const visible = isVisible
                                        ? isVisible(rowDetails)
                                        : true;
                                      const formFieldName = `${name}.${get(
                                        sectionField,
                                        'name'
                                      )}`;
                                      return (
                                        <Box
                                          minWidth={MIN_WIDTH}
                                          p={4}
                                          width="50%"
                                        >
                                          <FormField
                                            disabled={disabled}
                                            label={get(sectionField, 'label')}
                                            mask={get(sectionField, 'mask')}
                                            maxLength={get(
                                              sectionField,
                                              'maxLength'
                                            )}
                                            name={formFieldName}
                                            options={get(
                                              sectionField,
                                              'options',
                                              []
                                            )}
                                            type={get(sectionField, 'type')}
                                            validate={get(
                                              sectionField,
                                              'validate'
                                            )}
                                            value={get(values, formFieldName)}
                                            visible={visible}
                                            {...rest}
                                          />
                                        </Box>
                                      );
                                    })}
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    );
                  }}
                </FieldArray>
                <Toolbar
                  dispatch={dispatch}
                  handleSubmit={handleSubmit}
                  modified={modified}
                  sectionOperations={sectionOperations}
                  sectionOrder={sectionOrder}
                  stateValues={stateValues}
                  submitError={submitError}
                  submitting={submitting}
                  values={values}
                />
              </Box>
            );
          }}
        />
      )}
    </>
  );
};

ListForm.defaultProps = {
  sectionFields: [],
  sectionOperations: {},
  sectionOrder: 'middle',
  sectionTitle: '',
  stateValues: {},
  userId: '',
};

ListForm.propTypes = {
  active: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  mutationName: PropTypes.string.isRequired,
  queryName: PropTypes.string.isRequired,
  sectionFields: PropTypes.arrayOf(PropTypes.shape({})),
  sectionName: PropTypes.string.isRequired,
  sectionOperations: PropTypes.shape({}),
  sectionOrder: PropTypes.string,
  sectionTitle: PropTypes.string,
  stateValues: PropTypes.shape({}),
  userId: PropTypes.string,
};

export default ListForm;
