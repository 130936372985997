/**
 * @category Components
 * @param {object} fieldValues Indicates the values for the fields
 * @param {Function} render Indicates the render method for UI
 * @returns {React.FC}
 */
const CustomFormField = ({ fieldValues, render }) => {
  return render ? render(fieldValues) : 'No render defined';
};

export default CustomFormField;
