import Box from '@basecomponents/Box';
import Icon from '@src/components/Icon';
import React from 'react';
import PropTypes from 'prop-types';
import { produce } from 'immer';

export const imgSx = {
  alignItems: 'center',
  display: 'flex',
  height: '35px',
  justifyContent: 'center',
  mr: 2,
  width: '35px',
};

export const containerSx = {
  alignItems: 'center',
  borderRadius: 5,
  boxShadow: 5,
  display: 'flex',
  justifyContent: 'center',
  m: 5,
  p: 2,
};

export const EmailButton = ({ email, sx }) => {
  const styles = produce(containerSx, (draft) => {
    Object.assign(draft, sx)
  })
  return (
    <Box
      as="a"
      bg="accentSecondaryDark"
      color="white"
      href={`mailto:${email}`}
      m={0}
      sx={styles}
    >
      <Icon color="accentPrimary" svg="chatbubble" sx={imgSx} />
      <Box>{email}</Box>
    </Box>
  );
};


EmailButton.defaultProps = {
  sx: {}
}

EmailButton.propTypes = {
  email: PropTypes.string.isRequired,
  sx: PropTypes.object
};
