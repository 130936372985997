import {navigate, useParams} from '@reach/router';
import Box from '@basecomponents/Box';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {ApolloConsumer} from '@apollo/client';
import {Field, Form as FinalForm} from 'react-final-form';
import {AuthContext} from '@basecomponents/Auth';
import Spinner from '@basecomponents/Spinner';
import {useTranslation} from 'react-i18next';
import useSsm from '@src/utilities/hooks/useSsm';
import InputDropdown from '../../BaseComponents/Dropdown';
import remoteActionQuery from '../../../graphql/queries/remote-action.gql';
import {labelHandler} from '../../../utilities/label-utils';
import useSnackbar from '../../../utilities/use-snackbar';
import ToolbarButton from '../../BaseComponents/ToolbarButton';
import ManualInput from './ManualInput';
import UploadFile from '../UploadFile';
import GetData from '../../../utilities/get-data';
import generateRedirectPath from '../../../utilities/generate-redirect-path';
import config from '../../../config.json';
import dateUtils from '../../../utilities/date-utils';
import {uploadOption} from '../../../utilities/constants';

/**
 * @category Employee
 * @param {Object} location
 * @param {string} name
 * @param {string} redirectTo Indicates on what route the user should redirect to
 * @param {boolean} showFooterButtons Indicates whether to show the footer buttons
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const EmployeeCensus = ({
  location,
  name,
  redirectTo,
  showFooterButtons,
  ...rest
}) => {
  const { t } = useTranslation();
  const parameters = useParams();
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const groupId = get(parameters, 'groupId', '');
  const [nextLoading, setNextLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { apiData: groups = {}, loading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );
  const { apiData: paramsData = {} } = GetData(
    'get-ssm-params',
    JSON.stringify({ paramName: `workday-group-number-${config.environment}` })
  );
  const postEnrollmentConfigEnabled = useSsm('iaic-post-enrollment')
  const groupNumber = get(groups, 'groupNumber');
  const empLevelPostEnrollConfigEnabled = useSsm('iaic-employee-level-post-enrollment')
  const dropdownOptions = () => {
    const groupType = get(groups, 'groupType', '');
    const postEnrollmentGroup = get(groups, 'postEnrollmentGroup', false);
    if (groupType !== 'BEN_ADMIN_SYSTEM') {
      return [
        {
          canEnglishValue: null,
          frenchValue: 'Entrée manuelle',
          label: 'Manual Input',
          value: 'ManualInput',
        },
        {
          canEnglishValue: null,
          frenchValue: 'Téléchargement du fichier du recensement',
          label: 'Census File Upload',
          value: 'CensusFileUpload',
        },
        {
          canEnglishValue: 'Enrolment File Upload',
          frenchValue: 'Téléchargement du fichier d’inscription',
          label: 'Enrollment File Upload',
          value: 'EnrollmentFileUpload',
        },
      ];
    }
    if (postEnrollmentConfigEnabled && postEnrollmentGroup) {
      if (empLevelPostEnrollConfigEnabled) {
        return [
          {
            canEnglishValue: 'Pet Level Post Enrolment File Upload',
            frenchValue:
              'Téléchargement du fichier post-inscription au niveau de l’animal',
            label: 'Pet Level Post Enrollment File Upload',
            value: 'PostEnrollmentFileUpload',
          },
          {
            canEnglishValue: 'Employee Level Post Enrolment File Upload',
            frenchValue:
              'Téléchargement du fichier post-inscription au niveau des employés',
            label: 'Employee Level Post Enrollment File Upload',
            value: 'EmployeeLevelPostEnrollmentFileUpload',
          },
        ];
      }
      return [
        {
          canEnglishValue: 'Pet Level Post Enrolment File Upload',
          frenchValue:
            'Téléchargement du fichier post-inscription au niveau de l’animal',
          label: 'Pet Level Post Enrollment File Upload',
          value: 'PostEnrollmentFileUpload',
        },
      ];
    }
    return [
      {
        canEnglishValue: 'Enrolment File Upload',
        frenchValue: 'Téléchargement de fichiers d’inscription',
        label: 'Enrollment File Upload',
        value: 'EnrollmentFileUpload',
      },
    ];
  };

  return (
    <ApolloConsumer>
      {(client) => {
        const onClickCreateEmployeeAction = async (currentValues, form) => {
          // Old logic would overwrite 'dateOfHire' with fields in ...values, timestamp conversion had no effect
          const { dateOfHire, terminationDate, ...values } = currentValues;
          const params = {
            employeeData: {
              dateOfHire: dateUtils.setAPIDateOnly(dateOfHire),
              empId: get(values, 'empId', ''),
              employmentStatus: get(values, 'employmentStatus', ''),
              fullTimePartTimeCode: get(values, 'fullTimePartTimeCode', ''),
              locationName: get(values, 'locationName', ''),
              terminationDate: dateUtils.setAPIDateOnly(terminationDate),
            },
            groupId,
            user: {
              address: { ...get(values, 'address', ''), country: 'US' },
              contacts: [
                {
                  contactType: get(values, 'phoneType', ''),
                  phoneNumber: get(values, 'phoneNumber', ''),
                },
              ],
              email: get(values, 'email', ''),
              firstName: get(values, 'firstName', ''),
              lastName: get(values, 'lastName', ''),
              middleName: get(values, 'middleName', ''),
            },
            userId,
            isWorkday: groupNumber === paramsData.value,
          };
          await client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'employee-census',
                params: JSON.stringify({ ...params }),
              },
            })
            .then((response) => {
              const contactResponse = JSON.parse(
                get(response, 'data.remoteAction.data', '{}')
              );
              if (contactResponse) {
                setSucessSnack(
                  'Created employee successfully!',
                  config.notificationDuration
                );
                form.restart();
                if (redirectTo) {
                  navigate(redirectTo);
                }
              }
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error creating the user: ${e.message}`,
                config.notificationDuration
              )
            );
        };

        const onNextClick = () => {
          setNextLoading(true);
          return client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'get-all-employee',
                params: JSON.stringify([{ groupId }]),
              },
            })
            .then((response) => {
              const content = get(
                JSON.parse(get(response, 'data.remoteAction.data', '')),
                'content',
                []
              );

              if (content?.length >= 0) {
                setNextLoading(false);
                return navigate(
                  generateRedirectPath({
                    ...location,
                    queryParams: { [name]: 6 },
                  })
                );
              }
              setNextLoading(false);
              return setErrorSnack(
                'No Employees exist in this group. Create at least one employee to move forward.',
                config.notificationDuration
              );
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        };
        if (loading) {
          return <Spinner />;
        }

        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              onSubmit={onClickCreateEmployeeAction}
              render={({ handleSubmit, submitting, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        padding: 3,
                      }}
                    >
                      <Box
                        as="h3"
                        color="black"
                        display="flex"
                        justifyContent="flex-start"
                        sx={{
                          color: 'primary',
                          margin: '20px 0px 20px 0px;',
                          padding: 3,
                          width: '25rem',
                        }}
                      >
                        {t('groupEmployees.enrollmentPage.employeeCensus')}
                      </Box>
                      <Box data-cy="files" flex="1" justifyContent="center">
                        <Field
                          aria-label={t(
                            'groupEmployees.enrollmentPage.employeeCensusType'
                          )}
                          component={InputDropdown}
                          label={labelHandler(
                            t(
                              'groupEmployees.enrollmentPage.employeeCensusType'
                            ),
                            false
                          )}
                          name="employeeCensusType"
                          options={dropdownOptions()}
                          searchable={false}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        {get(values, 'employeeCensusType', '') ===
                          'CensusFileUpload' &&
                        config.environment === 'dev3' ? (
                          <>
                            <Field
                              aria-label={t('groups.uploadMethod')}
                              component={InputDropdown}
                              label={labelHandler(
                                t('groups.uploadMethod'),
                                false
                              )}
                              name="uploadMethod"
                              options={uploadOption}
                              searchable={false}
                              wrapperSx={{ ...commonFieldSxHalf }}
                              {...rest}
                            />
                          </>
                        ) : null}
                        {get(values, 'employeeCensusType', '') ===
                        'CensusFileUpload' ? (
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <UploadFile
                              fileTypes={[
                                'text/plain',
                                'application/vnd.ms-excel',
                                'text/x-csv',
                                'application/csv',
                                'application/x-csv',
                                'text/csv',
                                'text/comma-separated-values',
                                'text/x-comma-separated-values',
                                'text/tab-separated-values',
                              ]}
                              flow="census"
                              groupId={groupId}
                              onUploadComplete={() => setButtonDisabled(false)}
                              uploadMethod={get(values, 'uploadMethod')}
                              {...rest}
                            />
                            {redirectTo ? (
                              <ToolbarButton
                                aria-label="Done"
                                bg="accentDark"
                                disabled={buttonDisabled}
                                label="Done"
                                onClick={() => navigate(redirectTo)}
                                sx={{ width: '120px' }}
                              />
                            ) : (
                              ''
                            )}
                          </Box>
                        ) : null}
                        {get(values, 'employeeCensusType', '') ===
                          'EnrollmentFileUpload' &&
                        config.environment === 'dev3' ? (
                          <>
                            <Field
                              aria-label={t('groups.uploadMethod')}
                              component={InputDropdown}
                              label={labelHandler(
                                t('groups.uploadMethod'),
                                false
                              )}
                              name="uploadMethod"
                              options={uploadOption}
                              searchable={false}
                              wrapperSx={{ ...commonFieldSxHalf }}
                              {...rest}
                            />
                          </>
                        ) : null}
                        {[
                          'EnrollmentFileUpload',
                          'PostEnrollmentFileUpload',
                          'EmployeeLevelPostEnrollmentFileUpload',
                        ].includes(get(values, 'employeeCensusType')) ? (
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <UploadFile
                              fileTypes={[
                                'text/plain',
                                'application/vnd.ms-excel',
                                'text/x-csv',
                                'application/csv',
                                'application/x-csv',
                                'text/csv',
                                'text/comma-separated-values',
                                'text/x-comma-separated-values',
                                'text/tab-separated-values',
                              ]}
                              flow={
                                // eslint-disable-next-line no-nested-ternary
                                get(values, 'employeeCensusType') ===
                                'PostEnrollmentFileUpload'
                                  ? 'postEnrollment'
                                  : get(values, 'employeeCensusType') ===
                                    'EmployeeLevelPostEnrollmentFileUpload'
                                  ? 'employeeLevelPostEnrollment'
                                  : 'enrollment'
                              }
                              groupId={groupId}
                              onUploadComplete={() => setButtonDisabled(false)}
                              uploadMethod={get(values, 'uploadMethod')}
                              {...rest}
                            />
                            {redirectTo ? (
                              <ToolbarButton
                                aria-label="Done"
                                bg="accentDark"
                                disabled={buttonDisabled}
                                label="Done"
                                onClick={() => navigate(redirectTo)}
                                sx={{ width: '120px' }}
                              />
                            ) : (
                              ''
                            )}
                          </Box>
                        ) : null}
                        {get(values, 'employeeCensusType', '') ===
                          'ManualInput' && (
                          <>
                            <ManualInput groupId={groupId} {...rest} />
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                my: 6,
                              }}
                            >
                              <ToolbarButton
                                aria-label="Create Employee"
                                bg="accentDark"
                                disabled={submitting}
                                label="Create Employee"
                                submitting={submitting}
                                type="submit"
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                      {showFooterButtons ? (
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            my: 6,
                            p: 2,
                          }}
                        >
                          <ToolbarButton
                            aria-label={t('common.previous')}
                            bg="primaryLight"
                            label={t('common.previous')}
                            ml={0}
                            onClick={() =>
                              navigate(
                                generateRedirectPath({
                                  ...location,
                                  queryParams: { [name]: 4 },
                                })
                              )
                            }
                            width="10rem"
                          />
                          <ToolbarButton
                            aria-label={t('common.next')}
                            bg="primaryDark"
                            label={t('common.next')}
                            mr={0}
                            onClick={onNextClick}
                            submitting={nextLoading}
                            width="10rem"
                          />
                        </Box>
                      ) : (
                        ''
                      )}
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

EmployeeCensus.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  redirectTo: PropTypes.string,
  showFooterButtons: PropTypes.bool,
};

EmployeeCensus.defaultProps = {
  name: 'step',
  redirectTo: '',
  showFooterButtons: false,
};

export default EmployeeCensus;
