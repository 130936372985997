import Box from '@basecomponents/Box';
import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '@basecomponents/Auth';
import GetData from '../../../utilities/get-data';
import UploadedDocumentsSection from '../UploadedDocumentsSection';
import { USER_ROLE } from '../../../utilities/constants';
import Spinner from '../../BaseComponents/Spinner';
import config from '../../../config.json';

/**
 * @category Files
 * @param {boolean} actionDisabled Indicates whether the action is disabled
 * @param {string} groupId Indicates the unique group ID
 * @param {string} isEducational Indicates whether the uploaded media is educational
 * @param {string} setFigoMsg
 * @returns {React.FC}
 */
const FileViewSection = ({
  actionDisabled,
  groupId,
  isEducational,
  setFigoMsg,
}) => {
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const { apiData = {}, loading } = GetData(
    'get-files',
    JSON.stringify({
      groupId,
      isEducational: !!isEducational,
      page: 0,
      size: 100,
    })
  );

  const { apiData: groupData = {} } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );

  const { groupNumber } = groupData;

  const [docs, setDocs] = useState([]);

  useEffect(() => {
    if (!loading) {
      setDocs(apiData.content);
    }
  }, [loading]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box
      sx={{
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <UploadedDocumentsSection
        deleted={(s3Key) =>
          (s3Key.startsWith(`${groupNumber}/groupDocuments/`) ||
            isEducational) &&
          (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) &&
          !actionDisabled
        }
        documentsList={
          Array.isArray(docs) &&
          docs.map((file) => {
            return {
              attachmentName: get(file, 'name', null),
              created: get(file, 'created', null),
              isEmbedLink: get(file, 'isEmbedLink', null),
              isLogo: get(file, 'isLogo', null),
              s3Key: get(file, 's3Key', null),
              signedUrl: get(file, 'signedUrl', null),
            };
          })
        }
        group
        groupId={groupId}
        setFigoMsg={setFigoMsg}
        setSignUrl
      />
    </Box>
  );
};

FileViewSection.defaultProps = {
  actionDisabled: false,
  groupId: null,
  isEducational: false,
  setFigoMsg: false,
};

FileViewSection.propTypes = {
  actionDisabled: PropTypes.bool,
  groupId: PropTypes.string,
  isEducational: PropTypes.bool,
  setFigoMsg: PropTypes.bool,
};
export default FileViewSection;
