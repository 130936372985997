import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import Spinner from '@basecomponents/Spinner';
import Icon from "@src/components/Icon";

/**
 * @category Components
 * @param {boolean} active Indicates whether title or spinner is active
 * @param currentIndex
 * @param dispatch
 * @param {boolean} loading Indicates whether spinner is loading
 * @param {string} title Indicates the title for the header
 * @returns {React.FC}
 */
const FormHeader = ({ active,currentIndex,dispatch, loading, title }) => {
  return (
    <Box  sx={{
        position: 'relative'
    }}>
      {(loading || !active) && (
        <Box fontSize={active ? 5 : 4} fontWeight="bold" p={4}>
          {title}
        </Box>
      )}
      {active && loading && (
        <Box
          sx={{
            borderTopColor: 'accent',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            transition: 'all 0.25s ease-in-out',
          }}
        >
          <Spinner />
        </Box>
      )}
      <Icon
          height="1.5rem"
          onClick={()=>{
              dispatch({ params: {currentIndex}, type: 'onIndex' });
          }}
          svg={active ? 'expand-less' :'expand-more'}
          sx={{
              cursor:'pointer',
              position: 'absolute',
              right: '15px',
              top: '15px',
          }}
          width="1.5rem"
      />
    </Box>
  );
};

FormHeader.propTypes = {
  active: PropTypes.bool.isRequired,
  currentIndex: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default FormHeader;
