import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';
import InputWrapper from '../InputWrapper';

/**
 * @category BaseComponents
 * @param {object} input
 * @param {*} rest
 * @returns {React.FC}
 */

const Input = ({ input, ...rest }) => {
  const { defaultStyle = {}, sizes = {} } = useContext(ThemeContext);
  return (
    <>
      <InputWrapper input={input} {...rest}>
        {({ error, ...inputRest }) => {
          const { sx, disabled, ...rest } = inputRest;
          return (
            <Box
              as="input"
              disabled={disabled}
              id={input.name}
              sx={{ ...sx, ...defaultStyle.defaultInput(error, disabled) }}
              variant="inputs.primary"
              width={sizes.widths.Input}
              {...rest}
              {...input}
            />
          );
        }}
      </InputWrapper>
      {document.getElementById(input.name)?.maxLength > 0 && (
        <Box sx={{ ...defaultStyle.defaultInputMaxLengthStyle }}>
          {input.value.length}/{document.getElementById(input.name).maxLength}
        </Box>
      )}
    </>
  );
};

Input.propTypes = {
  input: PropTypes.shape({
    error: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default Input;
