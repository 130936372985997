import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';

/**
 * @category BaseComponents
 * @param {string} key
 * @param {string} id
 * @param {Object} tooltipProps
 * @param {Object} tooltipSx
 * @returns {React.Component}
 */
class TooltipBox extends React.Component {
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    const {
      id,
      tooltipProps: { sx: tooltipSx, ...tooltipProps },
    } = this.props;
    const { defaultStyle = {} } = this.context;
    return (
      <Box
        as={ReactTooltip}
        id={id}
        sx={defaultStyle.defaultToolTip(tooltipSx)}
        {...tooltipProps}
      />
    );
  }
}

TooltipBox.propTypes = {
  id: PropTypes.string.isRequired,
  tooltipProps: PropTypes.shape({ sx: PropTypes.shape({}) }),
};

TooltipBox.defaultProps = {
  tooltipProps: {},
};
TooltipBox.contextType = ThemeContext;
export default TooltipBox;
