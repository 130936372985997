import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import blacklistProps from '../../../utilities/blacklist-props';

const ButtonGroup = styled(
  blacklistProps({ blacklist: ['leftAlign', 'rightAlign', 'wrap'] })
)`
  display: flex;
  justify-content: center;
  margin: 0 -${(p) => p.theme.space[1]};

  & > * {
    margin: ${(p) => p.theme.space[1]};
  }

  ${(p) =>
    p.rightAlign &&
    css`
      justify-content: flex-end;
    `}

  ${(p) =>
    p.wrap &&
    css`
      flex-wrap: ${p.wrap};
    `}

  ${(p) =>
    p.leftAlign &&
    css`
      justify-content: flex-start;
    `}
`;

ButtonGroup.propTypes = {
  leftAlign: PropTypes.bool,
  rightAlign: PropTypes.bool,
  wrap: PropTypes.string,
};

ButtonGroup.defaultProps = {
  leftAlign: false,
  rightAlign: false,
  wrap: 'nowrap',
};

/**
 * @category BaseComponents
 * @param {React.children} children
 * @param {*} rest
 * @returns {React.FC}
 */
const ButtonGroups = ({ children, ...rest }) => (
  <ButtonGroup {...rest}>{children}</ButtonGroup>
);

ButtonGroups.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonGroups;
