import React from 'react';
import PropTypes from 'prop-types';
import General from './default';
import AK from './AK';
import AR from './AR';
import CT from './CT';
import FL from './FL';
import IL from './IL';
import KS from './KS';
import KY from './KY';
import MA_A from './MA_A';
import MA_AI from './MA_AI';
import MO from './MO';
import MN from './MN';
import MT from './MT';
import ND from './ND';
import NE from './NE';
import NH from './NH';
import NV from './NV';
import PA from './PA';
import OH from './OH';
import OK from './OK';
import OR from './OR';
import SD from './SD';
import TX from './TX';
import VA from './VA';

const Master = ({ type, product, ...rest }) => {
  switch (type) {
    case 'AK':
      return <AK product={product} {...rest} />;
    case 'AR':
      return <AR product={product} {...rest} />;
    case 'CT':
      return <CT product={product} {...rest} />;
    case 'FL':
      return <FL product={product} {...rest} />;
    case 'IL':
      return <IL product={product} {...rest} />;
    case 'KS':
      return <KS product={product} {...rest} />;
    case 'KY':
      return <KY product={product} {...rest} />;
    case 'MA':
      if (product === 'Accident Only') {
        return <MA_A product={product} {...rest} />;
      }
      return <MA_AI product={product} {...rest} />;
    case 'MO':
      return <MO product={product} {...rest} />;
    case 'MN':
      return <MN product={product} {...rest} />;
    case 'MT':
      return <MT product={product} {...rest} />;

    case 'ND':
      return <ND product={product} {...rest} />;

    case 'NE':
      return <NE product={product} {...rest} />;

    case 'NH':
      return <NH product={product} {...rest} />;

    case 'NV':
      return <NV product={product} {...rest} />;
    case 'PA':
      return <PA product={product} {...rest} />;

    case 'OH':
      return <OH product={product} {...rest} />;

    case 'OK':
      return <OK product={product} {...rest} />;

    case 'OR':
      return <OR product={product} {...rest} />;

    case 'SD':
      return <SD product={product} {...rest} />;

    case 'TX':
      return <TX product={product} {...rest} />;

    case 'VA':
      return <VA product={product} {...rest} />;
    default:
      return <General product={product} {...rest} />;
  }
};

Master.propTypes = {
  product: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Master;
