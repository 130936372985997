import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';

/**
 * @category Components
 * @param {Object} sx
 * @param {string} prePend
 * @param {string} append
 * @returns {React.FC}
 */
const CopyRight = ({ sx, prePend, append }) => (
  <Box sx={sx}>
    {prePend} {new Date().getFullYear()} {append}
  </Box>
);

CopyRight.propTypes = {
  append: PropTypes.string,
  prePend: PropTypes.string,
  sx: PropTypes.shape({}),
};

CopyRight.defaultProps = {
  append: '',
  prePend: '©',
  sx: {},
};

export default CopyRight;
