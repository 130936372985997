import { AuthContext } from '@basecomponents/Auth';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import GetData from '../../../../utilities/get-data';
import Spinner from '../../../BaseComponents/Spinner';
import DetailsPage from '../DetailsPage';
import Route from '../../Route';

/**
 * @category Employee
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const DefaultEnrolee = ({ location, ...rest }) => {
  const { t } = useTranslation()
  const queryParams = qs.parse(location.search);
  const showCoverage = get(queryParams, 'showCoverage', null);
  const { user } = useContext(AuthContext);
  const { apiData: { content: employeeData = [] } = {}, loading } = GetData(
    'get-all-employee',
    JSON.stringify({
      skipActiveCheck: true,
      sort: 'created,desc',
      userId: get(user, 'customSystemUserId', ''),
    })
  );

  const activeEmployeeId =
    employeeData.find((item) => item.currentGroup.isActiveGroup)?.employeeId ||
    get(employeeData, '0.employeeId');

  const renderDetails = () => {
    if (loading || employeeData.length === 0 || !employeeData) {
      return <Spinner />;
    }
    if (activeEmployeeId) {
      return (
        <DetailsPage
          employeeData={employeeData}
          employeeId={activeEmployeeId}
          showCoverage={showCoverage}
          {...rest}
        />
      );
    }
    return (
      <Box
        sx={{
          alignItems: 'center',
          color: 'accent',
          display: 'flex',
          fontSize: 6,
          fontWeight: 'bold',
          height: '100vh',
          justifyContent: 'center',
        }}
      >
      {t('groupEmployees.enrollmentPage.defaultPage.noUserFound')}
        <Box sx={{ fontWeight: 'normal', ml: 2 }}>
          {t('groupEmployees.enrollmentPage.defaultPage.contactHR')}
        </Box>
      </Box>
    );
  };

  return (
    <Route isPrivate layout="plain" {...rest}>
      {renderDetails()}
    </Route>
  );
};

DefaultEnrolee.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default DefaultEnrolee;
