import { get, isEmpty } from 'lodash';
import GetData from './get-data';
import { USER_ROLE } from './constants';

const getAdminGroup = (userId, role) => {
  const { apiData: { content: userData = [] } = {} } = GetData(
    'get-all-administrator-by-group-id',
    JSON.stringify({ userId }),
    role !== USER_ROLE.EMPLOYER,
    'cache-first'
  );
  const activeData = userData.find((item) => get(item, 'groupActive') === true);
  const inActiveData = userData.find(
    (item) =>
      get(item, 'user.inactiveDate') !== null &&
      new Date(get(item, 'user.inactiveDate')) > new Date()
  );
  // eslint-disable-next-line no-nested-ternary
  const activeUserData = isEmpty(activeData)
    ? isEmpty(inActiveData)
      ? userData[0]
      : inActiveData
    : activeData;
  return {
    employeeId: get(activeUserData, 'administratorId', null),
    groupId: get(activeUserData, 'groupId', null),
    groupName: get(activeUserData, 'groupName', null),
    groupNumber: get(activeUserData, 'groupNumber'),
    status: get(activeUserData, 'employmentStatus', 'ACTIVE').toLowerCase(),
  };
};

export default getAdminGroup;
