import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';

/**
 * @category Components
 * @param {string} displayValue
 * @param {boolean} loading
 * @param {Object} displaySx
 * @returns {React.FC}
 */
const ModuleContainer = ({ displayValue, loading, displaySx }) => {
  const theme = useContext(ThemeContext);
  const content = (
    <Box alignItems="center" display="inline-flex" sx={displaySx}>
      <Box>{displayValue}</Box>
    </Box>
  );
  if (loading) {
    return <PulseLoader color={get(theme, 'colors.accent')} size={5} />;
  }
  return content;
};

ModuleContainer.defaultProps = {
  displaySx: {},
  displayValue: null,
  loading: false,
};
ModuleContainer.propTypes = {
  displaySx: PropTypes.shape({}),
  displayValue: PropTypes.string,
  loading: PropTypes.bool,
};

export default ModuleContainer;
