import { useTranslation } from 'react-i18next';
import config from '@src/config.json';
import barrelErrors from '../meta/barrelErrors.json';
import sftpErrors from '../meta/sftpErrors.json';
import barrelFiles from '../meta/barrelFiles.json';
import commissions from '../meta/commissions.json';
import errors from '../meta/errors.json';
import files from '../meta/files.json';
import producers from '../meta/producers.json';
import subProducers from '../meta/sub-producers.json';
import users from '../meta/users.json';
import billing from '../meta/billing.json';
import groupAdmins from '../meta/group-admins.json';
import benAdmin from '../meta/ben-admin.json';
import broker from '../meta/group-broker.json';
import billingNotes from '../meta/billing-notes.json';
import reportExport from '../meta/report-export.json';
import billingHistory from '../meta/billing-history.json';

export default ({ moduleName }) => {
  const { t } = useTranslation();
  const claims = {
    claimId: {
      label: t('metadata.claimNumber'),
    },
    claimStatusDisplay: {
      label: t('metadata.claimStatus'),
      type: 'capitalCase',
    },
    created: {
      label: t('metadata.submittedDate'),
      type: 'dateTime',
    },
    createdBy: {
      label: t('metadata.createdBy'),
      moduleName: 'user',
      type: 'moduleInfo',
    },
    dependentsVisits: {
      diagnosis: {
        label: t('metadata.symptom'),
      },
      onsetDate: {
        label: t('metadata.symptomStartDate'),
        type: 'date',
      },
      treatmentStartDate: {
        label: t('metadata.treatmentStartDate'),
        type: 'date',
      },
    },
    employeeId: {
      label: t('metadata.employeeName'),
      moduleName: 'employee',
      type: 'moduleInfo',
    },
    externalClaimId: {
      label: t('metadata.claimNumber'),
    },
    lastModified: {
      label: t('metadata.submittedDate'),
      type: 'dateTime',
    },
    paidAmount: {
      label: t('metadata.paymentAmount'),
    },
    receiveDate: {
      label: t('metadata.submittedDate'),
      type: 'dateTime',
    },
    status: {
      label: t('metadata.claimStatus'),
      type: 'capitalCase',
    },
  };
  const groupEmployees = {
    annualSalary: {
      label: t('metadata.annualSalary'),
    },
    avgHrsPerWeek: {
      label: t('metadata.avgHoursPerWeek'),
    },
    certificateNumber: {
      label: t('metadata.certificateNumber'),
    },
    dateOfHire: {
      label: t('metadata.dateOfHire'),
      type: 'date',
    },
    department: {
      label: t('metadata.department'),
    },
    empId: {
      label: t('metadata.employeeId'),
    },
    enrollmentStatus: {
      label: t('metadata.enrollmentStatus'),
      moduleName: 'enrollmentStatus',
      type: 'moduleInfo',
    },
    fullTimePartTimeCode: {
      label: t('metadata.fullTimePartTime'),
    },
    groupId: {
      acceptGroupPlanChoice: {
        label: t('metadata.accepted'),
        type: 'boolean',
      },
      displayField: 'groupName',
      label: t('metadata.group'),
      moduleName: 'group',
      type: 'moduleLink',
    },
    jobTitle: {
      label: t('metadata.jobTitle'),
    },
    paidThroughDate: {
      label: t('metadata.paidThroughDate'),
      type: 'date',
    },
    payrollFrequency: {
      label: t('metadata.payrollFrequency'),
    },
    plan: {
      annualDeductible: {
        defaultValue: t('metadata.0dollar'),
        label: t('metadata.annaulDeductible'),
        type: 'currency',
      },
      annualLimit: {
        defaultValue: t('metadata.unlimited'),
        label: t('metadata.annualLimit'),
        type: 'currency',
      },
      bwpCLI: {
        defaultValue: t('metadata.sixMonths'),
        label: t('metadata.bwpCLI'),
      },
      bwpIllness: {
        defaultValue: t('metadata.waived'),
        label: t('metadata.bwpIllness'),
        text: t('common.days'),
        type: 'postfix',
      },
      bwpInjuries: {
        defaultValue: t('metadata.waived'),
        label: t('metadata.bwpInjuries'),
        text: t('common.days'),
        type: 'postfix',
      },
      coinsurance: {
        label: t('metadata.coinsurance'),
        type: 'directPercentage',
      },
      copay: {
        defaultValue: t('metadata.notIncluded'),
        label: t('metadata.copay'),
        type: 'currency',
      },
      coverageCredit: {
        label: t('metadata.coverageCredit'),
        type: 'boolean',
      },
      coverageFromDate: {
        label: t('metadata.coverageFromDate'),
        type: 'date',
      },
      coverageToDate: {
        label: t('metadata.coverageToDate'),
        type: 'date',
      },
      diminishingDeductible: {
        defaultValue: t('metadata.notIncluded'),
        label: t('metadata.diminishingDeductible'),
        type: 'currency',
      },
      enrollmentFromDate: {
        label: t('metadata.enrollmentFromDate'),
        type: 'date',
      },
      enrollmentToDate: {
        label: t('metadata.enrollmentToDate'),
        type: 'date',
      },
      existingCondition: {
        defaultValue: t('metadata.existingConditionDefault'),
        label: t('metadata.existingCondition'),
        picklistId: 'existing-condition',
        type: 'picklist',
      },
      lastModified: {
        label: t('metadata.lastModified'),
        type: 'dateTime',
      },
      maxAge: {
        defaultValue: t('metadata.none'),
        label: t('metadata.maxAge'),
      },
      name: {
        label: t('metadata.planName'),
      },
      planChoices: {
        coverageFromDate: {
          label: t('metadata.plan.coverageFromDate'),
          type: 'date',
        },
        coverageToDate: {
          label: t('metadata.plan.coverageToDate'),
          type: 'date',
        },
        enrollmentFromDate: {
          label: t('metadata.plan.enrollmentFromDate'),
          type: 'date',
        },
        enrollmentToDate: {
          label: t('metadata.plan.enrollmentToDate'),
          type: 'date',
        },
        name: {
          label: t('metadata.planChoicesName'),
        },
      },
      planEndDate: {
        label: t('metadata.planEndDate'),
        type: 'date',
      },
      planName: {
        label: t('metadata.planName'),
      },
      planPrice: {
        label: t('metadata.planPrice'),
        type: 'currency',
      },
      planStartDate: {
        label: t('metadata.planStartDate'),
        type: 'date',
      },
      poaAR: {
        label: t('metadata.poaAR'),
        type: 'boolean',
      },
      productDetails: {
        name: {
          label: t('metadata.productDetails'),
          type: 'productDetails',
        },
      },
      riderABC: {
        label: t('metadata.riderABC'),
        type: 'boolean',
      },
      riderAC: {
        label: t('metadata.riderAc'),
        type: 'boolean',
      },
      riderFR: {
        defaultValue: t('metadata.notIncluded'),
        label: t('metadata.riderFR'),
        type: 'currency',
      },
      riderICC: {
        label: config.canadaEnv
          ? t('metadata.inheritedConditionRider')
          : 'Inherited and Congenital Care',
        type: 'boolean',
      },
      riderOETC: {
        label: t('metadata.riderOETC'),
        type: 'boolean',
      },
      riderRD: {
        defaultValue: t('metadata.notIncluded'),
        label: t('metadata.riderRD'),
        type: 'currency',
      },
      riderRPT: {
        label: t('metadata.riderRPT'),
        type: 'boolean',
      },
      weightLimitA: {
        label: t('metadata.weightLimitA'),
        type: 'weight',
      },
      weightLimitB: {
        label: t('metadata.weightLimitB'),
        type: 'weight',
      },
      weightLimitC: {
        label: t('metadata.weightLimitC'),
        type: 'weight',
      },
      wellness: {
        defaultValue: t('metadata.notIncluded'),
        label: t('metadata.wellness'),
        // type: 'boolean',
      },
    },
    secondaryEmail: {
      label: t('metadata.secondaryEmail'),
    },
    status: {
      label: t('metadata.enrollmentStatus'),
    },
    terminationDate: {
      label: t('metadata.terminationDate'),
      type: 'date',
    },
    user: {
      email: {
        label: t('metadata.email'),
      },
      label: t('metadata.user'),
      secondaryEmail: {
        label: t('metadata.secondaryEmail'),
      },
      type: 'name',
    },
  };
  const employeeDependents = {
    additionalInsurance: {
      label: t('metadata.additionalInsurance'),
      type: 'boolean',
    },
    additionalInsuranceCompany: {
      label: t('metadata.companyName'),
    },
    certificateNumber: {
      label: t('metadata.certificateNumber'),
    },
    coverageEndDate: {
      label: t('metadata.coverageEndDate'),
      type: 'date',
    },
    coverageStartDate: {
      label: t('metadata.coverageStartDate'),
      type: 'date',
    },
    dependentData: {
      age: {
        label: t('metadata.age'),
      },
      breed: {
        label: t('metadata.breed'),
        moduleName: 'breed',
        type: 'moduleInfo',
      },
      petName: {
        label: t('metadata.petName'),
      },
      species: {
        label: t('metadata.species'),
        type: 'species',
      },
      weight: {
        label: t('metadata.weight'),
        type: 'weight',
      },
    },
    employeeContribution: {
      label: t('metadata.employeePremium'),
      type: 'currency',
    },
    employerContribution: {
      label: t('metadata.employerContribution'),
      type: 'currency',
    },
    enrollmentDOE: {
      label: t('metadata.coverageStartDate'),
      moduleName: 'enrollmentDOE',
      type: 'moduleInfo',
    },
    payPeriodPremiumEmployee: {
      label: t('metadata.payPeriodPremiumEmployee'),
      type: 'currency',
    },
    payPeriodPremiumEmployer: {
      label: t('metadata.payPeriodPremiumEmployer'),
      type: 'currency',
    },
    employerContributionMonthly: {
      label: t('metadata.employerContributionMonthly'),
      type: 'currency',
    },
    monthlyPremiumEmployee: {
      label: t('metadata.monthlyPremiumEmployee'),
      type: 'currency',
    },
    planPrice: {
      label: t('metadata.planPrice'),
      type: 'currency',
    },
    reason: {
      label: t('metadata.reason'),
      moduleName: 'reason',
      type: 'moduleInfo',
    },
    priorCoverageCredit: {
      label: "Prior Coverage Indicated",
      type: 'boolean'
    },
    rstAmount: {
      label: t('metadata.rstAmount'),
      type: 'currency',
    },
    status: {
      label: t('metadata.status'),
      moduleName: 'employeeDependentStatus',
      type: 'moduleInfo',
    },
    terminationDOE: {
      label: t('metadata.coverageEndDate'),
      moduleName: 'terminationDOE',
      type: 'moduleInfo',
    },
  };
  const lifeEvents = {
    doeType: {
      label: 'DOE TYPE',
    },
    acquisitionDate: {
      label: t(
        'groupEmployees.enrollmentPage.lateEnrollmentPage.petAcquisitionDate'
      ),
      type: 'date',
    },
    assignedTo: {
      label: t('metadata.assignedTo'),
      moduleName: 'user',
      type: 'moduleInfo',
    },
    benAdmin: {
      label: t('metadata.receivedFrom'),
      moduleName: 'isbenAdmin',
      type: 'moduleInfo',
    },
    certificateNumber: {
      label: t('metadata.certificateNumber'),
    },
    changeAt: {
      label: t('metadata.changeMadeAt'),
    },
    changeBy: {
      label: t('metadata.changeMadeBy'),
      moduleName: 'user',
      type: 'moduleInfo',
    },
    createdBy: {
      label: t('metadata.createdBy'),
      moduleName: 'user',
      type: 'moduleInfo',
    },
    createdFor: {
      label: t('metadata.createdFor'),
      moduleName: 'user',
      type: 'moduleInfo',
    },
    declineReasonType: {
      label: t('metadata.declineRequestType'),
    },
    employeeId: {
      label: t('metadata.terminationDate'),
      moduleName: 'terminationDate',
      type: 'moduleInfo',
    },
    groupName: {
      label: t('metadata.groupName'),
    },
    lastModified: {
      label: t('metadata.lastModified'),
      type: 'dateTime',
    },
    qleNumber: {
      label: t('metadata.qleNumber'),
      moduleName: 'qleStatusCode',
      type: 'moduleInfo',
    },
    qleType: {
      label: t('metadata.qleType'),
      picklistId: 'qle-type',
      type: 'picklist',
    },
    reason: {
      label: t('metadata.reason'),
    },
    reasonType: {
      label: t('metadata.reasonType'),
    },
    reopenReasonType: {
      label: t('metadata.reopenRequestType'),
    },
    requestDate: {
      label: t('metadata.requestDate'),
      type: 'dateWithDiff',
    },
    requestedDate: {
      label: t('metadata.requestedDate'),
      type: 'dateWithDiff',
    },
    requestStatus: {
      label: t('metadata.requestStatus'),
      picklistId: 'qle-status',
      type: 'picklist',
    },
    requestType: {
      label: t('metadata.requestType'),
    },
    transactionDate: {
      label: t('metadata.terminationDate'),
      type: 'date',
    },
    user: {
      email: {
        label: t('metadata.email'),
      },
    },
  };
  const groups = {
    alternateRegistration: {
      label: 'Alternate Registration',
      type: 'boolean',
    },
    benAdminName: {
      label: t('metadata.benAdmin'),
    },
    renewalType: {
      label: 'Renewal Type',
      picklistId: 'renewalType',
      type: 'picklist',
    },
    billingInfo: {
      accountType: {
        label: t('groups.paymentMethod'),
        picklistId: 'account-type',
        type: 'picklist',
      },
      billingCycle: {
        label: t('groups.billingCycle'),
        picklistId: 'billing-cycle',
        type: 'picklist',
      },
      billingType: {
        label: t('groups.billingType'),
        picklistId: 'billing-type',
        type: 'picklist',
      },
      payrollFrequency: {
        label: t('metadata.payrollFrequency'),
        picklistId: 'payroll-frequency',
        type: 'picklist',
      },
    },
    ratingFrequency: {
      label: 'Rate File Frequency',
      picklistId: 'rate-file-frequency',
      type: 'picklist',
    },
    cancellationReason: {
      label: 'Cancellation Reason',
      picklistId: 'cancellation-reason',
      type: 'picklist',
    },
    companyCode: {
      label: t('metadata.companyCode'),
    },
    created: {
      label: t('metadata.created'),
      type: 'dateTime',
    },
    eligibilityRule: {
      enrollmentPeriod: {
        label: 'Enrollment Period (in days)',
      },
      isFirstFollowingWaitingPeriod: {
        label: t('groups.firstMonthWaiting'),
        type: 'boolean',
      },
      newHireWaitingPeriod: {
        label: 'New Hire Waiting Period (in days)',
      },
    },
    fin: {
      label: 'Federal Number',
    },
    groupName: {
      label: t('filters.groupName'),
    },
    groupNameWithMidYearTransition: {
      label: t('filters.groupName'),
      moduleName: 'groupNameWithMidYearTransition',
      type: 'moduleInfo',
    },
    groupNameWithNotification: {
      label: t('filters.groupName'),
      moduleName: 'groupNotificationStatus',
      type: 'moduleInfo',
    },
    groupNumber: {
      label: 'MGA Group Number',
    },
    groupTransitionStatus: {
      label: 'Group Transition Status',
      moduleName: 'groupTransitionStatus',
      type: 'moduleInfo',
    },
    groupType: {
      label: t('groups.groupType'),
      picklistId: 'groupType',
      type: 'picklist',
    },
    locationDetails: {
      address: {
        label: 'Address',
        state: {
          label: 'State',
          picklistId: 'us-state',
          type: 'picklist',
        },
        type: 'address',
      },
      contact: {
        label: 'Contact',
        type: 'contact',
      },
    },
    mga: {
      label: t('groupEmployees.detailsPage.groupMGA'),
    },
    noOfEmployees: {
      label: t('groups.noOfEmployees'),
    },
    payrollDeductionReportLevel: {
      label: t('groups.payrollDeductionReportLevel'),
    },
    plan: {
      annualDeductible: {
        defaultValue: t('metadata.0dollar'),
        label: t('metadata.annaulDeductible'),
        type: 'currency',
      },
      annualLimit: {
        defaultValue: t('metadata.unlimited'),
        label: t('metadata.annualLimit'),
        type: 'currency',
      },
      bwpCLI: {
        defaultValue: t('metadata.sixMonths'),
        label: t('metadata.bwpCLI'),
      },
      bwpIllness: {
        defaultValue: t('metadata.waived'),
        label: t('metadata.bwpIllness'),
        text: t('common.days'),
        type: 'postfix',
      },
      bwpInjuries: {
        defaultValue: t('metadata.waived'),
        label: t('metadata.bwpInjuries'),
        text: t('common.days'),
        type: 'postfix',
      },
      coinsurance: {
        label: t('metadata.coinsurance'),
        type: 'directPercentage',
      },
      copay: {
        defaultValue: t('metadata.notIncluded'),
        label: t('metadata.copay'),
        type: 'currency',
      },
      coverageCredit: {
        label: t('metadata.coverageCredit'),
        type: 'boolean',
      },
      coverageFromDate: {
        label: t('metadata.masterPolicyEffectiveDate'),
        type: 'date',
      },
      coverageToDate: {
        label: t('metadata.masterPolicyEndDate'),
        type: 'date',
      },
      diminishingDeductible: {
        defaultValue: t('metadata.notIncluded'),
        label: t('metadata.diminishingDeductible'),
        type: 'currency',
      },
      enrollmentFromDate: {
        label: t('metadata.enrollmentFromDate'),
        type: 'date',
      },
      enrollmentToDate: {
        label: t('metadata.enrollmentToDate'),
        type: 'date',
      },
      existingCondition: {
        defaultValue: t('metadata.existingConditionDefault'),
        label: t('metadata.existingCondition'),
        picklistId: 'existing-condition',
        type: 'picklist',
      },
      lastModified: {
        label: 'Last Modified',
        type: 'dateTime',
      },
      maxAge: {
        defaultValue: t('metadata.none'),
        label: t('metadata.maxAge'),
      },
      name: {
        label: 'Plan Name',
      },
      planChoices: {
        coverageFromDate: {
          label: 'Coverage From Date',
          type: 'date',
        },
        coverageToDate: {
          label: 'Coverage To Date',
          type: 'date',
        },
        enrollmentFromDate: {
          label: t('metadata.plan.enrollmentFromDate'),
          type: 'date',
        },
        enrollmentToDate: {
          label: 'Enrollment To Date',
          type: 'date',
        },
        name: {
          label: 'Product',
        },
      },
      planEndDate: {
        label: 'Plan End Date',
        type: 'date',
      },
      planName: {
        label: 'Plan Name',
      },
      planPrice: {
        label: t('metadata.planPrice'),
        type: 'currency',
      },
      planStartDate: {
        label: 'Plan Start Date',
        type: 'date',
      },
      poaAR: {
        label: t('metadata.poaAR'),
        type: 'boolean',
      },
      productDetails: {
        name: {
          label: t('metadata.productDetails'),
          type: 'productDetails',
        },
      },
      riderABC: {
        label: t('metadata.riderABC'),
        type: 'boolean',
      },
      riderAC: {
        label: t('metadata.riderAc'),
        type: 'boolean',
      },
      riderFR: {
        defaultValue: t('metadata.notIncluded'),
        label: t('metadata.riderFR'),
        type: 'currency',
      },
      riderICC: {
        label: config.canadaEnv
          ? t('metadata.inheritedConditionRider')
          : 'Inherited and Congenital Care',
        type: 'boolean',
      },
      riderOETC: {
        label: t('metadata.riderOETC'),
        type: 'boolean',
      },
      riderRD: {
        defaultValue: t('metadata.notIncluded'),
        label: t('metadata.riderRD'),
        type: 'currency',
      },
      riderRPT: {
        label: t('metadata.riderRPT'),
        type: 'boolean',
      },
      weightLimitA: {
        label: 'Weight Limit: Band A',
        type: 'weight',
      },
      weightLimitB: {
        label: 'Weight Limit: Band B',
        type: 'weight',
      },
      weightLimitC: {
        label: 'Weight Limit: Band C',
        type: 'weight',
      },
      wellness: {
        defaultValue: t('metadata.notIncluded'),
        label: t('metadata.wellness'),
        // "type": "boolean"
      },
    },
    policyTerm: {
      label: t('common.policyTerm'),
    },
    postEnrollmentGroup: {
      label: t('groups.postEnrollmentStatus'),
      type: 'boolean',
    },
    registrationLink: {
      label: 'Registration Link',
      type: 'copyClipBoard',
    },
    renewalNotificationDate: {
      label: 'Renewal Notification Date',
      picklistId: 'renewal-notification-date',
      type: 'picklist',
    },
    renewalStatus: {
      label: t('groups.renewalStatus'),
      picklistId: 'renewal-status',
      type: 'picklist',
    },
    sicCode: {
      label: 'SIC Code',
    },
    status: {
      label: t('metadata.groupStatus'),
      type: 'capitalCase',
    },
  };
  const epr = {
    amountDue: {
      label: 'Amount Due',
    },
    billCreationDate: {
      label: 'Bill Creation Date',
      type: 'dateTime',
    },
    cashRemitted: {
      label: 'Cash Remitted',
      type: 'currency',
    },
    cycleNo: {
      label: 'Cycle Number',
    },
    empId: {
      label: t('filters.employeeId'),
    },
    firstName: {
      label: t('filters.firstName'),
    },
    groupCreditUsed: {
      label: 'Group Credit Used',
      type: 'currency',
    },
    lastName: {
      label: t('groupEmployees.enrollmentPage.lastName'),
    },
    outstandingBalance: {
      label: t('groupEmployees.dashboardPage.outstandingBalance'),
      type: 'currency',
    },
    status: {
      label: 'Status',
      type: 'capitalCase',
    },
    totalAmountDeducted: {
      label: 'Total Amount Deducted',
      type: 'currency',
    },
  };
  let result;
  switch (moduleName) {
    case 'barrelErrors': {
      result = barrelErrors;
      break;
    }
    case 'barrelFiles': {
      result = barrelFiles;
      break;
    }
    case 'billing': {
      result = billing;
      break;
    }
    case 'billing-notes': {
      result = billingNotes;
      break;
    }
    case 'report-export': {
      result = reportExport;
      break;
    }
    case 'masterCalendar': {
      result = billing;
      break;
    }
    case 'claims': {
      result = claims;
      break;
    }
    case 'commissions': {
      result = commissions;
      break;
    }
    case 'employee-dependents': {
      result = employeeDependents;
      break;
    }
    case 'epr': {
      result = epr;
      break;
    }
    case 'errors': {
      result = errors;
      break;
    }
    case 'files': {
      result = files;
      break;
    }
    case 'group-employees': {
      result = groupEmployees;
      break;
    }
    case 'enrollment-group-employees': {
      result = groupEmployees;
      break;
    }
    case 'group-admins': {
      result = groupAdmins;
      break;
    }
    case 'groups': {
      result = groups;
      break;
    }
    case 'producers': {
      result = producers;
      break;
    }
    case 'life-events': {
      result = lifeEvents;
      break;
    }
    case 'sub-producers': {
      result = subProducers;
      break;
    }
    case 'users': {
      result = users;
      break;
    }
    case 'ben-admin': {
      result = benAdmin;
      break;
    }
    case 'ben-broker': {
      result = broker;
      break;
    }
    case 'billing-history': {
      result = billingHistory;
      break;
    }
    case 'sftpErrors': {
      result = sftpErrors;
      break;
    }
    default: {
      result = {};
    }
  }
  return result;
};
