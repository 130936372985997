import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import Button from '@basecomponents/Button';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';

/**
 * @category BaseComponents
 * @param {boolean} enableJumpToPage
 * @param {React.ReactNode} leftButtonContent
 * @param {number} offset
 * @param {function} onPageClick
 * @param {function} onNextClick
 * @param {function} onPreviousClick
 * @param {number} pageSize
 * @param {number} pageTotal
 * @param {React.ReactNode} rightButtonContent
 * @param {number} total
 * @param {number} totalJumpToPages
 * @param {*} rest
 * @returns {React.FC}
 */
const Paginator = ({
  enableJumpToPage,
  leftButtonContent,
  offset,
  onPageClick,
  onNextClick,
  onPreviousClick,
  pageSize,
  pageTotal,
  rightButtonContent,
  total,
  totalJumpToPages,
  ...rest
}) => {
  const { t } = useTranslation()
  const { defaultStyle = {} } = useContext(ThemeContext);
  const {
    paginatorOneOfTotal = {},
    paginatorOneOfTotalContainer = {},
    paginatorButtonNextAndPrevious = {},
  } = defaultStyle.defaultPaginator(null, null);
  const currentPage = Math.floor(offset / pageSize) + 1;
  const pagiBtns = [];

  if (enableJumpToPage) {
    const totalPages = Math.ceil(total / pageSize);
    const MID = Math.floor(totalJumpToPages / 2);

    let start = 1;
    let end = Math.min(totalJumpToPages, totalPages);

    if (currentPage > MID + 1) {
      end = Math.min(currentPage + MID - 1, totalPages);
      start = currentPage - MID;
      start = Math.max(1, Math.min(start, end - totalJumpToPages + 1));
    }

    for (let i = start; i <= end; i += 1) {
      pagiBtns.push(
        <Button
          key={i}
          aria-label={i}
          onClick={() => {
            onPageClick(i - 1);
          }}
          simple
          sx={
            defaultStyle.defaultPaginator(currentPage, i)
              .paginatorNumericButtons
          }
        >
          {i}
        </Button>
      );
    }
  }

  return (
    <Box {...paginatorOneOfTotal} {...rest}>
      {pageTotal > 0 && (
        <Box sx={paginatorOneOfTotalContainer}>
          {offset + 1}&ndash;{offset + pageTotal}
          {total ? ` of ${total}` : ''}
        </Box>
      )}

      <Button
        aria-label={t('component.previousPage')}
        disabled={offset === 0}
        onClick={() => {
          onPreviousClick();
        }}
        sx={paginatorButtonNextAndPrevious}
      >
        {leftButtonContent}
      </Button>
      {enableJumpToPage && pageTotal > 0 && pagiBtns}
      <Button
        aria-label={t('component.nextPage')}
        disabled={
          total
            ? offset + pageSize >= total
            : pageTotal === 0 || pageTotal % pageSize !== 0
        }
        onClick={() => {
          onNextClick();
        }}
        sx={paginatorButtonNextAndPrevious}
      >
        {rightButtonContent}
      </Button>
    </Box>
  );
};

Paginator.propTypes = {
  enableJumpToPage: PropTypes.bool,
  leftButtonContent: PropTypes.node.isRequired,
  offset: PropTypes.number,
  onNextClick: PropTypes.func.isRequired,
  onPageClick: PropTypes.func,
  onPreviousClick: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  pageTotal: PropTypes.number.isRequired,
  rightButtonContent: PropTypes.node.isRequired,
  total: PropTypes.number,
  totalJumpToPages: PropTypes.number,
};

Paginator.defaultProps = {
  enableJumpToPage: false,
  offset: 0,
  onPageClick: () => {},
  pageSize: 10,
  total: null,
  totalJumpToPages: 10,
};

export default Paginator;
